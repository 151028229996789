import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { Theme } from "../globals/ThemeColors";


interface IProgressBarProps {
  progress: number
  borderRadius?: number
  height?: number
}
interface IProgressBarState {}

export class ProgressBar extends Component<IProgressBarProps, IProgressBarState> {
  render() {

    var borderRadiusStyle = this.props.borderRadius != undefined ? {borderRadius: this.props.borderRadius} : undefined
    var heightStyle = this.props.height != undefined ? {height: this.props.height} : undefined

    return (
      <View style={[styles.holderBar, borderRadiusStyle, heightStyle]}>
        <View style={[styles.progressBar, borderRadiusStyle, heightStyle, {width: `${Math.ceil(this.props.progress)}%`,}]}>

        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  holderBar: {
    width: "100%",
    height: 16,
    backgroundColor: Theme.ProgressBarEmpty,
    borderRadius: 5
  },
  progressBar: {
    width: "0%",
    height: 16,
    borderRadius: 5,
    transitionProperty: "width",
    transitionDuration: '0.6s',
    willChange: "width",
    backgroundColor: Theme.ProgressBarFilled,
  }

})
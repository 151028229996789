import { action, observable } from "mobx";
import "firebase/database";
import { ChatDb } from "../fire";
import { IChannel, IChannelUser, IMessage, IOTChatMessage } from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { OTGlobals } from "../OTGlobals";

const logger = new Logger("ChatMessageManager");

export class ChatMessageManager {
  /* copied down from ChatManager */
  teamId: string;
  userId: string;
  channelId: string;
  topicId: string;
  getUserChannel: (channelId: string) => IChannelUser;
  fsDb: firebase.firestore.Firestore;
  fakeId: number = 0;
  unwatchChannel?: () => void;
  unwatchChannelUsers?: () => void;
  unwatchChannelMessages?: () => void;

  @observable channel?: IChannel;
  @observable channelUsers: Record<string, IChannelUser> = {};
  @observable messages: { [id: string]: IOTChatMessage } = {};
  @observable lastReadMessageId?: number;
  @observable lastReceivedMessageId?: number;
  @observable gettingMore?: boolean = false;
  @observable atEnd: boolean = false;
  @observable chatUserIsTyping: Record<
    string,
    { lastTyping: Date; timeoutId: ReturnType<typeof setTimeout> }
  > = {};
  constructor(
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    getUserChannel: (channelId: string) => IChannelUser
  ) {
    this.fsDb = fsDb;
    this.teamId = teamId;
    this.userId = userId;
    this.channelId = channelId;
    this.topicId = topicId;
    this.getUserChannel = getUserChannel;
    logger.info(`initialising for teamId ${teamId} channelId ${channelId} topicId ${topicId}`);

    this.start();
  }

  start = () => {
    this.unwatchChannel = ChatDb.watchChannel(
      this.fsDb,
      this.teamId,
      this.channelId,
      this.handleChannel
    );
    this.unwatchChannelUsers = ChatDb.watchChannelUsers(
      this.fsDb,
      this.teamId,
      this.channelId,
      this.handleChannelUsers
    );

    //const teamManager = OTUserData.teamManagers[this.teamId]
    const userChannel = this.getUserChannel(this.channelId);

    this.lastReadMessageId = userChannel?.topics?.[this.topicId]?.messageId || 0;

    logger.debug("watchChannelMessages", this.channelId, this.lastReadMessageId);

    this.unwatchChannelMessages = ChatDb.watchChannelMessages(
      this.fsDb,
      this.teamId,
      this.channelId,
      this.topicId,
      Math.max(this.lastReadMessageId - 20, 0),
      this.handleWatchMessages
    );
  };

  stop = () => {
    this.unwatchChannel && this.unwatchChannel();
    this.unwatchChannelUsers && this.unwatchChannelUsers();
    this.unwatchChannelMessages && this.unwatchChannelMessages();
  };

  handleChannel = (channel: IChannel, isCached: boolean = false) => {
    logger.info("handleChannel", channel);
    this.channel = channel;
  };

  handleChannelUsers = (added: IChannelUser[], edited: IChannelUser[], deleted: string[]) => {
    for (let userId of deleted) {
      delete this.channelUsers[userId];
    }

    for (let channelUser of added) {
      this.channelUsers[channelUser.userId] = channelUser;
    }

    for (let channelUser of edited) {
      this.channelUsers[channelUser.userId] = channelUser;
    }

    this.calculateIsTyping(this.channelUsers);
  };

  loadSince = async (messageId: number) => {
    const teamData = OTGlobals.getTeamData?.(this.teamId);

    logger.info("reloading since", messageId);
    // this.addLog(`reloading all after reconnecting ${sinceMessageId}`);

    // if (Object.keys(this.messages).length > 0) {
    //   const oldestMessage = Object.keys(this.messages)[0];

    const messageDocs: {
      [id: string]: IMessage;
    } = await ChatDb.getChatMessagesSince(this.fsDb, this.teamId, this.channelId, this.topicId, messageId);

    const otMessages = Object.values(messageDocs).map((doc) => {
      // this.addLog(`reloading message ${doc.id}`);

      const teamUser = teamData.getTeamUser(doc.userId);

      const otmessage: IOTChatMessage = {
        ...doc,
        name: teamUser.name,
        userImageUrl: teamUser.imageUrl || null,
      };

      if (this.messages[doc.id]) {
        delete this.messages[doc.id];
      }

      this.messages[doc.id] = otmessage;

      return otmessage;
    });

    // }
  };

  pageSize = 50;
  loadMore = async () => {
    var teamData = OTGlobals.getTeamData(this.teamId);

    this.gettingMore = true;

    if (Object.keys(this.messages).length > 0) {
      const oldestMessage = Object.values(this.messages)[0].messageId;
      logger.debug("loading more messages", oldestMessage);
      let messageDocs: {
        [id: string]: IMessage;
      } = {};

      if (oldestMessage) {
        messageDocs = await ChatDb.getMoreChatMessages(
          this.fsDb,
          this.teamId,
          this.channelId,
          this.topicId,
          oldestMessage,
          this.pageSize
        );
      }

      const messages = {};

      logger.info("messageDocs", messageDocs);

      const otMessages = Object.values(messageDocs)
        .filter((doc) => !(doc.id in this.messages))
        .map((doc) => {
          const teamUser = teamData.getTeamUser(doc.userId);

          const otmessage: IOTChatMessage = {
            ...doc,
            name: teamUser.name,
            userImageUrl: teamUser.imageUrl || null,
          };
          messages[doc.id] = otmessage;
          return otmessage;
        });

      this.atEnd = otMessages.length <= 1;
      this.messages = { ...messages, ...this.messages };

    }
    this.gettingMore = false;
  };

  @action
  handleWatchMessages = (added: IMessage[], edited: IMessage[], deleted: string[]) => {
    for (let message of added) {
      this.handleDoc(message);
    }
    for (let message of edited) {
      this.handleDoc(message, true);
    }

    for (let messageId of deleted) {
      this.deleteDoc(messageId);
    }
  };

  handleDoc = (doc: IMessage, hasChanged: boolean = false) => {
    const teamData = OTGlobals.getTeamData(this.teamId);
    const teamUser = teamData.getTeamUser(doc.userId);

    if (hasChanged && !(doc.id in this.messages)) {
      logger.debug("got a change for a message I don't currently have, ignoring");
      return;
    }

    const otmessage: IOTChatMessage = {
      ...doc,
      name: teamUser.name,
      userImageUrl: teamUser.imageUrl || null,
    };

    this.messages[doc.id] = otmessage;

    if (!hasChanged) {
      if (
        doc.userId == this.userId &&
        !doc.isSystem &&
        (this.lastReadMessageId || 0) < doc.messageId
      ) {
        this.lastReadMessageId = doc.messageId;
      }
      if ((this.lastReceivedMessageId || 0) < doc.messageId) {
        this.lastReceivedMessageId = doc.messageId;
      }
    }
  };

  deleteDoc = (messageId) => {
    logger.debug("deleteDoc", messageId);

    delete this.messages[messageId];
  };

  calculateIsTyping = (channelUsers: Record<string, IChannelUser>) => {
    const users = channelUsers;

    for (let userId of Object.keys(users)) {
      if (userId == this.userId) {
        continue;
      }

      if (
        users[userId].topics?.[this.topicId]?.lastTyping &&
        Date.now() - users[userId].topics?.[this.topicId]?.lastTyping?.getTime()! < 5000
      ) {
        if (
          users[userId].topics?.[this.topicId].lastTyping?.getTime() !=
          this.chatUserIsTyping[userId]?.lastTyping.getTime()
        ) {
          const age = Date.now() - users[userId].topics?.[this.topicId].lastTyping?.getTime()!;

          if (this.chatUserIsTyping[userId]) {
            clearTimeout(this.chatUserIsTyping[userId].timeoutId);
          }

          const timeoutId = setTimeout(() => {
            delete this.chatUserIsTyping[userId];
          }, 5000 - age);

          this.chatUserIsTyping[userId] = {
            timeoutId,
            lastTyping: users[userId].topics?.[this.topicId].lastTyping!,
          };
        }
      } else if (this.chatUserIsTyping[userId]) {
        clearTimeout(this.chatUserIsTyping[userId].timeoutId);
        delete this.chatUserIsTyping[userId];
      }
    }
  };
}

import {
  IAudioContainer,
  IFocusWindow,
  IGetOpenWindows,
  IHideToast,
  INotification,
  IOpenWindow,
  IShowToast,
} from "@openteam/models";
import { assertDefined } from "@openteam/app-util";

type TRingTones = { getIncoming: () => IAudioContainer; getOutgoing: () => IAudioContainer };
type TSoundEffects = Record<
  "globalAlert" | "alert" | "videoBell" | "knock" | "newMessage" | "unmute",
  () => void
>;
type TParseEmoji = (value: string) => { value: any; isOnlyEmoji: boolean };
type TForegroundService = {
  startCall: (callId: string, title: string, text: string) => Promise<void>;
  stopCall: () => Promise<void>;
};
type TPlatformUtils = {
  Platform: any;
  PlatformOS: string;
  allowDisplaySleep: () => void;
  focusWindow: IFocusWindow;
  getOpenWindows: IGetOpenWindows;
  openWindow: IOpenWindow;
  preventDisplaySleep: () => void;
  createTray: () => void;
  destroyTray: () => void;
};
type TShowNotification = (v: INotification) => void;

export class OTUserInterfaceClass {
  _ringtones: TRingTones | undefined;

  setRingtones = (ringtones: TRingTones) => (this._ringtones = ringtones);
  get ringtones() {
    const f = this._ringtones;
    assertDefined(f);
    return f;
  }
  _soundEffects: TSoundEffects | undefined;
  setSoundEffects = (soundEffects: TSoundEffects) => (this._soundEffects = soundEffects);
  get soundEffects() {
    const f = this._soundEffects;
    assertDefined(f);
    return f;
  }

  _toastHandlers: { show: IShowToast; hide: IHideToast } | undefined;
  registerToastHandlers = (toastHandlers: { show: IShowToast; hide: IHideToast }) =>
    (this._toastHandlers = toastHandlers);
  get toastHandlers() {
    const f = this._toastHandlers;
    assertDefined(f);
    return f;
  }

  _parseEmojis: TParseEmoji | undefined;
  registerParseEmojis = (parseEmojis: TParseEmoji) => (this._parseEmojis = parseEmojis);
  get parseEmojis() {
    const f = this._parseEmojis;
    assertDefined(f);
    return f;
  }

  _foregroundService: TForegroundService | undefined;
  registerForegroundService = (foregroundService: TForegroundService) =>
    (this._foregroundService = foregroundService);
  get foregroundService() {
    const f = this._foregroundService;
    assertDefined(f);
    return f;
  }

  _platformUtils: TPlatformUtils | undefined;
  registerPlatformUtils = (platformUtils: TPlatformUtils) => (this._platformUtils = platformUtils);
  get platformUtils() {
    const f = this._platformUtils;
    assertDefined(f);
    return f;
  }

  _showNotification: TShowNotification | undefined;
  registerShowNotification = (showNotification: TShowNotification) =>
    (this._showNotification = showNotification);
  get showNotification() {
    const f = this._showNotification;
    assertDefined(f);
    return f;
  }

  _navigate: ((name: any, params: any) => void) | undefined
  registerNavigate = (f: (name: any, params: any) => void) => this._navigate = f;
  get navigate() {
    const f = this._navigate;
    assertDefined(f);
    return f;
  }
}

export const OTUserInterface = new OTUserInterfaceClass();

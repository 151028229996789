import events from "events";
import Peer from "simple-peer";
import uuid from "react-native-uuid";
import { action } from "mobx";
import { Logger } from "@openteam/app-util";
import { AwaitLock } from "@openteam/app-util";
import { ITeamDoc, ITeamServerResponse, IPTTController, IStreamUser } from "@openteam/models";
import { TeamConnection } from "./TeamConnection";
import { InStreamDetails, ISignalData, PTTType } from "./PeerConnection";
import { OTGlobals } from "./OTGlobals";
import { OTUserInterface } from "./OTUserInterface";
import { RoomManager } from "./RoomManager";
import {
  LocalStreamDetails,
  RemoteStreamDetails,
  WebcamStream,
  StreamDetails,
} from "./MediaDeviceManager";
import { userIsOnline } from "./utils/userIsOnline";
import { changeSDPCodec } from "./utils/WebRTCUtil";
import { sendAction } from "./Alert";

const logger = new Logger("PTTTeamController");

export class PTTTeamController extends events.EventEmitter implements IPTTController {
  fbDb: firebase.database.Database;
  _myUserId: string;
  _teamId: string;
  _teamConnection: TeamConnection;
  _roomManager: RoomManager;
  _currentPTT: number = 0;
  _currentTargets: string[] | undefined;
  _connectedTargets: string[] | undefined;
  _myStream: LocalStreamDetails | undefined;
  _incomingStreams: RemoteStreamDetails[] = [];
  _stateLock = new AwaitLock();
  _pttType: PTTType | undefined;
  _lastPttFrom: string | undefined;
  _canPttUsers: string[] = [];

  _users: { [id: string]: IStreamUser } = {};
  _incomingPeers: { [id: string]: PTTPeer } = {};
  _outgoingPeers: { [id: string]: PTTPeer } = {};

  constructor(
    fbDb: firebase.database.Database,
    myUserId: string,
    teamId: string,
    teamConnection: TeamConnection,
    roomManager: RoomManager
  ) {
    super();
    this.fbDb = fbDb;
    this._myUserId = myUserId;
    this._teamId = teamId;
    this._teamConnection = teamConnection;
    this._teamConnection.on("signal", this._handleSignalMsg);

    this._roomManager = roomManager;
  }
  _shouldAcceptStream = (userId: string) => {
    const teamData = OTGlobals.getTeamData(this._teamId);
    logger.debug(`_shouldAcceptStream: teammId ${this._teamId}`, teamData);
    return teamData.me.canPtt;
  };

  _handleSignalMsg = (roomId, fromUserId, data: ISignalData) => {
    const msgData = data;

    if (roomId != "ptt") {
      return;
    }

    // logger.info("handleMsg ", msgData)

    var msg = JSON.parse(msgData.offer);
    var fromInitiator = msgData.fromInitiator;

    if (fromInitiator) {
      if (msg.type == "offer") {
        if (this._incomingPeers[fromUserId] && msgData.newConnection) {
          logger.info(
            "Destroying old connection",
            fromUserId,
            this._incomingPeers[fromUserId].peerId
          );
          this._incomingPeers[fromUserId].peer.destroy();
          delete this._incomingPeers[fromUserId];
        }

        if (!this._incomingPeers[fromUserId] && this._shouldAcceptStream(fromUserId)) {
          this._createIncomingPeer(fromUserId, msgData.peerId, msgData.pttType);
        }
      }

      if (
        this._incomingPeers[fromUserId] &&
        this._incomingPeers[fromUserId].peerId == msgData.peerId
      ) {
        logger.debug("applying signal message from", fromUserId, msgData.peerId, msg);
        this._incomingPeers[fromUserId].peer.signal(msg);
      }
    } else {
      if (
        this._outgoingPeers[fromUserId] &&
        this._outgoingPeers[fromUserId].peerId == msgData.peerId
      ) {
        logger.debug("signal message from", fromUserId, msg);
        this._outgoingPeers[fromUserId].peer.signal(msg);
      }
    }
  };

  sendSignalMsg = (
    toUserId: string,
    data: ISignalData,
    callback?: (responseData: ITeamServerResponse) => void
  ) => {
    this._teamConnection.sendSignal("ptt", toUserId, data, callback);
  };

  _createIncomingPeer = (userId: string, peerId: string, pttType: PTTType) => {
    logger.debug(`_createIncomingPeer ${userId} ${peerId}`);

    this._incomingPeers[userId] = new PTTPeer(peerId, this._teamId, userId, pttType, false, {
      onClose: this._onIncomingClose,
      onError: this._onIncomingError,
      onConnect: this._onIncomingConnect,
      onSignal: this.sendSignalMsg,
    });
  };

  _onIncomingError = (userId: string, err: any) => {
    if (this._incomingPeers[userId]) {
      logger.debug("_onIncomingError for ", userId, this._incomingPeers[userId]?.peerId);

      this._incomingPeers[userId].peer.destroy();
      delete this._incomingPeers[userId];
    }
  };
  _onIncomingClose = (userId: string) => {
    logger.debug("_onIncomingClose for ", userId, this._incomingPeers[userId]?.peerId);
    delete this._incomingPeers[userId];
    this.emit("peerdisconnected", userId);
  };

  _onIncomingConnect = (userId: string) => {
    if (this._incomingPeers[userId]) {
      logger.debug("_onIncomingConnect for ", userId, this._incomingPeers[userId]?.peerId);
      if (Object.keys(this._users).includes(userId)) {
        this.emit("peerconnected", userId);
      } else {
        logger.warn(`user '${userId}' connected to room but not in users list {}`);
        this._disconnectIncomingPeer(userId);
      }
    }
  };

  _disconnectIncomingPeer = (userId) => {
    logger.debug("_disconnectIncomingPeer for ", userId, this._incomingPeers[userId]?.peerId);
    if (this._incomingPeers[userId]) {
      this._incomingPeers[userId].shutdownStreams();
      this._incomingPeers[userId].destroy();
      delete this._incomingPeers[userId];
    }
  };

  @action
  startPtt = async (roomId?: string, subTeam?: string, userId?: string) => {
    await this._stateLock.acquireAsync();
    const userSettings = OTGlobals.localUserSettings;

    try {
      if (!this.inPtt) {
        this._currentPTT += 1;
        const targets = this._getTargets(roomId, subTeam, userId);
        if (targets.length) {
          logger.debug("Starting ptt to ", targets);
          this._currentTargets = targets;
          this._connectedTargets = [];
          this._pttType = roomId ? "PTT_GLOBAL" : "PTT_WALKIE";
          this._myStream = new WebcamStream(this._teamId, this._myUserId, userSettings);
          this._myStream.on("trackremoved", this.stopPtt);
          await this._myStream.enableAudio();
          this._writeState();
          this.connectToTargets();
        }
      } else {
        logger.warn("Already in ptt");
      }
    } finally {
      this._stateLock.release();
    }

    return this.inPtt;
  };

  @action
  stopPtt = async () => {
    await this._stateLock.acquireAsync();

    try {
      if (this.inPtt) {
        logger.debug("Shutting down ptt");
        delete this._currentTargets;

        this._myStream!.off("trackremoved", this.stopPtt);
        this._myStream!.shutdown();

        Object.values(this._outgoingPeers).forEach((peer) => peer.shutdownStreams());
      } else {
        logger.warn("Not in ptt");
      }
    } catch (err) {
      logger.error("Error shutting down PTT stream", err);
    } finally {
      logger.info("shutting down peers");

      Object.keys(this._outgoingPeers).forEach((userId) => this._disconnectOutgoingPeer(userId));

      this._writeState();
      delete this._myStream;
      this._stateLock.release();
    }

    return this.inPtt;
  };

  processDoc = (doc: ITeamDoc) => {
    this._users = doc.users;

    const myRoom = this._roomManager.getUserRoom(this._myUserId);
    this._canPttUsers = Object.values(doc.users)
      .filter((user) => {
        const online = userIsOnline(user).isOnline;
        const inBackground = user.status?.inBackground == true;
        const userRoom = this._roomManager.getUserRoom(user.id);

        return !myRoom && online && !userRoom && !inBackground;
      })
      .map((user) => user.id);

    if (this.inPtt) {
      const okTargets = this._currentTargets!.filter((userId) => this.canPttUser(userId));

      if (okTargets.length == 0) {
        this.stopPtt();
      } else if (okTargets.length < this._currentTargets!.length) {
        this._currentTargets = okTargets;
        this.connectToTargets();
      }
    }
  };

  connectToTargets = () => {
    this._currentTargets?.forEach((userId) => {
      if (!this._outgoingPeers[userId]) {
        this._createOutgoingPeer(userId);
      }
    });
  };

  _createOutgoingPeer = (userId) => {
    logger.debug(`_createOutgoingPeer ${userId}`);

    this._outgoingPeers[userId] = new PTTPeer(
      undefined,
      this._teamId,
      userId,
      this._pttType,
      true,
      {
        onClose: this._onOutgoingClose,
        onError: this._onOutgoingError,
        onConnect: this._onOutgoingConnect,
        onSignal: this.sendSignalMsg,
      }
    );
  };
  _onOutgoingError = (userId: string, err: any) => {
    if (this._outgoingPeers[userId]) {
      logger.debug("_onOutgoingError for ", userId, this._outgoingPeers[userId]?.peerId);
      this._outgoingPeers[userId].destroy();
      delete this._outgoingPeers[userId];
    }
  };

  _onOutgoingClose = (userId: string) => {
    logger.debug("_onOutgoingClose for ", userId, this._outgoingPeers[userId]?.peerId);
    delete this._outgoingPeers[userId];
    this.emit("peerdisconnected", userId);

    if (this._currentTargets?.includes(userId)) {
      logger.info("retry creating peer ", userId);
      OTUserInterface.toastHandlers.show("Failed to connect walkie-talkie, retrying...", "error");
      setTimeout(() => this._createOutgoingPeer(userId), 1000);
    }
  };

  _onOutgoingConnect = (userId: string) => {
    if (this._outgoingPeers[userId]) {
      logger.debug("_onOutgoingConnect for ", userId, this._outgoingPeers[userId]?.peerId);

      if (Object.keys(this._users).includes(userId)) {
        if (this._currentTargets?.includes(userId) && this._myStream) {
          logger.debug(`user '${userId}' connected sending streams`);

          if (this._pttType == "PTT_GLOBAL") {
            OTUserInterface.soundEffects.globalAlert();
          } else {
            OTUserInterface.soundEffects.alert();
          }

          if (this._pttType) {
            this._teamConnection.sendAction(userId, this._pttType);

            sendAction(this.fbDb, this._myUserId, this._teamId, userId, this._pttType);
          }

          this._outgoingPeers[userId].sendStream(this._myStream);
          if (!this._connectedTargets!.includes(userId)) {
            this._connectedTargets!.push(userId);
            this._writeState();
          }
        }

        this.emit("peerconnected", userId);
      } else {
        logger.warn(`user '${userId}' connected to room but not in users list {}`);
        this._disconnectOutgoingPeer(userId);
      }
    }
  };

  _disconnectOutgoingPeer = (userId) => {
    logger.debug("_disconnectPeer for ", userId, this._outgoingPeers[userId]?.peerId);

    if (this._outgoingPeers[userId]) {
      this._outgoingPeers[userId].shutdownStreams();
      this._outgoingPeers[userId].destroy();
      delete this._outgoingPeers[userId];
    }
  };

  _getUserSessionToken = (userId: string) => {
    return this._users[userId]?.status?.sessionToken;
  };

  setLastPtt(userId) {
    this._lastPttFrom = userId;
  }

  toggleLastPtt = () => {
    if (this._lastPttFrom) {
      if (
        this.inPtt &&
        this._currentTargets!.includes(this._lastPttFrom) &&
        this._currentTargets!.length == 1
      ) {
        logger.info("stopping ptt");

        this.stopPtt();
      } else {
        logger.info("starting ptt");

        this.startPtt(undefined, undefined, this._lastPttFrom);
      }
    }
  };

  get inPtt() {
    return this._currentTargets != undefined;
  }

  canPttUser(userId) {
    return this._canPttUsers.includes(userId);
  }

  _getTargets = (roomId?: string, subTeam?: string, userId?: string) => {
    logger.info("_getTargets are ", roomId, subTeam, userId);
    var teamData = OTGlobals.getTeamData(this._teamId);

    var targets: string[] = [];

    if (userId) {
      targets = [userId];
    } else if (roomId) {
      const room = teamData.rooms[roomId];
      if (room) {
        if (subTeam) {
          targets = Object.keys(room.subteams![subTeam]);
        } else {
          targets = Object.keys(
            Object.values(room.subteams || {}).reduce((users, stUsers) => {
              users = { ...users, ...stUsers };
              return users;
            }, room.users)
          );
        }
      } else {
        logger.warn(`Unable to load ${roomId} from PTT`);
      }
    }
    return targets.filter((userId) => this.canPttUser(userId));
  };

  _writeState() {
    var teamData = OTGlobals.getTeamData(this._teamId);

    if (this.inPtt) {
      const streams = {};
      streams[this._myUserId] = { camera: this._myStream?.stream.id };

      teamData.pttState = {
        targets: this._currentTargets!,
        connected: this._connectedTargets!,
        streams,
      };
    } else {
      teamData.pttState = undefined;
    }
  }
}

interface IPTTCallbacks {
  onConnect?: (userId) => void;
  onClose?: (userId) => void;
  onError?: (userId, err) => void;
  onSignal?: (
    userId,
    data: ISignalData,
    callback?: (responseData: ITeamServerResponse) => void
  ) => void;
}

export class PTTPeer {
  peer: Peer;
  peerId: string;
  teamId: string;
  userId: string;
  pttType: PTTType;
  initiator: boolean;
  connected: boolean = false;
  outStreams: { [streamId: string]: StreamDetails } = {};
  inStreams: { [streamId: string]: InStreamDetails } = {};

  constructor(
    peerId: string | undefined,
    teamId: string,
    userId: string,
    pttType: PTTType | undefined,
    initiator: boolean,
    callbacks: IPTTCallbacks
  ) {
    logger.info("Creating peer for ", userId, "as initiator: ", initiator);
    this.peerId = peerId || uuid.v1();
    this.peer = new Peer({
      initiator: initiator,
      trickle: true,
      config: OTGlobals.config.RTCConfig,
    });
    this.teamId = teamId;
    this.userId = userId;
    this.pttType = pttType || "PTT_WALKIE";
    this.initiator = initiator;
    this.bindEvents(callbacks);
  }

  bindEvents = (callbacks: IPTTCallbacks) => {
    this.peer.on("error", (err) => {
      logger.info("error for", this.userId, err);
      if (err.code == "ERR_DATA_CHANNEL") {
        return;
      }
      if (callbacks.onError) {
        callbacks.onError(this.userId, err);
      }
    });

    this.peer.on("connect", () => {
      logger.info("Connection established ", this.userId);
      // wait for 'connect' event before using the data channel
      // peer.send('hey ' + userId + ', how is it going?')
      this.connected = true;
      if (callbacks.onConnect) {
        callbacks.onConnect(this.userId);
      }
      // Analytics.logEvent("peerconnection__peer_connected")
    });

    this.peer.on("close", () => {
      logger.info("Connection closed ", this.userId);
      if (callbacks.onClose) {
        callbacks.onClose(this.userId);
      }
      // Analytics.logEvent("peerconnection__peer_disconnected")
    });

    this.peer.on("data", (data) => {});

    this.peer.on("stream", (stream: MediaStream) => {
      logger.info("received stream from user: ", this.userId);
      logger.info(stream);
      this.registerStream(stream);
    });

    this.peer.on("track", (track: MediaStreamTrack, stream: MediaStream) => {
      logger.info(`received ${track.kind} track from user: ${this.userId}`);
      this.registerStream(stream);
      this.checkStream(stream.id);
    });

    this.peer.on("signal", (data) => {
      logger.debug(`generated signal for user: ${this.userId}, peerId: ${this.peerId}`, data);

      data = changeSDPCodec(data); // prefer h264

      if (callbacks.onSignal) {
        callbacks.onSignal(
          this.userId,
          {
            offer: JSON.stringify(data),
            fromInitiator: this.initiator,
            newConnection: !this.connected,
            pttType: this.pttType,
            peerId: this.peerId,
          },
          (responseData) => {
            logger.debug(`onSignal response data`, responseData);

            if (responseData.status == "ERROR") {
              this.shutdownStreams();
              this.destroy();
            }
          }
        );
      }
    });
  };

  registerStream = (stream: MediaStream) => {
    if (!this.inStreams[stream.id]) {
      logger.info("Adding stream", stream.id, "from", this.userId);
      const streamType = "camera";
      const inStream = new InStreamDetails(
        this.teamId,
        "ptt",
        this.userId,
        stream,
        streamType,
        this.pttType
      );
      this.inStreams[stream.id] = inStream;
      inStream.on("trackremoved", (track) => this.checkStream(stream.id));
    }
  };

  checkStream = (streamId: string, excTrack?: MediaStreamTrack) => {
    if (this.inStreams[streamId]) {
      if (this.inStreams[streamId].stream.active) {
        this.inStreams[streamId].checkTracks();
      }

      if (!this.inStreams[streamId].stream.active || this.inStreams[streamId].isEmpty()) {
        logger.info(
          "Removing inactive stream for",
          this.userId,
          this.inStreams[streamId],
          this.inStreams[streamId].isEmpty()
        );
        delete this.inStreams[streamId];
      }
    }
  };

  sendStream = (streamDetails: StreamDetails) => {
    if (this.connected && streamDetails) {
      if (!this.outStreams[streamDetails.stream.id]) {
        Object.values(this.outStreams)
          .filter((oStrm) => oStrm.streamType == streamDetails.streamType)
          .forEach((existingStream) => {
            logger.warn(`Found existing ${streamDetails.streamType} outstream cancelling`);
            this.cancelStream(existingStream);
          });

        this.outStreams[streamDetails.stream.id] = streamDetails;

        logger.info("Sending stream id", streamDetails.stream.id, " to ", this.userId);

        this.peer.addStream(streamDetails.stream);

        streamDetails.on("trackadded", (track, streamDetails) => {
          this.peer.addTrack(track, streamDetails.stream);
        });

        streamDetails.on("trackremoved", (track, streamDetails) => {
          this.peer.removeTrack(track, streamDetails.stream);
        });
      }
    } else {
      logger.info(
        "Unable to send stream",
        streamDetails.stream,
        "to peer",
        this.userId,
        ", connected",
        this.connected
      );
    }
  };

  cancelStream = (streamDetails: StreamDetails) => {
    if (this.outStreams[streamDetails.stream.id]) {
      logger.info(`Cancelling ${streamDetails.streamType} stream to ${this.userId}`);
      this.peer.removeStream(streamDetails.stream);
      delete this.outStreams[streamDetails.stream.id];
    }
  };

  shutdownStreams = () => {
    Object.values(this.outStreams).forEach((streamDetails) => {
      this.cancelStream(streamDetails);
    });
    // this.inStreams = {}
  };

  destroy = () => {
    if (this.peer) {
      this.peer.destroy();
      this.peer = undefined;
    }
  };
}

import { Feather } from '@expo/vector-icons';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Theme } from '../../globals/ThemeColors';
import { PlatformOS } from '../../utils/platform';
import { BadgeView } from '../BadgeUtil';
import { ConnectedHeadIcon } from '../ConnectedDot';
import Hoverable from "../Hoverable";
import { dismissModal, showModal } from '../ModalService';
import { OTButton } from '../buttons/OTButtons';
import { OTText } from '../OTText';
import { OTTouchableOpacity } from '..';
import { ChannelList } from './ChannelList';
import { CreateChat } from './CreateChat';
import HeaderDialog from '../headerDialog';
import HeadIcon from '../HeadIcon';
import { OTGlobals, OTUITree } from '@openteam/app-core';
import { CreateTopic } from './CreateTopic';
import { getStringColour } from '../../utils/OTUtils';
import { SKVideo } from '../SVGIcons';

interface IChatBarProps {
  teamId: string
  hideTitle?: boolean
  showTopics?: boolean
  additionalHeads?: JSX.Element[]
  goChannel?: (channelId, topicId) => void
  onClose?: () => void
}

interface IChatBarState {
}

@observer
export class ChatBar extends Component<IChatBarProps, IChatBarState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  showChannels = () => {
    showModal({
      contents: <HeaderDialog title={"Channels"} onClose={dismissModal}>
        <ChannelList teamId={this.props.teamId} />
      </HeaderDialog>,
    })

    this.props.onClose?.()
  }

  showCreateChat = () => {
    showModal({
      contents: <CreateChat teamId={this.props.teamId} />,
      showContainer: true,
      showClose: true
    })
    this.props.onClose?.()
  }


  render() {
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];
    return (
      <View style={{
        flex: 1,
        backgroundColor: Theme.OfficeBackground,
        padding: 10,
        borderRadius: Theme.curviness * 1.5,
        minWidth: 150
      }}>
        <ScrollView>
          {
            !this.props.hideTitle ?
              <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                <OTText style={{
                  fontWeight: '600', fontSize: 18
                }}>
                  Chat
                </OTText>
              </View>
              :
              null
          }

          <View style={{ marginBottom: 20 }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <OTText style={{
                fontWeight: '600', fontSize: 16, flex: 1
              }}>
                Channels
              </OTText>
              {
                PlatformOS != 'mobile' ?
                  <OTButton
                    small={true}
                    icon={<Feather name="plus" size={18} style={{ color: Theme.ButtonColor }} />}
                    onPress={this.showChannels}
                    backgroundColor={Theme.ButtonIconColor}
                  />
                  :
                  null
              }
            </View>

            {chatUIState.channelChats?.map(this.renderChat)}
          </View>

          <View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <OTText style={{
                fontWeight: '600', fontSize: 16, flex: 1
              }}>
                Direct
              </OTText>
              {
                PlatformOS != 'mobile' ?
                  <OTButton
                    small={true}
                    icon={<Feather name="plus" size={18} style={{ color: Theme.ButtonColor }} />}
                    onPress={this.showCreateChat}
                    backgroundColor={Theme.ButtonIconColor}
                  />
                  :
                  null
              }
            </View>

            {chatUIState.directChats?.map(this.renderChat)}
          </View>
        </ScrollView>
      </View>

    )
  }
  renderChat = (channelId) => {
    return <ChatBarChannel
      key={channelId}
      teamId={this.props.teamId}
      channelId={channelId}
      showTopics={this.props.showTopics}
      goChannel={this.props.goChannel}
    />

  }
}


interface IChatBarChannelProps {
  teamId: string
  channelId: string
  showTopics?: boolean
  goChannel?: (channelId, topicId) => void
}

interface IChatBarChannelState {
  hovered: boolean
}

@observer
export class ChatBarChannel extends Component<IChatBarChannelProps, IChatBarChannelState> {
  constructor(props) {
    super(props)

    this.state = {
      hovered: false
    }
  }

  showCreateTopic = () => {
    showModal({
      contents: <CreateTopic teamId={this.props.teamId} channelId={this.props.channelId }/>,
      showContainer: true,
      showClose: true
    })
  }


  render() {
    const { channelId, teamId } = this.props
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];
    const teamData = OTGlobals.getTeamData(teamId);

    const channel = chatUIState.getChannel(channelId);

    if (!channel) {
      return <View />
    }

    const isFocused = channelId == chatUIState.focusedChannelTopic?.channelId


    return (
      <View>
        <ChannelTopicItem teamId={teamId} channelId={channelId} topicId={"default"} goChannel={this.props.goChannel} />

        {
          teamData.capabilities.enableTopics ?
            <View style={{ marginLeft: 10 }}>
              {
                Object.keys(channel.topics || {}).map((topicId) => topicId != "default" ? <ChannelTopicItem key={`${teamId}-${channelId}-${topicId}`} teamId={teamId} channelId={channelId} topicId={topicId} forceShowTopic={this.props.showTopics} goChannel={this.props.goChannel} /> : null)
              }
              {
                isFocused && !this.props.showTopics ?
                  <OTTouchableOpacity onPress={this.showCreateTopic} style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingVertical: 2,
                    borderRadius: Theme.curviness / 2,
                    paddingHorizontal: 2,
                    margin: 3,
                    ...PlatformOS != 'mobile' ? { cursor: 'pointer', } as any : {},
                  }}>
                    <Feather name="plus" size={16} color={"black"} />
                    <View style={{ paddingLeft: 6, flex: 1, width: 100, flexDirection: 'row' }}>
                      <OTText style={{
                        flex: 1,
                        fontWeight: '500',
                        ...PlatformOS != 'mobile' ? { cursor: 'pointer', } : {},
                      }} numberOfLines={1}>
                        Add Topic
                  </OTText>
                    </View>
                  </OTTouchableOpacity>
                  :
                  null
              }
            </View>
            :
            null
        }

      </View>
    )
  }
}



interface IChannelTopicItemProps {
  teamId: string
  channelId: string
  topicId: string
  forceShowTopic?: boolean
  goChannel?: (channelId, topicId) => void
}

interface IChannelTopicItemState {
  hovered: boolean
}

@observer
export class ChannelTopicItem extends Component<IChannelTopicItemProps, IChannelTopicItemState> {
  constructor(props) {
    super(props)

    this.state = {
      hovered: false
    }
  }

  unbookmarkChat = () => {
    const { teamId, channelId, topicId } = this.props
    const chatUIState = OTUITree.chatUIStates[teamId]
    chatUIState.bookmarkChat(channelId, topicId, false)
  }

  goChannel = () => {
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];

    chatUIState.goChannel(this.props.channelId, this.props.topicId)

    this.props.goChannel?.(this.props.channelId, this.props.topicId)
  }

  getChannelDetails = () => {
    const { channelId, topicId, teamId } = this.props
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];
    const channel = chatUIState.getChannel(channelId);
    const teamData = OTGlobals.getTeamData(teamId);

    var users = (channel.userIds || []).filter(userId => userId != OTUITree.auth.userId)

    var userId: string | undefined = undefined
    if ((channel.chatType || 'chat') == 'chat' && users.length == 1) {
      userId = users[0]
    }
    var imageUrl = channel.imageUrl
    if (!imageUrl && userId) {
      var teamUser = teamData.getTeamUser(userId)
      imageUrl = teamUser.imageUrl
    }

    var name = channel.name
    if (!name) {
      name = users.map(userId => teamData.getTeamUser(userId).name).join(", ")
    }

    if (topicId != "default") {
      name = channel.topics[topicId].name
    }

    return {
      userId,
      name,
      imageUrl
    }
  }

  render() {
    const { channelId, topicId, teamId } = this.props
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];
    const teamData = OTGlobals.getTeamData(teamId)

    const channel = chatUIState.getChannel(channelId);
    const userChannel = chatUIState.getUserChannel(channelId);

    if (!channel) {
      return <View />
    }

    const rooms = Object.keys(teamData.rooms).filter(
      roomId => teamData.rooms[roomId].config?.channelId == channelId && teamData.rooms[roomId].config?.topicId == topicId)


    const { userId, name, imageUrl } = this.getChannelDetails()

    const inChannel = channelId == chatUIState.focusedChannelTopic?.channelId
    const isArchived = channel.topics?.[topicId]?.archived
    const numUnread = chatUIState.unreadCount[channelId]?.[topicId]
    const isMuted = userChannel.topics?.[topicId]?.muteNotify

    if (!this.props.forceShowTopic && (isArchived || (!inChannel && topicId != "default" && (!numUnread || isMuted)))) {
      return <View />
    }

    const isFocused = channelId == chatUIState.focusedChannelTopic?.channelId && chatUIState.focusedChannelTopic?.topicId == topicId


    return (
      <Hoverable
        onHoverIn={() => this.setState({ hovered: true })}
        onHoverOut={() => this.setState({ hovered: false })}>
        <OTTouchableOpacity onPress={this.goChannel}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            minHeight: 28,
            paddingVertical: 2,
            borderRadius: Theme.curviness / 2,
            backgroundColor: isFocused ? Theme.MainColour : this.state.hovered ? Theme.ButtonHighlightColor : undefined,
            paddingHorizontal: 2,
            margin: 3
          }} >
          <View >
            {
              topicId != "default" ?
                <Feather name="hash" size={16} color={getStringColour(name)} />
                :
                userId ?
                  <ConnectedHeadIcon size={24} teamId={this.props.teamId} userId={userId} />
                  :
                  <HeadIcon size={24} imageUrl={imageUrl} name={name!} />
            }

            <BadgeView badgeNum={numUnread} />
            {
              rooms.length > 0 ?
                <View style={{
                  position: "absolute",
                  bottom: -5,
                  right: -5,
                }}>
                  <SKVideo size={15} style={{
                    color: Theme.BadgeColor,
                  }} />
                </View>

                :
                null}

          </View>

          <View style={{ paddingLeft: 6, flex: 1, width: 100, flexDirection: 'row' }}>
            <OTText style={{
              flex: 1,
              fontWeight: numUnread ? 'bold' : '500',
              ...PlatformOS != 'mobile' ? { cursor: 'pointer', } : {},
              ...(isFocused && { color: this.state.hovered ? Theme.MainText : Theme.MainContrastColour })
            }} numberOfLines={1}>
              {name}
            </OTText>
            {
              this.state.hovered && (channel.chatType || 'chat') == 'chat' && topicId == 'default' ?
                <OTTouchableOpacity onPress={this.unbookmarkChat}>
                  <Feather name="x" size={18} style={{ color: Theme.HeaderIcon }} />
                </OTTouchableOpacity>
                :
                null
            }
          </View>

        </OTTouchableOpacity>
      </Hoverable>    )
  }
}
import React, { Component } from 'react';
import { OTText } from './OTText';
import { OTGlobals, OTUITree } from '@openteam/app-core';

interface IRoomTimerProps {
    teamId: string
    roomId: string
}

interface IRoomTimerState {
    started: number
    direction: string
    value: string
}

export default class RoomTimer extends Component<IRoomTimerProps, IRoomTimerState> {
    timer;

    constructor(props) {
        super(props)
        const { teamId, roomId } = this.props
        const teamData = OTGlobals.getTeamData(teamId);
        const room = teamData.rooms[roomId]
        let started;

        if (!room.config!.permanent) {
            started = room.config!.crDate ?? Date.now();
        } else {
            started = room.users[OTUITree.auth.userId].crDate ?? Date.now();
        }

        this.state = {
            started,
            direction: 'UP',
            value: ''
        }

        this.timer = setInterval(() => {
            this.generateValue();
        }, 1000)
    }

    componentWillUnmount () {
        clearInterval(this.timer);
    }

    generateValue = () => {
        const offset = Math.floor((Date.now() - this.state.started) / 1000);
        const hours = Math.floor(offset / 3600);
        const mins = Math.floor((offset / 60) % 60);
        const secs = Math.floor(offset % 60);

        let value;

        if (hours > 0) {
            value = `${hours.toString()}h ${mins.toString()}m`;
        } else if (mins > 1) {
            value = `${mins.toString()} mins`;
        } else {
            value = `${mins.toString()} min`;
        }
        
        if (this.state.value != value) {
            this.setState({ value })
        }
    }

    render () {
        return <OTText>{this.state.value}</OTText>;
    }
}
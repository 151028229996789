import React, { Component } from "react";
import { observer } from "mobx-react";
import { Logger } from '@openteam/app-util';

const logger = new Logger("PopoutButton")


interface PopoutButtonProps {
    teamId: string
    streamId: string
    style?: any
}

@observer
export default class PopoutButton extends Component<PopoutButtonProps, any> {
    render() {
        return null
    }
}
import React, { Component } from "react";
import { observer } from "mobx-react";
import { View, StyleSheet } from "react-native";
import { Theme } from "../../globals/ThemeColors";
import { OTTouchableOpacity } from "../../components";
import * as ModalService from '../../components/ModalService'
import { RoomSettings } from "../fragments/settings/RoomSettings";
import { RoomView } from "./RoomView";
import { OTText } from "../../components/OTText";
import { Feather } from '@expo/vector-icons';
import { Logger } from "@openteam/app-util";
import { OTGlobals } from "@openteam/app-core";

const logger = new Logger("RoomList");

interface IRoomListProps {
  teamId: string
  external?: boolean
  goToTop?: () => void
}

interface IRoomListState {

}

@observer
export class RoomList extends Component<IRoomListProps, IRoomListState> {

  showAddRoom = () => {
    ModalService.showModal(
      {
        contents: <RoomSettings onClose={ModalService.dismissModal} teamId={this.props.teamId} />,
        showContainer: true,
        showClose: true,
      }
    )
  }

  render() {
    const teamData = OTGlobals.getTeamData(this.props.teamId)
    const exclRooms = ['online', 'offline']

    if (teamData.currentRoomId && teamData.rooms[teamData.currentRoomId]?.config?.call) {
      exclRooms.push(teamData.currentRoomId)
    }



    const rooms = Object.keys(teamData.rooms).filter(
      roomId => !exclRooms.includes(roomId) && (this.props.external ? teamData.rooms[roomId].config?.external : !teamData.rooms[roomId].config?.external)
    ).sort((a, b) => (teamData.rooms[a].config?.static ? 0 : 1) - (teamData.rooms[b].config?.static ? 0 : 1))

    const activeRooms = rooms.filter(roomId => teamData.rooms[roomId].isActive)
    const inActiveRooms = rooms.filter(roomId => !teamData.rooms[roomId].isActive)

    const showAdd = !this.props.external

    if (rooms.length == 0 && !showAdd) {
      // nothing to display so don't
      return <View />
    }
    logger.debug("activeRooms: %o", activeRooms);
    logger.debug("inactiveRooms: %o", inActiveRooms);

    return (
      <View
        key={"roomList"}
        // @ts-expect-error dataSet not part of types
        dataSet={{ tutorial: `roomList-${this.props.external ? 'external' : 'internal'}` }}
        style={[styles.room, { backgroundColor: Theme.OfficeBackground }]}>
        <OTText style={styles.title}>{this.props.external && "External"} Rooms</OTText>
        <View style={styles.groupRooms}>
          {
            activeRooms.map(roomId => <RoomView key={roomId} roomId={roomId} teamId={this.props.teamId} goToTop={this.props.goToTop} />)
          }
        </View>
        <View style={styles.groupRooms}>

          {
            inActiveRooms.map(roomId => <RoomView key={roomId} roomId={roomId} teamId={this.props.teamId} goToTop={this.props.goToTop} />)
          }

          {showAdd ?
            <OTTouchableOpacity
              data-tip="Add new room"
              data-tutorial="addRoom"
              analyticsEvent="room_add_show"
              onPress={this.showAddRoom}
              style={[
                styles.addRoomButton,
                { backgroundColor: Theme.ButtonColor }
              ]}>
              <Feather name="plus" size={18} style={{ color: Theme.ButtonIconColor, paddingBottom: 20 }} />

              <OTText
                style={{
                  color: Theme.ButtonIconColor,
                  fontSize: 14,
                }}
              >
                New room
            </OTText>

            </OTTouchableOpacity>
            :
            null
          }
        </View>
      </View>
    )
  }
}



const styles = StyleSheet.create({
  room: {
    maxWidth: "100%",
    padding: 10,
    borderRadius: Theme.curviness * 1.5,
    marginTop: 10,
    marginHorizontal: 10,
  },
  groupRooms: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  title: {
    fontWeight: '600',
    fontSize: 18,
    paddingRight: 10
  },
  addRoomButton: {
    marginTop: 10,
    height: 250,
    width: 125,
    borderRadius: Theme.curviness,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
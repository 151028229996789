import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Switch } from 'react-native';
import { View, StyleSheet } from 'react-native';
import * as Analytics from '../../../utils/Analytics'
import { DialogView } from '../../../components/DialogView';
import { ITeamRoomConfig } from '@openteam/models';
import Select from 'react-select';
import * as ModalService from '../../../components/ModalService'
import { Theme } from '../../../globals/ThemeColors';
import { OTText, OTTextInput } from '../../../components/OTText';
import { ImageSelector } from '../../../components/ImageSelector';
import { OTButton } from '../../../components/buttons/OTButtons';
import { getRTDB } from '../../../globals/Fire/util';
import { FireDb, FireUtils, OTGlobals, OTUITree } from '@openteam/app-core';

const megaByte = 1048576
const maxUploadSize = 500000

interface IRoomSettingsProps {
  onClose: () => void
  teamId: string
  roomId?: string
}

interface IRoomSettingsState {
  uploading: boolean
  dropError?: string

  name: string
  desc: string
  imageUrl?: string
  call: boolean
  permanent: boolean
  external: boolean
  isLocked: boolean
  webcamOff: boolean
  micOff: boolean
  notify: boolean
  notifyTarget?: string
}

@observer
export class RoomSettings extends Component<IRoomSettingsProps, IRoomSettingsState> {
  constructor(props) {
    super(props);

    let teamData = OTGlobals.getTeamData(this.props.teamId)

    var roomSettings = this.props.roomId ? teamData.rooms[this.props.roomId].config : undefined

    this.state = {
      uploading: false,

      name: roomSettings?.name || "",
      desc: roomSettings?.desc || "",
      imageUrl: roomSettings?.imageUrl || undefined,
      call: roomSettings?.call ?? true,
      permanent: roomSettings?.permanent ?? true,
      external: roomSettings?.external ?? false,
      isLocked: roomSettings?.isLocked ?? false,
      webcamOff: roomSettings?.webcamOff || false,
      micOff: roomSettings?.micOff || false,
      notify: roomSettings?.notify || false,
      notifyTarget: roomSettings?.notifyTarget || undefined,
    }
  }

  setNotify = (tag) => {
    this.setState({
      notify: tag?.value != "__NOONE__",
      notifyTarget: tag?.value != "__NOONE__" ? tag?.value : undefined
    })
  }

  deleteRoom = () => {
    if (confirm(`Are you sure you want to delete this room?`)) {
      FireDb.closeRoom(getRTDB(), this.props.teamId, this.props.roomId!)
      this.props.onClose()
    }
  }

  onFiles = (e) => {
    var files = e.target.files
    var file = files[0]

    if (!file || file.size > maxUploadSize) {
      this.setState({ dropError: `Maximum file size is ${(maxUploadSize / megaByte).toFixed(2)}MB - Uploaded Size: ${(file.size / megaByte).toFixed(2)}MB` })
      return
    }
    this.setState({ uploading: true })
    FireUtils.uploadUserFile(
      this.props.teamId,
      OTUITree.auth.userId,
      'room_icon',
      file,
      (url) => {
        this.setState({ uploading: false, imageUrl: url })
      })
  }

  render() {
    let teamData = OTGlobals.getTeamData(this.props.teamId)
    const everyoneOption = { "value": undefined, "label": "Everyone Online" }
    const noOneOption = { "value": "__NOONE__", "label": "No One" }

    var subTeamOptions = [noOneOption, everyoneOption, ...Object.keys(teamData.subTeams).map((value) => ({ "value": value, "label": teamData.subTeams[value]?.name }))]

    var selectedNotifyTarget: { "value", "label" } = noOneOption
    if (this.state.notify == true) {

      if (this.state.notifyTarget == undefined) {
        selectedNotifyTarget = everyoneOption

      } else {
        selectedNotifyTarget = { "value": this.state.notifyTarget, "label": teamData.subTeams[this.state.notifyTarget]?.name }
      }
    }


    var isAdd = this.props.roomId == undefined

    return (
      <DialogView
        title={isAdd ? "Add new room" : "Room Settings"}
        footer={
          !isAdd && teamData.isAdmin ? <View style={{ flexDirection: 'row' }}><OTButton
            title="Delete Room"
            onPress={this.deleteRoom}
            backgroundColor={Theme.ButtonNegativeColor}
            textColor={Theme.ButtonNegativeIconColor}
          />
          </View>
            :
            undefined
        }
        buttons={[
          {
            title: this.props.roomId ? "Save" : "Add",
            onPress: this.saveSettings,
          }
        ]}>

        <View style={{ width: "100%" }}>


          <OTText style={[styles.itemTitle, { marginVertical: 10 }]}>Room Name</OTText>

          <OTTextInput style={{
            borderRadius: 7,
            borderWidth: 1,
            borderColor: Theme.InputHighlightColor,
            padding: 10,
            fontSize: 14,
            minWidth: 300
          }}
            value={this.state.name}
            onChangeText={(name) => this.setState({
              name: name
            })}
          />
          <OTText style={[styles.itemTitle, { marginVertical: 10 }]}>Description</OTText>

          <OTTextInput style={{
            borderRadius: 7,
            borderWidth: 1,
            borderColor: Theme.InputHighlightColor,
            padding: 10,
            fontSize: 14,
            minWidth: 300
          }}
            value={this.state.desc}
            onChangeText={(desc) => this.setState({
              desc: desc
            })}
          />
        </View>

        <View style={styles.itemContainer}>
          <OTText style={styles.itemTitle}>Room Image</OTText>
        </View>
        <ImageSelector
          width={140}
          height={100}
          loading={this.state.uploading}
          imageUrl={this.state.imageUrl}
          onFiles={this.onFiles}
        />
        {
          this.state.dropError ?

            <OTText style={{ color: 'red', textAlign: 'center', paddingBottom: 10, }}>
              {this.state.dropError}
            </OTText>
            : null
        }

        <View style={styles.itemContainer}>
          <OTText style={styles.itemTitle}>Delete room when empty</OTText>
          <Switch value={!this.state.permanent} onValueChange={(value) => this.setState({ permanent: !value })} />
        </View>
        <View style={styles.itemContainer}>
          <OTText style={styles.itemTitle}>Allow External Participants</OTText>
          <Switch value={this.state.external} onValueChange={(value) => this.setState({ external: value })} />
        </View>
        {
          isAdd ?
            null
            :
            <View style={styles.itemContainer}>
              <OTText style={styles.itemTitle}>Room Locked</OTText>
              <Switch value={this.state.isLocked} onValueChange={(value) => this.setState({ isLocked: value })} />
            </View>

        }


        <View style={styles.itemContainer}>
          <OTText style={styles.itemTitle}>Quiet (Disables audio/video)</OTText>
          <Switch value={!this.state.call} onValueChange={(value) => this.setState({ call: !value })} />
        </View>
        {
          this.state.call ?
            <>
              <View style={styles.itemContainer}>
                <OTText style={styles.itemTitle}>Default Webcam On</OTText>
                <Switch value={!this.state.webcamOff} onValueChange={(value) => this.setState({ webcamOff: !value })} />
              </View>

              <View style={styles.itemContainer}>
                <OTText style={styles.itemTitle}>Default Microphone On</OTText>
                <Switch value={!this.state.micOff} onValueChange={(value) => this.setState({ micOff: !value })} />
              </View>
            </>
            :
            null
        }

        <View style={styles.itemContainer}>
          <OTText style={styles.itemTitle}>Notify on enter</OTText>
        </View>
        <View style={styles.itemContainer}>
          <View style={{ flex: 1 }}>
            <Select
              value={selectedNotifyTarget}
              //styles={{
              //  control: (styles) => (
              //    {...styles,
              //      backgroundColor: "transparent",
              //      color: Theme.SecondaryText,
              //    }),
              //  option: styles => ({
              //      backgroundColor: Theme.RowHover,
              //      color: Theme.SecondaryText,
              //  }),
              //  placeholder: styles => (
              //    {
              //      color: Theme.MainText,
              //  }),
              //}}
              onChange={this.setNotify}
              options={subTeamOptions}
              placeholder="Select who to notify"
              isSearchable={false}
              isClearable={true}
              menuPortalTarget={document.getElementById("otbody")}
            />
          </View>
        </View>
      </DialogView>
    )
  }


  saveSettings = () => {

    if (!this.state.name) {
      return
    }

    var updateDoc: ITeamRoomConfig = {
      name: this.state.name,
      desc: this.state.desc,
      imageUrl: this.state.imageUrl || null,
      permanent: this.state.permanent,
      external: this.state.external,
      isLocked: this.state.isLocked,
      enabled: true,
      call: this.state.call,
      webcamOff: this.state.webcamOff,
      micOff: this.state.micOff,
      notify: this.state.notify,
      notifyTarget: this.state.notifyTarget || null,
    };

    if (this.props.roomId) {
      FireDb.updateRoomConfig(getRTDB(), this.props.teamId, this.props.roomId, updateDoc);
      Analytics.logEvent("room_settings_save", { roomId: this.props.roomId, name: this.state.name })

    } else {
      FireDb.createRoom(
        getRTDB(),
        this.props.teamId,
        OTUITree.auth.userId,
        OTUITree.auth.sessionToken,
        updateDoc
      );

      Analytics.logEvent("room_settings_add", { name: this.state.name })

    }

    this.props.onClose()
  }
}


export function showEditRoom(teamId, roomId) {
  ModalService.showModal(
    {
      contents: <RoomSettings
        onClose={ModalService.dismissModal}
        teamId={teamId}
        roomId={roomId} />,
      showContainer: true,
      showClose: true,
    }
  )
}

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 15,
    width: "100%"
  },
  itemTitle: {
    fontSize: 14,
    fontWeight: "600",
    flex: 1
  }
})

import React, { Component } from 'react';
import { View } from 'react-native';
import { Logger } from '@openteam/app-util';

const logger = new Logger("ReactAudio")

interface IReactAudioProps {
  stream?: MediaStream
  sinkId?: string
  muted: boolean
  volume?: number
}

interface IReactAudioState {
}

export class ReactAudio extends Component<IReactAudioProps, IReactAudioState> {
  audioRef = React.createRef<HTMLAudioElement>()


  componentDidMount = () => {
    this.setStream()
  }

  componentDidUpdate = (prevProps: IReactAudioProps) => {
    this.setStream(prevProps)
  }

  componentWillUnmount = () => {

    if (this.audioRef.current) {
      this.audioRef.current.srcObject = null
    }
  }

  setStream = (prevProps?: IReactAudioProps) => {

    if (this.audioRef.current) {
      if (this.props.muted) {
        this.audioRef.current.srcObject = null
      } else {
        if (this.audioRef.current.srcObject !== this.props.stream) {
          this.audioRef.current.srcObject = this.props.stream || null
        }
        // @ts-expect-error sinkId not part of types
        if (this.audioRef.current?.setSinkId && this.props.sinkId && this.audioRef.current.sinkId != this.props.sinkId) {
          // @ts-expect-error setSinkId not part of types
          this.audioRef.current?.setSinkId(this.props.sinkId)
        }
        if (this.audioRef.current.volume !== this.props.volume ?? 1) {
          logger.debug(`Setting volume to ${this.props.volume}`)
          this.audioRef.current.volume = this.props.volume ?? 1
        }
      }
    }
  }

  render() {
    return (
      <audio
        autoPlay
        playsInline
        muted={this.props.muted}
        ref={this.audioRef}
      />
    )
  }

}

import { IShortcutManager } from "models/src/win";


export class ShortcutManager implements IShortcutManager {
  constructor() {
    this.registerShortcuts();
  }

  registerShortcuts = () => {};

  unregisterShortcuts = () => {};
}

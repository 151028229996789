import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import { observable } from "mobx";
import { IFile, IMessageFile } from "@openteam/models";

export class WebFile implements IMessageFile {
  file: IFile;

  @observable progress = 100;
  @observable completed = true;
  @observable error?: string;
  @observable downloadUrl?: string;

  constructor(file: IFile) {
    this.file = file;
    this.downloadUrl = file.url
  }

  getPreviewURL = () => {
    return this.file.url
  }

  complete = async () => {};

  stop = () => {};
}

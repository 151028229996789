import React, { Component } from 'react';
import { IPluginConfig, PluginManager } from "@openteam/app-core";
import { IPluginResource } from "@openteam/models";
import { View } from 'react-native';
import { observer } from 'mobx-react';
import { InlineWeb } from '../InlineWeb';
import { WiteboardIcon } from '../SVGIcons';

interface IYoutubePluginDetails extends IPluginResource {
  args?: IWiteboardArgs
}

interface IWiteboardProps {
  pluginManager: PluginManager
  pluginDetails: IYoutubePluginDetails
}
interface IWiteboardState {
}

interface IWiteboardArgs {
  url?: string
}

@observer
export class Witeboard extends Component<IWiteboardProps, IWiteboardState> {
  player: any
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  _iframe
  render() {
    if (!this.props.pluginDetails.args?.url) {
      return <View />
    }
    return (
      <InlineWeb
        ref={r => this._iframe = r}
        // @ts-expect-error border not part of types
        style={{ width: "100%", height: "100%", border: 0, background: 'white' }}
        src={this.props.pluginDetails.args.url} />
    )
  }
}

function setupPlugin(pluginManager: PluginManager) {
  var witeboardID = pluginManager.generateId()

  var args = {
    url: `https://witeboard.com/OT${witeboardID}`
  }

  pluginManager.createPlugin(pluginType, args)
}

export const pluginType = 'witeboard'
export const pluginConfig: IPluginConfig = {
  name: 'Witeboard',
  multi: false,
  setupFunc: setupPlugin,
  component: Witeboard,
  icon: WiteboardIcon,
  iconColour: '#AFC1D7',
  webInline: true,
  canPopout: true,
}
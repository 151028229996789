import React, { Component } from 'react';
import { View } from 'react-native';
import { HeaderDialog, OTButton, OTText, OTTextInput } from '../../components';
import { FontAwesome } from '@expo/vector-icons';
import { Theme } from '../../globals/ThemeColors';
import { OTTouchableOpacity } from '../../components/buttons/OTTouchable';
import { dismissModal, showModal } from '../../components/ModalService';
import { getRTDB } from '../../globals/Fire/util';
import { FeedbackDb } from '@openteam/app-core';
import { PlatformOS } from '../../utils/platform';


interface ICallFeedbackProps {
  teamId: string,
  userId: string,
  roomId: string,
  startTime: number,
  endTime: number,
}

interface ICallFeedbackState {
  rating: number
  comment: string
}

export class CallFeedback extends Component<ICallFeedbackProps, ICallFeedbackState> {
  constructor(props) {
    super(props);

    this.state = {
      rating: 0,
      comment: ""
    }
  }

  doCallFeedback = async () => {
    if (!this.state.rating) {
      return
    }
    await FeedbackDb.addCallFeedback(
      getRTDB(),
      this.props.teamId,
      this.props.userId,
      this.props.roomId,
      this.props.startTime,
      this.props.endTime,
      this.state.rating,
      this.state.comment
    )

    dismissModal()
  }

  stars = [1,2,3,4,5]
  render() {
    return (
      <View style={{padding: 30, paddingVertical: 20}}>
        <OTText>
          Please rate the quality of your call
        </OTText>
        <View style={{ flexDirection: 'row', marginVertical: 5}}>
          {
            this.stars.map((starNum) => this.renderStar(starNum))
          }
        </View>

        <OTText>
          Tell us more
        </OTText>

        <OTTextInput
          style={{
            width: undefined,
            flex: 1,
            minHeight: 100,
            textAlignVertical: 'top',
            marginVertical: 10
          }}
          multiline={true}
          value={this.state.comment}
          onChangeText={(text) => this.setState({ comment: text })}
          placeholder=""
        />
        <View style={{ alignItems: 'flex-end', paddingTop: 10, }}>
          <OTButton small={true} title={"Submit"} onPress={this.doCallFeedback} />
        </View>

      </View>
    )
  }

  renderStar = (starNum: number) => {
    let iconSize = 48
    if (PlatformOS == 'mobile') {
      iconSize = 36
    }

    return (
      <OTTouchableOpacity key={`star-${starNum}`} onPress={() => this.setState({
        rating: starNum
      })}
        style={{ padding: 15 }}>
        {
          starNum <= this.state.rating ?
            <FontAwesome name="star" size={iconSize} color={Theme.ButtonColor} />
            :
            <FontAwesome name="star-o" size={iconSize} color={Theme.ButtonColor} />
        }


      </OTTouchableOpacity>
    )
  }

}

export function showCallFeedback(
  teamId: string,
  userId: string,
  roomId: string,
  startTime: number,
  endTime: number,
) {
  showModal({
    contents:
      <HeaderDialog title={"Give us feedback "} onClose={dismissModal} outerStyle={{ width: undefined, height: undefined }}>
      <CallFeedback teamId={teamId} userId={userId} roomId={roomId} startTime={startTime} endTime={endTime} />
    </HeaderDialog>,
  })
}
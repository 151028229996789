import events from 'events';
import { Platform } from 'react-native';
import * as Fire from '../../globals/Fire';
import messaging from '@react-native-firebase/messaging';
import { OTUITree, registerCall, respondToCall } from '@openteam/app-core';
import RNVoipCall, { RNVoipPushKit } from 'react-native-voip-call';
import { Logger } from '@openteam/app-util';
import { getRTDB } from '../../globals/Fire/util';

const logger = new Logger("PushNotify")

export class PushNotifyManager extends events.EventEmitter {

  constructor() {
    super()
  }

  init = async () => {
    logger.debug('init push notify');
    await this.checkPermission()

    this.createNotificationListeners()
  }

  uninit = () => { }

  checkPermission = async () => {
    const authStatus = await messaging().hasPermission();

    const enabled = authStatus === messaging.AuthorizationStatus.AUTHORIZED ||  authStatus === messaging.AuthorizationStatus.PROVISIONAL
    logger.debug('checking if i Have permission to send push notifications', enabled);

    // If Premission granted proceed towards token fetch
    if (enabled) {
      this.getToken();

    } else {
      // If permission hasn’t been granted to our app, request user in requestPermission method.
      this.requestPermission();
    }
  }

  requestPermission = async () => {
    try {
      logger.debug('requestion permission for push notify');
      const authStatus = await messaging().requestPermission();

      const enabled = authStatus === messaging.AuthorizationStatus.AUTHORIZED ||  authStatus === messaging.AuthorizationStatus.PROVISIONAL

      // User has authorised
      this.getToken();
    } catch (error) {
      // User has rejected permissions
      logger.debug('permission rejected');
    }
  }

  getToken = async () => {
    logger.debug('getting token');


    var fcmToken = await messaging().getToken();
    if (fcmToken) {
      logger.debug("got FCM token!", fcmToken)
      // user has a device token
      this.setPushToken(fcmToken)
    }

    if (Platform.OS == 'ios') {
      RNVoipPushKit.getPushKitDeviceToken((res) => {
        if (res.platform === 'ios') {
          this.setAPNSToken(res.deviceToken)
        }
      });

    }
  }



  createNotificationListeners = async () => {

    // This listener triggered when notification has been received in foreground
    var notificationListener = messaging().onMessage((notification) => {
      logger.debug(notification);//#

      if (notification.data?.type) {
        this.emit('notification', notification)

      }
    });

    // This listener triggered when app is in backgound and we click, tapped and opened notifiaction
    var notificationOpenedListener = messaging().onNotificationOpenedApp((notification) => {
      // showToast(notification.notification?.title, 'info', notification.notification?.body);
      logger.debug("notificationOpenedListener", notification)
      this.emit('notificationOpen', notification)

    });

    // This listener triggered when app is closed and we click,tapped and opened notification
    const notificationOpen = await messaging().getInitialNotification().then(notification => {
      if (notification) {
        // showToast(notification.notification?.title, 'info', notification.notification?.body);
        this.emit('notificationOpen', notification)
        logger.debug("notificationOpen", notification)

      }
    })

    RNVoipPushKit.RemotePushKitNotificationReceived((notification) => {

      logger.debug(notification);//#
      handleCall(notification)
    });
  }

  setPushToken = (token) => {
    Fire.setNotificationToken('fcm', token)
  }

  setAPNSToken = (apnsToken) => {
    Fire.setAPNSToken(apnsToken)
  }

}


export function initVoipCall() {

  let options = {
    appName: 'OpenTeam', // Required
    imageName: 'Appicon',  //string (optional) in ios Resource Folder
    ringtoneSound: '', //string (optional) If provided, it will be played when incoming calls received
    includesCallsInRecents: false, // boolean (optional) If provided, calls will be shown in the recent calls
    supportsVideo: true //boolean (optional) If provided, whether or not the application supports video calling (Default: true)
  }
  // Initlize Call Kit IOS is Required
  RNVoipCall.initializeCall(options).then(() => {
    //Success Call Back

    logger.debug("initializeCall succeeded")
  }).catch(e => logger.debug(e));

  messaging().setBackgroundMessageHandler(handleCall)
}


async function handleCall(remoteMessage) {
  logger.debug('Message handled in the background!', remoteMessage);

  if (Platform.OS === 'android') {
    let data;

    if (remoteMessage.data) {
      data = remoteMessage.data;
    }

    if (data && data.type === 'call' && data.callId) {
      let callOptions = {
        callerId: data.callId, // Important uuid must in this format

        ios: {
          phoneNumber: '12344', // Caller Mobile Number
          name: data.name, // caller Name
          hasVideo: true
        },
        android: {
          ringtuneSound: true, // defualt true
          ringtune: 'ringtune', // add file inside Project_folder/android/app/res/raw --Formats--> mp3,wav
          duration: 120000, // defualt 30000
          vibration: true, // defualt is true
          channel_name: 'call', //
          notificationId: 1123,
          notificationTitle: 'Call Incoming',
          notificationBody: data.name + ' is Calling...',
          // answerActionTitle: 'Answer',
          // declineActionTitle: 'Decline',
        }
      }

      registerCall(getRTDB(), data.callId, data.teamId, data.userId, () => endCall(data.callId))

      RNVoipCall.displayIncomingCall(callOptions).then((data) => {
        logger.debug("displayIncomingCall", data)
      }).catch(e => logger.debug(e))
    }
  }
}

const getCallRequestUIState = (teamId: string) => OTUITree.callRequestUIStates[teamId];

RNVoipCall.onCallAnswer(data => {
  logger.debug("call answered", data)
  // RNVoipCall.endCall(data.callerId);

  respondToCall(data.callerId, "accepted", getCallRequestUIState);
  // Fire.respondToCall(this.teamId, OTUITree.auth.userId, response)

});


RNVoipCall.addEventListener('didDisplayIncomingCall', ({ error, callUUID, handle, localizedCallerName, hasVideo, fromPushKit, payload }) => {
  logger.debug("didDisplayIncomingCall", payload)

  registerCall(getRTDB(), callUUID, payload.teamId, payload.userId, () => endCall(callUUID))

});

RNVoipCall.onEndCall(data => {

  logger.debug("call ended", data)
  respondToCall(data.callerId, "rejected", getCallRequestUIState);


});


export function endCall(callId) {
  RNVoipCall.endCall(callId)
  // RNVoipCall.endAllCalls();
}
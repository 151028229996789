import { StackActions, CommonActions} from "@react-navigation/native";
import { Logger } from "@openteam/app-util";

import React, { Component } from "react";

const logger = new Logger("NavigationService");

export const navigationRef: any = React.createRef();

export function navigate(name: string, params: any) {
    // navigationRef.current?.dispatch(StackActions.push(name, params));
  const orderedParams = Object.keys(params || {}).sort().map(key => `${key}:${params[key]}`).join("-")

  const key = `${name}-${orderedParams}`
  logger.info("navigating to", key)
  navigationRef.current?.dispatch(CommonActions.navigate({
    name,
    params,
    key: key
  })
);


  }
import React, { Component } from "react";
import { observer } from "mobx-react";
import { View, ImageBackground, StyleSheet, Text } from "react-native";
import * as StyleConstants from '../../globals/StyleConstants';
import { getStringColour } from "../../utils/OTUtils";
import { LinearGradient } from 'expo-linear-gradient';
import * as Analytics from '../../utils/Analytics'

import { Logger } from '@openteam/app-util';
import { SKKnock, SKVideo, SKMessage, SKMic, SKCamera, SKMegaphone, SKMicOff } from "../../components/SVGIcons";
import { OTTouchableOpacity } from "../../components";
import { Theme } from "../../globals/ThemeColors";
import Volume from "./Volume";
import ConnectedDot from "../../components/ConnectedDot";
import { OTText } from "../../components/OTText";
import { SelfieButton } from "./Selfie";
import { BadgeView } from "../../components/BadgeUtil";
import UserAlertBadges from "../UserAlertBadges";
import { Feather } from '@expo/vector-icons';
import { FileDrop } from "../FileDrop";
import { Draggable } from "../../components/Droppable";
import { OTGlobals, OTUITree } from "@openteam/app-core";
import { IOTRoomUser, IOTStream } from "@openteam/models";

const logger = new Logger("OnlineTile")

interface IOnlineUserProps {
  id: string
  teamId: string
  roomId?: string
  userId: string
  roomUser: IOTRoomUser
  streamId?: string
  height: number
  width: number
}

interface IOnlineUserState {
  color: string
  pushTalk: boolean
  speaking: boolean
}

@observer
export class OnlineUser extends Component<IOnlineUserProps, IOnlineUserState>  {

  pttStartTime?: number;
  pttTarget?: string;

  constructor(props) {
    super(props);

    const user = this.props.roomUser

    if (user.online) {
      logger.debug(`Creating OnlineUser for ${user.name}, key ${props.id}`)
    }

    this.state = {
      color: getStringColour(user.name),
      pushTalk: false,
      speaking: false,
    }
  }


  onPressInAudio = async () => {
    var teamManager = OTUITree.teamManagers[this.props.teamId]
    await teamManager.startPtt(undefined, undefined, this.props.userId)
    this.pttStartTime = Date.now()
    this.pttTarget = this.props.userId
  }

  onPressOutAudio = async () => {
    var teamManager = OTUITree.teamManagers[this.props.teamId];
    await teamManager.stopPtt()

    if (this.pttStartTime) {
      Analytics.logEvent('ptt', {
        duration_secs: (Date.now() - this.pttStartTime) / 1000,
        ptt_target: this.pttTarget
      })
      this.pttStartTime = undefined;
      this.pttTarget = undefined
    }
  }

  knockUser = () => {
    var teamManager = OTUITree.teamManagers[this.props.teamId];

    const user = this.props.roomUser

    var failsSilently = !user.online && user.hasMobile
    teamManager.knockUser(this.props.userId, failsSilently)
  }

  callUser = () => {
    logger.info("callUser on")
    const callRequestUIState = OTUITree.callRequestUIStates[this.props.teamId];
    callRequestUIState.sendCallUser(this.props.userId);
  }

  goChat = () => {
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];
    chatUIState?.goDirectChannel(this.props.userId);
    Analytics.logEvent('go_user_chat')
  }

/*   createNote = () => {
    var teamManager = OTUITree.teamManagers[this.props.teamId]
    teamManager.noteManager?.showCreateNote(this.props.userId)
    Analytics.logEvent('go_create_user_note')
  }
 */

  uploadFileUser = async (userId, files) => {
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];

    const channelId = await chatUIState.startChannel([userId]);

    chatUIState.goChannel(channelId, "default");

    chatUIState.addDraftFiles(channelId, "default", files);
  }

  render() {
    const { userId, teamId, roomId, streamId } = this.props;

    const teamData = OTGlobals.getTeamData(this.props.teamId);

    const streamInfo: IOTStream | undefined = streamId ? teamData.streams[streamId] : undefined;

    var isPttSource = streamId;
    const user = this.props.roomUser

    var isBig = this.props.width >= 100
    const isMe = this.props.userId == OTUITree.auth.userId
    var curviness = isBig? Theme.curviness : Theme.curviness/2


    var disableDrag = false

    var isPttTarget = teamData.pttState && teamData.pttState.targets.includes(userId)

    if (isPttTarget || !(user.online || user.hasMobile)) {
      disableDrag = true
    }

    return (
      <Draggable
        disableDrag={disableDrag}
        onDragStart={(e) => {
        e.dataTransfer.setData("userid", userId);
        e.dataTransfer.effectAllowed = "grabbing";
      }}>
      <View
      // @ts-expect-error dataSet not part of types
      dataSet={{
        tip: user.name,
        tutorial: `onlineUser-${isMe ? "me" : "other"}`
      }}
      style={[styles.tile, {borderRadius: curviness}]}>

        <ImageBackground
          source={{ uri: user.imageUrl || undefined }}
          style={[styles.backgroundImage, {
            backgroundColor: this.state.color,
            width: this.props.width || 173,
            height: this.props.height || 152,
            borderRadius: curviness
          }]}
          imageStyle={{
            borderRadius: curviness
          }}>
          <LinearGradient
            colors={['transparent', 'transparent', 'transparent', 'rgba(0,0,0,0.3)',]}
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              borderRadius: curviness
            }}
          />
            {
              this.renderPTTOverlay()
            }

            {
              isBig ?
                <>
                  <View style={{ position: 'absolute', left: 10, top: 10 }}>
                    <ConnectedDot userId={userId} roomId={roomId} teamId={teamId} />
                  </View>
                  {this.renderHeader()}
                </>
                :
                null
            }


            <View style={{ flex: 1, justifyContent: "flex-end" }}>
              <UserAlertBadges userId={userId} teamId={teamId} />
              {
                isPttSource
                  ? <Volume streamInfo={streamInfo} />
                  : null
              }
            </View>
            {
              this.renderUserDetails()
            }

            {
              this.renderCallingOverlay()
            }
          </ImageBackground>
        <FileDrop
          onDrop={(files) => files && this.uploadFileUser(userId, files)}
          dropEffect={'copy'}
          renderCover={(overFrame, overTarget) => {
            return overFrame && userId != OTUITree.auth.userId ?
              <View
                pointerEvents="none"
                style={{
                  ...StyleSheet.absoluteFillObject,
                  backgroundColor: 'rgba(255,255,255,0.7)',
                  borderWidth: 2,
                  borderColor: 'grey',
                  borderStyle: 'dotted',
                  borderRadius: curviness,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                {
                  overTarget ?
                    <Feather name="upload" pointerEvents={"none"} size={40} style={{ color: overTarget ? 'red' : 'grey' }} />
                    :
                    null
                }
              </View>
              :
              null
          }}
        >
        </FileDrop>
      </View>
      </Draggable>

    )
  }
  renderHeader = () => {
    const { userId, streamId } = this.props;


    const teamManager = OTUITree.teamManagers[this.props.teamId];
    const teamData = teamManager.teamData;

    let connected = teamManager.teamConnection ? teamManager.teamConnection.connected : true

    var isPttTarget = teamData.pttState && teamData.pttState.targets.includes(userId)
    var isPttConnected = teamData.pttState && teamData.pttState.connected.includes(userId)

    const user = this.props.roomUser
    const teamUser = teamData.getTeamUser(userId)
    const canPtt = teamUser?.canPtt && connected;

    return (
      <View style={styles.userHeaderHolder}>
        <View style={{ flex: 1 }} />
        {
          this.props.userId != OTUITree.auth.userId ?
            canPtt ?
              <OTTouchableOpacity
                activeOpacity={0.9}
                data-tip={`Push to talk to ${user.name}`}
                onPressIn={canPtt ? this.onPressInAudio : undefined}
                onPressOut={canPtt ? this.onPressOutAudio : undefined}
                style={styles.userWalkieTalkieButton}>
                <SKMic size={18} style={{ color: isPttTarget ? isPttConnected ? Theme.ButtonCapturingColor : Theme.ButtonDisabledColor : Theme.ButtonColor }} />
              </OTTouchableOpacity>
              :
              teamUser?.inBackground ?
                <View
                  data-tip={`Cannot PTT as ${user.name} is using another space`}
                  style={styles.userWalkieTalkieButton}>
                  <SKMicOff size={18} style={{ color: Theme.ButtonDisabledColor }} />
                </View>
                :
                null
            :
            null
        }
      </View>
    )
  }

  renderPTTOverlay = () => {
    const { userId, streamId, teamId } = this.props;
    const teamData = OTGlobals.getTeamData(teamId)

    var isPttSource = streamId;
    var isPttTarget = teamData.pttState && teamData.pttState.targets.includes(userId)
    var isPttConnected = teamData.pttState && teamData.pttState.connected.includes(userId)

    if (userId != OTUITree.auth.userId && isPttSource) {
      return (
        <View style={{ ...StyleSheet.absoluteFillObject, backgroundColor: 'rgba(0,0,0,0.5)', justifyContent: 'center', alignItems: 'center', borderRadius: Theme.curviness }}>
          {/* {
            isPttTarget ?
              <SKMic
                size={(this.props.width || 160) /4}
                style={{ color: isPttConnected ? StyleConstants.RecordingColour : StyleConstants.ConnectingColour }}
              />
              : null
          } */}
          {
            isPttSource
              ? <SKMegaphone size={(this.props.width || 160) /4} style={{ color: StyleConstants.RecordingColour }} />
              : null
          }
        </View>
      )
    }

  }

  renderCallingOverlay = () => {
    const { userId } = this.props;
    const callRequestUIState = OTUITree.callRequestUIStates[this.props.teamId];
    const isCalling = callRequestUIState.isCalling(userId);

    if (userId != OTUITree.auth.userId && isCalling) {
      return (
        <OTTouchableOpacity
        data-tip="Cancel Call"
        onPress={this.callUser}
        style={{
          ...StyleSheet.absoluteFillObject,
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: Theme.curviness }}>
            <SKVideo size={Math.max((this.props.width || 160) /4, 14)} style={{color: isCalling ? 'red' : 'white'}} />
        </OTTouchableOpacity>
      )
    }

  }


  renderUserDetails = () => {
    const { userId, roomId } = this.props;
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];
    const callRequestUIState = OTUITree.callRequestUIStates[this.props.teamId];
    const teamManager = OTUITree.teamManagers[this.props.teamId]
    let connected = teamManager.teamConnection ? teamManager.teamConnection.connected : true

    const teamData = teamManager.teamData;
    const isCalling = userId in callRequestUIState.callingUsers;
    const user = this.props.roomUser

    const room = roomId ? teamData.rooms[roomId] : undefined

    const inCall = room?.config ? room.config.call : false;
    var isBig = this.props.width >= 100

    const channelId = chatUIState?.getUserChatByUser(userId)?.id;

    const numUnread = chatUIState.unreadCount[channelId]?.["default"];


    if (this.props.userId == OTUITree.auth.userId) {
      return (
        <View style={styles.userDetailsHolder}>
          <OTText style={styles.username} numberOfLines={1}>{user.name} (Me)</OTText>
          <SelfieButton teamId={this.props.teamId} />

        </View>
      )
    }

    return (
      <View style={styles.userDetailsHolder}>
        {
          isBig?
          <OTText style={styles.username} numberOfLines={1}>{user.name}</OTText>
          :
          null
        }
        <View style={{ flex: 1 }} />
        {
          (user.online || user.hasMobile) && !inCall && connected?
            <OTTouchableOpacity
              data-tip="Knock on user to see if they are there"
              data-tutorial="teamUserKnock"
              onPress={this.knockUser}
              style={[styles.userIconButton, { marginTop: -5 }]}>
              <SKKnock size={20} style={{color: 'white'}}/>
            </OTTouchableOpacity>
            :
            null
        }
        {
          (user.online || user.hasMobile) && (isCalling || !inCall)?
            <OTTouchableOpacity
              data-tip="Call user into meeting"
              data-tutorial="teamUserPhone"
              onPress={this.callUser}
              style={styles.userIconButton}>
              <SKVideo size={17} style={{color: isCalling ? 'red' : 'white'}} />
            </OTTouchableOpacity>
            :
            null
        }

        <OTTouchableOpacity
          data-tip="Message user"
          data-tutorial="teamUserMessage"
          onPress={this.goChat}
          style={styles.userIconButton}>
          <SKMessage size={14} style={{color: 'white'}}/>
          <BadgeView badgeNum={numUnread} />

        </OTTouchableOpacity>

 {/*        {
          teamData.capabilities.stickyNotes ?
            <OTTouchableOpacity
              data-tip="Leave user note"
              data-tutorial="teamUserNote"
              onPress={this.createNote}
              style={styles.userIconButton}>
              <Feather name="square" size={14} style={{ color: 'white' }} />

            </OTTouchableOpacity>
            :
            null
        } */}

      </View>
    )
  }
}


const styles = StyleSheet.create({
  tile: {
    margin: 5,
    borderRadius: Theme.curviness,
    overflow: 'hidden'
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: StyleConstants.TileTitleBackground
  },
  backgroundImage: {

    borderRadius: Theme.curviness,
  },
  userHeaderHolder: {
    marginTop: 5,
    marginHorizontal: 5,
    flexDirection: 'row',
    alignItems: 'center'
  },
  userWalkieTalkieButton: {
    borderRadius: Theme.curviness / 1.5,
    backgroundColor: Theme.ButtonIconColor,
    alignItems: 'center',
    justifyContent: 'center',
    height: 25,
    width: 25,
  },
  username: {
    marginLeft: 3,
    color: 'white',
    fontWeight: '500',
    fontSize: 14,
  },
  userDetailsHolder: {
    marginHorizontal: 5,
    marginBottom: 7,
    flexDirection: 'row',
    alignItems: 'center'
  },
  userIconButton: {
    height: 17,
    width: 17,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 4,
  },

})
import React, { Component } from 'react';
import { StyleSheet, Text, ViewStyle, TextStyle, View, ActivityIndicator } from 'react-native';
import * as StyleConstants from '../../globals/StyleConstants';
import { OTTouchableOpacity } from './OTTouchable';
import { Theme } from '../../globals/ThemeColors';
import { OTText } from '../OTText';
import { PlatformOS } from '../../utils/platform';


export interface IOTButtonProps {
  title?: string
  icon?: JSX.Element
  rightIcon?: JSX.Element
  backgroundColor?: string
  textColor?: string
  borderColor?: string
  fontSize?: number
  small?: boolean
  href?: string
  onPress?: () => void
  onPressIn?: () => void
  onPressOut?: () => void
  disabled?: boolean
  loading?: boolean
  showShadow?: boolean
  analyticsEvent?: string
  toolTip?: string
  textStyle?: TextStyle
  outerStyle?: ViewStyle
  outlined?: boolean
}

interface IOTButtonState {
}

export class OTButton extends Component<IOTButtonProps, IOTButtonState> {
  _small: boolean

  constructor(props) {
    super(props)
    this._small = props.small || false
  }

  render() {
    const { outlined, backgroundColor, textColor, borderColor, showShadow, toolTip, small, href, fontSize, textStyle } = this.props;

    const buttonStyles: any[] = [styles.button]
    small ? buttonStyles.push(styles.small) : null;

    if (outlined) {
      buttonStyles.push({
        backgroundColor: 'transparent',
        borderColor: backgroundColor ?? Theme.ButtonColor,
      })
    } else {
      buttonStyles.push({
        backgroundColor: backgroundColor ?? Theme.PrimaryColour,
      })
    }

    borderColor ? buttonStyles.push({ borderColor, borderWidth: 1 }) : null
    showShadow ? buttonStyles.push(StyleConstants.LightShadow) : null
    //backgroundColor ? buttonStyles.push({ backgroundColor }) : null

    if (href) {
      return (
        <View
          // data-tip={toolTip}
          // @ts-expect-error dataSet not part of types
          dataSet={{ tip: toolTip }}
          accessibilityRole="link"
          target="_blank"
          href={href}
          style={[...buttonStyles, this.props.outerStyle]}>
          {this.renderChildren()}
        </View>
      )
    } else {
      return (
        <OTTouchableOpacity
          // data-tip={toolTip}
          dataSet={{ tip: toolTip }}
          style={[buttonStyles, this.props.outerStyle]}
          onPress={!this.props.disabled ? this.props.onPress : undefined}
          onPressIn={this.props.onPressIn}
          onPressOut={this.props.onPressOut}
          disabled={this.props.disabled}
          analyticsEvent={this.props.analyticsEvent}>
          {this.renderChildren()}
        </OTTouchableOpacity>
      )
    }
  }

  renderChildren = () => {
    const { textColor, fontSize, textStyle } = this.props;

    const color = textColor || Theme.ButtonIconColor;
    if (this.props.loading) {
      return <ActivityIndicator color={color} />
    }
    return (
      <>
        {
          this.props.icon
        }
        <OTText style={[styles.text, textStyle,
        {
          color,
          fontSize,
          ...PlatformOS != 'mobile' ? { cursor: 'pointer', } : {},
        }]} numberOfLines={1}>
          {this.props.title}
        </OTText>
        {
          this.props.rightIcon
        }
      </>
    )
  }
}

export function OTButtonSmall(props) {
  return (
    <OTButton
      small={true}
      {...props}
    />
  )
}

export function OTPrimaryButton(props) {
  return (
    <OTButton
      {...props}
      backgroundColor={props.backgroundColor || "transparent"}
      textColor={props.textColor || Theme.ButtonColor}
      borderColor={props.borderColor || Theme.ButtonColor}
    />
  )
}

export function OTCancelButton(props) {
  return (
    <OTButton
      {...props}
      title={props.title || "Cancel"}
      backgroundColor={props.backgroundColor || StyleConstants.SecondaryColour}
    />
  )
}


export class OTRoomHeaderButton extends Component<IOTButtonProps, IOTButtonState> {

  render() {
    return (
      <OTTouchableOpacity
        data-tip={this.props.toolTip}
        onPress={this.props.onPress}
        disabled={this.props.disabled}
        style={{
          paddingVertical: 5,
          paddingHorizontal: 10,
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: StyleConstants.VideoButtonColour,
          borderRadius: Theme.curviness,
          ...PlatformOS != 'mobile' ? { cursor: 'pointer', } : {},
          ...this.props.outerStyle
        }}>
        {
          this.props.icon
        }
        <OTText style={{
          fontWeight: "600", color: Theme.OnPrimaryColour,
          ...PlatformOS != 'mobile' ? { cursor: 'pointer', } : {},
        }}>
          {this.props.title}
        </OTText>
        {
          this.props.rightIcon
        }
      </OTTouchableOpacity>
    )
  }

}



const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    flex: 1,
    paddingHorizontal: 10,
    borderRadius: Theme.curviness,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 150,
    height: 40,
  },
  small: {
    flex: undefined,
    minWidth: undefined,
    borderRadius: Theme.curviness / 1.5,
    height: 30,
    paddingHorizontal: 10,
  },
  text: {
    fontWeight: "500"
  },
})

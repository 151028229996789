import React, { Component } from "react";
import { View } from "react-native";


interface IWidgetProps {
  teamId: string
}
interface IWidgetState {}

export class Widget extends Component<IWidgetProps, IWidgetState> {


  render() {
    return (
     <View />
    )
  }
}


import { observer } from "mobx-react";
import React, { Component } from "react";
import { View } from "react-native";
import HeadIcon from "../HeadIcon";
import { OTText } from "../OTText";
import { dismissModal } from "../ModalService";
import { DialogView } from "../DialogView";
import Select, { components } from "react-select";
import SettingsRow from "../settingsRow";
import { IOption } from "@openteam/models";
import { OTGlobals, OTUITree } from "@openteam/app-core";

export const ImageOption = (props) => (
  <components.Option {...props}>
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <HeadIcon size={30} imageUrl={props.data.imageUrl} name={props.data.label} />
      <OTText style={{ marginLeft: 10, fontSize: 14, flex: 1, fontWeight: "600", color: "#555" }}>
        {props.data.label}
      </OTText>
    </View>
  </components.Option>
);

export const ImageMultiValue = (props) => (
  <components.MultiValue {...props}>
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <HeadIcon size={30} imageUrl={props.data.imageUrl} name={props.data.label} />
      <OTText style={{ marginLeft: 10, fontSize: 14, flex: 1, fontWeight: "600", color: "#555" }}>
        {props.data.label}
      </OTText>
    </View>
  </components.MultiValue>
);

interface ICreateChatProps {
  teamId: string;
}

interface ICreateChatState {
  selectedUsers: IOption[];
}

@observer
export class CreateChat extends Component<ICreateChatProps, ICreateChatState> {
  constructor(props) {
    super(props);

    this.state = {
      selectedUsers: [],
    };
  }

  onUserIdsChanged = (userOption) => {
    this.setState({
      selectedUsers: userOption || [],
    });
  };

  createChat = async () => {
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];

    if (this.state.selectedUsers.length > 0) {
      const channelId = await chatUIState.startChannel(
        this.state.selectedUsers.map((item) => item.value)
      );
      chatUIState.goChannel(channelId, "default");
      dismissModal()
    }
  }

  render() {
    const teamData = OTGlobals.getTeamData(this.props.teamId);

    const options = Object.keys(teamData.users)
      .filter((userId) => userId != OTUITree.auth.userId)
      .sort((a, b) => ("" + teamData.users[a].name).localeCompare(teamData.users[b].name))
      .map((userId) => ({
        label: teamData.users[userId].name,
        value: userId,
        imageUrl: teamData.users[userId].imageUrl,
      }));

    return (
      <DialogView
        title={"Direct Message"}
        buttons={[
          {
            title: "Create Chat",
            onPress: this.createChat,
            disabled: this.state.selectedUsers.length == 0,
          },
        ]}
      >
        <View style={{ width: 420, marginVertical: 30, alignItems: "center" }}>
          <SettingsRow title="Add Members" width={420}>
            <Select
              value={this.state.selectedUsers}
              onChange={this.onUserIdsChanged}
              menuPortalTarget={document.getElementById("otbody")}
              placeholder="Select Team mates"
              options={options}
              components={{
                Option: ImageOption,
                MultiValue: ImageMultiValue,
              }}
              isSearchable={true}
              isMulti={true}
              isClearable={true}
            />
          </SettingsRow>
        </View>
      </DialogView>
    );
  }
}

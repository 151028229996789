import React, { Component } from 'react';
import { View } from 'react-native';
import { observer } from 'mobx-react';
import { OTText, OTTextInput } from '../OTText';
import { InlineWeb } from '../InlineWeb';
import { dismissModal, showModal } from '../ModalService';
import { Theme } from '../../globals/ThemeColors';
import { OTButton } from '../buttons/OTButtons';
import { Feather } from '@expo/vector-icons';
import { PlatformOS } from '../../utils/platform';
import { FiLink2 } from 'react-icons/fi';
import { IPluginResource } from '@openteam/models';
import { IPluginConfig, PluginManager } from '@openteam/app-core';


interface IShareLinkPluginDetails extends IPluginResource {
  args: IShareLinkArgs
}

interface IShareLinkProps {
  pluginManager: PluginManager
  pluginDetails: IShareLinkPluginDetails
  height: number
  width: number
}
interface IShareLinkState { }

interface IShareLinkArgs {
  url: string,
}

export class ShareLink extends Component<IShareLinkProps, IShareLinkState> {

  _iframe
  render() {
    if (!this.props.pluginDetails.args?.url) {
      return <View />
    }

    return (
      <InlineWeb
        ref={r => this._iframe = r}
        style={{ width: "100%", height: "100%" }}
        src={this.props.pluginDetails.args.url} />
    )
  }
}


interface ILinkSetupViewProps {
  pluginManager: PluginManager
  onClose: () => void
}

interface ILinkSetupViewState {
  urlText: string
}

@observer
export class LinkSetupView extends Component<ILinkSetupViewProps, ILinkSetupViewState> {
  constructor(props) {
    super(props);

    this.state = {
      urlText: '',
    }
  }

  render() {
    return (
      <View style={{ alignItems: 'center' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <pluginConfig.icon size={22} style={{ color: pluginConfig.iconColour, marginRight: 10 }} />
          <OTText style={{
            fontSize: 18,
            color: Theme.DarkText,
            fontWeight: "600"
          }}>{pluginConfig.name}</OTText>
        </View>

        <View style={{ marginVertical: 20, alignItems: 'center' }}>
          <OTTextInput
            style={{ padding: 10, minWidth: 300, backgroundColor: Theme.InputBackgroundColor, borderRadius: Theme.curviness, borderWidth: 1, borderColor: Theme.InputHighlightColor }}
            value={this.state.urlText}
            placeholder={"URL"}
            onChangeText={(text) => this.setState({ urlText: text })}
            onSubmitEditing={this.setUrl} />
        </View>
        <View style={{ flexDirection: 'row' }}>

          <OTButton
            onPress={this.setUrl}
            title={"Open"}
            disabled={!this.state.urlText}
            backgroundColor={!this.state.urlText ? Theme.ButtonDisabledColor : undefined}
          />
        </View>

      </View>
    )
  }

  setUrl = () => {

    if (this.state.urlText) {
      this.setState({
        urlText: '',
      })

      var args = {
        url: this.state.urlText
      }

      this.props.pluginManager.createPlugin(pluginType, args)

      this.props.onClose()
    }

  }
}

function setupPlugin(pluginManager) {
  showModal({
    contents: <LinkSetupView
      pluginManager={pluginManager}
      onClose={dismissModal}
    />,
    showContainer: true,
    showClose: true
  })
}

export class LinkIcon extends Component {
  render() {
    if (PlatformOS == 'mobile') {
      return (
        <Feather name="link-2" {...this.props} />
      )
    }

    return <FiLink2 {...this.props} />

  }
}

export const pluginType = 'link'
export const pluginConfig: IPluginConfig = {
  name: 'Share Link',
  multi: true,
  component: ShareLink,
  setupFunc: setupPlugin,
  icon: LinkIcon,
  iconColour: '#444',
  canPopout: true,
  webInline: false,
  canHandleUrl: url => { return { url } },
  urlPriority: 0
}
import { observer } from "mobx-react";
import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { Theme } from "../../globals/ThemeColors";
import { OnlineTile } from "../../components/userTile";
import { OTText } from "../../components/OTText";
import { IOTRoom, IOTRoomUser} from "@openteam/models";
import { OTGlobals } from "@openteam/app-core";

interface IOfflineListProps {
  teamId: string
}

interface IOfflineListState {

}

@observer
export class OfflineList extends Component<IOfflineListProps, IOfflineListState> {
  render() {

    return (
      <View
        key={"offlineList"}
        style={[styles.room, { backgroundColor: Theme.OfficeBackground }]}>
        {
          this.renderHeader()
        }

        {
          this.renderContent()
        }
      </View>
    )
  }


  renderHeader = () => {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <OTText style={styles.title}>Offline</OTText>
      </View>
    )
  }
  renderContent = () => {
    let teamData = OTGlobals.getTeamData(this.props.teamId)
    const room = teamData.rooms["offline"]

    return (
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingTop: 10, }}>
        {
          Object.keys(room.users).map(
            userId => (
              this._renderUserTile(room, userId, room.users[userId])
            ))
        }
      </View>
    )
  }


  _renderUserTile(room: IOTRoom, userId: string, teamUser: IOTRoomUser) {
    const { teamId } = this.props;
    const key = `${userId}`
    return (

      <OnlineTile
        id={key}
        key={key}
        teamId={teamId}
        userId={userId}
        roomUser={teamUser}
        width={50}
        height={50}
      />

    )
  }
}



const styles = StyleSheet.create({
  room: {
    maxWidth: "100%",
    padding: 10,
    borderRadius: Theme.curviness * 1.5,
    marginTop: 10,
    marginHorizontal: 10,
  },
  title: {
    fontWeight: '600',
    fontSize: 18,
    paddingRight: 10
  },
  subTeamContainer: {
    marginBottom: 20
  }
})
import { observer } from "mobx-react";
import React, { Component } from "react";
import Joyride, { Step } from 'react-joyride';

import { ScrollView, View, StyleSheet } from "react-native";

import * as StyleConstants from '../../globals/StyleConstants'
import * as Analytics from '../../utils/Analytics'

import { OTAppData } from "../../data/OTData";
import * as ModalService from '../../components/ModalService'
import { AlertButton } from "../fragments/Alerts";
import { OTTouchableOpacity } from "../../components";

import { ChatButton, Conversation } from "../../components/chat/Chat";
import { HelpButton } from "../fragments/Help";
import ReactTooltip from "react-tooltip";
import { Theme } from "../../globals/ThemeColors";
import { MainOffice } from "./MainOffice";
import { RoomList } from "./RoomList";
import { OfflineList } from "./OfflineList";
import { VideoCall } from "../videocallView/VideoCall";
import { Logger } from '@openteam/app-util';
import { MeetingButton } from "../../components/ExternalMeetings";
import { showAdminSettings } from "../fragments/settings/SettingsContainer";
import { OTText} from "../../components/OTText";
import { SKSettings } from "../../components/SVGIcons";
import HeadIcon from "../../components/HeadIcon";
import { isFrameless } from "../../utils/platform";
import { TeamList, TeamTitle } from "../../components/team";
import { OTPopover } from "../../components/OTPopover";
import { Feather } from '@expo/vector-icons';
import { FeedbackButton } from "../fragments/Feedback";
import { ChatBar } from "../../components/chat/ChatBar";
import { LoadingView } from "../../components/LoadingView";
import { BadgeView } from "../../components/BadgeUtil";
import { OTGlobals, OTUITree } from "@openteam/app-core";
import { DialogView } from "../../components/DialogView";

const logger = new Logger("TeamHome")

interface ITeamHomeProps {
  teamId: string
}

interface ITeamHomeState {
  showTeams: boolean
}

@observer
export default class TeamHome extends Component<ITeamHomeProps, ITeamHomeState> {
  scrollView: any = React.createRef();
  hideTutorial: boolean = false

  constructor(props) {
    super(props);

    this.state = {
      showTeams: false
    }

    this.initTutorial()
  }

  componentDidMount = async () => {
    const teamData = OTGlobals.getTeamData(this.props.teamId)
    Analytics.setProperties();
    ReactTooltip.rebuild()

  }

  // showInvite = async () => {
  //   await ModalService.showModal({
  //     contents: <View>
  //       <InviteTeam teamId={this.props.teamId} onClose={ModalService.dismissModal}/>
  //     </View>,
  //     showContainer: true
  //   })
  // }



  componentWillUnmount = () => {
  }

  componentDidUpdate = () => {
    ReactTooltip.rebuild()
  }

  goToTop = () => {
    this.scrollView.current?.scrollTo({ x: 0, y: 0, animated: true })
  }

  showSettings = () => {
    showAdminSettings(this.props.teamId)
  }

  toggleShowTeams = () => {
    this.setState({
      showTeams: !this.state.showTeams
    })
  }
  setCurrentTeam = (teamId) => {

    if (OTUITree.userManager.currentTeamId != teamId) {
      OTUITree.userManager.setCurrentTeam(teamId);
      Analytics.logEvent("switch_team", { teamId: teamId });
    }

    this.setState({ showTeams: false })
  }

  render() {
    const teamId = this.props.teamId;

    const teamManager = OTUITree.teamManagers[teamId];
    const teamData = teamManager.teamData;
    const chatUIState = OTUITree.chatUIStates[teamId]

    console.debug("TeamHome render")

    const isNarrow = OTUITree.dimensions.windowWidth < 800
    const showFullChat = !isNarrow

    if (!teamManager.loaded) {
      return <View></View>
    }

    return (
      <View style={{ flex: 1 }}>
        {
          this.renderHeader()
        }
        {
          teamData.currentRoomId && OTGlobals.callStateManager ?
            <VideoCall
              key={`currentRoom-${teamData.currentRoomId}`}
              roomId={teamData.currentRoomId}
              teamId={this.props.teamId}
              leaveCall={teamManager.leaveTeamRoom}
              goToTop={this.goToTop}
            />
            :
            null
        }

        <View style={{ flexDirection: 'row', flex: 1 }}>

          <ScrollView key="contentScroll" ref={this.scrollView} style={{}} >
            <View style={{ flexDirection: 'row', flex: 1, marginRight: -10, paddingBottom: 10 }}>

              {
                this.renderContent()
              }


            </View>

          </ScrollView>
          {
            showFullChat ?

              <View style={{ flexDirection: 'row', marginHorizontal: 10, marginBottom: 10, maxWidth: "50vw" }}>
                <View>
                  {
                    this.renderChatMenu()
                  }
                </View>
                {
                  chatUIState.focusedChannelTopic ?
                    <Conversation
                      key={`conversation-${chatUIState.focusedChannelTopic.channelId}-${chatUIState.focusedChannelTopic.topicId}`}
                      teamId={this.props.teamId}
                      channelId={chatUIState.focusedChannelTopic.channelId}
                      topicId={chatUIState.focusedChannelTopic.topicId}
                      onClose={() => chatUIState.closeChannel(chatUIState.focusedChannelTopic?.channelId, chatUIState.focusedChannelTopic?.topicId)}
                      showHeader={true}
                      outerStyle={{
                        flex: 1,
                        width: "40vw",
                        borderRadius: Theme.curviness,
                        marginLeft: 10
                      }}
                    />
                    :
                    null
                }
              </View>
              :
              null
          }
        </View>

        {
          teamData.currentRoomId && teamData.rooms?.[teamData.currentRoomId].users?.[OTUITree.auth.userId].currentSessionToken != OTUITree.auth.sessionToken ?
            <ModalService.OTModal>
              <ModalService.OTModalContainer>
                <DialogView title={`Looks like you're using OpenTeam somewhere else`} buttons={[
                  {
                    title: "Leave Room",
                    onPress: teamManager.leaveTeamRoom,
                    outlined: true,
                    textColor: Theme.ButtonNegativeColor,
                    borderColor: Theme.ButtonNegativeColor
                  },
                  {
                    title: "Join Here",
                    onPress: () => teamManager.joinTeamRoom(teamData.currentRoomId || null),
                    outlined: true,
                    borderColor: Theme.ButtonPositiveColor,
                    textColor: Theme.ButtonPositiveColor,
                  }

                ]}>

                  <OTText style={{ fontWeight: '600', fontSize: 14, margin: 20 }}>
                    You're currently in: {teamData.rooms?.[teamData.currentRoomId].config?.name}
                  </OTText>

                </DialogView>
              </ModalService.OTModalContainer>
            </ModalService.OTModal>
            :
            null
        }

        {
          !OTGlobals.isConnected ?
           <ModalService.OTModal>
              <ModalService.OTModalContainer>
                <View style={{flex: 1, alignContent: 'center', justifyContent: 'center'}}>
                  <LoadingView title="Connecting..." size="large" />
                </View>
              </ModalService.OTModalContainer>
           </ModalService.OTModal>
           :
           null
        }

        {this.renderTutorial()}
      </View>
    )
  }

  renderHeader = () => {
    const { teamId } = this.props
    const teamData = OTGlobals.getTeamData(teamId);
    const teamUserDoc = teamData.getTeamUser(OTUITree.auth.userId)

    if (teamData.inVideoChat) {
      return
    }

    const isNarrow = OTUITree.dimensions.windowWidth < 800
    const isVeryNarrow = OTUITree.dimensions.windowWidth < 600

    return (
      <View style={{
        marginLeft: isFrameless ? 70 : undefined,
        paddingHorizontal: 15,
        alignItems: 'center',
        height: 60,
        backgroundColor: Theme.HeaderBackground,
        flexDirection: 'row',
      }}>
        <OTPopover
          isOpen={this.state.showTeams}
          toggleIsOpen={this.toggleShowTeams}
          renderOpen={this.renderTeams}
          icon={
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <TeamTitle teamId={teamId} teamPath={teamData.teamPath} showName={!isVeryNarrow} />
              <View>
              {
                this.state.showTeams ?
                  <Feather name={"chevron-up"} size={20} style={{ paddingLeft: 5, color: Theme.MainText }} />
                  :
                  <Feather name={"chevron-down"} size={20} style={{ paddingLeft: 5, color: Theme.MainText }} />
              }
              <BadgeView badgeNum={OTUITree.appHomeUI.badgeNumOther} />
              </View>
            </View>}
          badgeNum={undefined}
          outerStyle={{ marginLeft: 0 }}
        />
        {/* <OTEditable
          style={{ fontWeight: "bold", fontSize: 22, color: Theme.MainText }}
          value={teamData!.teamName!}
          disabled={!teamData.isAdmin}
          onEdited={(teamName) => Fire.updateTeam(this.props.teamId, { teamName })}/> */}

        <OTTouchableOpacity data-tip="Settings" onPress={this.showSettings} style={{ marginLeft: 20 }}>
          <SKSettings size={18} style={{ color: Theme.MainColour }} />
        </OTTouchableOpacity>

        <View style={{ flex: 1 }} />


        <div style={{
          display: "flex",
          flexDirection: 'row',
          alignItems: 'center',
          WebkitAppRegion: "no-drag",
        } as any}>
          {
            !isVeryNarrow ?

              <FeedbackButton />
              :
              null
          }

          {
            teamData.capabilities.externalMeetings ?
              <MeetingButton dataSet={{ tutorial: 'ExternalMeetingButton' }} teamId={this.props.teamId} iconColor={Theme.HeaderIcon} />
              :
              null
          }

          {/* {
            teamData.capabilities.stickyNotes ?
              <NoteButton teamId={this.props.teamId} />
              :
              null
          } */}
          {
            isNarrow ?
              <ChatButton teamId={this.props.teamId} />
              :
              null
          }

          <AlertButton teamId={this.props.teamId} />


          <HelpButton teamId={this.props.teamId} icon={
            <View>
              <HeadIcon imageUrl={teamUserDoc.imageUrl} name={teamUserDoc.name} size={40} borderRadius={20} />
              {
                OTAppData.webUpgradeAvailable || OTAppData.desktopAppUpgradeAvailable ?
                  <View style={{ height: 10, width: 10, borderRadius: 5, backgroundColor: Theme.BadgeColor, position: 'absolute', top: 0, right: 0 }} />

                  :
                  null
              }
            </View>
          } />

        </div>
      </View>
    )
  }

  renderTeams = () => {
    return (
      <TeamList showName={true} setCurrentTeam={this.setCurrentTeam} showAdd={true} onAddOpen={this.toggleShowTeams} />
    )
  }

  renderChatMenu = () => {
    return (
      <ChatBar teamId={this.props.teamId} />
    )
  }


  renderContent = () => {
    const teamManager = OTUITree.teamManagers[this.props.teamId];
    const teamData = teamManager.teamData;
    logger.debug("rendering content", teamData.currentRoomId)

    return <View style={{
      flex: 1,
      minHeight: '100vh',
    }}>

      {
        teamData.inVideoChat ?
          null
          :
          <>
            <MainOffice teamId={this.props.teamId} />
            <RoomList teamId={this.props.teamId} />
            <RoomList teamId={this.props.teamId} external={true} />
            <OfflineList teamId={this.props.teamId} />
          </>
      }
    </View>

  }

  steps: Step[] = [
    {
      placement: 'center',
      target: 'body',
      title: "Welcome to OpenTeam! 🎉🎉🎉",
      content: "OpenTeam enables you to communicate as if you are in the office, no matter where you are in the world!",
    },
    {
      target: '[data-tutorial=mainOffice]',
      title: "This is your main space...",
      content: <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        You can see and interact with your online teammates here.
        <ul style={{ listStyleType: "none", textAlign: 'left', margin: 0, padding: 0, marginTop: 20 }}>
          <li>
            ✊ &nbsp; send a knock
          </li>
          <li>
            🗣️ &nbsp; walkie talkie them
          </li>
          <li>
            📞 &nbsp; give them a call
          </li>
          <li>
            💬 &nbsp; or just send a message!
          </li>
        </ul>
      </div>,
    },
    {
      target: '[data-tutorial=onlineUser-me]',
      title: '...and this is you.',
      content: "You can keep your photo up to date by using the selfie button to take a picture 📸 or upload that professional photo that your company paid all that money for.",
    },
    {
      target: '[data-tutorial=roomList-internal]',
      title: 'These are your rooms',
      content: "You can see when anyone is having a meeting and join by dragging yourself in or clicking Enter. You can customize room names and settings below to make your main space really feel like the office!",
    },
    {
      placement: 'bottom-end',
      target: '[data-tutorial=ExternalMeetingButton]',
      title: 'Hold an external call',
      content: "Setup a personalised link so you can have meetings with anyone from outside OpenTeam – just like having a client visit your office!",
    },
    // {
    //   placement: 'center',
    //   target: 'body',
    //   title: "One last thing...",
    //   content: "Experience OpenTeam in it's full glory by downloading the OpenTeam App for desktop!",
    // },


  ]

  initTutorial = () => {
    this.steps = this.steps.filter(step => !((step.target as string) in (OTGlobals.remoteUserSettings.tutorialStepsSeen || {})))
  }

  renderTutorial = () => {
    const teamData = OTGlobals.getTeamData(this.props.teamId)

    if (!this.hideTutorial && teamData.currentRoomId && OTGlobals.callStateManager) {
      this.hideTutorial = true
    }

    return (

      <Joyride
        steps={this.steps}
        run={this.steps.length > 0 && !this.hideTutorial}
        continuous={true}
        callback={this.handleJoyrideCallback}
        disableScrolling={true}
        locale={{
          last: 'Finish'
        }}
        styles={{
          options: {
            primaryColor: '#D23255',
            arrowColor: Theme.DialogBackground
          },
          tooltip: {
            borderRadius: Theme.curviness,
            backgroundColor: Theme.DialogBackground,
            color: Theme.MainText
          },
          tooltipTitle: {
            fontSize: 22,
            padding: 10,
          }
        }} />
    )
  }

  handleJoyrideCallback = (data) => {
    const { action, index, status, type, step, lifecycle } = data

    if (lifecycle == 'complete' && step) {
      let tutorialStepsSeen = { ...OTGlobals.remoteUserSettings.tutorialStepsSeen}
      tutorialStepsSeen[step.target] = true
      OTGlobals.userSettingsManager.updateRemoteSettings({ tutorialStepsSeen: tutorialStepsSeen })

      logger.debug("tutorialStepsSeen", OTGlobals.remoteUserSettings.tutorialStepsSeen, tutorialStepsSeen)
    }
  }
}

const styles = StyleSheet.create({
  sharingBox: {
    ...StyleConstants.BoxGlow,
    shadowColor: StyleConstants.RecordingColour,
    backgroundColor: StyleConstants.SharingBackground,
    padding: 10,
    margin: 10,
    borderRadius: Theme.curviness,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: "center"
  },
  roomGroup: {
    maxWidth: "100%",
    padding: 15,
    borderRadius: Theme.curviness,
    backgroundColor: "white",
    marginTop: 5,
    marginHorizontal: 10,
    marginBottom: 15,
  },
  groupRooms: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
    paddingRight: 10,
    paddingBottom: 10,
  },

})

const sharingIconStyle = { color: StyleConstants.SharingColour, marginRight: 10, marginLeft: 10 }

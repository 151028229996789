import React, { Component } from "react"
import { observer } from "mobx-react"
import { toJS } from "mobx"
import { ReactAudio } from "./ReactAudio"
import { Logger } from '@openteam/app-util';
import { OTGlobals } from "@openteam/app-core";

const logger = new Logger("PTTAudioRender")

interface IPTTAudioRenderProps {
  teamId: string
}

interface IPTTAudioRenderState {

}

@observer
export class PTTAudioRender extends Component<IPTTAudioRenderProps, IPTTAudioRenderState> {
  render() {

    const teamData = OTGlobals.getTeamData(this.props.teamId);
    logger.debug("teamData.pttStreams", toJS(teamData.pttStreams))
    return (
      <>
        {Object.keys(teamData.pttStreams).filter(userId => teamData.streams[teamData.pttStreams[userId]['camera']]).map(
          userId => {
          logger.debug("teamData.pttStreams", userId, Object.keys(teamData.pttStreams[userId]), teamData.pttStreams[userId]['camera'])

            let streamInfo = teamData.streams[teamData.pttStreams[userId]['camera']]

            return <ReactAudio
              key={streamInfo.stream.id}
              muted={false}
              stream={streamInfo.stream}
              sinkId={OTGlobals.mediaDevices.audiooutput?.deviceId}
            />
          }

        )
      }
      </>
    )
  }
}

import { observer } from "mobx-react";
import React, { Component } from 'react';
import { TouchableOpacity } from 'react-native';
import { Text, TextInput, View } from 'react-native';
import { validate } from 'validate.js';
import * as StyleConstants from '../globals/StyleConstants';
import { OTTouchableOpacity } from ".";
import { OTText, OTTextInput } from "./OTText";
import { Feather } from '@expo/vector-icons';



const validationConstraints = {
  curEmail: {
    email: {
      message: "^Please enter a valid email address"
    }
  },
};

interface IEmailChipInputProps {
  onEmailsChange?: (emails) => void
  hideChips?: boolean
}

interface IEmailChipInputState {
  emails: string[]
  curEmail: string
  errors?: { [id: string]: string[] }
}

@observer
export class EmailChipInput extends Component<IEmailChipInputProps, IEmailChipInputState> {
  constructor(props) {
    super(props);

    this.state = {
      emails: [],
      curEmail: ''
    }
  }

  clear = () => {
    this.setState({ emails: [] })
  }

  getEmails = () => {
    var emails = [...this.state.emails]

    var validEmail = this.addEmail()

    if (validEmail) {
      emails.push(validEmail)
    }

    return emails
  }

  onChangeText = (text) => {
    this.setState({
      curEmail: text.trim(),
      errors: undefined
    })
  }

  handleKeyPress = (evt) => {
    if (evt.key === "Backspace" && this.state.curEmail === "") {
      evt.preventDefault();
      let emails = this.state.emails
      if (emails.length > 0) {
        let curEmail = emails.pop()
        this.updateEmails(emails, curEmail)
      }
    } else if ((evt.key === " " || evt.key === "Enter")) {
      evt.preventDefault();
      if (this.state.curEmail != "") {
        this.addEmail()
      }
    }
  }

  deleteEmail = (index) => {
    let emails = this.state.emails
    emails = emails.splice(index, 1)
    this.updateEmails(emails, this.state.curEmail)
  }

  updateEmails = (emails, curEmail) => {
    this.setState({
      emails: emails,
      curEmail: curEmail
    }, this.onUpdate
    )
  }

  onUpdate = () => {
    this.props.onEmailsChange && this.props.onEmailsChange(this.state.emails)
  }

  addEmail = () => {

    const validationResult = validate(this.state, validationConstraints);

    if (validationResult && this.state.curEmail != "") {
      this.setState({ errors: validationResult });
      return
    }

    if (this.state.curEmail) {
      var newEmail = this.state.curEmail
      this.updateEmails(
        [...this.state.emails, this.state.curEmail],
        ''
      )
      return newEmail

    }
    return
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
          {
            !this.props.hideChips && this.state.emails.map((email, index) => (
              <View
                key={`${index}-${email}`}
                style={{
                  borderRadius: 5,
                  backgroundColor: StyleConstants.GreyInput,
                  margin: 3,
                  padding: 2,
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <OTText
                  style={{
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                  }}>
                  {email}
                </OTText>
                <OTTouchableOpacity
                  onPress={() => this.deleteEmail(index)}
                  analyticsEvent="invite_email_delete"
                  stopPropagation={true}
                >
                  <Feather name="x" />
                </OTTouchableOpacity>
              </View>
            ))
          }
          <OTTextInput
            placeholder="Invite User by Email"
            style={{
              padding: 5,
              flexGrow: 1,
            }}
            value={this.state.curEmail}
            onChangeText={this.onChangeText}
            blurOnSubmit={false}
            // onSubmitEditing={this.addEmail}
            onKeyPress={this.handleKeyPress}
          />
        </View>
        {
          this.state.errors ?
            <OTText style={{ color: 'red', textAlign: 'center', paddingTop: 10, }}>
              {
                Object.values(this.state.errors).map((it: string[]) => it.join('\n')).join('\n')
              }
            </OTText>
            :
            null
        }
      </View>
    )
  }
}
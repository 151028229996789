import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import {
  IOTUser,
  ITeamCall,
  ITeamRoomConfig,
  ITeamRoomUser,
  ITeamUserPrefs,
  IUserTeamReq,
  TActionType,
} from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { OTGlobals } from "../OTGlobals";
import { OTAppCoreData } from "../OTAppCoreData";
import { addWatch, FireUtils, removeWatch } from "./FireUtils";

const logger = new Logger("FireDb");

export class FireDb {
  static disconnectLeeway = 10 * 1000;

  static watchTeamExternalAddress = (
    fbDb: firebase.database.Database,
    teamId: string,
    address: string | undefined,
    callback: (doc) => void
  ) => {
    const ref = `/teamExternal/${teamId}/address/${address}`;
    addWatch(ref);
    fbDb.ref(ref).on("value", (snapshot) => callback(snapshot.val()));
  };

  static unwatchTeamExternalAddress = (
    fbDb: firebase.database.Database,
    teamId: string,
    address: string | undefined
  ) => {
    const ref = `/teamExternal/${teamId}/address/${address}`;
    removeWatch(ref);
    fbDb.ref(ref).off("value");
  };

  static createRoom = async (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string,
    sessionId: string,
    roomConfigDoc: ITeamRoomConfig
  ) => {
    logger.debug(
      `creating room userId=${userId}, teamId=${teamId}, roomConfigDoc=${roomConfigDoc}`
    );
    const useSFU = OTAppCoreData.useSFU;

    roomConfigDoc.crDate = firebase.database.ServerValue.TIMESTAMP as number;
    roomConfigDoc.useSFU = roomConfigDoc.call && useSFU;
    roomConfigDoc.ownerUserId = userId;

    const teamData = OTGlobals.getTeamData(teamId);
    const user = FireDb.getRoomUser(teamData.getTeamUser(userId));

    const roomDoc = {
      crDate: firebase.database.ServerValue.TIMESTAMP,
      config: roomConfigDoc,
      users: { [userId]: {...user, currentSessionToken: sessionId} },
    };
    logger.debug("createRoom with roomDoc=%o", roomDoc);

    const roomId = fbDb.ref("/teams/" + teamId + "/rooms/").push().key;

    await fbDb.ref(`/teams/${teamId}/rooms/${roomId}`).set(roomDoc);

    await FireDb.joinTeamRoom(fbDb, teamId, userId, sessionId, roomId, user);

    OTGlobals.analytics?.logEvent("fire__create_room");
    return roomId;
  }

  static joinTeamRoom = async (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string,
    sessionToken: string,
    roomId: string | null,
    roomUser: ITeamRoomUser
  ) => {
    logger.info(`joining room ${teamId}:${roomId}:${userId}`);

    var snap = await fbDb
      .ref(`/teams/${teamId}/rooms`)
      .orderByChild(`users/${userId}/id`)
      .equalTo(userId)
      .once("value");

    var roomIds: string[] = [];
    snap.forEach((doc) => {
      roomIds.push(doc.key as string);
    });

    for (const docKey of roomIds) {
      logger.info(`checking room ${docKey}`);

      if (docKey != roomId) {
        logger.info(`leaving room ${docKey}`);
        await FireDb.leaveTeamRoom(fbDb, teamId, userId, sessionToken, docKey!);
      }
    }

    if (roomId) {
      roomUser.crDate = firebase.database.ServerValue.TIMESTAMP as number;

      roomUser.currentSessionToken = sessionToken;
      logger.debug("joinTeamRoom userId=%s roomUser=%o", userId, roomUser);

      await fbDb.ref(`/teams/${teamId}/rooms/${roomId}/users/${userId}`).set(roomUser);
    }

  };

  static roomPresenceCallbacks = {};

  static setupRoomPresence = (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string,
    sessionToken: string,
    roomId: string
  ) => {
    var key = `${teamId}:${roomId}`;
    FireDb.roomPresenceCallbacks[key] = (snapshot) =>
      FireDb.setRoomSession(fbDb, teamId, userId, sessionToken, roomId, snapshot);

    fbDb.ref(".info/connected").on("value", FireDb.roomPresenceCallbacks[key]);
  };

  static setRoomSession = (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string,
    sessionToken: string,
    roomId: string,
    snapshot
  ) => {
    var statusRef = fbDb.ref(`/teams/${teamId}/rooms/${roomId}/users/${userId}/status`);
    var sessionRef = statusRef.child("activeSessions").child(sessionToken!);

    var isOfflineForDatabase = {
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    };

    var isOnlineForDatabase = {
      last_changed: firebase.database.ServerValue.TIMESTAMP,
      sessionToken: sessionToken,
    };

    if (snapshot.val() == false) {
      return;
    }

    sessionRef
      .onDisconnect()
      .set(null)
      .then(function () {
        sessionRef.set(true);
      });

    statusRef
      .onDisconnect()
      .update(isOfflineForDatabase)
      .then(function () {
        statusRef.update(isOnlineForDatabase);
      });
  };

  static leaveTeamRoom = async (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string,
    sessionToken: string,
    roomId: string
  ) => {
    //await FireDb.removeRoomPresence(fbDb, teamId, userId, sessionToken, roomId);
    await fbDb.ref(`/teams/${teamId}/rooms/${roomId}/users/${userId}`).set(null);
  };

  /* static removeRoomPresence = async (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string,
    sessionToken: string,
    roomId: string
  ) => {
    const key = `${teamId}:${roomId}`;

    logger.debug(
      "removeRoomPresence, teamId=%s, roomId=%s, callback=%o",
      teamId,
      roomId,
      FireDb.roomPresenceCallbacks[key]
    );

    if (FireDb.roomPresenceCallbacks[key]) {
      fbDb.ref(".info/connected").off("value", FireDb.roomPresenceCallbacks[key]);
      delete FireDb.roomPresenceCallbacks[key];
    }

    var statusRef = fbDb.ref(`/teams/${teamId}/rooms/${roomId}/users/${userId}/status`);
    var sessionRef = statusRef.child("activeSessions").child(sessionToken!);

    statusRef.onDisconnect().cancel();
    sessionRef.onDisconnect().cancel();
  };
 */
  static getRoomUser = (user: IOTUser) => ({
    id: user.userId,
    name: user.name,
    imageUrl: user.imageUrl || null,
  });

  static inviteTeamRoom = async (
    fbDb: firebase.database.Database,
    teamId: string,
    roomId: string,
    userId
  ) => {
    await fbDb.ref(`/teams/${teamId}/rooms/${roomId}/invites/${userId}`).set(true);
  };

  static acceptTeamExternalAddressWaiting = async (
    fbDb: firebase.database.Database,
    teamId,
    address,
    userId,
    roomId
  ) => {
    var addressRef = fbDb.ref(`/teamExternal/${teamId}/address/${address}`);
    var waitingRef = addressRef.child(`waiting/${userId}`);

    await waitingRef.update({
      roomId: roomId,
      status: "A",
    });
  };

  static holdTeamExternalAddressWaiting = async (
    fbDb: firebase.database.Database,
    teamId,
    address,
    userId
  ) => {
    var addressRef = fbDb.ref(`/teamExternal/${teamId}/address/${address}`);
    var waitingRef = addressRef.child(`waiting/${userId}`);

    await waitingRef.update({
      status: "H",
    });
  };

  static rejectTeamExternalAddressWaiting = async (
    fbDb: firebase.database.Database,
    teamId,
    address,
    userId
  ) => {
    var addressRef = fbDb.ref(`/teamExternal/${teamId}/address/${address}`);
    var waitingRef = addressRef.child(`waiting/${userId}`);

    await waitingRef.update({
      status: "R",
    });
  };

  static watchTeamAccessRequests = (
    fbDb: firebase.database.Database,
    teamId: string,
    callback: (doc) => void
  ) => {
    const ref = `/teamAccessReq/${teamId}`;
    addWatch(ref);
    fbDb
      .ref(ref)
      .orderByChild("status")
      .equalTo("waiting")
      .on("value", (snapshot) => callback(snapshot.val()));
  };

  static unwatchTeamAccessRequests = (fbDb: firebase.database.Database, teamId) => {
    const ref = `/teamAccessReq/${teamId}`;
    removeWatch(ref);
    fbDb.ref(ref).orderByChild("status").equalTo("waiting").off("value");
  };

  static approveTeamAccessReq = async (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string,
    userDoc: IUserTeamReq
  ) => {
    var teamUserDoc = {
      id: userId,
      dateJoined: firebase.database.ServerValue.TIMESTAMP,
      email: userDoc.email,
      name: userDoc.name,
      imageUrl: userDoc.imageUrl || null,
    };

    await fbDb.ref(`/teams/${teamId}/users/${userId}`).set(teamUserDoc);
    await FireDb.respondToTeamAccessReq(fbDb, teamId, userId, "accepted");
  };

  static rejectTeamAccessReq = async (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string
  ) => {
    await FireDb.respondToTeamAccessReq(fbDb, teamId, userId, "rejected");
  };

  static respondToTeamAccessReq = async (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string,
    status: "accepted" | "rejected"
  ) => {
    await fbDb.ref(`/teamAccessReq/${teamId}/${userId}/status`).set(status);
  };

  static startCall = async (
    fbDb: firebase.database.Database,
    teamId: string,
    myUserId: string,
    mySessionToken: string,
    userId,
    currentRoomId
  ) => {
    var snapshot = await fbDb.ref("/teams/" + teamId + "/calls/" + userId).once("value");
    var callData: ITeamCall = snapshot.val();

    if (callData.userId != myUserId) {
      return;
    }

    if (!currentRoomId) {
      logger.debug(`creating room with userId=${callData.userId}`);

      var roomConfigDoc: ITeamRoomConfig = {
        name: "Meeting Room",
        desc: "",
        enabled: true,
        call: true,
        permanent: false,
      };

      currentRoomId = await FireDb.createRoom(
        fbDb,
        teamId,
        callData.userId,
        mySessionToken,
        roomConfigDoc
      );
    }

    var callDoc = {
      senderStatus: "started",
      roomId: currentRoomId,
    };
    logger.debug("roomId is", currentRoomId);

    await fbDb.ref("/teams/" + teamId + "/calls/" + userId).update(callDoc);

    logger.debug("set room id on call", callDoc);
  };

  static watchAlerts = (
    fbDb: firebase.database.Database,
    myUserId: string,
    teamId: string,
    callback: (snapshot) => void,
    removedCallback: (snapshot) => void
  ) => {
    fbDb.ref("/alerts/" + teamId + "/" + myUserId).on("child_added", (doc) => {
      callback({ id: doc.key, ...doc.val() });
    });

    fbDb.ref("/alerts/" + teamId + "/" + myUserId).on("child_removed", (doc) => {
      removedCallback({ id: doc.key, ...doc.val() });
    });
  };

  static unwatchAlerts = (fbDb: firebase.database.Database, myUserId: string, teamId: string) => {
    fbDb.ref("/alerts/" + teamId + "/" + myUserId).off("child_added");

    fbDb.ref("/alerts/" + teamId + "/" + myUserId).off("child_removed");
  };

  static addAlert = (
    fbDb: firebase.database.Database,
    myUserId: string,
    teamId: string,
    userId: string,
    actionType: TActionType,
    sendTime?: number
  ) => {
    var msg = {
      fromUserId: myUserId,
      toUserId: userId,
      crDate: sendTime || firebase.database.ServerValue.TIMESTAMP,
      actionType: actionType,
    };

    fbDb.ref("/alerts/" + teamId + "/" + userId).push(msg);
  };

  static removeAlert = (
    fbDb: firebase.database.Database,
    myUserId: string,
    teamId: string,
    alertId: string
  ) => {
    fbDb
      .ref("/alerts/" + teamId + "/" + myUserId)
      .child(alertId)
      .remove();
  };

  static removeUserAlerts = async (
    fbDb: firebase.database.Database,
    myUserId: string,
    teamId: string,
    userId: string
  ) => {
    const ref = fbDb.ref("/alerts/" + teamId + "/" + myUserId);
    const snap = await ref.orderByChild("fromUserId").equalTo(userId).once("value");
    snap.forEach((doc) => {
      ref.child(doc.key!).remove();
    });
  };

  static removeAllAlerts = (
    fbDb: firebase.database.Database,
    myUserId: string,
    teamId: string,
    alertIds: string[]
  ) => {
    var updates = {};
    alertIds.forEach((id) => (updates[id] = null));

    fbDb.ref("/alerts/" + teamId + "/" + myUserId).update(updates);
  };

  static updateTeamUserPreference = async (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string,
    key: keyof ITeamUserPrefs,
    value
  ) => {
    await fbDb.ref(`/teams/${teamId}/users/${userId}/preferences/${key}`).update(value);
  };

  static setTeamUserPreference = async (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string,
    key: keyof ITeamUserPrefs,
    value
  ) => {
    await fbDb.ref(`/teams/${teamId}/users/${userId}/preferences/${key}`).set(value);
  };

  static updateTeamUserImage = async (
    fbDb: firebase.database.Database,
    userId: string,
    teamId: string,
    file
  ) => {
    FireUtils.uploadUserFile(teamId, userId, "profile", file, (url) => {
      var userObj = {
        imageUrl: url,
      };

      fbDb.ref(`/teams/${teamId}/users/${userId}`).update(userObj);
    });
  };

  static checkTeamRoomAccess = async (
    fbDb: firebase.database.Database,
    teamId: string,
    roomId: string
  ) => {
    var roomDoc = undefined;
    try {
      var snapshot = await fbDb.ref(`/teams/${teamId}/rooms/${roomId}`).once("value");
      roomDoc = snapshot.val();

      logger.debug("checkTeamRoomAccess", teamId, roomId, roomDoc);
    } catch (error) {
      logger.debug("error couldn't access room", teamId, roomId);
    }

    return roomDoc != undefined;
  };

  static updateRoomConfig = async (
    fbDb: firebase.database.Database,
    teamId: string,
    roomId: string,
    roomConfigDoc: Partial<ITeamRoomConfig>
  ) => {
    await fbDb.ref(`/teams/${teamId}/rooms/${roomId}/config`).update(roomConfigDoc);
  };

  static closeRoom = async (fbDb: firebase.database.Database, teamId: string, roomId: string) => {
    logger.info("deleting room", roomId);

    fbDb.ref(`/teams/${teamId}/rooms/${roomId}`).remove();
    OTGlobals.analytics?.logEvent("fire__close_room");
  };

  static checkTeamAccess = async (
    fbDb: firebase.database.Database,
    userId: string,
    teamId: string
  ) => {
    var teamName = undefined;
    try {
      var snapshot = await fbDb.ref(`/teams/${teamId}/users/${userId}`).once("value");
      var teamUserDoc = snapshot.val();

      logger.debug("checkTeamAccess", teamId, teamUserDoc);

      teamName = teamUserDoc?.name;
    } catch (error) {
      logger.debug("error couldn't access team", teamId);
    }

    return teamName != undefined;
  };

  static getTeamPathId = async (fbDb: firebase.database.Database, teamPath: string) => {
    var doc = await fbDb.ref(`/teamPath/${teamPath}`).once("value");
    var teamId = doc.val();
    return teamId;
  };
}


import { observer } from "mobx-react"
import React, { Component } from "react"
import { StyleSheet } from "react-native"
import { getActionTypeDetails, OTGlobals } from "@openteam/app-core";
import { DialogView } from "./DialogView"
import { AudioContainer, getIncomingRingtone, hideNotification, showNotification } from "../utils/Notify"
import HeadIcon from "./HeadIcon"
import { CallRequestManager } from "@openteam/app-core"
import { Theme } from "../globals/ThemeColors"
import { OTText } from "./OTText"

interface IIncomingCallProps {
  callRequestManager: CallRequestManager
  teamId: string
}

interface IIncomingCallState {
}

@observer
export class IncomingCall extends Component<IIncomingCallProps, IIncomingCallState> {
  ringtoneAudio?: AudioContainer

  componentDidMount = () => {
    var call = this.props.callRequestManager.call
    if (call) {
      const teamData = OTGlobals.getTeamData(this.props.teamId);
      var theCallingUser = teamData.getTeamUser(call.userId)

      var atdetails = getActionTypeDetails('CALL', theCallingUser)
      if (atdetails) {
        showNotification({
          teamId: this.props.teamId,
          title: atdetails.title,
          text: atdetails.presentBody,
          imageUrl: theCallingUser.imageUrl,
          id: "callfrom" + call.userId
        })
      }
    }

    this.ringtoneAudio = getIncomingRingtone()
    this.ringtoneAudio.play()
  }
  componentWillUnmount = () => {
    hideNotification("callfrom" + this.props.callRequestManager.call?.userId)
    this.ringtoneAudio?.pause()

  }

  rejectCall = () => {
    this.props.callRequestManager.recvRespondToCall("rejected")
  }

  acceptCall = () => {
    this.props.callRequestManager.recvRespondToCall("accepted")
  }

  render() {
    var call = this.props.callRequestManager.call

    if (!call) {
      return
    }

    const teamData = OTGlobals.getTeamData(this.props.teamId);
    var theCallingUser = teamData.getTeamUser(call.userId)
    var callingRoom = call.roomId ? teamData.rooms[call.roomId] : undefined


    return <DialogView title={`Incoming call`} buttons={[
      {
        title: "Decline",
        onPress: this.rejectCall,
        outlined: true,
        textColor: Theme.ButtonNegativeColor,
        borderColor: Theme.ButtonNegativeColor
      },
      {
        title: "Accept",
        onPress: this.acceptCall,
        outlined: true,
        borderColor: Theme.ButtonPositiveColor,
        textColor: Theme.ButtonPositiveColor,
      }

    ]}>
      <HeadIcon imageUrl={theCallingUser.imageUrl} name={theCallingUser.name} size={100} borderRadius={50} />

      <OTText style={{ fontWeight: '600', fontSize: 16, marginTop: 20 }}>{theCallingUser.name}{call.callName ? ` is calling ${call.callName}` : " is calling you"}</OTText>
      <OTText style={{}}>{teamData.teamName}</OTText>

      {
        callingRoom ?
          <OTText style={{ marginTop: 16 }}>{" is inviting you into " + callingRoom.config?.name}</OTText>
          :
          null
      }
    </DialogView>
  }
}


const styles = StyleSheet.create({
  callBox: {
    alignItems: 'center'
  }
  ,
})

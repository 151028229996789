import React, { Component } from "react";
import { Image, StyleSheet, View } from "react-native";
import * as ModalService from '../../components/ModalService';
import { OTButton } from "../../components/buttons/OTButtons";
import { OTTouchableOpacity } from "../../components";
import * as StyleConstants from '../../globals/StyleConstants';
import { OTText } from "../../components/OTText";
import { Theme } from "../../globals/ThemeColors";
import * as Analytics from "../../utils/Analytics"

import { FontAwesome5 } from '@expo/vector-icons';
import { OTGlobals } from "@openteam/app-core";

export async function showOnboarding() {
  await ModalService.showModal({
    contents: <View>
      <OnboardingHolder onClose={ModalService.dismissModal} />
    </View>,
    showContainer: true
  })
}

interface IOnboardingDetail {
  title: string
  illustration: JSX.Element
  details?: JSX.Element
}

interface IOnboardingHolderProps {
  onClose: () => void
}

interface IOnboardingHolderState {
}

export class OnboardingHolder extends Component<IOnboardingHolderProps, IOnboardingHolderState> {

  onboardingList: IOnboardingDetail[] = [
    {
      title: "Communicate Freely",
      illustration: <FontAwesome5 name="bullhorn" size={50} style={{ color: Theme.MainColour }} />,
      details: <OnboardingCommunicate />
    },
    {
      title: "Feel Connected",
      illustration: <FontAwesome5 name="building" size={50} style={{ color: Theme.MainColour }} />,
      details: <OnboardingOffice />
    },
    {
      title: "Collaborate Easily",
      illustration: <FontAwesome5 name="user-friends" size={50} style={{ color: Theme.MainColour }} />,
      details: <OnboardingCollaborate />
    },
    {
      title: "Improve Work/Life Balance",
      illustration: <FontAwesome5 name="home" size={50} style={{ color: Theme.MainColour }} />,
      details: <OnboardingWorkLife />
    },
    // {
    //   title: "Connect with your team",
    //   imageUrl: null,
    // },
    // // {
    // //   title: "Real-time Push To Talk", // Real time chat // talk in real time // Table Talk // roundtable
    // //   imageUrl: null, // screen shot with walkie talkie
    // // },
    // {
    //   title: "Easier meetings",
    //   imageUrl: null,
    // },
    // {
    //   title: "Improve team bonding",
    //   imageUrl: null,
    // },
    // {
    //   title: "Promote Innovation",
    //   imageUrl: null,
    // },
    // {
    //   title: "Stay Private",
    //   imageUrl: null,
    // },
  ]

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  goOnboardingItem = (item: IOnboardingDetail) => {
    ModalService.showModal({
      contents: <OnboardingDetail item={item} onClose={ModalService.dismissModal} />,
      showContainer: true
    })
    OTGlobals.userSettingsManager.updateRemoteSettings({ showOnboardingDone: true })
    Analytics.logEvent("onboarding_done", { itemTitle: item.title })
  }

  skipOnboarding = () => {
    OTGlobals.userSettingsManager.updateRemoteSettings({ showOnboardingSkipped: true })
    this.props.onClose()
    Analytics.logEvent("onboarding_skipped")
  }

  render() {

    return (
      <View style={{ minWidth: 600, minHeight: 400, margin: 10 }}>
        <View style={{ width: "100%", alignItems: 'center', flex: 1 }}>
          <OTText style={{ fontWeight: 'bold', fontSize: 24, marginBottom: 20 }}>
            What would you like to do with <OTText style={{ color: StyleConstants.PrimaryColour }}>Open</OTText>Team?
          </OTText>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
            {
              this.onboardingList.map((value) => this.renderOnboardingItem(value))
            }
          </View>
          {
            OTGlobals.remoteUserSettings.showOnboardingDone ?
              <View style={{ flexDirection: 'row' }}>
                <OTButton title="Continue" onPress={this.props.onClose} />
              </View>
              :
              <View style={{ flexDirection: 'row' }}>
                <OTButton title="Skip Guide" onPress={this.skipOnboarding} backgroundColor={'white'} borderColor={StyleConstants.SecondaryColour} textColor={'black'} />
              </View>
          }
        </View>
      </View>
    )
  }

  renderOnboardingItem = (item: IOnboardingDetail) => {
    return (
      <OTTouchableOpacity
        key={item.title}
        onPress={() => this.goOnboardingItem(item)}
        style={{
          height: 160,
          width: 160,
          padding: 10,
          margin: 5,
          borderWidth: 1,
          borderColor: StyleConstants.BorderColour,
          borderRadius: Theme.curviness,
          alignItems: 'center'
        }}>
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          {item.illustration}

        </View>
        <View style={{ height: 40, justifyContent: 'center' }}>

          <OTText style={{ textAlign: 'center', }}>
            {item.title}
          </OTText>
        </View>

      </OTTouchableOpacity>
    )
  }
}


interface IOnboardingDetailProps {
  item: IOnboardingDetail
  onClose: () => void
}

interface IOnboardingDetailState {
}

export class OnboardingDetail extends Component<IOnboardingDetailProps, IOnboardingDetailState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    var item = this.props.item
    return (
      <View style={{ maxWidth: 600, minWidth: 600, minHeight: 400, margin: 10 }}>
        <View style={{ width: "100%", alignItems: 'center', flex: 1 }}>

          <View style={{ flex: 1 }}>
            {item.details}
          </View>

          <View style={{ flexDirection: 'row' }}>
            <OTButton title="Go Back" onPress={this.props.onClose} />
          </View>

        </View>
      </View>

    )
  }
}


interface IOnboardingCommunicateProps {
}

interface IOnboardingCommunicateState {
}

export class OnboardingCommunicate extends Component<IOnboardingCommunicateProps, IOnboardingCommunicateState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    return (
      <View style={styles.onboardingContainer}>
        <OTText style={styles.onboardingTitle}>
          Communicate easily - Like you're in the same room
        </OTText>

        <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/webassets%2Fpttimage.png?alt=media" }} style={{ height: 221, width: 252, resizeMode: 'contain', marginBottom: 20, borderRadius: 5 }} />
        <OTText style={styles.onboardingSubTitle}>
          Always connected with Walkie Talkie
        </OTText>
        <OTText style={styles.onboardingContentText}>
          Conversations in the office flow freely and easily. Using our real-time Walkie Talkie, you can talk to anyone in the company, instantly, just by holding down their tile. It works exactly like a real walkie talkie.
        </OTText>

        <FontAwesome5 name="hand-rock" size={30} style={{ padding: 20 }} />

        <OTText style={styles.onboardingSubTitle}>
          Knock
        </OTText>
        <OTText style={styles.onboardingContentText}>
          Want to know if your teammate is free for a chat or a question? Knock and see if they respond.
        </OTText>

        <FontAwesome5 name="phone" size={30} style={{ padding: 20 }} />


        <OTText style={styles.onboardingSubTitle}>
          Video Rooms
        </OTText>
        <OTText style={styles.onboardingContentText}>
          Start a video meeting with a click, pull others into the meeting as and when needed. Everyone can see who is meeting with who (just like looking through the meeting room window!).
        </OTText>

        <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/webassets%2Fteammegaphone.png?alt=media" }} style={{ height: 42, width: 121, resizeMode: 'contain', marginVertical: 20, overflow: 'hidden', borderRadius: 5 }} />

        <OTText style={styles.onboardingSubTitle}>
          Megaphone
        </OTText>
        <OTText style={styles.onboardingContentText}>
          Want to talk to everyone in your team to say good morning, tell a joke or make an announcement? The megaphone is what you need.
        </OTText>
      </View>

    )
  }
}


interface IOnboardingOfficeProps {
}

interface IOnboardingOfficeState {
}

export class OnboardingOffice extends Component<IOnboardingOfficeProps, IOnboardingOfficeState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    return (
      <View style={styles.onboardingContainer}>

        <OTText style={styles.onboardingTitle}>
          Feel connected to your team
        </OTText>

        <OTText style={styles.onboardingContentText}>
          One of the biggest issues of working remotely is that you can no longer see each other, this goes beyond having the conversations you need to complete your work.
        </OTText>

        <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/webassets%2Fconnectteam.png?alt=media" }} style={{ height: 200, width: 300, resizeMode: 'contain', marginVertical: 20 }} />

        <OTText style={styles.onboardingSubTitle}>
          A Living Office
        </OTText>

        <OTText style={styles.onboardingContentText}>
          Using our intuitive office layout, you can now feel part of a busy workplace. See people moving between the office and different rooms as they join and leave meetings. Talk to anyone at a press of a button.
        </OTText>

        <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/webassets%2Fbreaknotify.png?alt=media" }} style={{ height: 100, width: 300, resizeMode: 'contain', marginVertical: 20 }} />

        <OTText style={styles.onboardingSubTitle}>
          Break Room
        </OTText>
        <OTText style={styles.onboardingContentText}>
          Informal conversation is a huge part of office life. You use it to get to know your teammates, talk about your lives and problems, and share about your latest whacky idea. We want all of this to still happen, so you can use the communal break room to tell people you're taking a break and to join you.
        </OTText>

        <FontAwesome5 name="camera" size={30} style={{ padding: 20 }} />

        <OTText style={styles.onboardingSubTitle}>
          Selfie
        </OTText>
        <OTText style={styles.onboardingContentText}>
          Seeing faces is something we take for granted in the office. When you're not in a call, you can use the selfie camera to share your bedhead, your pet, or the bread you baked!
        </OTText>

        <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/webassets%2Fsilence.png?alt=media" }} style={{ height: 40, width: 40, resizeMode: 'contain', marginVertical: 20 }} />

        <OTText style={styles.onboardingSubTitle}>
          Quiet Room
        </OTText>
        <OTText style={styles.onboardingContentText}>
          Join the Quiet Room to visually let people know you're not available to talk. This also turns off your walkie talkie/megaphone.
        </OTText>
      </View>

    )
  }
}


interface IOnboardingCollaborateProps {
}

interface IOnboardingCollaborateState {
}

export class OnboardingCollaborate extends Component<IOnboardingCollaborateProps, IOnboardingCollaborateState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    return (
      <View style={styles.onboardingContainer}>

        <OTText style={styles.onboardingTitle}>
          Collaborate with a click
        </OTText>

        <OTText style={styles.onboardingContentText}>
          So you want to get stuff done? It's never been easier with OpenTeam. Seeing their tile online you know the person you want to work with is working today. You've knocked and maybe walkie talkied them, but now you need to go a bit deeper.
        </OTText>

        <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/webassets%2Fvideointerface.png?alt=media" }} style={{ height: 200, width: 237, resizeMode: 'contain', marginVertical: 20, borderRadius: 5 }} />


        <OTText style={styles.onboardingSubTitle}>
          Video Collaboration
        </OTText>
        <OTText style={styles.onboardingContentText}>
          No meeting invites, no meeting id's, no having to wait for a free timeslot, collaborate in one click by using the call button. Need others in the room, they can join when they see the meeting is happening, or you can call them in.
        </OTText>

        <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/webassets%2Fscreenshare.png?alt=media" }} style={{ height: 200, width: 304, resizeMode: 'contain', marginVertical: 20, borderRadius: 5 }} />


        <OTText style={styles.onboardingSubTitle}>
          Screen Sharing
        </OTText>
        <OTText style={styles.onboardingContentText}>
          Feel like you're collaborating at the same computer using High Definition screensharing.
        </OTText>

        <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/webassets%2Fsendfile.png?alt=media" }} style={{ height: 200, width: 230, resizeMode: 'contain', marginVertical: 20, borderRadius: 5 }} />


        <OTText style={styles.onboardingSubTitle}>
          Drag and Drop File Transfer
        </OTText>
        <OTText style={styles.onboardingContentText}>
          Sending files has never been easier, you can simply drag and drop the files you want to send onto a user's tile to share them.
        </OTText>


        {/* <OTText style={styles.onboardingSubTitle}>
          Apps
        </OTText> */}

      </View>

    )
  }
}


interface IOnboardingWorkLifeProps {
}

interface IOnboardingWorkLifeState {
}

export class OnboardingWorkLife extends Component<IOnboardingWorkLifeProps, IOnboardingWorkLifeState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    return (
      <View style={styles.onboardingContainer}>

        <OTText style={styles.onboardingTitle}>
          Be the best place to work
        </OTText>

        <OTText style={styles.onboardingContentText}>
          Without a physical office, the boundaries of work and life become even more blurred.
        </OTText>

        <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/webassets%2Flaptopnature.jpg?alt=media" }} style={{ height: 200, width: 300, resizeMode: 'contain', marginVertical: 20, borderRadius: 5 }} />


        <OTText style={styles.onboardingSubTitle}>
          Work from anywhere
        </OTText>

        <OTText style={styles.onboardingContentText}>
          Work is where there is a stable internet connection. Whether that's sat on your sofa, at your perfect home office setup, or in the countryside by a lake.
        </OTText>

        <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/webassets%2Fofficepresence.png?alt=media" }} style={{ height: 200, width: 229, resizeMode: 'contain', marginVertical: 20, borderRadius: 5 }} />

        <OTText style={styles.onboardingSubTitle}>
          Office Presence
        </OTText>

        <OTText style={styles.onboardingContentText}>
          Let your colleagues know when you're available to communicate using office presence, just like being at the office.
        </OTText>

        {/* <Image source={{uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/webassets%2Fstickynotes.png?alt=media"}} style={{height: 200, width: 200, resizeMode: 'contain', marginVertical: 20, borderRadius: 5}} />

        <OTText style={styles.onboardingSubTitle}>
          Sticky Notes
        </OTText>

        <OTText style={styles.onboardingContentText}>
          Got something low priority to discuss and don't want to disturb your colleague? Leave a sticky note for them to deal with when they have time.
        </OTText> */}

        <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/webassets%2Fprivacy.jpg?alt=media" }} style={{ height: 200, width: 301, resizeMode: 'contain', marginVertical: 20, borderRadius: 5 }} />

        <OTText style={styles.onboardingSubTitle}>
          Privacy
        </OTText>
        <OTText style={styles.onboardingContentText}>
          No constantly open calls, no current app tracking, and definitely no automatic photos. 100% enterprise-grade encryption.
        </OTText>
      </View>

    )
  }
}


var styles = StyleSheet.create({
  onboardingContainer: {
    width: "100%",
    alignItems: 'center'
  },
  onboardingTitle: {
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: 20
  },
  onboardingSubTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 5,
  },
  onboardingContentText: {
    marginBottom: 40,
    textAlign: 'center',
  },
})

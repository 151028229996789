import { IActionDetails, IOTRoom, IOTUser, ITeamUser, TActionType } from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { FireDb } from "../fire";
import { OTGlobals } from "../OTGlobals";
import { OTUserInterface } from "../OTUserInterface";
import { OTUITree } from "../OTUITree";

const logger = new Logger("alerts/helpers");

export function sendAction(
  fbDb: firebase.database.Database,
  myUserId: string,
  teamId: string,
  userId: string,
  actionType: TActionType
) {
  // if (!actionUsers[userId]) {
  //   actionUsers[userId] = {}
  // }
  // actionUsers[userId].sendAction = actionType
  // actionUsers[userId].sendTime = Date.now()
  logger.info("adding alert ", teamId, userId, actionType);

  if (actionType in OTGlobals.actionTypeDetails && OTGlobals.actionTypeDetails[actionType].alert) {
    var sendTime = Date.now();
    FireDb.addAlert(fbDb, myUserId, teamId, userId, actionType, sendTime);
    FireDb.removeUserAlerts(fbDb, myUserId, teamId, userId);

    // setTimeout(
    //   () => {
    //     var recvTime = actionUsers[userId]?.recvTime
    //     logger.debug("sendTime", sendTime, recvTime)

    //     if (!recvTime || sendTime > recvTime + actionLeewayMS) {
    //       logger.debug("adding alert")
    //       Fire.addAlert(userId, actionType, sendTime)

    //     } else {
    //       logger.debug("clearing alert")

    //     }
    //   }, actionLeewayMS + 2 * 1000
    // )
  }
}

interface IActionTimer {
  // sendAction?: TActionType
  // sendTime?: number

  recvAction?: TActionType;
  recvTime?: number;
}

const actionUsers: Record<string, IActionTimer> = {};

export function recvAction(userId: string, actionType: TActionType) {
  logger.debug("received", actionType, "from", userId);

  if (actionType in OTGlobals.actionTypeDetails && OTGlobals.actionTypeDetails[actionType].alert) {
    if (!actionUsers[userId]) {
      actionUsers[userId] = {};
    }

    actionUsers[userId].recvAction = actionType;
    actionUsers[userId].recvTime = Date.now();
  }
}

export function doActionAlert(teamId: string, teamUserDoc: ITeamUser, actionType: TActionType) {
  const atDetails = getActionTypeDetails(actionType, teamUserDoc);

  if (atDetails) {
    if (atDetails.notify) {
      OTUserInterface.showNotification({
        teamId: teamId,
        title: atDetails.title,
        text: atDetails.presentBody,
        imageUrl: teamUserDoc.imageUrl,
      });
    }

    if (atDetails.sound) {
      atDetails.sound();
    }
  }
}

export function userOnlineAlert(teamId: string, userId: string) {
  const teamData = OTGlobals.getTeamData(teamId);
  const user = teamData.getTeamUser(userId);

  OTUserInterface.showNotification({
    teamId: teamId,
    title: `${user.name} just came online`,
    text: `Say hello!`,
  });
}

export function meetingRequestAlert(teamId: string, userId: string, name: string) {
  OTUserInterface.showNotification({
    teamId: teamId,
    title: `${name} is waiting for a meeting with you`,
    text: `Start the meeting`,
  });
}

export function roomChangeAlert(myUserId: string, teamId: string, newRoom: IOTRoom) {
  const teamData = OTGlobals.getTeamData(teamId);
  const chatUIState = OTUITree.chatUIStates[teamId]
  const { roomId } = newRoom;

  const me = teamData.getTeamUser(myUserId);

  const notInRoom = !teamData.currentRoomId
  const notifyMySubTeam = newRoom.config?.notify && (newRoom.config?.notifyTarget == undefined || newRoom.config?.notifyTarget == me.subTeam)
  const channelAlert =
    newRoom.config?.channelId && chatUIState.getChannel(newRoom.config?.channelId)

  if (notInRoom && (notifyMySubTeam || channelAlert)) {

    var prevUsers = teamData.rooms[roomId]?.users || {};

    Object.keys(newRoom.users).forEach((userId) => {
      if (!prevUsers[userId] && userId != myUserId) {
        const teamUserDoc = teamData.getTeamUser(userId);

        OTUserInterface.showNotification({
          teamId: teamId,
          title: `${teamUserDoc.name} has entered ${newRoom.config?.name}`,
          text: `Join them now`,
          imageUrl: teamUserDoc.imageUrl,
          id: undefined,
          // () => teamManager.joinTeamRoom(newRoom.roomId)
        });
      }
    });
  }
}

export function getActionTypeDetails(
  actionType: TActionType,
  teamUser: IOTUser | ITeamUser
): IActionDetails | undefined {
  if (actionType in OTGlobals.actionTypeDetails) {
    const details = OTGlobals.actionTypeDetails[actionType];
    return {
      ...details,
      title: details.title(teamUser),
      presentBody: details.presentBody(teamUser),
      pastBody: details.pastBody(teamUser),
    };
  }
}

// taken from https://github.com/sarink/react-file-drop and adapted for react-native
import React, {
  DragEvent as ReactDragEvent,
  DragEventHandler as ReactDragEventHandler
} from 'react';
import { View } from 'react-native';

export type DropEffects = 'copy' | 'move' | 'link' | 'none';

export interface DroppableProps {
  frame?: Exclude<HTMLElementTagNameMap[keyof HTMLElementTagNameMap], HTMLElement> | HTMLDocument;
  onFrameDragEnter?: (event: DragEvent) => void;
  onFrameDragLeave?: (event: DragEvent) => void;
  onFrameDrop?: (event: DragEvent) => void;
  onDragOver?: ReactDragEventHandler<HTMLDivElement>;
  onDragLeave?: ReactDragEventHandler<HTMLDivElement>;
  onDrop?: (event: ReactDragEvent<HTMLDivElement>) => any;
  dropEffect?: DropEffects;
  renderCover?: (overFrame: boolean, overTarget: boolean) => JSX.Element | null
  isInteresting:  (event: DragEvent | ReactDragEvent<HTMLElement>) => boolean
}

export interface DroppableState {
  overFrame: boolean;
  overTarget: boolean;
}

export class Droppable extends React.PureComponent<DroppableProps, DroppableState> {


  constructor(props: DroppableProps) {
    super(props);
    this.frameDragCounter = 0;
    this.state = { overFrame: false, overTarget: false };
  }

  componentDidMount() {
    this.startFrameListeners(this.props.frame || window.document);
    this.resetDragging();
    window.addEventListener('dragover', this.handleWindowDragOverOrDrop);
    window.addEventListener('drop', this.handleWindowDragOverOrDrop);
  }

  componentWillUnmount() {
    this.stopFrameListeners(this.props.frame || window.document);
    window.removeEventListener('dragover', this.handleWindowDragOverOrDrop);
    window.removeEventListener('drop', this.handleWindowDragOverOrDrop);
  }

  frameDragCounter: number;

  resetDragging = () => {
    this.frameDragCounter = 0;
    this.setState({ overFrame: false, overTarget: false });
  };

  handleWindowDragOverOrDrop = (event: DragEvent) => {
    // This prevents the browser from trying to load whatever file the user dropped on the window
    event.preventDefault();
  };

  handleFrameDrag = (event: DragEvent) => {
    // Only allow dragging of files
    if (!this.props.isInteresting(event)) return;

    // We are listening for events on the 'frame', so every time the user drags over any element in the frame's tree,
    // the event bubbles up to the frame. By keeping count of how many "dragenters" we get, we can tell if they are still
    // "overFrame" (b/c you get one "dragenter" initially, and one "dragenter"/one "dragleave" for every bubble)
    // This is far better than a "dragover" handler, which would be calling `setState` continuously.
    this.frameDragCounter += event.type === 'dragenter' ? 1 : -1;

    if (this.frameDragCounter === 1) {
      this.setState({ overFrame: true });
      if (this.props.onFrameDragEnter) this.props.onFrameDragEnter(event);
      return;
    }

    if (this.frameDragCounter === 0) {
      this.setState({ overFrame: false });
      if (this.props.onFrameDragLeave) this.props.onFrameDragLeave(event);
      return;
    }
  };

  handleFrameDrop = (event: DragEvent) => {
    if (!this.state.overTarget) {
      this.resetDragging();
      if (this.props.onFrameDrop) this.props.onFrameDrop(event);
    }
  };

  handleDragOver: ReactDragEventHandler<HTMLDivElement> = (event) => {
    if (this.props.isInteresting(event)) {
      this.setState({ overTarget: true });
      if (this.props.dropEffect)
        event.dataTransfer.dropEffect = this.props.dropEffect;
      if (this.props.onDragOver) this.props.onDragOver(event);
    }
  };

  handleDragLeave: ReactDragEventHandler<HTMLDivElement> = (event) => {
    this.setState({ overTarget: false });
    if (this.props.onDragLeave) this.props.onDragLeave(event);
  };

  handleDrop: ReactDragEventHandler<HTMLDivElement> = (event) => {
    if (this.props.onDrop && this.props.isInteresting(event)) {
      this.props.onDrop(event);
    }
    this.resetDragging();
  };

  stopFrameListeners = (frame: DroppableProps['frame']) => {
    if (frame) {
      frame.removeEventListener('dragenter', this.handleFrameDrag);
      frame.removeEventListener('dragleave', this.handleFrameDrag);
      frame.removeEventListener('drop', this.handleFrameDrop);
    }
  };

  startFrameListeners = (frame: DroppableProps['frame']) => {
    if (frame) {
      frame.addEventListener('dragenter', this.handleFrameDrag);
      frame.addEventListener('dragleave', this.handleFrameDrag);
      frame.addEventListener('drop', this.handleFrameDrop);
    }
  };

  render() {
    const {
      children,

    } = this.props;
    const { overFrame, overTarget } = this.state;

    const child =
    typeof children === "function"
      ? children(overFrame, overTarget)
      : children;

    const props = {
      onDragOver: this.handleDragOver,
      onDragLeave:this.handleDragLeave,
      onDrop:this.handleDrop
    }

    return <div {...props} style={{
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      pointerEvents: overFrame ? 'auto' : 'none'
      }}>
      {child}
      {this.props.renderCover?.(this.state.overFrame, this.state.overTarget)}
    </div>

  }
}

export class Draggable extends React.PureComponent<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      dragging: false
    }
  }

  onDragStart = (e) => {

    this.setState({dragging: true})

    this.props.onDragStart?.(e)
  }

  onDragEnd = (e) => {

    this.setState({dragging: false})

    this.props.onDragEnd?.(e)
  }

  render() {
    const { style, onDragStart, onDragEnd, disableDrag, ...otherProps} = this.props

    return <div draggable={!disableDrag} style={{cursor: !disableDrag ? this.state.dragging ? 'grabbing' : 'grab' : 'default', ...style}} onDragStart={this.onDragStart} onDragEnd={this.onDragEnd} {...otherProps}>
      {this.props.children}
    </div>
  }
}

import { observer } from "mobx-react";
import React, { Component } from "react";
import { toArray } from "react-emoji-render";
import { StyleSheet, ViewStyle, ListRenderItemInfo, Clipboard, View, Platform, ScrollView } from "react-native";
import { FileDrop } from "../FileDrop";
import { OTTouchableOpacity } from "../buttons";
import { Theme } from "../../globals/ThemeColors";
import HeadIcon from "../HeadIcon";
import { OTText, OTTextInput, TTextInput } from "../OTText";
import { Feather } from '@expo/vector-icons';
import { PlatformOS } from "../../utils/platform";
import { OTChatFile, OTChatFileUpload } from "./OTChatFile";
import { ChatFlatList } from "./ChatFlatList";
import { OTMarkdown } from "../OTMarkdown";
import Hoverable from "../Hoverable";
import { OTButton } from "../buttons/OTButtons";
import { IFile, IMessageFile, IOTChatMessage, IOTUser, IPendingMessage } from "@openteam/models";
import { OTLinkPreview } from "../OTLinkPreview";
import { showToast } from "../Toasts";
import { ImagePickerResponse, launchImageLibrary, MediaType, PhotoQuality } from 'react-native-image-picker';
import { EmojiPicker } from "../EmojiPicker";
import { GifPicker } from "../GifPicker";
import { WebFile } from "../../utils/WebFile";
import { showConfirm } from '../../components/ModalService';
import { OTUITree } from "@openteam/app-core";
import { SKVideo } from "../SVGIcons";

interface IOTChatProps {
  title?: string
  subTitle?: string | null
  titleImageUrl?: string
  showUserImage?: boolean
  chatHeaderLeft?: JSX.Element
  onTitlePress?: () => void
  lastReadMessageId?: number
  messages: IOTChatMessage[]
  sendMessage: (text, files?, replyMessage?) => void
  editMessage?: (messageId, text) => void
  deleteMessage?: (messageId) => void
  isTypingUsers?: IOTUser[]
  draftText: string
  draftReplyMessage?: IOTChatMessage
  setDraftText: (text) => void
  setDraftReplyMessage?: (message?: IOTChatMessage) => void
  draftFiles: IMessageFile[]
  setDraftFiles: (files: IMessageFile[]) => void
  pendingMessages: { [id: string]: IPendingMessage }
  onClose: () => void
  maxHeight?: string | number
  height?: string | number
  loadMore?: () => void
  onFiles?: (files: IFile[] | File[] | FileList | null) => void
  fullHeight?: boolean
  outerStyle?: ViewStyle
  showHeader?: boolean
  toggleMuteNotify?: () => void
  muteNotify?: boolean
  startRoom?: () => void
  hasRoom?: boolean
  isCalling?: boolean
  renderSubHeader?: () => JSX.Element
  renderSubFooter?: () => JSX.Element
  startTopic?: (message?: IOTChatMessage) => void
}

interface IOTChatState {
}

@observer
export class OTChat extends Component<IOTChatProps, IOTChatState> {
  _fileInput: any
  composeRef: any = React.createRef<OTChatCompose>();

  constructor(props) {
    super(props);

    this.state = {
      files: []
    }
  }

  componentDidMount = () => {
    // run this when autofocus is enabled otherwise it doesn't get run until next time
    // this.onFocus()

  }

  replyMessage = (message?: IOTChatMessage) => {
    if (message) {
      const { replyMessage, ...restMessage } = message
      this.props.setDraftReplyMessage?.(restMessage)
      this.composeRef.current?.focus()
    } else {
      this.props.setDraftReplyMessage?.()
    }
  }

  sendChatMsg = async () => {
    if (this.props.draftText || this.props.draftFiles?.length > 0) {
      this.props.sendMessage(this.props.draftText, this.props.draftFiles, this.props.draftReplyMessage)
      this.props.setDraftText('')
      this.props.setDraftReplyMessage?.()
      this.props.setDraftFiles([])

    }
  }


  render() {

    return (
      <View
        style={{
          backgroundColor: Theme.ChatBackground,
          overflow: 'hidden',
          flex: 1,
          ...this.props.outerStyle

        }}>
        {
          this.props.showHeader ?
            this.renderHeader()
            :
            null
        }
        {
          this.props.renderSubHeader ?
            this.props.renderSubHeader()
            :
            null
        }

        <View style={[styles.bodyContainer, { backgroundColor: Theme.ChatBody }]}>
          {this.renderMessages()}
          {
            this.props.renderSubFooter ?
              this.props.renderSubFooter()
              :
              null
          }

          {this.renderCompose()}
          {
            PlatformOS != 'mobile' ?
              <FileDrop
                onDrop={this.props.onFiles}
                dropEffect={'copy'}
                renderCover={(overFrame, overTarget) => {
                  return this.props.onFiles && overFrame ?
                    <View
                      pointerEvents="none"
                      style={{
                        ...StyleSheet.absoluteFillObject,
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        borderWidth: 2,
                        borderColor: 'grey',
                        borderStyle: 'dotted',
                        borderRadius: Theme.curviness,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                      {
                        overTarget ?
                          <Feather name="upload" size={40} style={{ color: overTarget ? 'red' : 'grey' }} />
                          :
                          null
                      }
                    </View>
                    :
                    null
                }}
              />
              :
              null
          }
        </View>

      </View>

    )
  }


  renderHeader = () => {
    return (
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        height: 50,
        marginHorizontal: 10,
      }}>
        <View >
          {
            this.props.chatHeaderLeft ?
              this.props.chatHeaderLeft
              :
            this.props.title ?
              <HeadIcon name={this.props.title || "Chat Messages"} imageUrl={this.props.titleImageUrl} size={30} />

              :
              null
          }
        </View>

        <OTTouchableOpacity onPress={this.props.onTitlePress} disabled={!this.props.onTitlePress} style={{ alignItems: 'center', flex: 1, paddingLeft: 10, }}>

          <OTText style={{
            textAlign: 'center',

            fontWeight: "600"
          }}>
            {
              this.props.title ?
                this.props.title
                :
                "Chat Messages"
            }
          </OTText>
          {
            this.props.subTitle ?
              <OTText
                style={{ textAlign: 'center', fontSize: 12 }}
                numberOfLines={1}
                // @ts-expect-error dataSet not part of types
                dataSet={{ tip: this.props.subTitle }}>
                {this.props.subTitle}
              </OTText>
              :
              null
          }
        </OTTouchableOpacity>
        {
          this.props.startRoom ?
            <OTButton
              toolTip={this.props.hasRoom ? "Call already started":"Start Call"}
              small={true}
              onPress={this.props.startRoom}
              textColor={this.props.hasRoom ? Theme.ButtonDisabledColor : Theme.ButtonColor}
              borderColor={this.props.hasRoom ? Theme.ButtonDisabledColor : Theme.ButtonColor}
              backgroundColor="transparent"
              disabled={this.props.hasRoom}
              icon={
                <SKVideo size={18} style={{ color: this.props.isCalling ? 'red' : this.props.hasRoom ? Theme.ButtonDisabledColor : Theme.ButtonColor }} />
              }
            />
            :
            null
        }
        {
          this.props.toggleMuteNotify ?
            <OTButton
              toolTip={this.props.muteNotify ? "Notifications Muted" : "Notify on message"}
              small={true}
              onPress={this.props.toggleMuteNotify}
              textColor={Theme.ButtonColor}
              borderColor={Theme.ButtonColor}
              outerStyle={{marginLeft: 10}}
              backgroundColor="transparent"
              icon={<Feather name={this.props.muteNotify ? "bell-off" : "bell"}
                size={18}
                style={{ color: Theme.ButtonColor }} />} />
            :
            null
        }

        <OTTouchableOpacity style={{ width: 30, justifyContent: 'center', alignItems: 'center' }} onPress={this.props.onClose}>
          <Feather name="x" size={20} style={{ color: Theme.HeaderIcon }} />
        </OTTouchableOpacity>
      </View>
    )
  }

  renderMessages = () => {
    return (
      <ChatFlatList
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        onEndReached={this.props.loadMore}
        style={{ flex: 1 }}
        // style={{ minHeight: 300, maxHeight: this.props.maxHeight, height: this.props.maxHeight, flex: 1 }}
        data={this.props.messages}
        renderItem={this.renderItem}
        keyExtractor={(item: IOTChatMessage) => item.id}
        ListHeaderComponent={this.renderPendingMessages}
      />
    )
  }

  renderPendingMessages = () => {
    return (
      <OTChatPendingMessages pendingMessages={this.props.pendingMessages} />
    )
  }


  renderCompose = () => {

    return (
      <OTChatCompose
        ref={this.composeRef}
        key={"compose"}
        autofocus={PlatformOS == 'mobile' ? false : true}
        backgroundColor={Theme.ChatCompose}
        isTypingUsers={this.props.isTypingUsers}
        draftText={this.props.draftText}
        setDraftText={this.props.setDraftText}
        draftReplyMessage={this.props.draftReplyMessage}
        setDraftReplyMessage={this.props.setDraftReplyMessage}
        draftFiles={this.props.draftFiles}
        setDraftFiles={this.props.setDraftFiles}
        sendChatMsg={this.sendChatMsg}
        onFiles={this.props.onFiles}
      />
    )
  }

  renderItem = ({ item, index }: ListRenderItemInfo<IOTChatMessage>) => {

    const prevItem = (index + 1) < this.props.messages.length && this.props.messages[index + 1] || undefined
    const prevGrouped = prevItem && item.userId == prevItem.userId && !item.isSystem && !prevItem.isSystem && (item.crDate.getTime() - prevItem.crDate.getTime() < 60 * 1000)


    const nextItem = (index - 1) >= 0 && this.props.messages[index - 1] || undefined
    const nextGrouped = nextItem && item.userId == nextItem.userId && !item.isSystem && !nextItem.isSystem && (nextItem.crDate.getTime() - item.crDate.getTime() < 60 * 1000)

    return (
      <OTChatMessage
        message={item}
        prevGrouped={prevGrouped}
        nextGrouped={nextGrouped}
        isLastRead={item.messageId != undefined && this.props.lastReadMessageId == item.messageId}
        showUserImage={this.props.showUserImage || false}
        editMessage={this.props.editMessage}
        deleteMessage={this.props.deleteMessage}
        replyMessage={this.props.setDraftReplyMessage ? this.replyMessage : undefined}
        startTopic={this.props.startTopic}

      />
    )
  }
}



interface IOTChatPendingMessagesProps {
  pendingMessages: { [id: string]: IPendingMessage }
}

interface IOTChatPendingMessagesState {
}


@observer
export class OTChatPendingMessages extends Component<IOTChatPendingMessagesProps, IOTChatPendingMessagesState> {

  render() {
    const pendingMessages = Object.values(this.props.pendingMessages || {}).filter(item => Object.keys(item.files || {}).length > 0)
    if (pendingMessages.length > 0) {
      return (
        <View>
          <OTText>
            Sending Message:
            </OTText>
          {pendingMessages.map((item, index) => this.renderPendingMessage(item, index))}
        </View>
      )
    }
    return null

  }

  renderPendingMessage = (pendingMessage: IPendingMessage, index: number) => {
    return (
      <View key={`PendingMessage-${index}`} style={{ flexDirection: 'row' }}>
        {
          pendingMessage.files?.map((uf, index) =>
            <OTChatFileUpload key={`${index}-${uf.file.name}`} file={uf} />)
        }
      </View>
    )
  }
}


interface IOTChatMessageProps {
  message: IOTChatMessage
  prevGrouped?: boolean
  nextGrouped?: boolean
  showUserImage: boolean
  editMessage?: (messageId, text) => void
  deleteMessage?: (messageId) => void
  replyMessage?: (message?: IOTChatMessage) => void
  startTopic?: (message?: IOTChatMessage) => void
  isReply?: boolean
  isLastRead?: boolean
}

interface IOTChatMessageState {
  hovered: boolean
  editing: boolean
  message: string
}


@observer
export class OTChatMessage extends Component<IOTChatMessageProps, IOTChatMessageState> {

  constructor(props) {
    super(props);

    this.state = {
      hovered: false,
      editing: false,
      message: ''
    }

  }

  editMessage = () => {
    this.setState({
      editing: true,
      message: this.props.message.message
    })
  }

  copyMessage = () => {
    Clipboard.setString(this.props.message.message)
    showToast("Message copied to clipboard", "info")
  }

  deleteMessage = () => {
    showConfirm(
      `Are you sure you want delete this message?`,
      () => {
        this.props.deleteMessage?.(this.props.message.id)
      },
      () => { }
    )
  }

  replyMessage = () => {
    this.props.replyMessage?.(this.props.message)
  }

  startTopic = () => {
    this.props.startTopic?.(this.props.message)
  }

  saveChatMsg = () => {
    if (this.state.message != this.props.message.message) {
      this.props.editMessage?.(this.props.message.id, this.state.message)
    }

    this.setState({
      editing: false,
    })
  }

  showMenu = () => {
    this.setState({ hovered: !this.state.hovered })
  }

  render() {

    if (PlatformOS == 'mobile') {
      return (
        <OTTouchableOpacity onLongPress={this.showMenu}>
          {this.renderContent()}
        </OTTouchableOpacity>
      )
    }

    return (
      <Hoverable onHoverIn={() => this.setState({ hovered: true })} onHoverOut={() => this.setState({ hovered: false })}>
        {this.renderContent()}
      </Hoverable>
    )
  }

  renderContent = () => {
    const { message, prevGrouped, nextGrouped, isLastRead, isReply} = this.props

    return (
      <View>
        <View style={{
          flexDirection: 'row',
          alignItems: 'flex-start',
          paddingVertical: 4,
          paddingHorizontal: 10,
          ...(!prevGrouped) && {
            paddingTop: 8
          },
          ...(!nextGrouped) && {
            paddingBottom: 8
          },
          ...this.props.isReply && {
            paddingLeft: 10,
            borderLeftWidth: 4,
            borderColor: Theme.MainText,
          },
          ...this.state.hovered && {
            backgroundColor: 'rgba(0,0,0,0.02)'
          }
        }}>
          {
            prevGrouped ?
              <View style={{ width: 36 }}/>
            :
              <HeadIcon imageUrl={message.userImageUrl} name={message.name} size={36} />
          }

          <View style={{
            flex: 1,
            paddingHorizontal: 12,
          }}>

            {
              prevGrouped ?
                null
                :
                <OTChatMessageHeader message={message} />
            }
            {
              this.state.editing ?
                this.renderEditMessage()
                :
                <OTChatMessageBody message={message} />
            }

          </View>
          {
            this.state.hovered && !isReply ?
              this.renderMessageControls()
              :
              null
          }
        </View>
        {
          isLastRead ?
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <OTText style={{ color: Theme.MainColour }}><Feather name="chevron-down" /> New Messages <Feather name="chevron-down" /></OTText>
            </View>
            :
            null
        }
      </View>
    )
  }

  renderMessageControls = () => {
    const message = this.props.message

    const isUser = message.userId == OTUITree.auth.userId

    return (
      <View style={{ position: 'absolute', right: 0, top: 0, flexDirection: 'row' }}>

        {
          this.props.replyMessage ?
            <OTTouchableOpacity onPress={this.replyMessage} style={{ marginHorizontal: 5, }}>
              <Feather name="corner-up-left" size={19} style={{ color: Theme.MainText }} />
            </OTTouchableOpacity>
            :
            null
        }

        {
          this.props.startTopic?
            <OTTouchableOpacity onPress={this.startTopic} style={{ marginHorizontal: 5, }}>
              <Feather name="hash" size={19} style={{ color: Theme.MainText }} />
            </OTTouchableOpacity>
            :
            null
        }
        {
          PlatformOS == 'mobile' ?
            <OTTouchableOpacity onPress={this.copyMessage} style={{ marginHorizontal: 5, }}>
              <Feather name="copy" size={19} style={{ color: Theme.MainText }} />
            </OTTouchableOpacity>
            :
            null
        }

        {
          isUser && this.props.editMessage && !message.isSystem ?
            <OTTouchableOpacity onPress={this.editMessage} style={{ marginHorizontal: 5, }}>
              <Feather name="edit-3" size={19} style={{ color: Theme.MainText }} />
            </OTTouchableOpacity>
            :
            null
        }

        {
          PlatformOS != 'mobile' && isUser && !message.isSystem && this.props.deleteMessage ?
            <OTTouchableOpacity onPress={this.deleteMessage} style={{ marginHorizontal: 5, }}>
              <Feather name="trash-2" size={19} style={{ color: Theme.MainText }} />
            </OTTouchableOpacity>
            :
            null
        }

      </View>
    )
  }

  renderEditMessage = () => {
    const { message } = this.props
    return (
      <View style={{ flexDirection: 'row', flex: 1 }}>
        <View style={{ flex: 1 }}>
          <View style={{ flexDirection: 'row', paddingVertical: 5 }}>

            <OTTextInput
              value={this.state.message}
              onChangeText={(value) => this.setState({ message: value })}
              onSubmitEditing={this.saveChatMsg}
              multiline={true}
              style={{
                flex: 1,
                backgroundColor: Theme.ChatCompose,
                padding: 10,
                borderWidth: 0,
                width: undefined
              }} />
          </View>

          <View style={{ flexDirection: 'row' }}>
            <OTButton small={true} title="Cancel" onPress={() => this.setState({ editing: false })} backgroundColor={"transparent"} borderColor={Theme.ButtonNegativeColor} textColor={Theme.ButtonNegativeColor} />
            <View style={{ padding: 2 }} />
            <OTButton small={true} title="Save Message" onPress={this.saveChatMsg} />
          </View>
        </View>

      </View>
    )
  }
}


interface IOTChatMessageHeaderProps {
  message: IOTChatMessage
}

interface IOTChatMessageHeaderState {
}


@observer
class OTChatMessageHeader extends Component<IOTChatMessageHeaderProps, IOTChatMessageHeaderState> {
  render() {

    const { message } = this.props

    const today = new Date()

    var d = message.crDate;

    let dateString = ""

    if (d) {
      if (today.toDateString() == d?.toDateString()) {
        dateString = d.toLocaleString('en-GB', { hour: "numeric", minute: "numeric" })

      } else {
        dateString = d.toLocaleString('en-GB', { month: 'short', day: 'numeric', hour: "numeric", minute: "numeric" })
      }
    }

    return (
      <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
        <OTText style={{ fontWeight: 'bold', fontSize: 14, color: Theme.MainText }}>{message.name}</OTText>
        <OTText style={{ marginLeft: 5, fontSize: 12, color: Theme.SecondaryText }}>
          {dateString}
        </OTText>

        {
          message.editDate ?
            <OTText style={{ marginLeft: 5, fontSize: 12, color: Theme.SecondaryText }}>
              - edited
            </OTText>
            :
            null
        }

        {
          message.isSystem ?
            <OTText style={{ marginLeft: 5, fontSize: 12, color: Theme.MainColour }}>
              System Message
            </OTText>
            :
            null
        }


      </View>
    )
  }
}

interface IOTChatMessageBodyProps {
  message: IOTChatMessage
}

interface IOTChatMessageBodyState {
}


@observer
export class OTChatMessageBody extends Component<IOTChatMessageBodyProps, IOTChatMessageBodyState> {
  render() {

    const { message } = this.props
    const emojiResult = parseEmojis(message.message || "")

    return (
      <>
        {
          message.files?.map((file, index) =>

            <OTChatFile key={`${file.name}-${index}`} file={file} />
          )
        }
        {
          emojiResult.isOnlyEmoji ?
            <OTText style={{ fontSize: 36 }}>
              {emojiResult.value}
            </OTText>
            :
            message.isSystem ?
              <OTText style={{ color: Theme.SecondaryText, fontStyle: 'italic' }}>
                {message.message}
              </OTText>
              :
              <OTMarkdown>{message.message}</OTMarkdown>
        }
        {
          this.props.message.linkPreview ?
            <OTLinkPreview linkPreview={this.props.message.linkPreview} />
            :
            null
        }

        {
          this.props.message.replyMessage ?
            <OTChatMessage
              message={this.props.message.replyMessage}
              showUserImage={true}
              isReply={true}
            />
            : null
        }

      </>
    )
  }
}

const acceptedImageFiles = ['image/gif', 'image/png', 'image/jpeg', 'image/bmp']

interface IOTChatComposeProps {
  autofocus?: boolean
  backgroundColor: string
  borderColor?: string
  defaultHeight?: number
  isTypingUsers?: IOTUser[]
  draftText: string
  setDraftText: (text) => void
  draftReplyMessage?: IOTChatMessage
  setDraftReplyMessage?: (message?: IOTChatMessage) => void
  draftFiles: IMessageFile[]
  setDraftFiles: (files: IMessageFile[]) => void
  onFiles?: (files: IFile[] | File[] | FileList | null) => void
  sendChatMsg: () => void
  disableSendOnEnter?: boolean
}

interface IOTChatComposeState {
  showAssetPicker: 'emoji' | 'gif' | undefined;
  selection: { start: number; end: number };
}

@observer
export class OTChatCompose extends Component<IOTChatComposeProps, IOTChatComposeState> {
  _fileInput: any;
  _textInput: any = React.createRef<TTextInput>();
  _watchingPaste = false;

  constructor(props) {
    super(props);

    this.state = {
      showAssetPicker: undefined,
      selection: { start: 0, end: 0 },
    };
  }

  componentDidMount = () => {
    if (this.props.autofocus) {
      this.watchPaste();
    }
  };

  componentWillUnmount = () => {
    this.unwatchPaste();
  };

  focus = () => {
    this._textInput.current?.focus();
  };

  onFocus = () => {
    this.watchPaste();
  };
  onBlur = () => {
    this.unwatchPaste();
  };

  watchPaste = () => {
    if (PlatformOS != "mobile") {
      if (!this._watchingPaste) {
        window.addEventListener("paste", this.onPaste, false);
        this._watchingPaste = true;
      }
    }
  };
  unwatchPaste = () => {
    if (PlatformOS != "mobile") {
      if (this._watchingPaste) {
        window.removeEventListener("paste", this.onPaste);
        this._watchingPaste = false;
      }
    }
  };

  onPaste = (pasteEvent) => {
    for (let item of pasteEvent.clipboardData.items) {
      if (acceptedImageFiles.includes(item.type)) {
        var blob = item.getAsFile() as File;
        console.log("got pasted image,");
        this.props.onFiles?.([blob]);
      }
    }
  };

  removeDraftFile = (index) => {
    var newDraftFiles = [...this.props.draftFiles];
    var uploadFiles = newDraftFiles.splice(index, 1);

    uploadFiles.forEach((uf) => uf.stop());

    this.props.setDraftFiles(newDraftFiles);
  };

  clearReplyMessage = () => {
    this.props.setDraftReplyMessage?.();
  };

  getImage = () => {
    const options = {
      maxWidth: 1280,
      maxHeight: 1280,
      quality: 0.8 as PhotoQuality,
      mediaType: "photo" as MediaType
    }
    launchImageLibrary(options, this.hdlImage)

  }

  hdlImage = async (result:  ImagePickerResponse) => {
    console.log(result);

    if (!result.didCancel && result.uri && result.type && result.fileSize) {

      const url =  result.uri
      const fileName = this.getFileName(result.fileName, url);

      let file: IFile = {
        name: fileName,
        url: url,
        size: result.fileSize,
        type: result.type,
      }

      this.props.onFiles?.([file])

    }
  };


  getFileName(name, path) {
    if (name != null) { return name; }

    if (Platform.OS === "ios") {
      path = "~" + path.substring(path.indexOf("/Documents"));
    }
    return path.split("/").pop();
  }


  addEmoji = (emoji) => {
    const curText = this.props.draftText || "";

    const start = this.state.selection ? this.state.selection.start : curText.length;
    const end = this.state.selection ? this.state.selection.end : curText.length;

    const newText = curText.slice(0, start) + emoji + curText.slice(end);

    this.props.setDraftText(newText);
    this.focus()

  };

  addGif = (title, url, size) => {
    const newFile = new WebFile({
      name: title,
      url: url,
      size: size,
      type: "image/gif"
    })
    console.log("adding gif", title, url, size, newFile)


    var newDraftFiles = [...this.props.draftFiles, newFile];

    this.props.setDraftFiles(newDraftFiles);
  }

  sendMessage = () => {
    this.props.sendChatMsg();
    if (this.state.showAssetPicker) {
      this.setState({ showAssetPicker: undefined });
    }
  };

  render() {
    return (
      <View style={{ paddingHorizontal: 12, paddingTop: 12 }}>
        <View
          style={{
            backgroundColor: this.props.backgroundColor,
            borderRadius: Theme.curviness / 1.5,
            borderColor: this.props.borderColor,
            borderWidth: this.props.borderColor ? 1 : undefined,
          }}
        >
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {this.props.draftFiles &&
              this.props.draftFiles.map((uf, index) => (
                <OTChatFileUpload
                  key={`${index}-${uf.file.name}`}
                  file={uf}
                  onRemove={() => this.removeDraftFile(index)}
                />
              ))}
          </View>
          {this.state.showAssetPicker == 'emoji' ? <EmojiPicker onEmojiSelected={this.addEmoji} /> : null}
          {this.state.showAssetPicker == 'gif' ? <GifPicker onGifSelected={this.addGif} /> : null}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >

            {PlatformOS != "mobile" ?
              <OTTouchableOpacity
                onPress={() => this.setState({ showAssetPicker: this.state.showAssetPicker == 'emoji' ? undefined : 'emoji' })}
                style={{ paddingLeft: 10 }}
              >
                <Feather name="smile" size={20} style={{ color: this.state.showAssetPicker == 'emoji' ? Theme.MainColour : Theme.MainText }} />
              </OTTouchableOpacity>
              :
              null
            }

            <OTTouchableOpacity
              onPress={() => this.setState({ showAssetPicker: this.state.showAssetPicker == 'gif' ? undefined : 'gif' })}
              style={{ paddingLeft: 10 }}
            >
              <OTText style={{ fontWeight: 'bold', color: this.state.showAssetPicker == 'gif' ? Theme.MainColour: Theme.MainText }}>GIF</OTText>
            </OTTouchableOpacity>

            {PlatformOS != "mobile" ? (
              this.props.onFiles ? (
                <OTTouchableOpacity
                  onPress={() => this._fileInput && this._fileInput.click()}
                  style={{ paddingLeft: 10 }}
                >
                  <input
                    ref={(c) => (this._fileInput = c)}
                    style={{ display: "none" }}
                    type="file"
                    multiple
                    onChange={(e) => this.props.onFiles?.(e.target.files)}
                  />
                  <Feather name="paperclip" size={20} style={{ color: Theme.MainText }} />
                </OTTouchableOpacity>
              ) : null
            ) :
              <OTTouchableOpacity onPress={this.getImage} style={{ paddingLeft: 10 }}>
                <Feather name="image" size={20} style={{ color: Theme.MainText }} />
              </OTTouchableOpacity>

            }

            <OTTextInput
              ref={this._textInput}
              style={{
                flex: 1,
                padding: 10,
                paddingTop: 11,
                borderWidth: 0,
                height: this.props.defaultHeight,
                width: undefined,
              }}
              autoFocus={this.props.autofocus}
              blurOnSubmit={false}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              multiline={true}
              placeholder="Type your message here..."
              value={this.props.draftText || ""}
              onChangeText={this.props.setDraftText}
              onSubmitEditing={this.props.disableSendOnEnter ? undefined : this.props.sendChatMsg}
              selection={PlatformOS == "mobile" ? undefined : this.state.selection}
              onSelectionChange={PlatformOS == "mobile" ? undefined : ({ nativeEvent: { selection } }) => {
                this.setState({ selection });
              }}
            />
            <View style={{ paddingRight: 10, flexDirection: "row", alignItems: "center" }}>
              <OTTouchableOpacity
                onPress={this.sendMessage}
                style={{
                  height: 25,
                  width: 25,
                  backgroundColor: Theme.ButtonColor, //(this.props.draftText ? this.props.draftText.length : 0) > 0 ? Theme.ButtonColor : Theme.ButtonDisabledColor,
                  borderRadius: 13,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                disabled={!(this.props.draftText || this.props.draftFiles)}
              >
                <Feather
                  name="send"
                  size={14}
                  style={{ color: Theme.ButtonIconColor, marginLeft: -1, marginBottom: -1 }}
                />
              </OTTouchableOpacity>
            </View>
          </View>

          {this.props.draftReplyMessage ? (
            <View style={{ marginLeft: 15 }}>
              <OTChatMessage
                message={this.props.draftReplyMessage}
                showUserImage={true}
                isReply={true}
                replyMessage={this.props.setDraftReplyMessage}
              />
              <View style={{ position: "absolute", right: 20, top: 10, flexDirection: "row" }}>
                <OTTouchableOpacity onPress={this.clearReplyMessage}>
                  <Feather name="x" size={19} style={{ color: Theme.MainText }} />
                </OTTouchableOpacity>
              </View>
            </View>
          ) : null}
        </View>
        <View style={{ height: 18, paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>
          {this.props.isTypingUsers && this.props.isTypingUsers.length > 0 ? (
            <OTText style={{ fontSize: 12 }}>
              <OTText style={{ fontWeight: "bold" }}>
                {this.props.isTypingUsers?.map((userObj) => userObj.name).join(", ")}
              </OTText>{" "}
              {this.props.isTypingUsers.length > 1 ? "are" : "is"} Typing
            </OTText>
          ) : null}
        </View>
      </View>
    );
  }
}



export function parseEmojis(value: string): { value: any, isOnlyEmoji: boolean } {
  const emojisArray = toArray(value);

  var isOnlyEmoji = true

  // toArray outputs React elements for emojis and strings for other
  const newValue = emojisArray.reduce((previous, current: any) => {
    if (typeof current === "string") {
      isOnlyEmoji = false
      return previous + current;
    }
    return previous + current.props.children;
  }, "");

  return { value: newValue, isOnlyEmoji: isOnlyEmoji };
};

const styles = StyleSheet.create({
  bodyContainer: {
    flex: 1,
    // borderTopLeftRadius: Theme.curviness * 2,
    // borderTopRightRadius: Theme.curviness * 2,
    // paddingTop: 15
  }

})
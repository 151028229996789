import { computed } from "mobx";
import { OTGlobals } from "../OTGlobals";

import { AppHomeManagerStatus } from "./AppHomeManagerStatus";
import type { AppHomeManager } from "./AppHomeManager";

export class AppHomeUI {
  _appHomeManager: AppHomeManager;

  constructor(appHomeManager: AppHomeManager) {
    this._appHomeManager = appHomeManager;
  }

  init = async () => this._appHomeManager.init();
  start = async () => this._appHomeManager.start();
  stop = async () => this._appHomeManager.stop();
  signOut = async () => this._appHomeManager.signOut();
  leaveTeam = async (teamId: string) => this._appHomeManager.leaveTeam(teamId);
  
  get teamManagers() {
    return this._appHomeManager.teamManagers;
  }

  get feedbackManager() {
    return this._appHomeManager.feedbackManager;
  }

  get inCall() {
    return this._appHomeManager.inCall;
  }

  get started() {
    return (
      this._appHomeManager.status === AppHomeManagerStatus.Running ||
      this._appHomeManager.status === AppHomeManagerStatus.Starting
    );
  }

  @computed get badgeNum() {
    return Object.values(this.teamManagers)
      .map((teamManager) => teamManager.teamData.badgeNum)
      .reduce((a, b) => a + b, 0);
  }

  @computed get badgeNumOther() {
    return Object.keys(this.teamManagers)
      .filter((teamId) => teamId != OTGlobals.auth.userManager.currentTeamId)
      .map((teamId) => this.teamManagers[teamId].teamData.badgeNum)
      .reduce((a, b) => a + b, 0);
  }
}

import firebase from "firebase/app";
import "firebase/database";

import { Logger } from "@openteam/app-util";

const logger = new Logger("UserSettingsDb");

export class UserSettingsDb {
  static getUserSettings = async  (fbDb: firebase.database.Database, userId: string) => {
    const snapshot = await fbDb.ref(`/users/${userId}/settings`).once("value");
    return snapshot.val()
  };

  static setUserSettings = async (fbDb: firebase.database.Database, userId: string, settings: string) => {
    await fbDb.ref(`/users/${userId}/settings`).set(settings);
  };
}

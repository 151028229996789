import { isMobile } from 'react-device-detect';
import { Logger } from '@openteam/app-util';
import { AwaitLock } from "@openteam/app-util";
import * as platform from './platform'
import { IAudioContainer, INotification } from "@openteam/models";
import { OTGlobals, OTUITree } from "@openteam/app-core";
import { autorun } from 'mobx';

const logger = new Logger("Notify")
var notifications: {[id: string]: Notification} = {}
var notificationInit: boolean = false

export function initNotifications () {
  if (!notificationInit) {
    notificationInit = true

    if ('Notification' in window && !isMobile) {
      Promise.resolve(Notification.requestPermission()).then(function(permission) {
        logger.info(permission);
      });
    }
  }
}



export function showNotification(n: INotification) {
  logger.info("isMobile", isMobile)
  if (!('Notification' in window ) || isMobile) {
    return
  }

  initNotifications()

  var img = '../assets/icon.png';
  var notification = new Notification(
    n.title + " - OpenTeam",
    {
       body: n.text,
       icon: n.imageUrl || img,
       requireInteraction: n.id ? true : false,
       silent: true
    });
  notification.onclick = function(x) {
    platform.focusSelf()
    this.close();
    OTUITree.userManager.setCurrentTeam(n.teamId);
    n.onPress && n.onPress()
  };

  if (n.id) {
    notifications[n.id] = notification
  }
}

export function hideNotification(id) {
  if (notifications[id]) {
    notifications[id].close()
  }
}

//noises

export class AudioContainer implements IAudioContainer {
  _player: HTMLAudioElement;
  _stateLock = new AwaitLock();

  constructor() {
    this._player = new Audio();
    this.setSink()

    this._player.play();

    autorun(this.setSink, { delay: 1000 });

  }

  setSink = () => {
    const deviceId = OTGlobals.mediaDevices.audiooutput?.deviceId;
    if (this._player.setSinkId) {
      this._player.setSinkId(deviceId);
    }
  }

  play = async (src?: string) => {
    await this._stateLock.acquireAsync();
    logger.debug("audio play got lock");

    try {
      if (src) {
        this._player.src = src;
      }
      var promise = this._player.play();

      await promise;
    } finally {
      logger.debug("audio release lock");

      this._stateLock.release();
    }
  };

  setSource = async (src: string, loop?: boolean) => {
    await this._stateLock.acquireAsync();

    try {
      if (src) {
        this._player.src = src;
      }
      this._player.loop = loop ? true : false;

      logger.debug("set source");
    } finally {
      this._stateLock.release();
    }
  };

  pause = async () => {
    await this._stateLock.acquireAsync();
    try {
      await this._player.pause();
      this._player.src = "";
    } finally {
      this._stateLock.release();
    }
  };
}

var soundEffect: AudioContainer
var incomingRing: AudioContainer
var outgoingRing: AudioContainer

var soundInited = false
export function initSoundEffects() {
  if (soundInited) {
    return
  }
  soundInited = true

  soundEffect = new AudioContainer()
  incomingRing = new AudioContainer()
  outgoingRing = new AudioContainer()
}


export function playGlobalAlert() {
  var chimemp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/globalchime.mp3?alt=media"
  soundEffect.play(chimemp3);
}

export function playAlert() {
  var chimemp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/me-too.mp3?alt=media"
  soundEffect.play(chimemp3);
}

export function playVideoBell() {
  var chimemp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/notify%2Fting.mp3?alt=media"
  soundEffect.play(chimemp3);
}

export function playKnock() {
  var chimemp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/knockknockknock.mp3?alt=media"
  soundEffect.play(chimemp3);
}

export function playNewMessage() {
  var chimemp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/notify%2Fmessage-notify.mp3?alt=media"
  soundEffect.play(chimemp3);
}

export function playUnmute() {
  var unmutemp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/notify%2Funmute.mp3?alt=media"
  soundEffect.play(unmutemp3);
}

export function getIncomingRingtone() {
  const incomingmp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/notify%2Fincoming-call.mp3?alt=media"
  incomingRing.setSource(incomingmp3, true)

  return incomingRing
}

export function getOutgoingRingtone() {
  const outgoingmp3 = "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/notify%2Foutgoing-call.mp3?alt=media"
  outgoingRing.setSource(outgoingmp3, true)

  return outgoingRing
}

export function playTestAudio() {
  const testmp3 =
    "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Ftestaudio.mp3?alt=media";
    soundEffect.play(testmp3);

}
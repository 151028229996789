
import React, { Component } from "react";

import {
  View,
} from "react-native";
import { OTButton } from "./buttons/OTButtons";
import { OTText } from "./OTText";


interface IDialogButton {
  title: string;
  onPress: () => void;
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  outlined?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

interface IDialogViewProps {
  title: string;
  buttons?: IDialogButton[];
  footer?: JSX.Element;
}
interface IDialogViewState {}

export class DialogView extends Component<IDialogViewProps, IDialogViewState> {
  render() {
    const { title, buttons } = this.props;
    return (
      <View style={{ alignItems: "center" }}>
        <OTText
          style={{
            fontSize: 18,
            fontWeight: "600",
            marginBottom: 20,
            marginTop: 5,
            textAlign: "center",
          }}
        >
          {title}
        </OTText>

        {this.props.children}

        {
          buttons ?
            <View style={{ flexDirection: "row", width: "100%", paddingTop: 20 }}>
              {(buttons || []).map((button) => {
                return (
                  <OTButton
                    key={button.title}
                    outerStyle={{ margin: 5 }}
                    backgroundColor={button.backgroundColor}
                    textColor={button.textColor}
                    borderColor={button.borderColor}
                    title={button.title}
                    outlined={button.outlined}
                    onPress={button.onPress}
                    loading={button.loading}
                    disabled={button.disabled}
                  />
                );
              })}
            </View>
            :
            null
        }
        {this.props.footer}
      </View>
    );
  }
}

import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ListRenderItemInfo, StyleSheet, FlatList, View, Text } from "react-native";
import { BadgeView } from '../../components/BadgeUtil';

import { OTAppData } from '../../data/OTData';

import * as Analytics from '../../utils/Analytics';
import { OTTouchableOpacity } from '../../components';
import { getTimeAgo, pluralise } from '../../utils/OTUtils';
import HeadIcon from '../../components/HeadIcon';
import { Theme } from '../../globals/ThemeColors';
import { OTText, OTTextInput } from '../../components/OTText';
import { CloudUpload, OTGlobals } from '@openteam/app-core';
import { IMessageFile, IFeedback, IFeedbackMessage } from "@openteam/models";
import { OTChatCompose, parseEmojis } from '../../components/chat/OTChat';
import { dismissModal, showModal } from '../../components/ModalService';
import { OTButton } from '../../components/buttons/OTButtons';

import { Feather } from '@expo/vector-icons';
import { SegmentedControl } from '../../components/SegmentedControl';
import { PlatformOS } from '../../utils/platform';
import { FileDrop } from '../../components/FileDrop';
import { ScrollView } from 'react-native-gesture-handler';
import { OTChatFile, OTChatFileUpload } from '../../components/chat';
import { OTMarkdown } from '../../components/OTMarkdown';
import { OTUITree } from '@openteam/app-core';
import { assertDefined } from "@openteam/app-util";
import { getRTDB } from '../../globals/Fire/util';
import { DialogView } from '../../components/DialogView';


const FeedbackTypes: { [id: string]: string } = {
  "general": "General",
  "feature": "Feature",
  "bug": "Bug",
  "announcement": "Announcement"
}

const FeedbackColors = {
  "general": Theme.FeedbackGeneral,
  "feature": Theme.FeedbackFeature,
  "bug": Theme.FeedbackBug,
  "announcement": Theme.FeedbackAnnouncement
}

interface IFeedbackButtonProps {
}

interface IFeedbackButtonState {
  showFeedback: boolean
}

@observer
export class FeedbackButton extends Component<IFeedbackButtonProps, IFeedbackButtonState> {
  constructor(props) {
    super(props);

    this.state = {
      showFeedback: false
    }
  }



  showFeedback = () => {
    showModal({
      contents: <FeedbackPage />,
    })
  }

  render() {
    const feedbackManager = OTUITree.appHomeUI.feedbackManager;

    return (
      <View style={{ marginLeft: 10 }}>
        <OTButton
          toolTip="Beta Community"
          small={true}
          icon={
            <Feather
              name="help-circle"
              size={14}
              style={{ color: Theme.ButtonColor, marginRight: 5 }}
            />
          }
          textColor={Theme.ButtonColor}
          borderColor={Theme.ButtonColor}
          backgroundColor="transparent"
          title="Beta Community"
          onPress={this.showFeedback}
        />
        <BadgeView
          badgeNum={feedbackManager?.numUnread}
          badgeSoftAlert={feedbackManager?.hasNew(OTUITree.userManager.userDoc)}
        />
      </View>
    );
  }
}



interface IFeedbackPageProps {
  feedbackId?: string
}

interface IFeedbackPageState {
  feedbackId?: string
  showCreate: boolean
}

@observer
export class FeedbackPage extends Component<IFeedbackPageProps, IFeedbackPageState> {
  constructor(props) {
    super(props);

    this.state = {
      feedbackId: this.props.feedbackId,
      showCreate: false
    }
  }



  goFeedback = (feedbackId) => {
    Analytics.logEvent("feedback_open", { feedbackId })
    this.setState({ feedbackId: feedbackId, showCreate: false })
  }

  goList = () => {
    this.setState({ feedbackId: undefined, showCreate: false })
  }

  showCreateFeedback = () => {
    this.setState({ showCreate: true })
  }

  render() {
    return (
      <View style={{
        minWidth: 400,
        width: "80vw",
        maxWidth: 800,
        minHeight: 350,
        height: "80vh",
        maxHeight: 600,
        backgroundColor: Theme.DialogBackground,
        borderRadius: Theme.curviness,
        overflow: 'hidden'
      }}>
        <View style={[pageStyles.headerRow, Theme.LightShadow]}>
          <View style={{ alignItems: 'center' }}>
            <OTText style={pageStyles.headerText}>Beta Community</OTText>
            <OTText style={{}}>Share feedback, bug reports and new feature ideas</OTText>
          </View>
        </View>

        <View style={{ margin: 15, flex: 1 }}>

          {
            this.state.showCreate ?
              <CreateFeedback onFeedback={this.goFeedback} />
              :
              this.state.feedbackId ?
                <Feedback feedbackId={this.state.feedbackId} onClose={this.goList} />
                :
                <FeedbackList goFeedback={this.goFeedback} />
          }

        </View>
        {
          this.state.feedbackId || this.state.showCreate ?
            <View style={{ position: 'absolute', left: 20, top: 15, flexDirection: 'row', alignItems: 'center' }}>
              <OTTouchableOpacity onPress={this.goList} >
                <Feather name="chevron-left" size={28} style={{ color: Theme.HeaderIcon }} />
              </OTTouchableOpacity>
            </View>
            :
            null
        }


        <View style={{ position: 'absolute', right: 20, top: 15, flexDirection: 'row', alignItems: 'center' }}>
          <OTButton small={true} title="New" icon={<Feather name="plus" size={20} style={{ color: Theme.ButtonIconColor }} />} onPress={this.showCreateFeedback} outerStyle={{ marginRight: 20 }} />

          <OTTouchableOpacity onPress={dismissModal} >
            <Feather name="x" size={18} style={{ color: Theme.HeaderIcon }} />
          </OTTouchableOpacity>
        </View>
      </View>
    )
  }
}





interface ICreateFeedbackProps {
  feedbackId?: string
  onFeedback: (feedbackId) => void
}

interface ICreateFeedbackState {
  title: string
  desc: string
  type: string,
  status: string,
  files: IMessageFile[]
}

@observer
export class CreateFeedback extends Component<ICreateFeedbackProps, ICreateFeedbackState> {
  _fileInput: any

  constructor(props) {
    super(props);

    this.state = {
      title: '',
      desc: '',
      type: 'general',
      status: 'active',
      files: [],
    }
  }


  createFeedback = async () => {
    if (this.state.title) {

      const feedbackUI = OTUITree.feedbackUI;
      assertDefined(feedbackUI);

      const teamId = OTUITree.userManager.currentTeamId;

      const userDoc =
        (teamId &&
          OTGlobals.getTeamData(teamId).me) ||
        OTUITree.userManager.userDoc;

      const username = userDoc?.name || "Unknown User";
      const imageUrl = userDoc?.imageUrl || null;

      if (this.props.feedbackId) {
        var feedbackId = this.props.feedbackId;

        await feedbackUI.updateFeedback(
          feedbackId,
          username,
          imageUrl,
          this.state.title,
          this.state.desc,
          this.state.type,
          this.state.status,
          this.state.files
        );
      } else {

        var feedbackId = await feedbackUI.addFeedback(
          username,
          imageUrl,
          this.state.title,
          this.state.desc,
          this.state.type,
          this.state.files
        );
      }
      this.props.onFeedback(feedbackId)
    }
  }

  onFiles = (files) => {


    var draftFiles = this.state.files ? [...this.state.files] : []
    Object.keys(files || {}).forEach(i => {
      var file = files[i]

      draftFiles.push(
        new CloudUpload(
          OTUITree.userManager.currentTeamId!,
          undefined,
          OTUITree.auth.userId,
          "chat",
          file
        )
      );

    })

    this.setState({ files: draftFiles })

  }

  removeFile = (index) => {
    var newDraftFiles = [...this.state.files]
    var uploadFiles = newDraftFiles.splice(index, 1)

    uploadFiles.forEach(uf => uf.stop())

    this.setState({ files: newDraftFiles })
  }

  render() {
    var feedbackTypes = { ...FeedbackTypes }

    if (!OTAppData.admins[OTUITree.auth.userId]) {
      delete feedbackTypes['announcement']
    }

    return (
      <View style={{ alignItems: 'center' }}>
        <DialogView
          title={"Create Feedback"}
          buttons={[
            {
              title: "Create",
              onPress: this.createFeedback,
              disabled: !this.state.title
            }
          ]}
        >
          <View style={{ minWidth: 500 }}>
            <View style={{ flexDirection: 'row', marginVertical: 10, }}>
              <OTTextInput
                style={{
                  width: undefined,
                  flex: 1
                }}
                value={this.state.title}
                onChangeText={(text) => this.setState({ title: text })}
                placeholder="Title"
              />
            </View>

            <View style={{}}>
              <OTText style={{ marginBottom: 10 }}>Feedback Type</OTText>
              <SegmentedControl
                segments={feedbackTypes}
                selectedSegment={this.state.type}
                onChange={(text) => this.setState({ type: text })} />
            </View>

            <View style={{ flexDirection: 'row', marginVertical: 10, }}>
              <OTTextInput
                style={{
                  width: undefined,
                  flex: 1,
                  minHeight: 100,
                  textAlignVertical: 'top',
                }}
                multiline={true}
                value={this.state.desc}
                onChangeText={(text) => this.setState({ desc: text })}
                placeholder="Description"
              />
            </View>
            {
              this.props.feedbackId ?
                <View style={{}}>
                  <OTText style={{ marginBottom: 10 }}>Status</OTText>
                  <SegmentedControl
                    segments={{ 'active': 'Active', 'confirmed': 'Confirmed', 'released': 'Released', 'closed': 'Closed' }}
                    selectedSegment={this.state.status}
                    onChange={(text) => this.setState({ status: text })} />
                </View>
                :
                null
            }

            <OTTouchableOpacity onPress={() => this._fileInput && this._fileInput.click()} style={{ paddingLeft: 10, flexDirection: 'row', alignItems: 'center' }} >
              <input ref={c => this._fileInput = c} style={{ display: 'none' }} type="file" multiple onChange={(e) => this.onFiles && this.onFiles(e.target.files)} />
              <Feather name="paperclip" size={14} style={{ color: Theme.MainText, marginRight: 5 }} />
              <OTText>Attach Files</OTText>
            </OTTouchableOpacity>

            {
              this.state.files ?
                <View style={{ flexDirection: 'row', marginTop: 10 }}>
                  {
                    this.state.files.map((uf, index) =>
                      <OTChatFileUpload key={`${index}-${uf.file.name}`} file={uf} onRemove={() => this.removeFile(index)} />)
                  }
                </View>
                :
                null
            }


          </View>
        </DialogView>
      </View>
    )
  }

}

interface IFeedbackListProps {
  goFeedback: (feedbackId) => void
}

interface IFeedbackListState {
  feedbackList: IFeedback[]
  feedbackIds: { [id: string]: boolean },

  loading: boolean
  atEnd: boolean
}

@observer
export class FeedbackList extends Component<IFeedbackListProps, IFeedbackListState> {
  constructor(props) {
    super(props);

    this.state = {
      feedbackList: [],
      feedbackIds: {},
      loading: false,
      atEnd: false,
    }
  }

  componentDidMount = () => {
    OTUITree.feedbackUI?.markFeedbackLastId(OTUITree.userManager.userDoc?.feedbackLastId);
    this.loadMore()
  }

  pageSize = 20

  loadMore = async () => {
    const feedbackUI = OTUITree.feedbackUI;
    assertDefined(feedbackUI);

    this.setState({
      loading: true,
    })

    try {
      console.log("loading feedback")

      var oldestMessage = this.state.feedbackList[this.state.feedbackList.length - 1]
      var feedbacks: { [id: string]: IFeedback } = await feedbackUI.getMoreFeedback(
        oldestMessage?.feedbackId,
        this.pageSize
      );

      var feedbackIds = { ...this.state.feedbackIds }

      var otFeedbackList = Object.values(feedbacks).filter(doc => !(doc.feedbackId in this.state.feedbackIds)).map(
        (doc) => {

          feedbackIds[doc.feedbackId] = true
          return doc
        }
      )

      this.setState({
        atEnd: otFeedbackList.length <= 1,
        loading: false,
        feedbackList: [...this.state.feedbackList, ...otFeedbackList.reverse()],
        feedbackIds: feedbackIds
      })

      console.log("loaded feedback", feedbackIds)


    } catch (e) {
      console.log("error loading feedback", e)
      this.setState({
        loading: false,
      })
    }

  }

  render() {

    return (
      <FlatList
        disableVirtualization
        style={{ flex: 1 }}
        data={this.state.feedbackList}
        renderItem={this.renderFeedback}
        keyExtractor={(item: IFeedback) => item.feedbackId}
        ListEmptyComponent={this.renderFeedbackEmpty}
        onEndReached={!this.state.loading && !this.state.atEnd ? this.loadMore : undefined}
      />
    )
  }

  renderFeedbackEmpty = () => {
    return <View style={{ alignItems: 'center' }}><OTText>No feedback</OTText></View>
  }

  renderFeedback = ({ item }: ListRenderItemInfo<IFeedback>) => {

    const feedbackItem = item


    return (

      <OTTouchableOpacity onPress={() => this.props.goFeedback(item.feedbackId)}
        style={[listStyles.item, { backgroundColor: Theme.OfficeBackground }]} >

        <View style={{ marginHorizontal: 10, flex: 1 }}>
          <OTText style={listStyles.title}>
            <OTText style={{ backgroundColor: FeedbackColors[feedbackItem.type], color: 'white', padding: 5, paddingHorizontal: 10, marginRight: 5, fontSize: 14, fontWeight: "500", borderRadius: Theme.curviness / 2 }}>
              {FeedbackTypes[feedbackItem.type]}
            </OTText>
            {feedbackItem.title}
          </OTText>

          <OTText style={{ marginVertical: 5, }} numberOfLines={5}>
            {feedbackItem.desc}
          </OTText>

          <OTText style={[listStyles.time,]}>
            {getTimeAgo(feedbackItem.crDate)} -  {Object.keys(feedbackItem.comments || {}).length} Comments
          </OTText>
        </View>

        <FeedbackVote feedbackItem={feedbackItem} disabled={true} />
        <BadgeView badgeNum={OTUITree.appHomeUI.feedbackManager?.feedbackUnread[item.feedbackId]} />

      </OTTouchableOpacity>
    )
  }
}


interface IFeedbackProps {
  feedbackId: string
  onClose: () => void
}

interface IFeedbackState {
  feedbackItem?: IFeedback
  loaded: boolean
  draftMessage: string
  draftFiles: IMessageFile[]

}

@observer
export class Feedback extends Component<IFeedbackProps, IFeedbackState> {
  _flatlistRef = React.createRef<FlatList>()
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      draftMessage: '',
      draftFiles: []
    }
  }

  componentDidMount = async () => {
    OTUITree.feedbackUI?.watchFeedback(this.props.feedbackId, this.hdlFeedback);
  }

  componentWillUnmount = () => {
    OTUITree.feedbackUI?.unwatchFeedback(this.props.feedbackId);
  }

  hdlFeedback = (doc: IFeedback) => {
    console.log("hdlFeedback", doc)

    if (doc.followers?.[OTUITree.auth.userId]) {
      console.log("i am following", doc.followers?.[OTUITree.auth.userId])
      let comments = Object.keys(doc.comments || {})

      let commentId = comments[comments.length - 1]

      if (commentId != doc.followers?.[OTUITree.auth.userId].lastRead) {
        console.log("markReadFeedback", this.props.feedbackId, commentId)

        OTUITree.feedbackUI?.markReadFeedback(
          this.props.feedbackId,
          commentId
        );
      }
    }

    this.setState({
      feedbackItem: doc,
      loaded: true
    })
  }

  sendChatMsg = async () => {
    if (this.state.draftMessage || this.state.draftFiles?.length > 0) {

      const username = OTUITree.userManager.userDoc?.name || "Unknown User";
      const imageUrl = OTUITree.userManager.userDoc?.imageUrl || null;

      const feedbackUI = OTUITree.feedbackUI;

      await feedbackUI?.commentFeedback(
        this.props.feedbackId,
        username,
        imageUrl,
        this.state.draftMessage,
        this.state.draftFiles,
        !this.state.feedbackItem?.followers?.[OTUITree.auth.userId]
      );
      Analytics.logEvent("feedback_send_msg", { feedbackId: this.props.feedbackId })

      this.setState({
        draftMessage: '',
        draftFiles: []
      })

    }
  }

  onFiles = (files) => {
    console.log("got files", files)

    var draftFiles = this.state.draftFiles ? [...this.state.draftFiles] : []
    Object.keys(files || {}).forEach(i => {
      var file = files[i]

      draftFiles.push(
        new CloudUpload(
          OTUITree.userManager.currentTeamId!,
          undefined,
          OTUITree.auth.userId,
          "feedback",
          file
        )
      );

    })

    this.setState({
      draftFiles: draftFiles
    })

  }

  followFeedback = () => {
    OTUITree.feedbackUI?.followFeedback(
      this.props.feedbackId,
      !this.state.feedbackItem?.followers?.[OTUITree.auth.userId]
    );
  }

  archiveFeedback = () => {
    confirm("Are you sure you want to delete this thread?") && this._doArchive()
  }

  _doArchive = () => {
    OTUITree.feedbackUI?.archiveFeedback(this.props.feedbackId);

    this.props.onClose()
  }

  render() {
    if (!this.state.loaded || !this.state.feedbackItem) {
      return <View />
    }

    const feedbackItem = this.state.feedbackItem
    const comments = Object.values(this.state.feedbackItem.comments || {})
    return (
      <View style={{ flex: 1 }}>
        <ScrollView style={{ flex: 1 }}>
          {this.renderHeader(feedbackItem)}
          {this.renderCompose()}
          {
            comments.length > 0 ?
              <OTText style={{ fontWeight: "600", fontSize: 16 }}>
                Comments ({comments.length})
              </OTText>
              :
              null
          }

          {
            comments.reverse().map((item, index) => this.renderComment(item, index))
          }

        </ScrollView>
        {
          PlatformOS != 'mobile' ?
            <FileDrop
              onDrop={this.onFiles}
              dropEffect={'copy'}
              renderCover={(overFrame, overTarget) => {
                return overFrame ?
                  <View
                    pointerEvents="none"
                    style={{
                      ...StyleSheet.absoluteFillObject,
                      backgroundColor: 'rgba(255,255,255,0.7)',
                      borderWidth: 2,
                      borderColor: 'grey',
                      borderStyle: 'dotted',
                      borderRadius: Theme.curviness,
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    {
                      overTarget ?
                        <Feather name="upload" size={40} style={{ color: overTarget ? 'red' : 'grey' }} />
                        :
                        null
                    }
                  </View>
                  :
                  null
              }}
            />
            :
            null
        }
      </View>
    )
  }

  renderHeader = (feedbackItem: IFeedback) => {
    return (
      <View>
        <View style={{ flexDirection: 'row', paddingBottom: 10, paddingTop: 5, alignItems: 'flex-start', }}>

          <View style={{ flex: 1, }}>

            <OTText style={listStyles.title}>
              <OTText style={{ backgroundColor: FeedbackColors[feedbackItem.type], color: 'white', padding: 5, paddingHorizontal: 10, marginRight: 5, fontSize: 14, fontWeight: "500", borderRadius: Theme.curviness / 2 }}>
                {FeedbackTypes[feedbackItem.type]}
              </OTText>
              {feedbackItem.title}
            </OTText>

            <View style={{ paddingVertical: 10 }}>

              <OTMarkdown>{feedbackItem.desc}</OTMarkdown>

              <View style={{ paddingVertical: 10, flexDirection: 'row' }}>
                {
                  feedbackItem.files?.map((file, index) => <OTChatFile key={index} file={file} />)
                }
              </View>


            </View>

            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={{}}>
                <HeadIcon size={20} imageUrl={feedbackItem.user.imageUrl} name={feedbackItem.user.name} />
              </View>
              <OTText style={{ marginLeft: 5 }}>
                Added by <OTText style={{ fontWeight: '600' }}>{feedbackItem.user.name}</OTText> - {getTimeAgo(feedbackItem.crDate)}
              </OTText>

              <View style={{ marginLeft: 10, }}>
                <OTButton
                  small={true}
                  onPress={this.followFeedback}
                  textColor={Theme.ButtonColor}
                  borderColor={Theme.ButtonColor}
                  backgroundColor="transparent"
                  title={feedbackItem.followers?.[OTUITree.auth.userId] ? "Following" : "Not Following"}
                  icon={<Feather name={feedbackItem.followers?.[OTUITree.auth.userId] ? "bell" : "bell-off"}
                    size={18}
                    style={{ color: Theme.ButtonColor, marginRight: 5 }} />} />
              </View>

              {
                OTUITree.auth.userId == feedbackItem.userId || OTAppData.admins[OTUITree.auth.userId] ?
                  <View style={{ marginLeft: 10, }}>
                    <OTTouchableOpacity onPress={this.archiveFeedback}>
                      <OTText style={{ color: Theme.ButtonNegativeColor }}>Delete</OTText>
                    </OTTouchableOpacity>
                  </View>
                  :
                  null
              }
            </View>
          </View>
          <FeedbackVote feedbackItem={feedbackItem} />

        </View>

      </View>
    )
  }

  renderComment = (item: IFeedbackMessage, index: number) => {
    const emojiResult = parseEmojis(item.message)

    return (
      <View key={index} style={{ backgroundColor: Theme.OfficeBackground, marginTop: 10, marginHorizontal: 12, padding: 10, borderRadius: Theme.curviness }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
          <View style={{}}>
            <HeadIcon size={20} imageUrl={item.user.imageUrl} name={item.user.name} />
          </View>
          <OTText style={{ marginLeft: 5 }}>
            <OTText style={{ fontWeight: '600' }}>{item.user.name}</OTText> - {getTimeAgo(item.crDate)}
          </OTText>
        </View>

        {
          item.files?.map((file, index) =>

            <OTChatFile key={`${file.name}-${index}`} file={file} />
          )
        }
        {
          emojiResult.isOnlyEmoji ?
            <OTText style={{ fontSize: 36 }}>
              {emojiResult.value}
            </OTText>
            :
            <OTMarkdown>{item.message}</OTMarkdown>
        }
      </View>
    )
  }

  renderCompose = () => {
    return (
      <View style={{ marginVertical: 20 }}>
        <OTText style={{ fontWeight: "600", fontSize: 16 }}>
          Leave a comment!
        </OTText>
        <OTChatCompose
          key={"compose"}
          backgroundColor={'transparent'}
          borderColor={Theme.InputHighlightColor}
          defaultHeight={60}
          draftText={this.state.draftMessage}
          setDraftText={(text) => this.setState({ draftMessage: text })}
          draftFiles={this.state.draftFiles}
          setDraftFiles={(draftFiles) => this.setState({ draftFiles: draftFiles })}
          sendChatMsg={this.sendChatMsg}
          onFiles={this.onFiles}
          disableSendOnEnter={true}
        />
      </View>
    )
  }
}



interface IFeedbackVoteProps {
  feedbackItem: IFeedback
  disabled?: boolean
}

interface IFeedbackVoteState {
}

@observer
export class FeedbackVote extends Component<IFeedbackVoteProps, IFeedbackVoteState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  doFeedbackVote = () => {
    const feedbackItem = this.props.feedbackItem

    OTUITree.feedbackUI?.voteFeedback(
      this.props.feedbackItem.feedbackId,
      !feedbackItem?.votes?.[OTUITree.auth.userId]
    );
  }

  render() {
    const numVotes = Object.keys(this.props.feedbackItem.votes || {}).length
    const voted = this.props.feedbackItem.votes?.[OTUITree.auth.userId]
    return (
      <View style={{ alignItems: 'center', minWidth: 70, }}>

        <OTText style={{ fontSize: 16, fontWeight: 'bold', color: voted ? Theme.MainColour : Theme.MainText, }}>
          {numVotes}
        </OTText>

        {this.props.disabled ?
          <OTText>{pluralise("vote", numVotes)}</OTText>
          :
          <OTButton
            small={true}
            title={voted ? "Unvote" : "Vote"}
            onPress={this.doFeedbackVote}
            disabled={this.props.disabled}
            outerStyle={{ marginTop: 10 }}
          />
        }
      </View>
    )
  }

}


const pageStyles = StyleSheet.create({
  headerRow: {
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  headerText: {
    fontSize: 18,
    fontWeight: "600",
  },
})


const listStyles = StyleSheet.create({
  list: {
  },
  item: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingVertical: 10,
    margin: 5,
    borderRadius: Theme.curviness,
  },
  title: {
    fontWeight: '600',
    fontSize: 16,
    flex: 1
  },
  time: {
    fontSize: 12,
  }
})
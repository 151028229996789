import { observer } from "mobx-react";
import React, { Component } from "react";
import { Image, View } from "react-native";
import { OTTouchableOpacity } from "../buttons";
import { Theme } from "../../globals/ThemeColors";
import { OTText } from "../OTText";
import { Feather } from '@expo/vector-icons';
import * as WebBrowser from 'expo-web-browser';
import ScalableImage from 'react-native-scalable-image';
import { ProgressBar } from "../ProgressBar";
import { IMessageFile, IFile } from "@openteam/models";
import { hitSlop5 } from "../../utils/OTUtils";

interface IOTChatFileProps {
  file: IFile
}
interface IOTChatFileState {
  collapsed: boolean
}

@observer
export class OTChatFile extends Component<IOTChatFileProps, IOTChatFileState> {
  constructor(props) {
    super(props)

    this.state = {
      collapsed: false
    }
  }

  openFile = () => {
    WebBrowser.openBrowserAsync(this.props.file.url);
  }


  render() {
    const file = this.props.file

    return (
      file.type.includes("image") ?
        <View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <OTText style={{ fontSize: 13, fontWeight: "600" }} ellipsizeMode={"middle"}>
              {file.name}
            </OTText>
            <OTTouchableOpacity onPress={() => this.setState({ collapsed: !this.state.collapsed })} hitSlop={hitSlop5}>
              {
                this.state.collapsed ?
                  <Feather name="chevron-up" size={14} style={{ paddingLeft: 5, color: Theme.MainText }} />
                  :
                  <Feather name="chevron-down" size={14} style={{ paddingLeft: 5, color: Theme.MainText }} />
              }
            </OTTouchableOpacity>
          </View>
          {
            this.state.collapsed ?
              null
              :
              <OTTouchableOpacity onPress={this.openFile} style={{}}>
                <ScalableImage
                  key={`${file.name}`}
                  source={{ uri: file.url }}
                  width={200}
                  height={200}
                  style={{
                    borderRadius: Theme.curviness,
                    marginVertical: 5,
                  }}
                />
              </OTTouchableOpacity>
          }
        </View>
        :
        <View>
          <OTText style={{}} ellipsizeMode={"middle"}>
            {file.name}
          </OTText>
          <OTTouchableOpacity onPress={this.openFile} style={{}}>
            <View style={{
              width: 100,
              height: 100,
              backgroundColor: Theme.ButtonColor,
              borderRadius: Theme.curviness,
              alignItems: 'center',
              justifyContent: 'center',
              marginVertical: 5,
            }}>
              <Feather name="file" size={40} style={{ color: Theme.ButtonIconColor }} />
            </View>
          </OTTouchableOpacity>
        </View>

    )

  }
}


interface IOTChatFileUploadProps {
  file: IMessageFile
  onRemove?: () => void
}
interface IOTChatFileUploadState {
  previewURL: string
}

@observer
export class OTChatFileUpload extends Component<IOTChatFileUploadProps, IOTChatFileUploadState> {
  constructor(props) {
    super(props)

    this.state = {
      previewURL: this.props.file.downloadUrl || this.props.file.getPreviewURL()
    }
  }


  render() {
    const uploadFile = this.props.file

    return (
      <View key={uploadFile.file.name} style={{ margin: 5, paddingRight: 0 }}>
        {
          uploadFile.file.type.includes("image") ?
            <Image
              source={{ uri: this.state.previewURL }}
              style={{ width: 60, height: 60, borderRadius: Theme.curviness / 1.5 }}
            />
            :
            <View style={{ width: 60, height: 60, backgroundColor: Theme.ButtonColor, borderRadius: Theme.curviness, alignItems: 'center', justifyContent: 'center' }}>
              <Feather name="file" size={40} style={{ color: Theme.ButtonIconColor }} />
            </View>
        }

        {
          !uploadFile.completed ?
            <View style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
              <ProgressBar progress={uploadFile.progress} height={8} />
            </View>
            :
            null
        }
        {
          this.props.onRemove ?

            <OTTouchableOpacity onPress={this.props.onRemove} style={{ position: 'absolute', right: 0, top: 0, backgroundColor: 'white', padding: 2, borderRadius: 10 }}>
              <Feather name="x-circle" size={14} />
            </OTTouchableOpacity>
            :
            null
        }
      </View>
    )

  }
}
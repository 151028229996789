import React from 'react';
import { getStringColour } from "../utils/OTUtils";
import { Theme } from '../globals/ThemeColors';
import { ImageBackground, Image, View, Text } from 'react-native';
import { OTText } from './OTText';

interface IHeadIconProps {
  imageUrl?: string | null,
  name: string
  size?: number
  borderRadius?: number
  style?: any
}

export default function HeadIcon(props: IHeadIconProps) {
  const backgroundColor = getStringColour(props.name)
  const size = props.size || 40
  const borderRadius = props.borderRadius || Theme.curviness / 2;

  return (
    <View style={[{ borderRadius, backgroundColor, width: size, height: size, alignItems: 'center', justifyContent: 'center' }, props.style]}>
      {
        props.imageUrl ?
          <Image
            style={{ borderRadius, width: size, height: size, }}
            data-tip={props.name}
            source={{ uri: props.imageUrl }}
          />
          :
          <OTText style={{ color: 'white', fontWeight: "600", fontSize: size / 2 }}>
            {props.name.charAt(0).toUpperCase()}
          </OTText>
      }

    </View>
  )
}

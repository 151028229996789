import moment from 'moment';
import { ITeamUser, IUserStatus } from '@openteam/models';
import * as StyleConstants from '../globals/StyleConstants';
import * as Fire from '../globals/Fire';
import { showToast } from '../components/Toasts';
import { Clipboard } from 'react-native';

export function getStringColour (text) {
  let sumChars = 0;

  if (text) {
    for (let i = 0; i < text.length; i++) {
      sumChars += text.charCodeAt(i);
    }
  }

  const colors = [
    '#6BCE7C',
    '#FF9996',
    '#FFAB38',
    '#CF82FF',
    '#FFBBE0',
    '#31AFD4',
    '#7E6B8F',
  ];

  return colors[sumChars % colors.length];
}

export function getStatusColour (connected, roomConnected, idle, inBackground) {
  var statusColour = StyleConstants.ConnectedColour
  if (!connected) {
    statusColour = StyleConstants.DisconnectColour
  } else if (!roomConnected) {
    statusColour = StyleConstants.ConnectingColour
  } else if (idle) {
    statusColour = StyleConstants.IdleColour
  } else if (inBackground) {
    statusColour = StyleConstants.InBackgroundColour
  }
  return statusColour
}
export function getStatusString (connected, roomConnected, idle, inBackground, hasMobile) {
  if (!connected) {
    if (hasMobile) {
      return 'mobile'
    } else {
      return 'disconnected'
    }
  } else if (!roomConnected) {
    return 'connecting'
  } else if (idle) {
    return 'idle'
  } else if (inBackground) {
    return 'inBackground'
  }
  return 'connected'
}

const SecondMilli = 1000
const MinuteMilli = 60 * SecondMilli
const HourMilli = 60 * MinuteMilli
const DayMilli = 24 * HourMilli

export function getTimeAgo (epochTime) {
  var now = Date.now()

  var diff = now - epochTime

  if (diff < 2 * MinuteMilli) {
    return "just now"
  } else if (diff < HourMilli) {
    return Math.floor(diff / MinuteMilli) + " mins ago"
  } else if (diff < 2 * HourMilli) {
    return "1 hour"
  } else if (diff < DayMilli) {
    return Math.floor(diff / HourMilli) + " hours ago"
  } else if (diff < 2 * DayMilli) {
    return "1 day"
  } else {
    return Math.floor(diff / DayMilli) + " days ago"
  }

}

export function msDuration (ms) {
  return moment.duration(ms, 'milliseconds').humanize(false)
}

export function secondsToDhms (seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor(seconds % (3600 * 24) / 3600);
  var m = Math.floor(seconds % 3600 / 60);
  var s = Math.floor(seconds % 60);


  // var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
  // var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  // var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

  var dDisplay = d > 0 ? `${d}d ` : "";
  var hDisplay = h > 0 ? `${h}h ` : "";
  var mDisplay = m > 0 ? `${m}m ` : "";
  var sDisplay = s > 0 ? `${s}s ` : "";

  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export function copyToClipboard(url) {
  Clipboard.setString(url)
  showToast("Copied to clipboard", "info")
}

export const hitSlop5 = { top: 5, right: 5, bottom: 5, left: 5 }


export function pluralise(word: string, num: number, suffix: string="s", plural?: string) {

  if (num == 1) {
    return word
  } else {
    if (plural) {
      return plural
    }
    return word + suffix
  }
}

import React, { Component } from "react";
import { View, StyleSheet, Image, ScrollView } from "react-native";
import * as StyleConstants from '../globals/StyleConstants'
import * as ModalService from '../components/ModalService'
import { PlatformOS } from "../utils/platform";
import DownloadRedirect, { platformDownloads } from "./DownloadRedirect";

import { Theme } from "../globals/ThemeColors";
import { OTButton } from "../components/buttons/OTButtons";
import { OTUserData } from "../data/OTData";
import { WWW_URL, LINKPROTOCOL } from "../globals/config";
import { Logger } from '@openteam/app-util';
import * as WindowUtils from '../utils/WindowUtils';
import { osName } from "react-device-detect";

import { signOut } from "../globals/app";
import { OTUITree } from "@openteam/app-core";

const logger = new Logger("ConnectView")
interface IConnectViewProps {
  onConnect: () => void
}
interface IConnectViewState { }

export class ConnectView extends Component<IConnectViewProps, IConnectViewState> {

  downloadRedirect = async () => {
    var protocolURL = `${LINKPROTOCOL}:${OTUserData.reqTeamPath || ''}`

    logger.info("protocolURL", protocolURL)
    WindowUtils.setUrl(protocolURL)
  }

  render() {
    if (PlatformOS == 'electron') {
      return (
        <View style={{ flex: 1, backgroundColor: Theme.OfficeBackground }} >
          <ModalService.OTModal>
            <ModalService.OTModalContainer>
              <ScrollView contentContainerStyle={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1, }}>
                <View style={{ width: "100%", flexDirection: 'row', alignItems: 'center', justifyContent: 'center', minWidth: 400, marginBottom: 20 }}>
                  <Image
                    accessibilityRole="link"
                    // @ts-expect-error target not part of types
                    target="_blank"
                    href={WWW_URL}
                    source={StyleConstants.LogoSource}
                    style={{ width: 150, height: 30, marginTop: 10, resizeMode: "contain" }} />
                  {
                    OTUITree.auth.userId ?
                      <OTButton
                        small={true}
                        outerStyle={{ flex: undefined, position: 'absolute', right: 0 }}
                        title="Sign out"
                        onPress={signOut}
                        // backgroundColor={Theme.ButtonNegativeColor}
                        backgroundColor="white"
                        borderColor={Theme.ButtonNegativeColor}
                        textColor={Theme.ButtonNegativeColor}
                      />
                      :
                      null
                  }
                </View>


                {this.renderConnect()}

              </ScrollView>
            </ModalService.OTModalContainer>

          </ModalService.OTModal>
        </View>
      )
    }
    return <DownloadRedirect onBrowserConnect={this.props.onConnect} />

  }

  renderConnect() {


    const trueOsName = osName == 'Ubuntu' ? 'Linux' : osName;

    const supportedPlatform = platformDownloads[trueOsName]

    return (
      <View style={{ marginTop: 30, marginBottom: 40 }}>
        <View style={{ flexDirection: 'row' }}>
          <OTButton
            title="Continue"
            onPress={this.props.onConnect}
            backgroundColor={'rgba(255,255,255,0.8)'}
            textColor={Theme.ButtonColor}
            outerStyle={{ paddingHorizontal: 30 }}
            borderColor={Theme.ButtonColor}
            showShadow={true}
          />
        </View>
      </View>
    )
  }
}


const styles = StyleSheet.create({
  connect: {
    width: "100%",
    minWidth: 200,
    borderRadius: Theme.curviness,
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    backgroundColor: Theme.PrimaryColour
  },
})
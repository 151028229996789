import firebase from "firebase/app";
import "firebase/database";

import { OTUserInterface } from "../OTUserInterface";

import { Logger } from "@openteam/app-util";
import { IUserTeamReq } from "@openteam/models";
import { addWatch, removeWatch } from "./FireUtils";

const logger = new Logger("UserManagerDb");

export class UserManagerDb {
  static setUserTeamId = (
    fbDb: firebase.database.Database,
    userId: string,
    teamId: string | undefined
  ) => {
    if (OTUserInterface.platformUtils.PlatformOS != "mobile") {
      fbDb
        .ref(`/users/${userId}`)
        .child("teamId")
        .set(teamId || null);
    }
  };

  static removeCurrentUserTeam = async (fbDb: firebase.database.Database, userId: string) =>
    fbDb
      .ref("/users/" + userId)
      .child("teamId")
      .set(null);

  static removeUserTeam = async (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string
  ) => fbDb.ref(`/users/${userId}/teams/${teamId}`).set(null);

  static watchUserTeamAccessRequest = (
    fbDb: firebase.database.Database,
    userId: string,
    teamId: string,
    callback: (doc) => void
  ) => {
    const ref = `/teamAccessReq/${teamId}/${userId}`;
    addWatch(ref);
    fbDb
      .ref(ref)
      .on("value", (snapshot) => callback(snapshot.val()));
  };

  static unwatchUserTeamAccessRequest = (
    fbDb: firebase.database.Database,
    userId: string,
    teamId: string
  ) => {
    const ref = `/teamAccessReq/${teamId}/${userId}`;
    removeWatch(ref);
    fbDb.ref(ref).off("value");
  };

  static requestTeamAccess = async (
    fbDb: firebase.database.Database,
    userId: string,
    teamId: string,
    requestDetails: Partial<IUserTeamReq>,
    teamPath: string
  ) => {
    logger.info(`requestTeamAccess: teamId=${teamId} teamPath=${teamPath}`);

    const userTeamReq: IUserTeamReq = {
      id: teamId,
      date: firebase.database.ServerValue.TIMESTAMP,
      email: "",
      name: "",
      imageUrl: null,
      status: "waiting",
      ...requestDetails,
    };

    fbDb.ref(`/teamAccessReq/${teamId}/${userId}`).set(userTeamReq);
    fbDb.ref(`/users/${userId}/teams/${teamId}`).set(teamPath);
  };

  static removeTeamAccessReq = async (
    fbDb: firebase.database.Database,
    userId: string,
    teamId: string
  ) => {
    fbDb.ref(`/users/${userId}/reqTeams/${teamId}`).set(null);
    fbDb.ref(`/teamAccessReq/${teamId}/${userId}`).remove();
  };

  static accessTeamWithInvite = async (teamId: string, inviteId: string) => {
    logger.debug("trying to join team with invite, teamId=%s, inviteId=%s", teamId, inviteId);
    const joinWithInvite = firebase.functions().httpsCallable("joinWithInvite");
    const result = await joinWithInvite({ teamId: teamId, inviteId: inviteId });
    
    return result.data.success;
  };

  static watchUser = (
    fbDb: firebase.database.Database,
    userId: string,
    callback: (doc) => void
  ) => {
    const ref = "/users/" + userId;
    addWatch(ref);
    fbDb.ref(ref).on("value", (snapshot) => callback(snapshot.val()));

    // getDB().collection('users').doc(user_id).onSnapshot((doc) => callback(doc.data()))
  };
  static unwatchUser = (fbDb: firebase.database.Database, userId: string) => {
    const ref = "/users/" + userId;
    removeWatch(ref);
    fbDb.ref(ref).off("value");
  };
}

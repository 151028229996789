import React, { Component } from 'react';
import { IPluginConfig, OTUITree, PluginManager } from "@openteam/app-core";
import { IPluginResource } from "@openteam/models";
import { View } from 'react-native';
import { observer } from 'mobx-react';
import { OTText, OTTextInput } from '../OTText';
import { InlineWeb } from '../InlineWeb';
import { dismissModal, showModal } from '../ModalService';
import { Theme } from '../../globals/ThemeColors';
import { OTButton } from '../buttons/OTButtons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { SiVisualstudiocode } from 'react-icons/si'
import { PlatformOS } from '../../utils/platform';
interface IVSCodeSharePluginDetails extends IPluginResource {
  args: IVSCodeShareArgs
}

interface IVSCodeShareArgs {
  url: string,
}

interface IVSCodeShareProps {
  pluginManager: PluginManager
  pluginDetails: IVSCodeSharePluginDetails
  height: number
  width: number
}
interface IVSCodeShareState { }

interface IVSCodeShareArgs {
  url: string,
}

export class VSCodeShare extends Component<IVSCodeShareProps, IVSCodeShareState> {

  _iframe
  render() {
    if (!this.props.pluginDetails.args?.url) {
      return <View />
    }

    if (this.props.pluginDetails.userId == OTUITree.auth.userId) {
      return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <View
            style={{ flexDirection: 'row', alignItems: 'center', padding: 5 }}>
            <pluginConfig.icon size={28} style={{ color: pluginConfig.iconColour }} />
            <OTText style={{ fontSize: 22, color: Theme.DarkText, fontWeight: "600" }}>{pluginConfig.name}</OTText>
          </View>
          <OTText>
            Your VSCode Live Share session
        </OTText>

        </View>
      )
    }

    return (
      <InlineWeb
        ref={r => this._iframe = r}
        style={{ width: "100%", height: "100%" }}
        src={this.props.pluginDetails.args.url} />
    )
  }
}




interface IVSCodeShareSetupViewProps {
  pluginManager: PluginManager
  onClose: () => void
}

interface IVSCodeShareSetupViewState {
  urlText: string
}

@observer
export class VSCodeShareSetupView extends Component<IVSCodeShareSetupViewProps, IVSCodeShareSetupViewState> {
  constructor(props) {
    super(props);

    this.state = {
      urlText: '',
    }
  }

  render() {
    return (
      <View style={{ alignItems: 'center' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <pluginConfig.icon size={22} style={{ color: pluginConfig.iconColour, marginRight: 10 }} />
          <OTText style={{
            fontSize: 18,
            color: Theme.DarkText,
            fontWeight: "600"
          }}>{pluginConfig.name}</OTText>
        </View>
        <OTText style={{ color: Theme.DarkText, marginTop: 20, marginBottom: 10 }}>
          Paste your VS Code Live Share link here. See more on Live Share <OTText style={{ color: Theme.LinkColor }} accessibilityRole='link' href="https://marketplace.visualstudio.com/items?itemName=MS-vsliveshare.vsliveshare">here</OTText>.
        </OTText>

        <View style={{ marginVertical: 20, alignItems: 'center' }}>
          <OTTextInput
            style={{ padding: 10, minWidth: 300, backgroundColor: Theme.InputBackgroundColor, borderRadius: Theme.curviness, borderWidth: 1, borderColor: Theme.InputHighlightColor }}
            value={this.state.urlText}
            placeholder={"Enter VSCode Live Share Link"}
            onChangeText={(text) => this.setState({ urlText: text })}
            onSubmitEditing={this.setUrl} />
        </View>
        <View style={{ flexDirection: 'row' }}>

          <OTButton
            onPress={this.setUrl}
            title={"Open"}
            disabled={!this.state.urlText}
            backgroundColor={!this.state.urlText ? Theme.ButtonDisabledColor : undefined}
          />
        </View>

      </View>
    )
  }

  setUrl = () => {

    if (this.state.urlText) {
      this.setState({
        urlText: '',
      })

      var args = {
        url: this.state.urlText
      }

      this.props.pluginManager.createPlugin(pluginType, args)

      this.props.onClose()
    }

  }
}

function setupPlugin(pluginManager) {
  showModal({
    contents: <VSCodeShareSetupView
      pluginManager={pluginManager}
      onClose={dismissModal}
    />,
    showContainer: true,
    showClose: true
  })
}

export class VSCodeIcon extends Component {
  render() {

    if (PlatformOS == 'mobile') {
      return (
        <MaterialCommunityIcons name="microsoft-visual-studio-code" {...this.props} />
      )
    }

    return (
      <SiVisualstudiocode {...this.props} />
    )
  }
}


export const pluginType = 'vscode'
export const pluginConfig: IPluginConfig = {
  name: 'VS Code',
  multi: false,
  component: VSCodeShare,
  setupFunc: setupPlugin,
  // component: VSCodeShare,
  icon: VSCodeIcon,
  iconColour: '#55ADED',
  canPopout: true,
  webInline: false
}
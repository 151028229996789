import events from 'events'
import { IIdleTimer } from 'models/src/win';


export class IdleTimerClass extends events.EventEmitter implements IIdleTimer {
  constructor() {
    super();
    this.start();
  }

  start = () => {
    window.addEventListener("focus", this.onFocus);
    window.addEventListener("blur", this.onBlur);
  };

  onFocus = () => {
    this.emit("present", true);
  };

  onBlur = () => {
    this.emit("present", false);
  };
}

export const IdleTimer = new IdleTimerClass()
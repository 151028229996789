import { Logger } from '@openteam/app-util';

const logger = new Logger("GridCalc")

export function generateGrid(width, height, aspectRatio, count, padding=0, verticalGrid=true) {

  var numColumns = 1
  var maxItemWidth = 0
  var maxItemHeight = 0

  var gridWidth = 0
  var columnItems = 0
  var gridHeight = 0

  var itemWidth = 0
  var itemHeight = 0

  if (!verticalGrid) {
    var usableHeight = width
    var usableWidth = height
    aspectRatio = 1/aspectRatio
  } else {
    var usableHeight = height
    var usableWidth = width
  }

  while (true) {

    columnItems = Math.ceil(count/numColumns)

    gridWidth = usableWidth / numColumns - padding
    gridHeight = usableHeight /columnItems - padding


    if (gridHeight * aspectRatio > gridWidth) {
      itemWidth = gridWidth
      itemHeight = gridWidth/aspectRatio
    } else {
      itemWidth = gridHeight*aspectRatio
      itemHeight = gridHeight
    }

    if (itemWidth > maxItemWidth) {
      maxItemWidth = itemWidth
      maxItemHeight = itemHeight
      numColumns += 1
    } else {
      break
    }
  }

  if (verticalGrid) {
    return {
      numColumns: numColumns -1,
      itemWidth: maxItemWidth,
      itemHeight: maxItemHeight
    }
  } else {
    return {
      numColumns: numColumns -1,
      itemWidth: maxItemHeight,
      itemHeight: maxItemWidth
    }
  }


}

interface ISize {
  height: number
  width: number
}


export function generateGridFocused(width, height, focusedAspectRatio, tileMinWidth, tileMinHeight, aspectRatio, streamCount, padding=10 ) {
  logger.debug("width, height", width, height, "focusedAspectRatio", focusedAspectRatio)

  var verticalGrid = false

  var focusedSize = getMaxSizeForAspectRatio(width - padding, height - padding, focusedAspectRatio || aspectRatio)

  var gridCount = streamCount
  var gridWidth = width
  var gridHeight = height

  var gridDimensions = {
    numColumns: 0,
    itemWidth: tileMinWidth,
    itemHeight: tileMinHeight
  }

  // if I have a grid to draw
  if (gridCount > 0) {

    // check focused item
    if (focusedAspectRatio) {
      //
      focusedSize = getMaxSizeForAspectRatio(width - tileMinWidth - padding, height - padding, focusedAspectRatio)
      var horizontalGrid = getMaxSizeForAspectRatio(width - padding, height - tileMinHeight - padding, focusedAspectRatio)

      if (horizontalGrid.width > focusedSize.width) {
        verticalGrid = false
        focusedSize = horizontalGrid

        gridHeight = height - (focusedSize.height || 0) - padding
      } else {
        verticalGrid = true
        gridWidth = width - (focusedSize.width || 0) - padding
      }
    }

    logger.debug("filling remaining space gridWidth, gridHeight with  aspectRatio count", gridWidth, gridHeight, aspectRatio, gridCount)

    gridDimensions = generateGrid(gridWidth, gridHeight, aspectRatio, gridCount, padding, verticalGrid=verticalGrid)
  }

  return {
    width: focusedSize.width,
    height: focusedSize.height,

    itemWidth: gridDimensions.itemWidth,
    itemHeight: gridDimensions.itemHeight,
    verticalGrid
  }
}


function getMaxSizeForAspectRatio(width, height, aspectRatio) {
  const curAspectRatio = width / height

  if (aspectRatio > curAspectRatio) {
    // constrained by width

    height = width / aspectRatio
  } else {
    // constrained by height
    width = height * aspectRatio

  }

  return {
    height,
    width
  }
}


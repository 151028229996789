import React, { Component } from "react";
import TeamHome from "./teamView/TeamHome";
import { OTUserData } from "../data/OTData";
import { View, StyleSheet, Image } from "react-native";
import { initSoundEffects } from "../utils/Notify";
import * as platform from "../utils/platform";
import { showReleaseNotes } from "./fragments/ReleaseNotes";
import * as StyleConstants from "../globals/StyleConstants";
import { observer } from "mobx-react";
import * as Fire from "../globals/Fire";
import { Widget } from "../widget/Widget";
import {
  AppHomeManager,
  CallStateManager,
  FireDb,
  OTGlobals,
  OTUITree,
  TeamManager,
  UserManager,
} from "@openteam/app-core";
import LoginTeam from "./LoginTeam";
import { TeamNonAccess } from "./teamView/TeamNonAccess";
import { Logger } from "@openteam/app-util";

import * as Analytics from "../utils/Analytics";
import { ConnectView } from "./ConnectView";
import { Theme } from "../globals/ThemeColors";
import { LoadingView } from "../components/LoadingView";
import RedirectToApp from "./RedirectToApp";
import { TeamList } from "../components/team";
import { StreamPopout } from "../components/StreamPopout";
import { PTTAudioRender } from "../components/PTTAudioRender";
import { getFSDB, getRTDB } from "../globals/Fire/util";
import { dismissModal, showCallRequestModal, showMeetingModal } from "../components/ModalService";
import { endActiveSession } from "../globals/app";
import { IdleTimer } from "../utils/IdleTimer";
import { ShortcutManager } from "../utils/Shortcuts";
import { PushNotifyManager } from "../mobile/controllers/PushNotify";
import { showCallFeedback } from "./fragments/CallFeedback";

const logger = new Logger("AppHome");

interface IAppHomeProps {}
interface IAppHomeState {
  loaded: boolean;
  hasTeam: boolean;
  showTeamPanel: boolean;
}

@observer
export default class AppHome extends Component<IAppHomeProps, IAppHomeState> {
  constructor(props) {
    super(props);

    if (!OTGlobals._appHomeManager) {
      logger.debug("creating appHomeManager");

      OTGlobals.registerAppHomeManager(
        new AppHomeManager(
          getRTDB(),
          getFSDB(),
          OTUITree.auth.userId,
          OTUITree.auth.sessionToken,
          endActiveSession,
          showMeetingModal,
          showCallRequestModal,
          showCallFeedback,
          dismissModal,
          IdleTimer,
          ShortcutManager,
          PushNotifyManager
        )
      );
    }

    this.state = {
      loaded: false,
      hasTeam: Object.keys(OTUITree.userManager.teamAccessIndex).length > 0,
      showTeamPanel: false,
    };
  }

  componentDidMount = async () => {
    logger.debug("init apphome");

    await this.handleRequestedPath();
    logger.debug("handled requested path");
    await OTUITree.appHomeUI.init();

    try {
      platform.createTray();
    } catch (err) {
      logger.error("Error initialising tray", err);
    }

    await this.autoConnect();

    logger.debug("loaded: true");

    this.setState({ loaded: true });
  };

  componentWillUnmount = () => {
    platform.destroyTray();
  };

  handleRequestedPath = async () => {
    logger.debug("PATH: handleRequestedPath reqTeamPath=%s", OTUserData.reqTeamPath);

    // checks the requested path, sets current team if possible, otherwise the
    // jointeam screen will be shown

    if (OTUserData.reqTeamPath) {
      // does the path have a team
      const reqTeamId = await FireDb.getTeamPathId(getRTDB(), OTUserData.reqTeamPath);

      if (reqTeamId) {
        logger.info(
          "PATH: handleRequestedPath reqTeamPath=%s, reqTeamId=%s, teamInviteId=%s",
          OTUserData.reqTeamPath,
          reqTeamId,
          OTUserData.teamInviteId
        );
        logger.info(
          "teamAccessIndex: %o",
          Object.keys(OTUITree.userManager.teamAccessIndex).map((x) => x.toString())
        );

        if (reqTeamId in OTUITree.userManager.teamAccessIndex) {
          OTUITree.userManager.setCurrentTeam(reqTeamId);
          OTUserData.reqTeamPath = undefined;
          OTUserData.teamInviteId = undefined;

        } else if (OTUserData.teamInviteId) {
          const result = await OTUITree.userManager.accessTeamWithInvite(
            OTUserData.reqTeamPath,
            OTUserData.teamInviteId
          );

          if (result) {
            OTUserData.reqTeamPath = undefined;
            OTUserData.teamInviteId = undefined;

            if (!this.state.hasTeam) {
              this.setState({
                hasTeam: true,
              });
            }
          }
        }
      }
    }
  };

  autoConnect = async () => {
    logger.info("OTUserData.userInteracted", OTUserData.userInteracted);
    if (platform.PlatformOS == "electron" || (!OTUserData.appLogin && OTUserData.userInteracted)) {
      const sessionToken = OTUITree.userManager.userDoc!.status?.sessionToken;

      const isMe = sessionToken && sessionToken == OTUITree.auth.sessionToken;
      const isActiveSession =
        sessionToken &&
        sessionToken in (OTUITree.userManager.userDoc!.status?.activeSessions || {});

      if (isMe || !isActiveSession) {
        logger.info("Initiating auto connect");
        await this.connect();
      } else {
        logger.info("there is an active session", sessionToken);
      }
    }
  };

  connected = false;
  connect = async () => {
    if (this.connected) {
      return;
    }
    this.connected = true;

    initSoundEffects();

    await OTUITree.appHomeUI.start();
    Analytics.logEvent("session_start");
  };

  renderConnectPrompt = () => {
    return <ConnectView onConnect={this.connect} />;
  };

  teamAdded = (teamId) => {
    this.setState({
      hasTeam: true,
    });
    this.autoConnect();
    this.setCurrentTeam(teamId);
  };

  setCurrentTeam = (teamId) => {
    const userManager = OTUITree.userManager;

    if (userManager.currentTeamId != teamId) {
      userManager.setCurrentTeam(teamId);
      Analytics.logEvent("switch_team", { teamId: teamId });
    }
  };

  render() {
    logger.info(`Rendering AppHome`);

    if (!this.state.loaded) {
      return (
        <View
          style={{
            flex: 1,
            backgroundColor: Theme.OfficeBackground,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingView title="Loading your account" size="large" />
        </View>
      );
    }

    const callStateManager = OTGlobals.callStateManager;
    var teamId: string | undefined = undefined;
    var isElectronMac = platform.isFrameless;
    var teamData;
    var inVideoChat = false;

    if (callStateManager && callStateManager.inCall) {
      teamId = callStateManager.teamId;
      teamData = OTGlobals.getTeamData(teamId);
      inVideoChat = callStateManager.inVideoChat;
    } else {
      teamId = OTUITree.userManager.currentTeamId;
      logger.debug("currentTeamId=%s", teamId);
      teamData = teamId ? OTGlobals.getUnsafeTeamData(teamId!) : undefined;
    }
    logger.debug("hasTeam=%s reqTeamPath=%s", this.state.hasTeam, OTUserData.reqTeamPath);

    if (!this.state.hasTeam || OTUserData.reqTeamPath) {
      return <LoginTeam onSuccess={this.teamAdded} />;
    }

    if (OTUserData.appLogin) {
      return (
        <RedirectToApp incToken={true} onBrowserConnect={() => (OTUserData.appLogin = undefined)} />
      );
    }

    if (this.state.loaded && !OTUITree.appHomeUI.started) {
      return this.renderConnectPrompt();
    }

    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          backgroundColor: Theme.HeaderBackground,
          height: "100vh",
          maxHeight: "100vh",
          width: "100vw",
          maxWidth: "100vw",
          overflow: "hidden",
        }}
      >
        {inVideoChat || !this.state.showTeamPanel ? null : (
          <View style={{ alignItems: "center" }}>
            <View style={{ height: 65, justifyContent: "center", alignItems: "center" }}>
              {!isElectronMac ? (
                <Image source={Theme.IconSource} style={{ height: 43, width: 43 }} />
              ) : null}
            </View>
            <TeamList setCurrentTeam={this.setCurrentTeam} />
          </View>
        )}

        {Object.keys(OTUITree.teamManagers).map((teamId) => (
          <PTTAudioRender key={teamId} teamId={teamId} />
        ))}

        {this.renderTeam()}
        {teamId ? <Widget key={`widget-${teamId}`} teamId={teamId} /> : null}

        {teamId && teamData && callStateManager
          ? Object.keys(callStateManager.callState.popoutStreams).map((streamId) => (
              <StreamPopout
                key={`Popout-${streamId}`}
                teamId={teamId!}
                streamId={streamId}
                onClose={() => {
                  delete callStateManager.callState.popoutStreams[streamId];
                }}
              />
            ))
          : null}
      </View>
    );
  }

  renderTeam = () => {
    const userManager = OTUITree.userManager;

    if (
      !userManager.currentTeamId ||
      !(userManager.currentTeamId in userManager.teamAccessIndex || {}) ||
      !(userManager.currentTeamId in OTUITree.teamManagers)
    ) {
      return <TeamNonAccess teamId={userManager.currentTeamId} />;
    }

    const teamManager = OTUITree.teamManagers[userManager.currentTeamId];

    const teamReady = teamManager && teamManager.running && teamManager.loaded;

    if (!teamReady) {
      logger.info("teamReady", !!teamManager, teamManager.running, teamManager.loaded);
      return (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <LoadingView title="Loading Team" size="large" />
        </View>
      );
    }

    return <TeamHome key={userManager.currentTeamId} teamId={userManager.currentTeamId} />;
  };
}

const styles = StyleSheet.create({
  connect: {
    width: "100%",
    minWidth: 200,
    borderRadius: Theme.curviness,
    justifyContent: "center",
    alignItems: "center",
    height: 60,
    backgroundColor: StyleConstants.PrimaryColour,
  },
  teamIcon: {
    height: 43,
    width: 43,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: Theme.curviness / 2,
  },
  teamIconContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 6,
    padding: 4,
  },
  teamIconContainerSelected: {
    padding: 3,
    borderWidth: 1,
    borderRadius: Theme.curviness / 2 + 3,
    borderColor: Theme.MenuTeamOutlineColour,
  },
});

import { Logger, assertDefined } from "@openteam/app-util";
import { action, computed, observable } from "mobx";
import { OTGlobals } from "./OTGlobals";
import { AppHomeManager } from "./AppHome/AppHomeManager";
import { AppHomeUI } from "./AppHome/AppHomeUI";
import { CallRequestUIState } from "./CallRequest/CallRequestUIState";
import type { CallRequestManager } from "./CallRequest/CallRequestManager";
import type { ChatManager } from "./Chat/ChatManager";
import { ChatUIState } from "./Chat/ChatUIState";
import { DimensionManager } from "./DimensionManager";
import { FeedbackManager } from "./Feedback";
import { HeartbeatManager } from "./HeartbeatManager";
import type { MeetingManager } from "./Meeting/MeetingManager";
import { MeetingUIState } from "./Meeting/MeetingUIState";
import { TeamAccessReqManager } from "./TeamAccessReqManager";
import { UserManager } from "./User/UserManager";
import { windowState } from "./WindowState";
import { OTAuth } from "./User/Auth";

const logger = new Logger("OTUITree");

export class OTUITreeClass {
  @observable dimensions = new DimensionManager();

  @observable chatUIStates: Record<string, ChatUIState> = {};
  @observable meetingUIStates: Record<string, MeetingUIState> = {};
  @observable heartbeatUI: HeartbeatManager | undefined;
  @observable feedbackUI: FeedbackManager | undefined;
  @observable teamAccessReqUI: Record<string, TeamAccessReqManager> = {};
  @observable callRequestUIStates: Record<string, CallRequestUIState> = {};
  @observable _appHomeUI: AppHomeUI | undefined;
  @observable _userManager: UserManager | undefined;

  _auth: OTAuth | undefined;

  @action
  reset = () => {
    logger.info("reset OTUITree");

    this.chatUIStates = {};
    this.meetingUIStates = {};
    this.heartbeatUI = undefined;
    this.feedbackUI = undefined;
    this.teamAccessReqUI = {};
    this.callRequestUIStates = {};
    this._appHomeUI = undefined;
    this._userManager = undefined;

    this._auth?.reset();

    OTGlobals.reset();

    windowState.reset();
  };

  registerChatManager = (chatManager: ChatManager) => {
    this.chatUIStates[chatManager.teamId] = new ChatUIState(chatManager);
  };

  registerMeetingManager = (meetingManager: MeetingManager) => {
    this.meetingUIStates[meetingManager.teamId] = new MeetingUIState(meetingManager);
  };

  registerTeamAccessReqManager = (f: TeamAccessReqManager) => (this.teamAccessReqUI[f.teamId] = f);

  registerCallRequestManager = (callRequestManager: CallRequestManager) =>
    (this.callRequestUIStates[callRequestManager.teamId] = new CallRequestUIState(
      callRequestManager
    ));

  registerAppHomeManager = (m: AppHomeManager) => (this._appHomeUI = new AppHomeUI(m));
  @computed get appHomeUI() {
    const f = this._appHomeUI;
    assertDefined(f);
    return f;
  }

  @computed get teamManagers() {
    return this.appHomeUI.teamManagers;
  }

  registerUserManager = (m: UserManager) => (this._userManager = m);
  @computed get userManager() {
    const f = this._userManager;
    assertDefined(f);
    return f;
  }
  @computed get unsafeUserManager() {
    return this._userManager;
  }

  registerAuth = (m: OTAuth) => (this._auth = m);
  get auth() {
    const f = this._auth;
    assertDefined(f);
    return f;
  }
}

export const OTUITree = new OTUITreeClass();


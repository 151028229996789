import { observer } from "mobx-react";
import React, { Component } from "react";
import { View } from "react-native";
import * as StyleConstants from "../../globals/StyleConstants";
import { OTTouchableOpacity } from "../../components";
import { OTText } from "../OTText";
import { EnableWebviewTag } from "../../utils/platform";
import { Theme } from "../../globals/ThemeColors";
import { OTGlobals } from "@openteam/app-core";
import { Feather } from "@expo/vector-icons";

interface IPluginContainerProps {
  teamId: string;
  pluginId: string;
  width: number;
  height: number;
  focused: boolean;
}

interface IPluginContainerState {}

@observer
export class PluginContainer extends Component<IPluginContainerProps, IPluginContainerState> {
  constructor(props) {
    super(props);

    this.state = {};

    console.log("creating pluginContainer");
  }

  setFocusPlugin = () => {
    const callStateManager = OTGlobals.callStateManager;
    const pluginManager = callStateManager!.pluginManager;
    pluginManager.setFocusPlugin(this.props.pluginId);
  };

  minimisePlugin = () => {
    this.setFocusPlugin();
  };

  closePlugin = () => {
    const { pluginId } = this.props;

    const callStateManager = OTGlobals.callStateManager;
    const pluginManager = callStateManager!.pluginManager;
    const pluginConfigList = OTGlobals.pluginConfigList;

    var pluginDetails = pluginManager.plugins[pluginId];
    var pluginConfig = pluginConfigList[pluginDetails.pluginType];

    confirm(`This will close ${pluginConfig.name} for everyone`) &&
      pluginManager.closePlugin(pluginId);
  };

  popoutPlugin = () => {
    const { pluginId } = this.props;

    const callStateManager = OTGlobals.callStateManager;
    const pluginManager = callStateManager!.pluginManager;

    pluginManager.popoutPlugin(pluginId);
  };

  render() {
    const { pluginId, width, height, focused } = this.props;

    const callStateManager = OTGlobals.callStateManager;
    const pluginManager = callStateManager!.pluginManager;
    const pluginConfigList = OTGlobals.pluginConfigList;

    var pluginDetails = pluginManager.plugins[pluginId];

    if (!pluginDetails) {
      return <View />;
    }

    var pluginConfig= pluginConfigList[pluginDetails.pluginType];

    return (
      <View>
        <OTTouchableOpacity
          key={pluginId}
          onPress={focused ? undefined : this.setFocusPlugin}
          disabled={focused}
        >
          <View
            pointerEvents={focused ? "auto" : "none"}
            style={{
              borderRadius: Theme.curviness,
              height,
              width,
              marginHorizontal: 5,
              marginVertical: 5,
              overflow: "hidden",
              backgroundColor: pluginConfig.backgroundColor || "white",
              ...StyleConstants.BoxShadow,
            }}
          >
            {focused ? (
              pluginConfig.webInline || EnableWebviewTag ? (
                <pluginConfig.component
                  key={pluginId}
                  pluginManager={callStateManager?.pluginManager}
                  pluginDetails={pluginDetails}
                />
              ) : (
                this.renderNotSupported()
              )
            ) : (
              this.renderMinimal()
            )}
          </View>
        </OTTouchableOpacity>
        {this.renderControls()}
      </View>
    );
  }

  renderControls = () => {
    const { pluginId,  } = this.props;

    const callStateManager = OTGlobals.callStateManager;
    const pluginManager = callStateManager!.pluginManager;
    const pluginConfigList = OTGlobals.pluginConfigList;

    var pluginDetails = pluginManager.plugins[pluginId];
    var pluginConfig = pluginConfigList[pluginDetails.pluginType];

    return (
      <View
        style={{
          position: "absolute",
          right: 10,
          top: 10,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <OTTouchableOpacity
          data-tip="Minimise"
          onPress={this.minimisePlugin}
          style={{
            backgroundColor: Theme.ButtonColor,
            width: 25,
            height: 25,
            borderRadius: 5,
            alignItems: "center",
            justifyContent: "center",
            marginRight: 5,
          }}
        >
          <Feather name="minus" size={18} style={{ color: Theme.OnPrimaryColor }} />
        </OTTouchableOpacity>
        {pluginConfig.canPopout ? (
          <OTTouchableOpacity
            data-tip="Open in window"
            onPress={this.popoutPlugin}
            style={{
              backgroundColor: Theme.ButtonColor,
              width: 25,
              height: 25,
              borderRadius: 5,
              alignItems: "center",
              justifyContent: "center",
              marginRight: 5,
            }}
          >
            <Feather
              name="external-link"
              size={18}
              style={{
                color: Theme.OnPrimaryColor,
              }}
            />
          </OTTouchableOpacity>
        ) : null}
        <OTTouchableOpacity
          data-tip="Close"
          onPress={this.closePlugin}
          style={{
            backgroundColor: Theme.ButtonColor,
            width: 25,
            height: 25,
            borderRadius: 5,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Feather
            name="x"
            size={18}
            style={{
              color: Theme.OnPrimaryColor,
            }}
          />
        </OTTouchableOpacity>
      </View>
    );
  };

  renderMinimal = () => {
    const { pluginId } = this.props;

    const callStateManager = OTGlobals.callStateManager;
    const pluginManager = callStateManager!.pluginManager;
    const pluginConfigList = OTGlobals.pluginConfigList;

    var pluginDetails = pluginManager.plugins[pluginId];
    var pluginConfig = pluginConfigList[pluginDetails.pluginType];

    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <pluginConfig.icon size={28} style={{ color: pluginConfig.iconColour }} />
        <OTText style={{ fontSize: 22, color: Theme.DarkText, fontWeight: "600" }}>
          {pluginConfig.name}
        </OTText>
      </View>
    );
  };

  renderNotSupported = () => {
    const { pluginId } = this.props;

    const callStateManager = OTGlobals.callStateManager;
    const pluginManager = callStateManager!.pluginManager;
    const pluginConfigList = OTGlobals.pluginConfigList;

    var pluginDetails = pluginManager.plugins[pluginId];
    var pluginConfig = pluginConfigList[pluginDetails.pluginType];

    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "space-around" }}>
        <View style={{ flexDirection: "row", alignItems: "center", padding: 5 }}>
          <pluginConfig.icon size={28} style={{ color: pluginConfig.iconColour }} />
          <OTText style={{ fontSize: 22, color: Theme.DarkText, fontWeight: "600" }}>
            {pluginConfig.name}
          </OTText>
        </View>
        <OTTouchableOpacity
          onPress={this.popoutPlugin}
          style={{ flexDirection: "row", alignItems: "center", padding: 5 }}
        >
          <Feather
            name="external-link"
            size={22}
            style={{ color: Theme.DarkText, marginRight: 10 }}
          />
          <OTText style={{ fontSize: 14, color: Theme.DarkText, textAlign: "center" }}>
            Click here to open {pluginConfig.name} in your browser
          </OTText>
        </OTTouchableOpacity>
      </View>
    );
  };
}

import { IActionTypeDetails } from "@openteam/models";
import { playAlert, playGlobalAlert, playKnock } from "../../utils/Notify";
import { SKMegaphone, SKKnock, SKMic, SKVideo } from "../SVGIcons";

import { Logger } from "@openteam/app-util";

const logger = new Logger("alerts/helpers");

export const getActionTypeConfig = (): Record<string, IActionTypeDetails> => ({
  PTT_WALKIE: {
    title: (teamUser) => "Walkie Talkie",
    presentBody: (teamUser) => `${teamUser.name} is talking to you`,
    pastBody: (teamUser) => `${teamUser.name} talked to you`,
    icon: SKMic,
    sound: playAlert,
    notify: true,
    alert: true,
  },
  PTT_GLOBAL: {
    title: (teamUser) => "Megaphone",
    presentBody: (teamUser) => `${teamUser.name} is using the megaphone`,
    pastBody: (teamUser) => `${teamUser.name} used the megaphone`,
    icon: SKMegaphone,
    sound: playGlobalAlert,
    notify: true,
    alert: true,
  },
  KNOCK: {
    title: (teamUser) => "Knock",
    presentBody: (teamUser) => `${teamUser.name} knocked`,
    pastBody: (teamUser) => `${teamUser.name} knocked`,
    icon: SKKnock,
    sound: playKnock,
    notify: true,
    alert: true,
  },
  CALL: {
    title: (teamUser) => "Incoming Call",
    presentBody: (teamUser) => `${teamUser.name} is calling you`,
    pastBody: (teamUser) => `${teamUser.name} called you`,
    icon: SKVideo,
    notify: false,
    alert: true,
  },
  BREAKROOM: {
    title: (teamUser) => `${teamUser.name} is taking a break`,
    presentBody: (teamUser) => `Join them in the break room`,
    pastBody: (teamUser) => `${teamUser.name} was in the break room`,
    notify: false,
    alert: false,
  },
});






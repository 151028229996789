import { Logger } from "@openteam/app-util";
const logger = new Logger("SignalsDb");

export class SignalsDb {
  static watchSignals = (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string,
    sessionToken: string,
    roomId: string,
    callback: (snapshot) => void
  ) => {
    var path = `/signals/${teamId}/user/${userId}/session/${sessionToken}/room/${roomId}`;

    fbDb.ref(path).on("child_added", (doc) => {
      //logger.debug("Got doc ", doc.key)
      callback(doc.val());
      fbDb.ref(`${path}/${doc.key}`).remove();
    });

    // getDB().collection('teams').doc(teamId).collection("signals").doc(userId).collection("messages").onSnapshot(
    //   callback
    // )
  };

  static unwatchSignals = (
    fbDb: firebase.database.Database,
    teamId: string,
    myUserId: string,
    mySessionToken: string,
    roomId: string
  ) => {
    var path = `/signals/${teamId}/user/${myUserId}/session/${mySessionToken}/room/${roomId}`;

    fbDb.ref(path).off("child_added");

    // getDB().collection('teams').doc(teamId).collection("signals").doc(userId).collection("messages").onSnapshot(
    //   callback
    // )
  };

  static addSignal = (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string,
    sessionToken: string,

    roomId: string,
    msg
  ) => {
    var path = `/signals/${teamId}/user/${userId}/session/${sessionToken}/room/${roomId}`;

    fbDb.ref(path).push(msg);
    // getDB().collection('teams').doc(teamId).collection("signals").doc(userId).collection("messages").add(msg)
  };
}

import React, { Component } from 'react';
import { googleConfig } from '../../globals/config';
import { View } from 'react-native';
import { observer } from 'mobx-react';

import { OTText, OTTextInput } from '../OTText';
import { showModal, dismissModal } from '../ModalService';
import { Theme } from '../../globals/ThemeColors';
import { InlineWeb } from '../InlineWeb';
import { OTButton } from '../buttons/OTButtons';
import { Logger } from '@openteam/app-util';
import GooglePicker from '../GooglePicker';
import { GoogleDriveIcon } from '../SVGIcons';
import { IPluginResource } from '@openteam/models';
import { IPluginConfig, PluginManager } from '@openteam/app-core';

const logger = new Logger("GoogleDrivePlugin")

interface IGoogleDrivePluginDetails extends IPluginResource {
  args?: IGoogleDriveArgs
}

interface IGoogleDriveProps {
  pluginManager: PluginManager
  pluginDetails: IGoogleDrivePluginDetails
  height: number
  width: number
}
interface IGoogleDriveState { }

interface IGoogleDriveArgs {
  url: string,
  title?: string
}

export class GoogleDrive extends Component<IGoogleDriveProps, IGoogleDriveState> {
  _iframe
  render() {
    if (!this.props.pluginDetails.args?.url) {
      return <View />
    }

    return (
      <InlineWeb
        ref={r => this._iframe = r}
        style={{ width: "100%", height: "100%" }}
        src={this.props.pluginDetails.args.url} />
    )
  }
}


interface IGoogleDriveSetupViewProps {
  pluginManager: PluginManager
  onClose: () => void
}

interface IGoogleDriveSetupViewState {
  urlText: string
}

@observer
export class GoogleDriveSetupView extends Component<IGoogleDriveSetupViewProps, IGoogleDriveSetupViewState> {
  constructor(props) {
    super(props);

    this.state = {
      urlText: '',
    }
  }

  handleEvent = (event, payload) => {

    if (event === 'SELECTED_FILES') {
      logger.info("payload is", payload)

      if (payload.files.length > 0) {
        var file = payload.files[0]

        this.createPlugin(
          {
            url: file.url,
            title: file.name
          }
        )

      }
    }
  }

  render() {
    return (
      <View style={{ alignItems: 'center' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <pluginConfig.icon size={22} style={{ color: pluginConfig.iconColour }} />
          <OTText style={{
            marginLeft: 10,
            fontSize: 18,
            color: Theme.DarkText,
            fontWeight: "600"
          }}>{pluginConfig.name}</OTText>
        </View>

        <View style={{ marginVertical: 20, alignItems: 'center' }}>
          <OTTextInput
            style={{
              padding: 10,
              minWidth: 300,
              backgroundColor: Theme.InputBackgroundColor,
              borderRadius: Theme.curviness, borderWidth: 1,
              borderColor: Theme.InputHighlightColor
            }}
            value={this.state.urlText}
            placeholder={"Google Drive URL"}
            onChangeText={(text) => this.setState({ urlText: text })}
            onSubmitEditing={this.setUrl} />
          <OTButton
            onPress={this.setUrl}
            title={"Open"}
            disabled={!this.state.urlText}
            backgroundColor={!this.state.urlText ? Theme.ButtonDisabledColor : undefined}
            outerStyle={{ flex: undefined, marginTop: 10 }}
          />
        </View>

        <OTText style={{ marginBottom: 20 }}>
          Or
          </OTText>

        <GooglePicker
          clientId={googleConfig.clientId}
          apiKey={googleConfig.apiKey}
          onEvent={this.handleEvent}
          multiSelect={false}
          exportAsBlobs={false}
          injectOnClick={true}
          allowSharedDrives={true}
          downloadSelectedFiles={false}
          origin={window.location.protocol + '//' + window.location.host}
        />

      </View>
    )
  }

  setUrl = () => {
    if (this.state.urlText) {

      const pluginArgs = getArgsFromURL(this.state.urlText);

      if (pluginArgs) {

        this.setState({
          urlText: '',
        })

        this.createPlugin(pluginArgs);
      }
    }
  }

  createPlugin = (args: IGoogleDriveArgs) => {

    this.props.pluginManager.createPlugin(pluginType, args)

    this.props.onClose()
  }
}

function getArgsFromURL(url: string): IGoogleDriveArgs | undefined {

  // Seems we can open pretty much any Google URL with the 'googledrive' plugin 
  var matches = url.match(/(?:https?:\/{2})?(?:.*\.)?google.com\/.+/);

  if (matches) {
    return { url }
  }
}

function setupPlugin(pluginManager) {
  showModal({
    contents: <GoogleDriveSetupView
      pluginManager={pluginManager}
      onClose={dismissModal}
    />,
    showContainer: true,
    showClose: true
  })
}

export const pluginType = "googledrive"
export const pluginConfig: IPluginConfig = {
  name: 'Google Drive',
  multi: true,
  component: GoogleDrive,
  setupFunc: setupPlugin,
  icon: GoogleDriveIcon,
  iconColour: '#F8D366',
  canPopout: true,
  webInline: true,
  canHandleUrl: getArgsFromURL,
  urlPriority: 10
}
import React, { Component } from "react";
import { OTTouchableOpacity } from "./buttons";
import { View, Image, ActivityIndicator, Text } from "react-native";
import { Theme } from '../globals/ThemeColors'
import { OTText } from "./OTText";
import { Feather } from '@expo/vector-icons';

interface IImageSelectorProps {
  width: number
  height: number
  loading: boolean
  onFiles: (e) => void
  imageUrl?: string
}
interface IImageSelectorState { }


export class ImageSelector extends Component<IImageSelectorProps, IImageSelectorState> {
  _fileInput

  render () {
    return (
      <View style={{ alignItems: 'flex-start' }}>
        <OTTouchableOpacity onPress={() => this._fileInput && this._fileInput.click()} style={{}} >
          <input ref={c => this._fileInput = c} style={{ display: 'none' }} type="file" onChange={this.props.onFiles} accept="image/x-png,image/gif,image/jpeg" />

          <View style={{
            width: this.props.width,
            height: this.props.height,
            borderRadius: Theme.curviness,
            justifyContent: 'center'
          }}>
            {
              this.props.loading ?
                <ActivityIndicator size={30} />
                :
                this.props.imageUrl ?
                  <View>
                    <Image source={{ uri: this.props.imageUrl }}
                      style={{ width: this.props.width, height: this.props.height, borderRadius: Theme.curviness }} />
                    <View style={{
                      position: 'absolute',
                      right: 5,
                      top: 5,
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      borderRadius: Theme.curviness, padding: 5
                    }}>
                      <Feather name="edit-2" style={{ color: 'white', }} />

                    </View>
                  </View>
                  :
                  <View style={{
                    flex: 1,
                    padding: 10,
                    borderWidth: 2,
                    borderStyle: 'dashed',
                    borderColor: '#EEEEEE',
                    backgroundColor: Theme.ControlBackground,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: Theme.curviness
                  }}>
                    <OTText style={{ textAlign: 'center', color: '#888' }}>Click to select an image</OTText>

                  </View>

            }
          </View>
        </OTTouchableOpacity>
      </View>
    )
  }
}
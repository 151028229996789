import React, { Component } from "react";
import { Linking, Text } from "react-native";
import Markdown, { MarkdownIt } from "react-native-markdown-display";
import { PlatformOS } from "../utils/platform";
import ScalableImage from "react-native-scalable-image";
import emoji from "markdown-it-emoji";
import { Theme } from "../globals/ThemeColors";
import { OTText } from "./OTText";
import { observer } from "mobx-react";

const markdownRules = {
  link: (node, children, parent, styles) => {
    return (
      <OTText
        key={node.key}
        style={[styles.link, { ...(PlatformOS != "mobile" ? { cursor: "pointer" } : {}) }]}
        accessibilityRole="link"
        hrefAttrs={{ target: "_blank" }}
        href={node.attributes.href}
      >
        {children}
      </OTText>
    );
  },
  emoji: (node, children, parent, styles) => {
    return (
      <Text key={node.key} style={[styles.text, { fontSize: 24 }]}>
        {node.content}
      </Text>
    );
  },
  image: (node, children, parent, styles, allowedImageHandlers, defaultImageHandler) => {
    const { src, alt } = node.attributes;

    // we check that the source starts with at least one of the elements in allowedImageHandlers
    const show =
      allowedImageHandlers.filter((value) => {
        return src.toLowerCase().startsWith(value.toLowerCase());
      }).length > 0;

    if (show === false && defaultImageHandler === null) {
      return null;
    }

    const imageProps: any = {
      indicator: true,
      key: node.key,
      style: [{ borderRadius: Theme.curviness, width: 300, height: 200 }, styles._VIEW_SAFE_image],
      source: {
        uri: show === true ? src : `${defaultImageHandler}${src}`,
      },
    };

    if (alt) {
      imageProps.accessible = true;
      imageProps.accessibilityLabel = alt;
    }

    return <ScalableImage {...imageProps} width={300} height={200} />;
  },
};

var markdownIt = MarkdownIt({ typographer: true, linkify: true, breaks: true });
markdownIt.use(emoji);

interface IOTMarkdownProps {}

interface IOTMarkdownState {}

@observer
export class OTMarkdown extends Component<IOTMarkdownProps, IOTMarkdownState> {
  render() {
    return (
      <Markdown
        markdownit={markdownIt}
        rules={markdownRules}
        style={{
          text: {
            fontFamily: PlatformOS != "mobile" ? "Lato" : undefined,
            color: Theme.MainText,
          },
          paragraph: {
            marginTop: 0,
            marginBottom: 0,
          },
          bullet_list: {
            color: Theme.MainText,
          },
          ordered_list: {
            color: Theme.MainText,
          },
          hr: {
            backgroundColor: Theme.MainText,
          },
          table: {
            borderColor: Theme.MainText,
          },
          tr: {
            borderColor: Theme.MainText,
          },
          blockquote: {
            backgroundColor: undefined,
            borderColor: "#CCC",
            borderLeftWidth: 4,
            marginLeft: 5,
            paddingHorizontal: 5,
          },
          code_inline: {
            //   borderWidth: 1,
            //   borderColor: '#CCCCCC',
            //   backgroundColor: Theme.OfficeBackground,
            padding: 1,
            paddingHorizontal: 4,
            //   borderRadius: 4,
            fontFamily: "Courier",
            //   color: Theme.MainText
          },
          code_block: {
            // borderWidth: 1,
            // borderColor: '#CCCCCC',
            // backgroundColor: Theme.OfficeBackground,
            // padding: 1,
            // paddingHorizontal: 2,
            // borderRadius: 4,
            fontFamily: "Courier",
            // color: Theme.MainText
          },
          fence: {
            //   borderWidth: 1,
            //   borderColor: '#CCCCCC',
            //   backgroundColor: Theme.OfficeBackground,
            //   padding: 1,
            //   paddingHorizontal: 2,
            //   borderRadius: 4,s
            fontFamily: "Courier",
            //   color: Theme.MainText
          },
        }}
      >
        {this.props.children}
      </Markdown>
    );
  }
}

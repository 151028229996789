import React, { Component } from 'react';
import { View, StyleSheet, Text, ImageBackground, ActivityIndicator} from 'react-native';


import * as Analytics from '../../utils/Analytics';
import * as StyleConstants from '../../globals/StyleConstants'
import { Theme } from '../../globals/ThemeColors';
import { sleep } from '@openteam/app-util';
import { FireDb, MediaDeviceManager, OTGlobals, OTUITree, WebcamStream } from "@openteam/app-core";
import { OTTouchableOpacity } from '../../components';
import * as ModalService from '../../components/ModalService'
import { showToast } from '../../components/Toasts';

import { Logger } from '@openteam/app-util';
import { OTText } from '../../components/OTText';
import { SKCamera } from '../../components/SVGIcons';
import { Feather } from '@expo/vector-icons';
import { getRTDB } from '../../globals/Fire/util';
import { DialogView } from "../DialogView";
import { UserVideo } from './UserVideo';
import { OTButton } from '../buttons';

const logger = new Logger("Selfie")

interface ISelfieButtonProps {
  teamId: string
}

export class SelfieButton extends Component<ISelfieButtonProps, any> {

  render() {
    return MediaDeviceManager.hasVideo ?
      <OTTouchableOpacity
        data-tip="Take a selfie"
        onPress={this.showModal}
        style={{ paddingLeft: 10 }}>
        <SKCamera size={20} style={{ color: 'white', }} />
      </OTTouchableOpacity >
      :
      null
  }

  showModal = () => {
    ModalService.showModal(
      {
        contents: <Selfie onClose={ModalService.dismissModal} teamId={this.props.teamId} />,
        showContainer: true,
        showClose: true,
      }
    )
  }
}

interface ISelfieProps {
  teamId: string
  onClose: () => void
}

interface ISelfieState {
  countdown?: number
  image?: Blob
  stream?: WebcamStream;
  imageCapture?: ImageCapture
  capturing: boolean
}

export class Selfie extends Component<ISelfieProps, ISelfieState> {
  _timeout;
  _countdownSecs = 3;

  constructor(props) {
    super(props)
    this.state = {
      countdown: undefined,
      capturing: false
    }
  }

  setupPhoto = async () => {

    if (!MediaDeviceManager.hasVideo) {
      return ModalService.dismissModal()
    }

    try {

      const stream = new WebcamStream(this.props.teamId, OTUITree.auth.userId, OTGlobals.mediaDevices);

      await stream.enableVideo();
      const videoTrack = stream.stream.getVideoTracks()[0];
      let imageCapture = new ImageCapture(videoTrack);

      this.setState({ stream, imageCapture });

      imageCapture.takePhoto()
    } catch (err) {
      logger.error("failed to access camera", err)
      showToast('Failed to access camera: ' + err.name, "error")
      ModalService.dismissModal()
    }

  }

  componentDidMount = () => {
    this.setupPhoto()
    Analytics.logEvent("selfie_opened")
  }

  componentWillUnmount = () => {
    this.stop()
  }

  stop = () => {
    this.state.stream?.shutdown()

    if (this._timeout) {
      clearTimeout(this._timeout)
      this._timeout = undefined
    }
    this.props.onClose()

  }

  cancel = () => {
    this.stop()
    Analytics.logEvent("selfie_cancelled")
  }

  startTimer = () => {
    this.setState({
      countdown: this._countdownSecs,
      image: undefined,
    })
    this._timeout = setInterval(this.timerRun, 1000)

  }

  timerRun = () => {

    if (this.state.countdown == undefined) {
      return
    }

    var countdown = this.state.countdown - 1

    this.setState({ countdown: countdown })

    if (countdown == 0) {
      if (this._timeout) {
        clearTimeout(this._timeout)
        this._timeout = undefined
      }

      this._takePhoto()
    }

  }


  _takePhoto = async () => {

    try {

      if (!this.state.stream || !this.state.imageCapture) {
        throw Error("No stream")
      }
      this.setState({capturing: true})

      let blob = await this.state.imageCapture.takePhoto()

      this.setState({ image: blob, capturing: false})

      Analytics.logEvent("selfie_taken")
    } catch (err) {
      logger.warn("Error capturing photo")
    }
  }

  savePhoto = async () => {
    try {
      if (!this.state.image) {
        throw Error("No image")
      }

      await FireDb.updateTeamUserImage(
        getRTDB(),
        OTUITree.auth.userId,
        this.props.teamId,
        this.state.image
      );
      Analytics.logEvent("selfie_saved")
    } catch (err) {
      logger.warn("Error capturing photo")
    } finally {
      this.stop()
    }

  }

  render() {
    return (
      <DialogView
        title={"Take a selfie"}
      >

        {

          this.state.image ?
            <ImageBackground source={{
              uri: URL.createObjectURL(this.state.image)
            }}
              style={{
                borderRadius: Theme.curviness,
                overflow: 'hidden',
                width: 500,
                height: 400,
              }}
            />
            :
            <View style={{
              backgroundColor: 'black',
              borderRadius: Theme.curviness,
              overflow: 'hidden',

            }}>

              <UserVideo
                componentId={"exttest"}
                flipVideo={true}
                muted={true}
                videoStream={this.state.stream?.stream}
                hasVideo={true}
                hasAudio={false}
                width={500}
                height={400}
              />

              {
                this.state.countdown != undefined ?
                  <View style={{
                    ...StyleSheet.absoluteFillObject,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    {
                      this.state.capturing ?
                        <ActivityIndicator size={80} color={"rgba(255,255,255,0.9)"} />
                        :
                        this.state.countdown > 0 ?
                          <OTText style={{ color: "rgba(255,255,255,0.9)", fontSize: 80, fontWeight: 'bold' }}>{this.state.countdown}</OTText>
                          :
                        null
                    }
                  </View>
                  :
                  null
              }
            </View>
        }
        <View style={{ marginTop: 20 }} />
        {
          this.state.image ?
            <View style={{flexDirection: 'row', height: 48,}}>
              <OTButton title="Re-take" backgroundColor={Theme.RecordingColor}  onPress={this.startTimer}/>
              <View style={{paddingHorizontal: 30}}/>
              <OTButton title="Use" onPress={ this.savePhoto}/>
            </View>
            :
            this.state.capturing || this.state.countdown?
              <ActivityIndicator size={48} color={Theme.RecordingColor} />
              :
              <OTTouchableOpacity
                data-tip="Click to take selfie"
                onPress={this.startTimer}
                style={{ height: 48, width: 48, borderRadius: 24, backgroundColor: Theme.RecordingColor }} />
        }

      </DialogView>
    )
  }
}
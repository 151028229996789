import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FlatList, ListRenderItemInfo, StyleSheet, View } from 'react-native';
import { Theme } from '../../globals/ThemeColors';
import { IChannel, IChannels } from '@openteam/models';
import { showModal } from '../ModalService';
import { OTButton } from '../buttons/OTButtons';
import { OTText } from '../OTText';
import { CreateChannel } from './CreateChannel';
import HeadIcon from '../HeadIcon';

import { OTGlobals, OTUITree } from "@openteam/app-core";

interface IChannelListProps {
  teamId: string
}

interface IChannelListState {
  channels?: IChannels
  loaded: boolean
}

@observer
export class ChannelList extends Component<IChannelListProps, IChannelListState> {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    }
  }

  componentDidMount = async () => {
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];
    const channels = await chatUIState.getChannels();

    this.setState({ channels });
  };

  showCreateChannel = () => {
    showModal({
      contents: <CreateChannel teamId={this.props.teamId} />,
      showContainer: true,
      showClose: true
    })
  }


  render() {

    return (
      <View style={{ flex: 1 }}>
        <FlatList
          disableVirtualization
          style={{ flex: 1, padding: 20 }}
          data={Object.keys(this.state.channels || {})}
          renderItem={this.renderChat}
          keyExtractor={(item: string) => item}
          ListEmptyComponent={this.renderChatEmpty}
        />
        <View style={{ alignItems: 'center', padding: 20 }}>
          <OTButton small={true} title={"Create Channel"} onPress={this.showCreateChannel} />
        </View>
      </View>
    )
  }

  renderChatEmpty = () => {
    return <View style={{ alignItems: 'center' }}><OTText>No channels yet</OTText></View>
  }


  renderChat = ({ item }: ListRenderItemInfo<string>) => {
    const channelId = item

    var channel = this.state.channels?.[channelId]

    if (!channel) {
      return <View />
    }
    return (
      <ChannelItem teamId={this.props.teamId} channelId={channelId} channel={channel} />
    )
  }
}



interface IChannelItemProps {
  teamId: string
  channelId: string
  channel: IChannel
}

interface IChannelItemState {
}

@observer
export class ChannelItem extends Component<IChannelItemProps, IChannelItemState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }


  render() {
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];

    const { channelId, channel } = this.props

    const teamData = OTGlobals.getTeamData(this.props.teamId);

    if (!channel) {
      return <View />
    }

    const hasJoined = chatUIState.channels[channelId] ? true : false;

    var users = channel.userIds?.filter(userId => userId != OTUITree.auth.userId) || []

    var imageUrl = channel.imageUrl
    if (!imageUrl && users.length == 1) {
      var teamUser = teamData.getTeamUser(users[0])

      imageUrl = teamUser.imageUrl
    }

    var name = channel.name
    if (!name) {
      name = users.map(userId => teamData.getTeamUser(userId).name).join(", ")
    }

    return (
      <View style={{ width: "100%", flexDirection: 'row', alignItems: 'center', paddingBottom: 10 }}>
        <HeadIcon imageUrl={imageUrl} name={name!} />
        <View style={{ paddingHorizontal: 10, paddingVertical: 5, flex: 1 }}>
          <OTText style={listStyles.title}>
            {name}
          </OTText>
          <OTText style={{}}>
            {channel.desc}
          </OTText>
        </View>
        {hasJoined ? <OTText style={{ marginHorizontal: 5 }}>Joined</OTText> : null}
        {hasJoined ? (
          <OTButton
            small={true}
            title={"Leave"}
            onPress={() => chatUIState.leaveChannel(channelId)}
          />
        ) : (
          <OTButton
            small={true}
            title={"Join"}
            onPress={() => chatUIState.joinChannel(channelId)}
          />
        )}
      </View>
    );
  }

}

const listStyles = StyleSheet.create({
  list: {
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    borderRadius: Theme.curviness,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    flex: 1
  },
  time: {
    marginLeft: 10,
    fontSize: 10,
  }
})
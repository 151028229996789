import React, { Component } from 'react';
import ReactPlayer from 'react-player'
import { IPluginConfig, PluginManager } from "@openteam/app-core";
import { IPluginResource } from "@openteam/models";
import { FaPlayCircle } from 'react-icons/fa';
import { View } from 'react-native';
import { observer } from 'mobx-react';
import * as StyleConstants from '../../globals/StyleConstants'
import { OTText, OTTextInput } from '../OTText';
import { Theme } from '../../globals/ThemeColors';
import { OTButton } from '../buttons/OTButtons';
import { showModal, dismissModal } from '../ModalService';

interface IYoutubePluginDetails extends IPluginResource {
  args: IVideoPlayerArgs
}

interface IVideoPlayerArgs {
  videoUrl: string,
  position: number,
  playing?: boolean,
  lastUpdate?: number
}

export class VideoPlayerController {
  pluginManager: PluginManager
  pluginDetails: IYoutubePluginDetails


  constructor(pluginManager: PluginManager, pluginDetails: IYoutubePluginDetails) {
    this.pluginManager = pluginManager
    this.pluginDetails = pluginDetails
  }
}

interface IVideoPlayerProps {
  pluginManager: PluginManager
  pluginDetails: IYoutubePluginDetails
  height: number
  width: number
}
interface IVideoPlayerState {
  urlText: string
}



@observer
export class VideoPlayer extends Component<IVideoPlayerProps, IVideoPlayerState> {
  player = React.createRef<ReactPlayer>()

  videoLoaded: boolean = false
  constructor(props) {
    super(props);

    this.state = {
      urlText: ''
    }
    console.log("creating YouTube")
  }

  componentDidUpdate(prevProps) {
    var prevArgs: IVideoPlayerArgs = prevProps.pluginDetails.args
    var args: IVideoPlayerArgs = this.props.pluginDetails.args
    this.syncVideo(prevArgs, args)
  }

  syncVideo = (prevArgs: IVideoPlayerArgs, args: IVideoPlayerArgs) => {
    if (!this.player.current) {
      return
    }

    if (
      prevArgs.videoUrl == args.videoUrl &&
      prevArgs.playing == args.playing &&
      prevArgs.position == args.position &&
      prevArgs.lastUpdate == args.lastUpdate
    ) {
      return
    }

    // if (prevArgs.videoUrl !== args.videoUrl) {
    //   this.videoLoaded = false
    //   this.player.current.loadVideoById(args.videoId);
    // }


    // if (!prevArgs.playing && args.playing) {
    //   console.log("playing video")
    //   this.player.current.playVideo();
    // }

    // if (prevArgs.playing && !args.playing) {
    //   console.log("pausing video")
    //   this.player.current.pauseVideo();
    // }

    this.syncTime()

  }

  syncTime = (force = false) => {
    // safe to call this as often as you like as it works
    // out the time difference since the last position

    var args: IVideoPlayerArgs = this.props.pluginDetails.args

    var currentTime = this.getCurrentTime()
    var remoteTime = args.position

    if (args.playing && args.lastUpdate) {
      // account for time elapsed
      remoteTime += (Date.now() - args.lastUpdate)

    }

    if (args.playing || force) {
      if (Math.abs(currentTime - remoteTime) > 500) {

        this.player.current?.seekTo(remoteTime / 1000);
      }
    }
  }

  getCurrentTime = () => {
    return this.player.current ? this.player.current.getCurrentTime() * 1000 : 0
  }

  render() {
    const opts = {
      width: '100%',
      height: '100%',
      // playerVars: {
      //   // https://developers.google.com/youtube/player_parameters
      //   autoplay: 1,
      // },
    };

    if (!this.props.pluginDetails.args.videoUrl) {
      return <View />
    }

    return (
      <ReactPlayer
        ref={this.player}
        width={"100%"}
        height={"100%"}
        key={`videoplayer`}
        id={`videoplayer-${this.props.pluginDetails.pluginId}`}
        url={this.props.pluginDetails.args.videoUrl}
        playing={this.props.pluginDetails.args.playing}
        controls={true}
        onReady={this._onReady}
        onPause={this._onPause}
        onPlay={this._onPlay}
        onSeek={this._onSeek}
      />

    )
  }


  setArgs = (args: {}) => {
    this.props.pluginManager.updatePluginArgs(
      this.props.pluginDetails.pluginId,
      {
        position: this.getCurrentTime(),
        lastUpdate: Date.now(),
        ...args,

      },
      true
    )
  }

  _onPlay = () => {

    if (!this.props.pluginDetails.args.playing) {
      this.setArgs({ playing: true })
    } else {
      this.syncTime(true) // hack to get around youtube initialisation time
    }
    this.props.pluginManager.onPlaying(this.props.pluginDetails.pluginId, true);
  }

  _onPause = () => {
    if (this.props.pluginDetails.args.playing) {
      this.setArgs({ playing: false })
    }
    this.props.pluginManager.onPlaying(this.props.pluginDetails.pluginId, false);
  }

  _onSeek = (seconds) => {

    if (seconds * 1000 != this.props.pluginDetails.args.position) {
      this.setArgs({ position: seconds * 1000 })
    }
  }

  _onReady = (event) => {
    // access to player in all event handlers via event.target
    // var pluginDetails = this.props.pluginDetails
    // var args = pluginDetails.args

    // this.syncVideo({
    //   videoUrl: args.videoUrl,
    //   playing: false,
    //   position: 0
    // }, args)
  }
}


interface IVideoPlayerSetupViewProps {
  pluginManager: PluginManager
  onClose: () => void
}

interface IVideoPlayerSetupViewState {
  urlText: string
}

@observer
export class VideoPlayerSetupView extends Component<IVideoPlayerSetupViewProps, IVideoPlayerSetupViewState> {
  constructor(props) {
    super(props);

    this.state = {
      urlText: '',
    }
  }

  render() {
    return (
      <View style={{ alignItems: 'center' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <FaPlayCircle size={22} style={{ color: Theme.MainColour, marginRight: 10 }} />
          <OTText style={{
            fontSize: 18,
            color: Theme.DarkText,
            fontWeight: "600"
          }}>Video Player</OTText>
        </View>
        <OTText style={{ marginTop: 20, width: 300, alignItems: 'center', textAlign: 'center' }}>
          Works with:
        </OTText>

        <OTText style={{ marginTop: 20, width: 300, alignItems: 'center', textAlign: 'center' }}>
          YouTube, Facebook, Twitch, Vimeo, and DailyMotion
        </OTText>



        <View style={{ marginVertical: 20, alignItems: 'center' }}>
          <OTTextInput
            style={{ padding: 10, minWidth: 300, backgroundColor: Theme.InputBackgroundColor, borderRadius: StyleConstants.curviness, borderWidth: 1, borderColor: Theme.InputHighlightColor }}
            value={this.state.urlText}
            placeholder={"Enter Video Link"}
            onChangeText={(text) => this.setState({ urlText: text })}
            onSubmitEditing={this.setUrl} />
        </View>
        <View style={{ flexDirection: 'row' }}>

          <OTButton
            onPress={this.setUrl}
            title={"Open"}
            disabled={!this.state.urlText}
            backgroundColor={!this.state.urlText ? Theme.ButtonDisabledColor : undefined}
          />
        </View>

      </View>
    )
  }

  setUrl = () => {
    if (this.state.urlText) {
      var pluginArgs = getArgsFromURL(this.state.urlText)
      if (pluginArgs) {

        this.setState({
          urlText: '',
        })

        this.props.pluginManager.createPlugin(pluginType, pluginArgs)

        this.props.onClose()
      }
    }
  }
}

function getArgsFromURL(url: string): IVideoPlayerArgs | undefined {
  if (ReactPlayer.canPlay(url)) {
    return {
      videoUrl: url,
      playing: false,
      position: 0,
    }
  }
}

function setupPlugin(pluginManager) {
  showModal({
    contents: <VideoPlayerSetupView
      pluginManager={pluginManager}
      onClose={dismissModal}
    />,
    showContainer: true,
    showClose: true
  })
}

export const pluginType = 'videoplayer'
export const pluginConfig: IPluginConfig = {
  name: 'Video Player',
  multi: true,
  canPopout: false,
  component: VideoPlayer,
  setupFunc: setupPlugin,
  icon: FaPlayCircle,
  iconColour: Theme.MainColour,
  webInline: true,
  backgroundColor: 'black',
  canHandleUrl: getArgsFromURL,
  urlPriority: 50
}
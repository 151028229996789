import React, { Component } from 'react';
import { IPluginConfig, PluginManager } from "@openteam/app-core";
import { IPluginResource } from "@openteam/models";
import { View } from 'react-native';
import { observer } from 'mobx-react';
import { OTText, OTTextInput } from '../OTText';
import { Theme } from '../../globals/ThemeColors';
import { OTButton } from '../buttons/OTButtons';
import { InlineWeb } from '../InlineWeb';
import { showModal, dismissModal } from '../ModalService';
import { FontAwesome } from '@expo/vector-icons';
import { PlatformOS } from '../../utils/platform';
import { FaTrello } from 'react-icons/fa';


interface ITrelloPluginDetails extends IPluginResource {
  args?: ITrelloArgs
}

interface ITrelloProps {
  pluginManager: PluginManager
  pluginDetails: ITrelloPluginDetails
  height: number
  width: number
}
interface ITrelloState {
  loadedScript: boolean
}

interface ITrelloArgs {
  url: string,
}

export class Trello extends Component<ITrelloProps, ITrelloState> {
  constructor(props) {
    super(props)

    this.state = {
      loadedScript: false
    }
  }
  setArgs = (args: {}) => {
    this.props.pluginManager.updatePluginArgs(
      this.props.pluginDetails.pluginId,
      {
        ...this.props.pluginDetails.args,
        ...args,
      },
      true
    )
  }

  _iframe
  render() {
    if (!this.props.pluginDetails.args?.url) {
      return <View />
    }

    return (
      <InlineWeb
        // @ts-expect-error border not part of types
        style={{ width: "100%", height: "100%", border: 0, background: 'white' }}
        src={this.props.pluginDetails.args.url + `?iframeSource=localhost`} />
    )
  }
}


interface ITrelloSetupViewProps {
  pluginManager: PluginManager
  onClose: () => void
}

interface ITrelloSetupViewState {
  urlText: string
}

@observer
export class TrelloSetupView extends Component<ITrelloSetupViewProps, ITrelloSetupViewState> {
  constructor(props) {
    super(props);

    this.state = {
      urlText: '',
    }
  }

  render() {
    return (
      <View style={{ alignItems: 'center' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <pluginConfig.icon size={22} style={{ color: pluginConfig.iconColour, marginRight: 10 }} />
          <OTText style={{
            fontSize: 18,
            color: Theme.DarkText,
            fontWeight: "600"
          }}>{pluginConfig.name}</OTText>
        </View>

        <View style={{ marginVertical: 20, alignItems: 'center' }}>
          <OTTextInput
            style={{ padding: 10, minWidth: 300, backgroundColor: Theme.InputBackgroundColor, borderRadius: Theme.curviness, borderWidth: 1, borderColor: Theme.InputHighlightColor }}
            value={this.state.urlText}
            placeholder={"Trello URL"}
            onChangeText={(text) => this.setState({ urlText: text })}
            onSubmitEditing={this.setUrl} />
        </View>
        <View style={{ flexDirection: 'row' }}>

          <OTButton
            onPress={this.setUrl}
            title={"Open"}
            disabled={!this.state.urlText}
            backgroundColor={!this.state.urlText ? Theme.ButtonDisabledColor : undefined}
          />
        </View>

      </View>
    )
  }

  setUrl = () => {
    if (this.state.urlText) {
      const pluginArgs = getArgsFromURL(this.state.urlText)

      if (pluginArgs) {

        this.setState({
          urlText: '',
        })

        this.props.pluginManager.createPlugin(pluginType, pluginArgs)

        this.props.onClose()
      }
    }
  }
}

function getArgsFromURL(url: string): ITrelloArgs | undefined {

  var matches = url.match(/(?:https?:\/{2})?(?:w{3}\.)?trello.com\/.+/);

  if (matches) {
    return { url }
  }
}

function setupPlugin(pluginManager) {
  showModal({
    contents: <TrelloSetupView
      pluginManager={pluginManager}
      onClose={dismissModal}
    />,
    showContainer: true,
    showClose: true
  })
}


export class TrelloIcon extends Component {
  render() {
    if (PlatformOS == 'mobile') {
      return (
        <FontAwesome name="trello" {...this.props} />
      )
    }

    return <FaTrello {...this.props} />

  }
}


export const pluginType = 'trello'
export const pluginConfig: IPluginConfig = {
  name: 'Trello',
  multi: false,
  component: Trello,
  setupFunc: setupPlugin,
  icon: TrelloIcon,
  iconColour: '#3477BA',
  canPopout: true,
  webInline: false,
  canHandleUrl: getArgsFromURL,
  urlPriority: 100
}
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { Logger } from "@openteam/app-util";
import { IPluginResource } from "@openteam/models";

const logger = new Logger("PluginDb");

export class PluginDb {
  static watchRoomPlugins = (
    fbDb: firebase.database.Database,
    teamId: string,
    roomId: string,
    callback: (snapshot: any, deleted: any) => void
  ) => {
    var ref = fbDb.ref(`/roomdetails/${teamId}/room/${roomId}/plugins/`);

    ref.on("child_added", (doc) => callback(doc.val(), false));
    ref.on("child_changed", (doc) => callback(doc.val(), false));
    ref.on("child_removed", (doc) => callback(doc.val(), true));
  };

  static setRoomPlugin = (
    fbDb: firebase.database.Database,
    teamId: string,
    roomId: string,
    pluginId: string,
    pluginData: IPluginResource
  ) => {
    fbDb.ref(`/roomdetails/${teamId}/room/${roomId}/plugins/${pluginId}`).set(pluginData);
  };

  static genPluginId(fbDb: firebase.database.Database, teamId: string, roomId: string) {
    return fbDb.ref(`/roomdetails/${teamId}/room/${roomId}/plugins/`).push().key!;
  }
}

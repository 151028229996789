import { action, autorun, observable, runInAction } from "mobx";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as uuid from "react-native-uuid";
import "firebase/database";
import { ChatDb } from "../fire";
import {
  IMessageFile,
  IOTChatMessage,
  IPendingMessage,
  TChatType,
  INotification,
  IMessage,
  IChannel,
  IChannelUser,
  IFile,
} from "@openteam/models";
import { Logger, AwaitLock } from "@openteam/app-util";
import { CloudUpload } from "../CloudUpload";
import { RNCloudUpload } from "../RNCloudUpload";
import { windowState } from "../WindowState";
import { OTUITree } from "../OTUITree";
import { OTGlobals } from "../OTGlobals";
import { ChatMessageManager } from "./ChatMessageManager";
import { OTUserInterface } from "../OTUserInterface";

const logger = new Logger("ChatManager");

export class ChatManager {
  teamId: string;
  userId: string;
  fbDb: firebase.firestore.Firestore;

  isFirstChatInfo: boolean = true;

  _messageLock = new AwaitLock();

  @observable focusedChannelTopic?: { channelId: string; topicId: string };
  @observable draftMessages: Record<string, Record<string, string>> = {};
  @observable draftReplyMessage: Record<string, Record<string, IOTChatMessage>> = {};
  @observable draftFiles: Record<string, Record<string, IMessageFile[]>> = {};
  @observable channels: Record<string, IChannel> = {};
  @observable userChannels: Record<string, IChannelUser> = {};
  @observable messageManager: Record<string, Record<string, ChatMessageManager>> = {};
  @observable pendingMessages: Record<string, Record<string, IPendingMessage>> = {};

  _autorun: Record<string, any> = {};

  unwatchUserChannelList?: () => void;
  unwatchChannelList?: () => void;
  constructor(fbDb: firebase.firestore.Firestore, teamId: string, userId: string) {
    this.teamId = teamId;
    this.userId = userId;
    this.fbDb = fbDb;

    logger.info(`initialised with team_id=${this.teamId}`);

    OTUITree.registerChatManager(this);
  }

  start = () => {
    logger.debug("start");

    const userChannelsDoc = OTGlobals.cache.getTeamCache(
      this.userId,
      this.teamId,
      "chat",
      "userchannels"
    );

    if (userChannelsDoc) {
      this.handleUserChannelList(userChannelsDoc, [], [], true);
    }

    this.unwatchUserChannelList = ChatDb.watchUserChannelList(
      this.fbDb,
      this.teamId,
      this.userId,
      this.handleUserChannelList
    );

    const watchChannelListDoc = OTGlobals.cache.getTeamCache(
      this.userId,
      this.teamId,
      "chat",
      "channels"
    );

    if (watchChannelListDoc) {
      this.handleChannelList(watchChannelListDoc, [], [], true);
    }

    this.unwatchChannelList = ChatDb.watchChannels(
      this.fbDb,
      this.teamId,
      this.userId,
      this.handleChannelList
    );

    this.loadFromAsyncStorage();

    this._autorun["updateUnreadChats"] = autorun(() => {
      const teamData = OTGlobals.getTeamData?.(this.teamId);

      let unreadChats = 0;

      for (const channelId in this.userChannels) {
        for (const topicId in this.channels[channelId]?.topics || {}) {
          if (
            (this.userChannels[channelId]?.topics?.[topicId]?.messageNum || 0) <
            (this.channels[channelId]?.topics?.[topicId]?.messageNum || 0)
          ) {
            unreadChats += 1;
          }
        }
      }

      if (teamData) {
        teamData.unreadChats = unreadChats;
      }
    });

    this._autorun["saveChatSettings"] = autorun(
      () => {
        AsyncStorage.setItem(
          `chatSettings-${this.teamId}`,
          JSON.stringify({
            focusedChannelTopic: this.focusedChannelTopic,
            draftMessages: this.draftMessages,
          })
        );
      },
      { delay: 1000 }
    );
  };

  stop = () => {
    logger.debug("stopping");

    Object.values(this._autorun).map((x) => x());
    this._autorun = {};

    this.unwatchUserChannelList && this.unwatchUserChannelList();
    this.unwatchChannelList && this.unwatchChannelList();
  };

  @action
  handleUserChannelList = (
    added: IChannelUser[],
    edited: IChannelUser[],
    deleted: string[],
    isCached: boolean = false
  ) => {
    for (let channelId of deleted) {
      this.closeChannel(channelId);
      delete this.userChannels[channelId];
    }

    for (let channelUser of added) {
      this.userChannels[channelUser.channelId] = channelUser;
    }

    for (let channelUser of edited) {
      this.userChannels[channelUser.channelId] = channelUser;
    }

    if (this.userChannels && !isCached) {
      OTGlobals.cache.setTeamCache(
        this.userId,
        this.teamId,
        "chat",
        "userchannels",
        Object.values(this.userChannels)
      );
    }
  };

  @action
  handleChannelList = async (
    added: IChannel[],
    edited: IChannel[],
    deleted: string[],
    isCached: boolean = false
  ) => {
    const allowNotify = !isCached && !this.isFirstChatInfo;

    for (let channel of added) {
      await this.handleChannel(channel.id, channel, allowNotify);
    }

    for (let channel of edited) {
      await this.handleChannel(channel.id, channel, allowNotify);
    }

    for (let channelId of deleted) {
      delete this.channels[channelId];
    }

    if (this.channels && !isCached) {
      OTGlobals.cache.setTeamCache(
        this.userId,
        this.teamId,
        "chat",
        "channels",
        Object.values(this.channels)
      );
    }

    if (!isCached && this.isFirstChatInfo) {
      this.isFirstChatInfo = false;
    }
  };

  handleChannel = async (channelId: string, doc: IChannel, allowNotify: boolean) => {
    const userChannel = this.getUserChannel(channelId);
    const prevDoc = this.getChannel(channelId);

    if (userChannel && prevDoc) {
      for (let topicId in doc.topics || {}) {
        const userTopic = userChannel.topics?.[topicId];
        const prevTopic = prevDoc.topics?.[topicId];
        const topic = doc.topics?.[topicId];

        if (topic?.messageNum && prevTopic?.messageNum != topic?.messageNum) {
          if (allowNotify && !userTopic?.muteNotify) {
            if (topic?.lastMessage) {
              this.notify(topic.lastMessage);
            }
          }
          if (!userChannel.bookmarked) {
            logger.info("going to bookmark prevDoc", prevDoc, "doc", doc);
            ChatDb.bookmarkChat(this.fbDb, this.teamId, this.userId, channelId, true);
          }
        }
      }
    }

    this.channels[channelId] = doc;
  };

  notify = (message: IMessage) => {
    const teamData = OTGlobals.getTeamData(this.teamId);
    const channelId = message.channelId;
    const topicId = message.topicId;

    if (!channelId) {
      return;
    }

    const channel = this.getChannel(channelId);
    const topic = channel.topics?.[topicId];
    const isNotMe = message.userId != this.userId;

    const isFocused =
      this.focusedChannelTopic?.channelId == channelId &&
      this.focusedChannelTopic?.topicId == topicId;
    const present =
      windowState.windowFocused && OTGlobals.auth.userManager.currentTeamId === this.teamId;
    logger.info("present=${present} windowState=${windowState}");

    const now = new Date().getTime();

    logger.debug("notify message.crDate", message.crDate);

    let messageEpoch: number = message.crDate?.getTime() || 0;

    const isRecent = now - messageEpoch < 5 * 60 * 1000;

    if (message && isNotMe && isRecent && (!present || !isFocused) && !teamData.inQuiet) {
      // new message that I didn't send
      const sendUserDoc = teamData.getTeamUser(message.userId);

      let title = `${sendUserDoc.name} sent you a message`;

      if (channel?.name) {
        title = `${sendUserDoc.name} sent a message to ${channel.name}`;
      }

      if (topic?.name && topic?.name != "default") {
        title += ` - ${topic.name}`;
      }

      let onPress = () => this.goChannel(channelId, topicId);

      if (OTUserInterface.platformUtils.PlatformOS == "mobile") {
        onPress = () => {
          OTUserInterface.navigate("Chat", {
            teamId: this.teamId,
            channelId: channelId,
            topicId: topicId,
          });
        };
      }

      const notification: INotification = {
        teamId: this.teamId,
        title: title,
        text: message.message,
        onPress: onPress,
      };

      this.doNotification(notification);
    }
  };

  doNotification = (n: INotification) => {
    const teamData = OTGlobals.getTeamData(this.teamId);

    if (OTUserInterface.platformUtils.PlatformOS == "mobile") {
      OTUserInterface.toastHandlers.show(n.title, "info", n.text, () => {
        n.onPress && n.onPress();
        OTUserInterface.toastHandlers.hide();
      });
    } else {
      OTUserInterface.showNotification(n);
    }

    if (!teamData.inCall) {
      OTUserInterface.soundEffects.newMessage();
    }
  };

  loadFromAsyncStorage = async () => {
    const jsonString = await AsyncStorage.getItem(`chatSettings-${this.teamId}`);
    const loadedSettings = jsonString && JSON.parse(jsonString);

    if (loadedSettings) {
      if (OTUserInterface.platformUtils.PlatformOS != "mobile") {
        if (
          loadedSettings.focusedChannelTopic?.channelId &&
          loadedSettings.focusedChannelTopic?.topicId
        ) {
          this.focusedChannelTopic = loadedSettings.focusedChannelTopic || this.focusedChannelTopic;
        }

        if (this.focusedChannelTopic) {
          const { channelId, topicId } = this.focusedChannelTopic;

          if (!this.messageManager[channelId]) {
            this.messageManager[channelId] = {};
          }

          if (!this.messageManager[channelId][topicId]) {
            this.messageManager[channelId][topicId] = new ChatMessageManager(
              this.fbDb,
              this.teamId,
              this.userId,
              this.focusedChannelTopic.channelId,
              this.focusedChannelTopic.topicId,
              this.getUserChannel
            );
          }
        }
      }

      this.draftMessages = loadedSettings.draftMessages || this.draftMessages;

      for (const channelId in this.draftMessages) {
        if (typeof this.draftMessages[channelId] === "string") {
          this.draftMessages[channelId] = {};
        }
      }
    }
  };

  markChatRead(channelId: string, topicId: string) {
    logger.info("marked chat read", channelId);

    const channel = this.channels?.[channelId];
    if (channel) {
      ChatDb.markChatRead(
        this.fbDb,
        this.teamId,
        this.userId,
        channelId,
        topicId,
        channel.topics?.[topicId].messageNum || 0,
        channel.topics?.[topicId].messageId || 0
      );
    }
  }

  getChatName = (chat: IChannel) => {
    const teamData = OTGlobals.getTeamData(this.teamId);
    const users = chat.userIds?.filter((userId) => userId != this.userId) || [];

    return users.map((userId) => teamData.getTeamUser(userId).name).join(", ");
  };

  createChannel = async (
    userIds: string[],
    name: string,
    desc?: string,
    chatType: TChatType = "channel",
    teamDefault: boolean = false
  ) => {
    const channelId = await ChatDb.addChannel(
      this.fbDb,
      this.teamId,
      this.userId,
      userIds,
      name,
      desc,
      chatType,
      teamDefault
    );
    logger.info("creating channel", name, channelId);

    return channelId;
  };

  updateChannel = async (channelId: string, userIds: string[], name: string, desc?: string) => {
    logger.info("creating channel", name);
    await ChatDb.updateChannel(this.fbDb, channelId, this.teamId, userIds, name, desc);

    return channelId;
  };

  removeChannelUser = async (channelId: string, userId: string) => {
    await ChatDb.removeChannelUser(this.fbDb, channelId, this.teamId, userId);
  };

  joinChannel = async (channelId: string) => {
    await ChatDb.joinChannel(this.fbDb, this.teamId, this.userId, channelId);

    this.goChannel(channelId, "default");
  };

  leaveChannel = async (channelId: string) => {
    await ChatDb.leaveChannel(this.fbDb, this.teamId, this.userId, channelId);

    this.closeChannel(channelId);
  };

  addDirectChannel = async (userIds: string[]) =>
    await ChatDb.addDirectChannel(this.fbDb, this.teamId, this.userId, userIds);

  getUserChannel = (channelId: string) => {
    return this.userChannels && this.userChannels[channelId];
  };

  getChannel = (channelId: string) => {
    return this.channels && this.channels[channelId];
  };

  getChannels = async () => {
    return await ChatDb.getChannels(this.fbDb, this.teamId);
  };

  bookmarkChat = (channelId: string, topicId: string, bookmarked: boolean) => {
    if (
      !bookmarked &&
      channelId == this.focusedChannelTopic?.channelId &&
      (topicId == this.focusedChannelTopic?.topicId || topicId == "default")
    ) {
      this.focusedChannelTopic = undefined;
    }

    ChatDb.bookmarkChat(this.fbDb, this.teamId, this.userId, channelId, bookmarked);
  };

  muteChatNotify = (channelId: string, topicId: string, muted: boolean) => {
    ChatDb.muteChatNotify(this.fbDb, this.teamId, this.userId, channelId, topicId, muted);
  };

  loadChannel = async (channelId) => {
    return await ChatDb.getChannel(this.fbDb, this.teamId, channelId);
  };

  goChannel = (channelId: string, topicId: string) => {
    logger.info("goChannel", channelId, topicId);

    if (
      this.focusedChannelTopic?.channelId != channelId ||
      this.focusedChannelTopic?.topicId != topicId
    ) {
      if (this.focusedChannelTopic) {
        // this.closeChannel(this.focusedchannelId)
      }
      this.focusedChannelTopic = { channelId, topicId };

      if (!this.messageManager[channelId]) {
        this.messageManager[channelId] = {};
      }
      if (!this.messageManager[channelId][topicId]) {
        this.messageManager[channelId][topicId] = new ChatMessageManager(
          this.fbDb,
          this.teamId,
          this.userId,
          channelId,
          topicId,
          this.getUserChannel
        );
      }
    }

    if (!this.userChannels[channelId]?.bookmarked) {
      ChatDb.bookmarkChat(this.fbDb, this.teamId, this.userId, channelId, true);
    }

    const teamData = OTGlobals.getTeamData(this.teamId);

    if (teamData.inVideoChat) {
      const callStateManager = OTGlobals.callStateManager;

      callStateManager?.setFocusRoom(false);
    }
  };

  closeChannel = (channelId?: string, topicId?: string) => {
    logger.info("closeChannel", channelId, topicId);

    if (
      channelId &&
      topicId &&
      this.focusedChannelTopic?.channelId == channelId &&
      this.focusedChannelTopic?.topicId == topicId
    ) {
      this.focusedChannelTopic = undefined;

      if (this.messageManager[channelId] && this.messageManager[channelId][topicId]) {
        this.messageManager[channelId][topicId].stop();
        delete this.messageManager[channelId][topicId];
      }
    }
  };

  createTopic = async (channelId: string, name: string) => {
    const topicId = await ChatDb.createTopic(this.fbDb, this.teamId, this.userId, channelId, name);
    return topicId;
  };

  editTopic = async (channelId: string, topicId: string, name: string) => {
    await ChatDb.editTopic(this.fbDb, this.teamId, this.userId, channelId, topicId, name);
  };

  archiveTopic = async (channelId: string, topicId: string, archive: boolean) => {
    await ChatDb.archiveTopic(this.fbDb, this.teamId, this.userId, channelId, topicId, archive);

    if (archive) {
      this.goChannel(channelId, "default");
    }
  };

  addDraftFiles = (channelId: string, topicId: string, files: FileList | File[] | IFile[] | null) => {
    if (!files) {
      return;
    }

    if (!this.draftFiles[channelId]) {
      this.draftFiles[channelId] = {};
    }

    const draftFiles = this.draftFiles[channelId][topicId]
      ? [...this.draftFiles[channelId][topicId]]
      : [];

    Object.keys(files || {}).forEach((i) => {
      let file = files[i];

      if (OTUserInterface.platformUtils.PlatformOS == "mobile") {
        draftFiles.push(new RNCloudUpload(this.teamId, undefined, this.userId, "chat", file));
      } else {
        draftFiles.push(new CloudUpload(this.teamId, undefined, this.userId, "chat", file));
      }

    });

    this.draftFiles[channelId][topicId] = draftFiles;
  };

  setDraftFiles = (channelId, topicId, draftFiles) => {
    if (!this.draftFiles[channelId]) {
      this.draftFiles[channelId] = {};
    }

    this.draftFiles[channelId][topicId] = draftFiles;
  };

  setDraftReplyMessage = (channelId: string, topicId: string, message: IOTChatMessage) => {
    if (!this.draftReplyMessage[channelId]) {
      this.draftReplyMessage[channelId] = {};
    }

    this.draftReplyMessage[channelId][topicId] = message;
  };

  deleteDraftReplyMessage = (channelId: string, topicId: string) => {
    delete this.draftReplyMessage[channelId]?.[topicId];
  };

  sendChatMessage = async (
    channelId: string,
    topicId: string,
    text,
    files,
    replyMessage?: IOTChatMessage,
  ) => {
    await this._messageLock.acquireAsync();
    if (!this.pendingMessages[channelId!]) {
      this.pendingMessages[channelId!] = {};
    }

    try {
      const tempId = uuid.v1();

      const message = {
        text,
        files,
      };
      this.pendingMessages[channelId!][tempId] = message;

      await ChatDb.addChatMessage(
        this.fbDb,
        this.teamId,
        this.userId,
        channelId,
        topicId,
        text,
        files,
        replyMessage
      );

      delete this.pendingMessages[channelId!][tempId];

      return channelId;
    } finally {
      this._messageLock.release();
    }
  };

  editChatMessage = async (channelId: string, topicId: string, messageId: string, text) => {
    await ChatDb.editChatMessage(this.fbDb, this.teamId, channelId, topicId, messageId, text);
  };

  deleteChatMessage = async (channelId: string, topicId: string, messageId: string) => {
    await ChatDb.deleteChatMessage(this.fbDb, this.teamId, channelId, topicId, messageId);
  };

  setIsTyping = async (channelId: string, topicId: string, isTyping: boolean) => {
    await this._messageLock.acquireAsync();

    try {
      logger.debug("setIsTyping", this.teamId, channelId, topicId, isTyping);
      await ChatDb.setIsTyping(this.fbDb, this.teamId, this.userId, channelId, topicId, isTyping);
    } catch (e) {
      logger.error("failed to set IsTyping", e);
    } finally {
      this._messageLock.release();
    }
  };
}

export function applyObjectUpdate(base: Object, update: Object) {
  Object.keys(update).forEach((key) => {
    if (base[key] != update[key]) {
      base[key] = update[key];
    }
  });
  Object.keys(base).forEach((key) => {
    if (!(key in update)) {
      delete base[key];
    }
  });
}

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import "firebase/firestore";

export function getRTDB() {
  return firebase.database();
}

export function getFSDB() {
  return firebase.firestore();
}

export function getFirebaseAuth() {
  return firebase.auth();
}

export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const firebaseTimestamp = firebase.firestore.Timestamp;

import { observer } from "mobx-react";
import React, { Component } from 'react';
import { OTPopover } from "../../components/OTPopover";
import { OTUserData } from "../../data/OTData";
import { AlertBadgesView } from "../fragments/Alerts";
import HeadIcon from "../../components/HeadIcon";
import { View, FlatList } from "react-native";
import { OTText } from "../../components/OTText";
import { OTButton } from "../../components/buttons/OTButtons";
import { SKMessage, SKMic, SKMicOff } from "../../components/SVGIcons";
import * as StyleConstants from '../../globals/StyleConstants';
import { OTTouchableOpacity } from "../../components";
import { Theme } from "../../globals/ThemeColors";
import { Feather } from '@expo/vector-icons';
import { OTGlobals, OTUITree } from "@openteam/app-core";
import { IOTRoomUser } from "@openteam/models";
import { Logger } from "@openteam/app-util";

const logger = new Logger("VideoCallParticpants");

interface IVideoCallParticipantsProps {
  teamId: string
  roomId: string
  onClose: () => void
}

interface IVideoCallParticipantsState {
}

@observer
export class VideoCallParticipants extends Component<IVideoCallParticipantsProps, IVideoCallParticipantsState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  muteUser = (userId) => {
    const callStateManager = OTGlobals.callStateManager;
    callStateManager?.muteUser(userId, true)
  }

  unmuteUser = (userId) => {
    const callStateManager = OTGlobals.callStateManager;
    callStateManager?.muteUser(userId, false)
  }

  muteAll = () => {
    const callStateManager = OTGlobals.callStateManager;
    callStateManager?.muteAll(true)
  }

  unMuteAll = () => {
    const { teamId } = this.props
    const callStateManager = OTGlobals.callStateManager;
    callStateManager?.muteAll(false)
  }

  render() {
    const { teamId, roomId } = this.props

    const teamManager = OTUserData.externalMeeting ? undefined : OTUITree.teamManagers[teamId];
    const teamData = OTGlobals.getTeamData(teamId);
    const room = teamData.rooms[roomId];

    logger.debug("room.users=%o", room.users);

    return (
      <View style={[
        {
          backgroundColor: Theme.DialogBackground,
          overflow: 'hidden',
          minHeight: 400,
          width: 300,
        },
        !teamData.inVideoChat ? {
          borderRadius: StyleConstants.curviness,
          overflow: 'hidden',
          marginLeft: 8,
          maxHeight: 400,
        }
          :
          undefined
      ]}>

        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: Theme.DialogBackground,
          height: 50,
          marginHorizontal: 10,
        }}>
          <OTText style={{
            textAlign: 'center',
            paddingLeft: 10,
            flex: 1,
            fontWeight: "600"
          }}>
            Participants
              </OTText>

          <OTTouchableOpacity style={{ width: 30, justifyContent: 'center', alignItems: 'center' }} onPress={this.props.onClose}>
            <Feather name="x" size={16} style={{ color: Theme.HeaderIcon }} />
          </OTTouchableOpacity>
        </View>

        <FlatList
          data={Object.values(room.users || {})}
          renderItem={this.renderItem}
          keyExtractor={user => user.userId}
          style={{ padding: 10 }} />

        {
          teamManager ?
            <View style={{ flexDirection: 'row', backgroundColor: Theme.MenuBackground, padding: 10 }}>

              <OTButton
                small={true}
                title="Mute All"
                onPress={this.muteAll}
              />
              <OTButton
                small={true}
                title="Unmute All"
                onPress={this.unMuteAll}
                outerStyle={{ marginLeft: 5 }}
              />
            </View>
            :
            null
        }

      </View>
    )
  }

  renderItem = ({ item }) => {
    return (
      <VideoCallParticipant user={item} teamId={this.props.teamId} roomId={this.props.roomId} />
    )
  }
}


interface IVideoCallParticipantProps {
  roomId: string
  teamId: string
  user: IOTRoomUser
}

interface IVideoCallParticipantState {
}

@observer
export class VideoCallParticipant extends Component<IVideoCallParticipantProps, IVideoCallParticipantState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  removeUser = () => {
    const { user } = this.props
    const callStateManager = OTGlobals.callStateManager;
      
    confirm(`Are you sure you want to remove ${user.name} from the call?`) &&
      callStateManager?.removeTeamRoomUser(this.props.roomId, user.userId);
  }

  goUserConversation = () => {
    const { user } = this.props
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];

    chatUIState.goDirectChannel(user.userId)

  }

  render() {
    const { user, teamId } = this.props

    logger.debug("user=%o", user);
    
    const teamManager = OTUserData.externalMeeting ? undefined : OTUITree.teamManagers[teamId];
    const teamData = OTGlobals.getTeamData(teamId);

    const callStateManager = OTGlobals.callStateManager;

    var cameraStreamId = callStateManager?.callState.streams[user.userId]?.["camera"];

    var isMuted = cameraStreamId && teamData.streams[cameraStreamId] ? teamData.streams[cameraStreamId].muted : true

    return (
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 3,
      }}>
        <HeadIcon imageUrl={user.imageUrl} name={user.name} size={30} />
        <OTText style={{ paddingHorizontal: 5, flex: 1, fontWeight: '500' }}>
          {user.name}
        </OTText>
        {
          teamManager && user.userId != OTUITree.auth.userId && user.userId in teamData.users ?
            <OTButton
              small={true}
              icon={<SKMessage size={14} style={{ color: Theme.ButtonIconColor }} />}
              title=""
              backgroundColor={Theme.ButtonColor}
              onPress={this.goUserConversation} />
            :
            null
        }
        {
          teamManager && user.userId != OTUITree.auth.userId ?
            <OTButton
              small={true}
              icon={
                isMuted ?
                  <SKMicOff size={14} style={{ color: Theme.ButtonNegativeIconColor }} />
                  :
                  <SKMic size={14} style={{ color: Theme.ButtonIconColor }} />
              }

              outerStyle={{ marginLeft: 5 }}
              title=""
              backgroundColor={isMuted ? Theme.ButtonNegativeColor : Theme.ButtonColor}
              onPress={
                () => callStateManager?.muteUser(user.userId, !isMuted)
              }
            />
            :
            null
        }
        {
          teamManager && user.userId != OTUITree.auth.userId ?
            <OTButton
              small={true}
              outerStyle={{ marginLeft: 5 }}
              title=""
              icon={
                <Feather name="x" size={18} style={{ color: Theme.ButtonIconColor }} />
              }
              backgroundColor={Theme.ButtonNegativeColor}
              onPress={this.removeUser}
            />
            :
            null
        }
      </View>
    )
  }
}
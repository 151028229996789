
import firebase from 'firebase/app';
import 'firebase/auth';
import * as firebaseui from 'firebaseui'
import * as Fire from './Fire';
import { googleConfig } from './config';

export function getFirebaseUiConfig() {
    return {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
          emailLinkSignIn: Fire.getMagicLinkSettings,
        },
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          clientId: googleConfig.clientId
        }
      ],
      credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
      callbacks: {
        signInSuccessWithAuthResult: Fire.signInSuccess
      }
    };
  }
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import * as StyleConstants from '../../globals/StyleConstants';
import { Theme } from '../../globals/ThemeColors';
import { OTGlobals, OTUITree } from '@openteam/app-core';
import { IOTStream  } from '@openteam/models';
import { OTUserData } from '../../data/OTData';
import { OTText } from '../../components/OTText';
import { Menu, MenuOption, MenuOptions, MenuTrigger } from "react-native-popup-menu";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {MdSignalWifi0Bar, MdSignalWifi1Bar, MdSignalWifi2Bar,  MdSignalWifi3Bar, MdSignalWifi4Bar, MdSignalWifiOff} from "react-icons/md";
import { Logger } from '@openteam/app-util';
import ReactTooltip from 'react-tooltip';


const logger = new Logger('SignalStrength')
export interface ISignalStrengthProps {
    teamId: string
    userId: string
    streamId: string
    size: 'S' | 'M' | 'L'
    forceShow?: boolean
}

export interface ISignalStrengthState {
    isMenuOpen: boolean
}

const SIZES = { S: 12, M: 16, L: 20 }

function getIcon(status: string, size: 'S' | 'M' | 'L') {
    let sz = SIZES[size] ?? SIZES.M;

    switch (status) {
        case 'ok':
            return {
                icon: <MdSignalWifi4Bar name="wifi" size={sz} color={Theme.SignalGood} />,
                shadow: Theme.SignalBG,
                tip: "Network Good",
            }
        case 'bandwidth-low':
            return {
                icon: <MdSignalWifi1Bar name="wifi" size={sz} color={Theme.SignalPoor} />,
                shadow: Theme.SignalBG,
                tip: "Low bandwidth"
            }
        case 'disconnect':
            return {
                icon: <MdSignalWifiOff name="wifi" size={sz} color={Theme.SignalBad} />,
                shadow: Theme.SignalBG,
                tip:  "Not connected"
            }
        case 'unstable':
            return {
                icon: <MdSignalWifiOff name="wifi" size={sz} color={Theme.SignalBad} />,
                shadow: Theme.SignalBG,
                tip:  "Network connection unstable"
            }
        case '1bar':
            return {
                icon: <MdSignalWifi1Bar name="wifi" size={sz} color={Theme.Signal1Bar} />,
                shadow: Theme.SignalBG,
                tip: "Network signal poor",
            }
        case '2bar':
            return {
                icon: <MdSignalWifi2Bar name="wifi" size={sz} color={Theme.Signal2Bar} />,
                shadow: Theme.SignalBG,
                tip: "Network ok",
            }
        case '3bar':
            return {
                icon:  <MdSignalWifi3Bar name="wifi" size={sz} color={Theme.Signal3Bar} />,
                shadow: Theme.SignalBG,
                tip: "Network ok",
            }
        default: 
            return {
                icon: <MdSignalWifi4Bar name="wifi" size={sz} color={Theme.SignalGood} />,
                shadow: Theme.SignalBG,
                tip: "Network Good",
            }
    }
}

@observer
export class SignalStrength extends Component<ISignalStrengthProps, ISignalStrengthState> {
    streamType

    constructor(props) {
        super(props)
        this.state = { isMenuOpen: false }
    }

    get teamData  () {
        return OTGlobals.getTeamData(this.props.teamId);
    }

    get streamInfo (): IOTStream {
        return this.teamData.streams[this.props.streamId];
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    _requestStats = () => {
        ReactTooltip.hide()
        this.setState({ isMenuOpen: true});

        if (OTGlobals.callStateManager &&  this.streamInfo) {
            const { streamType } = this.streamInfo;
            this.streamType = streamType
            OTGlobals.callStateManager.requestStats(this.props.userId, this.streamType)
        }
    }

    _cancelStats = () => {
        this.setState({ isMenuOpen: false});
        if (OTGlobals.callStateManager && this.props.userId && this.streamType) {
            OTGlobals.callStateManager.cancelStats(this.props.userId, this.streamType);
        }
    }

    render() {
        const isMe = OTUITree.auth.isMe(this.props.userId);
        const callState = OTGlobals.callStateManager?.callState;
        let icon;
        let show = true;

        //logger.debug(`Rendering Signal stringth isMe ${isMe} CallState Disconnect: ${callState?.networkDisconnected}, bw low: ${callState?.bandwidthLow}, networkStatus ${this.streamInfo?.networkStatus}`)
        
        if (isMe && callState) {
            if (callState.networkDisconnected) {
                icon = getIcon('disconnect', this.props.size);
            } else if (callState.bandwidthLow) {
                icon = getIcon('bandwidth-low', this.props.size);
            } else if (!this.streamInfo) {
                icon =  getIcon('ok', this.props.size);
            }
        }
        
        if (!icon && this.streamInfo) {
            const { networkStatus, score, hasAudio, hasVideo } = this.streamInfo
            if (networkStatus != 'ok') {
                icon =  getIcon(networkStatus, this.props.size);
            } else if (!(hasAudio || hasVideo)) {
                icon =  getIcon('ok', this.props.size);
                show = !hasVideo
            } else {
                if (score > 8) {
                    icon =  getIcon('ok', this.props.size);
                    show = !hasVideo;
                } else if (score > 6) {
                    icon =  getIcon('3bar', this.props.size);
                } else if (score > 4) {
                    icon =  getIcon('2bar', this.props.size);
                } else {
                    icon =  getIcon('1bar', this.props.size);
                }
            }
        } else if (!icon && !this.streamInfo && callState?.connectedUsers.includes(this.props.userId)) {
            icon =  getIcon('ok', this.props.size);
        } else if (!icon && !this.streamInfo) {
            logger.info(`No stream for ${this.props.userId}`)
            icon =  getIcon('disconnect', this.props.size);
        } 
        
        let style = show ? styles.show : styles.hide

        if (this.props.forceShow || this.state.isMenuOpen) {
            style = styles.hover
        }
        
        if (icon) {
            let sz = SIZES[this.props.size]
            return (
                // @ts-ignore
                <View dataSet={{tip: icon.tip}}>
                    <Menu onOpen={this._requestStats} onClose={this._cancelStats} >
                        <MenuTrigger customStyles={{
                            triggerWrapper: {
                                backgroundColor: icon.shadow,
                                padding: Math.ceil(((sz * 1.6) - sz)/2),
                                borderRadius: Math.ceil((sz * 1.6) / 2),
                            },
                            triggerOuterWrapper: style }}>
                            {icon.icon}
                        </MenuTrigger>
                        <MenuOptions
                            customStyles={{
                                optionsContainer: {
                                    width: undefined,
                                    marginTop: 10,
                                    borderRadius: Theme.curviness,
                                    backgroundColor: Theme.DialogBackground,
                                    ...StyleConstants.LightShadow,
                                },
                                optionWrapper: {
                                    paddingVertical: 10
                                }
                            }}>
                            {this.streamInfo?.stats && this.renderStats(this.streamInfo.stats, icon.tip)}
                        </MenuOptions>
                    </Menu>
                </View>
            )
        }
        return null;
    }

    renderStats = (stats, tip) => {
        if (!stats || Object.keys(stats).length == 0) {
            return (
                <View style={styles.popover}>
                    <OTText style={[styles.title, tip ? styles.warn : null]}>
                        {tip ?? "No stats"}
                    </OTText>
                </View>
            )
        } else {
            return (
                <View style={styles.popover}>
                    {tip ? 
                        <View style={{alignItems: 'center'}}>
                            <OTText style={[styles.title, styles.warn]}>{tip}</OTText>
                        </View> : null
                    }
                    {
                        Object.entries(stats).map(
                            ([kind, statsList]) => this.renderKind(kind, statsList)
                        )
                    }
                </View>
            )
        }
    }

    renderKind = (kind: string, statsList) => {
        return (
            <View key={kind} style={styles.stats}>
                <View style={{ alignItems: 'flex-end', marginRight: 5 }}>
                    <OTText style={styles.title}>{kind} :</OTText>
                    {statsList?.[0]?.layer ? <OTText style={styles.data}>layer :</OTText> : null}
                    {statsList?.[0]?.resolution ? <OTText style={styles.data}>resolution :</OTText> : null}
                    <OTText style={styles.data}>score (0-10) :</OTText>
                    <OTText style={styles.data}>jitter :</OTText>
                    <OTText style={styles.data}>bitrate (kb/s) :</OTText>
                    <OTText style={styles.data}>rtt (ms) :</OTText>
                </View>
                {
                    statsList.map(this.renderStat)
                }
            </View>
        )

    }

    renderStat = (stats: any) => {
        if (!stats) {
            return
        }
        let style: any = null;
        if (stats.enabled === false) {
            style = styles.disabled
        }
        return (
            <View key={stats.layer || 'layer'} style={{ alignItems: 'flex-start', marginRight: 5 }}>
                <OTText style={[styles.title, style]}>{stats.codec}</OTText>
                {stats.layer ? <OTText style={[styles.data, style]}>{stats.layer}</OTText> : null}
                {stats.resolution ?
                    <OTText style={[styles.data, style]}>{stats.resolution.height}p</OTText> : null
                }
                <OTText style={[styles.data, style]}>{stats.score}</OTText>
                <OTText style={[styles.data, style]}>{stats.jitter}</OTText>
                <OTText style={[styles.data, style]}>{Math.round(stats.bitrate / 1024)}</OTText>
                <OTText style={[styles.data, style]}>
                    {
                        stats.roundTripTime ? Math.round(stats.roundTripTime) : ''
                    }
                </OTText>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    hover: {
        opacity: 1,
        transition: "opacity 0.3s",
    },
    show: {
        opacity: 0.8,
        transition: "opacity ease-in 2s",
    },
    hide: {
        opacity: 0,
        transition: "opacity 1s ease-out 2s",
    },
    widget: {
        height: 15,
        width: 14,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        opacity: 0.75
    },
    bar: {
        // borderColor: 'white',
        // borderWidth: 1,
        width: 4,
    },
    good: {
        backgroundColor: Theme.MainColour,
    },
    popover: {
        ...StyleConstants.BoxShadow,
        backgroundColor: 'black',
        opacity: 0.9,
        padding: 4,
        borderRadius: Theme.curviness / 2
    },
    stats: {
        flexDirection: 'row',
    },
    title: {
        color: 'white',
        fontSize: 10,
        fontWeight: "bold",
        marginTop: 5
    },
    warn: {
        color: Theme.SignalPoor
    },
    data: {
        color: 'white',
        fontSize: 10,
    },
    disabled: {
        color: 'grey'
    }
})
import React, { Component } from "react";
import { observer } from "mobx-react";
import { View, StyleSheet, Dimensions } from "react-native";
import * as StyleConstants from "../../globals/StyleConstants";
import { Theme } from "../../globals/ThemeColors";
import { OTTouchableOpacity } from "../../components";
import { debounce } from "throttle-debounce";
import { showEditRoom } from "../fragments/settings/RoomSettings";
import { OTUserData, OTAppData } from "../../data/OTData";
import { RoomChatWidget, RoomChat } from "../../components/RoomMsgs";
import ReactTooltip from "react-tooltip";
import { generateGridFocused } from "../../utils/GridCalc";
import { OTButton } from "../../components/buttons/OTButtons";
import Hoverable from "../../components/Hoverable";
import { VideoTile } from "../../components/userTile";
import { isFrameless } from "../../utils/platform";
import { showDeviceSettings } from "../fragments/settings/SettingsContainer";
import { OTEditable, OTText } from "../../components/OTText";
import {
  SKMicOff,
  SKMic,
  SKSettings,
  SKUnlock,
  SKLock,
  SKMessage,
  SKMonitor,
  SKVideo,
} from "../../components/SVGIcons";
import Popover from "react-tiny-popover";
import { BadgeView } from "../../components/BadgeUtil";
import { VideoCallParticipants } from "./VideoCallParticipants";
import { Feather } from "@expo/vector-icons";
import { copyToClipboard } from "../../utils/OTUtils";
import RoomTimer from "../../components/RoomTimer";
import { Droppable } from "../../components/Droppable";
import { PluginContainer, PluginListItem } from "../../components/Plugin";
import { SegmentedControl } from "../../components/SegmentedControl";
import { OTGlobals, OTUITree, MediaDeviceManager, FireDb, windowState } from "@openteam/app-core";
import { IOTPluginTile, IOTTile } from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { getRTDB } from "../../globals/Fire/util";

const logger = new Logger("VideoCall");

interface IVideoCallProps {
  teamId: string;
  roomId: string;
  leaveCall: () => void;
  goToTop?: () => void;
}

interface IVideoCallState {
  showChat: boolean;
  showParticipants: boolean;
  chatText: string;
  windowWidth: number;
  windowHeight: number;
  focusStreamAspectRatio: number;
  quality: string;
}

@observer
export class VideoCall extends Component<IVideoCallProps, IVideoCallState> {
  itemHeight: number = 180
  itemWidth: number =  240
  _requestStreamTimer: number | undefined;

  constructor(props) {
    super(props);

    logger.debug("creating VideoCall");
    this.state = {
      showChat: false,
      showParticipants: false,
      chatText: "",
      windowWidth: Dimensions.get("window").width,
      windowHeight: Dimensions.get("window").height,
      focusStreamAspectRatio: 4 / 3,
      quality: "auto",
    };

    this.props.goToTop?.();
  }

  componentDidMount = () => {
    Dimensions.addEventListener("change", this.getDimensions);
    document.addEventListener("keydown", this.onKeyDown, true);
    document.addEventListener("keyup", this.onKeyup, true);
    this._requestStreams()
    this._requestStreamTimer = setInterval(this._requestStreams, 5000)
  };

  componentDidUpdate = (prevProps) => {
    this._requestStreams()
    ReactTooltip.rebuild();
  };

  componentWillUnmount = () => {
    Dimensions.removeEventListener("change", this.getDimensions);
    document.removeEventListener("keydown", this.onKeyDown, true);
    document.removeEventListener("keyup", this.onKeyup, true);

    this.cancelPushToUnmute();
    clearInterval(this._requestStreamTimer)
  };

  getDimensions = debounce(50, () => {
    const teamData = OTGlobals.getTeamData(this.props.teamId);

    if (teamData.inVideoChat) {
      this.setState({
        windowWidth: Dimensions.get("window").width,
        windowHeight: Dimensions.get("window").height,
      });
    }
  });

  _getGrid = () => {
    const tileAspectRatio = 4 / 3;
    const tileWidthtMin = 160;
    const tileHeightMin = 120;

    const teamData = OTGlobals.getTeamData(this.props.teamId);

    const callStateManager = OTGlobals.callStateManager;

    const { tiles, focusUserId, focusStreamType, focusedPluginId, popoutUser, streams } =
      callStateManager?.callState || {};

    var excludeStreams = new Set();

    var focusAspectRatio: number | undefined = undefined;
    if (focusUserId) {
      excludeStreams.add(`${focusUserId}-${focusStreamType}`);
      focusAspectRatio = this.state.focusStreamAspectRatio;

      let focusStreamId = focusStreamType && streams && streams[focusUserId]?.[focusStreamType];
      if (focusStreamId) {
        const streamInfo = teamData.streams[focusStreamId];
        if (streamInfo && streamInfo.hasVideo) {
          const videoTracks = streamInfo.stream.getVideoTracks();
          if (videoTracks.length > 0) {
            focusAspectRatio = videoTracks[0].getSettings().aspectRatio || focusAspectRatio;
          }
        }
      }
    }

    if (popoutUser) {
      excludeStreams.add(`${OTUITree.auth.userId}-camera`);
    }

    var remainingTiles =
      tiles?.filter((tile) => !excludeStreams.has(`${tile.userId}-${tile.streamType}`)) || [];

    if (focusedPluginId) {
      focusAspectRatio = 16 / 9;

      var plugin = callStateManager?.pluginManager.getPlugin(focusedPluginId);
      if (plugin) {
        var pluginConfig = callStateManager?.pluginManager.getPluginConfig(plugin.pluginType);
        if (pluginConfig?.aspectRatio) {
          focusAspectRatio = pluginConfig.aspectRatio;
        }
      }
    }
    const plugins =
      callStateManager?.pluginManager?.getPluginTiles.filter(
        (plugin) => plugin.pluginId != focusedPluginId
      ) || [];

    var totalTiles = remainingTiles.length + plugins.length;
    var headerHeight = 50;
    var footerHeight = 60;
    var chatWidth = this.state?.showChat || this.state?.showParticipants ? 300 : 0;

    var contentPadding = 10;

    var roundingBuffer = 2;

    var usableHeight =
      Dimensions.get("window").height -
      contentPadding -
      roundingBuffer -
      headerHeight -
      footerHeight;
    var usableWidth = Dimensions.get("window").width - contentPadding - roundingBuffer - chatWidth;

    var grid = generateGridFocused(
      usableWidth,
      usableHeight,
      focusAspectRatio,
      tileWidthtMin,
      tileHeightMin,
      tileAspectRatio,
      totalTiles
    );

    return grid;
  };

  _requestStreams = debounce(100, () => {
    const teamData = OTGlobals.getTeamData(this.props.teamId);
    const callStateManager = OTGlobals.callStateManager;

    //logger.info(`Checking requested streams`);

    if (callStateManager && windowState.windowShown) {
      const { connectedUsers, focusUserId, focusStreamType  } = callStateManager.callState;

      if (this.state.quality == 'hq') {
        callStateManager.setFocusedUsers([...connectedUsers]);
        callStateManager.setSenderResolution(1);
      } else if (this.state.quality == 'lq') {
        callStateManager.setFocusedUsers([]);
        callStateManager.setSenderResolution(0);
      } else {
        callStateManager.setSenderResolution(undefined);
        if (!teamData.inVideoChat) {
          callStateManager.setFocusedUsers([]);
        } else if (this.itemHeight > 180 && connectedUsers.length < 4) {
          callStateManager.setFocusedUsers([...connectedUsers]);
        } else if (focusUserId && focusStreamType === 'camera') {
          callStateManager.setFocusedUsers([focusUserId]);
        } else if (this.itemHeight > 180 && connectedUsers.length < 6) {
          callStateManager.setFocusedUsers([...connectedUsers]);
        } else {
          callStateManager.setFocusedUsers([]);
        }
      }
    }
  })

  toggleShowChat = () => {
    const callStateManager = OTGlobals.callStateManager;

    var showChat = !this.state.showChat;
    this.setState({ showChat: showChat, showParticipants: false });

    callStateManager?.setViewingRoomMsgs(showChat);
  };

  toggleShowParticipants = () => {
    var showParticipants = !this.state.showParticipants;
    this.setState({ showParticipants: showParticipants, showChat: false });
  };

  setQuality = (quality) => {
    this.setState({ quality });
  };

  focusRoom = () => {
    const callStateManager = OTGlobals.callStateManager;

    const newFocusRoom = !callStateManager?.callState.focusRoom;

    callStateManager?.setFocusRoom(newFocusRoom);

    if (!newFocusRoom && OTAppData.isFullscreen) {
      this.toggleIsFullScreen();
    }
    this.getDimensions();
  };

  toggleIsFullScreen = () => {
    OTAppData.isFullscreen = !OTAppData.isFullscreen;
  };

  focusStream = (userId, streamType) => {
    const callStateManager = OTGlobals.callStateManager;

    if (!callStateManager) {
      return;
    }

    if (
      callStateManager.callState.focusRoom &&
      userId == callStateManager.callState.focusUserId &&
      streamType == callStateManager.callState.focusStreamType
    ) {
      userId = streamType = undefined;
    }

    callStateManager.setFocusStream(userId, streamType);
    this.getDimensions();
  };

  setFocusSize = (width, height) => {
    this.setState({ focusStreamAspectRatio: width / height });
  };

  leaveCall = () => {
    logger.info(`Leaving call for room ${this.props.teamId}:${this.props.roomId}`)
    
    this.props.leaveCall()

    if (OTAppData.isFullscreen) {
      this.toggleIsFullScreen();
    }
  };

  onKeyDown = (e) => {
    this.onKeyPressed(e, true);
  };
  onKeyup = (e) => {
    this.onKeyPressed(e, false);
  };

  onKeyPressed = (e, isDown: boolean) => {
    const callStateManager = OTGlobals.callStateManager;

    if (
      callStateManager &&
      e.code == "Space" &&
      e.target.nodeName != "INPUT" &&
      e.target.nodeName != "TEXTAREA"
    ) {
      callStateManager?.setPushToUnmute(isDown);
      e.stopPropagation && e.stopPropagation();
      e.preventDefault();
    }
  };

  cancelPushToUnmute = () => {
    const callStateManager = OTGlobals.callStateManager;
    callStateManager?.setPushToUnmute(false);
  };

  showSettings = () => {
    showDeviceSettings(this.props.teamId);
  };

  showEditRoom = () => {
    showEditRoom(this.props.teamId, this.props.roomId);
  };

  onDrop = (e) => {
    const teamData = OTGlobals.getTeamData(this.props.teamId);
    const room = teamData.rooms[this.props.roomId];
    const callRequestUIState = OTUITree.callRequestUIStates[this.props.teamId];

    var userId = e.dataTransfer.getData("userid");

    if (userId && !room.users[userId]) {
      callRequestUIState.sendCallUser(userId);
    }
  };

  isInteresting = (e) => {
    const userId = e.dataTransfer.types && e.dataTransfer.types.indexOf("userid") != -1;

    logger.debug("isInteresting userId", e.dataTransfer.types, userId)
    return userId;
  };

  render() {
    const { teamId } = this.props;
    const teamData = OTGlobals.getTeamData(teamId);

    const backgroundColor = teamData.inVideoChat
      ? Theme.FullVideoCallBackground
      : Theme.VideoCallBackground;

    return (
      <View style={[styles.room, teamData.inVideoChat && styles.roomFull, { backgroundColor }]}>
        <View
          style={{
            flex: 1,
          }}
        >
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderFooter()}
          {this.renderMinimised()}
        </View>
        {this.state.showChat ? (
          <RoomChat
            teamId={this.props.teamId}
            toggleShow={this.toggleShowChat}
            showChat={this.state.showChat}
            outerStyle={
              !teamData.inVideoChat
                ? {
                    borderRadius: Theme.curviness,
                    overflow: "hidden",
                    marginLeft: 8,
                    maxHeight: 400,
                    width: 300,
                    flex: undefined,
                  }
                : {
                    width: 300,
                    flex: undefined,
                  }
            }
          />
        ) : null}
        {this.state.showParticipants ? this.renderParticipants() : null}
        <Droppable
          isInteresting={this.isInteresting}
          renderCover={this.renderCover}
          onDrop={this.onDrop}
        />
      </View>
    );
  }

  renderCover = (overFrame, overTarget) => {
    return overFrame ? (
      <View
        pointerEvents={"none"}
        style={{
          ...StyleSheet.absoluteFillObject,
          backgroundColor: "rgba(255,255,255,0.5)",
          borderWidth: overTarget ? 5 : 1,
          borderColor: Theme.MainColour,
          borderStyle: overTarget ? "solid" : "dashed",
          borderRadius: Theme.curviness,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {overTarget ? (
          <View style={{ alignItems: "center" }}>
            <SKVideo size={32} style={{ color: overTarget ? Theme.MainColour : "grey" }} />
            <OTText style={{ fontSize: 22, fontWeight: "500", color: Theme.MainColour }}>
              Invite User
            </OTText>
          </View>
        ) : null}
      </View>
    ) : null;
  };

  renderParticipants = () => {
    return (
      <VideoCallParticipants
        teamId={this.props.teamId}
        roomId={this.props.roomId}
        onClose={this.toggleShowParticipants}
      />
    );
  };
  renderHeader = () => {
    const { teamId, roomId } = this.props;

    const teamData = OTGlobals.getTeamData(teamId);

    const room = teamData.rooms[roomId];
    const meetingUIState = OTUITree.meetingUIStates[this.props.teamId];

    if (!room) {
      return;
    }
    const teamManager = OTUserData.externalMeeting
      ? undefined
      : OTUITree.teamManagers[this.props.teamId];
    const settingsEnabled = teamManager && (teamData.isAdmin || !room.config?.static);

    var iconSize = teamData.inVideoChat ? 18 : 14;

    return (
      <View
        style={[
          styles.headerRow,
          teamData.inVideoChat && isFrameless ? { paddingLeft: 80 } : null,
          teamData.inVideoChat && styles.headerFull,
          teamData.inVideoChat && { backgroundColor: Theme.FullVideoHeaderBackground },
        ]}
      >
        {/* {
          teamData.inVideoChat && videoChatManager ?
            <OTButton
              toolTip="Back to team"
              small={true}
              onPress={this.focusRoom}
              title={"Back to team"}
              icon={<FiArrowLeft size={14} style={{ color: Theme.ButtonColor, marginRight: 5 }} />}
              backgroundColor={"transparent"}
              textColor={Theme.ButtonColor}
              borderColor={Theme.ButtonColor}
              outerStyle={{ marginRight: 10}}
            />
            :
            null
        } */}
        <OTEditable
          style={[styles.title, teamData.inVideoChat && { fontSize: 18 }]}
          value={room.config?.name}
          disabled={!settingsEnabled}
          onEdited={(name) =>
            FireDb.updateRoomConfig(getRTDB(), this.props.teamId, this.props.roomId, { name })
          }
        />
        {settingsEnabled ? (
          <OTTouchableOpacity
            data-tip="Room Settings"
            disabled={!settingsEnabled}
            onPress={settingsEnabled ? this.showEditRoom : undefined}
            style={{ marginRight: 10 }}
            analyticsEvent="room_settings_open"
          >
            <SKSettings size={16} style={{ color: Theme.MainColour }} />
          </OTTouchableOpacity>
        ) : null}

        <RoomTimer teamId={this.props.teamId} roomId={this.props.roomId} />

        <View style={{ flex: 1 }} />

        {__DEV__ || window?.location?.host.startsWith("openteam-dev") ? (
          <View style={{ marginRight: 5 }}>
            <SegmentedControl
              segments={{ lq: "LQ", auto: "Auto", hq: "HQ" }}
              onChange={this.setQuality}
              selectedSegment={this.state.quality}
            />
          </View>
        ) : null}
        {
          <OTTouchableOpacity
            data-tip="Participants"
            onPress={this.toggleShowParticipants}
            style={[styles.headerButton, { backgroundColor: Theme.ButtonIconColor }]}
          >
            <Feather name="users" size={iconSize} style={{ color: Theme.ButtonColor }} />
          </OTTouchableOpacity>
        }

        {teamManager && teamData.inVideoChat && teamData.capabilities.externalMeetings ? (
          <OTTouchableOpacity
            data-tip="Meeting Requests"
            onPress={meetingUIState.showModal}
            style={[styles.headerButton, { backgroundColor: Theme.ButtonIconColor }]}
          >
            <Feather name="calendar" size={iconSize} style={{ color: Theme.ButtonColor }} />
            <BadgeView badgeNum={Object.keys(meetingUIState.addressDoc?.waiting || {}).length} />
          </OTTouchableOpacity>
        ) : null}

        {teamManager ? (
          <OTTouchableOpacity
            data-tip={teamData.inVideoChat ? "Collapse" : "Expand"}
            onPress={this.focusRoom}
            style={[styles.headerButton, { backgroundColor: Theme.ButtonColor }]}
          >
            {teamData.inVideoChat ? (
              <Feather name="minimize-2" size={iconSize} style={{ color: Theme.ButtonIconColor }} />
            ) : (
              <Feather name="maximize-2" size={iconSize} style={{ color: Theme.ButtonIconColor }} />
            )}
          </OTTouchableOpacity>
        ) : null}

        {teamData.inVideoChat ? (
          <OTTouchableOpacity
            data-tip={OTAppData.isFullscreen ? "Exit Fullscreen" : "Make fullscreen"}
            onPress={this.toggleIsFullScreen}
            style={[styles.headerButton, { backgroundColor: Theme.ButtonIconColor }]}
          >
            {OTAppData.isFullscreen ? (
              <Feather name="minimize" size={iconSize} style={{ color: Theme.ButtonColor }} />
            ) : (
              <Feather name="maximize" size={iconSize} style={{ color: Theme.ButtonColor }} />
            )}
          </OTTouchableOpacity>
        ) : null}

        <OTTouchableOpacity
          data-tip="Device Settings"
          onPress={this.showSettings}
          style={[styles.headerButton, { backgroundColor: Theme.ButtonIconColor }]}
        >
          <Feather name="sliders" size={iconSize} style={{ color: Theme.ButtonColor }} />
        </OTTouchableOpacity>
        {/*
        {
          room.config?.call && !room.config.static && videoChatManager?.toggleRoomLock ?
            <OTTouchableOpacity onPress={() => videoChatManager?.toggleRoomLock(roomId)} style={[styles.headerButton, { backgroundColor: Theme.ButtonIconColor }]}>
              {
                room.config?.isLocked ?
                  <SKLock size={iconSize} style={{ color: Theme.ButtonColor }} />
                  :
                  <SKUnlock size={iconSize} style={{ color: Theme.ButtonColor }} />
              }
            </OTTouchableOpacity>
            :
            null
        } */}

        <OTButton
          toolTip="Leave Room"
          onPress={this.leaveCall}
          small={true}
          title={"Leave Room"}
          icon={
            <Feather
              name="log-out"
              size={14}
              style={{ color: Theme.ButtonNegativeColor, marginRight: 5 }}
            />
          }
          backgroundColor={"transparent"}
          textColor={Theme.ButtonNegativeColor}
          borderColor={Theme.ButtonNegativeColor}
        />
      </View>
    );
  };

  renderContent = () => {
    const { teamId, roomId } = this.props;
    const teamData = OTGlobals.getTeamData(teamId);

    var focusedWidth = 240;
    var focusedHeight = 180;
    var itemWidth = 240;
    var itemHeight = 180;
    var verticalGrid = false;

    if (teamData.inVideoChat) {
      const grid = this._getGrid()
      logger.debug("getDimensions grid", grid)

      focusedWidth = grid.width
      focusedHeight = grid.height
      itemWidth = grid.itemWidth
      itemHeight = grid.itemHeight
      verticalGrid = grid.verticalGrid
    }

    this.itemWidth = itemWidth;
    this.itemHeight = itemHeight;

    const callStateManager = OTGlobals.callStateManager;
    const room = teamData.rooms[roomId];

    if (!callStateManager  || !room) {
      return;
    }

    const {
      streams,
      popoutUser,
      focusUserId,
      focusStreamType,
      focusedPluginId,
      tiles,
      networkDisconnected,
      bandwidthLow
    } = callStateManager.callState;


    const plugins: IOTPluginTile[] = callStateManager.pluginManager?.getPluginTiles.map(
      (plugin) => ({ tileType: "plugin", pluginId: plugin.pluginId })
    );

    var seenTiles = new Set();

    if (popoutUser && teamData.inVideoChat) {
      seenTiles.add(`stream-${OTUITree.auth.userId}-camera`);
    }

    const roomUsers = room.users;

    var genericTiles: (IOTTile | IOTPluginTile)[] = [
      ...callStateManager.callState.orderedTiles,
      ...plugins,
      ...tiles,
    ];

    var renderTiles: (IOTTile | IOTPluginTile)[] = [];
    genericTiles.forEach((tile) => {
      var exists = true;
      if (tile.tileType == "plugin") {
        var key = `plugin-${tile.pluginId}`;
        exists =
          callStateManager.pluginManager.plugins[tile.pluginId] && tile.pluginId == focusedPluginId;
      } else {
        var key = `stream-${tile.userId}-${tile.streamType}`;

        var isOnline = false;
        if (roomUsers[tile.userId]) {
          isOnline = roomUsers[tile.userId].online || roomUsers[tile.userId].inLeeway;
        }

        exists =
          isOnline &&
          (tile.streamType == "camera" || streams[tile.userId]?.[tile.streamType] != undefined);
      }

      if (!seenTiles.has(key) && exists) {
        seenTiles.add(key);
        renderTiles.push(tile);
      }
    });

    const networkMessage = (
      networkDisconnected ? "Your network is connection is unstable" : null
    );

    return (
      <View
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
          padding: 5,
          flexWrap: "wrap",
          flexDirection: verticalGrid ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {renderTiles.map((tile) => {
          if (tile.tileType == "plugin") {
            var focused = tile.pluginId == focusedPluginId && teamData.inVideoChat;
            return this.renderPlugin(
              tile.pluginId,
              focused ? focusedWidth : itemWidth,
              focused ? focusedHeight : itemHeight,
              focused
            );
          } else {
            var focused =
              tile.userId == focusUserId &&
              tile.streamType == focusStreamType &&
              teamData.inVideoChat;
            return this.renderUser(
              tile.userId,
              tile.streamType,
              streams[tile.userId]?.[tile.streamType],
              focused ? focusedWidth : itemWidth,
              focused ? focusedHeight : itemHeight,
              focused
            );
          }
        })}
        {renderTiles.length == 0 ? (
          <OTText style={{ color: StyleConstants.TextGrey }}>No one else is in this room</OTText>
        ) : null}

        { 
          /*
          networkMessage ? (
            <View style={{position: 'absolute', alignItems: 'center', justifyContent: 'center'}}>
              <View style={styles.networkErrorBox}>
                <OTText style={styles.networkErrorMessage}>{networkMessage}</OTText>
              </View>
            </View>
          ) : null
          */
        }
      </View>
    );
  };

  renderUser = (
    userId,
    streamType,
    streamId,
    itemWidth: number,
    itemHeight: number,
    focused = false
  ) => {
    const { teamId, roomId } = this.props;

    const teamData = OTGlobals.getTeamData(teamId);
    const callStateManager = OTGlobals.callStateManager;

    const room = teamData.rooms[this.props.roomId];

    const roomUser = room.users[userId];

    if (!callStateManager || !roomUser) {
      return;
    }
    const { connectedUsers } = callStateManager.callState;

    const userKey = `${userId}-${streamType}`;

    return (
      <View key={userKey} style={{ marginHorizontal: 5, marginVertical: 5 }}>
        <VideoTile
          key={userKey}
          teamId={teamId}
          userId={userId}
          teamUser={roomUser}
          itemWidth={itemWidth}
          itemHeight={itemHeight}
          roomId={roomId}
          streamId={streamId}
          connected={connectedUsers.includes(userId)}
          enableMenu={teamData.inVideoChat}
          focused={focused}
          onSize={focused ? this.setFocusSize : undefined}
          onPress={() => this.focusStream(userId, streamType)}
        />
      </View>
    );
  };

  renderPlugin = (pluginId, width, height, focused = false) => {
    return (
      <PluginContainer
        key={pluginId}
        teamId={this.props.teamId}
        pluginId={pluginId}
        width={width}
        height={height}
        focused={focused}
      />
    );
  };

  renderMinimised = () => {
    const { teamId, roomId } = this.props;

    const teamData = OTGlobals.getTeamData(teamId);

    const room = teamData.rooms[this.props.roomId];
    const userId = OTUITree.auth.userId;
    const callStateManager = OTGlobals.callStateManager;

    if (!callStateManager || !room || !room.users[userId]) {
      return;
    }
    const { popoutUser, streams, connectedUsers } = callStateManager.callState;

    var streamType = "camera";

    let streamId = streams && streams[userId]?.[streamType];

    if (!popoutUser || !teamData.inVideoChat) {
      return;
    }

    return (
      <View
        style={{
          position: "absolute",
          bottom: 10,
          right: 10,
        }}
      >
        <View key={streamId} style={{ margin: 5 }}>
          <VideoTile
            key={streamId}
            teamId={teamId}
            userId={userId}
            teamUser={room.users[userId]}
            itemWidth={160}
            itemHeight={120}
            roomId={roomId}
            streamId={streamId}
            matchAspectRatio={true}
            connected={connectedUsers.includes(userId)}
            pinned={false}
            enableMenu={false}
            onPress={callStateManager?.togglePopoutMe}
          />
        </View>
      </View>
    );
  };

  renderFooter = () => {
    const { teamId, roomId } = this.props;
    const teamManager = OTUserData.externalMeeting
      ? undefined
      : OTUITree.teamManagers[this.props.teamId];
    const teamData = OTGlobals.getTeamData(this.props.teamId);
    const room = teamData.rooms[roomId];

    const callStateManager = OTGlobals.callStateManager;
    const pluginManager = callStateManager?.pluginManager;

    const iconSize = teamData.inVideoChat ? 26 : 16;
    const buttonIconColor = teamData.inVideoChat ? Theme.ButtonColor : Theme.ButtonIconColor;
    const pluginConfigList = OTGlobals.pluginConfigList;

    if (!room || !callStateManager) {
      return;
    }

    return (
      <View style={[styles.footerRow, teamData.inVideoChat && styles.footerFull]}>
        <FooterButton
          teamId={teamId}
          title={teamData.callAudioOn ? "Mute" : "Unmute"}
          onPress={callStateManager?.toggleAudio}
          isOn={teamData.callAudioOn}
          colorButton={true}
          disabled={!MediaDeviceManager.hasAudio}
          buttonOnIcon={<SKMic size={iconSize} style={{ color: buttonIconColor }} />}
          buttonOffIcon={
            <SKMicOff
              size={iconSize}
              style={{ color: MediaDeviceManager.hasAudio ? "white" : "grey" }}
            />
          }
        />

        <FooterButton
          teamId={teamId}
          title={teamData.callVideoOn ? "Turn off video" : "Turn on video"}
          onPress={callStateManager?.toggleVideo}
          isOn={teamData.callVideoOn}
          colorButton={true}
          disabled={!MediaDeviceManager.hasVideo}
          buttonOnIcon={<SKVideo size={iconSize} style={{ color: buttonIconColor }} />}
          buttonOffIcon={
            <SKVideo
              size={iconSize}
              style={{ color: MediaDeviceManager.hasAudio ? "white" : "grey" }}
              fill={MediaDeviceManager.hasVideo ? "white" : "grey"}
            />
          }
        />

        <FooterButton
          teamId={teamId}
          title={teamData.callScreenshareOn ? "Stop" : "Share"}
          onPress={callStateManager?.toggleScreenShare}
          isOn={!teamData.callScreenshareOn}
          colorButton={false}
          disabled={false}
          buttonOnIcon={<SKMonitor size={iconSize} style={{ color: buttonIconColor }} />}
          buttonOffIcon={<SKMonitor size={iconSize} style={{ color: Theme.ButtonNegativeColor }} />}
          mouseOverMenu={this.renderShareMenu}
        />

        <FooterButton
          teamId={teamId}
          title={this.state.showChat ? "Hide chat" : "Show chat"}
          onPress={this.toggleShowChat}
          isOn={this.state.showChat}
          colorButton={false}
          disabled={false}
          buttonOnIcon={
            <View>
              <SKMessage size={iconSize - 3} style={{ color: buttonIconColor }} />
              <BadgeView badgeNum={callStateManager.callState.unreadRoomMsgs} />
            </View>
          }
          mouseOverMenu={this.renderChatMenu}
        />
        {room.config?.call && !room.config.static && teamManager?.toggleRoomLock ? (
          <FooterButton
            teamId={teamId}
            title={room.config?.isLocked == true ? "Unlock" : "Lock"}
            onPress={() => teamManager?.toggleRoomLock(roomId)}
            isOn={room.config?.isLocked == true}
            colorButton={false}
            disabled={false}
            buttonOnIcon={<SKLock size={iconSize} style={{ color: buttonIconColor }} />}
            buttonOffIcon={<SKUnlock size={iconSize} style={{ color: buttonIconColor }} />}
          />
        ) : null}

        {Object.keys(pluginManager?.plugins || {}).length ? (
          <View
            style={{ width: 1, height: "100%", backgroundColor: "white", marginHorizontal: 10 }}
          />
        ) : null}
        {pluginManager &&
          Object.keys(pluginManager?.plugins || {}).map((pluginId) => {
            const pluginDetails = pluginManager.plugins[pluginId];
            var pluginConfig = pluginConfigList[pluginDetails.pluginType];

            var isFocused = pluginManager.focusedPluginId == pluginId;
            var isPoppedOut = pluginManager.poppedoutPlugins[pluginId] != undefined;

            return (
              <FooterButton
                key={pluginId}
                teamId={teamId}
                title={`${pluginConfig.name} - ${pluginDetails.args?.title}`}
                onPress={() => pluginManager.setFocusPlugin(pluginId)}
                isOn={true}
                colorButton={false}
                mouseOverMenu={() => this.renderPluginMenu(pluginId)}
                defaultButtonColor={
                  isFocused ? Theme.PluginBackground : isPoppedOut ? "transparent" : "white"
                }
                borderColor={isFocused ? undefined : isPoppedOut ? "white" : undefined}
                disabled={false}
                buttonOnIcon={
                  <pluginConfig.icon size={iconSize} style={{ color: pluginConfig.iconColour }} />
                }
              />
            );
          })}
      </View>
    );
  };

  renderShareMenu = (closeMenu: () => void) => {
    const { teamId } = this.props;

    const teamData = OTGlobals.getTeamData(teamId);

    const callStateManager = OTGlobals.callStateManager;

    const pluginConfigList = OTGlobals.pluginConfigList;

    if (!callStateManager) {
      return <View />;
    }
    const pluginManager = callStateManager.pluginManager;
    return (
      <View style={{ width: 300 }}>
        <View
          style={{
            height: 40,
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 20,
          }}
        >
          <OTText style={{ fontWeight: "bold", fontSize: 16 }}>Apps</OTText>
        </View>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {Object.keys(pluginConfigList).map((pluginType) => (
            <PluginListItem
              key={pluginType}
              pluginType={pluginType}
              pluginManager={callStateManager?.pluginManager}
              onClose={() => closeMenu()}
            />
          ))}
        </View>

        <View
          style={{
            height: 40,
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 20,
          }}
        >
          <OTText style={{ fontWeight: "bold", fontSize: 16 }}>Recent</OTText>
        </View>
        <View style={{}}>
          {pluginManager.recentPlugins.map((plugin) => {
            let pluginConfig = pluginConfigList[plugin.pluginType];

            if (!pluginConfig) {
              return;
            }
            return (
              <OTTouchableOpacity
                key={plugin.pluginId}
                onPress={() => {
                  pluginManager.restoreHistory(plugin.pluginId);
                  closeMenu();
                }}
                style={{
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  marginHorizontal: 10,
                }}
              >
                <pluginConfig.icon size={18} style={{ color: pluginConfig.iconColour }} />
                <OTText
                  numberOfLines={1}
                  style={{ marginLeft: 5, color: Theme.DarkText, padding: 5 }}
                >
                  {pluginConfig.name} {plugin.args.title}
                </OTText>
              </OTTouchableOpacity>
            );
          })}
        </View>

        <View style={{ borderBottomWidth: 1, borderColor: StyleConstants.BorderColour }}></View>
        <OTTouchableOpacity
          onPress={() => {
            callStateManager?.toggleScreenShare();
            closeMenu();
          }}
          style={{
            height: 45,
            justifyContent: "center",
            paddingHorizontal: 20,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <SKMonitor size={20} style={{ color: Theme.MainText }} />
          <OTText style={{ marginLeft: 5, fontWeight: "bold" }}>
            {teamData.callScreenshareOn ? "Stop Screen Share" : "Share Screen"}
          </OTText>
        </OTTouchableOpacity>
      </View>
    );
  };

  renderChatMenu = (closeMenu: () => void) => {
    return (
      <View style={{ minWidth: 200 }}>
        <RoomChatWidget
          key={"roomChatWidget"}
          chatText={this.state.chatText}
          onChangeText={(text) => this.setState({ chatText: text })}
          teamId={this.props.teamId}
        />
      </View>
    );
  };

  renderPluginMenu = (pluginId) => {
    const callStateManager = OTGlobals.callStateManager;
    const pluginManager = callStateManager?.pluginManager;
    const pluginConfigList = OTGlobals.pluginConfigList;

    if (!pluginManager) {
      return <View />;
    }

    const pluginDetails = pluginManager.plugins[pluginId];
    var pluginConfig = pluginConfigList[pluginDetails.pluginType];

    return (
      <View style={{ minWidth: 150 }}>
        <View
          style={{
            padding: 10,
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 20,
          }}
        >
          <OTText style={{ fontWeight: "bold", color: Theme.DarkText }}>
            {pluginConfig.name} {pluginDetails.args?.title}
          </OTText>
        </View>

        <View style={{ borderBottomWidth: 1, borderColor: StyleConstants.BorderColour }}></View>

        {pluginConfig.canPopout ? (
          pluginManager.poppedoutPlugins[pluginId] ? (
            <OTTouchableOpacity
              onPress={() => pluginManager.unPopoutPlugin(pluginId)}
              style={{
                height: 45,
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: 20,
              }}
            >
              <OTText style={{ fontWeight: "600", color: Theme.DarkText }}>Pop in</OTText>
            </OTTouchableOpacity>
          ) : (
            <OTTouchableOpacity
              onPress={() => pluginManager.popoutPlugin(pluginId)}
              style={{
                height: 45,
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: 20,
              }}
            >
              <OTText style={{ fontWeight: "600", color: Theme.DarkText }}>Pop out</OTText>
            </OTTouchableOpacity>
          )
        ) : null}
        {pluginManager.focusedPluginId == pluginId ? (
          <OTTouchableOpacity
            onPress={() => pluginManager.setFocusPlugin(pluginId)}
            style={{
              height: 45,
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: 20,
            }}
          >
            <OTText style={{ fontWeight: "600", color: Theme.DarkText }}>Minimise</OTText>
          </OTTouchableOpacity>
        ) : (
          <OTTouchableOpacity
            onPress={() => pluginManager.setFocusPlugin(pluginId)}
            style={{
              height: 45,
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: 20,
            }}
          >
            <OTText style={{ fontWeight: "600", color: Theme.DarkText }}>Open</OTText>
          </OTTouchableOpacity>
        )}
        {pluginDetails.args.url ? (
          <OTTouchableOpacity
            onPress={() => copyToClipboard(pluginDetails.args.url)}
            style={{
              height: 45,
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: 20,
            }}
          >
            <OTText style={{ fontWeight: "600", color: Theme.DarkText }}>Copy Link</OTText>
          </OTTouchableOpacity>
        ) : null}

        <View style={{ borderBottomWidth: 1, borderColor: StyleConstants.BorderColour }}></View>
        <OTTouchableOpacity
          onPress={() => pluginManager.closePlugin(pluginId)}
          style={{
            height: 45,
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 20,
          }}
        >
          <OTText style={{ fontWeight: "600", color: Theme.ButtonNegativeColor }}>
            Close for everyone
          </OTText>
        </OTTouchableOpacity>
      </View>
    );
  };
}

interface IFooterButtonProps {
  teamId: string;
  title: string;
  onPress: () => void;
  isOn: boolean;
  colorButton: boolean;
  defaultButtonColor?: string;
  borderColor?: string;
  disabled: boolean;
  buttonOnIcon: any;
  buttonOffIcon?: any;
  mouseOverMenu?: (closeMenu: () => void) => JSX.Element;
}

interface IFooterButtonState {
  showContext: boolean;
}

class FooterButton extends Component<IFooterButtonProps, IFooterButtonState> {
  constructor(props) {
    super(props);
    this.state = {
      showContext: false,
    };
  }

  closeMenu = () => {
    logger.debug("Closing menu...");
    this.setState({ showContext: false });
  };

  toggleContextMenu = (e?) => {
    this.setState({ showContext: !this.state.showContext });
  };

  renderContextMenu = () => {
    return (
      <View
        style={{
          margin: 5,
        }}
      >
        <View
          style={{
            borderRadius: Theme.curviness,
            backgroundColor: Theme.DialogBackground,

            ...StyleConstants.LightShadow,
          }}
        >
          {this.props.mouseOverMenu && this.props.mouseOverMenu(this.closeMenu)}
        </View>
      </View>
    );
  };

  onLongPress = (e) => {
    this.toggleContextMenu();
  };

  render() {
    const {
      teamId,
      title,
      onPress,
      disabled,
      isOn,
      borderColor,
      colorButton,
      defaultButtonColor,
      buttonOnIcon,
      buttonOffIcon,
      mouseOverMenu,
    } = this.props;

    const teamData = OTGlobals.getTeamData(teamId);

    var size = 30;
    var curviness = Theme.curviness / 2;
    var margin = 6;

    if (teamData.inVideoChat) {
      size = 50;
      margin = 10;
      curviness = Theme.curviness;
    }

    var buttonColor = defaultButtonColor
      ? defaultButtonColor
      : teamData.inVideoChat
      ? Theme.ButtonIconColor
      : Theme.ButtonColor;

    return (
      <Hoverable
        onHoverIn={() => this.setState({ showContext: true })}
        onHoverOut={() => this.setState({ showContext: false })}
      >
        {(isHovered) => (
          <View style={{ alignItems: "center", marginHorizontal: margin }}>
            <Popover
              isOpen={mouseOverMenu != undefined && this.state.showContext}
              windowBorderPadding={20}
              position={"top"} // preferred position
              content={this.renderContextMenu}
              onClickOutside={this.toggleContextMenu}
            >
              <OTTouchableOpacity
                data-tip={title}
                onPress={onPress}
                onLongPress={this.props.mouseOverMenu && this.onLongPress}
                disabled={disabled}
                style={{
                  height: size,
                  width: size,
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor:
                    isOn || !colorButton ? buttonColor : StyleConstants.NegativeColour,
                  borderRadius: curviness,
                  borderWidth: borderColor ? 2 : undefined,
                  borderColor: borderColor,
                }}
              >
                {isOn ? buttonOnIcon : buttonOffIcon || buttonOnIcon}
              </OTTouchableOpacity>
            </Popover>
          </View>
        )}
      </Hoverable>
    );
  }
}

const styles = StyleSheet.create({
  room: {
    flexDirection: "row",
    maxWidth: "100%",
    borderRadius: Theme.curviness * 1.5,
    transitionProperty: Theme.TransitionProperty,
    transitionDuration: Theme.TransitionDuration,
    padding: 8,
    marginBottom: 10,
    marginHorizontal: 10,
  },
  roomFull: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
    marginHorizontal: 0,
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    borderRadius: undefined,
  } as any,
  title: {
    fontWeight: "600",
    fontSize: 18,
    paddingRight: 10,
  },
  headerRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerFull: {
    height: 50,
    paddingHorizontal: 19,
  },
  headerButton: {
    height: 25,
    width: 25,
    borderRadius: Theme.curviness / 2,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 12,
  },
  footerRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  footerFull: {
    height: 60,
    paddingBottom: 10,
  },
  networkErrorMessage: {
    fontWeight: "700",
    fontSize: 18,
    color: "white"
  },
  networkErrorBox: {
    paddingVertical: 18,
    paddingHorizontal: 32,
    borderRadius: Theme.curviness,
    backgroundColor: "rgba(69, 69, 69, 0.8)",
  }
});

import React, { PureComponent } from 'react';
import {
  StyleProp,
  TextInput, TextInputProps, ViewStyle
} from 'react-native';
import { PlatformOS } from '../utils/platform';

interface IAutoGrowInputProps extends TextInputProps {
  style: StyleProp<ViewStyle>
}
interface IAutoGrowInputState {
  height?: number
}

export default class AutogrowInput extends PureComponent<IAutoGrowInputProps, IAutoGrowInputState> {
  inputRef = React.createRef<TextInput>()
  defaultHeight = 35
  constructor(props) {
    super(props)

    this.state = {
    };
  
  }

  componentDidMount() {
  }

  componentDidUpdate = (prevProps) => {
    if (!this.props.value && prevProps.value) {
      this.setState({
        height: undefined,
      });
    }
  }

  onContentSizeChange = (event) => {
    if (this.state.height !== event.nativeEvent.contentSize.height) {
      this.setState({
        height: Math.min(event.nativeEvent.contentSize.height + 1, 90),
      });
    } 
  }

  focus = () => {
    this.inputRef.current?.focus();
  }

  onKeyPress = (e) => {
    if (this.props.multiline && this.props.onSubmitEditing) {
      if(e.keyCode == 13 && e.shiftKey == false) {
        e.preventDefault();
        this.props.onSubmitEditing(e)
      }
    }
  }

  render() {
    const {style, onSubmitEditing, ...otherProps} = this.props;


    if (PlatformOS == 'mobile') {
      return (
        <TextInput
          ref={this.inputRef}
          {...otherProps}
          style={[style]}
        />
        );
    }
    return (
      <TextInput
        ref={this.inputRef}
        {...otherProps}
        onKeyPress={this.onKeyPress}
        style={[
          {height: this.defaultHeight}, 
          style, 
          this.state.height ? { 
            height: this.state.height, 
          } 
          : undefined]}
        onContentSizeChange={this.onContentSizeChange}
      />
      );
  }
}


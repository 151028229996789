import { observer } from "mobx-react";
import React, { Component } from 'react';
import { ListRenderItemInfo } from 'react-native';
import { FlatList, Image, View, StyleSheet } from 'react-native';
import validate from 'validate.js';
import * as Fire from '../../globals/Fire';
import * as Analytics from '../../utils/Analytics';
import { EmailChipInput } from '../../components/EmailChipInput';
import { IUserTeamReq } from '@openteam/models';
import { OTButton } from '../../components/buttons/OTButtons';
import { OTTouchableOpacity } from '../../components';
import { getTimeAgo } from '../../utils/OTUtils';
import * as StyleConstants from '../../globals/StyleConstants';
import HeadIcon from '../../components/HeadIcon';
import { OTPopover } from "../../components/OTPopover";
import { Theme } from "../../globals/ThemeColors";
import { OTText, OTTextInput } from "../../components/OTText";
import { Feather } from '@expo/vector-icons';
import { OTGlobals, OTUITree } from "@openteam/app-core";


interface ITeamAccessReqsButtonProps {
  teamId: string
}

interface ITeamAccessReqsButtonState {
}

@observer
export class TeamAccessReqsButton extends Component<ITeamAccessReqsButtonProps, ITeamAccessReqsButtonState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }


  render() {
    const teamAccessReqUI = OTUITree.teamAccessReqUI[this.props.teamId];

    return (
      <OTPopover
        title="Team Invites"
        isOpen={teamAccessReqUI.showReqs}
        toggleIsOpen={teamAccessReqUI.toggleShowReqs}
        renderOpen={this.renderAccessReqs}
        icon={<Feather name="user-plus" size={22} />}
        iconText="Invite"
        badgeNum={Object.keys(teamAccessReqUI.accessReqs || {}).length}
      />
    );

  }
  renderAccessReqs = () => {
    return (
      <TeamAccessReqs teamId={this.props.teamId} />
    )
  }
}



interface ITeamAccessReqsProps {
  teamId: string
}

interface ITeamAccessReqsState {
  message?: string
}

@observer
export class TeamAccessReqs extends Component<ITeamAccessReqsProps, ITeamAccessReqsState> {
  emailInput?: EmailChipInput | null
  constructor(props) {
    super(props);

    this.state = {
    }
  }


  sendInviteEmail = () => {
    var emails = this.emailInput?.getEmails() || []
    if (emails.length > 0) {

      emails.forEach(email => {
        Fire.inviteUser(this.props.teamId, email)
      })
      this.setState({
        message: "Sent invite(s) to:\n" + emails.join('\n')
      })
    }

    this.emailInput?.clear()
  }

  render() {
    const teamAccessReqUI = OTUITree.teamAccessReqUI[this.props.teamId];
    console.log("TEAMACCESSREQ: %o", teamAccessReqUI);
    var entries = Object.entries(teamAccessReqUI.accessReqs || {});
    return (
      <View style={{ minWidth: 250 }}>
        <OTText
          style={{
            fontWeight: "600",
            color: Theme.DarkText,
            fontSize: 18,
            marginTop: 5,
            marginBottom: 20,
            textAlign: "center",
          }}
        >
          Add new user
        </OTText>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
          }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              borderRadius: 5,
              borderWidth: 1,
              borderColor: Theme.InputHighlightColor,
            }}
          >
            <EmailChipInput ref={(c) => (this.emailInput = c)} />
          </View>

          <View style={{ padding: 5 }} />
          <OTTouchableOpacity
            onPress={this.sendInviteEmail}
            style={{
              height: 30,
              width: 30,
              backgroundColor: Theme.ButtonColor,
              borderRadius: 15,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Feather
              name="send"
              style={{ color: Theme.ButtonIconColor, marginLeft: -1, marginBottom: -1 }}
            />
          </OTTouchableOpacity>
        </View>

        {this.state.message ? <OTText style={{ padding: 5 }}>{this.state.message}</OTText> : null}
        <View style={{ padding: 5 }} />

        <OTTouchableOpacity
          onPress={() => Fire.copyInvite(this.props.teamId)}
          style={{
            marginTop: 5,
            padding: 10,
            borderRadius: Theme.curviness / 1.5,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            backgroundColor: Theme.RowBackground,
            flexDirection: "row",
          }}
        >
          <Feather name="link" style={{ color: Theme.MainText }} />
          <OTText style={{ paddingLeft: 10, color: Theme.MainText }}>Copy invite link</OTText>
        </OTTouchableOpacity>
        <FlatList
          style={{ flex: 1 }}
          data={entries}
          renderItem={this.renderAccessReq}
          keyExtractor={(item) => item[0]}
          ItemSeparatorComponent={() => (
            <View
              style={{
                borderBottomWidth: 1,
                borderColor: StyleConstants.BorderColour,
                marginVertical: 5,
              }}
            />
          )}
          ListEmptyComponent={this.renderEmpty}
          ListHeaderComponent={this.renderHeader}
        />
      </View>
    );
  }

  renderHeader = () => {
    return (
      <View style={{ width: "100%", alignItems: 'center', marginVertical: 10 }}>
        <OTText style={{ paddingVertical: 15, fontWeight: '600' }}>
          Approve Access Requests
        </OTText>
      </View>
    )
  }

  renderAccessReq = ({ item }: ListRenderItemInfo<[string, IUserTeamReq]>) => {
    const teamAccessReqUI = OTUITree.teamAccessReqUI[this.props.teamId];
    const [id, userDoc] = item

    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: Theme.RowBackground,
          borderRadius: Theme.curviness,
          padding: 10,
        }}
      >
        <HeadIcon size={60} imageUrl={userDoc.imageUrl} name={userDoc.name} />

        <View style={{ paddingHorizontal: 10, paddingVertical: 5, flex: 1 }}>
          <OTText style={{ fontWeight: "600" }}>{userDoc.name}</OTText>
          <OTText style={{ marginVertical: 5 }}>{userDoc.email}</OTText>
          <OTText style={{ fontSize: 12, color: StyleConstants.TextGrey }}>
            {getTimeAgo(userDoc.date)}
          </OTText>
        </View>
        <OTTouchableOpacity
          onPress={() => teamAccessReqUI.acceptReq(id, userDoc)}
          style={styles.responseButton}
        >
          <Feather name="check" size={24} style={{ color: Theme.ButtonPositiveColor }} />
        </OTTouchableOpacity>
        <OTTouchableOpacity
          onPress={() => teamAccessReqUI.rejectReq(id)}
          style={styles.responseButton}
        >
          <Feather name="x" size={24} style={{ color: Theme.ButtonNegativeColor }} />
        </OTTouchableOpacity>
      </View>
    );

  }

  renderEmpty = () => {
    return <View style={{ alignItems: 'center' }}><OTText>No access requests</OTText></View>
  }

}

interface IInviteTeamProps {
  teamId: string
  onClose: () => void
}

interface IInviteTeamState {
  emails: string[]
  errors: { [id: number]: string[] }
  sending: boolean
  sent: boolean
}

@observer
export class InviteTeam extends Component<IInviteTeamProps, IInviteTeamState> {
  constructor(props) {
    super(props);

    this.state = {
      emails: ["", "", ""],
      errors: {},
      sending: false,
      sent: false
    }
  }

  isValidEmail = (email) => {
    return true
  }

  sendInviteEmail = () => {
    var emails = this.state.emails
    if (emails.length > 0) {
      if (this.state.sending) {
        return
      }
      this.setState({
        sending: true
      })

      emails.forEach(email => {
        if (email && this.isValidEmail(email)) {
          Fire.inviteUser(this.props.teamId, email)
        }
      })
      this.setState({
        sending: false,
        sent: true
        // "Sent invite(s) to:\n" + emails.join('\n')
      })
      this.props.onClose()
      OTGlobals.userSettingsManager.updateRemoteSettings({ addUsersDone: true })
      Analytics.logEvent("addUsersDone", { numInvites: emails.length })
    }

  }


  cancel = () => {
    this.props.onClose()
    OTGlobals.userSettingsManager.updateRemoteSettings({ addUsersSkipped: true });
    Analytics.logEvent("addUsersSkipped")

  }

  onEmailChanged = (text, index) => {
    var emails = [...this.state.emails]
    emails[index] = text
    var errors = {}

    emails.forEach((email, index) => {
      if (email.length > 0) {
        let error = validate.single(email, { presence: true, email: true });
        if (error) {
          errors[index] = error
        }
      }
    })

    this.setState({
      emails: emails,
      errors: errors
    })
  }

  render() {
    return (
      <View style={{ minWidth: 400, margin: 10 }}>
        <View style={{ width: "100%", alignItems: 'center' }}>
          <OTText style={{ fontWeight: '600', fontSize: 24, textAlign: 'center' }}>
            You're all set! Invite your teammates to get started
          </OTText>

          <Image
            source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Finvite.png?alt=media" }}
            style={{ width: 240, height: 160, resizeMode: 'contain', margin: 5, }} />

          <OTText >
            Who else is working with you?
          </OTText>

          <View style={{ width: "60%", marginVertical: 20 }}>
            {
              this.state.emails.map((value, index) => this.renderEmailInput(index))
            }
            <OTTouchableOpacity onPress={() => this.setState({ emails: [...this.state.emails, ""] })} style={{ paddingTop: 10, alignSelf: 'flex-end' }}>
              <View style={{ width: 35, height: 35, borderRadius: 5, backgroundColor: Theme.ButtonIconColor, borderWidth: 1, borderColor: Theme.ButtonColor, alignItems: 'center', justifyContent: 'center' }}>
                <Feather name="plus" size={20} style={{ color: Theme.ButtonColor }} />
              </View>
            </OTTouchableOpacity>
            <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 15 }}>
              <OTButton title="Invite Teammates" onPress={this.sendInviteEmail} />
            </View>
          </View>

        </View>

        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <OTButton title="Copy Invite Link" textStyle={{ fontSize: 13 }} onPress={() => Fire.copyInvite(this.props.teamId)} backgroundColor={'white'} textColor={Theme.OffDarkText} />
          <View style={{ flex: 1 }} />
          <OTButton title="Skip for now" textStyle={{ fontSize: 13 }} onPress={this.cancel} backgroundColor={'white'} textColor={Theme.OffDarkText} />
        </View>
      </View>
    )
  }

  renderEmailInput = (index) => {
    const error = this.state.errors[index]
    return (
      <View key={"email" + index}>
        <OTTextInput
          placeholder="name@domain.com"
          style={{
            padding: 8,
            paddingHorizontal: 10,
            marginVertical: 4,
            borderRadius: 5,
            flexGrow: 1,
            borderWidth: 1,
            borderColor: Theme.InputHighlightColor
          }}
          value={this.state.emails[index]}
          onChangeText={(text) => this.onEmailChanged(text, index)}
        />

        {
          error ?
            <OTText style={{ color: 'red', textAlign: 'center', }}>
              {error[0]}
            </OTText>
            :
            null
        }
      </View>
    )
  }

}

var styles = StyleSheet.create({
  responseButton: {
    backgroundColor: 'white',
    width: 25,
    height: 25,
    borderRadius: Theme.curviness / 2,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8
  }
})
import React, { Component } from "react";

import * as WebBrowser from 'expo-web-browser';
import { View, StyleSheet } from "react-native";

import { FEEDBACK_URL, WWW_URL } from "../../globals/config";
import * as Analytics from '../../utils/Analytics';

import { OTTouchableOpacity } from "../../components";
import { showReleaseNotes } from "./ReleaseNotes";
import { showOnboarding } from "../teamView/Onboarding";
import { OTPopover } from "../../components/OTPopover";
import Hoverable from "../../components/Hoverable";
import { Theme } from "../../globals/ThemeColors";
import { OTText } from "../../components/OTText";
import { showUserSettings } from "./settings/SettingsContainer";
import { SKSettings } from "../../components/SVGIcons";
import { OTAppData } from "../../data/OTData";
import { appUpgrade } from "../../utils/platform";
import { Feather } from '@expo/vector-icons';
import { OTAppCoreData } from "@openteam/app-core";

interface IHelpButtonProps {
  teamId: string
  icon: JSX.Element
}

interface IHelpButtonState {
  showMenu
}

export class HelpButton extends Component<IHelpButtonProps, IHelpButtonState> {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false
    }
  }


  toggleShowMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  showUserSettings = () => {
    showUserSettings(this.props.teamId)
    this.toggleShowMenu()
  }

  goUserGuide = () => {
    showOnboarding()
    Analytics.logEvent("help__onboarding_clicked")
    this.toggleShowMenu()
  }

  goFeedback = () => {
    WebBrowser.openBrowserAsync(FEEDBACK_URL);
    Analytics.logEvent("help__feedback_clicked")
    this.toggleShowMenu()
  }

  goSupport = () => {
    WebBrowser.openBrowserAsync('mailto:support@openteam.io?subject=Support Request');
    Analytics.logEvent("help__support_clicked")
    this.toggleShowMenu()
  }

  goReleaseNotes = () => {
    showReleaseNotes(0)
    Analytics.logEvent("help__releasenotes_clicked")
    this.toggleShowMenu()
  }

  goWebsite = () => {
    WebBrowser.openBrowserAsync(WWW_URL);
    Analytics.logEvent("help__website_clicked")
    this.toggleShowMenu()
  }

  doUpdate = () => {
    appUpgrade()
  }

  render() {

    return (
      <OTPopover
        title="Help"
        isOpen={this.state.showMenu}
        toggleIsOpen={this.toggleShowMenu}
        renderOpen={this.renderHelp}
        icon={this.props.icon}
        badgeNum={undefined}
      />
    )

  }

  renderHelp = () => {
    return (
      <View style={styles.menuContainer}>
        {
          OTAppData.webUpgradeAvailable || OTAppData.desktopAppUpgradeAvailable ?
            <View style={{ borderBottomColor: Theme.SeparatorColor, borderBottomWidth: 1, }}>
              <Hoverable>
                {
                  (isHovered) => (
                    <OTTouchableOpacity onPress={this.doUpdate} style={[styles.menuItem, { backgroundColor: isHovered ? Theme.GreyBackground : 'transparent' }]}>
                      <View style={{ flex: 1 }}>
                        <OTText style={styles.menuTitle}>
                          Update OpenTeam
                    </OTText>
                        <OTText style={styles.menuDesc}>
                          A newer version is available
                    </OTText>
                      </View>
                      <Feather name="arrow-up-circle" size={28} style={{ color: Theme.BadgeColor }} />
                    </OTTouchableOpacity>)
                }
              </Hoverable>
            </View>

            :
            null

        }
        {this.renderItem("Profile Settings", this.showUserSettings, undefined, <SKSettings size={16} style={{ color: Theme.DarkText }} />)}
        {/* {this.renderItem("Show User Guide", this.goUserGuide)} */}
        {this.renderItem("Add Feedback", this.goFeedback)}
        {this.renderItem("support@openteam.io", undefined, "mailto:support@openteam.io?subject=Support Request")}
        {/* {this.renderItem("Show Release Notes", this.goReleaseNotes)} */}
        {this.renderItem("OpenTeam.io", this.goWebsite)}
        {this.renderItem(`Version: ${OTAppCoreData.version}`, () => { })}
      </View>
    )
  }

  renderItem = (title: string, onPress?: () => void, href?: string, icon?: JSX.Element) => {
    return (
      <Hoverable>
        {
          (isHovered) => (
            href ?
              <View
                data-tip={title}
                accessibilityRole="link"
                // @ts-expect-error target not part of types
                target="_blank"
                href={href}
                style={
                [styles.menuItem, { backgroundColor: isHovered ? Theme.RowHover : 'transparent' }]
              }>
                <OTText style={styles.menuTitle}>
                  {title}
                </OTText>
                {
                  icon
                }
              </View>
              :
              <OTTouchableOpacity onPress={onPress} style={
                [styles.menuItem, { backgroundColor: isHovered ? Theme.RowHover : 'transparent' }]
              }>
                <OTText style={styles.menuTitle}>
                  {title}
                </OTText>
                {
                  icon
                }
              </OTTouchableOpacity>
          )
        }
      </Hoverable>
    )
  }
}

const styles = StyleSheet.create({
  menuContainer: {
  },
  menuItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 8,
    marginVertical: 4,
    borderRadius: Theme.curviness / 1.5
  },
  menuTitle: {
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 17,
    marginRight: 10
  },
  menuDesc: {
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 15,
    marginRight: 10
  },
})
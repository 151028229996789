import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/storage';

import * as Device from 'expo-device';

import { Clipboard, Platform } from 'react-native';
import { logEvent, aliasUser } from '../../utils/Analytics';
import { firebaseConfig, WEB_URL, googleConfig } from '../config';
import { Logger } from '@openteam/app-util';

import {
  INotes,
  ITeamDoc,
  ITeamRoom,
  IUserDoc,
  IUserTeamReq,
  INoteThread,
  INotePart,
  INoteUser,
  ISubTeam,
  ITeamCapabilities,
  ITeamExternalWaiting,
  ITeamConfigDoc,
} from "@openteam/models";
import { OTUserData } from '../../data/OTData';
import { showToast } from '../../components/Toasts';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { getFirebaseAuth, getRTDB } from './util';

import {
  OTAppCoreData,
  OTGlobals,
  OTUITree,
  TeamManagerDb,
  ExternalMeetingDb,
  FireDb,
} from "@openteam/app-core";

const logger = new Logger("Fire")

if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);

var functions = firebase.functions();


export function getUser() {
  return getFirebaseAuth().currentUser
}


export function getAnonymousLogin() {
  logger.info("logging in anonymously")
  getFirebaseAuth().signInAnonymously();
}

// export function getDB() {
//   return firebase.firestore()
// }

export function copyInvite(teamId) {
  const doc = {
    invitedBy: OTUITree.auth.userId,
    crDate: firebase.database.ServerValue.TIMESTAMP,
  }
  const inviteId = getRTDB().ref(`/teamInvite/${teamId}`).push(doc).key

  const teamData = OTGlobals.getTeamData(teamId);

  var inviteURL = `${WEB_URL}/${teamData.teamPath}?inviteId=${inviteId}`

  Clipboard.setString(inviteURL)

  showToast("Copied to clipboard", "info")
  logEvent("teamhome__copy_invite")
}

export function onAuthStateChanged(callback: (user: firebase.User | null) => void) {
  getFirebaseAuth().onAuthStateChanged(callback);
}

export async function getAuthToken() {
  return await getFirebaseAuth().currentUser?.getIdToken(true);
}

export function watchConfig(callback: (doc) => void) {
  getRTDB().ref('/config/').on('value', (snapshot) => callback(snapshot.val()))
}


export function watchAdmins(callback: (doc) => void) {
  getRTDB().ref('/admins/').on('value', (snapshot) => callback(snapshot.val()))
}


export function DEVONLYgoogleLogin() {
  var provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: "select_account"
  })
    firebase.auth().signInWithPopup(provider).then(function(result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // var token = result.credential.accessToken;
      // The signed-in user info.
      var user = result.user;

      signInSuccess(result)
  })
}

export function signInSuccess(result: {user: firebase.User | null}) {
  const user = result.user
  logger.info("signInSuccess", user)

  if (!user || user.isAnonymous) {
    return false
  }

  aliasUser(user.uid)

  if (user.displayName) {
    setupAccount(
      user.uid,
      user.displayName,
      user.email,
      user.photoURL
    )

  }

  return false
}

export function getMagicLinkSettings() {
  const params = {
    // loginRedirect: "true"
  }

  if (OTUserData.appLogin) {
    params['appLogin'] = OTUserData.appLogin
  }

  if (OTUserData.teamInviteId) {
    params["inviteId"] = OTUserData.teamInviteId;
  }

  if (OTUserData.referrerId) {
    params['referrerId'] = OTUserData.referrerId
  }

  const searchParams = new URLSearchParams(params);

  return {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url:
      WEB_URL +
      (OTUserData.reqTeamPath ? "/" + OTUserData.reqTeamPath : "/") +
      "?" +
      searchParams.toString(),
    // This must be true.
    handleCodeInApp: true,
    iOS: {
      bundleId: "com.openteam.openteammobile",
    },
    android: {
      packageName: "com.openteam.openteammobile",
      installApp: true,
      minimumVersion: "12",
    },
  };
}

export async function sendMagicLink(email) {
  var config = getMagicLinkSettings()

  console.log("sendMagicLink config", config)
  try {
    await getFirebaseAuth().sendSignInLinkToEmail(email, config);

    await AsyncStorage.setItem('emailForSignIn', email);

    console.log("sendMagicLink done")

  } catch (error) {
    console.log("sendMagicLink error", error)

  }
}



export async function getCustomToken() {
  var getCustomToken = firebase.functions().httpsCallable('getCustomToken');
  var result = await getCustomToken({})
  return result.data.authToken
}

export async function signInWithCustomToken(authToken) {
  const credential = await getFirebaseAuth().signInWithCustomToken(authToken);

  logger.debug("got credential", credential)

}




export async function setupAccount(userId, name, email, imageUrl) {
  logger.debug("setting up account ", name)

  var existingUser = await getRTDB().ref('/users/' + userId).once("value")
  logger.debug("got user ", existingUser.val())

  if (!existingUser.val()) {
    var userObj: IUserDoc = {
      name: name,
      email: email,
      imageUrl: imageUrl || null,
      accountSetup: true,
      crDate: firebase.database.ServerValue.TIMESTAMP,
      referrerId: OTUserData.referrerId || null
    }

    getRTDB().ref('/users/' + userId).update(userObj)
  }

}

export var globalRooms: {[id: string] : ITeamRoom} = {
  quiet: {
    config: {
      name: "Quiet 🤫",
      desc: "Let people know you want some quiet time",
      static: true,
      permanent: true,
      enabled: true,
      call: false,
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fquietillustration.png?alt=media"
    }
  },
  break: {
    config: {
      name: "Break ☕",
      desc: "Take shared coffee breaks together in here",
      static: true,
      permanent: true,
      enabled: true,
      call: true,
      useSFU: true,
      notify: true,
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fbreakillustration.png?alt=media"

    }
  },
}

export async function createTeam(teamPath) {

  var curTeamId = await FireDb.getTeamPathId(getRTDB(), teamPath)

  if (curTeamId) {
    throw Error(teamPath + " already exists")
  }

  var teamObj: ITeamDoc = {
    teamName: teamPath,
    teamPath: teamPath,
    crDate: firebase.database.ServerValue.TIMESTAMP,
    ownerUserId: OTUITree.auth.userId,
    admin: {
      [OTUITree.auth.userId]: {
        id: OTUITree.auth.userId,
      },
    },
    calls: {},
    hideStaticRooms: true,
    rooms: globalRooms,
    users: {
      [OTUITree.auth.userId]: {
        id: OTUITree.auth.userId,
        dateJoined: firebase.database.ServerValue.TIMESTAMP as number,
        email: OTUITree.userManager.userDoc!.email,
        name: OTUITree.userManager.userDoc!.name,
        imageUrl: OTUITree.userManager.userDoc!.imageUrl || null,
      },
    },
  };

  var teamId = getRTDB().ref('/teams').push(teamObj).key!

  getRTDB().ref(`/teamPath/${teamPath}`).set(teamId)
  getRTDB().ref(`/users/${OTUITree.auth.userId}/teams/${teamId}`).set(teamPath)

  // setUserTeamId(teamId)

  return teamId
}

export function createSubTeam(teamId: string, name: string) {

  var subTeam: ISubTeam = {
    name: name,
  }

  var subTeamId = getRTDB().ref(`/teams/${teamId}/subteams/`).push(subTeam).key

  return subTeamId
}

export function updateSubTeam(teamId: string, subTeamId: string, name: string) {


  var subTeam: ISubTeam = {
    name: name,
  }

  getRTDB().ref(`/teams/${teamId}/subteams/${subTeamId}`).update(subTeam)
}



export function setTeamAdmin(teamId, userId, isAdmin: boolean) {
  var msg = isAdmin ? {
    id: userId
  } : null
  getRTDB().ref(`/teams/${teamId}/admin/${userId}`).set(msg)
}


export function createTeamExternalAddress(teamId, address) {
  const teamData = OTGlobals.getTeamData(teamId);
  var teamUserDoc = teamData.getTeamUser(OTUITree.auth.userId)

  TeamManagerDb.updateTeamUser(getRTDB(), teamId, OTUITree.auth.userId, {address: address})
  getRTDB().ref(`/teamExternal/${teamId}/address/${address}`).set({
    userId: OTUITree.auth.userId,
    name: teamUserDoc.name
  })
}


export async function getTeamExternalAddress(teamId, address) {
  var doc = await getRTDB().ref(`/teamExternal/${teamId}/address/${address}`).once('value')
  var addressDoc = doc.val()

  return addressDoc
}


export async function joinTeamExternalAddressWaiting(teamId, address, name, callback: (doc: ITeamExternalWaiting) => void) {
  var addressRef = getRTDB().ref(`/teamExternal/${teamId}/address/${address}`)
  var waitingRef = addressRef.child(`waiting/${OTUITree.auth.userId}`)

  getRTDB().ref('.info/connected').on('value', function (snapshot) {
    if (snapshot.val() == false) {
      return;
    };

    waitingRef.onDisconnect().set(null).then(function() {
      waitingRef.update({
        name: name
      });
    });

  })

  waitingRef.on('value', (snapshot) => callback(snapshot.val()))
  // check my session token is the one, if not, disable presence and reload
}

export function cancelTeamExternalAddressWaiting(teamId, address) {
  var addressRef = getRTDB().ref(`/teamExternal/${teamId}/address/${address}`)
  var waitingRef = addressRef.child(`waiting/${OTUITree.auth.userId}`)
  waitingRef.set(null)

}


// export async function getTeams() {

//   var doc = await getRTDB().ref(`/teams/`).orderByChild(`users/${OTUITree.auth.userId}/id`).equalTo(OTUITree.auth.userId).once('value')
//   var teamDocs = doc.val()

//   var teams: ITeamDocContainer[] = Object.keys(teamDocs || {}).map(teamId => ({
//     id: teamId,
//     doc: teamDocs[teamId]
//   }))

//   return teams
// }

// export function watchTeams(callback: (teamId: string, doc: ITeamDoc) => void, deleteCallback: (id: string) => void) {
//   const ref = getRTDB().ref(`/teams/`).orderByChild(`users/${OTUITree.auth.userId}/id`).equalTo(OTUITree.auth.userId)

//   ref.on(
//     'child_added',
//     (snapshot) => callback(snapshot.key!, snapshot.val())
//   )

//   ref.on(
//     'child_changed',
//     (snapshot) => callback(snapshot.key!, snapshot.val())
//   )

//   ref.on(
//     'child_removed',
//     (snapshot) => deleteCallback(snapshot.key!)
//   )
// }

// export function unwatchTeams() {
//   const ref = getRTDB().ref(`/teams/`).orderByChild(`users/${OTUITree.auth.userId}/id`).equalTo(OTUITree.auth.userId)
//   ref.off('child_added')
//   ref.off('child_changed')
//   ref.off('child_removed')

// }


export async function inviteUser(teamId, email) {

  var inviteByEmail = firebase.functions().httpsCallable('inviteByEmail');
  var result = await inviteByEmail({teamId: teamId, email: email})

  return result.data.success
}


export function updateTeam(teamId: string, updateDoc) {
  getRTDB().ref('/teams/'+teamId).update(updateDoc)
}


export function updateTeamConfig(teamId: string, configDoc: ITeamConfigDoc) {
  getRTDB().ref(`/teams/${teamId}/config`).update(configDoc)
}



export async function adminRemoveUserFromTeam(teamId, userId) {

  var snap = await getRTDB().ref(`/teams/${teamId}/rooms`).orderByChild(`users/${userId}/id`).equalTo(userId).once('value')

  var roomIds: string[] = []
  snap.forEach((doc) => {
    roomIds.push(doc.key as string)
  })

  for (const roomId of roomIds) {
    await ExternalMeetingDb.removeTeamRoomUser(getRTDB(), teamId, roomId, userId);
  }

  getRTDB().ref(`/teams/${teamId}/admin/${userId}`).set(null)
  getRTDB().ref(`/teams/${teamId}/users/${userId}`).set(null)
}


export function watchNotes(teamId: string, callback: (doc: INotes) => void) {

  getRTDB().ref(`/notes/${teamId}`).orderByChild(`users/${OTUITree.auth.userId}/active`).equalTo(OTUITree.auth.userId).on(
    'value',
    (doc) => {callback(doc.val())}
  )
}

export function createNote(teamId: string, userId: string, priority: number, title: string, desc: string) {
  var noteId = getRTDB().ref(`/notes/${teamId}`).push().key!

  var partId = getRTDB().ref(`/notes/${teamId}/${noteId}/noteParts/`).push().key!

  var part: INotePart = {
    id: partId,
    fromUserId: OTUITree.auth.userId,
    crDate: firebase.database.ServerValue.TIMESTAMP as number,
    lastUpdate: firebase.database.ServerValue.TIMESTAMP  as number,

    title: title,
    desc: desc,
  }

  var users: {[id: string]: INoteUser} = {
    [userId]: {
      id: userId,
      status: 'inbox',
      numUnread: 1,
      active: userId,
    }
  }

  users[OTUITree.auth.userId] = {
    id: OTUITree.auth.userId,
    status: 'sent',
    numUnread: 0,
    active: OTUITree.auth.userId,
  }

  var thread: INoteThread = {
    id: noteId,
    fromUserId: OTUITree.auth.userId,
    toUserId: userId,
    crDate: firebase.database.ServerValue.TIMESTAMP as number,
    lastUpdate: firebase.database.ServerValue.TIMESTAMP as number,
    priority: priority,
    title: title,
    desc: desc,
    users: users,
    noteParts: {
      // [partId]: part
    }
  }

  logger.debug("INote", thread)

  getRTDB().ref(`/notes/${teamId}/${noteId}`).set(thread)

  return noteId
}


export async function addNotePart(teamId: string, noteId: string, title: string, desc: string) {

  var partId = getRTDB().ref(`/notes/${teamId}/${noteId}/noteParts/`).push().key!

  var part: INotePart = {
    id: partId,
    fromUserId: OTUITree.auth.userId,
    crDate: firebase.database.ServerValue.TIMESTAMP as number,
    lastUpdate: firebase.database.ServerValue.TIMESTAMP as number,

    title: title,
    desc: desc,
  }

  getRTDB().ref(`/notes/${teamId}/${noteId}/noteParts/${partId}`).set(part)


  getRTDB().ref(`/notes/${teamId}/${noteId}`).transaction(
    function(note: INoteThread) {
      if (note.users) {
        Object.keys(note.users).forEach(
          userId => {
            if (userId != OTUITree.auth.userId) {
              note.users[userId].numUnread += 1

            } else {
              note.users[userId].numUnread = 0
            }
            note.users[userId].active = userId
            note.users[userId].done = null
          })
      }
      return note
    }
  )
}

export function markNoteRead(teamId: string, id: string) {
  getRTDB().ref(`/notes/${teamId}/${id}/users/${OTUITree.auth.userId}`).update({
    // status: 'inbox',
    numUnread: 0
  })
}


export function removeNote(teamId: string, id: string) {
  getRTDB().ref(`/notes/${teamId}/${id}/users/${OTUITree.auth.userId}`).update({
    status: 'done',
    numUnread: 0,
    active: null,
    done: OTUITree.auth.userId
  })
}



export function genSessionToken() {
  logger.debug("genSessionToken");
  return getRTDB().ref(`/users/${OTUITree.auth.userId}/status/activeSessions`).push().key!
}




export function setNotificationToken(type, token) {
  console.log("setting token ", token, " for ", OTUITree.auth.userId)

  var notificationRef = getRTDB().ref(
    `/users/${OTUITree.auth.userId}/device/${OTAppCoreData.deviceId}`
  );
  notificationRef.update({token: token, type: type})

}

export function setAPNSToken(apnsToken) {
  console.log("setting apnsToken ", apnsToken, " for ", OTUITree.auth.userId)

  var notificationRef = getRTDB().ref(`/users/${OTUITree.auth.userId}/device/${OTAppCoreData.deviceId}`)
  notificationRef.update({apnsToken: apnsToken})

}

export async function getRoomServer(teamId, roomId) {
  const teamData = OTGlobals.getTeamData(teamId);

  if (teamData.capabilities.scalableSFU) {
    const roomKey = `${teamId}:${roomId}`
    const func = firebase.functions().httpsCallable('findServerForResource');
    const result = await func({ resourceType: 'room', resourceRef: roomKey });
    return result.data;
  } else {
    return {
      wsURL: OTAppCoreData.SFUUrl,
    };
  }
}

export async function getMiroToken() {
  var getMiroToken = firebase.functions().httpsCallable("getMiroToken");
  var result = await getMiroToken();

  logger.info("getMiroToken", result.data.authToken);

  return result.data.authToken;
}
import React, { Component, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { StyleSheet, View } from 'react-native';
import { Theme } from '../../globals/ThemeColors';
import { SKMicOff } from '../../components/SVGIcons';
import { IOTStream } from '@openteam/models';
import { Feather, MaterialCommunityIcons } from '@expo/vector-icons';
import { Logger } from '@openteam/app-util';
import { StreamDetails } from 'app/core/src/MediaDeviceManager';

const logger = new Logger('Volume')

interface IVolumeProps {
    streamInfo?: IOTStream 
    size?: string
    onSpeak?: (boolean) => void
    speakerDisabled?: boolean
    inAudioGroup?: boolean
    isAudioGroupSpeaker?: boolean
    volumeFactor?: number
}

interface IVolumeState {
}

@observer
export default class Volume extends Component<IVolumeProps, IVolumeState> {

    constructor(props) {
        super(props)
    }

    get size() {
        return this.props.size ?? 'S'
    }

    render () {
        const { muted, volume: _volume } = this.props.streamInfo || { muted: true, volume: 0 };

        const volume = _volume * (this.props.volumeFactor ?? 1);
        const opacity = Math.min(0.6 + (volume / 10), 1)
        const iconSz = {'S': 15, 'M': 20, 'L': 25}[this.size]

        const isShushed = this.props.isAudioGroupSpeaker == false;
        const showSpeakerDisabled = this.props.speakerDisabled;
        const showVolume = !(this.props.inAudioGroup || muted || isShushed || showSpeakerDisabled);

        let icons: JSX.Element[] = [];

        if (this.props.inAudioGroup) {
            icons.push(
                <View  key="account-group" style={styles.IconBackground}>
                    <MaterialCommunityIcons
                        name="account-group"
                        styles={{
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        color="white"
                        size={iconSz}
                    />
                </View>
                );
        } else {
                icons.push(
                    <View key="muted" style={[styles.IconBackground, muted ? null : styles.Hidden]}>
                        <SKMicOff style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: Theme.ButtonNegativeColor,
                            transitionProperty: 'opacity',
                            opacity: opacity,
                            transitionDuration: '0.2s',
                        }} size={iconSz}/>
                    </View>
                )
                if (showSpeakerDisabled) {
                    icons.push(
                        <View key="shushed" style={[styles.IconBackground, showSpeakerDisabled ? null : styles.Hidden]}>
                            <Feather name="volume-x" style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                opacity: opacity,
                                color: Theme.ButtonNegativeColor,
                                }} size={iconSz} />
                        </View>
                    )
                }
        }

        return (
            <View style={[styles.Volume, styles[`${this.size}Volume`]]} >
                {icons}
                <VolumeBar volume={volume} show={showVolume}/>
            </View>
        );
    }
}

type VolueBarProps = {
    volume: number;
    show?: boolean
}

export function VolumeBar ({volume, show}: VolueBarProps) {
    const [barStyle, setBarStyle] = useState('Hidden')

    useEffect(() => {
        setBarStyle((show ?? true)? `level${volume}` : 'Hidden')
    }, [volume, show])

    return (
        <View key="vol" style={[styles.Bar, styles[barStyle]]} />
    )
}

const styles = StyleSheet.create({
    IconBackground: {
        marginHorizontal: 3,
        backgroundColor: 'rgba(0,0,0,0.6)',
        padding: 5,
        borderRadius: Theme.curviness/2,
        transitionProperty: "opacity transform",
        transitionDuration: ".2s"
    },
    Hidden: {
        opacity: 0,
        transform: [{scale: 0}],
    },
    Volume: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
    },
    LVolume: {
        height: 45,
        width: '40%',
    },
    MVolume: {
        height: 25,
        width: '60%',
    },
    SVolume: {
        height: 15,
        width: '100%',
    },
    Bar: {
        position: 'absolute',
        alignItems: 'center',
        height: 6,
        width: "100%",
        borderRadius: 3,
        transitionProperty: 'transform opacity',
        transitionDuration: '0.35s',
        willChange: "transform opacity",
        backgroundColor: Theme.VolumeBarColour,
    },
    level0: { transform: [{scale: 0}], opacity: 0.3},
    level1: { transform: [{scaleX: 0.1}, {scaleY: 0.3}], opacity: 0.4},
    level2: { transform: [{scaleX: 0.2}, {scaleY: 0.6}], opacity: 0.5},
    level3: { transform: [{scaleX: 0.3}, {scaleY: 0.9}], opacity: 0.6},
    level4: { transform: [{scaleX: 0.4}],  opacity: 0.7},
    level5: { transform: [{scaleX: 0.5}],  opacity: 0.8},
    level6: { transform: [{scaleX: 0.6}],  opacity: 0.8},
    level7: { transform: [{scaleX: 0.7}],  opacity: 0.9},
    level8: { transform: [{scaleX: 0.8}],  opacity: 0.9},
    level9: { transform: [{scaleX: 0.9}],  opacity: 1.0},
    level10: { transform: [{scaleX: 1}],   opacity: 1.0},
})
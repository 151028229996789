import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";
import { IHideToast, IShowToast } from '@openteam/models';

const toastTypes = {
  info: toast.TYPE.INFO,
  success: toast.TYPE.SUCCESS,
  error: toast.TYPE.ERROR
}

export const showToast: IShowToast = (msg, toastType, desc?) => {
  toast(msg, {
    type: toastTypes[toastType]
  })
}

export const hideToast: IHideToast = () => {};

export function getToastContainer () {
  return <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={3000} pauseOnFocusLoss={false} />

}

import { ITeamDoc } from "@openteam/models";
import firebase from "firebase/app";
import "firebase/database";
import { OTAppCoreData } from "../OTAppCoreData";
import * as Device from "expo-device";
import { OTUserInterface } from "../OTUserInterface";

import { Logger } from "@openteam/app-util";
import { addWatch, removeWatch } from "./FireUtils";

const logger = new Logger("AppHomeManagerDb");

export class AppHomeManagerDb {
  static watchTeamList = (
    fbDb: firebase.database.Database,
    userId: string,
    callback: (doc: { [teamIds: string]: boolean }) => void
  ) => {
    const ref = `/userteams/${userId}`
    addWatch(ref);
    fbDb.ref(ref).on("value", (snapshot) => callback(snapshot.val()));
  };

  static unwatchTeamList = (fbDb: firebase.database.Database, userId: string) => {
    const ref = `/userteams/${userId}`;
    removeWatch(ref)
    fbDb.ref(ref).off("value");
  };

  static watchTeam = (
    fbDb: firebase.database.Database,
    teamId: string,
    callback: (teamId: string, doc: ITeamDoc) => void,
    onError: (err) => void
  ) => {
    const ref = `/teams/${teamId}`;
    addWatch(ref);
    fbDb
      .ref(ref)
      .on("value", (snapshot) => callback(snapshot.key!, snapshot.val()), onError);
  };

  static unwatchTeam = (fbDb: firebase.database.Database, teamId: string) => {
    const ref = `/teams/${teamId}`;
    removeWatch(ref);
    fbDb
      .ref(ref)
      .off("value");
  };

  static registerDeviceUser = (
    fbDb: firebase.database.Database,
    userId: string,
    sessionToken: string
  ) => {
    var deviceRef = fbDb.ref(`/users/${userId}/device/${OTAppCoreData.deviceId}`);

    const device = {
      manufacturer: Device.manufacturer,
      modelName: Device.modelName,
      deviceName: Device.deviceName,
      osName: Device.osName,
      osVersion: Device.osVersion,
    };
    deviceRef.update({
      last_changed: firebase.database.ServerValue.TIMESTAMP,
      appVersion: OTAppCoreData.version,
      sessionToken: sessionToken,
      platform: OTUserInterface.platformUtils.PlatformOS,
      os: OTUserInterface.platformUtils.Platform.OS,
      device: device,
    });
  };

  static unregisterDeviceUser = async (fbDb: firebase.database.Database, userId: string) => {
    await fbDb.ref(`/users/${userId}/device/${OTAppCoreData.deviceId}`).remove();
  };

  static getTeam = async (fbDb: firebase.database.Database, teamId: string): Promise<ITeamDoc> => {
    const snapshot = await fbDb.ref("/teams/" + teamId).once("value");

    return snapshot.val();
  };
}

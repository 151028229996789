import { MeetingManager } from "./MeetingManager";

export class MeetingUIState {
  _meetingManager: MeetingManager;

  constructor(meetingManager: MeetingManager) {
    this._meetingManager = meetingManager;
  }

  createRoom = () => this._meetingManager.createRoom();
  acceptReq = (userId: string, roomId: string | undefined) =>
    this._meetingManager.acceptReq(userId, roomId);
  toggleShowMenu = () => this._meetingManager.toggleShowMenu();
  holdReq = (userId: string) => this._meetingManager.holdReq(userId);
  rejectReq = (userId: string) => this._meetingManager.rejectReq(userId);
  showModal = () => this._meetingManager.showModal();

  get address() {
    return this._meetingManager.address;
  }
  get showMenu() {
    return this._meetingManager.showMenu;
  }
  get addressDoc() {
    return this._meetingManager.addressDoc;
  }

}

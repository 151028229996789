import React, { Component } from "react";
import { observer } from "mobx-react";
import * as ModalService from "../../../components/ModalService";

import { View, Text, TextInput, ScrollView, FlatList, StyleSheet } from "react-native";
import {
  UserSettings,
  UserSignOutSettings,
  UserMediaSettings,
  UserAppSettings,
} from "./UserSettings";
import { AdminTeamSettings, AdminUserSettings, AdminSubTeamSettings } from "./AdminSettings";
import { Theme } from "../../../globals/ThemeColors";
import { OTButton } from "../../../components/buttons/OTButtons";
import { HeaderDialog } from "../../../components";
import { NotifySettings } from "./TeamSettings";
import { Feather } from "@expo/vector-icons";
import { OTUITree, OTGlobals } from "@openteam/app-core";

export function showAdminSettings(teamId, initialSection?) {
  const teamData = OTGlobals.getTeamData(teamId);

  var sections = ["space", "teams", "users"];

  if (teamData.capabilities.notifyUserOnline) {
    sections.push("notify");
  }

  ModalService.showModal({
    contents: (
      <SettingsContainer
        teamId={teamId}
        onClose={ModalService.dismissModal}
        availableSections={sections}
        initialSection={initialSection}
        showLeave={true}
      />
    ),
  });
}

export function showUserSettings(teamId) {
  ModalService.showModal({
    contents: (
      <SettingsContainer
        teamId={teamId}
        onClose={ModalService.dismissModal}
        availableSections={["profile", "devices", "app"]}
        initialSection={"profile"}
      />
    ),
  });
}

export function showDeviceSettings(teamId) {
  ModalService.showModal({
    contents: (
      <SettingsContainer
        teamId={teamId}
        onClose={ModalService.dismissModal}
        availableSections={["devices"]}
        initialSection={"devices"}
      />
    ),
  });
}

interface ISettingsSection {
  icon: string;
  name: string;
  component: any;
  reqAdmin?: boolean;
}

interface ISettingsContainerProps {
  teamId: string;
  availableSections?: string[];
  onClose: () => void;
  initialSection?: string;
  showSignOut?: boolean;
  showLeave?: boolean;
}

interface ISettingsContainerState {
  availableSections: string[];
  sections: { [id: string]: ISettingsSection };
}

@observer
export class SettingsContainer extends Component<ISettingsContainerProps, ISettingsContainerState> {
  constructor(props) {
    super(props);

    const teamData = OTGlobals.getTeamData(this.props.teamId);
    var isAdmin = teamData && teamData.isAdmin;

    var availableSections: string[] = props.availableSections || Object.keys(this.sections);

    availableSections = availableSections.filter(
      (sectionKey) =>
        !this.sections[sectionKey].reqAdmin || (this.sections[sectionKey].reqAdmin && isAdmin)
    );

    var sections = {};

    availableSections.forEach((section) => {
      sections[section] = this.sections[section];
    });

    this.state = {
      availableSections: availableSections,
      sections: sections,
    };
  }

  sections: { [id: string]: ISettingsSection } = {
    space: {
      icon: "home",
      name: "Space",
      component: AdminTeamSettings,
      reqAdmin: true,
    },
    teams: {
      icon: "users",
      name: "Teams",
      component: AdminSubTeamSettings,
      reqAdmin: true,
    },
    users: {
      icon: "user-check",
      name: "Users",
      component: AdminUserSettings,
      reqAdmin: true,
    },
    notify: {
      icon: "bell",
      name: "Notifications",
      component: NotifySettings,
    },
    profile: {
      icon: "user",
      name: "My Profile",
      component: UserSettings,
    },
    devices: {
      icon: "sliders",
      name: "Devices",
      component: UserMediaSettings,
    },
    app: {
      icon: "sidebar",
      name: "App",
      component: UserAppSettings,
    },
    signout: {
      icon: "sign-out-alt",
      name: "Sign Out",
      component: UserSignOutSettings,
    },
  };

  leaveTeam = () => {
    if (confirm("Are you sure you want to leave the team?")) {
      (async () => await OTUITree.appHomeUI.leaveTeam(this.props.teamId))();
      this.props.onClose();
    }
  };

  render() {
    return (
      <SettingsDialog
        title="Settings"
        sectionParams={{ teamId: this.props.teamId }}
        sections={this.state.sections}
        onClose={this.props.onClose}
        initialSection={this.props.initialSection}
        bottomMenuButton={this.props.showLeave ? this.renderLeave() : undefined}
      />
    );
  }

  renderLeave = () => {
    return (
      <View style={{ flexDirection: "row", margin: 10 }}>
        <OTButton
          small={true}
          outerStyle={{ height: 30, flex: 1 }}
          icon={
            <Feather
              name="log-out"
              style={{
                color: Theme.ButtonNegativeColor,
                marginRight: 5,
              }}
            />
          }
          textColor={Theme.ButtonNegativeColor}
          borderColor={Theme.ButtonNegativeColor}
          backgroundColor={"transparent"}
          title={"Leave Team"}
          onPress={this.leaveTeam}
        />
      </View>
    );
  };
}

interface ISettingsDialogProps {
  title: string;
  sectionParams: { [id: string]: any };
  sections: { [id: string]: ISettingsSection };
  onClose: () => void;
  initialSection?: string;
  bottomMenuButton?: JSX.Element;
}

interface ISettingsDialogState {
  section: string;
}

@observer
export class SettingsDialog extends Component<ISettingsDialogProps, ISettingsDialogState> {
  constructor(props) {
    super(props);

    this.state = {
      section: this.props.initialSection || Object.keys(this.props.sections)[0],
    };
  }

  render() {
    let section = this.props.sections[this.state.section];
    return (
      <HeaderDialog title={this.props.title} onClose={this.props.onClose}>
        <View style={{ flexDirection: "row", flex: 1 }}>
          {this.renderMenu()}
          {section ? (
            <ScrollView
              key={this.state.section}
              style={{ flex: 1 }}
              contentContainerStyle={{ padding: 30 }}
            >
              {<section.component {...this.props.sectionParams} onClose={this.props.onClose} />}
            </ScrollView>
          ) : null}
        </View>
      </HeaderDialog>
    );
  }

  renderMenu = () => {
    return (
      <View
        style={{
          minWidth: 125,
          paddingTop: 10,
          display: "flex",
          alignItems: "flex-start",
          backgroundColor: Theme.MenuBackground,
        }}
      >
        {Object.keys(this.props.sections).map((sectionKey) => {
          let section = this.props.sections[sectionKey];
          let selected = sectionKey == this.state.section;
          return (
            <View
              key={sectionKey}
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 10,
                width: "-webkit-fill-available",
              }}
            >
              <OTButton
                key={sectionKey}
                small={true}
                outerStyle={{ height: 30, flex: 1, justifyContent: "flex-start" }}
                icon={
                  <Feather
                    // @ts-expect-error name unknown
                    name={section.icon}
                    size={16}
                    style={{
                      color: selected ? Theme.ButtonIconColor : Theme.MainText,
                      marginRight: 5,
                    }}
                  />
                }
                textColor={selected ? Theme.ButtonIconColor : Theme.MainText}
                backgroundColor={selected ? Theme.ButtonColor : "transparent"}
                title={section.name}
                onPress={() => this.setState({ section: sectionKey })}
              />
            </View>
          );
        })}
        <View style={{ flex: 1 }} />
        {this.props.bottomMenuButton}
      </View>
    );
  };
}

import firebase from "firebase/app";
import "firebase/analytics";
import mixpanel from "mixpanel-browser";

import * as config from "../globals/config";

import { PlatformOS } from "./platform";
import { OTAppCoreData, OTUITree } from "@openteam/app-core";

import { Logger } from "@openteam/app-util";

const logger = new Logger("Analytics");

mixpanel.init(config.MIXPANELID, { ignore_dnt: true });

export function aliasUser(userId) {
  mixpanel.alias(userId);
}

export function identifyUser(userId) {
  mixpanel.identify(userId);
  firebase.analytics().setUserId(userId);
  logger.debug("identifyUser", userId);
}

export function setProperties() {

  const userManager = OTUITree.unsafeUserManager;

  firebase.analytics().setUserProperties({
    teamId: userManager?.currentTeamId,
    userId: OTUITree.auth.userId,
  });

  mixpanel.people.set({
    userId: OTUITree.auth.userId,
    version: OTAppCoreData.version,
    platform: PlatformOS,
    ...(userManager
      ? {
          $email: userManager.userDoc?.email, // only special properties need the $
          "Sign up date": userManager.userDoc?.crDate, // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
          teamId: userManager.currentTeamId,
          name: userManager.userDoc?.name,
        }
      : {}),
  });
}

export function logEvent(event: string, data?) {
  const eventData = {
    teamId: OTUITree.unsafeUserManager?.currentTeamId,
    ...data,
  };

  mixpanel.track(event, eventData);
  logger.debug("tracking event", event, eventData);

  firebase.analytics().logEvent(event, eventData);
}

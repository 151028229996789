import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";
import { Logger } from "@openteam/app-util";
import uuid from "react-native-uuid";

const logger = new Logger("FireUtil");

export const _fireWatches: Record<string, boolean> = {};

const watchKey = (ref: string, key?: string) => [ref, key].filter(x => x !== undefined).join(":")

export const addWatch = (ref: string, key?: string) => {
  const k = watchKey(ref, key);

  //logger.debug("addWatch: ref=%s", k);

  if (_fireWatches[k] === true) logger.error("watch already exists: %s", k);

  _fireWatches[k] = true;
};

export const removeWatch = (ref: string, key?: string) => {
  const k = watchKey(ref, key);

  //logger.debug("removeWatch: %s", k);

  if (_fireWatches[k] === false) logger.error("watch has been removed: %s", k);

  if (!_fireWatches[k]) logger.error("watch doesn't exist: %s", k);

  delete _fireWatches[k];
};

export class FireUtils {
  static uploadUserFile = async (
    teamId: string,
    userId: string,
    imageKind,
    file,
    callback: (url) => void
  ) => {
    var storageRef = firebase.storage().ref();

    const fileName = file.name || uuid.v1()

    var uploadFile = await storageRef
      .child(`/users/${userId}/team/${teamId}/${imageKind}/${fileName}`)
      .put(file);

    var url = await uploadFile.ref.getDownloadURL();
    callback(url);
  };

  static getAuthToken = async () => firebase.auth().currentUser?.getIdToken(true);
}

import React, { Component } from 'react';
import { View, TextInput, Image, Text, ImageBackground, ScrollView } from 'react-native';

import * as StyleConstants from '../globals/StyleConstants'
import * as Fire from '../globals/Fire';
import * as FireWebAuthUI from '../globals/FireWebAuthUI';
import { GoogleIcon } from '../components/GoogleLogo';
import * as firebaseui from 'firebaseui'

import { OTModalContainer } from '../components/ModalService';
import { PageHeader } from './fragments/PageHeader';
import NewFirebaseAuth from '../components/FirebaseAuthUI';
import { googleConfig } from '../globals/config';
import { Theme } from '../globals/ThemeColors';
import { OTText } from '../components/OTText';
import { isMobile, isIPad13 } from 'react-device-detect';
import { OTTouchableOpacity } from '../components';

import * as Analytics from '../utils/Analytics'
import { getFirebaseAuth } from '../globals/Fire/util';


interface ILoginProps {
}

interface ILoginState {
  signUp: boolean
}

export default class Login extends Component<ILoginProps, ILoginState> {
  constructor(props) {
    super(props);

    this.state = {
      signUp: true
    }

  }

  componentDidMount = () => {
    Analytics.logEvent("login_page")
  }

  render() {
    var uiConfig = FireWebAuthUI.getFirebaseUiConfig()
    return (
      <View style={{ flex: 1, backgroundColor: Theme.OfficeBackground }} >
        <ScrollView contentContainerStyle={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1, paddingHorizontal: 25 }}>

          <View style={{ flexDirection: 'row', minHeight: "100vh", width: "100vw", maxWidth: "100vw", flex: 1, justifyContent: 'center', flexWrap: 'wrap-reverse' }}>
            {
              !isMobile || isIPad13 ?
                <View style={{ flex: 3, minWidth: 300, alignItems: 'center', justifyContent: 'center' }}>
                  <Image
                    source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fwelcome.png?alt=media" }}
                    style={{ height: "80vh", minWidth: 300, minHeight: 250, width: "50vw" }} resizeMode="contain" />
                </View>
                :
                null
            }
            <View style={{ flex: 2, height: "100vh", minWidth: 300, alignItems: 'center', justifyContent: 'center' }}>

              <OTModalContainer padding={20} style={{ minWidth: 300, minHeight: 200, justifyContent: 'space-around' }}>
                <OTText style={{ fontSize: 25, fontWeight: "500", color: Theme.DarkText, }}>
                  {
                    this.state.signUp ?
                      "Sign up"
                      :
                      "Sign in"
                  }

                </OTText>
                <NewFirebaseAuth uiConfig={uiConfig} firebaseAuth={getFirebaseAuth()} />
                <OTTouchableOpacity onPress={() => this.setState({ signUp: !this.state.signUp })} style={{ paddingTop: 10 }} analyticsEvent={this.state.signUp ? "login_show_signin" : "login_show_signup"}>
                  <OTText style={{ fontSize: 14, fontWeight: "500", color: Theme.OffDarkText, }}>
                    {
                      this.state.signUp ?
                        "If you already have an account click here"
                        :
                        "Don't have an account? Sign up"
                    }
                  </OTText>
                </OTTouchableOpacity>
              </OTModalContainer>
            </View>
          </View>
          <PageHeader />
        </ScrollView>
      </View>
    )
  }

}

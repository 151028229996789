import React, { Component } from "react";
import { View, StyleSheet, Text, ActivityIndicator } from "react-native";
import { OTText } from "./OTText";
import { Theme } from "../globals/ThemeColors";


interface ILoadingViewProps {
  title: string
  size?: 'large' | 'normal'
}
interface ILoadingViewState { }

export class LoadingView extends Component<ILoadingViewProps, ILoadingViewState> {
  render() {
    var indicatorSize = 40
    var textSize = 14
    var marginTop = 15

    if (this.props.size == 'large') {
      indicatorSize = 50
      textSize = 18
      marginTop = 30

    }
    return (
      <View style={{alignItems: 'center'}}>
        <ActivityIndicator size={indicatorSize} color={Theme.MainColour}/>
        <OTText style={{fontSize: textSize, marginTop: marginTop, fontWeight: 'bold'}}>
          {this.props.title}
        </OTText>
      </View>
    )
  }
}
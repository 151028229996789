import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlatList, FlatListProps } from "react-native";
import { PlatformOS } from "../../utils/platform";


@observer
export class ChatFlatList extends Component<FlatListProps<any>, any> {
  _scrollNode: any
  _flatlistRef = React.createRef<FlatList>()

  componentDidMount = () => {
    if (PlatformOS != 'mobile') {
      this._scrollNode = this._flatlistRef.current?.getScrollableNode();
      this._scrollNode?.addEventListener("wheel", this._invertedWheelEvent);
    }
  }
  componentWillUnmount = () => {
    if (PlatformOS != 'mobile') {
      this._scrollNode?.removeEventListener("wheel", this._invertedWheelEvent);
    }
  }

  _invertedWheelEvent = e => {
    this._scrollNode.scrollTop -= e.deltaY;
    e.preventDefault();
  };

  scrollToStart = () => {
    this._flatlistRef.current?.scrollToOffset({ offset: 0, animated: false })
  }

  scrollToEnd = () => {
    this._flatlistRef.current?.scrollToEnd()
  }

  render() {
    return (
      <FlatList
        ref={this._flatlistRef}
        inverted
        disableVirtualization
        {...this.props}
      >
        {this.props.children}
      </FlatList>
    )

  }
}

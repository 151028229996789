

import React, { PureComponent } from 'react';
import { TouchableOpacityProps } from 'react-native';
import { TouchableOpacity } from 'react-native';
import ReactTooltip from 'react-tooltip';
import { initSoundEffects } from '../../utils/Notify';
import { OTUserData } from '../../data/OTData';
import * as Analytics from '../../utils/Analytics'
import Hoverable from '../Hoverable';
import { rebuildTooltips } from '../Tooltip';

interface IOTTouchableOpacityProps extends TouchableOpacityProps {
  stopPropagation?: boolean
  analyticsEvent?: string
  activeOpacity?: number
  href?: string
  onContextMenu?: () => void
}

export const OTTouchableOpacity = React.forwardRef<TouchableOpacity, IOTTouchableOpacityProps>((props, ref) => {

  const onPress = (e) => {
    if (props.stopPropagation) {
      e.stopPropagation();
    }


    if (!OTUserData.userInteracted) {
      initSoundEffects()

      OTUserData.userInteracted = true
    }
    props.onPress && props.onPress(e)
    ReactTooltip.hide()

    if (props.analyticsEvent) {
      Analytics.logEvent(props.analyticsEvent, { interaction: 'button' })
    }

  }

  rebuildTooltips()

  return (<TouchableOpacity
    ref={ref}
    // @ts-expect-error dataSet not part of types
    dataSet={{ tip: props['data-tip'] }}
    activeOpacity={props.activeOpacity ?? 0.4}
    {...props}
    onPress={onPress}>
    {props.children}
  </TouchableOpacity>
  )
});



export function OTHoverableOpacity(props) {
  let { hoveredOpacity, opacity, activeOpacity, style, disabled, ...newProps } = props;
  if (disabled) {
    activeOpacity = hoveredOpacity = opacity = 0.2;
  } else {
    activeOpacity = activeOpacity ?? 1;
    hoveredOpacity = hoveredOpacity ?? 0.85
    opacity = opacity ?? 0.6;
  }
  return (
    <Hoverable>
      {hovered => (
        <OTTouchableOpacity {...newProps} disabled={disabled} activeOpacity={activeOpacity} style={[style, { opacity: hovered ? hoveredOpacity : opacity }]} />
      )}
    </Hoverable>
  )
}


import firebase from "firebase/app";
import "firebase/database";

import * as Device from "expo-device";
import { Logger } from "@openteam/app-util";
import { OTUserInterface } from "../OTUserInterface";
import { OTAppCoreData } from "../OTAppCoreData";
import { ITeamCapabilities } from "@openteam/models";
import { addWatch, removeWatch } from "./FireUtils";
const logger = new Logger("SignalsDb");

export class TeamManagerDb {
  static setTeamBackgroundStatus = (
    fbDb: firebase.database.Database,
    userId: string,
    teamId: string,
    inBackground: boolean
  ) => {
    const statusRef = fbDb.ref("/teams/" + teamId + "/users/" + userId + "/status");

    statusRef.update({
      inBackground: inBackground,
      focused: !inBackground, // for backwards compatability use inBackground until everyone has upgraded, then can switch to focused which makes more sense.
    });
    logger.info("set background to", inBackground);
  };

  static setIdle = (fbDb: firebase.database.Database, userId: string, teamId: string, idle) => {
    var statusRef = fbDb.ref("/teams/" + teamId + "/users/" + userId + "/status");

    statusRef.update({
      idle: idle,
    });
    logger.debug("set idle to", idle);
  };

  static registerDeviceTeamUser = (
    fbDb: firebase.database.Database,
    userId: string,
    sessionToken: string,
    teamId: string
  ) => {
    const version = OTAppCoreData.version;
    const deviceId = OTAppCoreData.deviceId;

    const deviceRef = fbDb.ref(`/teams/${teamId}/users/${userId}/device/${deviceId}`);

    const device = {
      manufacturer: Device.manufacturer,
      modelName: Device.modelName,
      deviceName: Device.deviceName,
      osName: Device.osName,
      osVersion: Device.osVersion,
    };
    deviceRef.update({
      last_changed: firebase.database.ServerValue.TIMESTAMP,
      appVersion: version,
      sessionToken: sessionToken,
      platform: OTUserInterface.platformUtils.PlatformOS,
      os: OTUserInterface.platformUtils.Platform.OS,
      device: device,
    });
  };

  static unregisterDeviceTeamUser = async (
    fbDb: firebase.database.Database,
    userId: string,
    teamId: string
  ) => {
    const deviceId = OTAppCoreData.deviceId;

    await fbDb.ref(`/teams/${teamId}/users/${userId}/device/${deviceId}`).remove();
  };

  static updateTeamUser = async (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string,
    userObj
  ) => {
    fbDb.ref(`/teams/${teamId}/users/${userId}`).update(userObj);
  };

  static watchTeamCapabilities = (
    fbDb: firebase.database.Database,
    teamId: string,
    callback: (doc: ITeamCapabilities) => void
  ) => {
    const ref = `/teamCapabilities/${teamId}`;
    addWatch(ref);
    fbDb.ref(ref).on("value", (snapshot) => callback(snapshot.val()));
  };

  static unwatchTeamCapabilities = (fbDb: firebase.database.Database, teamId: string) => {
    const ref = `/teamCapabilities/${teamId}`;
    removeWatch(ref);
    fbDb.ref(ref).off("value");
  };

  static removeTeamAdminUser = (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string
  ) => {
    fbDb.ref(`/teams/${teamId}/admin/${userId}`).set(null);
  };

  static leaveTeam = (fbDb: firebase.database.Database, teamId: string, userId: string) => {
    fbDb.ref(`/teams/${teamId}/users/${userId}`).set(null);
  };
}

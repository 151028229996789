import { IFocusWindow, IGetOpenWindows, IOpenWindow } from "@openteam/models"
import { Platform as ReactNativePlatform } from "react-native"
import * as WindowUtils from './WindowUtils'

export const Platform = ReactNativePlatform;
export const PlatformOS: string = Platform.OS
export const WebOS: string = 'web'
export const isFrameless: boolean = false
export var EnableGoogleLogin = true
export var EnableWebviewTag = false

export function setupApp() {
  console.log("setup web")

}
export function handleWindowUrl(url) {

}

export function appUpgrade() {
  WindowUtils.reload(true)

}

export const openWindow: IOpenWindow = (url, ref, options) => {
  return window.open(
    url,
    ref,
    options
  )
}

export const getOpenWindows: IGetOpenWindows = () => {
  return {}
}

export const focusWindow: IFocusWindow = (subWin, ref) => {
  subWin.focus()
}

export function focusSelf() {
  window.focus();
}

export function createTray () { }

export function destroyTray() { }

export function preventDisplaySleep() { }

export function allowDisplaySleep() { }
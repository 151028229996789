import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

import { ICallFeedback, IFeedback, IFeedbackMessage } from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { addWatch, removeWatch } from "./FireUtils";

const logger = new Logger("Fire");
export class FeedbackDb {
  static watchMyFeedback = (
    fbDb: firebase.database.Database,
    userId: string,
    callback: (doc: { [id: string]: IFeedback }) => void
  ) => {
    const ref = `/feedback/`;
    addWatch(ref);
    fbDb
      .ref(ref)
      .orderByChild(`followers/${userId}/following`)
      .equalTo(true)
      .on("value", (doc) => {
        callback(doc.val());
      });
  };
  
  static unwatchMyFeedback = (
    fbDb: firebase.database.Database,
    userId: string,
  ) => {
    const ref = `/feedback/`;
    removeWatch(ref);
    fbDb
      .ref(ref)
      .orderByChild(`followers/${userId}/following`)
      .equalTo(true)
      .off();
  };

  static getMoreFeedback = async (fbDb: firebase.database.Database, lastId, pageSize) => {
    var ref = fbDb.ref(`/feedback/`).orderByKey();

    if (lastId) {
      ref = ref.endAt(lastId);
    }

    const doc = await ref.limitToLast(pageSize).once("value");

    logger.info("getMoreFeedback ", pageSize, doc.val());
    return doc.val();
  };

  static getFeedback = async (fbDb: firebase.database.Database, feedbackId) => {
    var doc = await fbDb.ref(`/feedback/${feedbackId}`).once("value");
    var feedbackDoc = doc.val();

    return feedbackDoc;
  };

  static markReadFeedback = async (
    fbDb: firebase.database.Database,
    userId: string,
    feedbackId: string,
    lastRead: string
  ) => {
    fbDb.ref(`/feedback/${feedbackId}/followers/${userId}/lastRead`).set(lastRead);
  };

  static watchFeedbackLastId = async (
    fbDb: firebase.database.Database,
    callback: (doc) => void
  ) => {
    const ref = `/global/feedbackLastId`;
    addWatch(ref);
    fbDb.ref(ref).on("value", (snapshot) => callback(snapshot.val()));
  };

  static unwatchFeedbackLastId = async (fbDb: firebase.database.Database) => {
    const ref = `/global/feedbackLastId`;
    removeWatch(ref);
    fbDb.ref(ref).off("value");
  };

  static markFeedbackLastId = async (
    fbDb: firebase.database.Database,
    userId: string,
    feedbackId
  ) => {
    const ref = `/users/${userId}/feedbackLastId`;
    addWatch(ref);
    fbDb.ref(ref).set(feedbackId);
  };

  static watchFeedback = async (
    fbDb: firebase.database.Database,
    feedbackId: string,
    callback: (doc: IFeedback) => void
  ) => {
    const ref = `/feedback/${feedbackId}`;
    addWatch(ref);
    fbDb.ref(ref).on("value", (snapshot) => callback(snapshot.val()));
  };

  static unwatchFeedback = async (fbDb: firebase.database.Database, feedbackId) => {
    const ref = `/feedback/${feedbackId}`;
    removeWatch(ref);
    fbDb.ref(ref).off("value");
  };

  static addFeedback = async (
    fbDb: firebase.database.Database,
    userId: string,
    username: string,
    imageUrl: string | null,
    title,
    desc,
    type,
    files
  ) => {
    var feedbackId = fbDb.ref(`/feedback/`).push().key!;

    if (files) {
      await Promise.all(files.map((cu) => cu.uploadTask));
      await Promise.all(files.map((cu) => cu.loadDownloadURL()));
    }

    var msg: IFeedback = {
      feedbackId: feedbackId,
      userId: userId,
      user: {
        userId: userId,
        name: username,
        imageUrl: imageUrl,
      },
      crDate: firebase.database.ServerValue.TIMESTAMP as number,
      followers: {
        [userId]: {
          following: true,
        },
      },
      votes: {
        [userId]: true,
      },
      title: title,
      desc: desc,
      type: type,
      status: "active",
      files:
        files &&
        files.map((cu) => ({
          name: cu.file.name,
          type: cu.file.type,
          size: cu.file.size,
          url: cu.downloadUrl!,
        })),
    };

    fbDb.ref(`/feedback/${feedbackId}`).set(msg);

    FeedbackDb.markFeedbackLastId(fbDb, userId, feedbackId);

    return feedbackId;
  };

  static updateFeedback = async (
    fbDb: firebase.database.Database,
    userId: string,
    feedbackId: string,
    username: string,
    imageUrl: string | null,
    title,
    desc,
    type,
    status,
    files
  ) => {
    if (files) {
      await Promise.all(files.map((cu) => cu.uploadTask));
      await Promise.all(files.map((cu) => cu.loadDownloadURL()));
    }

    var msg: IFeedback = {
      feedbackId: feedbackId,
      userId: userId,
      user: {
        userId: userId,
        name: username,
        imageUrl: imageUrl,
      },
      crDate: firebase.database.ServerValue.TIMESTAMP as number,
      title: title,
      desc: desc,
      type: type,
      status: status,
      files:
        files &&
        files.map((cu) => ({
          name: cu.file.name,
          type: cu.file.type,
          size: cu.file.size,
          url: cu.downloadUrl!,
        })),
    };

    fbDb.ref(`/feedback/${feedbackId}`).update(msg);
  };

  static voteFeedback = async (
    fbDb: firebase.database.Database,
    userId: string,
    feedbackId: string,
    voted: boolean
  ) => {
    fbDb.ref(`/feedback/${feedbackId}/votes/${userId}`).set(voted || null);
    FeedbackDb.followFeedback(fbDb, userId, feedbackId, true);
  };

  static followFeedback = async (
    fbDb: firebase.database.Database,
    userId: string,
    feedbackId: string,
    follow: boolean
  ) => {
    var followDoc: any = null;
    if (follow) {
      followDoc = {
        following: true,
      };
    }
    fbDb.ref(`/feedback/${feedbackId}/followers/${userId}`).set(followDoc);
  };

  static commentFeedback = async (
    fbDb: firebase.database.Database,
    userId: string,
    feedbackId: string,
    username: string,
    imageUrl: string | null,
    message,
    files,
    setFollow
  ) => {
    if (files) {
      await Promise.all(files.map((cu) => cu.uploadTask));
      await Promise.all(files.map((cu) => cu.loadDownloadURL()));
    }

    const messageId = fbDb.ref(`/feedback/${feedbackId}/comments`).push().key!;

    const msg: IFeedbackMessage = {
      crDate: firebase.database.ServerValue.TIMESTAMP as number,
      userId: userId,
      user: {
        userId: userId,
        name: username,
        imageUrl: imageUrl,
      },
      message: message,
      files:
        files &&
        files.map((cu) => ({
          name: cu.file.name,
          type: cu.file.type,
          size: cu.file.size,
          url: cu.downloadUrl!,
        })),
    };

    fbDb.ref(`/feedback/${feedbackId}/comments/${messageId}`).set(msg);

    if (setFollow) {
      FeedbackDb.followFeedback(fbDb, userId, feedbackId, true);
    }
  };

  static archiveFeedback = async (fbDb: firebase.database.Database, feedbackId: string) => {
    var doc = await fbDb.ref(`/feedback/${feedbackId}`).once("value");
    var feedbackDoc = doc.val();
    fbDb.ref(`/feedbackarchive/${feedbackId}`).set(feedbackDoc);
    doc.ref.remove();
  };

  static addCallFeedback = async (fbDb: firebase.database.Database, teamId: string, userId: string, roomId: string, startTime, endTime, rating, comment) => {

    const msg: ICallFeedback = {
      crDate: Date.now(),
      teamId: teamId,
      userId: userId,
      roomId: roomId,
      startTime: startTime,
      endTime: endTime,
      rating: rating,
      comment: comment,
    };

    await fbDb.ref(`/callfeedback/`).push(msg);
  }

}

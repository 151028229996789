import React from 'react';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { Logger } from 'app/util/src/Logger';


const logger = new Logger ("NewFirebaseAuth");

interface INewFirebaseAuthProps {
  uiConfig: firebaseui.auth.Config;
  uiCallback?(ui: firebaseui.auth.AuthUI): void;
  firebaseAuth: any; // As firebaseui-web
  className?: string;
}

interface INewFirebaseAuthState {

}


class NewFirebaseAuth extends React.PureComponent<INewFirebaseAuthProps, INewFirebaseAuthState> {
  ui
  observer
  isSignedIn: boolean = false
  componentDidMount() {
    this.observer = this.props.firebaseAuth.onAuthStateChanged(user => {
      if (!user && this.isSignedIn) {
        this.ui.reset();
      }

      this.isSignedIn = !!user;
    });

    this.ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(this.props.firebaseAuth);

    // Trigger the callback if any was set.
    if (this.props.uiCallback) {
      this.props.uiCallback(this.ui);
    }
    this.ui.disableAutoSignIn();
    this.ui.start('#NEW_FIREBASE_AUTH', this.props.uiConfig);
  }

  componentWillUnmount() {
    this.observer && this.observer();
    this.observer = undefined;
  }

  render() {
    return <div id="NEW_FIREBASE_AUTH" style={{width: "100%"}}/>;
  }
}

export default NewFirebaseAuth;
import { IComputedValue } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { View } from "react-native";
import { Theme } from "../globals/ThemeColors";
import { OTText } from "./OTText";

interface IBadgeViewProps {
  badgeNum?: number;
  badgeSoftAlert?: IComputedValue<boolean>;
}
interface IBadgeViewState {}

@observer
export class BadgeView extends Component<IBadgeViewProps, IBadgeViewState> {
  render() {
    return this.props.badgeNum && this.props.badgeNum > 0 ? (
      <View
        style={{
          position: "absolute",
          top: -5,
          right: -5,
          height: 16,
          minWidth: 16,
          justifyContent: "center",
          alignItems: "center",
          paddingHorizontal: 4,
          borderRadius: 8,
          backgroundColor: Theme.BadgeColor,
        }}
      >
        <OTText style={{ color: "white", fontWeight: "bold", fontSize: 11, marginTop: -1 }}>
          {this.props.badgeNum}
        </OTText>
      </View>
    ) : !!this.props.badgeSoftAlert?.get() ? (
      <View
        style={{
          position: "absolute",
          top: -5,
          right: -5,
          height: 16,
          minWidth: 16,
          justifyContent: "center",
          alignItems: "center",
          paddingHorizontal: 4,
          borderRadius: 8,
          backgroundColor: Theme.BadgeSoftAlertColor,
        }}
      ></View>
    ) : null;
  }
}

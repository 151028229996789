import { observable } from "mobx";
import "firebase/database";
import { FireDb } from "../fire";
import { Logger } from "@openteam/app-util";
import { ITeamExternalAddress, ITeamRoomConfig, TShowMeetingModal } from "@openteam/models";
import { OTGlobals } from "../OTGlobals";
import { OTUITree } from "../OTUITree";
import { meetingRequestAlert } from "../Alert";

const logger = new Logger("MeetingManager");


export class MeetingManager {
  fbDb: firebase.database.Database;
  teamId: string;
  userId: string;
  sessionToken: string;
  showMeetingModal: TShowMeetingModal;

  @observable address?: string;

  @observable showMenu: boolean = false;
  @observable addressDoc?: ITeamExternalAddress;
  @observable showModalDialog?: boolean = false;

  constructor(
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string,
    sessionToken: string,
    showMeetingModal: TShowMeetingModal,
    address?: string
  ) {
    this.fbDb = fbDb;
    this.teamId = teamId;
    this.userId = userId;
    this.sessionToken = sessionToken;
    this.showMeetingModal = showMeetingModal;

    OTUITree.registerMeetingManager(this);

    this.setupAddress(address);
  }

  setupAddress = (address: string | undefined) => {
    if (address && this.address != address) {
      logger.debug("watchTeamExternalAddress", address);
      FireDb.watchTeamExternalAddress(this.fbDb, this.teamId, address, this.handleAddress);
    }
    this.address = address;
  };

  stop = () => {
    if (this.address)
      FireDb.unwatchTeamExternalAddress(this.fbDb, this.teamId, this.address);
  };

  handleAddress = (doc: ITeamExternalAddress) => {
    var hasNew = false;

    Object.keys(doc.waiting || {}).forEach((userId) => {
      if (!this.addressDoc?.waiting?.[userId]) {
        hasNew = true;
        meetingRequestAlert(this.teamId, userId, doc.waiting[userId].name);
      }
    });

    this.addressDoc = doc;
    logger.debug("handleAddress", doc);

    if (hasNew && !this.showModalDialog) {
      if (this.showMenu) {
        this.toggleShowMenu();
      }
      this.showModal();
    }
  };

  showModal = () => {
    logger.info("showing pending Meeting users");
    this.showModalDialog = true;
    this.showMeetingModal(this.teamId, this.hideModal);
  };

  hideModal = () => {
    this.showModalDialog = false;
  };

  toggleShowMenu = () => {
    this.showMenu = !this.showMenu;
  };

  createRoom = async () => {
    logger.debug("creating room");
    var teamData = OTGlobals.getTeamData(this.teamId);
    var meTeamUser = teamData.getTeamUser(this.userId);

    var roomConfigDoc: ITeamRoomConfig = {
      name: `${meTeamUser.name}'s Room`,
      desc: "",
      enabled: true,
      call: true,
      permanent: false,
      external: true,
    };

    var roomId = await FireDb.createRoom(
      this.fbDb,
      this.teamId,
      this.userId,
      this.sessionToken,
      roomConfigDoc
    );

    return roomId;
  };

  acceptReq = async (userId: string, roomId: string | undefined) => {
    logger.debug("accept req roomId", roomId);

    if (roomId) {
      await FireDb.inviteTeamRoom(this.fbDb, this.teamId, roomId, userId);
      await FireDb.acceptTeamExternalAddressWaiting(this.fbDb, this.teamId, this.address, userId, roomId);

      OTGlobals.analytics?.logEvent("meetingManager__acceptExternalUser");
    } else {
      logger.error("roomId not supplied to acceptReq");
    }
  };

  holdReq = (userId: string) => {
    FireDb.holdTeamExternalAddressWaiting(this.fbDb, this.teamId, this.address, userId);
  };

  rejectReq = (userId: string) => {
    FireDb.rejectTeamExternalAddressWaiting(this.fbDb, this.teamId, this.address, userId);
  };
}

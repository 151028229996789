import { autorun, action, computed, observable, set } from "mobx";
import { Logger } from "@openteam/app-util";
import { applyObjectUpdate } from "./utils/applyObjectUpdate";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { UserSettingsDb } from "./fire/UserSettingsDb";

const logger = new Logger("UserSettings");

export interface IMediaDeviceSettings {
  audio?: { [key: string]: any };
  video?: { [key: string]: any };
  audiooutput?: { [key: string]: any };
}

export interface IRemoteUserSettings {
  showOnboardingDone?: boolean;
  showOnboardingSkipped?: boolean;
  addUsersDone?: boolean;
  addUsersSkipped?: boolean;
  tutorialShown?: boolean;
  tutorialStepsSeen?: { [key: string]: boolean };
  releaseNoteIndex?: number;
  darkMode?: boolean;
  faceDetect?: boolean;
}

export interface ILocalUserSettings extends IMediaDeviceSettings {
  videoSimulcastEnabled?: boolean;
  screenshareQuality?: string;
  cameraQuality?: string;
  showStreamStatistics?: boolean;
  teamWidgetEnabled?: boolean;
  teamWidgetMaxTiles?: number;
}

export interface IUserSettings extends  ILocalUserSettings, IRemoteUserSettings {}

const userSettingDefaults = {
  videoSimulcastEnabled: true,
  screenshareQuality: "900p",
  cameraQuality: "hvga",
  showStreamStatistics: false,
  teamWidgetEnabled: false,
  teamWidgetMaxTiles: 6,
};

export class UserSettingsManager {
  fbDb?: firebase.database.Database;
  userId: string = "";

  _autorun: Record<string, any> = {};

  @observable _localSettings: ILocalUserSettings = {};
  @observable _remoteSettings: IRemoteUserSettings = {};
  _localSettingsLoaded: boolean = false;
  _remoteSettingsLoaded: boolean = false;

  @computed get localSettings() {
    return { ...userSettingDefaults, ...this._localSettings };
  }

  @computed get remoteSettings() {
    return { ...this._localSettings, ...this._remoteSettings };
  }

  constructor() {
    this.start();
  }

  updateLocalSettings = <K extends keyof ILocalUserSettings>(
    settings: Pick<ILocalUserSettings, K>
  ) => {
    //this._userSettings = { ...this._userSettings, ...settings }
    //return
    logger.debug(`local user settings before:`, this._localSettings, settings);
    applyObjectUpdate(this._localSettings, { ...this._localSettings, ...settings });
    logger.debug(`local user settings after:`, this._localSettings);
  };

  updateRemoteSettings = <K extends keyof IRemoteUserSettings>(
    settings: Pick<IRemoteUserSettings, K>
  ) => {
    //this._userSettings = { ...this._userSettings, ...settings }
    //return
    logger.debug(`remote user settings before:`, this._remoteSettings, settings);
    applyObjectUpdate(this._remoteSettings, { ...this._remoteSettings, ...settings });
    logger.debug(`remote user settings after:`, this._remoteSettings);
  };

  @action reset() {
    this.userId = "";

    this._localSettingsLoaded = false;
    this._remoteSettingsLoaded = false;
    this._localSettings = {};
    this._remoteSettings = {};
  }

  @action
  loadUserSettings = async (fbDb: firebase.database.Database, userId: string) => {
    if (this.userId == userId) {
      logger.debug(`Setting userSettings already loaded for userId=${this.userId}`);
      return;
    }
    this.fbDb = fbDb;
    this.userId = userId;

    if (userId === "") {
      logger.debug("Setting userSettings defaults");
      set(this._localSettings, {});
      set(this._remoteSettings, {});
    } else {
      for (let key of [`userSettings-${userId}`, "userSettings"]) {
        const jsonString = await AsyncStorage.getItem(key);
        const loadedSettings =
          jsonString && (JSON.parse(jsonString) as ILocalUserSettings | undefined);

        if (loadedSettings) {
          set(this._localSettings, loadedSettings);
          logger.debug(`loaded settings from key ${key}`, loadedSettings);
          break;
        }
      }

      const remoteSettingsJSON = await UserSettingsDb.getUserSettings(this.fbDb, userId);
      const loadedRemoteSettings =
        remoteSettingsJSON && (JSON.parse(remoteSettingsJSON) as IRemoteUserSettings | undefined);
      if (loadedRemoteSettings) {
        logger.debug(`loaded remote settings`, loadedRemoteSettings);

        set(this._remoteSettings, loadedRemoteSettings);
      }
      this._localSettingsLoaded = true;
      this._remoteSettingsLoaded = true;
    }
  };

  start = () => {
    this._autorun["saveLocalSettings"] = autorun(
      () => {
        const localSettings = this._localSettings;
        logger.info("saveLocalSettings", localSettings);

        if (localSettings && this.userId && this._localSettingsLoaded) {
          logger.debug(`Saving local user settings for userId=${this.userId}`);
          AsyncStorage.setItem(`userSettings-${this.userId}`, JSON.stringify(this._localSettings));
        }
      },
      { delay: 1000 }
    );

    this._autorun["saveRemoteSettings"] = autorun(
      () => {
        const remoteSettings = this._remoteSettings;
        logger.info("saveRemoteSettings", remoteSettings);

        if (remoteSettings && this.userId && this.fbDb && this._remoteSettingsLoaded) {
          logger.debug(`Saving remote user settings for userId=${this.userId}`);
          UserSettingsDb.setUserSettings(
            this.fbDb,
            this.userId,
            JSON.stringify(this._remoteSettings)
          );
        }
      },
      { delay: 1000 }
    );
  };

  stop = () => {
    Object.values(this._autorun).map((x) => x());
    this._autorun = {};
  };
}

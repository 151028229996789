import { observer } from "mobx-react";
import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import * as Analytics from '../../utils/Analytics';
import * as Fire from '../../globals/Fire';
import * as StyleConstants from '../../globals/StyleConstants';
import { Theme } from "../../globals/ThemeColors";
import { OTButton } from "../../components/buttons/OTButtons";
import { OTTouchableOpacity } from "../../components";
import { OnlineTile } from "../../components/userTile";
import { TeamAccessReqs } from "../fragments/TeamAccessReqs";
import * as ModalService from '../../components/ModalService'
import { BadgeView } from "../../components/BadgeUtil";
import { showAdminSettings } from "../fragments/settings/SettingsContainer";
import { OTEditable, OTText } from "../../components/OTText";
import { SKMegaphone, SKKnock, SKVideo } from "../../components/SVGIcons";
import { Logger } from '@openteam/app-util';
import { Feather, FontAwesome } from '@expo/vector-icons';
import {
  OTUITree,
  defaultSubTeam,
  defaultSubTeamName,
  generateTiles,
  OTGlobals,
} from "@openteam/app-core";
import { IOTRoomUser, IOTRoom, TStreamType } from "@openteam/models";
const logger = new Logger("MainOffice");


interface IMainOfficeProps {
  teamId: string
}

interface IMainOfficeState {

}

@observer
export class MainOffice extends Component<IMainOfficeProps, IMainOfficeState> {
  pttStartTime?: number;
  pttTarget?: string;
  inviteUsersNum = 3;

  startPtt = (roomId?, group?) => {
    const teamManager = OTUITree.teamManagers[this.props.teamId];
    teamManager.startPtt(roomId, group);
    this.pttStartTime = Date.now();
    this.pttTarget = group;
  };

  stopPtt = () => {
    const teamManager = OTUITree.teamManagers[this.props.teamId];
    teamManager.stopPtt();
    if (this.pttStartTime) {
      Analytics.logEvent("megaphone", {
        duration_secs: (Date.now() - this.pttStartTime) / 1000,
        ptt_target: this.pttTarget,
      });
      this.pttStartTime = undefined;
      this.pttTarget = undefined;
    }
  };

  showAddNewUser = () => {
    ModalService.showModal({
      contents: <TeamAccessReqs teamId={this.props.teamId} />,
      showContainer: true,
      showClose: true,
    });
  };

  get hasEnoughUsers() {
    const teamData = OTGlobals.getTeamData(this.props.teamId);
    return Object.keys(teamData.users).length > this.inviteUsersNum;
  }

  getSubTeamName = (subTeam) => {
    const teamData = OTGlobals.getTeamData(this.props.teamId);

    return subTeam == defaultSubTeam
      ? teamData.config?.defaultSubTeamName || defaultSubTeamName
      : teamData.subTeams[subTeam]?.name;
  };

  showTeamSetup = () => {
    showAdminSettings(this.props.teamId, "teams");
  };

  render() {
    const teamData = OTGlobals.getTeamData(this.props.teamId);
    const room = teamData.rooms["online"];
    const userSubTeam = teamData.getTeamUser(OTUITree.auth.userId).subTeam || defaultSubTeam;
    var subteams = room.subteams || {};

    var expandedSubTeams: string[] = [];
    var collapsedSubTeams: string[] = [];

    Object.keys(subteams).forEach((subTeam) => {
      if (subTeam == userSubTeam || (teamData.preferences?.displaySubteam?.[subTeam] ?? true)) {
        expandedSubTeams.push(subTeam);
      } else {
        collapsedSubTeams.push(subTeam);
      }
    });

    const mainStyles = [styles.room, { backgroundColor: Theme.OfficeBackground }];
    return (
      <View
        key={"mainOffice"}
        // @ts-expect-error dataSet not part of types
        dataSet={{ tutorial: "mainOffice" }}
        style={mainStyles}
      >
        {this.renderHeader()}

        {/* {
          this._renderSubTeam(room, 'base', room.users, room.inRoom)
        } */}

        {expandedSubTeams.map((subteam) =>
          this._renderSubTeam(room, subteam, subteams[subteam], room.inRoom)
        )}

        {collapsedSubTeams.length > 0 ? (
          <>
            <OTText style={styles.title}>Teams</OTText>
            <View style={styles.subTeamList}>
              {collapsedSubTeams.map((subteam) =>
                this._renderSubTeamMinimal(room, subteam, subteams[subteam])
              )}

              {/* {
                  teamData.isAdmin ?
                    this.renderAddTeamTile()
                    :
                    null
                } */}
            </View>
          </>
        ) : null}
      </View>
    );
  }

  renderAddTeamTile = () => {
    return (
      <OTTouchableOpacity
        style={[styles.subTeamAddButton, { backgroundColor: Theme.ButtonColor }]}
        onPress={this.showTeamSetup}
      >
        <Feather
          name="plus"
          size={18}
          style={{ color: Theme.ButtonIconColor, paddingBottom: 10 }}
        />
        <OTText
          style={{
            color: Theme.ButtonIconColor,
            fontSize: 14,
          }}
        >
          Add team
        </OTText>
      </OTTouchableOpacity>
    );
  };

  renderHeader = () => {
    const teamAccessReqUI = OTUITree.teamAccessReqUI[this.props.teamId];
    let teamData = OTGlobals.getTeamData(this.props.teamId);

    return (
      <View style={{ flexDirection: "row", alignItems: "center", paddingBottom: 5 }}>
        <OTText style={styles.title}>Main Space</OTText>
        {this.hasEnoughUsers ? (
          <View style={{ marginLeft: 10 }}>
            <OTButton
              toolTip="Add teammates"
              small={true}
              icon={
                <Feather
                  name="user-plus"
                  size={14}
                  style={{ color: Theme.ButtonColor, marginRight: 5 }}
                />
              }
              textColor={Theme.ButtonColor}
              borderColor={Theme.ButtonColor}
              backgroundColor="transparent"
              title="Add teammates"
              onPress={this.showAddNewUser}
            />
            <BadgeView badgeNum={Object.keys(teamAccessReqUI.accessReqs || {}).length} />
          </View>
        ) : null}

        {teamData.isAdmin ? (
          <View style={{ marginLeft: 10 }}>
            <OTButton
              toolTip="Setup Teams"
              small={true}
              textColor={Theme.ButtonColor}
              borderColor={Theme.ButtonColor}
              backgroundColor="transparent"
              title="Setup teams"
              onPress={this.showTeamSetup}
            />
          </View>
        ) : null}

        <View style={{ flex: 1 }}></View>
        {teamData.rooms["online"].inRoom ? (
          <OTButton
            toolTip="Talk to everyone online"
            small={true}
            icon={<SKMegaphone size={20} style={{ color: Theme.ButtonColor, marginRight: 5 }} />}
            textColor={Theme.ButtonColor}
            borderColor={Theme.ButtonColor}
            backgroundColor="transparent"
            title="Talk to everyone"
            onPressIn={() => this.startPtt("online")}
            onPressOut={() => this.stopPtt()}
          />
        ) : null}
      </View>
    );
  };

  _renderSubTeam = (
    room: IOTRoom,
    subTeam: string,
    users: { [id: string]: IOTRoomUser },
    inRoom: boolean
  ) => {
    const teamManager = OTUITree.teamManagers[this.props.teamId];
    const teamData = teamManager.teamData;
    const userSubTeam = teamData.getTeamUser(OTUITree.auth.userId).subTeam || defaultSubTeam;
    const callRequestUIState = OTUITree.callRequestUIStates[this.props.teamId];

    return (
      <View key={`online-${subTeam}`} style={styles.subTeamContainer}>
        {subTeam != "base" ? (
          <View style={{ flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
            <OTEditable
              style={[styles.subTeamTitle]}
              value={this.getSubTeamName(subTeam)}
              disabled={!teamData.isAdmin}
              onEdited={(name) => Fire.updateSubTeam(this.props.teamId, subTeam, name)}
            />
            {subTeam != userSubTeam ? (
              <OTTouchableOpacity
                data-tip="Unpin team"
                style={styles.pinButton}
                onPress={() => teamManager.setDisplaySubTeam(subTeam, false)}
              >
                <FontAwesome name="star" size={18} style={{ color: Theme.ButtonColor }} />
              </OTTouchableOpacity>
            ) : null}

            {inRoom ? (
              <OTTouchableOpacity
                data-tip="Knock on everyone in this team"
                style={styles.pttButton}
                onPress={() => teamManager.knockSubTeam(subTeam)}
              >
                <SKKnock data-tutorial="teamknock" style={{ color: Theme.ButtonColor }} size={16} />
              </OTTouchableOpacity>
            ) : null}

            <OTTouchableOpacity
              data-tip="Call everyone in this team"
              style={styles.pttButton}
              onPress={() => teamManager.callSubTeam(subTeam, this.getSubTeamName(subTeam))}
            >
              <SKVideo
                data-tutorial="teamcall"
                style={{
                  color: callRequestUIState.callsByKey[subTeam]
                    ? Theme.ButtonNegativeColor
                    : Theme.ButtonColor,
                }}
                size={16}
              />
            </OTTouchableOpacity>

            <OTTouchableOpacity
              data-tip="Push To Talk to everyone in this team"
              style={styles.pttButton}
              onPressIn={() => this.startPtt("online", subTeam)}
              onPressOut={() => this.stopPtt()}
            >
              <SKMegaphone
                data-tutorial="megaphone"
                style={{ color: Theme.ButtonColor }}
                size={16}
              />
            </OTTouchableOpacity>
          </View>
        ) : null}

        <View style={{ flexDirection: "row", flexWrap: "wrap", paddingTop: 10, margin: -5 }}>
          {generateTiles(
            Object.keys(users),
            teamData.pttStreams
          ).map(({ userId, streamType, streamId }) =>
            this._renderUserTile(room, userId, users[userId], streamType, streamId)
          )}

          {!this.hasEnoughUsers && userSubTeam == subTeam ? (
            <View>
              {this.renderAddUser()}
              <BadgeView
                badgeNum={Object.keys(teamManager.teamAccessReqManager.accessReqs || {}).length}
              />
            </View>
          ) : null}
        </View>
      </View>
    );
  };

  renderAddUser = () => {
    return (
      <OTTouchableOpacity
        data-tip="Add teammates"
        data-tutorial="addUser"
        analyticsEvent="user_add_show"
        onPress={this.showAddNewUser}
        style={[styles.addUserButton, { backgroundColor: Theme.ButtonColor }]}
      >
        <Feather
          name="user-plus"
          size={18}
          style={{ color: Theme.ButtonIconColor, paddingBottom: 20 }}
        />
        <OTText
          style={{
            color: Theme.ButtonIconColor,
            fontSize: 14,
            textAlign: "center",
          }}
        >
          Add teammates
        </OTText>
      </OTTouchableOpacity>
    );
  };

  _renderUserTile(
    room: IOTRoom,
    userId: string,
    teamUser: IOTRoomUser,
    tileType: TStreamType = "camera",
    streamId?: string
  ) {
    const key = `${userId}:${tileType}`;
    return (
      <OnlineTile
        id={key}
        key={key}
        teamId={this.props.teamId}
        userId={userId}
        roomUser={teamUser}
        streamId={streamId}
        width={173}
        height={152}
      />
    );
  }

  _renderSubTeamMinimal = (
    room: IOTRoom,
    subTeam: string,
    users: { [id: string]: IOTRoomUser }
  ) => {
    const teamManager = OTUITree.teamManagers[this.props.teamId];
    const teamData = teamManager.teamData;
    
    return (
      <View
        key={`online-minimal-${subTeam}`}
        style={[styles.subTeamMinimalContainer, { backgroundColor: Theme.RoomBackground }]}
      >
        <View style={styles.minimalHeader}>
          <OTText style={[styles.smallTitle, { flex: 1 }]} numberOfLines={1}>
            {this.getSubTeamName(subTeam)}
          </OTText>
          <OTTouchableOpacity
            data-tip="Pin team"
            style={styles.pinButton}
            onPress={() => teamManager.setDisplaySubTeam(subTeam, true)}
          >
            <Feather name="star" size={18} style={{ color: Theme.ButtonColor }} />
          </OTTouchableOpacity>
        </View>
        <View style={{ flexDirection: "row", flexWrap: "wrap", paddingTop: 8, margin: -5 }}>
          {generateTiles(
            Object.keys(users),
            teamData.pttStreams
          ).map(({ userId, streamType, streamId }) =>
            this._renderMinimalUserTile(room, userId, users[userId], streamType, streamId)
          )}
        </View>
      </View>
    );
  };

  _renderMinimalUserTile = (
    room: IOTRoom,
    userId: string,
    teamUser: IOTRoomUser,
    tileType: TStreamType = "camera",
    streamId?: string
  ) => {
    const key = `${userId}:${tileType}`;
    return (
      <OnlineTile
        id={key}
        key={key}
        teamId={this.props.teamId}
        userId={userId}
        roomUser={teamUser}
        streamId={streamId}
        height={30}
        width={30}
      />
    );
  };
}


const styles = StyleSheet.create({
  room: {
    maxWidth: "100%",
    padding: 10,
    borderRadius: Theme.curviness * 1.5,
    marginHorizontal: 10,
  },
  addUserButton: {
    margin: 5,
    height: 152,
    width: 145,
    borderRadius: Theme.curviness,
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontWeight: '600',
    fontSize: 18,
  },
  subTeamTitle: {
    fontWeight: '600',
    fontSize: 16,
  },
  smallTitle: {
    fontWeight: '500',
    fontSize: 14,
  },
  subTeamContainer: {
    marginTop: 10
  },
  subTeamMinimalContainer: {
    borderRadius: Theme.curviness,
    padding: 7,
    height: 70,
    margin: 5,
    ...StyleConstants.LightShadow
  },
  subTeamAddButton: {
    borderRadius: Theme.curviness,
    height: 69,
    width: 134,
    margin: 5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  minimalHeader: {
    flexDirection: 'row',
  },
  subTeamList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 10,
    margin: -5,
  },
  pttButton: {
    backgroundColor: 'white',
    borderRadius: Theme.curviness / 2,
    height: 25,
    width: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
  pinButton: {
    // backgroundColor: Theme.ButtonColor,
    // height: 18,
    // width: 18,
    // borderRadius: 9,
    marginLeft: 10,
    alignItems: "center",
    justifyContent: "center"
  },
});
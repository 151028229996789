import { observer } from "mobx-react";
import React, { Component, useEffect, useState } from "react";
import { Theme } from "../../globals/ThemeColors";
import { getStringColour } from "../../utils/OTUtils";
import * as StyleConstants from "../../globals/StyleConstants";
import * as ModalService from "../../components/ModalService";
import JoinTeam from "./joinTeam";
import { ImageBackground, View } from "react-native";
import { OTTouchableOpacity } from "../buttons";
import { OTText } from "../OTText";
import { Feather, FontAwesome } from "@expo/vector-icons";
import { BadgeView } from "../BadgeUtil";
import styles from "./teamStyles";
import { OTGlobals, OTUITree } from "@openteam/app-core";
import { Logger } from "@openteam/app-util";

const logger = new Logger("TeamList");

interface ITeamListProps {
  showName?: boolean;
  setCurrentTeam: (teamId: string) => void;
  showAdd?: boolean;
  onAddOpen?: () => void;
}

interface ITeamListState {}

const TeamList: React.FC<ITeamListProps> = observer((props) => {
  const defaultVals = {
    badgeNum: 0,
    requesting: true,
    teamName: "",
    teamPath: "",
    iconLetter: "",
    iconImageUrl: "",
    iconColour: StyleConstants.PrimaryColour,
  };

  const [teamMenu, setTeamMenu] = useState({});

  const teamAccessIndex = OTUITree.userManager.teamAccessIndex;

  useEffect(() => {
    //    let isMounted = true;
    const t = Object.entries(teamAccessIndex).reduce(
      (obj, [teamId, teamPath]) => {
        const teamData = OTGlobals.getUnsafeTeamData(teamId);

        const data = {
          ...defaultVals,
          ...{
            teamName: OTUITree.userManager.teamAccessIndex[teamId],
            teamPath: OTUITree.userManager.teamAccessIndex[teamId],
          },
          ...(teamData
            ? {
                badgeNum: teamData.badgeNum,
                requesting: false,
                teamName: teamData.teamName,
                teamPath: teamData.teamPath,
                iconImageUrl: teamData.iconImageUrl,
              }
            : {}),
          ...(typeof teamPath === "string"
            ? {
                iconLetter: teamPath.charAt(0).toUpperCase(),
                iconColour: getStringColour(teamPath),
              }
            : {}),
        };

        return { ...obj, [teamId]: data };
      },
      [teamAccessIndex]
    );
    setTeamMenu(t);
  }, [teamAccessIndex]);

  const addTeam = () => {
    ModalService.showModal({
      contents: <JoinTeam onClose={ModalService.dismissModal} />,
      showContainer: true,
      showClose: true,
    });

    props.onAddOpen && props.onAddOpen();
  };

  const renderMenuTeam = (teamId: string, teamPath: string) => {
    const userManager = OTUITree.userManager;

    const isSelected = teamId == userManager.currentTeamId;

    const data = teamMenu[teamId] || { ...defaultVals, teamName: teamPath, teamPath };

    return (
      <OTTouchableOpacity
        key={"menuteam" + teamId}
        data-tip={teamPath}
        style={{ flexDirection: "row", alignItems: "center", marginVertical: 4 }}
        onPress={() => props.setCurrentTeam(teamId)}
      >
        <View
          style={[styles.teamIconContainer, isSelected ? styles.teamIconContainerSelected : null]}
        >
          <ImageBackground
            source={{ uri: data.iconImageUrl }}
            imageStyle={{
              borderRadius: Theme.curviness / 2,
            }}
            style={[
              styles.teamIcon,
              {
                backgroundColor: data.iconColour + "44",
              },
            ]}
          >
            {data.iconImageUrl ? null : (
              <OTText style={{ fontSize: 20, fontWeight: "bold", color: data.iconColour }}>
                {data.iconLetter}
              </OTText>
            )}
            <BadgeView badgeNum={data.badgeNum} />
            {data.requesting ? (
              <FontAwesome
                name="exclamation-circle"
                size={20}
                style={{
                  position: "absolute",
                  top: -5,
                  left: -5,
                  color: "#E69435",
                }}
              />
            ) : null}
          </ImageBackground>
        </View>
        {props.showName ? (
          <OTText
            style={{ paddingLeft: 10, fontSize: 16, fontWeight: "bold", color: Theme.MainText }}
          >
            {data.teamName}
          </OTText>
        ) : null}
      </OTTouchableOpacity>
    );
  };

  return (
    <View
      style={[
        !props.showName && {
          backgroundColor: Theme.HeaderBackground,
          paddingHorizontal: 11,
        },
      ]}
    >
      {Object.keys(teamAccessIndex).map((teamId) =>
        renderMenuTeam(teamId, teamAccessIndex[teamId])
      )}

      {props.showAdd ? (
        <OTTouchableOpacity style={styles.teamIconContainer} onPress={addTeam}>
          <View style={[styles.teamIcon, { backgroundColor: Theme.ButtonColor }]}>
            <Feather name="plus" size={20} style={{ color: Theme.ButtonIconColor }} />
          </View>
          {props.showName ? (
            <OTText
              style={{
                paddingLeft: 10,
                fontSize: 16,
                fontWeight: "bold",
                color: Theme.MainText,
              }}
            >
              Create new space
            </OTText>
          ) : null}
        </OTTouchableOpacity>
      ) : null}
    </View>
  );
});

@observer
class TeamListv1 extends Component<ITeamListProps, ITeamListState> {
  constructor(props) {
    super(props);

    this.state = {};
  }
  addTeam = () => {
    ModalService.showModal({
      contents: <JoinTeam onClose={ModalService.dismissModal} />,
      showContainer: true,
      showClose: true,
    });

    this.props.onAddOpen && this.props.onAddOpen();
  };

  render() {
    const userManager = OTUITree.userManager;
    return (
      <View
        style={[
          !this.props.showName && {
            backgroundColor: Theme.HeaderBackground,
            paddingHorizontal: 11,
          },
        ]}
      >
        {Object.keys(userManager.teamAccessIndex).map((teamId) =>
          this.renderMenuTeam(teamId, userManager.teamAccessIndex[teamId])
        )}

        {this.props.showAdd ? (
          <OTTouchableOpacity style={styles.teamIconContainer} onPress={this.addTeam}>
            <View style={[styles.teamIcon, { backgroundColor: Theme.ButtonColor }]}>
              <Feather name="plus" size={20} style={{ color: Theme.ButtonIconColor }} />
            </View>
            {this.props.showName ? (
              <OTText
                style={{
                  paddingLeft: 10,
                  fontSize: 16,
                  fontWeight: "bold",
                  color: Theme.MainText,
                }}
              >
                Create new space
              </OTText>
            ) : null}
          </OTTouchableOpacity>
        ) : null}
      </View>
    );
  }

  renderMenuTeam = (teamId: string, teamPath: string) => {
    const userManager = OTUITree.userManager;

    const isSelected = teamId == userManager.currentTeamId;
    const teamData = OTGlobals.getTeamData(teamId);
    var badgeNum = 0;
    var iconImageUrl: string | undefined = undefined;
    var requesting = true;
    var iconLetter = "";
    var iconColour = StyleConstants.PrimaryColour;
    var teamName = teamPath;

    if (teamData) {
      teamPath = teamData.teamPath!;
      teamName = teamData.teamName!;
      badgeNum = teamData.badgeNum;
      iconImageUrl = teamData.iconImageUrl;
      requesting = false;
    }

    if (typeof teamPath === "string") {
      iconLetter = teamPath.charAt(0).toUpperCase();
      iconColour = getStringColour(teamPath);
    }

    return (
      <OTTouchableOpacity
        key={"menuteam" + teamId}
        data-tip={teamPath}
        style={{ flexDirection: "row", alignItems: "center", marginVertical: 4 }}
        onPress={() => this.props.setCurrentTeam(teamId)}
      >
        <View
          style={[styles.teamIconContainer, isSelected ? styles.teamIconContainerSelected : null]}
        >
          <ImageBackground
            source={{ uri: iconImageUrl }}
            imageStyle={{
              borderRadius: Theme.curviness / 2,
            }}
            style={[
              styles.teamIcon,
              {
                backgroundColor: iconColour + "44",
              },
            ]}
          >
            {iconImageUrl ? null : (
              <OTText style={{ fontSize: 20, fontWeight: "bold", color: iconColour }}>
                {iconLetter}
              </OTText>
            )}
            <BadgeView badgeNum={badgeNum} />
            {requesting ? (
              <FontAwesome
                name="exclamation-circle"
                size={20}
                style={{
                  position: "absolute",
                  top: -5,
                  left: -5,
                  color: "#E69435",
                }}
              />
            ) : null}
          </ImageBackground>
        </View>
        {this.props.showName ? (
          <OTText
            style={{ paddingLeft: 10, fontSize: 16, fontWeight: "bold", color: Theme.MainText }}
          >
            {teamName}
          </OTText>
        ) : null}
      </OTTouchableOpacity>
    );
  };
}

export default TeamList;

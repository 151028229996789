import events from "events";
import { hashObject } from "react-hash-string";
import { Logger } from "@openteam/app-util";
import { ITeamUser, ITeamRoom, ITeamRoomUser, IOTRoom, IOTRoomUser } from "@openteam/models";
import { OTGlobals } from "./OTGlobals";
import { FireDb } from "./fire/FireDb";
import { userIsOnline } from "./utils/userIsOnline";
import { applyObjectUpdate } from "./utils/applyObjectUpdate";
import { roomChangeAlert } from "./Alert";

const logger = new Logger("RoomManager");

export const defaultSubTeam = "newarrivals";
export const defaultSubTeamName = "New Arrivals";

interface ITeamDocRoom {
  rooms?: Record<string, ITeamRoom>;
  users: Record<string, ITeamUser>;
}

export class RoomManager extends events.EventEmitter {
  fbDb: firebase.database.Database;
  userId: string;
  teamId: string;

  _doc?: ITeamDocRoom;
  _reprocessTimeout;
  _userRooms: Record<string, string> = {};
  _currentRoomIds: string[] = [];
  _initialised: boolean = false

  constructor(fbDb: firebase.database.Database, userId: string, teamId: string) {
    super();
    this.fbDb = fbDb;
    this.userId = userId;
    this.teamId = teamId;
  }

  getUserRoom = (userId: string) => {
    return this._userRooms[userId];
  };

  handleDoc(doc: ITeamDocRoom) {
    this._doc = doc;
    if (this._reprocessTimeout) {
      clearTimeout(this._reprocessTimeout);
      delete this._reprocessTimeout;
    }
    return this._processDoc();
  }

  _processDoc() {
    const doc = this._doc;

    if (!doc) {
      return;
    }
    const { online, offline } = this._filterUsers(doc);

    this._doOtherRooms(doc);
    this._doOnlineRoom(doc, online);
    this._doOfflineRoom(doc, offline);

    const activeRooms = ["online", ...this._currentRoomIds, "offline"];
    this._removeInactiveRooms(activeRooms);

    var myRoomId = this._userRooms[this.userId];
    logger.debug(`teamId=${this.teamId} myRoomId=${myRoomId}`);

    const teamData = OTGlobals.getTeamData(this.teamId);

    if (teamData.currentRoomId != myRoomId) {
      logger.info(`roomchanged ${teamData.currentRoomId} -> ${myRoomId}`)
      this.emit("roomchanged", myRoomId, teamData.currentRoomId);
      teamData.currentRoomId = myRoomId;
    }

    this._initialised = true

    return myRoomId ? teamData.rooms[myRoomId] : undefined;
  }

  _getRooms = (doc): { [id: string]: ITeamRoom } => {
    return { ...(doc.rooms || {}) };
  };

  _doOtherRooms(doc: ITeamDocRoom) {
    let rooms = this._getRooms(doc);

    const teamData = OTGlobals.getTeamData(this.teamId);

    var userRooms: { [id: string]: string } = {};
    var currentRoomIds: string[] = [];
    var nextOffline: number | undefined = undefined;
    const offlineCutoff = new Date().getTime() - FireDb.disconnectLeeway;

    Object.keys(rooms).forEach((roomId) => {
      var room = rooms[roomId];
      var config = room.config;
      var users = room.users || {};
      var isCall = config?.call;

      var OTUsers: { [id: string]: IOTRoomUser } = {};
      var onlineUsers: string[] = [];

      const inRoom = users[this.userId] != undefined;

      if (inRoom) {
        OTUsers[this.userId] = this._getIOTRoomUser(users[this.userId]);
      }

      Object.keys(users).forEach((userId) => {
        var user: ITeamUser | ITeamRoomUser = users[userId];

        // this is to catch the situation where i have an incomplete userdoc in a room
        if (!user?.id) {
          return;
        }

        // if (user.id != this.userId || user.currentSessionToken == OTUITree.auth.sessionToken) {
        //     userRooms[userId] = roomId
        // }

        userRooms[userId] = roomId;

        if (!isCall) {
          OTUsers[userId] = this._getIOTRoomUser(this._doc!.users[userId]);
          OTUsers[userId].currentSessionToken = user.currentSessionToken;
        } else {
          OTUsers[userId] = this._getIOTRoomUser(user);
        }

        if (
          OTUsers[userId].online ||
          OTUsers[userId].inLeeway ||
          (!isCall && OTUsers[userId].hasMobile)
        ) {
          onlineUsers.push(userId);
        }

        if (OTUsers[userId].inLeeway && user.status) {
          const offlineIn = user.status.last_changed - offlineCutoff;

          if (!nextOffline || nextOffline > offlineIn) {
            nextOffline = offlineIn;
          }

          if (nextOffline && !this._reprocessTimeout) {
            this._reprocessTimeout = setTimeout(() => {
              logger.debug("Reprocessing doc from timeout");
              delete this._reprocessTimeout;
              this._processDoc();
            }, nextOffline);
          }
        }
      });

      if (config?.permanent || inRoom || onlineUsers.length > 0) {
        this._writeRoom({
          roomId,
          isActive: onlineUsers.length > 0,
          inRoom: inRoom,
          users: OTUsers,
          config: config,
        });
        // Need to keep track of which rooms we've actually updated
        // So that we can accurately delete orphaned rooms OTData
        currentRoomIds.push(roomId);
      }
    });

    this._userRooms = userRooms;
    this._currentRoomIds = currentRoomIds;
    //logger.debug("Completed processing other rooms", this._currentRoomIds, this._userRooms, teamData.rooms)
  }

  _getIOTRoomUser(roomUser: ITeamRoomUser | ITeamUser): IOTRoomUser {
    const { isOnline, inLeeway } = userIsOnline(roomUser);
    return {
      userId: roomUser.id,
      name: roomUser.name,
      imageUrl: roomUser.imageUrl || null,
      online: isOnline,
      inLeeway: inLeeway,
      hasMobile: Object.keys(roomUser.device || {}).length > 0,
      inBackground: roomUser.status?.inBackground || false,
      idle: roomUser.status?.idle || false,
      currentSessionToken: roomUser.currentSessionToken || null,
      sessionToken: roomUser.status?.sessionToken || null,
      status: roomUser.status,
    };
  }

  _doOnlineRoom(doc, onlineUsers: ITeamUser[]) {
    logger.debug("Processing onlineRoom");
    let rooms = this._getRooms(doc);

    let users: { [id: string]: IOTRoomUser } = {};
    let subteams: { [id: string]: { [id: string]: IOTRoomUser } } = {};
    let inRoom = false;

    onlineUsers.forEach((user) => {
      if (!this._userRooms[user.id]) {
        // logger.debug(`online user ${user.name} subteam ${user.subTeam}`)
        var userSubTeam = user.subTeam || defaultSubTeam;

        if (!subteams[userSubTeam]) {
          subteams[userSubTeam] = {};
        }
        subteams[userSubTeam][user.id] = this._getIOTRoomUser(user);

        if (user.id == this.userId) {
          inRoom = true;
        }
      }
    });

    this._writeRoom({
      roomId: "online",
      inRoom,
      isActive: onlineUsers.length > 0,
      users,
      subteams,
    });
    logger.debug("Completed processing onlineRoom");

    return ["online"];
  }

  _doOfflineRoom(doc: ITeamDocRoom, offlineUsers: ITeamUser[]) {
    const users: { [id: string]: IOTRoomUser } = offlineUsers.reduce(
      (accumulator, currentValue) => {
        accumulator[currentValue.id] = this._getIOTRoomUser(currentValue);
        return accumulator;
      },
      {}
    );

    this._writeRoom({
      roomId: "offline",
      inRoom: users[this.userId] != undefined,
      isActive: false,
      users,
    });

    return ["offline"];
  }

  _filterUsers(doc: ITeamDocRoom) {
    let online: ITeamUser[] = [];
    let offline: ITeamUser[] = [];
    const offlineCutoff = new Date().getTime() - FireDb.disconnectLeeway;
    var nextOffline;
    Object.values(doc.users).forEach((user) => {
      if (Object.keys(user.device || {}).length > 0) {
        if (user.id == this.userId) {
          online.unshift(user);
        } else {
          online.push(user);
        }
      } else if (!user.status) {
        offline.push(user);
      } else if (
        user.status.sessionToken &&
        user.status.sessionToken in (user.status.activeSessions || {})
      ) {
        if (user.id == this.userId) {
          online.unshift(user);
        } else {
          online.push(user);
        }
      } else if (user.status.last_changed > offlineCutoff) {
        if (user.id == this.userId) {
          online.unshift(user);
        } else {
          online.push(user);
        }

        const offlineIn = user.status.last_changed - offlineCutoff;
        if (!nextOffline || nextOffline > offlineIn) {
          nextOffline = offlineIn;
        }
      } else {
        offline.push(user);
      }
    });

    if (nextOffline && !this._reprocessTimeout) {
      this._reprocessTimeout = setTimeout(() => {
        logger.debug("Reprocessing doc from timeout");
        delete this._reprocessTimeout;
        this._processDoc();
      }, nextOffline);
    }

    return { online, offline };
  }

  _removeInactiveRooms(activeRooms) {
    const teamData = OTGlobals.getTeamData(this.teamId);

    Object.keys(teamData.rooms).forEach((roomId) => {
      if (!activeRooms.includes(roomId)) {
        delete teamData.rooms[roomId];
      }
    });
  }

  _writeRoom(obj: IOTRoom) {
    const { roomId } = obj;
    const hashVal = hashObject(obj);
    const teamData = OTGlobals.getTeamData(this.teamId);

    logger.debug("writing room,", obj)

    if (!teamData.rooms[roomId] || teamData.rooms[roomId].hashVal != hashVal) {
      var newRoomState = { ...obj, hashVal };

      if (this._initialised) {
        roomChangeAlert(this.userId, this.teamId, newRoomState);
      }

      logger.debug("updating room for ", roomId);

      if (!teamData.rooms[roomId]) {
        teamData.rooms[roomId] = newRoomState;
        //logger.debug("setting room for ", roomId, newRoomState)
      } else {
        applyObjectUpdate(teamData.rooms[roomId], newRoomState);
      }
    }
  }
}

import { StyleSheet } from 'react-native';
import { Theme } from '../../globals/ThemeColors';

const styles = StyleSheet.create({

    teamIcon: {
        height: 43,
        width: 43,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: Theme.curviness / 2,
    },
    teamIconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginVertical: 6,
        padding: 4,
    },
    teamIconContainerSelected: {
        padding: 3,
        borderWidth: 1,
        borderRadius: Theme.curviness / 2 + 3,
        borderColor: Theme.MenuTeamOutlineColour,
    },
})

export default styles;

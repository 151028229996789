import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Switch } from 'react-native';
import { View, StyleSheet } from 'react-native';
import Select from 'react-select';
import { debounce } from 'throttle-debounce';
import * as Analytics from '../../../utils/Analytics';
import { ImageSelector } from '../../../components/ImageSelector';
import { OTGlobals, MediaDeviceManager, SCREENSHARE_SIZES, VIDEO_SIZES, FireUtils, TeamManagerDb, OTUITree, WebcamStream } from "@openteam/app-core";
import { OTButton } from '../../../components/buttons/OTButtons';
import { SegmentedControl } from '../../../components/SegmentedControl';
import * as StyleConstants from '../../../globals/StyleConstants';
import { OTText, OTTextInput } from '../../../components/OTText';
import { Theme } from '../../../globals/ThemeColors';
import { Logger } from "@openteam/app-util";
import { Feather } from '@expo/vector-icons';
import { getRTDB } from 'app/webapp/src/globals/Fire/util';
import { signOut } from "../../../globals/app";
import { reaction } from 'mobx';
import { UserVideo } from 'app/webapp/src/components/userTile';
import { VolumeBar } from 'app/webapp/src/components/userTile/Volume';
import { playTestAudio } from 'app/webapp/src/utils/Notify.web';
import { preventDisplaySleep } from 'app/webapp/src/utils/platform';

const logger = new Logger('UserSettings')

interface IUserSettingsProps {
    teamId: string
    onClose: () => void
}

interface IUserSettingsState {
    name: string
    subTeam?: string
    uploading: boolean
    error?: string
    dropError?: string
    darkMode?: boolean
}

const megaByte = 1048576
const maxUploadSize = 500000

@observer
export class UserSettings extends Component<IUserSettingsProps, IUserSettingsState> {
  constructor(props) {
    super(props);
    const teamData = OTGlobals.getTeamData(this.props.teamId);
    const teamUserDoc = teamData.me;

    this.state = {
      name: teamUserDoc.name,
      subTeam: teamUserDoc.subTeam,
      uploading: false,
      darkMode: OTGlobals.remoteUserSettings.darkMode,
    };
  }

  onFiles = (e) => {
    var files = e.target.files;
    var file = files[0];

    if (!file || file.size > maxUploadSize) {
      this.setState({
        dropError: `Maximum file size is ${(maxUploadSize / megaByte).toFixed(
          2
        )}MB - Uploaded Size: ${(file.size / megaByte).toFixed(2)}MB`,
      });
      return;
    }
    this.setState({ uploading: true });
    FireUtils.uploadUserFile(this.props.teamId, OTUITree.auth.userId, "profile", file, (url) => {
      this.setState({ uploading: false });

      TeamManagerDb.updateTeamUser(getRTDB(), this.props.teamId, OTUITree.auth.userId, {
        imageUrl: url,
      });
    });
  };

  onNameChanged = (name) => {
    this.setState(
      {
        name: name,
      },
      () => this.saveName(name)
    );
  };

  saveName = debounce(500, () => {
    TeamManagerDb.updateTeamUser(getRTDB(), this.props.teamId, OTUITree.auth.userId, {
      name: this.state.name.trim(),
    });
  });

  onSubTeamChanged = (tag) => {
    this.setState({
      subTeam: tag?.value,
    });

    TeamManagerDb.updateTeamUser(getRTDB(), this.props.teamId, OTUITree.auth.userId, {
      subTeam: tag?.value || null,
    });
    Analytics.logEvent("usersettings_subteam_changed");
  };

  leaveTeam = () => {
    confirm("Are you sure you want to leave the team?") &&
      OTUITree.teamManagers[this.props.teamId].leaveTeam();
    OTUITree.userManager.unsetCurrentTeam(this.props.teamId);
    this.props.onClose();
  };

  setDarkMode = (darkModeString: "auto" | "dark" | "light") => {
    const darkModeValues = {
      auto: undefined,
      dark: true,
      light: false,
    };

    const darkMode = darkModeValues[darkModeString];

    logger.info(`Setting darkMode to ${darkModeString}, darkMode ${darkMode}`);

    this.setState({ darkMode });
    OTGlobals.userSettingsManager.updateRemoteSettings({ darkMode });
  };

  signOut = () => {
   signOut(false);
  };

  render() {
    const teamData = OTGlobals.getTeamData(this.props.teamId);
    if (!teamData) {
      return <View />;
    }

    const teamUserDoc = teamData.me;

    var subTeamOptions = Object.keys(teamData.subTeams).map((value) => ({
      value: value,
      label: teamData.subTeams[value]?.name,
    }));

    return (
      <View style={{}}>
        <View style={styles.settingsSection}>
          <OTText style={styles.title}>Enter your name</OTText>

          <OTTextInput value={this.state.name} onChangeText={this.onNameChanged} />
        </View>
        <View style={styles.settingsSection}>
          <OTText style={styles.title}>Set your profile picture</OTText>

          <ImageSelector
            width={240}
            height={180}
            loading={this.state.uploading}
            imageUrl={teamUserDoc.imageUrl}
            onFiles={this.onFiles}
          />

          {this.state.dropError ? (
            <OTText style={{ color: "red", textAlign: "center", paddingBottom: 10 }}>
              {this.state.dropError}
            </OTText>
          ) : null}
        </View>

        <View style={styles.settingsSection}>
          <OTText style={styles.title}>Your Team</OTText>
          <View style={{ width: 300 }}>
            <Select
              value={
                this.state.subTeam && {
                  value: this.state.subTeam,
                  label: teamData.subTeams[this.state.subTeam]?.name,
                }
              }
              onChange={this.onSubTeamChanged}
              options={subTeamOptions}
              menuPortalTarget={document.getElementById("otbody")}
              placeholder="Select Team"
              isSearchable={false}
              isClearable={true}
            />
          </View>
        </View>

        <View style={[styles.settingsSection]}>
          <OTText style={styles.title}>Dark Mode</OTText>
          <View style={{ width: 300 }}>
            <SegmentedControl
              segments={{ auto: "Auto", light: "Light", dark: "Dark" }}
              selectedSegment={
                this.state.darkMode == undefined
                  ? "auto"
                  : this.state.darkMode == true
                  ? "dark"
                  : "light"
              }
              onChange={this.setDarkMode}
            />
          </View>
        </View>

        <View style={styles.settingsSection}>
          <OTText style={styles.title}>Signed In Using: {teamUserDoc.email}</OTText>
          <View style={{ flexDirection: "row" }}>
            <OTButton
              small={true}
              icon={
                <Feather
                  name="log-out"
                  style={{
                    color: Theme.ButtonNegativeColor,
                    marginRight: 5,
                  }}
                />
              }
              textColor={Theme.ButtonNegativeColor}
              borderColor={Theme.ButtonNegativeColor}
              backgroundColor={"transparent"}
              title={"Sign Out"}
              onPress={this.signOut}
            />
          </View>
        </View>
      </View>
    );
  }
}



interface IUserMediaSettingsProps { }
interface IUserMediaSettingsState {
    devices: { [kind: string]: MediaDeviceInfo[] }
    hasAudio: boolean
    hasVideo: boolean
    hasOutput: boolean
    enableTestStreams: boolean
    camStream?: WebcamStream;
    micStream?: WebcamStream;
    volume?: number
}

@observer
export class UserMediaSettings extends Component<IUserMediaSettingsProps, IUserMediaSettingsState> {
    _reaction: Record<string, any> = {};

    constructor(props) {
      super(props)

      const enableTestStreams = OTGlobals.auth._userManager ? true : false

        this.state = {
            devices: { audioinput: [], videoinput: [], audiooutput: [] },
            hasAudio: false,
            hasVideo: false,
            hasOutput: false,
            enableTestStreams: enableTestStreams
        }
    }

    componentDidMount = () => {
      MediaDeviceManager.updateMediaDevices();
      this.updateMediaDevices();
      this.getTestStream();

      this.setupReactions();
    }

  componentWillUnmount = () => {
    logger.debug("userMediaSettings closed, shutting down test streams")
    navigator.mediaDevices.removeEventListener?.('devicechange', this.updateMediaDevices);

    this.stopTestStream()
  }

  getTestStream = async () => {
    if (this.state.enableTestStreams) {

      const camStream = new WebcamStream(OTGlobals.auth.userManager.currentTeamId!, OTUITree.auth.userId, OTGlobals.mediaDevices);
      await camStream.enableVideo();

      const micStream = new WebcamStream(OTGlobals.auth.userManager.currentTeamId!, OTUITree.auth.userId, OTGlobals.mediaDevices);
      await micStream.enableAudio();
      micStream.on("volume_change", (volume) => {this.setState({volume})})

      this.setState({ camStream, micStream });
    }
  }

  stopTestStream = () => {
    this.state.camStream?.shutdown();
    this.state.micStream?.shutdown();

    this.setState({ camStream: undefined, micStream: undefined });
    Object.values(this._reaction).map((x) => x());
  }


  setupReactions = () => {
    this._reaction["_devicesChangedReaction"] = reaction(
      () => {
        return [OTGlobals.mediaDevices.audio, OTGlobals.mediaDevices.video];
      },
      async () => {
        logger.info("_devicesChangedReaction", OTGlobals.mediaDevices)
        if (this.state.camStream) {
          await this.state.camStream.updateSettings(OTGlobals.mediaDevices);
        }
        if (this.state.micStream) {
          await this.state.micStream.updateSettings(OTGlobals.mediaDevices);
        }
      }
    );

    this._reaction["_cameraQualityChangedReaction"] = reaction(
      () => {
        return OTGlobals.localUserSettings.cameraQuality;
      },
      () => {
        if (this.state.camStream) {
          this.state.camStream.applyConstraints("video");
        }
      }
    );
  }


    updateMediaDevices = async (ev?: Event) => {
        const devices = await MediaDeviceManager.getMediaDevices()
        this.setState({
            devices,
            hasAudio: devices.audioinput.length > 0,
            hasVideo: devices.videoinput.length > 0,
            hasOutput: devices.audiooutput.length > 0,
        })
        if (!ev) {
            navigator.mediaDevices.addEventListener?.('devicechange', this.updateMediaDevices);
        }
    }


    audioDeviceChange = (audioDevice) => {
        if (this.state.hasAudio) {
            const audio = audioDevice.value != 'default' ? { deviceId: audioDevice.value } : undefined;
            OTGlobals.userSettingsManager.updateLocalSettings({ audio });
            Analytics.logEvent('usersettings_audio_device_changed', {
                device: audioDevice.value == 'default' ? 'default' : 'non-default'
            })
        }
    }

    videoDeviceChange = (videoDevice) => {
        if (this.state.hasVideo) {
            const video = videoDevice.value != 'default' ? { deviceId: videoDevice.value } : undefined;
          OTGlobals.userSettingsManager.updateLocalSettings({ video });
            Analytics.logEvent('usersettings_video_device_changed', {
                device: videoDevice.value == 'default' ? 'default' : 'non-default'
            })
        }
    }

    audioOutputDeviceChange = (audioOutputDevice) => {
        if (this.state.hasOutput) {
            const audiooutput = audioOutputDevice.value != 'default' ? { deviceId: audioOutputDevice.value } : undefined;
          OTGlobals.userSettingsManager.updateLocalSettings({ audiooutput });
            Analytics.logEvent('usersettings_audio_output_device_changed', {
                device: audioOutputDevice.value == 'default' ? 'default' : 'non-default'
            })
        }
    }

    setScreenShareQuality = (screenshareQuality: string) => {
      OTGlobals.userSettingsManager.updateLocalSettings({ screenshareQuality });
        Analytics.logEvent('usersettings_screenshare_quality', { screenshareQuality })
    }

    setCameraQuality = (cameraQuality: string) => {
      OTGlobals.userSettingsManager.updateLocalSettings({ cameraQuality });
        Analytics.logEvent('usersettings_camera_quality', { cameraQuality })
    }

  render() {
        var audioDevices = [
            { value: 'default', label: 'Default' },
            ...Object.values(this.state.devices.audioinput).map(
                (device) => (
                    { "value": device.deviceId, "label": device.label || "Unnamed microphone device" }
                )).filter(d => d.value != 'default')
        ]

        var videoDevices = [
            { value: 'default', label: 'Default' },
            ...Object.values(this.state.devices.videoinput).map(
                (device) => (
                    { "value": device.deviceId, "label": device.label || "Unnamed video device" }
                )).filter(d => d.value != 'default')
        ]

        var audioOutputDevices = [
            { value: 'default', label: 'Default' },
            ...Object.values(this.state.devices.audiooutput).map(
                (device) => (
                    { "value": device.deviceId, "label": device.label || "Unnamed audio output device" }
                )).filter(d => d.value != 'default')
        ]

        var audioSelected = audioDevices.filter(
            d => d.value == (OTGlobals.localUserSettings['audio']?.deviceId || 'default'))[0]

        var videoSelected = videoDevices.filter(
          d => d.value == (OTGlobals.localUserSettings['video']?.deviceId || 'default'))[0]

        var audioOutputSelected = audioOutputDevices.filter(
          d => d.value == (OTGlobals.localUserSettings['audiooutput']?.deviceId || 'default'))[0]


        var screenShareQualitySegments = Object.keys(SCREENSHARE_SIZES).reduce(function (obj, x) {
            obj[x] = SCREENSHARE_SIZES[x].name.replace(' ', '\n');
            return obj;
        }, {});

        var cameraQualitySegments = Object.keys(VIDEO_SIZES).reduce(function (obj, x) {
            obj[x] = `${x.toLocaleUpperCase()}\n${VIDEO_SIZES[x].height}p`;
            return obj;
        }, {});

        const {
            screenshareQuality, cameraQuality
        } = OTGlobals.localUserSettings;


        return (
          <View>


            {this.state.hasAudio ? (
              <View style={styles.settingsSection}>
                <OTText style={styles.title}>Microphone Device</OTText>

                {this.state.micStream?.stream.id ? (
                  <View
                    style={{
                      width: 300,
                      alignItems: "center",
                      backgroundColor: Theme.OfficeBackground,
                      borderRadius: 5,
                      marginBottom: 10
                    }}
                  >
                  <View style={{
                      justifyContent: 'center',
                      height: 15,
                      width: "100%"
                  }}>
                    <VolumeBar volume={this.state.volume ?? 0}
                    />
                  </View>
                  </View>
                ) : null}

                <View style={{ width: 300 }}>
                  <Select
                    value={audioSelected}
                    onChange={this.audioDeviceChange}
                    options={audioDevices}
                    menuPortalTarget={document.getElementById("otbody")}
                    isSearchable={false}
                  />
                </View>
              </View>
            ) : null}

            {this.state.hasVideo ? (
              <View style={styles.settingsSection}>
                <OTText style={styles.title}>Video Device</OTText>

                {this.state.camStream?.stream.id ? (
                  <View
                    style={{
                      width: 300,
                      height: 200,
                      marginBottom: 30,
                      borderRadius: Theme.curviness,
                      overflow: "hidden",
                      backgroundColor: "black",
                    }}
                  >
                    <UserVideo
                      componentId={"exttest"}
                      flipVideo={true}
                      muted={true}
                      videoStream={this.state.camStream?.stream}
                      hasVideo={true}
                      hasAudio={this.state.camStream?.hasAudio ?? false}
                      width={300}
                      height={200}
                      sinkId={OTGlobals.mediaDevices.audiooutput?.deviceId}
                    />
                  </View>)

                  : null}

                <View style={{ width: 300 }}>
                  <Select
                    value={videoSelected}
                    onChange={this.videoDeviceChange}
                    options={videoDevices}
                    menuPortalTarget={document.getElementById("otbody")}
                    isSearchable={false}
                  />
                </View>
              </View>
            ) : null}

            {this.state.hasOutput ? (
              <View style={styles.settingsSection}>
                <OTText style={styles.title}>Speaker Device</OTText>
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 10,

                  }}
                >

                  <OTButton title="Play test sound"
                    small={true}
                  icon={<Feather name="play" size={24} color={Theme.ButtonIconColor} />}
                  onPress={playTestAudio}
                  />
                </View>
                <View style={{ width: 300 }}>
                  <Select
                    value={audioOutputSelected}
                    onChange={this.audioOutputDeviceChange}
                    options={audioOutputDevices}
                    menuPortalTarget={document.getElementById("otbody")}
                    isSearchable={false}
                  />
                </View>
              </View>
            ) : null}

            <View style={styles.settingsSection}>
              <OTText style={styles.title}>Camera Quality</OTText>
              <SegmentedControl
                segments={cameraQualitySegments}
                selectedSegment={cameraQuality}
                onChange={this.setCameraQuality}
              />
            </View>

            <View style={styles.settingsSection}>
              <OTText style={styles.title}>Screenshare Quality</OTText>
              <SegmentedControl
                segments={screenShareQualitySegments}
                selectedSegment={screenshareQuality}
                onChange={this.setScreenShareQuality}
              />
            </View>
          </View>
        );
    }
}



interface IUserSignOutSettingsProps { }
interface IUserSignOutSettingsState { }

export class UserSignOutSettings extends Component<IUserSignOutSettingsProps, IUserSignOutSettingsState> {
    render() {
        return (
            <View>
                <OTText style={{ fontWeight: "bold", }}>Sign out of this device</OTText>

                <View style={{ flexDirection: "row", width: 200 }}>
                    <OTButton title="Sign Out" onPress={signOut} backgroundColor={StyleConstants.SecondaryColour} />
                </View>
            </View>

        )
    }
}

interface IUserAppSettingsProps { }
interface IUserAppSettingsState {
    teamWidgetEnabled: boolean
    teamWidgetMaxTiles: number
}

@observer
export class UserAppSettings extends Component<IUserAppSettingsProps, IUserAppSettingsState> {

    constructor(props) {
        super(props)
        this.state = {
          teamWidgetEnabled: OTGlobals.localUserSettings.teamWidgetEnabled ?? false,
          teamWidgetMaxTiles: OTGlobals.localUserSettings.teamWidgetMaxTiles
        }
    }

    setTeamWidgetEnabled = (teamWidgetEnabled: boolean) => {
        this.setState({ teamWidgetEnabled })
        OTGlobals.userSettingsManager.updateLocalSettings({ teamWidgetEnabled });
    }

    setTeamWidgetMaxTiles = (teamWidgetMaxTilesStr: string) => {
        logger.debug("teamWidgetMaxTilesStr", teamWidgetMaxTilesStr)
        if (Number.isNaN(teamWidgetMaxTilesStr)) {
            logger.warn("Not an integer....")
            return;
        }

        const teamWidgetMaxTiles = parseInt(teamWidgetMaxTilesStr);
        if (teamWidgetMaxTiles < 1 || teamWidgetMaxTiles > 20) {
            logger.warn("out of range");
            return;
        }

        logger.debug("teamWidgetMaxTiles", teamWidgetMaxTiles)
        this.setState({ teamWidgetMaxTiles })
        OTGlobals.userSettingsManager.updateLocalSettings({ teamWidgetMaxTiles });
    }


    render() {
        return (
            <View>
                <View style={[styles.settingsSection, { width: 300, flexDirection: 'row', alignItems: 'center' }]}>
                    <OTText style={styles.title}>Enable team widget</OTText>
                    <Switch value={this.state.teamWidgetEnabled} onValueChange={this.setTeamWidgetEnabled} />
                </View>
                <View style={[styles.settingsSection, { width: 300, flexDirection: 'row', alignItems: 'center' }]}>
                    <OTText style={styles.title}>Maximum number of User rows to show</OTText>
                    <OTTextInput value={`${this.state.teamWidgetMaxTiles}`} maxLength={2} style={{ width: 50 }} keyboardType="number-pad" onChangeText={this.setTeamWidgetMaxTiles} />
                </View>
            </View>
        )
    }
}


const styles = StyleSheet.create({
    title: {
        fontSize: 14,
        fontWeight: "600",
        marginBottom: 10,
        flex: 1,
    },
    settingsSection: {
        marginBottom: 20,
    },
})
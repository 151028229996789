import { IUserStatus } from "@openteam/models";
import { FireDb } from "../fire";

export function userIsOnline(user: { status?: IUserStatus }) {
  const offlineCutoff = new Date().getTime() - FireDb.disconnectLeeway;

  var isOnline = false;
  var inLeeway = false;
  if (
    user.status?.sessionToken &&
    user.status?.sessionToken in (user.status?.activeSessions || {})
  ) {
    isOnline = true;
  } else if (user.status?.last_changed && user.status?.last_changed > offlineCutoff) {
    inLeeway = true;
  }

  return { isOnline, inLeeway };
}

import React, { Component } from "react";
import { Image, StyleSheet, View } from "react-native-web";
import { OTButton, OTText } from "../../components";
import { WWW_URL } from "../../globals/config";
import * as platform from "../../utils/platform";
import { Theme } from "../../globals/ThemeColors";
import { signOut } from "../../globals/app";
import { OTUITree } from "@openteam/app-core";

interface IPageHeaderProps {
  hideSignOut?: boolean;
}
interface IPageHeaderState {}

export class PageHeader extends Component<IPageHeaderProps, IPageHeaderState> {
  render() {
    return (
      <View
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          paddingVertical: "3vh",
          paddingHorizontal: 25,
          paddingLeft: platform.isFrameless ? 80 : 25,
        }}
      >
        <View>
          <Image
            accessibilityRole="link"
            target="_blank"
            href={WWW_URL}
            source={Theme.LogoSource}
            style={{ width: 150, height: 30, resizeMode: "contain" }}
          />
          <OTText>The workplace for remote teams</OTText>
        </View>
        <View style={{ flex: 1 }} />
        {OTUITree.auth.userId && !OTUITree.auth.isAnonymous && !this.props.hideSignOut ? (
          <OTButton
            small={true}
            outerStyle={{ flex: undefined }}
            title="Sign out"
            onPress={() => signOut(false)}
            backgroundColor={Theme.ButtonNegativeIconColor}
            borderColor={Theme.ButtonNegativeColor}
            textColor={Theme.ButtonNegativeColor}
          />
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({});

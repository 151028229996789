import { FireAuth, OTAppCoreData, OTGlobals, OTUITree, OTUserInterface } from "@openteam/app-core";
import * as config from "./config";
import { OTUserData } from "../data/OTData";
import * as Analytics from "../utils/Analytics";
import { getPluginConfig } from "../components/plugins";
import { getActionTypeConfig } from "../components/alerts";
import {
  getIncomingRingtone,
  getOutgoingRingtone,
  playAlert,
  playGlobalAlert,
  playKnock,
  playNewMessage,
  playUnmute,
  playVideoBell,
  showNotification,
} from "../utils/Notify";
import { showToast, hideToast } from "../components/Toasts";

import Sentry from "../utils/Sentry";
import { getDisplayMedia } from "../components/ScreenSharePicker";
import * as ForegroundService from "../utils/ForegroundService";
import { parseEmojis } from "../components/chat";
import * as platformUtils from "../utils/platform";

import * as PlatformConstants from "../globals/PlatformConstants";
import Constants from "expo-constants";

import * as GoogleSignIn from "expo-google-sign-in";
import * as WindowUtils from "../utils/WindowUtils";
import { navigate } from "../mobile/navigation/NavigationService";
import { Logger } from "@openteam/app-util";
import { resetModalContainer } from "../components/ModalService";

const logger = new Logger("app");

export const setup = () => {
  OTAppCoreData.version = PlatformConstants.version || null;
  OTAppCoreData.deviceId = Constants.deviceId;

  OTGlobals.createCache(PlatformConstants.version || "");
  OTGlobals.registerAnalytics(Analytics);
  OTGlobals.pluginConfigList = getPluginConfig();
  OTGlobals.actionTypeDetails = getActionTypeConfig();
  OTGlobals.registerSentry(Sentry);
  OTGlobals.registerGetDisplayMedia(getDisplayMedia);
  OTGlobals.setConfig(config);

  OTUserInterface.setRingtones({
    getIncoming: getIncomingRingtone,
    getOutgoing: getOutgoingRingtone,
  });
  OTUserInterface.setSoundEffects({
    globalAlert: playGlobalAlert,
    alert: playAlert,
    videoBell: playVideoBell,
    knock: playKnock,
    newMessage: playNewMessage,
    unmute: playUnmute,
  });
  OTUserInterface.registerForegroundService(ForegroundService);
  OTUserInterface.registerParseEmojis(parseEmojis);
  OTUserInterface.registerPlatformUtils(platformUtils);
  OTUserInterface.registerToastHandlers({ show: showToast, hide: hideToast });
  OTUserInterface.registerShowNotification(showNotification);
  OTUserInterface.registerNavigate(navigate);
};

//
// If the sign out is instigated by the user, e.g. they click the
// sign out button.  signOut() is initially called with refresh=false,
// we do our clean up (firebase watches, mobx autoruns), then do the
// firebase auth logout, which will trigger the onAuthStateChanged,
// and subsequently re-run this function with refresh=true.
//
export const signOut = async (refresh: boolean = true) => {
  logger.debug("signOut refresh=%s", refresh);

  await OTUITree.auth.signOut();

  await FireAuth.signOut();

  OTUserData.reset();
  OTUITree.reset();

  resetModalContainer();

  if (platformUtils.PlatformOS == "mobile") {
    await GoogleSignIn.signOutAsync();
  }

  if (refresh) {

    if (platformUtils.PlatformOS != "mobile") {
      WindowUtils.setPath("");
      WindowUtils.reload(false);
    }
  }
};

export const endActiveSession = async (reload: boolean = true) => {
  logger.debug("endActiveSession");

  await OTUITree.appHomeUI.stop();
  await OTUITree.userManager.stop();

  if (reload) {
    WindowUtils.reload(false);
  }
};

import { observer } from "mobx-react";
import React, { Component } from "react";
import { View, Switch } from "react-native";
import HeadIcon from "../HeadIcon";
import { Theme } from "../../globals/ThemeColors";
import { OTText, OTTextInput } from "../OTText";
import { OTButton } from "../buttons/OTButtons";
import { dismissModal } from "../ModalService";
import { DialogView } from "../DialogView";
import Select from "react-select";
import SettingsRow from "../settingsRow";
import { IOption } from "@openteam/models";
import { OTGlobals, OTUITree } from "@openteam/app-core";
import { ImageMultiValue, ImageOption } from "./CreateChat";

interface ICreateTopicProps {
  teamId: string;
  channelId: string;
  topicId?: string
  name?: string;
}

interface ICreateTopicState {
  name: string;
  isEditing: boolean;
  saving: boolean
}

@observer
export class CreateTopic extends Component<ICreateTopicProps, ICreateTopicState> {
  constructor(props) {
    super(props);
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];
    const channel = chatUIState.getChannel(this.props.channelId);

    var name: string = this.props.name || '';
    var isEditing = false;

    if (this.props.topicId) {
      isEditing = true;
      name = channel.topics?.[this.props.topicId].name
    }

    this.state = {
      name: name,
      isEditing: isEditing,
      saving: false
    };
  }



  createTopic = async () => {
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];

    this.setState({ saving: true })

    if (this.state.name && !this.state.saving) {

      if (this.props.topicId) {
        await chatUIState.editTopic(
          this.props.channelId,
          this.props.topicId,
          this.state.name,
        );
      } else {
        const topicId = await chatUIState.createTopic(
          this.props.channelId,
          this.state.name,
        );
        chatUIState.goChannel(this.props.channelId, topicId);

      }

      dismissModal();
    }
  };

  archiveTopic = async () => {
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];

    if (this.props.topicId) {
      await chatUIState.archiveTopic(
        this.props.channelId,
        this.props.topicId,
        true,
      );
    }

    dismissModal();
  }

  render() {
    const teamData = OTGlobals.getTeamData(this.props.teamId);

    return (
      <DialogView
        title={this.state.isEditing ? "Topic Settings" : "Create Topic"}
        buttons={[
          {
            title: this.state.isEditing ? "Save Topic" : "Create Topic",
            onPress: this.createTopic,
            disabled: this.state.name.length == 0 || this.state.saving,
          },
        ]}
        footer={
          this.state.isEditing ? (
            <View style={{ flexDirection: "row" }}>
              <OTButton
                title="Archive Topic"
                onPress={this.archiveTopic}
                backgroundColor={Theme.ButtonNegativeColor}
                textColor={Theme.ButtonNegativeIconColor}
              />
            </View>
          ) : undefined
        }
      >
        <View style={{ width: 420, marginVertical: 20, alignItems: "center" }}>
          <SettingsRow title="Topic Name" width={420}>
            <OTTextInput
              style={{
                width: undefined,
                flex: 1,
              }}
              value={this.state.name}
              onChangeText={(text) => this.setState({ name: text })}
              placeholder="Topic Name"
            />
          </SettingsRow>

        </View>
      </DialogView>
    );
  }
}

import { Feather } from '@expo/vector-icons';
import * as WebBrowser from 'expo-web-browser';
import { observer } from "mobx-react";
import React, { Component } from "react";
import { View } from "react-native";
import ScalableImage from 'react-native-scalable-image';
import { Theme } from "../globals/ThemeColors";
import { ILinkPreview } from "@openteam/models";
import { PlatformOS } from '../utils/platform';
import { OTText } from "./OTText";
import { OTTouchableOpacity } from "./buttons";

interface IOTLinkPreviewProps {
  linkPreview: ILinkPreview
}
interface IOTLinkPreviewState {
}

@observer
export class OTLinkPreview extends Component<IOTLinkPreviewProps, IOTLinkPreviewState> {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    const { linkPreview } = this.props

    return (
      <View
        style={{
          marginTop: 10,
          paddingLeft: 10,
          borderLeftWidth: 4,
          borderColor: Theme.SecondaryText,
        }}
      >
        <OTText
          style={{
            fontWeight: "bold",
            color: Theme.LinkColor,
            ...(PlatformOS != "mobile" ? { cursor: "pointer" } : {}),
          }}
          accessibilityRole="link"
          hrefAttrs={{ target: "_blank" }}
          href={linkPreview.url}
        >
          {linkPreview.title}
        </OTText>
        <OTText>{linkPreview.description}</OTText>
        {linkPreview.image ? (
          <ScalableImage
            key={`${linkPreview.image}`}
            source={{ uri: linkPreview.image }}
            width={200}
            height={200}
            style={{
              borderRadius: Theme.curviness,
              marginVertical: 5,
            }}
          />
        ) : null}
      </View>
    );

  }
}
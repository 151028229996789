import { observer } from "mobx-react"
import React, { Component } from "react"
import { View, TouchableOpacity, Text, StyleSheet } from "react-native"
import * as StyleConstants from '../globals/StyleConstants'
import { OTTouchableOpacity } from "./buttons"
import { Theme } from "../globals/ThemeColors"
import { OTText } from "./OTText"



interface ISegmentedControlProps {
  segments: {[id: string]: string}
  selectedSegment: string
  onChange: (value) => void
}

interface ISegmentedControlState {
}

@observer
export class SegmentedControl extends Component<ISegmentedControlProps, ISegmentedControlState> {
  render() {
    return (
      <View style={[styles.container, {backgroundColor: Theme.ControlBackground}]}>
        {
          Object.keys(this.props.segments).map(value => {
            const selected = this.props.selectedSegment == value;
            const style = selected ? styles.selected : null;
            const color = selected ? Theme.MainText : Theme.SecondaryText;
            const themeStyle = selected ? { backgroundColor: Theme.ControlSelected } : null;
            
            return (
              <OTTouchableOpacity
              key={value}
              onPress={() => this.props.onChange(value)}
              stopPropagation={true}
              analyticsEvent={"segment_" + value}
              style={[ styles.item, style, themeStyle]}>
              <OTText style={[ styles.buttonLabel, { color }]}>
                {this.props.segments[value]}
              </OTText>
            </OTTouchableOpacity>
            )})
        }
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: 'row',
    borderRadius: Theme.curviness/1.5,
  },
  item: {
    flex: 1,
    padding: 5,
    paddingHorizontal: 15,
    margin: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selected: {
    borderRadius: Theme.curviness/1.5,
    ...StyleConstants.LightShadow
  },
  buttonLabel: {
    fontSize: 14
  },
})


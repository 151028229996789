import * as fbStorage from "@react-native-firebase/storage";
import { observable } from 'mobx';
import { IMessageFile, IFile } from "@openteam/models";
import * as uuid from "react-native-uuid";

import { Logger } from "@openteam/app-util";
const logger = new Logger("RNCloudUpload");
import { OTGlobals } from "./OTGlobals";


export class RNCloudUpload implements IMessageFile {

  id: string;
  teamId: string;
  roomId: string | undefined;
  userId: string;
  imageKind: string;

  file: IFile;

  @observable progress = 0;
  @observable completed = false;
  @observable error?: string;
  @observable downloadUrl?: string;

  uploadTask?: fbStorage.FirebaseStorageTypes.Task;

  constructor(
    teamId: string,
    roomId: string | undefined,
    userId: string,
    imageKind: string,
    file: IFile
  ) {
    this.file = file;
    this.teamId = teamId;
    this.roomId = roomId;
    this.userId = userId;
    this.imageKind = imageKind;
    console.log("created RNCloudUpload for ", file.name);

    this.id = uuid.v1();

    this.uploadFile();
  }

  getPreviewURL = () => {
    return this.file.url
  }

  getStorageRef = () => {
    const bucket = OTGlobals.config.TEAM_STORAGE_BUCKET || "gs://openteamfiles";

    const path = `/team/${this.teamId}/${this.imageKind}/${this.userId}/${this.id}/${this.file.name}`;

    logger.info("storage ref path", path);
    var storageRef = fbStorage.firebase.app().storage(bucket).ref(path);
    return storageRef;
  };

  uploadFile = async () => {
    console.log("uploading ", this.file.url);

    const fileRef = this.getStorageRef();

    var metadata = {
      customMetadata: {
        teamId: this.teamId,
        roomId: this.roomId,
      },
    };

    this.uploadTask = fileRef.putFile(this.file.url);

    this.uploadTask.on(
      "state_changed",
      (snapshot) => {
        this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case "paused":
            break;
          case "running":
            break;
        }
      },
      (error) => {
        console.log("file upload failed", this.file.url, error);
        this.error = error.name;
      },
      async () => {
        this.completed = true;
        await this.loadDownloadURL();
        console.log("File available at", this.downloadUrl);
      }
    );
  };

  loadDownloadURL = async () => {
    this.downloadUrl = await this.getStorageRef().getDownloadURL();
  };

  complete = async () => {
    await this.uploadTask;
    await this.loadDownloadURL();
  };

  stop = () => {
    this.uploadTask && this.uploadTask.cancel();
    this.completed && this.getStorageRef().delete();
  };
}

import React, { Component } from 'react';
import { View, ScrollView } from 'react-native';
import * as Fire from '../globals/Fire';
import * as Analytics from '../utils/Analytics'

import { validate } from 'validate.js';
import { OTModalContainer } from '../components/ModalService';
import { PageHeader } from './fragments/PageHeader';
import { OTText, OTTextInput } from '../components/OTText';
import { Theme } from '../globals/ThemeColors';
import { ProgressBar } from '../components/ProgressBar';
import { DialogView } from '../components/DialogView';

const validationConstraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: "^Please enter a name"
    },
  },
};

interface ISetupAccountProps {
}

interface ISetupAccountState {
  imageFile?: any
  name: string
  errors?: { [id: string]: string[] }
}

export default class SetupAccount extends Component<ISetupAccountProps, ISetupAccountState> {
  constructor(props) {
    super(props);

    this.state = {
      name: ''

    }
  }
  componentDidMount = () => {
    Analytics.logEvent("setupaccount_page")
  }

  render() {
    return (
      <View style={{ flex: 1, backgroundColor: Theme.OfficeBackground }} >
        <ScrollView contentContainerStyle={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1, paddingHorizontal: 25, paddingTop: 50 }}>

          <OTModalContainer padding={40} style={{ minWidth: 300, minHeight: 200, justifyContent: 'space-around' }}>

            <DialogView
              title={"Thanks for signing up!"}
              buttons={[
                {
                  title: "Next",
                  onPress: this.onPress
                }
              ]}
            >
              <OTText style={{ fontSize: 16, fontWeight: "600", color: Theme.DarkText, marginBottom: 20 }}>
                What's your name?
                </OTText>

              <OTTextInput style={{
                borderRadius: 5,
                backgroundColor: 'white',
                borderWidth: 1,
                borderColor: '#ccc',
                padding: 10,
                marginVertical: 10,
                minWidth: 250,
              }}
                value={this.state.name}
                onChangeText={this.onChangeText}
                onSubmitEditing={this.onPress}
                placeholder="Your name"

              />

              <OTText style={{ color: 'red', textAlign: 'center', paddingTop: 10, }}>
                {this.getErrorMessages()}
              </OTText>


            </DialogView>


            {/* <OTText style={{ fontSize: 22, fontWeight: "500", color: Theme.DarkText, marginBottom: 20 }}>
              What's your name?
                </OTText>

            <OTTextInput style={{
              borderRadius: 5,
              backgroundColor: 'white',
              borderWidth: 1,
              borderColor: '#ccc',
              padding: 10,
              marginVertical: 10,
              minWidth: 250,
            }}
              value={this.state.name}
              onChangeText={this.onChangeText}
              onSubmitEditing={this.onPress}
              placeholder="Your name"

            />

            <OTText style={{ color: 'red', textAlign: 'center', paddingTop: 10, }}>
              {this.getErrorMessages()}
            </OTText>



            <View style={{ flexDirection: 'row', width: "100%", marginTop: 15 }}>
              <OTButton
                title="Next"
                onPress={this.onPress}
              />
            </View> */}
          </OTModalContainer>
          <PageHeader />
        </ScrollView>
        <View
          // @ts-expect-error position not part of types
          style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
          <ProgressBar progress={33} borderRadius={0} height={12} />
        </View>
      </View>
    )
  }

  getErrorMessages(separator = "\n") {
    if (!this.state.errors) {
      return []
    }

    return Object.values(this.state.errors).map((it) => it.join(separator)).join(separator);
  }


  onPress = () => {
    validate(this.state, validationConstraints)

    const validationResult = validate(this.state, validationConstraints);

    if (validationResult) {
      this.setState({ errors: validationResult });
      return
    }

    var user = Fire.getUser()

    if (!user) {
      return
    }

    Analytics.logEvent("setupaccount_done")


    Fire.setupAccount(
      user.uid,
      this.state.name,
      user.email,
      undefined
    )

  }

  onChangeText = name => this.setState({ name })
}
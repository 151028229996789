import React, { Component } from "react";
import { View, ScrollView, Image, StyleSheet, ActivityIndicator } from "react-native";
import { OTUserData } from "../data/OTData";
import * as StyleConstants from "../globals/StyleConstants";
import * as WindowUtils from "../utils/WindowUtils";
import * as Fire from "../globals/Fire";
import * as Analytics from "../utils/Analytics";
import { PageHeader } from "./fragments/PageHeader";
import { OTModalContainer } from "../components/ModalService";
import { ITeamExternalAddress, ITeamExternalWaiting } from "@openteam/models";
import { assertDefined, Logger } from "@openteam/app-util";
import { VideoCall } from "./videocallView/VideoCall";
import { OTText, OTTextInput } from "../components/OTText";
import { Theme } from "../globals/ThemeColors";
import { isMobile, isIPad13 } from "react-device-detect";
import { OTButton } from "../components/buttons/OTButtons";
import { PlatformOS } from "../utils/platform";
import { UserVideo } from "../components/userTile";
import { showDeviceSettings } from "./fragments/settings/SettingsContainer";
import { Feather } from "@expo/vector-icons";
import Volume from "../components/userTile/Volume";
import { reaction, toJS } from "mobx";
import { ExternalMeetingManager, FireDb, OTGlobals, OTUITree, WebcamStream } from "@openteam/app-core";
import { getRTDB } from "../globals/Fire/util";
import { showCallFeedback } from "./fragments/CallFeedback";

const logger = new Logger("ExternalMeetings");

interface IExternalMeetingProps {}

interface IExternalMeetingState {
  loaded: boolean;
  sentRequest: boolean;
  waitingResponse: boolean;
  teamId?: string;
  currentRoomId?: string;
  meeting?: ITeamExternalAddress;
  name: string;
  error?: string;
  message?: string;
  testStream?: WebcamStream;
}

export default class ExternalMeeting extends Component<IExternalMeetingProps, IExternalMeetingState> {
  externalManager?: ExternalMeetingManager;

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      sentRequest: false,
      waitingResponse: false,
      name: OTUITree.unsafeUserManager?.userDoc?.name || "",
    };
  }

  componentDidMount = async () => {

    const teamId = OTUserData.externalMeeting
      ? await FireDb.getTeamPathId(getRTDB(), OTUserData.externalMeeting.teamPath)
      : undefined;

    if (!teamId) {
      this.setState({
        error: `Team ${OTUserData.externalMeeting?.teamPath} does not exist`,
        loaded: false,
      });
    } else {
      var meeting = await Fire.getTeamExternalAddress(teamId, OTUserData.externalMeeting?.address);

      if (meeting) {
        this.setState({
          teamId: teamId,
          meeting: meeting,
          loaded: true,
        });

        this.externalManager = new ExternalMeetingManager(
          getRTDB(),
          OTUITree.auth.userId,
          OTUITree.auth.sessionToken,
          teamId,
          this.setMeeting,
          showCallFeedback,
        );

        logger.info("camera wsettings", toJS(OTGlobals.mediaDevices));

        var testStream = new WebcamStream(teamId, OTUITree.auth.userId, OTGlobals.mediaDevices);
        await testStream.enableAudio();
        await testStream.enableVideo();
        this.setState({ testStream });

        Analytics.logEvent("externalMeeting__OpenExternalLink");
      } else {
        this.setState({
          teamId: teamId,
          error: `${OTUserData.externalMeeting?.teamPath}/${OTUserData.externalMeeting?.address} does not exist`,
          loaded: true,
        });
      }
    }
  };

  componentWillUnmount() {
    if (this.externalManager) {
      this.externalManager.shutdown();
      OTGlobals.unregisterGetTeamData();
    }
  }

  _devicesChangedReaction = reaction(
    () => {
      return [OTGlobals.mediaDevices.audio, OTGlobals.mediaDevices.video];
    },
    async () => {
      logger.info("camera settings changed", toJS(OTGlobals.mediaDevices));
      if (this.state.testStream) {
        await this.state.testStream.updateSettings(OTGlobals.mediaDevices);
      }
    }
  );

  _cameraQualityChangedReaction = reaction(
    () => {
      return OTGlobals.localUserSettings.cameraQuality;
    },
    () => {
      if (this.state.testStream) {
        logger.debug("quality change for camera");
        this.state.testStream.applyConstraints("video");
      }
    }
  );

  setMeeting = (currentRoomId) => {
    this.setState({ currentRoomId: currentRoomId });

    if (PlatformOS != "electron") {
      WindowUtils.changeHistory(
        "OpenTeam",
        `/${WindowUtils.getPath()}` + (currentRoomId ? `?roomId=${currentRoomId}` : "")
      );
    }

    if (!currentRoomId) {
      WindowUtils.reload();
    }
  };

  joinMeeting = async () => {
    this.setState({
      error: undefined,
    });

    if (!this.state.name) {
      this.setState({
        error: "Please enter a name",
      });
      return;
    }

    var roomId = OTUserData.externalMeeting?.roomId;

    if (roomId) {
      var hasAccess = await FireDb.checkTeamRoomAccess(getRTDB(), this.state.teamId!, roomId);

      if (!hasAccess) {
        roomId = undefined;
      }
    }

    if (roomId) {
      this.syncCall(OTUserData.externalMeeting?.roomId);
    } else {
      Fire.joinTeamExternalAddressWaiting(
        this.state.teamId,
        OTUserData.externalMeeting?.address,
        this.state.name,
        this.hdlWaiting
      );
      this.setState({
        sentRequest: true,
        waitingResponse: true,
      });
    }
  };

  hdlWaiting = (doc: ITeamExternalWaiting) => {
    if (doc) {
      if (doc.status == "A" && doc.roomId) {
        this.syncCall(doc.roomId);
        Fire.cancelTeamExternalAddressWaiting(
          this.state.teamId,
          OTUserData.externalMeeting?.address
        );
      } else if (doc.status == "H") {
        this.setState({
          message: "Your host has noticed your request and will admit you shortly.",
        });
      } else if (doc.status == "R") {
        this.setState({
          waitingResponse: false,
          message:
            "Your request to join the meeting has been rejected. Please contact the person who invited you.",
        });
        Fire.cancelTeamExternalAddressWaiting(
          this.state.teamId,
          OTUserData.externalMeeting?.address
        );
      }
    }
  };

  syncCall = (roomId) => {
    assertDefined(this.externalManager);

    if (!this.externalManager.roomId) {
      this.state.testStream?.shutdown();

      this.externalManager.setupRoom(roomId, this.state.name);
    }
  };

  showSettings = () => {
    showDeviceSettings(this.state.teamId);
  };

  render() {
    const teamData = this.state.teamId ? this.externalManager?.teamData : undefined;

    if (this.state.teamId && this.state.currentRoomId && this.externalManager) {
      return (
        <VideoCall
          key={this.state.currentRoomId}
          teamId={this.state.teamId}
          roomId={this.state.currentRoomId}
          leaveCall={this.externalManager.leaveTeamRoom}
        />
      );
    }
    return (
      <View style={{ flex: 1, backgroundColor: Theme.OfficeBackground }}>
        <ScrollView
          contentContainerStyle={{
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
            paddingHorizontal: 25,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              minHeight: "100vh",
              width: "100vw",
              maxWidth: "100vw",
              flex: 1,
              justifyContent: "center",
              flexWrap: "wrap-reverse",
            }}
          >
            {!isMobile || isIPad13 ? (
              <View
                style={{ flex: 3, minWidth: 300, alignItems: "center", justifyContent: "center" }}
              >
                {this.state.sentRequest ? (
                  <Image
                    source={{
                      uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fjoinmeeting2.svg?alt=media",
                    }}
                    style={{ height: "80vh", minWidth: 300, minHeight: 250, width: "50vw" }}
                    resizeMode="contain"
                  />
                ) : (
                  <Image
                    source={{
                      uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fjoinmeeting1.svg?alt=media",
                    }}
                    style={{ height: "80vh", minWidth: 300, minHeight: 250, width: "50vw" }}
                    resizeMode="contain"
                  />
                )}
              </View>
            ) : null}
            <View
              style={{
                flex: 2,
                height: "100vh",
                minWidth: 300,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <OTModalContainer
                padding={30}
                style={{
                  paddingHorizontal: 50,
                  minWidth: 300,
                  minHeight: 200,
                  justifyContent: "space-around",
                }}
              >
                <OTText
                  style={{
                    fontSize: 22,
                    fontWeight: "500",
                    color: Theme.DarkText,
                    marginBottom: 30,
                  }}
                >
                  Joining {this.state.meeting?.name}'s room
                </OTText>

                {this.state.waitingResponse ? (
                  <View style={{ alignItems: "center" }}>
                    <ActivityIndicator size={70} color={Theme.MainColour} />
                    {this.state.message ? null : (
                      <OTText
                        style={{
                          fontSize: 14,
                          marginTop: 30,
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        We're notifying your meeting host, please wait.
                      </OTText>
                    )}
                  </View>
                ) : null}
                {!this.state.sentRequest ? (
                  <OTTextInput
                    style={styles.nameInput}
                    value={this.state.name}
                    onChangeText={(text) => this.setState({ name: text })}
                    placeholder="Your Name"
                  />
                ) : null}
                {this.state.error ? <OTText style={styles.error}>{this.state.error}</OTText> : null}

                {this.state.message ? (
                  <OTText style={styles.message}>{this.state.message}</OTText>
                ) : null}

                <View
                  style={{
                    width: 360,
                    height: 240,
                    marginTop: 30,
                    borderRadius: Theme.curviness,
                    overflow: "hidden",
                    backgroundColor: "black",
                  }}
                >
                  <UserVideo
                    componentId={"exttest"}
                    flipVideo={true}
                    muted={true}
                    videoStream={this.state.testStream?.stream}
                    hasVideo={true}
                    hasAudio={this.state.testStream?.hasAudio ?? false}
                    width={360}
                    height={240}
                    sinkId={OTGlobals.mediaDevices.audiooutput?.deviceId}
                  />
                  {this.state.testStream?.stream.id ? (
                    <View
                      style={{
                        position: "absolute",
                        bottom: 5,
                        padding: 5,
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Volume
                        streamInfo={teamData?.streams[this.state.testStream?.stream.id]}
                        size={"small"}
                      />
                    </View>
                  ) : null}
                </View>

                <OTButton
                  small={true}
                  icon={
                    <Feather
                      name="sliders"
                      size={16}
                      style={{ color: Theme.ButtonColor, paddingRight: 5 }}
                    />
                  }
                  title="Device Settings"
                  onPress={this.showSettings}
                  textColor={Theme.ButtonColor}
                  borderColor={Theme.ButtonColor}
                  backgroundColor="transparent"
                  outerStyle={{
                    marginTop: 10,
                  }}
                />

                {!this.state.sentRequest ? (
                  <View style={{ flexDirection: "row", marginTop: 30, width: "100%" }}>
                    <OTButton
                      title="Request Access"
                      onPress={this.joinMeeting}
                      outerStyle={{ ...StyleConstants.LightShadow }}
                    />
                  </View>
                ) : null}
              </OTModalContainer>
            </View>
          </View>
          <PageHeader hideSignOut={true} />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  nameInput: {
    borderRadius: Theme.curviness / 1.5,
    borderWidth: 1,
    borderColor: Theme.InputHighlightColor,
    padding: 7,
    width: 250,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    marginTop: 20,
  },
  error: {
    color: "red",
    textAlign: "center",
    paddingTop: 10,
  },
  message: {
    backgroundColor: Theme.GreyBackground,
    padding: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
    textAlign: "center",
    marginTop: 10,
    width: 300,
  },
  buttonText: {},
});

import React, { Component } from "react";
import { observer } from "mobx-react";
import { debounce } from "throttle-debounce";
import Select from "react-select";

import { View, StyleSheet, FlatList } from "react-native";
import * as Fire from "../../../globals/Fire";
import { OTTouchableOpacity } from "../../../components";
import { ImageSelector } from "../../../components/ImageSelector";
import { showToast } from "../../../components/Toasts";
import HeadIcon from "../../../components/HeadIcon";
import { Theme } from "../../../globals/ThemeColors";
import { OTButton } from "../../../components/buttons/OTButtons";
import { OTText, OTTextInput } from "../../../components/OTText";
import {
  defaultSubTeamName,
  TeamManagerDb,
  FireUtils,
  OTGlobals,
  OTUITree,
} from "@openteam/app-core";
import { Feather } from "@expo/vector-icons";
import { TeamAccessReqs } from "../TeamAccessReqs";
import * as ModalService from "../../../components/ModalService";
import { getRTDB } from "app/webapp/src/globals/Fire/util";

interface AdminTeamSettingsProps {
  teamId: string;
}
interface AdminTeamSettingsState {
  name: string;
  uploadingBackground: boolean;
  uploadingIcon: boolean;
  dropError?: string;
}

const megaByte = 1024 * 1024;

@observer
export class AdminTeamSettings extends Component<AdminTeamSettingsProps, AdminTeamSettingsState> {
  maxBackgroundUploadSize = 5 * megaByte;
  maxIconUploadSize = 0.5 * megaByte;
  constructor(props) {
    super(props);

    const teamData = OTGlobals.getTeamData(this.props.teamId);

    this.state = {
      name: teamData.teamName!,
      uploadingBackground: false,
      uploadingIcon: false,
    };
  }

  componentWillUnmount = () => {};

  onBackgroundFile = (e) => {
    var files = e.target.files;
    var file = files[0];

    if (!file) {
      return;
    }

    if (file.size > this.maxBackgroundUploadSize) {
      var msg = `Maximum file size is ${(this.maxBackgroundUploadSize / megaByte).toFixed(
        2
      )}MB - Uploaded Size: ${(file.size / megaByte).toFixed(2)}MB`;
      showToast(msg, "error");
      return;
    }

    this.setState({ uploadingBackground: true });

    FireUtils.uploadUserFile(this.props.teamId, OTUITree.auth.userId, "background", file, (url) => {
      this.setState({ uploadingBackground: false });

      Fire.updateTeam(this.props.teamId, {
        backgroundImageUrl: url || null,
      });
    });
  };

  onBackgroundURL = (url) => {
    Fire.updateTeam(this.props.teamId, {
      backgroundImageUrl: url || null,
    });
  };

  onIconFile = (e) => {
    var files = e.target.files;
    var file = files[0];

    if (!file) {
      return;
    }

    if (file.size > this.maxIconUploadSize) {
      var msg = `Maximum file size is ${(this.maxIconUploadSize / megaByte).toFixed(
        2
      )}MB - Uploaded Size: ${(file.size / megaByte).toFixed(2)}MB`;
      showToast(msg, "error");
      return;
    }

    this.setState({ uploadingIcon: true });

    FireUtils.uploadUserFile(this.props.teamId, OTUITree.auth.userId, "icon", file, (url) => {
      this.setState({ uploadingIcon: false });

      Fire.updateTeam(this.props.teamId, {
        iconImageUrl: url || null,
      });
    });
  };

  onNameChanged = (name) => {
    this.setState(
      {
        name: name,
      },
      () => this.saveName(name)
    );
  };

  saveName = debounce(500, () => {
    if (this.state.name.length > 0) {
      Fire.updateTeam(this.props.teamId, {
        teamName: this.state.name,
      });
    }
  });

  render() {
    const teamData = OTGlobals.getTeamData(this.props.teamId);

    return (
      <View style={{}}>
        <View style={styles.settingsSection}>
          <OTText style={styles.title}>Change Team Name</OTText>

          <OTTextInput value={this.state.name} onChangeText={this.onNameChanged} />
        </View>
        {/* <OTText style={styles.title}>Set your team background</OTText>

        <ImageSelector
          width={240}
          height={180}
          loading={this.state.uploadingBackground}
          imageUrl={teamData.backgroundImageUrl}
          onFiles={this.onBackgroundFile}
        />

        <UnsplashPicker onSelect={this.onBackgroundURL}/> */}
        <View style={styles.settingsSection}>
          <OTText style={styles.title}>Set your team icon</OTText>

          <ImageSelector
            width={100}
            height={100}
            loading={this.state.uploadingIcon}
            imageUrl={teamData.iconImageUrl}
            onFiles={this.onIconFile}
          />
        </View>
      </View>
    );
  }
}

interface AdminUserSettingsProps {
  teamId: string;
}
interface AdminUserSettingsState {}

@observer
export class AdminUserSettings extends Component<AdminUserSettingsProps, AdminUserSettingsState> {
  removeUser = (userId) => {
    const teamData = OTGlobals.getTeamData(this.props.teamId);
    var users = teamData.users;

    let user = users[userId];

    if (user) {
      confirm(`Are you sure you want to remove ${user.name} from the team?`) &&
        Fire.adminRemoveUserFromTeam(this.props.teamId, userId);
    }
  };

  makeAdmin = (userId) => {
    const teamData = OTGlobals.getTeamData(this.props.teamId);

    var users = teamData.users;
    let user = users[userId];

    if (user) {
      confirm(`Make ${user.name} a team admin?`) &&
        Fire.setTeamAdmin(this.props.teamId, userId, true);
    }
  };

  removeAdmin = (userId) => {
    const teamData = OTGlobals.getTeamData(this.props.teamId);

    var users = teamData.users;
    let user = users[userId];

    if (user) {
      confirm(`Remove admin permissions from ${user.name}?`) &&
        Fire.setTeamAdmin(this.props.teamId, userId, false);
    }
  };

  showAddNewUser = () => {
    ModalService.showModal({
      contents: <TeamAccessReqs teamId={this.props.teamId} />,
      showContainer: true,
      showClose: true,
    });
  };

  render() {
    const teamData = OTGlobals.getTeamData(this.props.teamId);

    var users = teamData.users;
    var subTeamOptions = Object.keys(teamData.subTeams).map((value) => ({
      value: value,
      label: teamData.subTeams[value]?.name,
    }));

    return (
      <View style={{}}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <OTText style={styles.title}>Users</OTText>

          <OTButton
            toolTip="Add teammates"
            small={true}
            icon={
              <Feather
                name="user-plus"
                size={14}
                style={{ color: Theme.ButtonColor, marginRight: 5 }}
              />
            }
            textColor={Theme.ButtonColor}
            borderColor={Theme.ButtonColor}
            backgroundColor="transparent"
            title="Add teammates"
            onPress={this.showAddNewUser}
          />
        </View>

        {Object.keys(users).map((userId) => {
          let user = users[userId];
          return (
            <View key={userId} style={{ flexDirection: "row", alignItems: "center", margin: 5 }}>
              <HeadIcon imageUrl={user.imageUrl} name={user.name} size={30} />
              <View
                style={{
                  flex: 1,
                  paddingHorizontal: 10,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <OTText style={{ width: 150, fontSize: 14, color: Theme.DarkText }}>
                  {user.name}
                </OTText>
                <View style={{ width: 200 }}>
                  <Select
                    value={
                      user.subTeam && {
                        value: user.subTeam,
                        label: teamData.subTeams[user.subTeam]?.name,
                      }
                    }
                    onChange={(tag) =>
                      TeamManagerDb.updateTeamUser(getRTDB(), this.props.teamId, userId, {
                        subTeam: tag?.value || null,
                      })
                    }
                    options={subTeamOptions}
                    placeholder="Select Team"
                    isSearchable={false}
                    isClearable={true}
                    menuPortalTarget={document.getElementById("otbody")}
                  />
                </View>
              </View>
              {userId != OTUITree.auth.userId ? (
                user.isAdmin ? (
                  <OTButton
                    small={true}
                    title="Remove Admin"
                    onPress={() => this.removeAdmin(userId)}
                    outerStyle={{ marginHorizontal: 10 }}
                  />
                ) : (
                  <OTButton
                    small={true}
                    title="Make Admin"
                    onPress={() => this.makeAdmin(userId)}
                    outerStyle={{ marginHorizontal: 10 }}
                  />
                )
              ) : null}
              <View style={{ width: 20 }}>
                {userId != OTUITree.auth.userId ? (
                  <OTTouchableOpacity onPress={() => this.removeUser(userId)}>
                    <Feather name="x" style={{ color: Theme.ButtonNegativeColor }} size={20} />
                  </OTTouchableOpacity>
                ) : null}
              </View>
            </View>
          );
        })}
      </View>
    );
  }
}

interface AdminSubTeamSettingsProps {
  teamId: string;
}
interface AdminSubTeamSettingsState {
  defaultSubTeamName: string;
}

@observer
export class AdminSubTeamSettings extends Component<
  AdminSubTeamSettingsProps,
  AdminSubTeamSettingsState
> {
  constructor(props) {
    super(props);
    const teamData = OTGlobals.getTeamData(this.props.teamId);

    this.state = {
      defaultSubTeamName: teamData.config?.defaultSubTeamName || defaultSubTeamName,
    };
  }
  onNameChanged = (name) => {
    this.setState(
      {
        defaultSubTeamName: name,
      },
      () => this.saveDefaultSubTeamName(name)
    );
  };

  saveDefaultSubTeamName = debounce(500, () => {
    Fire.updateTeamConfig(this.props.teamId, {
      defaultSubTeamName: this.state.defaultSubTeamName,
    });
  });
  render() {
    const teamData = OTGlobals.getTeamData(this.props.teamId);

    return (
      <View style={{}}>
        <View style={styles.settingsSection}>
          <OTText style={styles.title}>Teams</OTText>
          <FlatList
            data={Object.keys(teamData.subTeams)}
            keyExtractor={(item) => item}
            renderItem={this.renderItem}
            ListFooterComponent={this.renderFooter}
          />
        </View>

        <View style={styles.settingsSection}>
          <OTText style={styles.title}>Default Team Name</OTText>

          <OTTextInput
            style={{
              fontSize: 12,
            }}
            value={this.state.defaultSubTeamName}
            onChangeText={this.onNameChanged}
          />
        </View>
      </View>
    );
  }
  renderItem = ({ item }) => {
    return <SubTeamRow teamId={this.props.teamId} subTeamId={item} />;
  };

  renderFooter = () => {
    return <SubTeamRow teamId={this.props.teamId} />;
  };
}

interface SubTeamRowProps {
  teamId: string;
  subTeamId?: string;
}
interface SubTeamRowState {
  name: string;
  editing: boolean;
}

@observer
export class SubTeamRow extends Component<SubTeamRowProps, SubTeamRowState> {
  constructor(props) {
    super(props);
    const teamData = OTGlobals.getTeamData(this.props.teamId);

    this.state = {
      editing: false,
      name: this.props.subTeamId ? teamData.subTeams[this.props.subTeamId].name : "",
    };
  }
  onChangeText = (name: string) => {
    this.setState({
      name: name,
    });
  };

  showEdit = () => {
    this.setState({
      editing: true,
    });
  };

  saveName = () => {
    if (this.props.subTeamId && !this.state.name) {
      return false;
    }

    this.setState({
      editing: false,
    });

    const teamData = OTGlobals.getTeamData(this.props.teamId);
    var prevName = this.props.subTeamId ? teamData.subTeams[this.props.subTeamId].name : "";

    if (this.state.name == prevName) {
      return;
    }

    console.log("saving", this.props.teamId, this.props.subTeamId, this.state.name);

    if (this.props.subTeamId) {
      Fire.updateSubTeam(this.props.teamId, this.props.subTeamId, this.state.name);
    } else {
      Fire.createSubTeam(this.props.teamId, this.state.name);
      this.setState({
        name: "",
      });
    }
  };

  render() {
    return (
      <View
        key={this.props.subTeamId}
        style={{
          flexDirection: "row",
          alignItems: "center",
          margin: 5,
          borderRadius: Theme.curviness / 1.5,
          backgroundColor: Theme.ControlBackground,
        }}
      >
        <View style={{ flex: 1 }}>
          {this.state.editing ? (
            <OTTextInput
              value={this.state.name}
              onChangeText={this.onChangeText}
              onBlur={this.saveName}
              autoFocus={true}
              style={{
                padding: 7,
                color: Theme.MainText,
                fontSize: 14,
                borderWidth: 0,
              }}
            />
          ) : (
            <OTTouchableOpacity onPress={this.showEdit}>
              {this.props.subTeamId ? (
                <View style={{ flexDirection: "row", alignItems: "center", paddingRight: 10 }}>
                  <OTText
                    style={{
                      padding: 7,
                      color: Theme.MainText,
                      fontSize: 14,
                      flex: 1,
                    }}
                  >
                    {this.state.name}
                  </OTText>
                  <Feather name="edit-3" size={14} style={{ color: Theme.MainColour }} />
                </View>
              ) : (
                <View style={{ flexDirection: "row" }}>
                  <OTButton
                    onPress={this.showEdit}
                    small={true}
                    outerStyle={{ height: 30, flex: 1 }}
                    textColor={Theme.ButtonColor}
                    borderColor={Theme.ButtonColor}
                    backgroundColor={Theme.InputBackgroundColor}
                    outlined={true}
                    title={"Add a team"}
                  />
                </View>
              )}
            </OTTouchableOpacity>
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  title: {
    fontSize: 14,
    fontWeight: "600",
    marginBottom: 10,
  },
  settingsSection: {
    marginBottom: 20,
  },
});

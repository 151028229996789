import  {InfluxDB, Point, WriteApi} from '@influxdata/influxdb-client-browser';
const org = 'will@openteam.io'
const bucket = 'applogs'

import { ILogMessage, ILogSender } from "./ILogSender";

export class InfluxSender implements ILogSender {
  _client: InfluxDB;
  _writeApi: WriteApi;

  _token: string;
  _platform: string;
  _sessionId: string;
  _userId: string;

  constructor(platform, token, sessionId?, userId?) {
    this._client = new InfluxDB({url: 'https://europe-west1-1.gcp.cloud2.influxdata.com', token: token})
    this._writeApi = this._client.getWriteApi(org, bucket, undefined, { flushInterval: 5000 })

    this._token = token;
    this._platform = platform;
    this._sessionId = sessionId;
    this._userId = userId;

    this.setup();
    
  }

  setUser = (sessionId?, userId?) => {
    this._sessionId = sessionId;
    this._userId = userId;
    this.setup();
  };

  setup = () => {
    var tags = {
        platform : this._platform
    };

    if (this._sessionId) tags["sessionId"] = this._sessionId;
    if (this._userId) tags["userId"] = this._userId;

    this._writeApi.useDefaultTags(tags)
  };


  push = (message: ILogMessage) => {
    const point1 = new Point(message.appName)
    .tag("prefix", message.prefix)
    .tag("level", message.level)
    .stringField("message", message.message)

    this._writeApi.writePoint(point1);
  }

}

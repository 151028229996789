import { IOTTile } from "@openteam/models";

export function generateTiles(users: string[], streamMap): IOTTile[] {
  const tiles: IOTTile[] = [];
  for (const userId of users) {
    tiles.push({
      tileType: "stream",
      userId,
      streamType: "camera",
      streamId: streamMap[userId]?.["camera"],
    });
    if (streamMap[userId]?.["screen"]) {
      tiles.push({
        tileType: "stream",
        userId,
        streamType: "screen",
        streamId: streamMap[userId]?.["screen"],
      });
    }
  }
  return tiles;
}

import events from "events";
import { action } from "mobx";
import { Logger } from "@openteam/app-util";
import { RoomManager } from "./RoomManager";
import {
  LocalStreamDetails,
  WebcamStream,
} from "./MediaDeviceManager";
import { AwaitLock } from "@openteam/app-util";
import { IActionMsg, ITeamDoc, IPTTController } from "@openteam/models";
import { OTGlobals } from "./OTGlobals";
import { OTUserInterface } from "./OTUserInterface";
import { P2PStreamManager } from "./PeerConnection";
import { userIsOnline } from "./utils/userIsOnline";

const logger = new Logger("PTTController");

export class PTTController extends events.EventEmitter implements IPTTController {
  _myUserId: string;
  _teamId: string;
  _streamManager: P2PStreamManager;
  _roomManager: RoomManager;
  _currentTargets: string[] | undefined;
  _connectedTargets: string[] | undefined;
  _myStream: LocalStreamDetails | undefined;
  _stateLock = new AwaitLock();
  _pttType: "PTT_WALKIE" | "PTT_GLOBAL" | undefined;
  _lastPttFrom: string | undefined;
  _canPttUsers: string[] = [];

  constructor(myUserId: string, teamId: string, streamManager: P2PStreamManager, roomManager: RoomManager) {
    super();
    this._myUserId = myUserId;
    this._teamId = teamId;
    this._streamManager = streamManager;
    this._streamManager.on("connected", this._onConnected);
    this._streamManager.on("disconnected", this._onDisconnected);
    this._streamManager.on("peerconnected", this._onPeerConnected);
    this._streamManager.on("peerdisconnected", this._onPeerConnected);
    this._streamManager.on("streamconnected", this._onStreamConnected);
    this._roomManager = roomManager;
  }

  @action
  startPtt = async (roomId?: string, subTeam?: string, userId?: string) => {
    await this._stateLock.acquireAsync();
    const userSettings = OTGlobals.localUserSettings;
    try {
      if (!this.inPtt) {
        const targets = this._getTargets(roomId, subTeam, userId);
        if (targets.length) {
          logger.debug("Starting ptt to ", targets);
          this._currentTargets = targets;
          this._connectedTargets = [];
          this._pttType = roomId ? "PTT_GLOBAL" : "PTT_WALKIE";
          this._streamManager.setCallUsers(targets);
          this._myStream = new WebcamStream(this._teamId, this._myUserId, userSettings);
          this._myStream.on("trackremoved", this.stopPtt);
          await this._myStream.enableAudio();
          this._streamManager.addStream(this._myStream);
          this._sendPTTMessage(this._currentTargets);
          this._writeState();
          if (this._pttType == "PTT_GLOBAL") {
            OTUserInterface.soundEffects.globalAlert();
          } else {
            OTUserInterface.soundEffects.alert();
          }
        }
      } else {
        logger.warn("Already in ptt");
      }
    } finally {
      this._stateLock.release();
    }

    return this.inPtt;
  };

  @action
  stopPtt = async () => {
    await this._stateLock.acquireAsync();

    try {
      if (this.inPtt) {
        logger.debug("Shutting down ptt");
        delete this._currentTargets;

        this._streamManager.shutdownStreams();
        this._myStream!.off("trackremoved", this.stopPtt);
        this._myStream!.shutdown();
      } else {
        logger.warn("Not in ptt");
      }
    } catch (err) {
      logger.error("Error shutting down PTT stream", err);
    } finally {
      this._streamManager.clearCallUsers();
      this._writeState();
      delete this._myStream;
      this._stateLock.release();
    }

    return this.inPtt;
  };

  processDoc = (doc: ITeamDoc) => {
    const myRoom = this._roomManager.getUserRoom(this._myUserId);
    this._canPttUsers = Object.values(doc.users)
      .filter((user) => {
        const online = userIsOnline(user).isOnline;
        const inBackground = user.status?.inBackground == true;
        const userRoom = this._roomManager.getUserRoom(user.id);

        return !myRoom && online && !userRoom && !inBackground;
      })
      .map((user) => user.id);

    if (this.inPtt) {
      const okTargets = this._currentTargets!.filter((userId) => this.canPttUser(userId));

      if (okTargets.length == 0) {
        this.stopPtt();
      } else if (okTargets.length < this._currentTargets!.length) {
        this._currentTargets = okTargets;
        this._streamManager.setCallUsers(okTargets);
      }
    }
  };

  setLastPtt(userId) {
    this._lastPttFrom = userId;
  }

  toggleLastPtt = () => {
    if (this._lastPttFrom) {
      if (
        this.inPtt &&
        this._currentTargets!.includes(this._lastPttFrom) &&
        this._currentTargets!.length == 1
      ) {
        logger.info("stopping ptt");

        this.stopPtt();
      } else {
        logger.info("starting ptt");

        this.startPtt(undefined, undefined, this._lastPttFrom);
      }
    }
  };

  _sendPTTMessage = (users: string[]) => {
    if (this.inPtt) {
      const msg: IActionMsg = {
        msgType: "ACTION",
        actionType: this._pttType!,
      };
      users.forEach((userId) => this._streamManager.sendMessage(userId, msg));
    }
  };

  _onConnected() {}
  _onDisconnected() {}

  _onPeerConnected = (userId) => {
    if (this.inPtt) {
      if (this._currentTargets!.includes(userId)) {
        this._sendPTTMessage([userId]);
      }
      this._writeState();
    }
  };

  _onPeerDisconnected() {}

  _onStreamConnected = (streamId, userId) => {
    // logger.debug(`Got stream connected for ${userId}, targets: ${this._currentTargets}, connected: ${this._connectedTargets}`)
    if (this.inPtt && this._currentTargets!.includes(userId)) {
      if (!this._connectedTargets!.includes(userId)) {
        this._connectedTargets!.push(userId);
        this._writeState();
      }
    }
  };

  get inPtt() {
    return this._currentTargets != undefined;
  }

  canPttUser(userId) {
    return this._canPttUsers.includes(userId);
  }

  _getTargets = (roomId?: string, subTeam?: string, userId?: string) => {
    logger.info(`_getTargets are roomId=${roomId} subTeam=${subTeam} userId=${userId}`);
    const teamData = OTGlobals.getTeamData(this._teamId);

    var targets: string[] = [];

    if (userId) {
      targets = [userId];
    } else if (roomId) {
      const room = teamData.rooms[roomId];
      if (room) {
        if (subTeam) {
          targets = Object.keys(room.subteams![subTeam]);
        } else {
          targets = Object.keys(
            Object.values(room.subteams || {}).reduce((users, stUsers) => {
              users = { ...users, ...stUsers };
              return users;
            }, room.users)
          );
        }
      } else {
        logger.warn(`Unable to load ${roomId} from PTT`);
      }
    }
    return targets.filter((userId) => this.canPttUser(userId));
  };

  _writeState() {
    const teamData = OTGlobals.getTeamData(this._teamId);

    if (this.inPtt) {
      const streams = {};
      streams[this._myUserId] = { camera: this._myStream?.stream.id };

      teamData.pttState = {
        targets: this._currentTargets!,
        connected: this._connectedTargets!,
        streams,
      };
    } else {
      teamData.pttState = undefined;
    }
  }
}

import React, { Component } from "react";
import { observer } from "mobx-react";
import { StyleSheet, View } from 'react-native';
import { Theme } from "../../globals/ThemeColors";
import * as StyleConstants from '../../globals/StyleConstants';
import { OTButton } from "../../components/buttons/OTButtons";
import { Image } from "react-native";
import { OnlineTile } from "../../components/userTile";
import * as Analytics from '../../utils/Analytics';
import { showEditRoom } from "../fragments/settings";
import { OTTouchableOpacity } from "../../components";
import { OTText } from "../../components/OTText";
import { SKSettings, SKLock } from "../../components/SVGIcons";
import { Logger } from '@openteam/app-util';
import { Feather } from '@expo/vector-icons';
import { Droppable } from "../../components/Droppable";
import { showToast } from "../../components/Toasts.web";
import { OTUITree } from "@openteam/app-core";
import {IOTRoomUser} from "@openteam/models";

const logger = new Logger("RoomView");

interface IRoomViewProps {
  teamId: string
  roomId: string
  goToTop?: () => void
}

interface IRoomViewState {

}

@observer
export class RoomView extends Component<IRoomViewProps, IRoomViewState> {
  enterRoom = () => {
    const teamManager = OTUITree.teamManagers[this.props.teamId];
    const teamData = teamManager.teamData;
    let room = teamData.rooms[this.props.roomId]

    teamManager.joinTeamRoom(this.props.roomId == 'online' ? null : this.props.roomId)
    Analytics.logEvent("room__enter_room", { roomId: this.props.roomId })

    if (room.config?.call) {
      this.props.goToTop && this.props.goToTop()
    }
  }

  leaveRoom = () => {
    const teamManager = OTUITree.teamManagers[this.props.teamId];
    teamManager.joinTeamRoom(null)
    Analytics.logEvent("room__leave_room", { roomId: this.props.roomId })
  }

  inviteToRoom = (userId) => {
    const teamManager = OTUITree.teamManagers[this.props.teamId];
    const teamData = teamManager.teamData;
    const room = teamData.rooms[this.props.roomId]
    const callRequestUIState = OTUITree.callRequestUIStates[this.props.teamId];

    if (!room.config?.call) {
      showToast("Can't drag user into the quiet room", "error")
    } else {
      callRequestUIState.sendCallUser(userId, undefined, undefined, this.props.roomId);
      Analytics.logEvent("room__invite_user", { roomId: this.props.roomId, userId })
    }
  }

  showEditRoom = () => {
    showEditRoom(this.props.teamId, this.props.roomId)
  }

  onDrop = (e) => {

    var data = e.dataTransfer.getData("userid");

    if (data == OTUITree.auth.userId) {
      this.enterRoom()
    } else {
      this.inviteToRoom(data)
    }

  }

  isInteresting = (e) => {
    const isMe = e.dataTransfer.types && e.dataTransfer.types.indexOf("userid") != -1

    return isMe
  }

  render() {
    logger.debug("Rendering roomView");

    const meUserId = OTUITree.auth.userId;
    const teamManager = OTUITree.teamManagers[this.props.teamId];
    const teamData = teamManager.teamData;
    let room = teamData.rooms[this.props.roomId]

    const { roomId, inRoom, config, users } = room;

    var onlineUsers = Object.keys(users || {}).filter(userId => users[userId].online || users[userId].inLeeway || (!config?.call && users[userId].hasMobile))

    var enterBackgroundColor = 'transparent'
    var enterTextColor = Theme.ButtonColor
    var enterBorderColor: string | undefined = Theme.ButtonColor

    const canEditRoom =
      teamData.isAdmin || room.config?.ownerUserId === meUserId || (inRoom && !room.config?.static);

    if (config?.static) {
      enterBackgroundColor = Theme.ButtonColor
      enterTextColor = Theme.ButtonIconColor
      enterBorderColor = undefined
    }

    return (
      <View style={[styles.roomContainer, { backgroundColor: Theme.RoomBackground }]}>
        <View style={styles.roomHeader}>
          {config?.imageUrl ? (
            <Image
              source={{ uri: config.imageUrl }}
              style={{
                width: 140,
                height: 100,
                margin: 10,
                borderRadius: StyleConstants.curviness,
              }}
            />
          ) : null}
          <View style={styles.roomDetails}>
            <View style={styles.roomTitleRow}>
              <OTText style={[styles.roomTitle, { color: Theme.MainText }]}>{config?.name}</OTText>
              {canEditRoom ? (
                <OTTouchableOpacity
                  data-tip="Room Settings"
                  onPress={this.showEditRoom}
                  style={{ marginLeft: 10 }}
                  analyticsEvent="room_settings_open"
                >
                  <SKSettings size={16} style={{ color: Theme.MainColour }} />
                </OTTouchableOpacity>
              ) : null}
              <View style={{ flex: 1 }} />

              {
                room.config?.isLocked ?
                  <View
                    // @ts-expect-error dataSet not part of types
                    dataSet={{ tip: "Room Locked" }}
                    style={[styles.iconBackground, { backgroundColor: Theme.IconBackground }]}>
                    <SKLock size={14} style={{ color: Theme.MainColour }} />
                  </View>
                  :
                  null
              }
              {
                room.config?.notify ?
                  <View
                    // @ts-expect-error dataSet not part of types
                    dataSet={{ tip: `Notifies ${room.config.notifyTarget || "everyone online"} when someone enters` }}
                    style={styles.iconBackground}>
                    <Feather name="bell" size={14} style={{ color: Theme.MainColour }} />
                  </View>
                  :
                  null
              }

            </View>
            <View style={styles.roomDescHolder}>
              <OTText
                // @ts-expect-error dataSet not part of types
                dataSet={{ tip: config?.desc }}
                style={[styles.roomDesc, { color: Theme.MainText }]} numberOfLines={2}>
                {config?.desc}
              </OTText>
            </View>
            {inRoom ? (
              <OTButton
                toolTip="Leave Room"
                onPress={this.leaveRoom}
                small={true}
                title={"Leave"}
                icon={
                  <Feather
                    name="log-out"
                    size={14}
                    style={{ color: Theme.ButtonNegativeColor, marginRight: 5 }}
                  />
                }
                backgroundColor={"transparent"}
                textColor={Theme.ButtonNegativeColor}
                borderColor={Theme.ButtonNegativeColor}
              />
            ) : !config?.isLocked || canEditRoom ? (
              <OTButton
                toolTip="Enter Room"
                onPress={this.enterRoom}
                small={true}
                title={"Enter"}
                icon={
                  <Feather
                    name="log-in"
                    size={14}
                    style={{ color: enterTextColor, marginRight: 5 }}
                  />
                }
                backgroundColor={enterBackgroundColor}
                textColor={enterTextColor}
                borderColor={enterBorderColor}
              />
            ) : null}
          </View>
        </View>
        <View style={{ height: 1, backgroundColor: Theme.SeparatorColor, margin: 5 }} />
        <View style={styles.roomUsersContainer}>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', maxWidth: "80vw" }}>

            {
              onlineUsers.length == 0 ?
                <View style={[
                  styles.emptyRoomContainer,
                  { backgroundColor: Theme.OfficeBackground }]}>
                  <OTText style={{ fontSize: 14, color: Theme.TertiaryText }}> No one in here </OTText>
                </View>
                :
                onlineUsers.map(
                  (userId) => (
                    this._renderUserTile(userId, users[userId])
                  ))
            }
          </View>

        </View>
        <Droppable
          isInteresting={this.isInteresting}
          renderCover={this.renderCover}
          onDrop={this.onDrop} />
      </View>
    )
  }

  renderCover = (overFrame, overTarget) => {
    return overFrame ?
      <View
        pointerEvents={"none"}
        style={{
          ...StyleSheet.absoluteFillObject,
          backgroundColor: 'rgba(255,255,255,0.5)',
          borderWidth: overTarget ? 5 : 1,
          borderColor: Theme.MainColour,
          borderStyle: overTarget ? 'solid' : 'dashed',
          borderRadius: Theme.curviness,
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        {
          overTarget ?
            <View style={{ alignItems: 'center' }}>
              <Feather name="log-in" size={32} style={{ color: overTarget ? Theme.MainColour : 'grey' }} />
              <OTText style={{ fontSize: 22, fontWeight: "500", color: Theme.MainColour }}>
                Enter Room
              </OTText>
            </View>
            :
            null
        }
      </View>
      :
      null
  }

  _renderUserTile = (userId: string, teamUser: IOTRoomUser) => {
    const key = `${userId}`
    return (

      <OnlineTile
        id={key}
        key={key}
        teamId={this.props.teamId}
        roomId={this.props.roomId}
        userId={userId}
        roomUser={teamUser}
        width={110}
        height={100}
      />

    )
  }
}



const styles = StyleSheet.create({
  roomContainer: {
    borderRadius: Theme.curviness * 1.5,
    padding: 10,
    marginTop: 10,
    marginRight: 10,
    minWidth: 250,
  },
  roomHeader: {
    flexDirection: 'row',
    height: 110,
  },
  roomDetails: {
    alignItems: 'flex-start',
    margin: 5,
    flex: 1,
  },
  roomTitleRow: {
    flexDirection: 'row',
    height: 16,
    alignItems: 'center',
    width: "100%"
  },
  roomTitle: {
    fontWeight: "600",
    fontSize: 16,
  },
  iconBackground: {
    height: 21,
    width: 21,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  roomDescHolder: {
    flex: 1,
    justifyContent: 'center'
  },
  roomDesc: {
    fontSize: 14,
    width: 150,

  },
  roomUsersContainer: {
    minHeight: 110,
    justifyContent: 'center',
  },
  emptyRoomContainer: {
    flex: 1,
    height: 50,
    margin: 5,
    borderRadius: Theme.curviness,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
})
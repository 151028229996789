export function reload(forceReload=false) {
  window.location.reload(forceReload)
}

export function getPath() {
  var path = window.location.pathname.replace('/', '')
  return path
}

export function setPath(path) {
  window.location.href = `/${path}`
}

export function getUrl() {
  return window.location.href
}

export function setUrl(href) {
  return window.location.href = href
}

export function changeHistory(title, path) {
  window.history.pushState(undefined, title, path);
}
import React, { Component } from "react";
import { View, StyleSheet } from "react-native";

import * as Fire from "../../globals/Fire";
import { OTUserData } from "../../data/OTData";
import * as Analytics from "../../utils/Analytics";

import validate from "validate.js";
import * as ModalService from "../ModalService";
import { showToast } from "../Toasts";
import { Logger } from "@openteam/app-util";
import { Theme } from "../../globals/ThemeColors";
import { OTText, OTTextInput } from "../OTText";
import { OTTouchableOpacity } from "..";
import { IUserTeamReq } from "models/src/team";
import { OTUITree, FireDb } from "@openteam/app-core";
import { getRTDB } from "../../globals/Fire/util";
import { DialogView } from "../DialogView";

const logger = new Logger("JoinTeam");

export const workspaceNameConstraints = {
  workspaceName: {
    presence: {
      allowEmpty: false,
      message: "^Please enter a space name",
    },
    format: {
      pattern: "^[a-z0-9_-]*$",
      message: "can only contain alphanumeric digits, underscores and dashes",
    },
  },
};

const asyncJoinConstraints = {
  workspaceName: {
    checkTeamExists: true,
  },
};

const asyncCreateConstraints = {
  workspaceName: {
    checkspacePath: true,
  },
};

validate.validators.checkTeamExists = function (value: string) {
  return new (validate as any).Promise(async function (resolve, reject) {
    var teamId = await FireDb.getTeamPathId(getRTDB(), value.toLowerCase());
    logger.debug("teamId for ", value, teamId);
    if (teamId) {
      var hasAccess = await FireDb.checkTeamAccess(getRTDB(), OTUITree.auth.userId, teamId);

      if (hasAccess) {
        resolve("already added");
      } else {
        resolve();
      }
    } else {
      resolve("doesn't exist");
    }
  });
};

validate.validators.checkspacePath = function (value: string) {
  return new (validate as any).Promise(async function (resolve, reject) {
    var teamId = await FireDb.getTeamPathId(getRTDB(), value.toLowerCase());
    logger.debug("teamId for", value, teamId);
    if (teamId) {
      resolve("already exists");
    } else {
      resolve();
    }
  });
};

interface IJoinTeamProps {
  onClose?: () => void;
  onJoin?: (teamId) => void;
  onCreate?: (teamId) => void;
}

interface IJoinTeamState {
  createMode: boolean;
  workspaceName: string;
  errors?: any[];
}

export default class JoinTeam extends Component<IJoinTeamProps, IJoinTeamState> {
  constructor(props) {
    super(props);

    this.state = {
      createMode: OTUserData.reqTeamPath ? false : true,
      workspaceName: OTUserData.reqTeamPath || "",
    };
  }

  componentDidMount = async () => {
    if (this.state.workspaceName) {
      try {
        await validate.async(this.state, workspaceNameConstraints);
      } catch (err) {
        this.setState({ errors: err });
      }
    }
  };

  toggleCreateMode = () => {
    this.setState({
      createMode: !this.state.createMode,
    });
  };

  getErrorMessages = (separator = "\n") => {
    if (!this.state.errors) {
      return [];
    }

    return Object.values(this.state.errors)
      .map((it: string[]) => it.join(separator))
      .join(separator);
  };

  onChangeText = (workspaceName) => {
    this.setState({ workspaceName: workspaceName.toLowerCase() });
  };

  joinTeam = async () => {
    this.setState({ errors: [] });

    try {
      await validate.async(this.state, workspaceNameConstraints);
      await validate.async(this.state, asyncJoinConstraints);
    } catch (err) {
      this.setState({ errors: err });
      return;
    }

    try {
      const teamPath = this.state.workspaceName;

      const result = await OTUITree.userManager.doTeamAccessReq(teamPath);
      
      this.props.onJoin && this.props.onJoin(result.teamId);
      this.props.onClose && this.props.onClose();
      Analytics.logEvent("jointeam_done");
      OTUserData.reqTeamPath = undefined;
      OTUserData.teamInviteId = undefined;
    } catch (err) {
      showToast("Failed to create workspace: " + err.message, "error");
    }
  };

  createTeam = async () => {
    try {
      await validate.async(this.state, workspaceNameConstraints);
      await validate.async(this.state, asyncCreateConstraints);
    } catch (err) {
      this.setState({ errors: err });
      return;
    }

    try {
      var teamId = await Fire.createTeam(this.state.workspaceName);

      // if im already logged in then reload the page to the new team, otherwise load the team
      // OTUserData.setCurrentTeam(teamId)
      this.props.onCreate && this.props.onCreate(teamId);
      this.props.onClose && this.props.onClose();
      Analytics.logEvent("createteam_done");
    } catch (err) {
      showToast("Failed to create space: " + err.message, "error");
    }
  };

  render() {
    var title = "Create your workspace";
    var desc = "What's the name of your company or team?";
    var buttonName = "Create workspace";
    var onPress = this.createTeam;

    if (!this.state.createMode) {
      title = "Join a workspace";
      desc = "Enter workspace name";
      buttonName = "Request to join";
      onPress = this.joinTeam;
    }

    return (
      <DialogView
        title={title}
        buttons={[
          {
            title: buttonName,
            onPress: onPress,
          },
        ]}
        footer={
          <OTTouchableOpacity
            onPress={this.toggleCreateMode}
            style={{ paddingTop: 20 }}
            analyticsEvent={this.state.createMode ? "createteam_showjoin" : "jointeam_showcreate"}
          >
            <OTText style={{ fontSize: 14, fontWeight: "500", color: Theme.OffDarkText }}>
              {this.state.createMode
                ? "Looking to join an existing team, click here"
                : "To create a new team, click here"}
            </OTText>
          </OTTouchableOpacity>
        }
      >
        <OTText style={{ fontWeight: "600", marginBottom: 20, fontSize: 16 }}>{desc}</OTText>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <OTText style={{ marginRight: 10, color: Theme.MainColour }}>openteam.space/</OTText>
          <OTTextInput
            style={styles.nameInput}
            value={this.state.workspaceName}
            onChangeText={this.onChangeText}
            onSubmitEditing={onPress}
            placeholder="Workspace Name"
          />
        </View>

        <OTText style={styles.error}>{this.getErrorMessages()}</OTText>
      </DialogView>
    );
  }
}

const styles = StyleSheet.create({
  row: {
    paddingTop: 15,
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  nameInput: {
    minWidth: 150,
  },
  error: { color: "red", textAlign: "center", paddingTop: 10 },
  titleText: {
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 20,
  },
  normalText: { fontWeight: "600", fontSize: 16 },
});

import React, { Component } from 'react';
import { Text, ViewStyle } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import * as StyleConstants from '../../globals/StyleConstants'
import { OTTouchableOpacity } from './OTTouchable';
import { OTText } from '../OTText';
import { Theme } from "../../globals/ThemeColors";

interface IAnchorProps {
    href: string
    title: string
    style?: ViewStyle
    backgroundColor?: string
    textColor?: string
    onPress?: () => void
}

interface IAnchorState {
}

const style: ViewStyle = {
    padding: 10,
    borderRadius: Theme.curviness,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: StyleConstants.PrimaryColour
};

export default class Anchor extends Component<IAnchorProps, IAnchorState> {
    _handlePress = () => {
        WebBrowser.openBrowserAsync(this.props.href);
        this.props.onPress && this.props.onPress();
    };

    render() {
        return (
            <OTTouchableOpacity style={[style, this.props.style]} onPress={this._handlePress} >
                <OTText style={{ color: this.props.textColor || 'white', fontSize: 14, fontWeight: "bold" }}>{this.props.title}</OTText>
            </OTTouchableOpacity>
        );
    }
}
import firebase from "firebase/app";
import "firebase/database";

import { ITeamCall, TReceiverStatus } from "@openteam/models";
import { Logger } from "@openteam/app-util";
import uuid from "react-native-uuid";


const logger = new Logger("CallRequestDb");

export class CallRequestDb {
  static watchCalls(
    fbDb: firebase.database.Database,
    teamId: string,
    callback: (recvUserId: string, doc: ITeamCall) => void
  ) {
    const teamCallPath = `/teams/${teamId}/calls`;

    fbDb.ref(teamCallPath).on("child_added", (snapshot) => callback(snapshot.key!, snapshot.val()));
    fbDb
      .ref(teamCallPath)
      .on("child_changed", (snapshot) => callback(snapshot.key!, snapshot.val()));
  }

  static unwatchCalls(fbDb: firebase.database.Database, teamId: string) {
    const teamCallPath = `/teams/${teamId}/calls`;

    fbDb.ref(teamCallPath).off("child_added");
    fbDb.ref(teamCallPath).off("child_changed");
  }

  static cancelCall = async (
    fbDb: firebase.database.Database,
    myUserId: string,
    teamId: string,
    userId: string
  ) => {
    const snapshot = await fbDb.ref("/teams/" + teamId + "/calls/" + userId).once("value");
    const callData: ITeamCall = snapshot.val();
    logger.debug("call", callData);

    if (callData.userId != myUserId) {
      return;
    }

    var callDoc = {
      senderStatus: "cancelled",
      active: false,
    };
    await fbDb.ref("/teams/" + teamId + "/calls/" + userId).update(callDoc);
  };

  static getCall = async (
    fbDb: firebase.database.Database,
    teamId: string,
    userId: string
  ): Promise<ITeamCall> => {
    const snapshot = await fbDb.ref("/teams/" + teamId + "/calls/" + userId).once("value");
    const callData: ITeamCall = snapshot.val();

    return callData;
  };

  static callUser = async (
    fbDb: firebase.database.Database,
    myUserId: string,
    mySessionToken: string,
    teamId: string,
    userId: string,
    currentRoomId?: string,
    callKey?: string,
    callName?: string
  ) => {
    var callDoc: ITeamCall = {
      callId: uuid.v1(),
      userId: myUserId,
      senderSessionToken: mySessionToken,
      active: true,
      roomId: currentRoomId || null,
      callKey: callKey || null,
      callName: callName || null,
      senderStatus: "calling",
      receiverStatus: "waiting",
    };

    await fbDb.ref("/teams/" + teamId + "/calls/" + userId).set(callDoc);
  };

  static respondToCall = async (
    fbDb: firebase.database.Database,
    mySessionToken: string,
    teamId: string,
    userId: string,
    receiverStatus: TReceiverStatus
  ) => {
    if (receiverStatus != "accepted") {
      await fbDb.ref("/teams/" + teamId + "/calls/" + userId).update({
        receiverStatus: receiverStatus,
        active: false,
      });
    } else {
      await fbDb.ref("/teams/" + teamId + "/calls/" + userId).update({
        receiverStatus: receiverStatus,
        receiverSessionToken: mySessionToken,
      });
    }

    // var callDoc: ITeamCall = {
    //   userId: OTUITree.auth.userId,
    //   active: true,
    //   senderStatus: "calling",
    //   receiverStatus: 'waiting'
    // }

    // getRTDB().ref('/teams/'+teamId + '/rooms/').push(roomObj).key
  };

  static joinCall = async (fbDb: firebase.database.Database, teamId: string, userId: string) => {
    await fbDb.ref("/teams/" + teamId + "/calls/" + userId).update({
      active: false,
    });
  };
}


export function OTRunOnce(callback: (...reqArgs) => Promise<any>) {
  var working = false;
  var currentArgs = undefined

  function wrapper(this: any, ...wrapArgs) {
    var self = this;
    var args = arguments as any;



    // Execute `callback` and update the `lastExec` timestamp.
    async function exec() {
      if (!working) {
        console.log("running!")

        working = true;
        currentArgs = args

        var response = await callback.apply(self, args);
        working = false
        if (args != currentArgs)  {

        }
      } else {
        console.log("not running because already executing")
        currentArgs = args

      }

    }
    exec()
  }
  return wrapper
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export class AwaitLock {
  private _acquired: boolean = false;
  private _waitingResolvers: (() => void)[] = [];

  acquireAsync(): Promise<void> {
    if (!this._acquired) {
      this._acquired = true;
      return Promise.resolve();
    }

    return new Promise(resolve => {
      this._waitingResolvers.push(resolve);
    });
  }

  tryAcquire(): boolean {
    if (!this._acquired) {
      this._acquired = true;
      return true;
    }

    return false;
  }

  release(): void {
    if (!this._acquired) {
      throw new Error(`Cannot release an unacquired lock`);
    }

    if (this._waitingResolvers.length > 0) {
      let resolve = this._waitingResolvers.shift()!;
      resolve();
    } else {
      this._acquired = false;
    }
  }
}
   import { TextStyle, ViewStyle, Platform, Dimensions, StyleSheet } from 'react-native';

// Change how rounded the interface is, 1-10;
export const curviness = 10;
export const PrimaryColour = '#306FB7';
export const PrimaryForeground = 'white';
export const SecondaryColour = '#EC6E7A';

export const NegativeColour = '#EC6E7A'
export const BackgroundGrey = '#EFEFF1'
export const GreyButton = '#EEEEEF'
export const GreyInput = '#EEEEEF'
export const SelectedColour = '#BBDFEF'

export const LinkColour = '#0366D6';

export const ModalImageContainerColour = 'rgba(255,255,255,0.9)'
export const ModalImageContainerPadding = 40

export const VolumeBarColour = '#FED217'
export const WarningColour = '#F6CC50'
export const HeaderColour = 'rgba(255,255,255,1)'
export const SidebarColour = HeaderColour;
export const MenuTeamOutlineColour = '#4665F6';
export const BadgeColour = 'red'
export const OfficeColour = HeaderColour
export const RoomColour = BackgroundGrey;
export const BorderColour = '#E9E9E9';
export const TileTitleBackground = PrimaryForeground;
export const ScrollbarTrackColour = OfficeColour
export const ScrollbarColour = 'rgba(0,0,0,0.6)'
export const StickyNoteColour = '#fcf6bd'

export const ConnectedColour = 'green';
export const ConnectingColour = 'grey';
export const DisconnectColour = '#EC6E7A';
export const IdleColour = 'orange';
export const InBackgroundColour = '#306FB7';

export const VideoButtonColour = 'rgba(0,0,0,0.5)'
export const TeamHeaderButtonColour = 'rgba(255,255,255,0.5)'
export const TeamHeaderIconColour = '#202227'

export const RecordingColour = 'red';
export const SharingColour = RecordingColour;
export const SharingBackground = PrimaryForeground;

export const TextGrey = '#7F828D'
export const IconGrey = '#7F828D'
export const IconDarkGrey = '#434445'
export const TextDarkGrey = '#434445'

export const MissedColour = '#D17476'

export const BugColour = '#F4AE96'
export const FeatureColour = '#9DC9F5'

export const BackgroundSource = {uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/webassets%2Fopenteambackground.jpg?alt=media"}
export const LogoSource = {uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fopenteamlogo.png?alt=media"}
export const IconSource = {uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fopenteamlogorow.png?alt=media"}

export const BoxShadow: ViewStyle = {
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowRadius: 10,
    shadowOffset: {
        height: 0, width: 0
    },
};

export const LightShadow: ViewStyle = {
    shadowColor: 'black',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowOffset: {
        height: 0, width: 0
    },
};

export const SelectedShadow: ViewStyle = {
    shadowColor: 'black',
    shadowOpacity: 0.6,
    shadowRadius: 10,
    shadowOffset: {
        height: 0, width: 0
    },
};

export const BoxGlow: ViewStyle = {
    ...BoxShadow,
    shadowColor: 'white',
};

export const TextShadow: TextStyle = {
    textShadowColor: "black",
    textShadowOffset: {
        width: 0,
        height: 0
    },
    textShadowRadius: 2
}

import React, { Component } from "react";
import { View, Text, ImageBackground, StyleSheet } from "react-native";
import { observer } from "mobx-react";

import * as StyleConstants from "../../globals/StyleConstants";
import Hoverable from "../../components/Hoverable";
import { UserVideo } from "./UserVideo";

import { getStringColour, getStatusColour } from "../../utils/OTUtils";
import { OTButton, OTTouchableOpacity } from "../../components";
import Volume from "./Volume";
import { RoomMsgs } from "../RoomMsgs";
import { toJS } from "mobx";
import { Image } from "react-native";
import { Theme } from "../../globals/ThemeColors";
import { SignalStrength } from "./SignalStrength";
import { OTText } from "../../components/OTText";
import { Feather } from "@expo/vector-icons";
import { PopoutButton } from "../../components/popoutWindow";
import { OTAppCoreData, OTGlobals, OTUITree } from "@openteam/app-core";
import { IOTRoomUser } from "models/src/room";

import { Logger } from "@openteam/app-util";
import { OTPopover } from "../OTPopover";
import { TStreamType } from "models/src/stream";
import { IPeerInfo } from "models/src/call";

const logger = new Logger("VideoTile");

interface IVideoUserProps {
  teamId: string;
  roomId: string;
  teamUser: IOTRoomUser;
  userId: string;
  streamId: string;
  focused?: boolean;
  matchAspectRatio?: boolean;
  pinned?: boolean;
  itemWidth: number;
  itemHeight: number;
  connected: boolean;
  enableMenu?: boolean;
  onSize?: (width, height) => void;
  onPress?: () => void;
}

interface IVideoUserState {
  userColor: string;
  showMenu: boolean;
  hovered: boolean;
}
@observer
export class VideoUser extends Component<IVideoUserProps, IVideoUserState> {
  isMe: boolean;

  constructor(props) {
    super(props);

    logger.debug("creating VideoUser for", this.props.userId);

    const user = this.props.teamUser;
    this.isMe = OTUITree.auth.isMe(this.props.userId);
    if (!user) {
      logger.error(`Unable to load user with id ${this.props.userId}`);
    }

    this.state = {
      userColor: getStringColour(user.name),
      showMenu: false,
      hovered: false,
    };
  }

  get streamInfo() {
    let { teamId, streamId } = this.props;
    const teamData = OTGlobals.getTeamData(teamId);
    return teamData.streams[streamId] || { streamType: "camera", muted: true, volume: 0 };
  }

  get size() {
    const { itemWidth } = this.props;
    return itemWidth > 600 ? "L" : itemWidth > 350 ? "M" : "S";
  }

  get callState() {
    return OTGlobals.callStateManager?.callState;
  }

  get peerInfo() {
    return this.callState?.peerInfo[this.props.userId] || ({} as IPeerInfo);
  }

  get faceDetect() {
    return this.streamInfo.streamType === "camera" ? this.callState?.faceDetect[this.props.userId] : undefined;
  }

  toggleShowMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };

  hideMenu = () => {
    this.setState({ showMenu: false });
  };

  togglePopoutMe = () => {
    const callStateManager = OTGlobals.callStateManager;
    callStateManager?.togglePopoutMe();
    this.hideMenu();
  };

  toggleUserSound = () => {
    const { userId } = this.props;
    const callStateManager = OTGlobals.callStateManager;
    callStateManager?.setDisableUserSound(userId, !callStateManager.disableUserSound[userId]);
    this.hideMenu();
  };

  setSameRoom = () => {
    const callStateManager = OTGlobals.callStateManager;
    callStateManager?.setSameRoom(this.props.userId);
    this.hideMenu();
  };

  clearSameRoom = () => {
    const callStateManager = OTGlobals.callStateManager;
    callStateManager?.setSameRoom(null);
    this.hideMenu();
  };

  focusStream = (focus: boolean) => {
    if (focus) {
      OTGlobals.callStateManager?.setFocusStream(this.props.userId, this.streamInfo.streamType);
    } else {
      OTGlobals.callStateManager?.setFocusStream(undefined, undefined);
    }
    this.hideMenu();
  };

  toggleFaceDetect = () => {
    OTGlobals.userSettingsManager.updateRemoteSettings({
      faceDetect: !OTGlobals.remoteUserSettings.faceDetect,
    });
    this.hideMenu();
  };

  render() {
    let {
      teamId,
      userId,
      teamUser: user,
      streamId,
      itemWidth,
      itemHeight,
      matchAspectRatio,
      connected,
      focused,
    } = this.props;
    const callStateManager = OTGlobals.callStateManager;
    const isMe = userId == OTUITree.auth.userId;

    const myAudioGroupId = this.callState?.audioGroupId;
    const { audioGroupId, volume, isAudioGroupSpeaker } = this.peerInfo;
    const { streamType, hasVideo, hasAudio, stream, muted } = this.streamInfo;

    if (!user) {
      logger.error(`No user doc for user ${this.props.userId}, crash imminent`);
      if (!__DEV__) {
        return <View />;
      }
    }

    var color = hasVideo ? "black" : this.state.userColor;

    var isSmall = itemWidth <= 240;

    if (hasVideo && (matchAspectRatio || focused)) {
      // im the big window so fit the aspect ratio of the video
      const videoTracks = stream.getVideoTracks();

      if (videoTracks.length > 0) {
        const videoTrack = videoTracks[0];

        const trackSettings = videoTrack.getSettings();
        const trackAspectRatio = trackSettings.aspectRatio;

        if (trackAspectRatio) {
          const curAspectRatio = itemWidth / itemHeight;

          if (trackAspectRatio > curAspectRatio) {
            // constrained by width

            itemHeight = itemWidth / trackAspectRatio;
          } else {
            // constrained by height
            itemWidth = itemHeight * trackAspectRatio;
          }
        }
      }
    }

    const userSoundDisabled = callStateManager?.disableUserSound[userId];
    const inMyAudioGroup = !isMe && audioGroupId !== undefined && audioGroupId === myAudioGroupId;

    var chats = toJS(callStateManager?.userRoomMsgs[this.props.userId] || []).reverse();
    var componentId = ["videochat", this.props.roomId, this.props.userId, streamId].join("-");

    const SSOffset = { L: 24, M: 16, S: 8 }[this.size];
    const controlSize = { L: 20, M: 16, S: 12 }[this.size];
    const fontSize = { L: 16, M: 16, S: 14 }[this.size];

    return (
      <Hoverable
        onHoverIn={() => this.setState({ hovered: true })}
        onHoverOut={() => this.setState({ hovered: false })}
      >
        <View>
          <OTTouchableOpacity
            activeOpacity={0.9}
            onPress={this.props.onPress}
            style={{
              width: itemWidth,
              height: itemHeight,
              backgroundColor: color,
              borderRadius: Theme.curviness,
              overflow: "hidden",
            }}
          >
            {user.imageUrl ? (
              <ImageBackground
                source={{ uri: user.imageUrl }}
                imageStyle={{ borderRadius: Theme.curviness }}
                style={{
                  width: itemWidth,
                  height: itemHeight,
                  ...StyleSheet.absoluteFillObject,
                }}
              ></ImageBackground>
            ) : null}

            {stream ? (
              <UserVideo
                key={componentId}
                componentId={componentId}
                flipVideo={this.isMe && streamType == "camera"}
                hasAudio={hasAudio && !this.isMe}
                muted={muted || userSoundDisabled || inMyAudioGroup}
                volume={volume}
                videoStream={stream}
                hasVideo={hasVideo}
                width={itemWidth}
                height={itemHeight}
                onSize={this.props.onSize}
                sinkId={OTGlobals.mediaDevices.audiooutput?.deviceId}
                enableFaceDetect={isMe && streamType == 'camera' && OTGlobals.remoteUserSettings.faceDetect}
                onFaceDetect={callStateManager?.onFaceDetect}
                faceDetect={this.faceDetect}
              />
            ) : null}
            {!isSmall || this.state.hovered ? (
              <View
                style={{
                  flexDirection: "row",
                  position: "absolute",
                  bottom: SSOffset,
                  left: SSOffset,
                  // backgroundColor: 'rgba(0,0,0,0.5)',
                  // borderRadius: Theme.curviness,
                  alignItems: "center",
                }}
              >
                <OTText numberOfLines={1} style={{ color: "white", fontWeight: "600", fontSize }}>
                  {user.name} {isMe ? "(me)" : undefined}
                </OTText>
              </View>
            ) : null}

            {(this.state.hovered || this.state.showMenu) && streamType == "camera" ? (
              <View
                style={{
                  position: "absolute",
                  right: SSOffset,
                  top: SSOffset,
                }}
              >
                <OTPopover
                  isOpen={this.state.showMenu}
                  toggleIsOpen={this.toggleShowMenu}
                  renderOpen={this.renderMenu}
                  placement="auto"
                  icon={
                    <View
                      style={[
                        styles.hoverDots,
                        {
                          padding: Math.ceil((controlSize * 1.6 - controlSize) / 2),
                          borderRadius: Theme.curviness / 2, //Math.ceil((controlSize * 1.6) / 2),
                        },
                      ]}
                    >
                      <Feather
                        name="more-vertical"
                        size={controlSize}
                        style={{ color: Theme.VideoButtonFG }}
                      />
                    </View>
                  }
                />
              </View>
            ) : null}

            {this.state.hovered && streamType == "screen" ? (
              <PopoutButton
                teamId={teamId}
                streamId={streamId}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  position: "absolute",
                  right: SSOffset,
                  top: SSOffset,
                }}
              />
            ) : null}

            <View style={{ position: "absolute", left: SSOffset, top: SSOffset }}>
              <SignalStrength
                userId={userId}
                teamId={teamId}
                streamId={streamId}
                size={this.size}
                forceShow={this.state.hovered}
              />
            </View>

            {streamType === "camera" ? (
              <View
                style={{
                  position: "absolute",
                  bottom: SSOffset,
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Volume
                  streamInfo={this.streamInfo}
                  size={this.size}
                  speakerDisabled={userSoundDisabled}
                  isAudioGroupSpeaker={isAudioGroupSpeaker}
                  inAudioGroup={inMyAudioGroup}
                  volumeFactor={volume}
                />
              </View>
            ) : null}
          </OTTouchableOpacity>

          <RoomMsgs
            chats={chats}
            sendChatMessage={callStateManager?.sendChatMessage}
            showAdd={false}
            showName={false}
            width={this.props.itemWidth}
          />
        </View>
      </Hoverable>
    );
  }

  renderMenu = () => {
    let { teamId, userId } = this.props;
    const teamData = OTGlobals.getTeamData(teamId);
    const { streamType } = this.streamInfo;
    const callStateManager = OTGlobals.callStateManager;
    const myAudioGroupId = this.callState?.audioGroupId;
    const audioGroupId = this.peerInfo?.audioGroupId;
    const userSoundDisabled = callStateManager?.disableUserSound[userId];
    const isMe = userId == OTUITree.auth.userId;
    const isFocused =
      callStateManager?.callState.focusUserId == userId &&
      callStateManager?.callState.focusStreamType == streamType;

    logger.debug(`user ${userId} peerInfo ${JSON.stringify(this.peerInfo)}`);
    logger.debug(`user ${userId} is in audio group ${audioGroupId}, myGroup ${myAudioGroupId}`);

    const inMyAudioGroup = !isMe && audioGroupId !== undefined && audioGroupId === myAudioGroupId;

    const items: any = [];

    if (isFocused) {
      items.push(this.renderMenuItem("Unfocus", () => this.focusStream(false)));
    } else {
      items.push(this.renderMenuItem("Focus", () => this.focusStream(true)));
    }

    if (isMe) {
      if (this.props.enableMenu) {
        if (callStateManager?.callState.popoutUser) {
          items.push(this.renderMenuItem("Include your video in grid", this.togglePopoutMe));
        } else {
          items.push(this.renderMenuItem("Minimise your video from grid", this.togglePopoutMe));
        }
      }

      if (myAudioGroupId) {
        items.push(
          this.renderMenuItem(
            "Clear same room",
            this.clearSameRoom,
            <Feather name="user-minus" style={styles.menuIcon} size={20} />
          )
        );
      }

      if (OTGlobals.remoteUserSettings.faceDetect) {
        items.push(this.renderMenuItem("Disable Face Detection", this.toggleFaceDetect));
      } else {
        items.push(this.renderMenuItem("Enable Face Detection", this.toggleFaceDetect));
      }
    } else {
      if (userSoundDisabled || inMyAudioGroup) {
        items.push(
          this.renderMenuItem(
            "Enable sound from user",
            this.toggleUserSound,
            <Feather name="volume-2" style={styles.menuIcon} size={20} />,
            inMyAudioGroup
          )
        );
      } else {
        items.push(
          this.renderMenuItem(
            "Disable sound from user",
            this.toggleUserSound,
            <Feather name="volume-x" style={[styles.menuIcon, styles.Negative]} size={20} />,
            inMyAudioGroup
          )
        );
      }

      if (inMyAudioGroup) {
        items.push(
          this.renderMenuItem(
            "Clear same room",
            this.clearSameRoom,
            <Feather name="user-minus" style={styles.menuIcon} size={20} />
          )
        );
      } else if (!(myAudioGroupId && audioGroupId)) {
        items.push(
          this.renderMenuItem(
            "Set same room",
            this.setSameRoom,
            <Feather name="user-plus" style={styles.menuIcon} size={20} />
          )
        );
      }
    }

    return <View style={{ flex: 1, paddingHorizontal: 5 }}>{items}</View>;
  };

  renderMenuItem(text: string, onPress: () => void, icon?: JSX.Element, disabled: boolean = false) {
    return (
      <Hoverable key={text}>
        {(isHovered) => (
          <OTTouchableOpacity
            style={[
              styles.menuButton,
              isHovered ? styles.menuButtonHovered : null,
              disabled ? styles.menuButtonDisabled : null,
            ]}
            onPress={onPress}
            disabled={disabled}
          >
            <OTText style={[styles.menuText, disabled ? styles.menuTextDisabled : null]}>
              {text}
            </OTText>
            {icon}
          </OTTouchableOpacity>
        )}
      </Hoverable>
    );
  }
}

const styles = StyleSheet.create({
  hoverDots: {
    backgroundColor: Theme.VideoButtonBG,
    alignItems: "center",
    justifyContent: "center",
  },
  menuText: {
    cursor: "pointer",
    fontWeight: "600",
  },
  menuTextDisabled: {
    cursor: "default",
    fontWeight: "400",
    opacity: 0.8,
    fontStyle: "italic",
  },
  menuIcon: {
    color: Theme.MainColour,
    paddingLeft: 10,
  },
  Negative: {
    color: Theme.ButtonNegativeColor,
  },
  menuButton: {
    minHeight: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginVertical: 2,
    borderRadius: Theme.curviness / 1.5,
    backgroundColor: "transparent",
  },
  menuButtonHovered: {
    backgroundColor: Theme.RowHover,
  },
  menuButtonDisabled: {
    backgroundColor: "transparent",
  },
});

import React, { Component } from 'react';
import { View, Image, Text, ImageBackground, ScrollView, Platform } from 'react-native';

import * as StyleConstants from '../globals/StyleConstants'
import * as Fire from '../globals/Fire';
import * as WindowUtils from '../utils/WindowUtils';
import { OTTouchableOpacity } from '../components';
import { LINKPROTOCOL } from '../globals/config';
import { Logger } from '@openteam/app-util';
import { PageHeader } from './fragments/PageHeader';
import { OTButton } from '../components/buttons/OTButtons';
import * as WebBrowser from 'expo-web-browser';
import { OTText } from '../components/OTText';
import { Theme } from '../globals/ThemeColors';
import { FontAwesome5 } from '@expo/vector-icons';

import {
  osName,
  deviceDetect,
  deviceType,
  isMobile,
  isMobileOnly,
  isIOS,
  isMacOs,
  isChrome,
  isSafari,
  isAndroid,
  isMobileSafari,
  isIPad13
} from "react-device-detect";
import { OTUserData } from '../data/OTData';

const logger = new Logger("DownloadRedirect")

export const platformDownloads = {

  'Mac OS': {
    'url': 'https://openteam.ams3.digitaloceanspaces.com/latest/OpenTeam.dmg',
    'icon': <FontAwesome5 name="apple" size={20} style={{ color: 'white', marginRight: 5 }} />
  },
  'Windows': {
    'url': 'https://openteam.ams3.digitaloceanspaces.com/latest/OpenTeam Setup.exe',
    'icon': <FontAwesome5 name="windows" size={20} style={{ color: 'white', marginRight: 5 }} />
  },
  'Linux': {
    'url': 'https://snapcraft.io/openteam',
    'icon': <FontAwesome5 name="linux" size={20} style={{ color: 'white', marginRight: 5 }} />
  },
}

interface IDownloadRedirectProps {
  incToken?: boolean
  onBrowserConnect: () => void
}

interface IDownloadRedirectState {
  loaded: boolean
  token?: string
}

export default class DownloadRedirect extends Component<IDownloadRedirectProps, IDownloadRedirectState> {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    }


  }

  componentDidMount = async () => {
    if (this.props.incToken) {
      this.downloadRedirect()
    }
    console.log("deviceDetect", deviceDetect())
  }

  downloadRedirect = async () => {
    var protocolURL = `${LINKPROTOCOL}:${OTUserData.reqTeamPath || ''}`

    var token = this.state.token

    if (!token) {
      token = await Fire.getCustomToken()

      this.setState({ loaded: true, token: token })
    }


    if (token) {
      protocolURL = protocolURL + `?token=${token}`
    }

    logger.info("protocolURL", protocolURL)
    WindowUtils.setUrl(protocolURL)
  }

  download = (osKey) => {
    var downloadDetails = platformDownloads[osKey]
    WindowUtils.setUrl(downloadDetails.url)
  }



  render() {
    return (
      <View style={{ flex: 1, backgroundColor: Theme.OfficeBackground }} >
        <ScrollView contentContainerStyle={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1, }}>

          <View style={{ flexDirection: 'row', width: "100vw", maxWidth: "100vw", flex: 1, justifyContent: 'center', flexWrap: 'wrap-reverse' }}>
            {
              !isMobile || isIPad13 ?
                <View style={{ flex: 3, minWidth: 300, alignItems: 'center', justifyContent: 'center' }}>
                  <Image
                    source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fwelcome.png?alt=media" }}
                    style={{ height: "60vh", minWidth: 300, minHeight: 250, width: "50vw" }} resizeMode="contain" />
                </View>
                :
                null
            }
            <View style={{ flex: 2, height: "100vh", minWidth: 300, alignItems: 'center', justifyContent: 'center', backgroundColor: Theme.HeaderBackground, paddingVertical: 80 }}>
              {this.renderDownload()}
            </View>
          </View>

          <PageHeader />

        </ScrollView>
      </View>
    )
  }

  renderDownload = () => {
    const trueOsName = osName == 'Ubuntu' ? 'Linux' : osName;

    const mainButton = platformDownloads[trueOsName]

    const remainingLinks = Object.keys(platformDownloads).filter(key => key != trueOsName)
    const isIOSNotSafari = isIOS && (!isMobileSafari || isChrome)
    const isMacOSSafari = isMacOs && isSafari && !isIPad13

    return (
      <View style={{ alignItems: 'center' }}>
        <View style={{ margin: 30, alignItems: 'center' }}>
          {
            mainButton ?

              <View style={{ alignItems: 'center' }}>
                <OTText style={{ fontSize: 25, fontWeight: "500", color: Theme.DarkText, marginBottom: 30 }}>
                  Download now
              </OTText>
                <View style={{ flexDirection: 'row' }}>
                  <OTButton
                    icon={mainButton.icon}
                    title={`Download for ${trueOsName}`}
                    href={mainButton.url}
                    outerStyle={{ paddingHorizontal: 30 }}
                    showShadow={true}
                  />
                </View>

                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                  <OTText style={{ color: Theme.DarkText, fontWeight: "500" }}>
                    Or download for
                  {
                      remainingLinks.map((osKey, index) => <OTText
                        key={osKey}
                        style={{ color: Theme.LinkColor, paddingRight: 5 }} accessibilityRole="link" href={platformDownloads[osKey].url}>

                        {
                          index > 0 ?
                            <OTText style={{ color: Theme.DarkText }}>
                              or
                         </OTText>
                            :
                            null
                        } {osKey}
                      </OTText>
                      )
                    }
                  </OTText>

                </View>
                <OTText style={{ color: Theme.DarkText, margin: 30 }}>
                  Or
                </OTText>
              </View>
              :
              null
          }

          <View style={{ flexDirection: 'row' }}>
            <OTButton
              title="Continue in Browser"
              icon={<FontAwesome5 name="globe-americas" size={16} style={{ color: Theme.ButtonColor, marginRight: 10 }} />}
              onPress={this.props.onBrowserConnect}
              backgroundColor={'rgba(255,255,255,0.8)'}
              textColor={Theme.ButtonColor}
              outerStyle={{ paddingHorizontal: 30 }}
              borderColor={Theme.ButtonColor}
              showShadow={true}
              disabled={isIOSNotSafari}
            />
          </View>
          {
            isIOSNotSafari ?
              <OTText style={{ textAlign: 'center', color: Theme.ButtonNegativeColor }}>
                OpenTeam is currently unsupported in this browser on iOS, please try Safari
            </OTText>
              :
              null
          }
          {
            isMacOSSafari ?
              <OTText style={{ textAlign: 'center', color: Theme.ButtonNegativeColor }}>
                OpenTeam is unoptimised for Safari, for optimal performance please use Google Chrome or install the desktop client
              </OTText>
              :
              null
          }
          {
            mainButton ?
              <OTTouchableOpacity style={{ marginTop: 20 }} onPress={this.downloadRedirect}>
                <OTText style={{ color: Theme.LinkColor, fontWeight: "500" }}><OTText style={{ color: Theme.DarkText }}>Already installed?</OTText> Launch OpenTeam</OTText>
              </OTTouchableOpacity>
              :
              null
          }
        </View>
      </View>
    )
  }
}
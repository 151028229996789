import { observer } from "mobx-react";
import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import { OTTouchableOpacity } from '.';
import { hitSlop5 } from '../utils/OTUtils';
import { Theme } from "../globals/ThemeColors";
import { SKMegaphone, SKKnock, SKMic, SKVideo } from "./SVGIcons";
import { Feather } from '@expo/vector-icons';
import { OTGlobals, OTUITree } from "@openteam/app-core";
import HeadIcon from "./HeadIcon";
import { OTText } from "./OTText";

interface IUserIconListProps {
  userIds: string[]
  teamId: string
  maxIcons: number
  onPress?: () => void
}

interface IUserIconListState {
}

@observer
export default class UserIconList extends Component<IUserIconListProps, IUserIconListState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    const { userIds, teamId } = this.props
    const teamData = OTGlobals.getTeamData(teamId);

    const users = userIds.map(userId => teamData.getTeamUser(userId))
    const tooltip = users.map(user => user.name).join(", ")

    const limitUserIds = userIds.slice(0, this.props.maxIcons)
    const remainder = userIds.length - limitUserIds.length
    return (
      <OTTouchableOpacity
        onPress={this.props.onPress}
        style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 10 }}
        dataSet={{ tip: tooltip }}>
        {limitUserIds.map(userId => this.renderUserIcon(userId))}

        {
          remainder ?
            <OTText style={{paddingLeft: 5}}>+{remainder}</OTText>
            :
            null
        }
      </OTTouchableOpacity>
    )

  }

  renderUserIcon = (userId: string) => {
    const { teamId } = this.props
    const teamData = OTGlobals.getTeamData(teamId);
    const userDoc = teamData.getTeamUser(userId)
    return (
      <View style={{marginLeft: -10}}>
        <HeadIcon size={30} name={userDoc.name} imageUrl={userDoc.imageUrl} />
      </View>
    )
  }

}

const styles = StyleSheet.create({
  iconHolder: {
    height: 17,
    width: 17,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 5
  }
})
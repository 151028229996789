import events from "events";
import uuid from "react-native-uuid";
import { computed, observable, reaction, action, toJS, autorun } from "mobx";
import { LocalStreamDetails, ScreenShareStream, WebcamStream } from "./MediaDeviceManager";
import { AwaitLock, Logger } from "@openteam/app-util";
import MediaSoupStreamManager from "./MediaSoup";
import { P2PStreamManager } from "./PeerConnection";

import {
  IChatMsg,
  IKickUserMsg,
  IMessageFile,
  IMuteUserMsg,
  IOTCallState,
  IOTChatMessage,
  IOTPluginTile,
  IOTRoomUser,
  IOTTile,
  IPeerMsg,
  IPendingMessage,
  TStreamType,
  IFaceDetect
} from "@openteam/models";
import { CloudUpload } from "./CloudUpload";
import { PluginManager } from "./PluginManager";
import { OTGlobals } from "./OTGlobals";
import { ExternalMeetingDb, FireDb } from "./fire";
import { OTUserInterface } from "./OTUserInterface";
import { OTAppCoreData } from "./OTAppCoreData";
import { TShowCallFeedback } from "./CallRequest";
import { generateTiles } from "./utils/generateTiles";
import { setStream } from "./utils/setStream";

const logger = new Logger("CallState");

export class CallStateManager extends events.EventEmitter {
  fbDb: firebase.database.Database;
  sessionToken: string;

  _streamManager!: P2PStreamManager | MediaSoupStreamManager;

  pluginManager: PluginManager;
  teamId: string;
  roomId: string;
  myUserId: string;

  _audioOn: boolean = false;
  _videoOn: boolean = false;
  _screenShareOn: boolean = false;
  _wantAudio: boolean = true;
  _wantVideo: boolean = true;

  pushToUnmute: boolean = false;
  connected: boolean = false;

  myStreams: Record<string, LocalStreamDetails> = {};

  _loudestLastChanged: number;
  _startTime: number;
  _screenShareStarted: number | undefined;

  stateLock = new AwaitLock();

  roomUsers: Record<string, boolean> = {};

  @observable roomMsgs: IOTChatMessage[] = [];
  @observable userRoomMsgs: Record<string, IOTChatMessage[]> = {};
  @observable viewingRoomMsgs: boolean = false;
  @observable draftMessage: string = "";
  @observable draftFiles: IMessageFile[] = [];
  @observable pendingMessages: Record<string, IPendingMessage> = {};
  @observable disableUserSound: { [id: string]: boolean } = {};

  @observable callState: IOTCallState;

  @observable _inCall: boolean = false;

  isStopping: boolean = false;

  showCallFeedback: TShowCallFeedback;

  _autorun: Record<string, any> = {};
  _reaction: Record<string, any> = {};

  constructor(
    fbDb: firebase.database.Database,
    userId: string,
    sessionToken: string,
    teamId: string,
    roomId: string,
    users: Record<string, IOTRoomUser>,
    showCallFeedback: TShowCallFeedback
  ) {
    super();

    this._loudestLastChanged = Date.now();

    this.fbDb = fbDb;
    this.myUserId = userId;
    this.sessionToken = sessionToken;
    this.showCallFeedback = showCallFeedback;

    logger.debug("Creating CallState for room", roomId, "user", this.myUserId);

    this.teamId = teamId;
    this.roomId = roomId;
    this._startTime = Date.now();

    const teamData = OTGlobals.getTeamData(this.teamId);
    const roomConfig = teamData.rooms[roomId].config;

    this._setupStreamManager();

    this.pluginManager = new PluginManager(this.fbDb, this.myUserId, this.teamId, this.roomId);

    this.pluginManager.on("pluginfocused", this.setFocusPlugin);
    this.pluginManager.on("plugindeleted", this.pluginDeleted);
    this.pluginManager.on("mediaplaying", this._onMediaPlaying);

    this.callState = {
      roomId: this.roomId,
      connectedUsers: [],
      unreadRoomMsgs: 0,
      popoutUser: true,
      streams: {},
      tiles: [],
      orderedTiles: [],
      popoutStreams: {},
      bandwidthLow: false,
      networkDisconnected: false,
      audioGroupId: null,
      peerInfo: {},
      faceDetect: {}
    };

    this.updateUsers(users);

    // This will trigger creation of streams so don't set until the end

    this._wantAudio = (roomId ? true : false) && !(roomConfig && roomConfig.micOff);
    this._wantVideo = roomConfig && roomConfig.call && !roomConfig.webcamOff ? true : false;

    this._updateStream("camera", this._wantAudio, this._wantVideo);

    this.startPresence();

    OTUserInterface.platformUtils.preventDisplaySleep();
  }

  startPresence = () => {
    const teamData = OTGlobals.getTeamData(this.teamId);
    const room = teamData.rooms[this.roomId];

    this._autorun["updateCallStateTiles"] = autorun(() => {
      const { streams } = this.callState;
      if (room) {
        if (
          this.callState.focusUserId &&
          this.callState.focusStreamType &&
          !streams[this.callState.focusUserId]?.[this.callState.focusStreamType]
        ) {
          if (
            !(
              room.users[this.callState.focusUserId]?.online ||
              room.users[this.callState.focusUserId]?.inLeeway
            ) ||
            this.callState.focusStreamType != "camera"
          ) {
            this.callState.focusUserId = undefined;
            this.callState.focusStreamType = undefined;
          }
        }

        var onlineUsers = Object.keys(room.users || {}).filter(
          (userId) => room.users[userId].online || room.users[userId].inLeeway
        );

        this.callState.tiles = generateTiles(onlineUsers, streams);
        Object.keys(this.callState.popoutStreams).forEach((streamId) => {
          if (!teamData.streams[streamId]) {
            delete this.callState?.popoutStreams[streamId];
          }
        });
      }
    });

    this.setupReactions();

    //    FireDb.setupRoomPresence(this.fbDb, this.teamId, this.myUserId, this.sessionToken, this.roomId);
    OTUserInterface.foregroundService.startCall(
      this.roomId,
      room.config?.name || "Meeting Room",
      "Call Ongoing"
    );
    this._inCall = true;
  };

  stopPresence = async () => {
    logger.info("removing room presence");
    /*     await FireDb.removeRoomPresence(
      this.fbDb,
      this.teamId,
      this.myUserId,
      this.sessionToken,
      this.roomId
    );
 */
    this._inCall = false;

    OTUserInterface.foregroundService.stopCall();

    Object.values(this._autorun).map((x) => x());
    this._autorun = {};

    Object.values(this._reaction).map((x) => x());
    this._autorun = {};
  };

  broadcastMessage = (msg: IPeerMsg) => {
    this._streamManager!.sendMessageToAll(msg);
  };

  sendMessage = (userId: string, msg: IPeerMsg) => {
    this._streamManager!.sendMessage(userId, msg);
  };

  addRoomMsg = (msg: IChatMsg) => {
    // might be better if this was pulled out in the display code
    const teamData = OTGlobals.getTeamData(this.teamId);

    const room = teamData.rooms[this.roomId];

    const roomUserDoc = room.users[msg.userId];

    const crDate = new Date(0);
    crDate.setUTCMilliseconds(msg.crDate);

    var chatMsg: IOTChatMessage = {
      id: `${msg.userId}${msg.crDate}`,
      crDate: crDate,
      teamId: this.teamId,
      channelId: "{msg.userId}${msg.crDate}",
      topicId: "default",
      userId: msg.userId,
      name: roomUserDoc.name,
      message: msg.data,
      messageNum: this.roomMsgs.length + 1,
      messageId: this.roomMsgs.length + 1,
      userImageUrl: roomUserDoc.imageUrl || null,
      files: msg.files,
    };

    this.roomMsgs.push(chatMsg);
    if (!this.userRoomMsgs[msg.userId]) {
      this.userRoomMsgs[msg.userId] = [];
    }
    this.userRoomMsgs[msg.userId].push(chatMsg);

    var msgDetails = OTUserInterface.parseEmojis(msg.data);

    if (!this.viewingRoomMsgs && msg.userId != this.myUserId && !msgDetails.isOnlyEmoji) {
      this.callState.unreadRoomMsgs += 1;
    }
  };

  addDraftFiles = (files: FileList | File[] | null) => {
    if (!files) {
      return;
    }

    var draftFiles = this.draftFiles ? [...this.draftFiles] : [];
    Object.keys(files || {}).forEach((i) => {
      var file = files[i];

      draftFiles.push(new CloudUpload(this.teamId, this.roomId, this.myUserId, "chat", file));
    });

    this.draftFiles = draftFiles;
  };

  setDraftFiles = (draftFiles: IMessageFile[]) => {
    this.draftFiles = draftFiles;
  };

  onFaceDetect = (faceDetect?: IFaceDetect) => {
    this.hdlFaceDetect(this.myUserId, faceDetect)
    this.broadcastMessage({
      msgType: "FACEDETECT",
      userId: this.myUserId,
      faceDetect: faceDetect,
    });
  };

  hdlFaceDetect = (userId: string, faceDetect?: IFaceDetect) => {
    logger.debug("hdlFaceDetect", userId, faceDetect);

    if (faceDetect) {
      this.callState.faceDetect[userId] = faceDetect;
    } else {
      delete this.callState.faceDetect[userId];
    }
  }

  sendChatMessage = async (text: string, files?: IMessageFile[]) => {
    this.sendURL(text);

    var tempId = uuid.v1();

    var message = {
      text,
      files,
    };

    this.pendingMessages[tempId] = message;

    if (files) {
      await Promise.all(files.map((cu) => cu.complete()));
    }

    const msg: IChatMsg = {
      msgType: "MESSAGE",
      userId: this.myUserId,
      crDate: Date.now(),
      data: text,
      files:
        files &&
        files.map((cu) => ({
          name: cu.file.name,
          type: cu.file.type,
          size: cu.file.size,
          url: cu.downloadUrl!,
        })),
    };
    this._streamManager.sendMessageToAll(msg);
    this.addRoomMsg(msg);

    delete this.pendingMessages[tempId];
  };

  sendURL = (text) => {
    if (OTUserInterface.platformUtils.PlatformOS == "mobile") {
      return;
    }

    var urlRegex = /((https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    var matches = [...text.matchAll(urlRegex)];

    matches.forEach((element) => {
      const url = element[0];
      const { pluginType, pluginArgs } = this.pluginManager.getUrlHandler(url);
      if (pluginType) {
        if (
          confirm(
            `We've detected that you're sharing a link (${url})\n\n` +
              `Would you like to open it for everyone using the ${pluginType} plugin?`
          )
        ) {
          this.pluginManager.createPlugin(pluginType, pluginArgs);
        }
      }
    });
  };

  setDisableUserSound = (userId: string, disable: boolean) => {
    logger.info("setting disableUserSound", userId, disable);
    this.disableUserSound[userId] = disable;
  };

  muteUser = (userId: string, mute: boolean) => {
    const msg: IMuteUserMsg = {
      msgType: "MUTE",
      mute: mute,
      userId: this.myUserId,
      crDate: Date.now(),
    };

    this._streamManager.sendMessage(userId, msg);
  };

  muteAll = (mute: boolean) => {
    const msg: IMuteUserMsg = {
      msgType: "MUTE",
      mute: mute,
      userId: this.myUserId,
      crDate: Date.now(),
    };
    this._streamManager.sendMessageToAll(msg);
  };

  removeTeamRoomUser = (roomId: string, userId: string) => {
    const msg: IKickUserMsg = {
      msgType: "CALLKICKED",
      userId: this.myUserId,
      crDate: Date.now(),
    };

    this._streamManager.sendMessage(userId, msg);

    ExternalMeetingDb.removeTeamRoomUser(this.fbDb, this.teamId, roomId, userId);
  };

  setViewingRoomMsgs = (isViewing: boolean) => {
    if (isViewing) {
      this.callState.unreadRoomMsgs = 0;
    }
    this.viewingRoomMsgs = isViewing;
  };

  @computed get audioOn() {
    return this.myStreams["camera"] && !this.myStreams["camera"].muted;
  }

  @computed get videoOn() {
    return this.myStreams["camera"] && this.myStreams["camera"].hasVideo;
  }

  @computed get screenShareOn() {
    return this.myStreams["screen"] && true;
  }

  toggleAudio = async () => {
    try {
      logger.debug(`toggleAudio from ${this._wantAudio}`);
      this._wantAudio = !(this._wantAudio && this.audioOn);
      await this._updateStream("camera", this._wantAudio, this.videoOn);
    } catch (err) {
      OTUserInterface.toastHandlers.show("Failed to access microphone: " + err.message, "error");
    }
  };

  toggleVideo = async () => {
    try {
      this._wantVideo = !(this._wantVideo && this.videoOn);
      await this._updateStream("camera", this.audioOn, this._wantVideo);
    } catch (err) {
      OTUserInterface.toastHandlers.show("Failed to access camera: " + err.message, "error");
    }
  };

  toggleScreenShare = async () => {
    try {
      if (!this.screenShareOn) {
        await this._updateStream("screen", true, true);
        this._screenShareStarted = Date.now();
      } else if (this.myStreams.screen) {
        await this._updateStream("screen", false, false);
        if (this._screenShareStarted) {
          OTGlobals.analytics?.logEvent("call_screenshare", {
            duration_secs: (Date.now() - this._screenShareStarted) / 100,
            quality: OTGlobals.localUserSettings.screenshareQuality,
          });
          this._screenShareStarted = undefined;
        }
      }
    } catch (err) {
      if (err.message) {
        OTUserInterface.toastHandlers.show("Failed to Share Screen: " + err.message, "error");
      }
    }
  };

  setPushToUnmute = (unmute: boolean) => {
    if (this.myStreams["camera"]?.hasAudio) {
      if (this.myStreams["camera"].muted && unmute) {
        this.pushToUnmute = true;
        this.myStreams["camera"].unmute();
      } else if (this.pushToUnmute && !this.myStreams["camera"].muted && !unmute) {
        this.myStreams["camera"].mute();
        this.pushToUnmute = false;
      }
    }
  };

  leaveCall = () => {
    this.emit("leavecall");
    OTGlobals.analytics?.logEvent("room__leave_room");
  };

  shutdown = async () => {
    await this.stateLock.acquireAsync();

    this.isStopping = true;

    const endTime = Date.now();
    const duration = (endTime - this._startTime) / 1000;

    try {
      if (this.pluginManager) {
        this.pluginManager.removeAllListeners();
        this.pluginManager.stop();
      }

      logger.debug(`Shutting down callstate for ${this.roomId}`);

      logger.debug(`Cancelling my ${Object.keys(this.myStreams)} streams`);
      this._streamManager.removeAllListeners();
      this._streamManager.shutdownStreams();
      await this._streamManager.stop();

      Object.keys(this.myStreams).forEach((kind) => {
        this.myStreams[kind].shutdown();
        delete this.myStreams[kind];
      });
      await this.stopPresence();

      OTUserInterface.platformUtils.allowDisplaySleep();

      if (Math.random() < (OTAppCoreData.remoteConfig?.CallFeedbackFactor || 0)) {
        this.showCallFeedback(this.teamId, this.myUserId, this.roomId, this._startTime, endTime);
      }
    } finally {
      this.stateLock.release();
    }

    return duration;
  };

  getUserStreams = (userId) => {
    return this._streamManager.getUserStreams(userId);
  };

  updateUsers = async (users: { [userId: string]: IOTRoomUser }) => {
    await this.stateLock.acquireAsync();

    try {
      logger.debug("Updating users");

      this._streamManager.updateUsers(users);

      logger.debug("Room ", this.roomId, " contains ", Object.keys(users));

      const roomUsers = {};

      Object.keys(users)
        .filter((userId) => userId != this.myUserId)
        .forEach((userId) => {
          roomUsers[userId] = this.roomUsers[userId] ?? false;
        });

      this.roomUsers = roomUsers;

      this._updateConected();
    } finally {
      this.stateLock.release();
    }
  };

  @action
  togglePopoutMe = () => {
    var newPopout = !this.callState.popoutUser;
    if (
      newPopout &&
      this.callState.focusUserId == this.myUserId &&
      this.callState.focusStreamType == "camera"
    ) {
      this.setFocusStream(undefined, undefined);
    }
    this.callState.popoutUser = newPopout;
  };

  setFocusRoom = (focusRoom: boolean) => {
    this.callState.focusRoom = focusRoom;
    if (focusRoom) {
      OTGlobals.analytics?.logEvent("roomchat__set_fullscreen");
    }
  };

  @action
  setFocusStream = (userId: string | undefined, streamType: TStreamType | undefined) => {
    if (userId && streamType) {
      this.setTileOrder({
        tileType: "stream",
        userId: userId,
        streamType: streamType,
        streamId: "",
      });

      if (this.callState.focusedPluginId) {
        this.pluginManager.setFocusPlugin(undefined);
      }

      if (userId == this.myUserId && streamType == "camera") {
        this.callState.popoutUser = false;
      }

      if (!this.callState.focusRoom) {
        this.setFocusRoom(true);
      }
    }

    this.callState.focusUserId = userId;
    this.callState.focusStreamType = streamType;
    logger.debug("setFocusStream", userId, streamType);
  };

  @action
  setFocusPlugin = (pluginId) => {
    if (pluginId) {
      this.callState.focusUserId = undefined;
      this.callState.focusStreamType = undefined;

      this.setTileOrder({ tileType: "plugin", pluginId: pluginId });

      if (!this.callState.focusRoom) {
        this.setFocusRoom(true);
      }
    }
    this.callState.focusedPluginId = pluginId;
    logger.debug("setFocusPlugin", pluginId);
  };

  pluginDeleted = (pluginId) => {
    this.setTileOrder({ tileType: "plugin", pluginId: pluginId }, true);
  };

  _onMediaPlaying = (pluginId, playing) => {
    if (pluginId == this.callState.focusedPluginId) {
      if (playing && this.audioOn) {
        logger.debug("Media playing, disabling audio");
        this.toggleAudio();
      } else if (!playing && !this.audioOn) {
        logger.debug("Media stopped, enabling audio");
        OTUserInterface.toastHandlers.show(
          "You have been unmuted as the media has stopped",
          "info"
        );
        this.toggleAudio();
      }
    } else {
      logger.info("Plugin not focussed, ignoring media playing event");
    }
  };

  @action
  setTileOrder = (tile: IOTTile | IOTPluginTile, remove: boolean = false) => {
    var index = -1;
    if (tile.tileType == "plugin") {
      index = this.callState.orderedTiles.findIndex(
        (t) => t.tileType == "plugin" && t.pluginId == tile.pluginId
      );
    }

    if (tile.tileType == "stream") {
      index = this.callState.orderedTiles.findIndex(
        (t) => t.tileType == "stream" && t.userId == tile.userId && t.streamType == tile.streamType
      );
    }

    if (index != -1) {
      this.callState.orderedTiles.splice(index, 1);
    }

    if (!remove) {
      this.callState.orderedTiles.unshift(tile);
    }
  };

  setFocusedUsers = (userIds: string[]) => {
    if (this._streamManager instanceof MediaSoupStreamManager) {
      //logger.debug("requesting high quality feed for", userIds);
      this._streamManager.setFocusedUsers(userIds);
    }
  };

  setSenderResolution = (layer?: number) => {
    if (this._streamManager instanceof MediaSoupStreamManager) {
      this._streamManager.updateSenderResolution(layer);
    }
  };

  setSameRoom = async (userId: string | null) => {
    if (this._streamManager instanceof MediaSoupStreamManager) {
      const audioGroupId = (await this._streamManager.setSameRoom(userId)).audioGroupId;
      this.callState.audioGroupId = audioGroupId
    }
  }

  requestStats = (userId: string, streamType: string) => {
    if (this._streamManager instanceof MediaSoupStreamManager) {
      this._streamManager.requestStats(userId, streamType);
    }
  };

  cancelStats = (userId: string, streamType: string) => {
    if (this._streamManager instanceof MediaSoupStreamManager) {
      this._streamManager.cancelStats(userId, streamType);
    }
  };

  _setupStreamManager = () => {
    const teamData = OTGlobals.getTeamData(this.teamId);
    const room = teamData.rooms[this.roomId];

    if (this.isStopping) {
      logger.info("Reached setupStreamManager but CallStateManager is stopping, doing nothing");
      return;
    } else if (room.users[this.myUserId]?.currentSessionToken != this.sessionToken) {
      logger.info(
        "Reached setupStreamManager but currentSessionToken doesn't match, doing nothing"
      );
      return;
    }

    if (OTAppCoreData.useSFU && room.config?.useSFU) {
      this._streamManager = new MediaSoupStreamManager(
        this.myUserId,
        this.sessionToken,
        this.teamId,
        this.roomId
      );
      //this._streamManager.on("streamsupdated", this._calcAllStreams)
    } else {
      logger.debug(`_setupStreamManager myUserId=${this.myUserId}`);
      this._streamManager = new P2PStreamManager(
        this.fbDb,
        this.myUserId,
        this.sessionToken,
        this.teamId,
        this.roomId
      );
    }
    this._streamManager.on("connected", this._onConnected);
    this._streamManager.on("peerconnected", this._onPeerConnected);
    this._streamManager.on("peerdisconnected", this._onPeerDisconnected);
    this._streamManager.on("disconnected", this._onDisconnected);
    this._streamManager.on("message", this._onMessage);
    this._streamManager.on("screenshare", this._onScreenShare);
    this._streamManager.on("speaker", this._onSpeaker);
    this._streamManager.on("producerscore", this._onProducerScore);
    this._streamManager.on("producerstats", this._onProducerStats);
    this._streamManager.on("bandwidth", this._onBandwidth);
    this._streamManager.on("network", this._onNetworkStatus);
    this._streamManager.on("audiogroup", (audioGroupId) => this.callState.audioGroupId = audioGroupId)
    this._streamManager.on("peerupdated", (peerId, peerInfo) => this.callState.peerInfo[peerId] = peerInfo)

    this._streamManager.start();
  };

  _onConnected = () => {
    this.connected = true;
    Object.values(this.myStreams).forEach((stream) => this._streamManager.addStream(stream));
  };

  _onPeerConnected = (userId, peerInfo, reconnect: boolean=false) => {
    if (Object.keys(this.roomUsers).includes(userId)) {
      logger.debug("Peer connected", userId);

      this.roomUsers[userId] = true;
      this.callState.peerInfo[userId] = peerInfo
      this._updateConected();
      if (!reconnect) {
        OTUserInterface.soundEffects.videoBell();
      }
      if (this.callState.faceDetect[this.myUserId]) {
        this.sendMessage(userId, {
          msgType: "FACEDETECT",
          userId: this.myUserId,
          faceDetect: this.callState.faceDetect[this.myUserId],
        })
      }
    } else {
      // This may happen in PTT scenarios
      logger.debug(`Peer ${userId} connected, but not in room`);
    }
  };

  _onPeerDisconnected = (userId) => {
    logger.debug("Peer disconnected", userId);

    const teamData = OTGlobals.getTeamData(this.teamId);
    const room = teamData.rooms[this.roomId];
    const user = room.users[userId];
    delete this.callState.peerInfo[userId];
    OTUserInterface.toastHandlers.show(`${user.name} has been disconnected`, "error");
  };

  _updateConected() {
    let connList = this._streamManager.connectedUsers;

    if (this.connected) {
      connList = [this.myUserId].concat(connList);
    }

    // Cannot set property 'connectedUsers' of undefined
    this.callState!.connectedUsers = connList;

    logger.debug("Setting connectedUsers", connList);
  }

  _onDisconnected = async () => {
    const wantAudio = this.audioOn;
    const wantVideo = this.videoOn;

    await this.stateLock.acquireAsync();

    try {
      logger.warn("Streamanager disconnected, cleaning up streams");
      this.connected = false;

      Object.keys(this.myStreams).forEach((kind) => {
        this.myStreams[kind].shutdown();
        delete this.myStreams[kind];
      });
      this._streamManager.removeAllListeners();
      await this._streamManager.stop();

      logger.warn("Creating StreamManager");
      this._setupStreamManager();
    } finally {
      this.stateLock.release();
    }

    await this._updateStream("camera", wantAudio, wantVideo);
    await this._updateStream("screen", this.screenShareOn, this.screenShareOn);
  };

  _onMessage = (userId, msg: IPeerMsg) => {
    if (msg.msgType == "MESSAGE") {
      logger.debug("received chat message", msg);
      this.addRoomMsg(msg);
    } else if (msg.msgType == "MUTE") {
      logger.debug("received mute message", msg, "this.audioOn", this.audioOn);

      if (msg.mute != !this.audioOn) {
        const teamData = OTGlobals.getTeamData(this.teamId);
        const room = teamData.rooms[this.roomId];

        const sendingUser = room.users[userId];
        if (msg.mute) {
          OTUserInterface.toastHandlers.show(`You have been muted by ${sendingUser.name}`, "error");
        } else {
          OTUserInterface.toastHandlers.show(
            `You have been unmuted by ${sendingUser.name}`,
            "success"
          );
          OTUserInterface.soundEffects.unmute();
        }

        this.toggleAudio();
      }
    } else if (msg.msgType == "CALLKICKED") {
      OTUserInterface.toastHandlers.show(`You have been removed from the meeting`, "error");
      this.emit("callkicked");
    } else if (msg.msgType == "FACEDETECT") {
      this.hdlFaceDetect(msg.userId, msg.faceDetect);
    } else {
      logger.warn("Unexpected meassge type:", msg.msgType);
    }
  };

  _onSpeaker = (stream) => {
    if (stream && Date.now() - this._loudestLastChanged > 1000) {
      this._loudestLastChanged = Date.now();
      this.callState.loudestStreamId = stream ? stream.stream.id : null;
    }
  };

  _onScreenShare = (userId, streamId) => {
    this.callState.screenshareStreamId = streamId;

    this.setFocusStream(userId, "screen");
    console.log("_onScreenShare");
    this.emit("screenshare", userId, streamId);
  };

  _onProducerScore = (streamType, kind, score: number) => {
    if (this.myStreams[streamType]) {
      this.myStreams[streamType].setScore(kind, score);
    }
  };

  _onProducerStats = (streamType, kind, stats: any) => {
    if (this.myStreams[streamType]) {
      this.myStreams[streamType].setStats(kind, stats);
    }
  };

  _onBandwidth = ({status, availableBitrate, effectiveDesiredBitrate}) => {
    this.callState.bandwidthLow = (status !== 'ok')
  }

  _onNetworkStatus = ({status}) => {
    if (status !== 'connecting') {
      // ignore the connecting status to avoid flashing when joining call
      this.callState.networkDisconnected = (status !== 'connected');
    }
  }

  _updateStream = async (
    streamType: "camera" | "screen",
    wantAudio: boolean,
    wantVideo: boolean
  ) => {
    await this.stateLock.acquireAsync();

    logger.debug(`Update ${streamType} stream, audio: ${wantAudio}, video ${wantVideo}`);

    try {
      let stream: LocalStreamDetails = this.myStreams[streamType] || null;

      if (!stream && (wantAudio || wantVideo)) {
        logger.debug(`Creating ${streamType} stream`);
        if (streamType == "camera") {
          stream = new WebcamStream(this.teamId, this.myUserId, OTGlobals.mediaDevices);
        } else {
          stream = new ScreenShareStream(this.teamId, this.myUserId, OTGlobals.getTeamData);
        }
      }

      if (stream) {
        logger.debug(`Audio, want: ${wantAudio}, have: ${stream.hasAudio}, muted: ${stream.muted}`);

        if (wantAudio && !stream.hasAudio) {
          await stream.enableAudio();
        } else if (wantAudio && stream.hasAudio && stream.muted) {
          await stream.unmute();
        } else if (!stream.muted && !wantAudio) {
          await stream.mute();
        }

        logger.debug(`Video, want: ${wantVideo}, have: ${stream.hasVideo}`);

        if (wantVideo && !stream.hasVideo) {
          await stream.enableVideo();
        } else if (stream.hasVideo && !wantVideo) {
          await stream.disableVideo();
        }

        if (!this.myStreams[streamType]) {
          this.myStreams[streamType] = stream;

          this._streamManager.addStream(stream);

          stream.on("trackremoved", this._trackRemoved);
        }

        setStream(this.teamId, this.roomId, this.myUserId, streamType, stream.stream!.id);
      }

      return stream;
    } finally {
      this.stateLock.release();
    }
  };

  _trackRemoved = async (track: MediaStreamTrack, stream: LocalStreamDetails) => {
    await this.stateLock.acquireAsync();
    try {
      const streamType = stream.streamType;
      if (!(stream.hasAudio || stream.hasVideo)) {
        this._streamManager.cancelStream(stream);
        if (stream.stream?.id == this.myStreams[stream.streamType]?.stream?.id) {
          delete this.myStreams[stream.streamType];
          setStream(this.teamId, this.roomId, this.myUserId, streamType);
        }
      }
    } finally {
      this.stateLock.release();
    }
  };

  setupReactions = () => {
    this._reaction["_devicesChangedReaction"] = reaction(
      () => {
        return [OTGlobals.mediaDevices.audio, OTGlobals.mediaDevices.video];
      },
      async () => {
        const mediaDevices = OTGlobals.mediaDevices;

        logger.info("camera settings changed", toJS(mediaDevices));
        if (this.myStreams["camera"]) {
          await this.myStreams["camera"].updateSettings(mediaDevices);
        } else {
          logger.debug("Camera stream not found, recreating");
          this._updateStream("camera", this._wantAudio, this._wantVideo);
        }
      }
    );

    this._reaction["_simulcastChangedReaction"] = reaction(
      () => {
        return OTGlobals.localUserSettings.videoSimulcastEnabled;
      },
      () => {
        const stream = this.myStreams["camera"];
        logger.debug("simulcast change for", stream);
        if (stream) {
          stream.bumpTrack("video");
        }
      }
    );

    this._reaction["_cameraQualityChangedReaction"] = reaction(
      () => {
        return OTGlobals.localUserSettings.cameraQuality;
      },
      () => {
        const stream = this.myStreams["camera"];
        if (stream) {
          logger.debug("quality change for camera");
          stream.applyConstraints("video");
        }
      }
    );

    this._reaction["_screenShareQualityChangedReaction"] = reaction(
      () => {
        return OTGlobals.localUserSettings.screenshareQuality;
      },
      () => {
        const stream = this.myStreams["screen"];
        if (stream) {
          logger.debug("quality change for camera");
          stream.applyConstraints("video");
        }
      }
    );
  };

  @computed get inCall() {
    return this._inCall;
  }

  @computed get inVideoChat() {
    return !!(this.inCall && this.callState.focusRoom && this.callState.tiles.length > 0);
  }
}

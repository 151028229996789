import debug, { Debugger } from "debug";
import { ILogSender } from "./ILogSender";

const NAMESPACE = "OT";
var APP_NAME = "default";
var LOGSENDER: ILogSender;

export function initLoggerApp(appName) {
  APP_NAME = appName;
}

export function setLogSender(sender: ILogSender) {
  LOGSENDER = sender;
}

debug.enable(`${NAMESPACE}*`);

export class Logger {
  _debug: Debugger;
  _info: Debugger;
  _warn: Debugger;
  _error: Debugger;
  prefix?: string;

  constructor(prefix?) {
    if (prefix) {
      this._debug = debug(`${NAMESPACE}:${prefix}`);
      this._info = debug(`${NAMESPACE}:INFO:${prefix}`);
      this._warn = debug(`${NAMESPACE}:WARN:${prefix}`);
      this._error = debug(`${NAMESPACE}:ERROR:${prefix}`);
    } else {
      this._debug = debug(`${NAMESPACE}`);
      this._info = debug(`${NAMESPACE}:INFO`);
      this._warn = debug(`${NAMESPACE}:WARN`);
      this._error = debug(`${NAMESPACE}:ERROR`);
    }

    this.prefix = prefix;

    this._debug.log = function () {
      console.debug.apply(console, arguments as any);
    };
    this._info.log = function () {
      console.info.apply(console, arguments as any);
    };
    this._warn.log = function () {
      console.warn.apply(console, arguments as any);
    };
    this._error.log = function () {
      console.error.apply(console, arguments as any);
    };
  }

  debug(...args) {
    this.wrapLoggly(this._debug, "debug", ...args);
  }

  info(...args) {
    this.wrapLoggly(this._info, "info", ...args);
  }

  warn(...args) {
    this.wrapLoggly(this._warn, "warn", ...args);
  }

  error(...args) {
    this.wrapLoggly(this._error, "error", ...args);
  }

  wrapLoggly = (logFunc, logLevel, ...args) => {
    logFunc(...args);

    const text: string | undefined = args
      .map((value) => (typeof value === "object" ? JSONStringify(value) : value))
      .join(", ");

    logLevel != "debug" &&
      LOGSENDER &&
      LOGSENDER.push({
        message: text,
        appName: APP_NAME,
        prefix: this.prefix,
        level: logLevel,
      });
  };
}

function JSONStringify(object: any) {
  const masks = ["fbDb"];

  const replacer = (k, v) => (masks.includes(k) ? undefined : v);
  try {
    return JSON.stringify(object, replacer);
  } catch (err) {
    return "[failed to JSON.Stringify Object]"
  }
}
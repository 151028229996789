import events from "events";

import {
  ITeamDoc,
  TShowMeetingModal,
  IIdleTimer,
  IShortcutManager,
  IShortcutManagerClass,
  IPushNotifyManagerClass,
  IPushNotifyManager,
} from "@openteam/models";

import { AwaitLock } from "@openteam/app-util";
import { Logger } from "@openteam/app-util";
import { FirebaseMessagingTypes } from "@react-native-firebase/messaging";
import { AppHomeManagerDb, SessionDb, TeamManagerDb } from "../fire";
import { OTUserInterface } from "../OTUserInterface";
import { TDismissModal, TShowCallFeedback, TShowCallRequestModal } from "../CallRequest";
import { OTUITree } from "../OTUITree";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { action, computed, observable } from "mobx";
import { TeamManager } from "../TeamManager";
import { FeedbackManager } from "../Feedback";
import { HeartbeatManager } from "../HeartbeatManager";
import { OTGlobals } from "../OTGlobals";
import { windowState } from "../WindowState";
import { AppHomeManagerStatus } from "./AppHomeManagerStatus";

const logger = new Logger("AppHomeManager");

export type THandleOpenNotification = (notification: FirebaseMessagingTypes.RemoteMessage) => void;

export class AppHomeManager extends events.EventEmitter {
  fbDb: firebase.database.Database;
  fsDb: firebase.firestore.Firestore;
  myUserId: string;
  sessionToken: string;

  showMeetingModal: TShowMeetingModal;
  showCallRequestModal: TShowCallRequestModal;
  showCallFeedback: TShowCallFeedback;
  dismissModal: TDismissModal;
  endActiveSessionCallback: (reload: boolean) => Promise<void>;
  idleTimer: IIdleTimer;
  shortcutManagerClass: IShortcutManagerClass;
  pushNotifyManagerClass: IPushNotifyManagerClass;

  @observable status: AppHomeManagerStatus = AppHomeManagerStatus.RequiresInit;

  @observable teamIdList: Record<string, boolean> = {};
  @observable teamManagers: Record<string, TeamManager> = {};

  shortcutManager?: IShortcutManager;
  feedbackManager?: FeedbackManager;
  pushNotifyManager: IPushNotifyManager;
  heartbeatManager?: HeartbeatManager;

  constructor(
    fbDb: firebase.database.Database,
    fsDb: firebase.firestore.Firestore,
    myUserId: string,
    sessionToken: string,
    endActiveSessionCallback: (reload: boolean) => Promise<void>,
    showMeetingModal: TShowMeetingModal,
    showCallRequestModal: TShowCallRequestModal,
    showCallFeedback: TShowCallFeedback,
    dismissModal: TDismissModal,
    idleTimer: IIdleTimer,
    shortCutManagerClass: IShortcutManagerClass,
    pushNotifyManagerClass: IPushNotifyManagerClass
  ) {
    super();

    this.fbDb = fbDb;
    this.fsDb = fsDb;
    this.myUserId = myUserId;
    this.sessionToken = sessionToken;

    this.endActiveSessionCallback = endActiveSessionCallback;
    this.showMeetingModal = showMeetingModal;
    this.showCallRequestModal = showCallRequestModal;
    this.showCallFeedback = showCallFeedback;
    this.dismissModal = dismissModal;
    this.idleTimer = idleTimer;
    this.shortcutManagerClass = shortCutManagerClass;
    this.pushNotifyManagerClass = pushNotifyManagerClass;

    this.pushNotifyManager = new this.pushNotifyManagerClass();

    OTGlobals.registerGetTeamData((teamId: string) => this.teamManagers[teamId].teamData);
    OTGlobals.registerGetUnsafeTeamData((teamId: string) => this.teamManagers[teamId]?.teamData);

    OTUITree.registerAppHomeManager(this);
  }

  onInit?: () => void;
  _stateLock = new AwaitLock();

  init = async () => {
    if (this.status !== AppHomeManagerStatus.RequiresInit) {
      logger.debug("can't start status=%s", this.status);
      return;
    }
    logger.info("Initalising...");

    const teamListDoc = OTGlobals.cache.getCache(this.myUserId, "teamlist", "global");
    if (teamListDoc) {
      await this.handleTeamList(teamListDoc, true);
    }

    AppHomeManagerDb.watchTeamList(this.fbDb, this.myUserId, this.handleTeamList);

    this.status = AppHomeManagerStatus.Stopped;

    logger.info("Init complete");

    this.emit("init");
  };

  handleTeamList = async (doc: Record<string, boolean>, isCached: boolean = false) => {
    doc = doc || {};

    logger.info(
      "handleTeamList doc=%o teamIdList=%o isCached=%s",
      Object.keys(doc),
      Object.keys(this.teamIdList),
      isCached
    );

    for (let teamId of Object.keys(doc)) {
      logger.info("watching for ", teamId);

      if (!(teamId in this.teamIdList)) {
        const teamDoc = OTGlobals.cache.getCache(this.myUserId, "team", teamId);

        if (teamDoc) {
          await this.handleTeam(teamId, teamDoc, true);
        }

        AppHomeManagerDb.watchTeam(this.fbDb, teamId, this.handleTeam, (err) => {
          logger.info("watch team rejected, will remove team");
          this.handleTeamRemove(teamId);
        });
      }
    }

    for (let teamId of Object.keys(this.teamIdList)) {
      if (!(teamId in doc)) {
        AppHomeManagerDb.unwatchTeam(this.fbDb, teamId);
        this.handleTeamRemove(teamId);
      }
    }

    OTGlobals.auth.userManager?.handleTeamList(doc);

    this.teamIdList = doc;

    OTGlobals.cache.setCache(this.myUserId, "teamlist", "global", doc);
  };

  start = async () => {
    await this._stateLock.acquireAsync();
    logger.debug("starting");

    try {
      if (this.status !== AppHomeManagerStatus.Stopped) {
        logger.debug("Can't start statue=%s", this.status);
        return;
      }

      if (OTUserInterface.platformUtils.PlatformOS == "mobile") {
        AppHomeManagerDb.registerDeviceUser(this.fbDb, this.myUserId, this.sessionToken);
        this.pushNotifyManager.init();
        this.pushNotifyManager.on("notification", this.handleNotification);
        this.pushNotifyManager.on("notificationOpen", this.handleOpenNotification);
      } else {
        SessionDb.setupAppPresence(
          this.fbDb,
          this.myUserId,
          this.sessionToken,
          this.endActiveSessionCallback
        );

        this.shortcutManager = new this.shortcutManagerClass();
        this.feedbackManager = new FeedbackManager(this.fbDb, this.myUserId);
        this.feedbackManager.start();

        this.setupIdleTimer();
      }

      this.heartbeatManager = new HeartbeatManager();

      Object.keys(this.teamManagers).forEach((teamId) => {
        this.teamManagers[teamId].start();
        logger.info("start: starting Team", teamId);
      });

      const userManager = OTGlobals.auth.userManager;

      if (!userManager.currentTeamId) {
        if (userManager.userDoc!.teamId) {
          userManager.setCurrentTeam(userManager.userDoc!.teamId);
        } else if (Object.keys(userManager.userTeams).length > 0) {
          userManager.setCurrentTeam(Object.keys(userManager.userTeams)[0]);
        }
      }

      if (userManager.currentTeamId && !userManager.userTeams[userManager.currentTeamId]) {
        if (userManager.currentTeamId in userManager.reqTeams) {
          logger.debug("not loading team, access is being requested");
        } else {
          logger.debug("haven't loaded current team, do it now", userManager.currentTeamId);
          try {
            const teamDoc = await AppHomeManagerDb.getTeam(this.fbDb, userManager.currentTeamId);
            this.handleTeam(userManager.currentTeamId, teamDoc);
          } catch (err) {
            logger.debug("error trying to load team, most likely permission error", err);
          }
        }
      }

      logger.debug("running");
      this.status = AppHomeManagerStatus.Running;
    } finally {
      this._stateLock.release();
      logger.debug("start done");
    }
  };

  stop = async () => {
    logger.debug("stopping");

    if (
      !(
        this.status === AppHomeManagerStatus.Running || this.status === AppHomeManagerStatus.Stopped
      )
    ) {
      logger.debug("Can't stop status=%s", this.status);
      return;
    }

    this.unwatchTeams();

    for (let teamId of Object.keys(this.teamManagers)) {
      await this.teamManagers[teamId].stop();
      logger.info("stop teamId=%s", teamId);
    }

    if (this.feedbackManager) {
      this.feedbackManager.stop();
      this.feedbackManager = undefined;
    }
    logger.debug("stopped");

    this.status = AppHomeManagerStatus.RequiresInit;
  };

  @action reset() {
    this.teamManagers = {};
    this.teamIdList = {};
  }

  signOut = async () => {
    if (OTUserInterface.platformUtils.PlatformOS == "mobile") {
      AppHomeManagerDb.unregisterDeviceUser(this.fbDb, this.myUserId);
      const unregistrations = Object.keys(this.teamManagers).map((teamId) =>
        TeamManagerDb.unregisterDeviceTeamUser(this.fbDb, this.myUserId, teamId)
      );
      Promise.all(unregistrations);
    }

    await SessionDb.removeAppSession(
      this.fbDb,
      this.myUserId,
      this.sessionToken,
      Object.keys(this.teamIdList || {})
    );

    AsyncStorage.clear();

    //await FireAuth.signOut();
  };

  setupIdleTimer = () => {
    this.idleTimer.on("present", this.handlePresent);
    this.idleTimer.on("idle", this.handleIdle);
    this.idleTimer.on("show", this.handleShow);
  };

  unwatchTeams = () => {
    AppHomeManagerDb.unwatchTeamList(this.fbDb, this.myUserId);

    for (let teamId of Object.keys(OTGlobals.auth.userManager.userTeams)) {
      AppHomeManagerDb.unwatchTeam(this.fbDb, teamId);
    }
  };

  handlePresent = (present: boolean) => {
    windowState.windowFocused = present;
  };

  handleShow = (show: boolean) => {
    logger.debug("windowShown", show);
    windowState.windowShown = show;
  };

  handleIdle = (idle: boolean) => {
    if (idle && this.inCall) {
      logger.debug("ignoring idle AppHomeManager.inCall", this.inCall);
      idle = false;
    }

    if (OTGlobals.isIdle != idle) {
      logger.debug("idle changed", idle);
      OTGlobals.isIdle = idle;
    }
  };

  handleTeam = async (teamId: string, doc: ITeamDoc, isCached: boolean = false) => {
    await this._stateLock.acquireAsync();
    //logger.debug("handleTeam, doc", doc)

    try {
      if (doc) {
        OTGlobals.auth.userManager.userTeams[teamId] = doc;

        if (!this.teamManagers[teamId]) {
          logger.info("creating Team", teamId);

          this.teamManagers[teamId] = new TeamManager(
            this.fbDb,
            this.fsDb,
            this.myUserId,
            this.sessionToken,
            teamId,
            this.showMeetingModal,
            this.showCallRequestModal,
            this.showCallFeedback,
            this.dismissModal,
            this.handleNotification
          );

          await this.teamManagers[teamId].handleTeamDoc(doc);

          if (this.status === AppHomeManagerStatus.Running) {
            logger.info("handleTeams: starting Team", teamId);
            this.teamManagers[teamId].start();
          }
        } else {
          await this.teamManagers[teamId].handleTeamDoc(doc);
        }
      } else {
      }

      if (!isCached) {
        OTGlobals.cache.setCache(this.myUserId, "team", teamId, doc);
      }
    } finally {
      this._stateLock.release();
    }
  };

  handleTeamRemove = async (teamId: string) => {
    await this._stateLock.acquireAsync();
    logger.debug("removing team", teamId);

    const userManager = OTGlobals.auth.userManager;

    try {
      delete userManager.userTeams[teamId];

      if (this.teamManagers[teamId]) {
        this.teamManagers[teamId].stop();
        delete this.teamManagers[teamId];
      }

      OTGlobals.cache.setCache(this.myUserId, "team", teamId, null);
    } finally {
      this._stateLock.release();
      logger.debug("handleTeamRemove done");
    }
  };

  handleNotification = (notification: FirebaseMessagingTypes.RemoteMessage) => {
    if (notification.data?.teamId) {
      const teamManager = this.teamManagers[notification.data?.teamId];
      if (teamManager) {
        teamManager.handleNotification(notification);
      }
    } else {
      console.log("show apphomemanager notification", notification);

      OTUserInterface.toastHandlers.show(
        notification.notification?.title,
        "info",
        notification.notification?.body,
        () => this.handleOpenNotification(notification)
      );
    }
  };

  handleOpenNotification: THandleOpenNotification = (notification) => {
    logger.info("handleOpenNotification", notification);
    if (notification.data?.teamId) {
      OTGlobals.auth.userManager.setCurrentTeam(notification.data?.teamId);

      if (notification.data?.type == "CHAT") {
        logger.info("navigating to chat", {
          teamId: notification.data.teamId,
          channelId: notification.data.channelId,
          topicId: notification.data.topicId,
        });
        OTUserInterface.navigate("Chat", {
          teamId: notification.data.teamId,
          channelId: notification.data.channelId,
          topicId: notification.data.topicId,
        });
      }
    }
  };

  @computed get inCall() {
    return OTGlobals.callStateManager?.inCall;
    /*     return Object.values(this.teamManagers)
      .map((teamManager) => teamManager.teamData.inCall)
      .reduce((a, b) => a || b, false);
 */
  }

  leaveTeam = async (teamId: string) => {
    logger.info("leaving team, teamId=%s", teamId);
    //    AppHomeManagerDb.unwatchTeam(this.fbDb, teamId);
    this.teamManagers[teamId].leaveTeam();
    OTGlobals.auth.userManager.unsetCurrentTeam(teamId);
  };
}

import React, { memo, useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Platform,
  ActivityIndicator,
  FlatList,
  ListRenderItemInfo,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import emoji from "emoji-datasource";
import { OTTouchableOpacity } from "./buttons";
import { Feather } from "@expo/vector-icons";

interface ICategory {
  symbol: string | null;
  name: string;
}

export const Categories: { [id: string]: ICategory } = {
  all: {
    symbol: null,
    name: "All",
  },
  history: {
    symbol: "🕘",
    name: "Recently used",
  },
  emotion: {
    symbol: "😀",
    name: "Smileys & Emotion",
  },
  people: {
    symbol: "🧑",
    name: "People & Body",
  },
  nature: {
    symbol: "🦄",
    name: "Animals & Nature",
  },
  food: {
    symbol: "🍔",
    name: "Food & Drink",
  },
  activities: {
    symbol: "⚾️",
    name: "Activities",
  },
  places: {
    symbol: "✈️",
    name: "Travel & Places",
  },
  objects: {
    symbol: "💡",
    name: "Objects",
  },
  symbols: {
    symbol: "🔣",
    name: "Symbols",
  },
  flags: {
    symbol: "🏳️‍🌈",
    name: "Flags",
  },
};

interface IEmoji {
  name: string;
  unified: string;
  non_qualified: string;
  docomo: string;
  au: string;
  softbank: string;
  google: string;
  image: string;
  sheet_x: number;
  sheet_y: number;
  short_name: string;
  short_names: string[];
  text?: any;
  texts?: any;
  category: string;
  sort_order: number;
  added_in: string;
  has_img_apple: boolean;
  has_img_google: boolean;
  has_img_twitter: boolean;
  has_img_facebook: boolean;
}

const charFromUtf16 = (utf16: any) =>
  String.fromCodePoint(...utf16.split("-").map((u) => "0x" + u));
export const charFromEmojiObject = (obj) => charFromUtf16(obj.unified);
const filteredEmojis = emoji.filter((e) => !e["obsoleted_by"]);
const emojiByCategory = (category) => filteredEmojis.filter((e) => e.category === category);
const sortEmoji = (list) => list.sort((a, b) => a.sort_order - b.sort_order);

let emojiList = {};
Object.keys(Categories).forEach((c) => {
  let name = Categories[c].name;
  emojiList[c] = sortEmoji(emojiByCategory(name));
});

interface ITabBarProps {
  activeCategory: string;
  onPress: (category: string) => void;
}

const TabBar: React.FC<ITabBarProps> = ({ activeCategory, onPress }: ITabBarProps) => {
  return (
    <View style={{ flexDirection: "row", paddingHorizontal: 10, paddingTop: 10 }}>
      {Object.keys(Categories)
        .filter((c) => c !== "all")
        .map((c) => {
          const category = Categories[c];
          return (
            <TouchableOpacity
              key={category.name}
              onPress={() => onPress(c)}
              style={{
                flex: 1,
                borderColor: c === activeCategory ? "#aaa" : "transparent",
                borderBottomWidth: 4,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  marginHorizontal: 5,
                  fontSize: 24,
                }}
              >
                {category.symbol}
              </Text>
            </TouchableOpacity>
          );
        })}
    </View>
  );
};

interface IEmojiCellProps {
  emoji: IEmoji;
  onPress: () => void;
}


const EmojiCell: React.FC<IEmojiCellProps> = memo(({ emoji, onPress }) => (
  <TouchableOpacity
    activeOpacity={0.5}
    style={{
      width: 42,
      height: 42,
      alignItems: "center",
      justifyContent: "center",
    }}
    onPress={onPress}
  >
    <Text style={{ color: "#FFFFFF", fontSize: 42 - 12 }}>{charFromUtf16(emoji.unified)}</Text>
  </TouchableOpacity>
));

interface IEmojiPickerProps {
  onEmojiSelected: (emoji: string) => void;
}

export const EmojiPicker: React.FC<IEmojiPickerProps> = ({ onEmojiSelected }) => {
  const [history, setHistory] = useState<IEmoji[]>([]);
  const [category, setCategory] = useState("history");
  const [searchQuery, setSearchQuery] = useState("");
  const storage_key = "@openteam/emoji/history";

  useEffect(() => {
    async function fetchSaved() {
      let savedhistory = await AsyncStorage.getItem(storage_key);
      if (savedhistory) {
        let json = JSON.parse(savedhistory);
        setHistory(json);
      }
    }
    fetchSaved();
  }, []);

  const addHistory = (emoji: IEmoji) => {
    var index = history.findIndex((h) => h.name == emoji.name);
    if (index != -1) {
      history.splice(index, 1);
    }

    history.unshift(emoji);
    setHistory(history);

    AsyncStorage.setItem(storage_key, JSON.stringify(history));
  };

  const Searchbar = (
    <View
      style={{
        margin: 10,
        paddingHorizontal: 10,
        backgroundColor: "#eee",
        borderRadius: 10,
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <TextInput
        style={{
          paddingVertical: 10,
          flex: 1,
        }}
        placeholder={"Search Emoji"}
        clearButtonMode="always"
        returnKeyType="done"
        autoCorrect={false}
        value={searchQuery}
        onChangeText={setSearchQuery}
      />
      {searchQuery ? (
        <OTTouchableOpacity onPress={() => setSearchQuery("")}>
          <Feather name="x" size={18} style={{ color: "#555" }} />
        </OTTouchableOpacity>
      ) : null}
    </View>
  );

  const renderEmojiCell = (item: IEmoji) => (
    <EmojiCell
      key={item.unified}
      emoji={item}
      onPress={() => {
        onEmojiSelected(charFromUtf16(item.unified));
        addHistory(item);
      }}
    />
  );
  var emojiData: IEmoji[] = [];
  if (searchQuery) {
    const filtered = emoji.filter((e) => {
      let display = false;
      e.short_names.forEach((name) => {
        if (name.includes(searchQuery.toLowerCase())) display = true;
      });
      return display;
    });

    emojiData = filtered as IEmoji[];
  } else if (category == "history") {
    emojiData = history;
  } else {
    emojiData = category in emojiList ? emojiList[category] : [];
  }
  return (
    <View
      style={{
        height: 200
      }}
    >
      <TabBar activeCategory={category} onPress={setCategory} />
      {Searchbar}
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ paddingHorizontal: 10, flexDirection: "row", flexWrap: "wrap" }}
        keyboardShouldPersistTaps={"always"}
      >
        {emojiData.map((item, index) => renderEmojiCell(item))}
      </ScrollView>
    </View>
  );
};

import React, { Component } from 'react';
import { View, Image, Text, ImageBackground, ScrollView, Platform } from 'react-native';

import * as StyleConstants from '../globals/StyleConstants'
import * as Fire from '../globals/Fire';
import * as WindowUtils from '../utils/WindowUtils';
import { OTTouchableOpacity } from '../components';
import { LINKPROTOCOL } from '../globals/config';
import { Logger } from '@openteam/app-util';
import { LoadingView } from '../components/LoadingView';
import { OTModalContainer } from '../components/ModalService';
import { PageHeader } from './fragments/PageHeader';
import { OTButton } from '../components/buttons/OTButtons';
import { isMacOs, osName, deviceDetect, deviceType } from 'react-device-detect';
import * as WebBrowser from 'expo-web-browser';
import { OTText } from '../components/OTText';
import { Theme } from '../globals/ThemeColors';
import { OTUserData } from '../data/OTData';

const logger = new Logger("RedirectToApp")

interface IRedirectToAppProps {
  incToken?: boolean
  onBrowserConnect: () => void
}

interface IRedirectToAppState {
  loaded: boolean
  token?: string
}

export default class RedirectToApp extends Component<IRedirectToAppProps, IRedirectToAppState> {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    }


  }

  componentDidMount = async () => {
    if (this.props.incToken) {
      var token = await Fire.getCustomToken()

      this.setState({ loaded: true, token: token }, this.redirectToApp)
    }
    console.log("deviceDetect", deviceDetect())
  }

  redirectToApp = async () => {
    var protocolURL = `${LINKPROTOCOL}:${OTUserData.reqTeamPath || ''}`


    if (this.state.token) {
      protocolURL = protocolURL + `?token=${this.state.token}`
    }

    logger.info("protocolURL", protocolURL)
    WindowUtils.setUrl(protocolURL)
  }


  renderRedirect = () => {
    return (
      <View>
        <View style={{ margin: 30, marginTop: 10, alignItems: 'center' }}>
          {
            this.state.loaded ?
              <View style={{ flexDirection: 'row', width: "100%" }}>
                <OTButton title="Open App" onPress={this.redirectToApp} showShadow={true} />
              </View>
              :
              <LoadingView title="Redirecting to App" />
          }

        </View>
        <OTTouchableOpacity style={{}} onPress={this.props.onBrowserConnect}>
          <OTText style={{ color: StyleConstants.LinkColour, textAlign: 'center' }}><OTText style={{ color: Theme.DarkText }}>App not working?</OTText> Click here to open OpenTeam in your browser</OTText>
        </OTTouchableOpacity>
      </View>
    )
  }

  render() {
    return (
      <View style={{ flex: 1, backgroundColor: Theme.OfficeBackground }} >
        <ScrollView contentContainerStyle={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1, paddingHorizontal: 25 }}>

          <View style={{ flexDirection: 'row', width: "100vw", maxWidth: "100vw", flex: 1, alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
            <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fconnectionl.svg?alt=media" }} style={{ width: "25vw", height: "60vh", resizeMode: "contain" }} />

            <View style={{ flex: 2, minWidth: 100, alignItems: 'center', justifyContent: 'center' }}>
              <OTModalContainer padding={40} style={{ width: 380, minWidth: 100, minHeight: 200, justifyContent: 'space-around' }}>

                {this.renderRedirect()}

              </OTModalContainer>
            </View>
            <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fconnectionr.svg?alt=media" }} style={{ width: "25vw", height: "60vh", resizeMode: "contain" }} />

          </View>
          <PageHeader />

        </ScrollView>
      </View>
    )

  }

}
import React, { Component } from "react";
import { View, StyleSheet, ViewStyle, Platform } from "react-native";
import { Theme } from "../globals/ThemeColors";
import { getStatusString } from "../utils/OTUtils";
import { SignalStrength } from "../components/userTile/SignalStrength";
import { Feather } from '@expo/vector-icons';
import { FiLoader, FiSmartphone } from "react-icons/fi";

import HeadIcon from "./HeadIcon";
import { SKMic } from "./SVGIcons";
import { observer } from "mobx-react";
import { PlatformOS } from "../utils/platform";
import { IOTUser, IOTRoomUser } from "@openteam/models";
import { OTGlobals } from "@openteam/app-core";

interface IConnectedDotProps {
  userId: string
  teamId: string
  roomId?: string
  streamId?: string
}

interface IConnectedDotState {

}

@observer
export default class ConnectedDot extends Component<IConnectedDotProps, IConnectedDotState> {
  render () {
    const teamData = OTGlobals.getTeamData(this.props.teamId);
    let user: IOTUser | IOTRoomUser;

    if (this.props.roomId) {
      user = teamData.rooms[this.props.roomId].users[this.props.userId];
    } else {
      user = teamData.getTeamUser(this.props.userId);
    }

    var status = getStatusString(user.online, true, user.idle, user.inBackground, user.hasMobile)

    if (status == 'mobile') {
      if (PlatformOS == 'mobile') {
        return <Feather name="smartphone" size={12} style={{color:Theme.ConnectingColour }} data-tip={`${user.name} is on ${status}`}/>
      } else {
        return <FiSmartphone size={12} style={{color:Theme.ConnectingColour }} data-tip={`${user.name} is on ${status}`}/>
      }
    } else if (status == 'connecting') {
      if (PlatformOS == 'mobile') {
        return <Feather name="loader" size={12} style={{ color: Theme.ConnectingColour }} data-tip={`${user.name} is ${status}`} />
      } else {
        return <FiLoader size={12} style={{ color: Theme.ConnectingColour }} data-tip={`${user.name} is ${status}`} />
      }
    }

    return (
      <View style={[styles.status, styles[status]]} data-tip={`${user.name} is ${status}`} />
    )
  }
}

interface IConnectedHeadIconProps extends IConnectedDotProps {
  size?: number
  borderRadius?: number
  style?: any
  isPTTSource?: boolean
}

@observer
export class ConnectedHeadIcon extends Component<IConnectedHeadIconProps> {

  render () {
    const teamData = OTGlobals.getTeamData(this.props.teamId);
    const user = teamData.getTeamUser(this.props.userId);
    return (
      <View style={{ position: "relative" }}>
        <HeadIcon
          imageUrl={user.imageUrl}
          name={user.name}
          size={this.props.size}
          borderRadius={this.props.borderRadius}
          style={this.props.style}
        />
        {
          this.props.isPTTSource ?
            <View style={styles.pttOverlay}>
              <SKMic size={(this.props.size ?? 20) / 2} style={{ color: Theme.RecordingColor }} />
            </View>
            : null
        }
        <View style={[styles.dotContainer, { backgroundColor: Theme.OfficeBackground }]}>
          <ConnectedDot userId={this.props.userId} teamId={this.props.teamId} streamId={this.props.streamId} />
        </View>
      </View>
    )
  }
}

const styles = {
  status: {
    width: 8,
    height: 8,
    borderRadius: 4,
  },
  connected: {
    backgroundColor: Theme.OnlineColor
  },
  inBackground: {
    backgroundColor: Theme.OnlineColor
  },
  connecting: {
    backgroundColor: Theme.ConnectingColour
  },
  idle: {
    backgroundColor: "white",
    borderColor: Theme.OnlineColor,
    borderWidth: 2,
  },
  disconnected: {
    backgroundColor: Theme.OfflineColor
  },
  dotContainer: {
    position: "absolute",
    top: -5,
    left: -5,
    width: 14,
    height: 14,
    borderRadius: 7,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  } as ViewStyle,
  pttOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  } as ViewStyle
}

import { CallRequestManager } from "./CallRequestManager";
import { Logger } from "@openteam/app-util";
import { computed } from "mobx";
import { TReceiverStatus } from "@openteam/models";

const logger = new Logger("CallRequestUIState");

export class CallRequestUIState {
  _callRequestManager: CallRequestManager;

  constructor(callRequestManager: CallRequestManager) {
    this._callRequestManager = callRequestManager;
  }

  get userId() {
    return this._callRequestManager.userId;
  }
  get sessionToken() {
    return this._callRequestManager.sessionToken;
  }
  get teamId() {
    return this._callRequestManager.teamId;
  }
  get calls() {
    return this._callRequestManager.calls;
  }
  get incomingCallUserId() {
    return this._callRequestManager.incomingCallUserId;
  }

  get callsByKey() {
    return this._callRequestManager.callsByKey;
  }
  get callingUsers() {
    return this._callRequestManager.callingUsers;
  }

  isCalling = (userId: string) => userId in this.callingUsers;

  recvRespondToCall = (response: TReceiverStatus) =>
    this._callRequestManager.recvRespondToCall(response);

  sendCallUser = (userId: string, callKey?: string, callName?: string, roomId?: string) =>
    this._callRequestManager.sendCallUser(userId, callKey, callName, roomId);

  sendCancelCall = (userId: string, userInitiated: boolean = false) =>
    this._callRequestManager.sendCancelCall(userId, userInitiated);
}

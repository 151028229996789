import React, { Component } from "react";

import { observer } from "mobx-react";
import { View, CheckBox } from "react-native";
import Select from "react-select";

import { Logger } from "@openteam/app-util";
import { ITeamExternalWaiting } from "@openteam/models";
import { OTButton } from "./buttons/OTButtons";
import { OTTouchableOpacity } from ".";
import { OTText } from "./OTText";
import { Theme } from "../globals/ThemeColors";
import HeadIcon from "./HeadIcon";
import { OTGlobals, OTUITree } from "@openteam/app-core";

const logger = new Logger("PendingMeetingUsers");

interface IPendingMeetingUsersProps {
  teamId: string;
  isSection?: boolean;
  onClose?: () => void;
}

interface IPendingMeetingUsersState {
  selected: { [id: string]: boolean };
  roomId?: string;
}

@observer
export class PendingMeetingUsers extends Component<
  IPendingMeetingUsersProps,
  IPendingMeetingUsersState
> {
  constructor(props) {
    super(props);

    const meetingUIState = OTUITree.meetingUIStates[this.props.teamId];
    const teamData = OTGlobals.getTeamData(this.props.teamId);

    var selected: { [id: string]: boolean } = {};
    var waitingUsers = Object.keys(meetingUIState.addressDoc?.waiting || {});
    if (waitingUsers.length == 1) {
      selected = { [waitingUsers[0]]: true };
    }

    this.state = {
      selected: selected,
      roomId:
        teamData.currentRoomId && teamData.rooms[teamData.currentRoomId]?.config?.external
          ? teamData.currentRoomId
          : undefined,
    };
  }

  acceptSelected = async () => {
    if (Object.keys(this.state.selected).length == 0) {
      return;
    }

    const meetingUIState = OTUITree.meetingUIStates[this.props.teamId];

    var roomId = this.state.roomId;
    if (!roomId) {
      //create new room
      roomId = (await meetingUIState.createRoom()) || undefined;
    }

    Object.keys(this.state.selected).forEach((id) => meetingUIState.acceptReq(id, roomId));

    this.setState({
      selected: {},
    });

    if (!this.props.isSection && this.props.onClose) {
      this.props.onClose();
      logger.info("closing");
    }
  };

  holdSelected = () => {
    if (Object.keys(this.state.selected).length == 0) {
      return;
    }

    const meetingUIState = OTUITree.meetingUIStates[this.props.teamId];

    Object.keys(this.state.selected).forEach((id) => meetingUIState.holdReq(id));

    this.setState({
      selected: {},
    });

    if (!this.props.isSection && this.props.onClose) {
      this.props.onClose();
      logger.info("closing");
    }
  };

  rejectSelected = () => {
    if (Object.keys(this.state.selected).length == 0) {
      return;
    }

    const meetingUIState = OTUITree.meetingUIStates[this.props.teamId];

    Object.keys(this.state.selected).forEach((id) => meetingUIState.rejectReq(id));

    this.setState({
      selected: {},
    });

    if (!this.props.isSection && this.props.onClose) {
      this.props.onClose();
      logger.info("closing");
    }
  };

  toggleSelected = (id) => {
    var selected = { ...this.state.selected };

    if (selected[id]) {
      delete selected[id];
    } else {
      selected[id] = true;
    }
    this.setState({ selected: selected });
  };

  setRoomId = (tag) => {
    this.setState({ roomId: tag?.value });
  };

  render() {
    const meetingUIState = OTUITree.meetingUIStates[this.props.teamId];

    var waiting = meetingUIState.addressDoc?.waiting || {};

    if (this.props.isSection && Object.keys(waiting).length == 0) {
      return <View />;
    }

    return (
      <View>
        <View style={{ alignItems: "center", marginBottom: 10, marginHorizontal: 30 }}>
          <OTText style={{ fontWeight: "500", fontSize: this.props.isSection ? 16 : 18 }}>
            Waiting Room
          </OTText>
        </View>

        {Object.keys(waiting).length == 0 ? this.renderNoOne() : this.renderWaitingList()}
      </View>
    );
  }

  renderNoOne = () => {
    return (
      <View style={{ alignItems: "center", margin: 10 }}>
        <OTText>Empty</OTText>
      </View>
    );
  };

  renderWaitingList = () => {
    const teamData = OTGlobals.getTeamData(this.props.teamId);
    const meetingUIState = OTUITree.meetingUIStates[this.props.teamId];

    var waiting = meetingUIState.addressDoc?.waiting || {};

    var roomOptions: { value: any; label: any }[] = Object.keys(teamData.rooms)
      .filter((roomId) => teamData.rooms[roomId]?.config?.external)
      .map((roomId) => ({ value: roomId, label: teamData.rooms[roomId]?.config?.name }));
    const newRoomOption = { value: undefined, label: "Create New Room" };

    roomOptions = [newRoomOption, ...roomOptions];

    var roomOption = roomOptions.filter((ro) => ro.value == this.state.roomId).pop();
    var hasSelected = Object.keys(this.state.selected).length > 0;

    return (
      <>
        {Object.keys(waiting)
          .filter((id) => !waiting[id].status || waiting[id].status == "H")
          .map((id) => this.renderWaiting(id, waiting[id]))}

        {!hasSelected ? (
          <View style={{ marginBottom: 10, alignItems: "center" }}>
            <OTText style={{ color: Theme.OffDarkText }}>Please select a user to continue</OTText>
          </View>
        ) : null}

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <OTTouchableOpacity stopPropagation={true} style={{ width: 200 }}>
            <Select
              value={roomOption}
              onChange={this.setRoomId}
              options={roomOptions}
              menuPortalTarget={document.getElementById("otbody")}
              isSearchable={false}
            />
          </OTTouchableOpacity>
          <OTButton
            small={true}
            title={"Accept"}
            disabled={!hasSelected}
            backgroundColor={hasSelected ? Theme.ButtonPositiveColor : Theme.ButtonDisabledColor}
            onPress={this.acceptSelected}
            outerStyle={{ marginLeft: 10 }}
          />
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-around", marginTop: 10 }}>
          <OTButton
            small={true}
            title={"Decline"}
            disabled={!hasSelected}
            textColor={hasSelected ? Theme.ButtonNegativeColor : Theme.ButtonDisabledColor}
            backgroundColor={Theme.ButtonNegativeIconColor}
            borderColor={hasSelected ? Theme.ButtonNegativeColor : Theme.ButtonDisabledColor}
            onPress={this.rejectSelected}
            outerStyle={{ flex: 1, marginRight: 5 }}
          />
          <OTButton
            small={true}
            title={"Put on hold"}
            disabled={!hasSelected}
            textColor={hasSelected ? Theme.ButtonWarnColor : Theme.ButtonDisabledColor}
            backgroundColor={Theme.ButtonWarnIconColor}
            borderColor={hasSelected ? Theme.ButtonWarnColor : Theme.ButtonDisabledColor}
            onPress={this.holdSelected}
            outerStyle={{ flex: 1, marginLeft: 5 }}
          />
        </View>
      </>
    );
  };

  renderWaiting = (id: string, waitingDoc: ITeamExternalWaiting) => {
    return (
      <OTTouchableOpacity
        onPress={() => this.toggleSelected(id)}
        key={id}
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 10,
          padding: 10,
          backgroundColor: Theme.GreyBackground,
          borderRadius: Theme.curviness / 1.5,
        }}
      >
        <HeadIcon name={waitingDoc.name} imageUrl={waitingDoc.imageUrl} size={40} />
        <View style={{ marginLeft: 10, flex: 1 }}>
          <OTText style={{ fontWeight: "500", fontSize: 14 }}>{waitingDoc.name}</OTText>
          <OTText style={{ fontSize: 12, color: Theme.ButtonWarnColor }}>
            {waitingDoc.status == "H" ? "On Hold" : null}
          </OTText>
        </View>
        <CheckBox value={this.state.selected[id]} onChange={() => this.toggleSelected(id)} />
      </OTTouchableOpacity>
    );
  };
}

import { observer } from "mobx-react"
import React, { Component } from "react"
import { Theme } from "../../globals/ThemeColors"
import { getStringColour } from "../../utils/OTUtils"
import { OTText } from '../../components/OTText';
import * as StyleConstants from '../../globals/StyleConstants'
import {  FontAwesome } from '@expo/vector-icons';
import { BadgeView } from '../../components/BadgeUtil';
import { ImageBackground, View } from "react-native"
import styles from './teamStyles';
import { OTGlobals } from "@openteam/app-core";

interface ITeamTitleProps {
    teamId: string
    teamPath?: string
    showName?: boolean
}

interface ITeamTitleState {
}

@observer
export default class TeamTitle extends Component<ITeamTitleProps, ITeamTitleState> {

    render() {
        var { teamId, teamPath } = this.props
        const teamData = OTGlobals.getUnsafeTeamData(teamId);

        var badgeNum = 0
        var iconImageUrl: string | undefined = undefined
        var requesting = true
        var iconLetter = ''
        var iconColour = StyleConstants.PrimaryColour
        var teamName = teamPath

        if (teamData) {
            teamPath = teamData.teamPath!
            teamName = teamData.teamName!
            badgeNum = teamData.badgeNum
            iconImageUrl = teamData.iconImageUrl
            requesting = false
        }

        if (typeof teamPath === 'string') {
            iconLetter = teamPath.charAt(0).toUpperCase()
            iconColour = getStringColour(teamPath)
        }

        return (
            <View
                key={"menuteam" + teamId}
                style={{ flexDirection: 'row', alignItems: 'center', }}>
                <View
                    style={[
                        styles.teamIconContainer,
                    ]}
                >
                    <ImageBackground
                        source={{ uri: iconImageUrl }}
                        imageStyle={{
                            borderRadius: Theme.curviness / 2,
                        }}
                        style={[styles.teamIcon, {
                            backgroundColor: iconColour + "44",
                        }]}>
                        {
                            iconImageUrl ?
                                null
                                :
                                <OTText style={{ fontSize: 20, fontWeight: 'bold', color: iconColour, }}>
                                    {iconLetter}
                                </OTText>
                        }
                        <BadgeView badgeNum={badgeNum} />
                        {
                            requesting ?
                                <FontAwesome
                                    name="exclamation-circle"
                                    size={20}
                                    style={{
                                        position: 'absolute',
                                        top: -5,
                                        left: -5,
                                        color: '#E69435'
                                    }} />
                                :
                                null
                        }
                    </ImageBackground>
                </View>
                {
                    this.props.showName ?
                        <OTText style={{ paddingLeft: 10, fontSize: 22, fontWeight: 'bold', color: Theme.MainText }}>{teamName}</OTText>
                        :
                        null
                }
            </View>
        )
    }
}

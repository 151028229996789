import { observer } from "mobx-react";
import React, { Component } from "react";
import { View, StyleSheet, ViewStyle } from "react-native";
import { Theme } from "../globals/ThemeColors";
import { OTTouchableOpacity } from "./buttons";
import { OTText } from "./OTText";
import { Feather } from '@expo/vector-icons';
import { toJS } from "mobx";

interface IHeaderDialogProps {
    title: string
    onClose: () => void
    outerStyle?: ViewStyle
}

interface IHeaderDialogState {
}

@observer
export default class HeaderDialog extends Component<IHeaderDialogProps, IHeaderDialogState> {
    render() {
        return (
            <View style={[{
                minWidth: 350,
                width: "80vw",
                maxWidth: 800,
                minHeight: 350,
                height: "80vh",
                maxHeight: 600,
                backgroundColor: Theme.DialogBackground,
                borderRadius: Theme.curviness,
                overflow: 'hidden'
            }, this.props.outerStyle]}>
                <View style={[styles.headerRow, toJS(Theme.LightShadow )]}>
                    <OTText style={styles.headerText}>{this.props.title}</OTText>

                </View>
                <View style={{ flex: 1, }}>

                    {this.props.children}

                </View>

                <OTTouchableOpacity onPress={this.props.onClose} style={{ position: 'absolute', right: 20, top: 20 }}>
                    <Feather name="x" size={18} style={{ color: Theme.HeaderIcon }} />
                </OTTouchableOpacity>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    headerRow: {
        height: 56,
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerText: {
        fontSize: 18,
        fontWeight: "600",
    },
})


import { observer } from "mobx-react";
import { autorun, IReactionDisposer } from 'mobx';
import React, { Component } from "react";
import { ListRenderItemInfo, StyleSheet, FlatList, View, ViewStyle } from "react-native";
import { throttle } from "throttle-debounce";
import { BadgeView } from "../BadgeUtil";
import * as Analytics from "../../utils/Analytics";
import { OTChat, parseEmojis } from "./OTChat";
import { OTTouchableOpacity } from "..";
import { getTimeAgo } from "../../utils/OTUtils";
import HeadIcon from "../HeadIcon";
import { OTPopover } from "../OTPopover";
import { Theme } from "../../globals/ThemeColors";
import { OTText } from "../OTText";
import { Feather } from "@expo/vector-icons";
import { OTButton } from "../buttons/OTButtons";
import { dismissModal, showModal } from '../ModalService';
import { IMessageFile, IOption, IOTRoom} from "@openteam/models";
import { ChatBar } from "./ChatBar";
import { Logger } from "@openteam/app-util";
import { IOTChatMessage, IFile} from "@openteam/models";
import { OTGlobals, OTUITree, windowState } from '@openteam/app-core';
import { CreateChat } from "./CreateChat";
import { CreateChannel } from "./CreateChannel";
import Select, { components } from 'react-select'
import SettingsRow from '../settingsRow';
import { RoomUser } from "../../mobile/views/user/RoomUser";
import { CreateTopic } from "./CreateTopic";
import UserIconList from "../UserIconList";

const logger = new Logger("Chat");

interface IChatButtonProps {
  teamId: string;
}

interface IChatButtonState {}

@observer
export class ChatButton extends Component<IChatButtonProps, IChatButtonState> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  toggleChat = () => {
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];
    chatUIState.toggleShowChats();
  };

  goChat = (channelId, topicId) => {
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];
    chatUIState.goChannel(channelId, topicId);
    Analytics.logEvent("chat_open", { channelId });
    this.toggleChat();
  };

  showCreateChat = () => {
    showModal({
      contents: <CreateChat teamId={this.props.teamId} />,
      showContainer: true,
      showClose: true,
    });
    this.toggleChat();
  };

  render() {
    const teamData = OTGlobals.getTeamData(this.props.teamId);
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];

    return (
      <OTPopover
        title="Chats"
        isOpen={chatUIState.showChatMenu}
        toggleIsOpen={chatUIState?.toggleShowChats}
        renderOpen={this.renderChats}
        icon={<Feather name="message-circle" size={21} style={{ color: Theme.HeaderIcon }} />}
        badgeNum={teamData.unreadChats}
      />
    );
  }
  renderChats = () => {
    return (
      <View
        style={{
          flex: 1,
          minHeight: 350,
          maxHeight: "40vh",
          minWidth: 250,
        }}
      >
        <ChatBarMini teamId={this.props.teamId} onClose={this.toggleChat} />
      </View>
    );
  };
}

interface IChatBarMiniProps {
  teamId: string;
  onClose: () => void;
}

interface IChatBarMiniState {}

@observer
export class ChatBarMini extends Component<IChatBarMiniProps, IChatBarMiniState> {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];
    return (
      <View style={{ flex: 1 }}>
        {chatUIState.focusedChannelTopic ? (
          <Conversation
            key={`channel-${chatUIState.focusedChannelTopic.channelId}-${chatUIState.focusedChannelTopic.topicId}`}
            teamId={this.props.teamId}
            channelId={chatUIState.focusedChannelTopic.channelId}
            topicId={chatUIState.focusedChannelTopic.topicId}
            onClose={() => chatUIState.closeChannel(chatUIState.focusedChannelTopic?.channelId, chatUIState.focusedChannelTopic?.topicId)}
            showHeader={true}
            outerStyle={{
              flex: 1,
              borderRadius: Theme.curviness,
            }}
          />
        ) : (
          <ChatBar teamId={this.props.teamId} hideTitle={true} onClose={this.props.onClose} />
        )}
      </View>
    );
 }
}


interface IConversationProps {
  teamId: string
  channelId: string
  topicId: string
  onClose: () => void
  outerStyle?: ViewStyle
  showHeader?: boolean
}

interface IConversationState {}

@observer
export class Conversation extends Component<IConversationProps, IConversationState> {
  markChatReadAutorun?: IReactionDisposer
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      atEnd: false,
    };
  }

  componentDidMount = async () => {

    this.markChatReadAutorun = autorun(
      () => {
        const chatUIState = OTUITree.chatUIStates[this.props.teamId];
        const channel = chatUIState.getChannel(this.props.channelId)
        const userChannel = chatUIState.getUserChannel(this.props.channelId)
        const messageManager = chatUIState.messageManager[this.props.channelId]?.[this.props.topicId]

        if (channel && userChannel && windowState.windowFocused && (messageManager?.lastReceivedMessageId || 0) > (userChannel.topics?.[this.props.topicId]?.messageId || 0)) {
          logger.info("chat mark read", channel, userChannel, messageManager?.lastReceivedMessageId)
          const chatUIState = OTUITree.chatUIStates[this.props.teamId];
          chatUIState.markChatRead(this.props.channelId, this.props.topicId)
        }
      }
    );
  }

  componentWillUnmount = async () => {
    this.markChatReadAutorun && this.markChatReadAutorun()
  }

  getChatName = () => {
    const { teamId, channelId, topicId } = this.props

    const chatUIState = OTUITree.chatUIStates[teamId];
    const channel = chatUIState.getChannel(channelId)
    const teamData = OTGlobals.getTeamData(teamId);
    var users = (channel.userIds || []).filter(userId => userId != OTUITree.auth.userId)

    var name = channel.name
    var nameList = users.map(userId => teamData.getTeamUser(userId).name).join(", ")
    if (!name) {
      name = nameList;
    }

    return name
  }

  toggleMuteNotify = () => {
    const { teamId, channelId, topicId } = this.props

    const chatUIState = OTUITree.chatUIStates[teamId];
    const userChannel = chatUIState.getUserChannel(channelId);
    chatUIState.muteChatNotify(channelId, topicId, !userChannel?.topics?.[topicId]?.muteNotify);
  }

  startRoom = async () => {
    const { teamId, channelId, topicId } = this.props

    const chatUIState = OTUITree.chatUIStates[teamId];
    const channel = chatUIState.getChannel(channelId)

    var teamManager = OTUITree.teamManagers[teamId]

    const userIds = (channel.userIds || []).filter(userId => userId != OTUITree.auth.userId )
    if (channel.chatType == "chat" && userIds.length == 1) {
      const callRequestUIState = OTUITree.callRequestUIStates[teamId];
      callRequestUIState.sendCallUser(userIds[0]);
    } else {
      var name = this.getChatName()

      if (topicId != "default" && channel.topics?.[topicId]?.name) {
        name += ` #${channel.topics[topicId].name}`
      }

      await teamManager.startRoomForChannel(name, channelId, topicId)
    }
  }

  sendChatMsg = async (text: string, files: IMessageFile[], replyMessage?: IOTChatMessage) => {
    const { teamId, channelId, topicId } = this.props

    const chatUIState = OTUITree.chatUIStates[teamId];
    await chatUIState.sendChatMessage(
      channelId,
      topicId,
      text,
      files,
      replyMessage
    );

    Analytics.logEvent("chat_send_msg", { channelId: channelId, topicId: topicId })
  }

  editMsg = async (messageId: string, text) => {
    const { teamId, channelId, topicId } = this.props

    const chatUIState = OTUITree.chatUIStates[teamId];
    await chatUIState.editChatMessage(channelId, topicId, messageId, text);
  }

  deleteMsg = async (messageId: string) => {
    const { teamId, channelId, topicId } = this.props

    const chatUIState = OTUITree.chatUIStates[teamId];
    await chatUIState.deleteChatMessage(channelId, topicId, messageId);
  }

  onFiles = (files: FileList | File[] | IFile[] | null) => {
    const { teamId, channelId, topicId } = this.props

    const chatUIState = OTUITree.chatUIStates[teamId];
    chatUIState.addDraftFiles(channelId, topicId, files);
  }

  showEditChannel = () => {
    showModal({
      contents: <CreateChannel teamId={this.props.teamId} channelId={this.props.channelId} />,
      showContainer: true,
      showClose: true,
    });
  };

  showEditTopic = () => {
    showModal({
      contents: <CreateTopic teamId={this.props.teamId} channelId={this.props.channelId} topicId={this.props.topicId} />,
      showContainer: true,
      showClose: true,
    });
  }

  setDraftText = (text) => {
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];

    if (!chatUIState.draftMessages[this.props.channelId]) {
      chatUIState.draftMessages[this.props.channelId] = {}
    }
    chatUIState.draftMessages[this.props.channelId][this.props.topicId] = text;

    if (text) {
      this.setIsTyping();
    }
  };

  setDraftReplyMessage = (message?: IOTChatMessage) => {
    const { teamId, channelId, topicId } = this.props

    const chatUIState = OTUITree.chatUIStates[teamId];

    if (!message) {
      chatUIState.deleteDraftReplyMessage(channelId, topicId);
    } else {
      chatUIState.setDraftReplyMessage(channelId, topicId, message);
    }
  };

  startTopic = (message?: IOTChatMessage) => {
    showModal({
      contents: <CreateTopic teamId={this.props.teamId} channelId={this.props.channelId} name={message?.message} />,
      showContainer: true,
      showClose: true,
    });
  }

  setIsTyping = throttle(1000, true, () => {
    const { teamId, channelId, topicId } = this.props

    const chatUIState = OTUITree.chatUIStates[teamId];
    chatUIState.setIsTyping(channelId, topicId, true);
  });

  render() {
    const { teamId, channelId, topicId } = this.props


    const chatUIState = OTUITree.chatUIStates[teamId];
    const teamData = OTGlobals.getTeamData(teamId);
    const messageManager = chatUIState.messageManager[channelId]?.[topicId]
    const channel = chatUIState.getChannel(channelId)
    const userChannel = chatUIState.getUserChannel(channelId)


    if (!userChannel || !channel || !messageManager) {
      return <View />
    }

    const isChannel = channel.chatType == 'private_channel' || channel.chatType == 'channel'
    const isTopic = topicId != "default"
    var users = (channel.userIds || []).filter(userId => userId != OTUITree.auth.userId)

    var imageUrl = channel.imageUrl
    if (!imageUrl && users.length == 1) {
      var teamUser = teamData.getTeamUser(users[0]);

      imageUrl = teamUser.imageUrl;
    }

    let name = this.getChatName()
    var subtitle: string | undefined = channel.desc || undefined

    if (isTopic && channel.topics?.[topicId]?.name) {
      subtitle = `A topic in ${name}`
      name = `#${channel.topics[topicId].name}`
    }


    if (channel.chatType == 'private_channel') {
      name += " - Private"
    }

    const lastReceivedMessageId = messageManager.lastReceivedMessageId
    const lastReadMessageId = messageManager.lastReadMessageId

    let isCalling: boolean = false
    const userIds = (channel.userIds || []).filter(userId => userId != OTUITree.auth.userId)
    if (channel.chatType == "chat" && userIds.length == 1) {
      const callRequestUIState = OTUITree.callRequestUIStates[teamId];
      isCalling = callRequestUIState.isCalling(userIds[0]);
    }

    const rooms = Object.keys(teamData.rooms).filter(
      roomId => teamData.rooms[roomId].config?.channelId == channelId && teamData.rooms[roomId].config?.topicId == topicId)

    return (
      <OTChat
        title={name}
        subTitle={subtitle}
        titleImageUrl={imageUrl || undefined}
        showUserImage={true}
        chatHeaderLeft={<UserIconList teamId={teamId} userIds={userIds.filter(userId => userId != OTUITree.auth.userId)} maxIcons={3} onPress={isChannel ? this.showEditChannel : undefined}/>}
        onTitlePress={isTopic ? this.showEditTopic : isChannel ? this.showEditChannel : undefined}
        lastReadMessageId={lastReceivedMessageId != lastReadMessageId ? lastReadMessageId : undefined}
        messages={Object.values(messageManager.messages).reverse()}
        sendMessage={this.sendChatMsg}
        editMessage={this.editMsg}
        deleteMessage={this.deleteMsg}
        isTypingUsers={Object.keys(
          messageManager.chatUserIsTyping || {}
        ).map(userId => teamData.getTeamUser(userId))}
        draftText={chatUIState.draftMessages[channelId]?.[topicId]}
        draftReplyMessage={chatUIState.draftReplyMessage[channelId]?.[topicId]}
        setDraftText={this.setDraftText}
        setDraftReplyMessage={this.setDraftReplyMessage}
        draftFiles={chatUIState.draftFiles[channelId]?.[topicId] || []}
        setDraftFiles={(draftFiles) => chatUIState.setDraftFiles(channelId, topicId, draftFiles)}
        pendingMessages={chatUIState.pendingMessages[channelId]}
        onClose={this.props.onClose}
        loadMore={
          !messageManager.gettingMore && !messageManager.atEnd ? messageManager.loadMore : undefined
        }
        onFiles={this.onFiles}
        showHeader={this.props.showHeader}
        renderSubFooter={this.renderSubFooter}
        outerStyle={this.props.outerStyle}
        toggleMuteNotify={this.toggleMuteNotify}
        muteNotify={userChannel?.topics?.[topicId]?.muteNotify}
        startRoom={teamData.capabilities.enableChannelCall ? this.startRoom : undefined}
        hasRoom={rooms.length > 0}
        isCalling={isCalling}
        startTopic={teamData.capabilities.enableTopics ? this.startTopic : undefined}
      />
    );
  }
  renderSubFooter = () => {
    const { teamId, channelId, topicId } = this.props

    const teamData = OTGlobals.getTeamData(teamId)

    const rooms = Object.keys(teamData.rooms).filter(
      roomId => teamData.rooms[roomId].config?.channelId == channelId && teamData.rooms[roomId].config?.topicId == topicId)

    return (
      <View>
        {
          rooms.map(roomId => this.renderChannelRoom(roomId, teamData.rooms[roomId]))
        }
      </View>
    )
  }

  renderChannelRoom = (roomId: string, room: IOTRoom) => {
    const { teamId, channelId, topicId } = this.props
    if (!roomId || !room) {
      return
    }
    const { inRoom, config, users } = room;

    if (inRoom) {
      return null
    }
    const teamManager = OTUITree.teamManagers[teamId]

    var onlineUsers = Object.keys(users || {}).filter(userId => users[userId].online || users[userId].inLeeway || (!config?.call && users[userId].hasMobile))

    return (
      <View
        key={roomId}
        style={{
          borderRadius: Theme.curviness * 1.5,
          padding: 10,
          marginTop: 10,
          backgroundColor: Theme.VideoCallBackground
        }}>


        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <OTText style={[{
            fontWeight: "600",
            fontSize: 16,
          }, { color: Theme.MainText }]}>Ongoing Call:</OTText>
          <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap', marginLeft: 10 }}>
            <UserIconList userIds={onlineUsers} teamId={teamId} maxIcons={6}/>
          </View>
          {
            inRoom ?
             null
              :
              <OTButton
                toolTip="Enter Room"
                onPress={() => teamManager.joinTeamRoom(roomId == 'online' ? null : roomId)
                }
                small={true}
                title={"Enter"}
                // icon={<FiLogIn size={14} style={{ color: enterTextColor, marginRight: 5 }} />}
                backgroundColor={"transparent"}
                textColor={Theme.ButtonColor}
                borderColor={Theme.ButtonColor}
              />
          }
        </View>


      </View>
    )
  }
}

interface IConversationMuteButtonProps {
  teamId: string
  channelId: string
  topicId: string
}

interface IConversationMuteButtonState {}

@observer
export class ConversationMuteButton extends Component<
  IConversationMuteButtonProps,
  IConversationMuteButtonState
> {
  toggleMuteNotify = () => {

    const { teamId, channelId, topicId } = this.props

    const chatUIState = OTUITree.chatUIStates[teamId];
    const userChannel = chatUIState.getUserChannel(channelId)

    chatUIState.muteChatNotify(channelId, topicId, !userChannel.topics?.[topicId].muteNotify);
  }

  render() {
    const { teamId, channelId, topicId } = this.props

    const chatUIState = OTUITree.chatUIStates[teamId];
    const userChannel = chatUIState.getUserChannel(channelId);

    return (
      <OTButton
        toolTip={userChannel.topics?.[topicId]?.muteNotify ? "Notifications Muted" : "Notify on message"}
        small={true}
        onPress={this.toggleMuteNotify}
        textColor={Theme.ButtonColor}
        borderColor={Theme.ButtonColor}
        backgroundColor="transparent"
        outerStyle={{ marginRight: 10 }}
        icon={
          <Feather
            name={userChannel.topics?.[topicId]?.muteNotify ? "bell-off" : "bell"}
          size={18}
          style={{ color: Theme.ButtonColor }} />} />
    )
  }
}

const listStyles = StyleSheet.create({
  list: {},
  item: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 5,
    borderRadius: Theme.curviness,
  },
  title: {
    fontWeight: "bold",
    fontSize: 16,
    flex: 1,
  },
  time: {
    marginLeft: 10,
    fontSize: 10,
  },
});

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
    width: "100%",
  },
  itemTitle: {
    fontSize: 14,
    fontWeight: "600",
    flex: 1,
  },
});

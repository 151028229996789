import "firebase/database";
import { Logger } from "@openteam/app-util";
import { ITeamRoom } from "@openteam/models";
import { addWatch, removeWatch } from "./FireUtils";

const logger = new Logger("ExternalMeetingDb");

export class ExternalMeetingDb {
  static removeInviteTeamRoom = async (
    fbDb: firebase.database.Database,
    teamId: string,
    roomId: string,
    userId
  ) => {
    fbDb.ref(`/teams/${teamId}/rooms/${roomId}/invites/${userId}`).remove();
  }

  static removeTeamRoomUser = async (
    fbDb: firebase.database.Database,
    teamId: string,
    roomId: string,
    userId: string
  ) => {
    ExternalMeetingDb.removeInviteTeamRoom(fbDb, teamId, roomId, userId);
    fbDb.ref(`/teams/${teamId}/rooms/${roomId}/users/${userId}`).remove();
  };

  static watchRoom = (
    fbDb: firebase.database.Database,
    teamId: string,
    roomId: string,
    callback: (id: string, doc: ITeamRoom) => void
  ) => {
    const ref = `/teams/${teamId}/rooms/${roomId}`;
    addWatch(ref);
    fbDb
      .ref(ref)
      .on("value", (snapshot) => callback(snapshot.key!, snapshot.val()));
  }
  static unwatchRoom = (fbDb: firebase.database.Database, teamId: string, roomId: string) => {
    const ref = `/teams/${teamId}/rooms/${roomId}`;
    removeWatch(ref);
    fbDb.ref(ref).off("value");
  }
}

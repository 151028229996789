import React, { Component } from 'react';
import Script from 'react-load-script';
import { Logger } from "@openteam/app-util";
import { GoogleDriveIcon } from './SVGIcons';
import { OTButton } from './buttons/OTButtons';
import { Theme } from '../globals/ThemeColors';
import { View } from 'react-native';

const logger = new Logger("GooglePicker")

const G_API_JS_URL = 'https://apis.google.com/js/api.js';
const FILE_URL = 'https://www.googleapis.com/drive/v3/files';
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest', 'https://docs.googleapis.com/$discovery/rest?version=v1'];
const AUTH_SCOPE = 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.readonly';
const GDOC_ICON = "https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.document";
const GDOC_MIMETYPE = "application/vnd.google-apps.document";

const DEFAULT_EXPORT_TYPE_MAP = {
  document: 'application/pdf',
  drawing: 'image/png',
  presentation: 'application/pdf',
  script: 'application/vnd.google-apps.script+json',
  spreadsheet: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

const EVENTS = {
  START_REMOTE_PULL: 'START_REMOTE_PULL',
  CANCEL: 'CANCEL',
  ERROR: 'ERROR',
  SELECTED_FILES: 'SELECTED_FILES',
  SELECTED_FILE: 'SELECTED_FILES',
};

const ENTER_KEY_CODE = 13;

interface IGoogleDrivePermission {
  type: 'user' | 'group' | 'domain' | 'anyone'
  role: 'reader' | 'commenter' | 'writer'
  emailAddress?: string // required if type is 'user' or 'group'
  domain?: string // required if type is domain
}

interface IGooglePickerProps {
  clientId: string,
  apiKey: string,
  exportMimeTypeOverrides?: {
    document?: string,
    drawing?: string,
    presentation?: string,
    script?: string,
    spreadsheet?: string
  },
  origin?: string,
  onEvent: (event, payload?) => void,
  multiSelect?: boolean,
  injectOnClick?: boolean,
  allowSharedDrives?: boolean,
  allowedMimeTypes?: string[],
  exportAsBlobs?: boolean,
  downloadSelectedFiles?: boolean
}

interface IGooglePickerState {
  accessToken?: string
  action?: 'PICK' | 'CREATE'
  authAction?: () => void
  creatingDoc: boolean
  pickingDoc: boolean
  pickerInitialised?: boolean
}

export default class GooglePicker extends Component<IGooglePickerProps, IGooglePickerState> {
  constructor(props) {
    super(props);

    this.state = {
      creatingDoc: false,
      pickingDoc: false,
    };
  }

  handleAction = (action: 'CREATE' | 'PICK') => {
    this.setState({ action });

    if (!this.state.accessToken) {
      gapi.auth2.getAuthInstance().signIn()
        .then(user => {
          this.setState({ accessToken: user.getAuthResponse().access_token });
          this._handleAction(action);
        })
        .catch((err) => {
          logger.error("Signin in error", err);
          this.setState({ action: undefined });
        });
    } else {
      this._handleAction(action);
    }
  }

  _handleAction = (action: 'CREATE' | 'PICK') => {
    if (action == 'CREATE') {
      this.createDoc();
    } else if (action == 'PICK') {
      this.createPicker();
    }
  }

  createDoc = async () => {
    if (this.state.creatingDoc) {
      logger.info("Already creating doc...");
      return;
    }

    this.setState({ creatingDoc: true });
    try {
      var d = new Date()

      const title = `Meeting Notes ${d.toDateString()} ${d.toLocaleTimeString()}`

      var file = await this.createGoogleDoc(title)
      console.log("created file", file)
      await this.setPermission(file.documentId, { type: 'anyone', role: 'writer' })

      var returnFile = {
        description: "",
        embedUrl: `https://docs.google.com/document/d/${file.documentId}/preview`,
        iconUrl: GDOC_ICON,
        id: file.documentId,
        isShared: true,
        mimeType: GDOC_MIMETYPE,
        name: title,
        serviceId: "doc",
        sizeBytes: 0,
        type: "document",
        url: `https://docs.google.com/document/d/${file.documentId}/edit?usp=drive_web`,
      }

      this.props.onEvent(EVENTS.SELECTED_FILES, { files: [returnFile] });
    } catch (err) {
      logger.warn("Error creating doc:", err);
    } finally {
      this.setState({ action: undefined, creatingDoc: false })
    }
  }

  createGoogleDoc = async (title: string) => {
    // @ts-ignore: Property 'docs' does not exist on type 'typeof client'.
    var doc = await gapi.client.docs.documents.create({ title })
    return doc.result
  }

  setPermission = async (fileId, perm: IGoogleDrivePermission) => {
    var response = await gapi.client.drive.permissions.create({
      fileId, resource: perm
    })
    logger.info("googleapi.setPermission", response);
  }

  createPicker = () => {
    if (this.state.pickingDoc) {
      logger.info("Picker already open...");
      return;
    }

    try {
      this.setState({ pickingDoc: true })

      const { accessToken, pickerInitialised } = this.state;

      if (!accessToken || !pickerInitialised) {
        logger.warn("Not logged in or picker not yet initialised");
        return;
      }

      const {
        multiSelect, allowSharedDrives, allowedMimeTypes, origin, apiKey
      } = this.props;


      const defaultView = new google.picker.DocsView()
        .setIncludeFolders(true)
        .setOwnedByMe(true);

      if (allowedMimeTypes) {
        defaultView.setMimeTypes(allowedMimeTypes.join(','));
      }

      const picker = new google.picker.PickerBuilder()
        .addView(defaultView)
        .setOAuthToken(accessToken)
        .setDeveloperKey(apiKey)
        .setCallback(this.downloadFiles);

      if (multiSelect) {
        picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
      }

      if (origin) {
        picker.setOrigin(origin);
        console.log("setting googlepicker origin to", origin)
      }

      if (allowSharedDrives) {
        // @ts-ignore: Property 'setEnableDrives' does not exist on type 'DocsView'.
        const sharedDriveView = new google.picker.DocsView().setEnableDrives(true);

        if (allowedMimeTypes) {
          sharedDriveView.setMimeTypes(allowedMimeTypes.join(','));
        }

        picker.addView(sharedDriveView);
      } else {
        picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN);
      }

      picker.build().setVisible(true);
    } catch (err) {
      logger.error("Error creating picker:", err)
      this.setState({ action: undefined, pickingDoc: false })
    }
  };

  getMimeType = (file) => {
    const { exportMimeTypeOverrides } = this.props;
    const typeSplit = file.mimeType.split('.');
    const type = typeSplit[typeSplit.length - 1].toLowerCase();

    return exportMimeTypeOverrides?.[type] ?? DEFAULT_EXPORT_TYPE_MAP[type];
  };

  downloadFiles = async (data) => {
    const { onEvent, downloadSelectedFiles, exportAsBlobs } = this.props;
    const { accessToken } = this.state;

    if (!accessToken) {
      logger.warn("Unable to download files, no access token");

    } else if (data.action === google.picker.Action.CANCEL) {
      this.setState({ action: undefined, pickingDoc: false })
      onEvent(EVENTS.CANCEL);

    } else if (data.action !== google.picker.Action.PICKED) {
      logger.info("ignoring picker action: ", data.action);

    } else if (!exportAsBlobs) {
      const docs = data[google.picker.Response.DOCUMENTS];
      onEvent(EVENTS.SELECTED_FILES, { accessToken, files: docs });

    } else {
      const docs = data[google.picker.Response.DOCUMENTS];
      const fetchOptions = { headers: { Authorization: `Bearer ${accessToken}` } };

      onEvent(EVENTS.START_REMOTE_PULL);

      const blobs = (await Promise.all(docs.map(async (file) => {
        const isDoc = file.type.toLowerCase() === 'document';
        const mimeType = isDoc && this.getMimeType(file);

        if (isDoc && !mimeType) {
          console.warn(`No corresponding mime type for selected file type (${file.mimeType})`);

          return null;
        }

        const url = isDoc
          ? `${FILE_URL}/${file.id}/export?mimeType=${mimeType}`
          : `${FILE_URL}/${file.id}?alt=media`;

        const blob = await fetch(url, fetchOptions).then((res) => res.blob());

        onEvent(EVENTS.SELECTED_FILE, { accessToken, file: blob });

        return blob;
      }))).filter(Boolean);

      onEvent(EVENTS.SELECTED_FILES, { accessToken, files: blobs })
      this.setState({ action: undefined, pickingDoc: false })
    }
  };

  onApiLoad = () => {
    gapi.load('auth2', this.initAuth);
    gapi.load('client', this.initClient);
    gapi.load('picker', this.initPicker);
  }

  initAuth = async () => {
    const { clientId } = this.props;

    gapi.auth2.init({ client_id: clientId, scope: AUTH_SCOPE })
      .then((auth: gapi.auth2.GoogleAuth) => {
        const user = gapi.auth2.getAuthInstance().currentUser.get();
        if (user) {
          const accessToken = user.getAuthResponse().access_token;
          this.setState({ accessToken })
        }
      }).catch((err) => {
        logger.error("Error initialising gapi.auth2:", err);
      });
  };

  componentWillUnmount() {
    if (gapi.auth2) {
      gapi.auth2.getAuthInstance().isSignedIn.listen
    }
  }

  initClient = () => {
    const { apiKey, clientId } = this.props;

    gapi.client.init({
      apiKey,
      clientId,
      discoveryDocs: DISCOVERY_DOCS,
      scope: AUTH_SCOPE
    }).catch((err) => {
      logger.error("Error initialising gapi.client:", err)
    });
  };


  initPicker = () => {
    console.log("initPicker")
    this.setState({ pickerInitialised: true });
  };

  handleKeyPress({ keyCode }) {
    if (keyCode !== ENTER_KEY_CODE) { return; }

    this.handleAction('PICK');
  }

  render() {
    const { injectOnClick } = this.props;

    if (!injectOnClick) {
      return (
        <div
          onKeyPress={this.handleKeyPress}
          role="button"
          tabIndex={0}
          onClick={() => this.handleAction('PICK')}
        >
          <Script url={G_API_JS_URL} onLoad={this.onApiLoad} />
          {this.props.children}
        </div>
      );
    } else if (!this.state.pickingDoc) {
      return (
        <>
          <Script url={G_API_JS_URL} onLoad={this.onApiLoad} />
          <OTButton
            onPress={() => this.handleAction('PICK')}
            icon={<View style={{ marginRight: 10 }} >
              <GoogleDriveIcon size={22} />
            </View>}
            title="Select from Google Drive"
            backgroundColor={Theme.ButtonIconColor}
            borderColor={Theme.ButtonColor}
            textColor={Theme.DarkText}
            loading={this.state.action == 'PICK'}
            disabled={this.state.action !== undefined}
            outerStyle={{ margin: 10, flex: undefined }} />

          <OTButton
            onPress={() => this.handleAction('CREATE')}
            title="Create New Document"
            loading={this.state.action == 'CREATE'}
            disabled={this.state.action !== undefined}
            outerStyle={{ margin: 10, flex: undefined }} />
        </>
      );
    } else {
      return null;
    }
  }
}

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";
import { observable } from "mobx";
import * as uuid from "react-native-uuid";

import { IMessageFile } from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { OTGlobals } from "./OTGlobals";

const logger = new Logger("CloudUpload");

export class CloudUpload implements IMessageFile {
  id: string;
  teamId: string;
  roomId: string | undefined;
  userId: string;
  imageKind: string;

  file: File;

  @observable progress = 0;
  @observable completed = false;
  @observable error?: string;
  @observable downloadUrl?: string;

  uploadTask?: firebase.storage.UploadTask;

  constructor(
    teamId: string,
    roomId: string | undefined,
    userId: string,
    imageKind: string,
    file: File
  ) {
    this.file = file;
    this.teamId = teamId;
    this.roomId = roomId;
    this.userId = userId;
    this.imageKind = imageKind;

    console.log("created CloudUpload for ", file);

    this.id = uuid.v1();
    this.uploadFile();
  }

  getPreviewURL = () => {
    return URL.createObjectURL(this.file);
  };

  getStorageRef = () => {
    const bucket = OTGlobals.config.TEAM_STORAGE_BUCKET || "gs://openteamfiles";
    const storageRef = firebase.app().storage(bucket).ref();
    return storageRef.child(
      `/team/${this.teamId}/${this.imageKind}/${this.userId}/${this.id}/${this.file.name}`
    );
  };

  uploadFile = async () => {
    console.log("uploading ", this.file);

    const fileRef = this.getStorageRef();

    const { roomId, teamId } = this;

    var metadata = {
      customMetadata: {
        teamId,
        ...(roomId && { roomId }),
      },
    };

    this.uploadTask = fileRef.put(this.file, metadata);

    this.uploadTask.on(
      "state_changed",
      (snapshot) => {
        this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            break;
        }
      },
      (error) => {
        console.log("file upload failed", this.file.name, error);
        this.error = error.name;
      },
      async () => {
        this.completed = true;
        await this.loadDownloadURL();
        console.log("File available at", this.downloadUrl);
      }
    );
  };

  loadDownloadURL = async () => {
    this.downloadUrl = await this.uploadTask?.snapshot.ref.getDownloadURL();
  };

  complete = async () => {
    await this.uploadTask;
    await this.loadDownloadURL();
  };

  stop = () => {
    this.uploadTask && this.uploadTask.cancel();
    this.completed && this.getStorageRef().delete();
  };
}

export default CloudUpload;

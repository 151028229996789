import React, { Component } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  View,
  ViewStyle,
  StyleSheet,
} from "react-native";
import * as StyleConstants from "../globals/StyleConstants";
import { OTTouchableOpacity } from "./buttons";
import { Theme } from "../globals/ThemeColors";
import { OTText } from "./OTText";
import { Feather } from "@expo/vector-icons";
import { PendingMeetingUsers } from "./PendingMeetingUsers";
import { IncomingCall } from "./IncomingCall";
import { CallRequestManager } from "@openteam/app-core";
import { DialogView } from "./DialogView";

interface IOTModalProps {}

interface IOTModalState {}

export class OTModal extends Component<IOTModalProps, IOTModalState> {
  render() {
    return (
      <View
        style={{
          zIndex: 100,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0,0,0, 0.6)",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
        }}
      >
        {this.props.children}
      </View>
    );
  }
}

interface ModalDetails {
  contents: JSX.Element;
  showContainer?: boolean;
  showClose?: boolean;
  onClose?: () => void;
  title?: string;
}

interface ModalContainerProps {}
interface ModalContainerState {
  modalContents: ModalDetails[];
}

export class ModalContainer extends Component<ModalContainerProps, ModalContainerState> {
  constructor(props) {
    super(props);

    this.state = {
      modalContents: [],
    };
  }

  reset = () => this.setState({ modalContents: [] });

  close = () => {
    dismissModal();
    this.state.modalContents[0].onClose && this.state.modalContents[0].onClose();
  };

  render() {
    if (this.state.modalContents.length == 0) {
      return <View />;
    }

    return (
      <OTModal>
        <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          style={{ flex: 1, width: "100%", justifyContent: "center", alignItems: "center" }}
        >
          <ScrollView
            keyboardShouldPersistTaps="handled"
            showsVerticalScrollIndicator={false}
            style={{ flexGrow: 0, width: "100%" }}
            contentContainerStyle={{ alignItems: "center" }}
          >
            {this.state.modalContents[0].showContainer ? (
              <OTModalContainer title={this.state.modalContents[0].title}>
                {this.state.modalContents[0].contents}

                {this.state.modalContents[0].showClose ? (
                  <OTTouchableOpacity
                    onPress={this.close}
                    style={{ position: "absolute", top: 10, right: 10 }}
                  >
                    <Feather name="x" size={20} style={{ color: Theme.HeaderIcon }} />
                  </OTTouchableOpacity>
                ) : null}
              </OTModalContainer>
            ) : (
              this.state.modalContents[0].contents
            )}
          </ScrollView>
        </KeyboardAvoidingView>
      </OTModal>
    );
  }
}

var modalSingletonInstance: ModalContainer;
export function setModalContainer(modal: ModalContainer | null) {
  if (modal != null) {
    modalSingletonInstance = modal;
  }
}

export async function showModal(modalContent: ModalDetails) {
  var addModal = new Promise((resolve, reject) => {
    modalSingletonInstance.setState(
      {
        modalContents: [modalContent, ...modalSingletonInstance.state.modalContents],
      },
      () => resolve(null)
    );
  });

  return await addModal;
}

export function resetModalContainer() {
  modalSingletonInstance.reset();
}

export async function showConfirm(title: string, onConfirm: () => void, onCancel: () => void) {
  const modalContent: ModalDetails = {
    contents: (
      <DialogView
        title={title}
        buttons={[
          {
            title: "Cancel",
            backgroundColor: Theme.ButtonIconColor,
            textColor: Theme.ButtonColor,
            onPress: () => {
              onCancel();
              dismissModal();
            },
          },
          {
            title: "OK",
            onPress: () => {
              onConfirm();
              dismissModal();
            },
          },
        ]}
      >
        <OTText style={{ maxWidth: 400, textAlign: "center", flex: 1 }}>{}</OTText>
      </DialogView>
    ),
    showContainer: true,
    showClose: true,
    onClose: onCancel,
  };
  var addModal = new Promise((resolve, reject) => {
    modalSingletonInstance.setState(
      {
        modalContents: [modalContent, ...modalSingletonInstance.state.modalContents],
      },
      () => resolve(null)
    );
  });

  return await addModal;
}

export async function showMeetingModal(teamId: string, hideModalCallback: () => void) {
  const modalContent: ModalDetails = {
    contents: (
      <PendingMeetingUsers
        teamId={teamId}
        onClose={() => {
          dismissModal();
          hideModalCallback();
        }}
      />
    ),
    showContainer: true,
    showClose: true,
    onClose: hideModalCallback,
  };
  var addModal = new Promise((resolve, reject) => {
    modalSingletonInstance.setState(
      {
        modalContents: [modalContent, ...modalSingletonInstance.state.modalContents],
      },
      () => resolve(null)
    );
  });

  return await addModal;
}

export const showCallRequestModal = (callRequestManager: CallRequestManager, teamId: string) =>
  showModal({
    contents: <IncomingCall callRequestManager={callRequestManager} teamId={teamId} />,
    showContainer: true,
  });

export async function dismissModal() {
  var removeModal = new Promise((resolve, reject) => {
    var newContents = [...modalSingletonInstance.state.modalContents];
    newContents.shift();
    modalSingletonInstance.setState(
      {
        modalContents: newContents,
      },
      () => resolve(null)
    );
  });

  return await removeModal;
}

interface IOTModalContainerProps {
  title?: string;
  padding?: number;
  backgroundColor?: string;
  style?: ViewStyle;
}

interface IOTModalContainerState {}

export class OTModalContainer extends Component<IOTModalContainerProps, IOTModalContainerState> {
  render() {
    return (
      <View
        style={{
          backgroundColor: this.props.backgroundColor || Theme.DialogBackground,
          borderRadius: Theme.curviness * 2,
          padding: this.props.padding || 20,
          margin: 20,
          minWidth: 300,
          alignItems: "center",
          ...StyleConstants.LightShadow,
          ...this.props.style,
        }}
      >
        {this.props.title ? (
          <View style={[pageStyles.headerRow]}>
            <OTText style={pageStyles.headerText}>{this.props.title}</OTText>
          </View>
        ) : null}

        {this.props.children}
      </View>
    );
  }
}

const pageStyles = StyleSheet.create({
  headerRow: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginBottom: 20,
  },
  headerText: {
    fontSize: 18,
    fontWeight: "600",
  },
});

import AsyncStorage from "@react-native-async-storage/async-storage";
import { autorun, set, observable, IReactionDisposer } from "mobx";
import { Logger } from "./Logger";
import semver from "semver";

const logger = new Logger("OTCache");
const LocalStorageCacheKey = `OT:cache`;

export class OTCacheClass {
  inited: boolean = false;
  loadingPromise: Promise<boolean>;
  @observable cache: any;

  _autorun: Record<string, IReactionDisposer> = {};

  constructor(version: string) {
    this.cache = {
      cacheTime: new Date(),
      version,
    };
    this.loadingPromise = this.loadCache();
    this.start();
  }

  start = () => {
    this._autorun["saveCache"] = autorun(
      () => {
        logger.info(`Saving app cache`);
        this._save();
      },
      { delay: 1000 }
    );
  };

  stop = () => {
    Object.values(this._autorun).map((x) => x());
    this._autorun = {};
  };

  setCache = (userId: string, objType: string, objId: string, obj: any) => {
    const key = `${userId}-${objType}-${objId}`;
    this.cache[key] = obj;
    this.cache["cacheTime"] = new Date();
  };

  getCache = (userId: string, objType: string, objId: string) => {
    const key = `${userId}-${objType}-${objId}`;
    return this.cache[key];
  };

  setTeamCache = (userId: string, teamId: string, objType: string, objId: string, obj: any) => {
    const key = `${userId}-${teamId}-${objType}-${objId}`;
    this.cache[key] = obj;
    this.cache["cacheTime"] = new Date();
  };

  getTeamCache = (userId: string, teamId: string, objType: string, objId: string) => {
    const key = `${userId}-${teamId}-${objType}-${objId}`;
    return this.cache[key];
  };

  loadCache = async () => {
    if (this.inited) {
      logger.debug(`Cache already loaded`);
      return false;
    }

    this.inited = true;

    const jsonString = await AsyncStorage.getItem(LocalStorageCacheKey);
    const loadedSettings = jsonString && JSON.parse(jsonString);

    if (loadedSettings) {
      const cacheVersion = loadedSettings.version;
      const appVersion = this.cache.version;

      if (cacheVersion && appVersion) {
        var semDiff = semver.diff(cacheVersion, appVersion);

        if (semDiff == "major" || semDiff == "minor") {
          logger.warn(
            "cache version doesn't match major/minor version so is now considered invalid. Not using"
          );

          AsyncStorage.removeItem(LocalStorageCacheKey);

          return true;
        }
      }

      set(this.cache, loadedSettings);
      this.cache["version"] = appVersion;
      logger.debug(`loaded cache from key ${LocalStorageCacheKey}`);
    }
    return true;
  };

  reset = async () => {
    this.cache = {};
    await this._save();
  };

  _save = async () => AsyncStorage.setItem(LocalStorageCacheKey, JSON.stringify(this.cache));


}

/* export var OTCache: OTCacheClass = new OTCacheClass();

export async function createOTUserData() {
  OTCache = new OTCacheClass();
  await OTCache.loadingPromise;
}
 */
import { IPluginConfig, PluginManager } from '@openteam/app-core';
import { IPluginResource } from '@openteam/models';
import React, { Component } from 'react';
import { View } from 'react-native';
import { MiroIcon } from '../SVGIcons';

declare namespace miroBoardsPicker {
  export function open(args: any): void
}

interface IMiroBoardPluginDetails extends IPluginResource {
  args?: IMiroBoardArgs
}

interface IMiroBoardProps {
  pluginManager: PluginManager
  pluginDetails: IMiroBoardPluginDetails
  height: number
  width: number
}
interface IMiroBoardState {
  loadedScript: boolean
}

interface IMiroBoardArgs {
  url: string,
}

export class MiroBoard extends Component<IMiroBoardProps, IMiroBoardState> {
  constructor(props) {
    super(props)

    this.state = {
      loadedScript: false
    }
  }

  _iframe
  render() {
    if (!this.props.pluginDetails.args?.url) {
      return <View />
    }

    return (
      <iframe
        className="miro-embedded-board"
        src={this.props.pluginDetails.args.url}
        referrerPolicy="no-referrer-when-downgrade"
        frameBorder="0"
        style={{ width: "100%", height: "100%", border: 0, background: 'white' }}></iframe>
    )
  }
}


function setupPlugin(pluginManager: PluginManager) {
  const script = document.createElement("script");

  script.src = "https://miro.com/app/static/boardsPicker.1.0.js";
  script.async = true;
  script.onload = () => openBoardPicker(pluginManager);
  document.body.appendChild(script);
}

function openBoardPicker(pluginManager: PluginManager) {
  miroBoardsPicker.open({
    clientId: '3074457347900948324',
    action: 'access-link',
    getToken: PluginManager.getMiroToken,
    allowCreateAnonymousBoards: true,
    success: function (result) {
      console.log(result)
      var args: IMiroBoardArgs = {
        url: result.accessLink
      }
      pluginManager.createPlugin(pluginType, args)
    }
  })
}

export const pluginType = 'miro'
export const pluginConfig: IPluginConfig = {
  name: "MiroBoard",
  multi: true,
  setupFunc: setupPlugin,
  component: MiroBoard,
  icon: MiroIcon,
  iconColour: undefined,
  webInline: true,
  canPopout: true,
};

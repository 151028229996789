import { observer } from "mobx-react";
import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import { OTTouchableOpacity } from '.';
import { hitSlop5 } from '../utils/OTUtils';
import { Theme } from "../globals/ThemeColors";
import { SKMegaphone, SKKnock, SKMic, SKVideo } from "./SVGIcons";
import { Feather } from '@expo/vector-icons';
import { OTUITree } from "@openteam/app-core";

interface IUserAlertBadgesProps {
    userId: string
    teamId: string
}

interface IUserAlertBadgesState {
}

@observer
export default class UserAlertBadges extends Component<IUserAlertBadgesProps, IUserAlertBadgesState> {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const teamManager = OTUITree.teamManagers[this.props.teamId]

        const { userId } = this.props

        const userBadges = teamManager.alertManager.badges[userId]

        if (!userBadges) {
            return <View />
        }

        return (
            <View style={{
                margin: 5,
                alignItems: 'center'
            }}>
                <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255,255,255,0.9)',
                    padding: 5,
                    borderRadius: Theme.curviness,
                }}>
                    {
                        userBadges?.KNOCK ?
                            <View style={[styles.iconHolder, { marginTop: -5 }]}>
                                <SKKnock size={15} style={iconStyle} />
                            </View>
                            :
                            null
                    }
                    {
                        userBadges?.CALL ?
                            <View style={styles.iconHolder}>
                                <SKVideo size={18} style={iconStyle} />
                            </View>
                            :
                            null
                    }
                    {
                        userBadges?.PTT_WALKIE ?
                            <View style={styles.iconHolder}>
                                <SKMic size={17} style={iconStyle} />
                            </View>
                            :
                            null
                    }
                    {
                        userBadges?.PTT_GLOBAL ?
                            <View style={styles.iconHolder}>
                                <SKMegaphone size={16} style={iconStyle} />
                            </View>
                            :
                            null
                    }
                    <OTTouchableOpacity
                        onPress={() => teamManager.alertManager.removeUserAlerts(userId)}
                        stopPropagation={true}
                        analyticsEvent="clear_user_alerts"
                        hitSlop={hitSlop5}
                    >
                        <Feather name="x" size={14} style={{ color: '#555' }} />
                    </OTTouchableOpacity>
                </View>
            </View>
        )
    }

}

const iconStyle = { color: Theme.ButtonNegativeColor }
const styles = StyleSheet.create({
    iconHolder: {
        height: 17,
        width: 17,
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 5
    }
})
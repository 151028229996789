import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlatList, ListRenderItemInfo, View } from "react-native";

import * as StyleConstants from "../../globals/StyleConstants";
import { Theme } from "../../globals/ThemeColors";
import { OTGlobals, PluginManager } from "@openteam/app-core";
import { PluginListItem } from "./PluginListItem";

interface IPluginListProps {
  pluginManager: PluginManager;
  onClose: () => void;
}
interface IPluginListState {}

@observer
export class PluginList extends Component<IPluginListProps, IPluginListState> {
  render() {
    const pluginConfigList = OTGlobals.pluginConfigList;

    return (
      <View
        style={{
          ...StyleConstants.BoxShadow,
          margin: 5,
          padding: 10,
          borderRadius: Theme.curviness,
          maxHeight: "70vh",
          maxWidth: 300,
        }}
      >
        <FlatList
          style={{ minHeight: 300, maxHeight: "40vh", minWidth: 250 }}
          data={Object.keys(pluginConfigList)}
          renderItem={this.renderItem}
          keyExtractor={(item) => item}
        />
      </View>
    );
  }

  renderItem = ({ item }: ListRenderItemInfo<string>) => {
    return (
      <PluginListItem
        pluginType={item}
        pluginManager={this.props.pluginManager}
        onClose={this.props.onClose}
      />
    );
  };
}

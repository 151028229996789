import { Logger } from "@openteam/app-util";
import { computed, observable } from "mobx";
import { FeedbackDb } from "../fire";
import { IFeedback, IUserDoc } from "@openteam/models";
import { OTUITree } from "../OTUITree";

const logger = new Logger("FeedbackManager");

export class FeedbackManager {
  @observable watchedFeedback: { [id: string]: IFeedback } = {};
  @observable feedbackUnread: { [id: string]: number } = {};
  @observable feedbackLastId?: string = undefined;

  userId: string;
  fbDb: firebase.database.Database;

  constructor(fbDb: firebase.database.Database, userId: string) {
    this.userId = userId;
    this.fbDb = fbDb;

    OTUITree.feedbackUI = this;
  }

  start = () => {
    FeedbackDb.watchFeedbackLastId(this.fbDb, (feedbackId) => {
      this.feedbackLastId = feedbackId;
    });
    FeedbackDb.watchMyFeedback(this.fbDb, this.userId, this.hdl_feedback);
  }

  stop = () => {
    FeedbackDb.unwatchFeedbackLastId(this.fbDb);
    FeedbackDb.unwatchMyFeedback(this.fbDb, this.userId);
  }


  hdl_feedback = (doc: { [id: string]: IFeedback }) => {
    this.watchedFeedback = doc;

    Object.values(doc || {}).forEach((feedback) => {
      let lastRead = feedback.followers?.[this.userId].lastRead || "";
      let comments = Object.keys(feedback.comments || {});
      let index = comments.indexOf(lastRead);
      this.feedbackUnread[feedback.feedbackId] = comments.length - (index + 1);
    });
  };

  markFeedbackLastId = (feedbackLastId: string | undefined) => {
    if (this.feedbackLastId && this.feedbackLastId != feedbackLastId) {
      FeedbackDb.markFeedbackLastId(this.fbDb, this.userId, this.feedbackLastId);
    }
  };

  getMoreFeedback = async (feedbackId: string | undefined, pageSize: number) =>
    FeedbackDb.getMoreFeedback(this.fbDb, feedbackId, pageSize);

  addFeedback = async (username: string, imageUrl: string | null, title, desc, type, files) =>
    FeedbackDb.addFeedback(this.fbDb, this.userId, username, imageUrl, title, desc, type, files);

  updateFeedback = async (
    feedbackId: string,
    username: string,
    imageUrl: string | null,
    title,
    desc,
    type,
    status,
    files
  ) =>
    FeedbackDb.updateFeedback(
      this.fbDb,
      this.userId,
      feedbackId,
      username,
      imageUrl,
      title,
      desc,
      type,
      status,
      files
    );

  watchFeedback = async (feedbackId: string, callback: (doc: IFeedback) => void) =>
    FeedbackDb.watchFeedback(this.fbDb, feedbackId, callback);

  unwatchFeedback = async (feedbackId: string) => FeedbackDb.unwatchFeedback(this.fbDb, feedbackId);

  markReadFeedback = async (feedbackId: string, lastRead: string) =>
    FeedbackDb.markReadFeedback(this.fbDb, this.userId, feedbackId, lastRead);

  commentFeedback = async (
    feedbackId: string,
    username: string,
    imageUrl: string | null,
    message,
    files,
    setFollow
  ) =>
    FeedbackDb.commentFeedback(
      this.fbDb,
      this.userId,
      feedbackId,
      username,
      imageUrl,
      message,
      files,
      setFollow
    );

  followFeedback = async (feedbackId: string, follow: boolean) =>
    FeedbackDb.followFeedback(this.fbDb, this.userId, feedbackId, follow);

  archiveFeedback = async (feedbackId: string) => FeedbackDb.archiveFeedback(this.fbDb, feedbackId);

  voteFeedback = async (feedbackId: string, voted: boolean) =>
    FeedbackDb.voteFeedback(this.fbDb, this.userId, feedbackId, voted);

  @computed get numUnread() {
    return Object.values(this.feedbackUnread).filter((unread) => unread > 0).length;
  }

  hasNew = (userDoc: IUserDoc | undefined) =>
    computed(() => {
      if (this.feedbackLastId) {
        if (this.feedbackLastId != userDoc?.feedbackLastId) {
          return true;
        }
      }
      return false;
    });
}

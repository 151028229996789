import { action, observable } from "mobx";
export class WindowState {
  @observable windowFocused: boolean = true;
  @observable windowShown: boolean = true;

  @action reset() {
    this.windowFocused = true;
    this.windowShown = true;
  }
}

export const windowState = new WindowState();

import { observer } from "mobx-react";
import React, { Component } from "react";
import { View, Switch } from "react-native";
import HeadIcon from "../HeadIcon";
import { Theme } from "../../globals/ThemeColors";
import { OTText, OTTextInput } from "../OTText";
import { OTButton } from "../buttons/OTButtons";
import { dismissModal } from "../ModalService";
import { DialogView } from "../DialogView";
import Select from "react-select";
import SettingsRow from "../settingsRow";
import { IOption } from "@openteam/models";
import { OTGlobals, OTUITree } from "@openteam/app-core";
import { ImageMultiValue, ImageOption } from "./CreateChat";

interface ICreateChannelProps {
  teamId: string;
  channelId?: string;
}

interface ICreateChannelState {
  selectedUsers: IOption[];
  isEditing: boolean;
  isPrivate: boolean;
  teamDefault: boolean;
  name: string;
  desc: string;
}

@observer
export class CreateChannel extends Component<ICreateChannelProps, ICreateChannelState> {
  constructor(props) {
    super(props);

    const chatUIState = OTUITree.chatUIStates[this.props.teamId];

    var name: string = "";
    var desc: string = "";
    var isPrivate: boolean = false;
    var isEditing = false;
    var teamDefault = false;


    if (this.props.channelId) {
      const channel = chatUIState.getChannel(this.props.channelId);

      if (channel) {
        isEditing = true;
        isPrivate = channel.chatType == "private_channel";
        teamDefault = channel.teamDefault || false;
        name = channel.name || "";
        desc = channel.desc || "";
      }
    }

    this.state = {
      selectedUsers: [],
      isEditing: isEditing,
      isPrivate: isPrivate,
      teamDefault: teamDefault,
      name: name,
      desc: desc,
    };
  }

  onUserIdsChanged = (userOption) => {
    this.setState({
      selectedUsers: userOption || [],
    });
  };

  createChat = async () => {
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];

    if (this.state.name) {
      if (!this.props.channelId) {
        console.log("try to create channel", this.state.name, this.state.selectedUsers);

        const channelId = await chatUIState.createChannel(
          this.state.teamDefault ? [] : this.state.selectedUsers.map((item) => item.value),
          this.state.name,
          this.state.desc,
          this.state.isPrivate ? "private_channel" : "channel",
          this.state.teamDefault
        );
        chatUIState.goChannel(channelId, 'default');
      } else {
        await chatUIState.updateChannel(
          this.props.channelId,
          this.state.selectedUsers.map((item) => item.value),
          this.state.name,
          this.state.desc
        );
      }

      dismissModal();
    }
  };

  leaveChannel = () => {
    if (this.props.channelId) {
      const chatUIState = OTUITree.chatUIStates[this.props.teamId];

      chatUIState.closeChannel(this.props.channelId)
      chatUIState.removeChannelUser(this.props.channelId, OTUITree.auth.userId)
      dismissModal()
    }
  }

  render() {
    const teamData = OTGlobals.getTeamData(this.props.teamId);
    var currentUsers = {};

    if (this.props.channelId) {
      const chatUIState = OTUITree.chatUIStates[this.props.teamId];
      const channel = chatUIState.getChannel(this.props.channelId);

      currentUsers = channel.userIds?.reduce(function (obj, x) {
        obj[x] = {
          id: x,
        };
        return obj;
      }, {}) || {};
    }

    const options = Object.keys(teamData.users)
      .filter((userId) => userId != OTUITree.auth.userId && !(userId in currentUsers))
      .sort((a, b) => ("" + teamData.users[a].name).localeCompare(teamData.users[b].name))
      .map((userId) => ({
        label: teamData.users[userId].name,
        value: userId,
        imageUrl: teamData.users[userId].imageUrl,
      }));

    return (
      <DialogView
        title={this.state.isEditing ? "Channel Settings" : "Create Channel"}
        buttons={[
          {
            title: this.state.isEditing ? "Save Channel" : "Create Channel",
            onPress: this.createChat,
            disabled: this.state.name.length == 0,
          },
        ]}
        footer={
          this.state.isEditing && OTUITree.auth.userId in currentUsers ? (
            <View style={{ flexDirection: "row" }}>
              <OTButton
                title="Leave Channel"
                onPress={this.leaveChannel}
                backgroundColor={Theme.ButtonNegativeColor}
                textColor={Theme.ButtonNegativeIconColor}
              />
            </View>
          ) : undefined
        }
      >
        <View style={{ width: 420, marginVertical: 20, alignItems: "center" }}>
          <SettingsRow title="Channel Name" width={420}>
            <OTTextInput
              style={{
                width: undefined,
                flex: 1,
              }}
              value={this.state.name}
              onChangeText={(text) => this.setState({ name: text })}
              placeholder="Channel Name"
            />
          </SettingsRow>
          <SettingsRow title="Channel Description" width={420}>
            <OTTextInput
              style={{
                width: undefined,
                flex: 1,
              }}
              value={this.state.desc}
              onChangeText={(text) => this.setState({ desc: text })}
              placeholder="Description"
            />
          </SettingsRow>

          {!this.state.isEditing ? (
            <>
              <SettingsRow title="Make Private" width={420}>
                <View style={{ flexDirection: "row" }}>
                  <OTText style={{ flex: 1 }}>
                    A private channel can only be seen by it's members
                  </OTText>
                  <Switch
                    value={this.state.isPrivate}
                    onValueChange={(value) =>
                      this.setState({
                        isPrivate: value,
                        teamDefault: value ? false : this.state.teamDefault,
                      })
                    }
                  />
                </View>
              </SettingsRow>

              <SettingsRow title="Global Channel" width={420}>
                <View style={{ flexDirection: "row" }}>
                  <OTText style={{ flex: 1 }}>Automatically add everyone in the company</OTText>
                  <Switch
                    disabled={this.state.isPrivate}
                    value={this.state.teamDefault}
                    onValueChange={(value) => this.setState({ teamDefault: value })}
                  />
                </View>
              </SettingsRow>
            </>
          ) : null}

          {this.state.teamDefault ? null : (
            <SettingsRow title="Add Members" width={420}>
              <Select
                value={this.state.selectedUsers}
                onChange={this.onUserIdsChanged}
                menuPortalTarget={document.getElementById("otbody")}
                placeholder="Select Team mates"
                options={options}
                components={{
                  Option: ImageOption,
                  MultiValue: ImageMultiValue,
                }}
                isSearchable={true}
                isMulti={true}
                isClearable={true}
              />
            </SettingsRow>
          )}

          {this.state.isEditing ? (
            <SettingsRow title="Members" width={420}>
              {Object.keys(currentUsers).map((userId) => this.renderUser(userId))}
            </SettingsRow>
          ) : null}
        </View>
      </DialogView>
    );
  }
  renderUser = (userId: string) => {
    const chatUIState = OTUITree.chatUIStates[this.props.teamId];
    const teamData = OTGlobals.getTeamData(this.props.teamId);
    const user = teamData.getTeamUser(userId);

    return (
      <View key={userId} style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}>
        <HeadIcon size={36} imageUrl={user.imageUrl} name={user.name} />
        <OTText style={{ marginLeft: 10, fontSize: 14, flex: 1, fontWeight: "600" }}>
          {user.name}
        </OTText>
        {userId != OTUITree.auth.userId ? (
          <OTButton
            small={true}
            title="Remove"
            onPress={() => chatUIState.removeChannelUser(this.props.channelId!, userId)}
          />
        ) : null}
      </View>
    );
  };
}

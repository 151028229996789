import * as SentryReact from "@sentry/react";
import React from "react";
import { StyleSheet, View, Image } from "react-native";
import firebase from "firebase/app";
import "firebase/auth";
import * as Fire from "./src/globals/Fire";
import ReactTooltip from "react-tooltip";
import validate from "validate.js";
import { MenuProvider } from "react-native-popup-menu";
import * as Font from "expo-font";
import { FontAwesome, Feather } from "@expo/vector-icons";

import * as WindowUtils from "./src/utils/WindowUtils";
import { Theme, LogoSource } from "./src/globals/ThemeColors";

import queryString from "query-string";

import { OTUserData, OTAppData } from "./src/data/OTData";

import { observer } from "mobx-react";
import { IOTRemoteConfig } from "@openteam/models";

import * as ModalService from "./src/components/ModalService";

import semver from "semver";
import { OTTouchableOpacity } from "./src/components";

import { AppHome, SetupAccount, Login } from "./src/web";

import { getToastContainer } from "./src/components/Toasts";
import { Logger, initLoggerApp, setLogSender, InfluxSender } from "@openteam/app-util";
import { LoadingView } from "./src/components/LoadingView";
import { isFrameless, appUpgrade } from "./src/utils/platform";
import FullScreen from "react-full-screen";
import { workspaceNameConstraints } from "./src/components/team";
import ExternalMeeting from "./src/web/externalView";
import { OTText } from "./src/components/OTText";
import * as platform from "./src/utils/platform";
import { AppearanceProvider } from "react-native-appearance";

import { setup, signOut } from "./src/globals/app";
import { OTAppCoreData, OTGlobals, FireDb, OTUITree, OTAuth } from "@openteam/app-core";
import { getRTDB } from "./src/globals/Fire/util";
import { action } from "mobx";
import { INFLUX_APP_LOG_TOKEN } from "./src/globals/config";

setup();

initLoggerApp("webapp");

let influxSender: InfluxSender;

if (INFLUX_APP_LOG_TOKEN) {
  influxSender = new InfluxSender(
    platform.PlatformOS,
    INFLUX_APP_LOG_TOKEN,
    OTAppCoreData.deviceId
  );

  setLogSender(influxSender);
  console.log("setup InfluxSender");
}

const logger = new Logger("App.web");

function cacheFonts(fonts) {
  return fonts.map((font) => Font.loadAsync(font));
}

@observer
export default class App extends React.Component<any, { externalMeetingCheck: boolean }> {
  constructor(props) {
    super(props);

    logger.debug("created app");

    if (!OTGlobals._auth) {
      // the check on OTGlobals._auth is for hot reloading
      const auth = new OTAuth(getRTDB());
      auth.on("signOut", signOut);
      OTGlobals.registerAuth(auth);
    }
    this.state = {
      externalMeetingCheck: false,
    };
  }

  componentDidMount = async () => {
    await this._loadAssetsAsync();
    await OTGlobals.cache.loadingPromise;
    logger.info(
      "userAgent",
      navigator.userAgent,
      "deviceId",
      OTAppCoreData.deviceId,
      "sessionToken",
      OTUITree.auth.sessionToken
    );

    platform.setupApp();

    this.getConfig();

    await this.checkPathArgs();

    this.setupLogin();
  };

  async _loadAssetsAsync() {
    FontAwesome.font["FontAwesome"] += "?version=" + OTAppCoreData.version;
    Feather.font["feather"] += "?version=" + OTAppCoreData.version;
    const fontAssets = cacheFonts([FontAwesome.font, Feather.font]);

    await Promise.all([, ...fontAssets]);
  }

  getConfig = () => {
    Fire.watchConfig((data: IOTRemoteConfig) => {
      logger.info("loaded remoteConfig data=%o", data);
      if (data) {
        OTAppCoreData.remoteConfig = data;
        logger.info("OTAppData.version", OTAppCoreData.version);

        if (data.version && OTAppCoreData.version) {
          if (semver.gt(data.version, OTAppCoreData.version)) {
            var semDiff = semver.diff(data.version, OTAppCoreData.version);
            if (semDiff == "major" || semDiff == "minor") {
              OTAppData.webUpgradeForce = true;
              // if its a major upgrade then we need to disconnect everything if we're already conneected so lets
              if (OTGlobals._appHomeManager) {
                WindowUtils.reload();
              }
            }
            OTAppData.webUpgradeAvailable = true;
          }
        }

        if (data.splashEnabled != OTAppData.splashEnabled) {
          if (OTAppData.splashEnabled == undefined) {
            OTAppData.splashEnabled = data.splashEnabled;
          } else {
            WindowUtils.reload(true);
          }
        }
      }
    });

    Fire.watchAdmins((data: { [id: string]: { id: string } }) => {
      if (data) {
        OTAppData.admins = data;
        logger.info("OTAppData.admins", OTAppData.admins);
      }
    });
  };

  checkPathArgs = async () => {
    const parsed = queryString.parse(location.search);
    // check for teampath and inviteId
    var path = WindowUtils.getPath();

    if (parsed.referrerId) {
      if (Array.isArray(parsed.referrerId)) {
        OTUserData.referrerId = parsed.referrerId[0];
      } else {
        OTUserData.referrerId = parsed.referrerId || undefined;
      }
    }

    var externalLink = path.match(new RegExp("^([a-zA-Z0-9_-]*)/([a-zA-Z0-9_-]*)$"));

    if (externalLink) {
      console.log("externalLink", externalLink);

      var roomId: undefined | string;
      if (Array.isArray(parsed.roomId)) {
        roomId = parsed.roomId[0];
      } else {
        roomId = parsed.roomId || undefined;
      }

      var teamPath = externalLink[1];
      var address = externalLink[2];

      OTUserData.externalMeeting = {
        teamPath: teamPath,
        address: address,
        roomId: roomId,
      };

      if (!OTUITree.auth.userId) {
        Fire.getAnonymousLogin();
      }

      path = "";
    }

    if (path) {
      try {
        await validate.async({ workspaceName: path }, workspaceNameConstraints);
      } catch (err) {
        logger.error("invalid workspaceName", path);
        path = "";
      }
    }

    if (path) {
      logger.info("detected workspaceName", path);

      OTUserData.reqTeamPath = path;

      if (parsed.inviteId) {
        if (Array.isArray(parsed.inviteId)) {
          OTUserData.teamInviteId = parsed.inviteId[0];
        } else {
          OTUserData.teamInviteId = parsed.inviteId;
        }
        logger.info("detected inviteId", OTUserData.teamInviteId);
      }
    }

    // check if this is an app login
    if (parsed.appLogin) {
      if (Array.isArray(parsed.appLogin)) {
        OTUserData.appLogin = parsed.appLogin[0];
      } else {
        OTUserData.appLogin = parsed.appLogin;
      }
    }

    // check if this is a loginRedirect
    if (parsed.loginRedirect) {
      if (Array.isArray(parsed.loginRedirect)) {
        OTUserData.loginRedirect = parsed.loginRedirect[0];
      } else {
        OTUserData.loginRedirect = parsed.loginRedirect;
      }
    }
  };

  setupLogin = async () => {
    OTUITree.auth.loadFromCache();
    Fire.onAuthStateChanged(this._onAuthStateChanged);
  };

  _onAuthStateChanged = async (user: firebase.User | null) => {
    OTUITree.auth.onAuthStateChanged(user);

    if (user && user.uid) {
      if (influxSender) {
        influxSender.setUser(OTUITree.auth.sessionToken, user.uid);
      }

      if (!user.isAnonymous) {
        await this.checkExternalMeeting();
      }
    }
  };

  checkExternalMeeting = async () => {
    const teamPath = OTUserData.externalMeeting?.teamPath;
    logger.info(`external meeting ${teamPath}`);

    if (teamPath) {
      this.setState({ externalMeetingCheck: true });
      const teamId = await FireDb.getTeamPathId(getRTDB(), teamPath);
      const hasAccess = await FireDb.checkTeamAccess(getRTDB(), OTUITree.auth.userId, teamId);
      logger.info(`external meeting ${teamPath} (teamId=${teamId}) hasAccess=${hasAccess}`);

      if (hasAccess) {
        logger.info(`setting external meeting to undefined, user has access to this team`);
        OTUserData.reqTeamPath = teamPath;
        OTUserData.externalMeeting = undefined;
        this.setState({ externalMeetingCheck: false });
      }
    }
  };

  forceReload = () => {
    appUpgrade();
  };

  render() {
    logger.debug(
      "render() isLoaded=%s, externalMeeting=%o",
      OTUITree.auth.isLoaded,
      OTUserData.externalMeeting
    );

    return (
      <SentryReact.ErrorBoundary fallback={"An error has occurred"}>
        <FullScreen
          enabled={OTAppData.isFullscreen}
          onChange={(isFullscreen) => {
            OTAppData.isFullscreen = isFullscreen;
          }}
        >
          <div id="otbody" className="otbody">
            <AppearanceProvider>
              <MenuProvider>
                <View style={{ flex: 1, width: "100vw", height: "100vh" }}>
                  {this.renderContent()}

                  <ModalService.ModalContainer ref={(c) => ModalService.setModalContainer(c)} />
                  {getToastContainer()}
                  <ReactTooltip
                    className="reacttooltip"
                    effect="solid"
                    delayShow={500}
                    delayUpdate={500}
                    afterShow={(evt) => setTimeout(() => ReactTooltip.hide(evt.target), 10000)}
                  />
                  <style
                    dangerouslySetInnerHTML={{
                      __html: `
              @import url("https://use.typekit.net/yes5dwt.css");

              html {
                font-family: 'Lato', sans-serif;
                font-size: 14px;
              }
              svg {
                font-size: 16px;
              }
              .fullscreen {
                display: flex;
                flex: 1;
              }
              .otbody {
                display: flex;
                flex: 1;
              }
              .reacttooltip {
                font-weight: 500;
              }
              input {
                outline: none;
              }
              textarea {
                outline: none;
              }
              .pluginfill {
                height: 100%;
                width: 100%;
              }
              .Toastify__toast {
                border-radius: 5px;
              }
              ::-webkit-scrollbar {
                width: 0.62em;
                background: ${Theme.ScrollbarTrackColour};
              }
              ::-webkit-scrollbar-thumb {
                background: ${Theme.ScrollbarColour};
                border-radius: 10px
              }

              ::-webkit-scrollbar-track-piece
              {
                 display:none;
              }
              .mdl-shadow--2dp {
                box-shadow: none;
              }
              .mdl-button {
                border-radius: 5px;
              }
            `,
                    }}
                  />
                </View>
              </MenuProvider>
            </AppearanceProvider>
          </div>
        </FullScreen>
      </SentryReact.ErrorBoundary>
    );
  }
  renderContent = () => {
    if (OTAppCoreData.remoteConfig?.splashEnabled) {
      return (
        <View style={styles.container}>
          <Image
            source={LogoSource}
            style={{ width: 300, height: 60, resizeMode: "contain", marginBottom: 30 }}
          />

          <View>
            <OTText style={{ textAlign: "center" }}>OpenTeam is currently under maintenance</OTText>
          </View>
        </View>
      );
    }

    if (
      !OTUITree.auth.isLoaded ||
      this.state.externalMeetingCheck ||
      (OTUserData.externalMeeting && !OTUITree.auth.userId)
    ) {
      return (
        <View style={styles.container}>
          <LoadingView size="large" title="Getting OpenTeam ready..." />
        </View>
      );
    }

    if (OTAppData.webUpgradeForce) {
      return (
        <ModalService.OTModal>
          <View style={styles.mandatoryUpgradeContainer}>
            <OTText style={{ fontSize: 20, paddingBottom: 20, fontWeight: "bold" }}>
              Version out of date
            </OTText>

            <OTTouchableOpacity style={styles.mandatoryUpgradeButton} onPress={this.forceReload}>
              <OTText style={{ color: "white", fontSize: 18, fontWeight: "bold" }}>Upgrade</OTText>
            </OTTouchableOpacity>
          </View>
        </ModalService.OTModal>
      );
    }
    return (
      <View style={{ flex: 1 }}>
        {isFrameless ? (
          <div
            style={
              {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                height: 40,
                pointerEvents: "none",
                WebkitAppRegion: "drag",
                WebkitUserSelect: "none",
              } as any
            }
          />
        ) : null}

        {this.renderPage()}
      </View>
    );
  };

  renderPage = () => {
    logger.debug(
      "renderPage loaded=%s, userId=%s, isAnonymous=%s, externalMeeting=%o",
      OTUITree.auth.isLoaded,
      OTUITree.auth.userId,
      OTUITree.auth.isAnonymous,
      OTUserData.externalMeeting
    );

    if (OTUserData.externalMeeting && OTUITree.auth.userId) {
      logger.debug("Rendered");
      return <ExternalMeeting />;
    }

    if (!OTUITree.auth.userId || OTUITree.auth.isAnonymous) {
      return <Login />;
    } else {
      const userManager = OTUITree.userManager;

      if (!userManager.userDoc?.accountSetup) return <SetupAccount />;

      return <AppHome />;
    }
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Theme.OfficeBackground,
    alignItems: "center",
    justifyContent: "center",
  },
  upgradeButton: {
    padding: 10,
    backgroundColor: Theme.WarningColor,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  mandatoryUpgradeContainer: {
    backgroundColor: "white",
    borderRadius: Theme.curviness,
    padding: 20,
    margin: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  mandatoryUpgradeButton: {
    width: "100%",
    minWidth: 200,
    borderRadius: Theme.curviness,
    justifyContent: "center",
    alignItems: "center",
    height: 60,
    backgroundColor: Theme.WarningColor,
  },
});

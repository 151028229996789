import React, { Component } from "react";

import { observer } from "mobx-react";
import { View, Image, StyleSheet, Clipboard } from "react-native";

import { showModal, dismissModal } from "./ModalService";
import * as Fire from "../globals/Fire";
import { Logger } from "@openteam/app-util";
import { OTPopover } from "./OTPopover";
import { OTButton } from "./buttons/OTButtons";
import validate from "validate.js";
import { showToast } from "./Toasts";
import { WEB_URL } from "../globals/config";
import { OTText, OTTextInput } from "./OTText";
import { Theme } from "../globals/ThemeColors";
import { Feather } from "@expo/vector-icons";
import { OTGlobals, OTUITree } from "@openteam/app-core";
import { DialogView } from "./DialogView";
import { PendingMeetingUsers } from "./PendingMeetingUsers";

const logger = new Logger("ExternalMeetings");

interface IMeetingButtonProps {
  teamId: string;
  iconSize?: number;
  iconColor?: string;
  dataSet?: { [id: string]: string };
}

interface IMeetingButtonState {}

@observer
export class MeetingButton extends Component<IMeetingButtonProps, IMeetingButtonState> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  copyMeetingAddress = () => {
    const teamData = OTGlobals.getTeamData(this.props.teamId);
    const meetingUIState = OTUITree.meetingUIStates[this.props.teamId];

    var url = `${WEB_URL}/${teamData.teamPath}/${meetingUIState.address}`;

    Clipboard.setString(url);
    showToast("Copied to clipboard", "info");
  };

  render() {
    const meetingUIState = OTUITree.meetingUIStates[this.props.teamId];

    return (
      <OTPopover
        dataSet={this.props.dataSet}
        title={meetingUIState.address ? "Create External Meeting" : "Setup External Meetings"}
        isOpen={meetingUIState.showMenu}
        toggleIsOpen={meetingUIState.toggleShowMenu}
        badgeNum={Object.keys(meetingUIState.addressDoc?.waiting || {}).length}
        renderOpen={this.renderMenu}
        icon={
          <Feather
            name="calendar"
            size={this.props.iconSize ? this.props.iconSize : 20}
            style={{ color: this.props.iconColor }}
          />
        }
      />
    );
  }
  renderMenu = () => {
    const meetingUIState = OTUITree.meetingUIStates[this.props.teamId];
    const teamData = OTGlobals.getTeamData(this.props.teamId);

    if (!meetingUIState.address) {
      return (
        <View>
          <OTText style={{ alignSelf: "center", textAlign: "center", paddingBottom: 20 }}>
            Schedule meetings with users outside of your team by sharing your unique external
            meeting link
          </OTText>
          <View style={{ flexDirection: "row" }}>
            <OTButton
              icon={
                <Feather
                  name="link"
                  style={{ color: Theme.ButtonIconColor, marginRight: 8 }}
                  size={14}
                />
              }
              title="Create external meetings"
              onPress={this.showSetupExternalMeetings}
            />
          </View>
        </View>
      );
    }
    var url = `${WEB_URL}/${teamData.teamPath}/${meetingUIState.address}`;

    return (
      <View>
        <OTText style={{ alignSelf: "center", marginBottom: 10 }}>
          Share your meeting link with guests
        </OTText>
        <View style={{ flexDirection: "row" }}>
          <OTTextInput
            value={url}
            style={{
              flex: 1,
              minWidth: 200,
              borderWidth: 1,
              borderColor: Theme.InputHighlightColor,
              borderRadius: 5,
              marginRight: 5,
              paddingHorizontal: 5,
            }}
            // @ts-expect-error target is not a number
            onFocus={(event) => event.target.select()}
          />
          <OTButton
            title="Copy"
            small={true}
            backgroundColor={Theme.ButtonColor}
            textColor={Theme.ButtonIconColor}
            onPress={this.copyMeetingAddress}
          />
        </View>

        <View style={{ marginTop: 10 }}>
          <PendingMeetingUsers teamId={this.props.teamId} isSection={true} />
        </View>
      </View>
    );
  };

  showSetupExternalMeetings = () => {
    const meetingUIState = OTUITree.meetingUIStates[this.props.teamId];

    meetingUIState.toggleShowMenu();

    showModal({
      contents: (
        <SetupMeetingAddress
          teamId={this.props.teamId}
          onClose={dismissModal}
          onSuccess={() => {}}
        />
      ),
      showContainer: true,
    });
  };
}

export const addressConstraints = {
  address: {
    presence: {
      allowEmpty: false,
      message: "^Please enter a link address",
    },
    format: {
      pattern: "^[a-zA-Z0-9_-]*$",
      message: "can only contain alphanumeric digits, underscores and dashes",
    },
  },
};

interface ISetupMeetingAddressProps {
  teamId: string;
  onSuccess?: () => void;
  onClose: () => void;
}

interface ISetupMeetingAddressState {
  address: string;
  created: boolean;
  errors?: any[];
}

@observer
export class SetupMeetingAddress extends Component<
  ISetupMeetingAddressProps,
  ISetupMeetingAddressState
> {
  constructor(props) {
    super(props);

    this.state = {
      address: "",
      created: false,
    };
  }

  onChangeText = (text) => {
    this.setState({
      address: text,
    });
  };

  onPress = async () => {
    const asyncConstraints = {
      address: {
        checkAddress: true,
      },
    };

    var teamId = this.props.teamId;

    validate.validators.checkAddress = function (value) {
      return new (validate as any).Promise(async function (resolve, reject) {
        var addressDoc = await Fire.getTeamExternalAddress(teamId, value);
        logger.debug("addressDoc for", teamId, value, addressDoc);
        if (addressDoc) {
          resolve("already exists");
        } else {
          resolve();
        }
      });
    };

    try {
      await validate.async(this.state, addressConstraints);
      await validate.async(this.state, asyncConstraints);
    } catch (err) {
      this.setState({ errors: err });
      return;
    }

    try {
      var addressDoc = await Fire.createTeamExternalAddress(teamId, this.state.address);

      // if im already logged in then reload the page to the new team, otherwise load the team
      this.props.onSuccess && this.props.onSuccess();

      this.setState({ created: true });
    } catch (err) {
      showToast("Failed to create link: " + err.message, "error");
    }
  };

  getErrorMessages = (separator = "\n") => {
    if (!this.state.errors) {
      return [];
    }

    return Object.values(this.state.errors)
      .map((it: string[]) => it.join(separator))
      .join(separator);
  };

  render() {
    const teamData = OTGlobals.getTeamData(this.props.teamId);

    if (this.state.created) {
      return this.renderComplete();
    }
    return (
      <DialogView
        title="Create your personalised meeting link"
        buttons={[
          {
            title: "Cancel",
            onPress: this.props.onClose,
            backgroundColor: Theme.ButtonIconColor,
            textColor: Theme.ButtonColor,
            borderColor: Theme.ButtonColor,
          },
          {
            title: "Create Link",
            onPress: this.onPress,
          },
        ]}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <OTText style={{ fontSize: 14, marginRight: 10, color: Theme.MainColour }}>
            {WEB_URL}/{teamData.teamPath}/
          </OTText>
          <OTTextInput
            style={styles.nameInput}
            value={this.state.address}
            onChangeText={this.onChangeText}
            onSubmitEditing={this.onPress}
            placeholder="Your name"
          />
        </View>

        <OTText style={styles.error}>{this.getErrorMessages()}</OTText>
      </DialogView>
    );
  }

  renderComplete = () => {
    const teamData = OTGlobals.getTeamData(this.props.teamId);

    return (
      <DialogView
        title="Your link has been created!"
        buttons={[
          {
            title: "Close",
            onPress: this.props.onClose,
          },
        ]}
      >
        <Image
          source={{
            uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fexternalmeetingcreated.svg?alt=media",
          }}
          style={{
            width: 172,
            height: 116,
            resizeMode: "contain",
            margin: 20,
          }}
        />

        <OTText style={{ fontWeight: "600", fontSize: 18 }}>
          Share your unique link with guests:
        </OTText>
        <View style={{ alignItems: "center", width: 300, marginTop: 20 }}>
          <OTText style={{ fontWeight: "600", fontSize: 14, textAlign: "center" }}>
            Don't worry meeting attendees will have to be accepted into the meeting from your
            virtual waiting room to avoid surprise visits!
          </OTText>
        </View>

        <OTText
          style={{
            margin: 20,
            marginBottom: 10,
            fontWeight: "600",
            color: Theme.MainColour,
          }}
        >
          {WEB_URL}/{teamData.teamPath}/{this.state.address}
        </OTText>
      </DialogView>
    );
  };
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  nameInput: {
    borderRadius: Theme.curviness / 1.5,
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: Theme.InputHighlightColor,
    padding: 10,
    fontSize: 14,
    minWidth: 150,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    marginTop: 20,
  },
  error: { color: "red", textAlign: "center", paddingTop: 10 },
  buttonText: {},
});

import firebase from "firebase/app";
import "firebase/functions";

import events from "events";

import { observable, runInAction, computed, autorun } from "mobx";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { PluginDb } from "./fire";
import { Logger } from "@openteam/app-util";
import { IPluginResource } from "@openteam/models";
import { OTGlobals } from "./OTGlobals";
import { OTUserInterface } from "./OTUserInterface";

const logger = new Logger("PluginManager");

export interface IPluginConfig {
  name: string;
  multi: boolean;
  component?: any;
  start?: (x: IPluginResource) => void;
  setupFunc?: (x: PluginManager) => void;
  icon: any;
  iconColour?: string;
  aspectRatio?: number;
  webInline?: boolean;
  canPopout?: boolean;
  backgroundColor?: string;
  canHandleUrl?: (url) => any;
  urlPriority?: number;
}

export class PluginManager extends events.EventEmitter {
  fbDb: firebase.database.Database;
  userId: string;
  _teamId: string;
  _roomId: string;

  @observable plugins: { [id: string]: IPluginResource } = {};
  @observable showPluginMenu: boolean = false;
  @observable focusedPluginId?: string;
  @observable poppedoutPlugins: { [id: string]: { window: Window; ref: string } } = {};
  popoutPoll;

  @observable pluginHistory: { [id: string]: IPluginResource } = {};

  unsupportedPlugins = new Set();

  _autorun: Record<string, any> = {};

  constructor(fbDb: firebase.database.Database, userId: string, teamId: string, roomId: string) {
    super();

    this.fbDb = fbDb;
    this.userId = userId;
    this._teamId = teamId;
    this._roomId = roomId;

    PluginDb.watchRoomPlugins(fbDb, teamId, roomId, this.handlePluginData);

    this.popoutPoll = setInterval(this.checkPopouts, 1000);
    this.loadFromAsyncStorage();
    this.start();
  }

  start = () => {
    this._autorun["pluginHistory"] = autorun(
      () => {
        AsyncStorage.setItem(
          `pluginHistory-${this._teamId}`,
          JSON.stringify({
            pluginHistory: this.pluginHistory,
          })
        );
      },
      { delay: 1000 }
    );
  };
  
  stop = () => {
    Object.values(this._autorun).map((x) => x());
    this._autorun = {};
  };

  static getMiroToken = async () => {
    var getMiroToken = firebase.functions().httpsCallable("getMiroToken");
    var result = await getMiroToken();

    logger.info("getMiroToken", result.data.authToken);

    return result.data.authToken;
  };

  loadFromAsyncStorage = async () => {
    var jsonString = await AsyncStorage.getItem(`pluginHistory-${this._teamId}`);
    var loadedSettings = jsonString && JSON.parse(jsonString);

    if (loadedSettings) {
      var pluginhistory: { [id: string]: IPluginResource } = loadedSettings.pluginHistory || {};
      Object.values(pluginhistory)
        .sort((a, b) => a.crDate - b.crDate)
        .forEach((pluginDetails) => {
          this.pluginHistory[pluginDetails.pluginId] = pluginDetails;
        });
    }
  };

  toggleShowPlugins = () => {
    this.showPluginMenu = !this.showPluginMenu;
  };

  generateId = () => {
    return PluginDb.genPluginId(this.fbDb, this._teamId, this._roomId);
  };

  handlePluginData = (msg: IPluginResource, deleted: boolean) => {
    logger.info("received plugindata message", msg, "deleted", deleted);

    runInAction(() => {
      if (deleted) {
        this.closePlugin(msg.pluginId);
      } else if (msg.status == "A") {
        if (!(msg.pluginType in OTGlobals.pluginConfigList)) {
          if (!this.unsupportedPlugins.has(msg.pluginId)) {
            OTUserInterface.toastHandlers.show(
              `This version of OpenTeam doesn't support plugin ${msg.pluginType} please upgrade`,
              "error"
            );
            this.unsupportedPlugins.add(msg.pluginId);
          }
          return;
        }

        var isNew = !(msg.pluginId in this.plugins);

        this.plugins[msg.pluginId] = msg;

        if (isNew) {
          this.checkPluginWindow(msg.pluginId);
          this.setFocusPlugin(msg.pluginId);
          this.setHistory(msg);
        }
      }
    });
  };

  setHistory = (pluginDetails: IPluginResource) => {
    this.pluginHistory[pluginDetails.pluginId] = pluginDetails;
  };

  restoreHistory = (pluginId) => {
    var pluginDetails = this.pluginHistory[pluginId];
    this.createPlugin(pluginDetails.pluginType, pluginDetails.args, pluginId);
  };

  @computed get recentPlugins() {
    return Object.values(this.pluginHistory)
      .sort((a, b) => b.crDate - a.crDate)
      .filter((value) => !this.plugins[value.pluginId])
      .slice(0, 5);
  }

  getPlugins = () => {
    return Object.values(this.plugins).filter((plugin) => plugin.status == "A");
  };

  @computed get getPluginTiles() {
    logger.info("in getPluginTiles", this.poppedoutPlugins);
    return Object.keys(this.plugins)
      .filter(
        (pluginId) => this.plugins[pluginId].status == "A" && this.focusedPluginId == pluginId
      )
      .map((pluginId) => this.plugins[pluginId]);
  }

  getPlugin = (pluginId) => {
    return this.plugins[pluginId];
  };

  getPluginConfig = (pluginType) => {
    return OTGlobals.pluginConfigList[pluginType];
  };

  hasOpenPlugin = (pluginType) => {
    var existingPlugins = Object.values(this.plugins).filter(
      (plugin) => plugin.pluginType == pluginType
    );
    return existingPlugins.length > 0;
  };

  setupPlugin = (pluginType: string) => {
    var pluginConfig: IPluginConfig = OTGlobals.pluginConfigList[pluginType];

    if (!pluginConfig.multi) {
      if (this.hasOpenPlugin(pluginType)) {
        OTUserInterface.toastHandlers.show(
          `${pluginConfig.name} can only be opened once and is already open`,
          "error"
        );
        logger.info("this plugin only allows one instance");
        return;
      }
    }
    if (!pluginConfig.setupFunc) {
      this.createPlugin(pluginType);
    } else {
      pluginConfig.setupFunc(this);
    }
  };

  createPlugin = async (pluginType: string, args?: any, prevPluginId?: string) => {
    var pluginConfig: IPluginConfig = OTGlobals.pluginConfigList[pluginType];

    var pluginId = prevPluginId || this.generateId();

    this.plugins[pluginId] = {
      pluginType: pluginType,
      pluginId: pluginId,
      crDate: Date.now(),
      userId: this.userId,
      status: "A",
      args: args || {},
    };

    this.checkPluginWindow(pluginId);
    this.setFocusPlugin(pluginId);

    this.savePluginState(pluginId);

    this.setHistory(this.plugins[pluginId]);

    OTGlobals.analytics?.logEvent("plugin_add", { pluginId: pluginId, pluginType: pluginType });
    return this.plugins[pluginId].status == "A";
  };

  savePluginState = (pluginId) => {
    logger.info(`Saving plugin state for ${pluginId}`);
    PluginDb.setRoomPlugin(
      this.fbDb,
      this._teamId,
      this._roomId,
      pluginId,
      this.plugins[pluginId] || null
    );
  };

  updatePluginArgs = (pluginId: string, args: {}, sync: boolean) => {
    this.plugins[pluginId].args = { ...this.plugins[pluginId].args, ...args };
    this.plugins[pluginId].userId = this.userId;

    if (sync) {
      this.savePluginState(pluginId);
    }
  };

  onPlaying = (pluginId: string, playing: boolean = true) => {
    logger.debug(`emitting: mediaplaying ${pluginId}, playing ${playing}`);
    this.emit("mediaplaying", pluginId, playing);
  };

  closePlugin = (pluginId: string) => {
    if (this.focusedPluginId == pluginId) {
      this.setFocusPlugin(undefined);
    }

    this.emit("plugindeleted", pluginId);

    delete this.plugins[pluginId];
    delete this.poppedoutPlugins[pluginId];

    this.savePluginState(pluginId);

    OTGlobals.analytics?.logEvent("plugin_close", { pluginId: pluginId });
  };

  setFocusPlugin = (pluginId) => {
    if (this.poppedoutPlugins[pluginId] && !this.poppedoutPlugins[pluginId].window.closed) {
      OTUserInterface.platformUtils.focusWindow(
        this.poppedoutPlugins[pluginId].window,
        this.poppedoutPlugins[pluginId].ref
      );
      return;
    }

    if (!pluginId || this.focusedPluginId == pluginId) {
      this.focusedPluginId = undefined;
      this.emit("pluginfocused", undefined);
    } else {
      var pluginConfig = OTGlobals.pluginConfigList[this.plugins[pluginId].pluginType];

      if (!pluginConfig) {
        return;
      }

      if (pluginConfig.start) {
        pluginConfig.start(this.plugins[pluginId]);
      }

      if (pluginConfig.component) {
        logger.info("setting this.focusedPluginId", pluginId);
        this.emit("pluginfocused", pluginId);
        this.focusedPluginId = pluginId;
      }
    }
  };

  unPopoutPlugin = (pluginId) => {
    if (this.poppedoutPlugins[pluginId] && !this.poppedoutPlugins[pluginId].window.closed) {
      this.poppedoutPlugins[pluginId].window.close();
      delete this.poppedoutPlugins[pluginId];

      OTGlobals.analytics?.logEvent("plugin_unpop", {
        pluginId: pluginId,
        pluginType: this.plugins[pluginId]?.pluginType,
      });
    }
    this.setFocusPlugin(pluginId);
  };

  popoutPlugin = (pluginId) => {
    var pluginDetails = this.plugins[pluginId];

    if (this.focusedPluginId == pluginId) {
      this.setFocusPlugin(undefined);
    }

    if (this.poppedoutPlugins[pluginId] && !this.poppedoutPlugins[pluginId].window.closed) {
      OTUserInterface.platformUtils.focusWindow(
        this.poppedoutPlugins[pluginId].window,
        this.poppedoutPlugins[pluginId].ref
      );
    } else {
      var ref = pluginId;
      var newWin = OTUserInterface.platformUtils.openWindow(
        pluginDetails.args.url,
        ref,
        "width=800,height=600"
      );

      if (newWin) {
        this.poppedoutPlugins[pluginId] = { window: newWin, ref: ref };
      }

      OTGlobals.analytics?.logEvent("plugin_popout", {
        pluginId: pluginId,
        pluginType: this.plugins[pluginId]?.pluginType,
      });
    }
  };

  checkPluginWindow = (pluginId) => {
    var openWindows = OTUserInterface.platformUtils.getOpenWindows();

    if (pluginId in openWindows) {
      this.poppedoutPlugins[pluginId] = { window: openWindows[pluginId], ref: pluginId };
      OTGlobals.analytics?.logEvent("plugin_popout_recover", {
        pluginId: pluginId,
        pluginType: this.plugins[pluginId]?.pluginType,
      });
    }
  };

  checkPopouts = () => {
    Object.keys(this.poppedoutPlugins).forEach((pluginId) => {
      if (this.poppedoutPlugins[pluginId]?.window.closed) {
        logger.info("poppedout window closed", pluginId);
        delete this.poppedoutPlugins[pluginId];
      }
    });
  };

  getUrlHandler = (url) => {
    let matchedType;
    let matchedArgs;
    let matchedPriority = -1;

    Object.entries(OTGlobals.pluginConfigList).forEach(([pluginType, pluginConfig]) => {
      if (pluginConfig.canHandleUrl) {
        const args = pluginConfig.canHandleUrl(url);
        const priority = pluginConfig.urlPriority ?? 0;

        if (args) {
          logger.info(`${pluginType} can handle url ${url}, priority: ${priority}`);
          if (priority > matchedPriority) {
            matchedPriority = priority;
            matchedArgs = args;
            matchedType = pluginType;
          }
        }
      }
    });

    return { pluginType: matchedType, pluginArgs: matchedArgs };
  };
}

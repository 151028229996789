import { observer } from "mobx-react";
import React, { Component } from "react";
import { View, ScrollView } from "react-native";
import * as Fire from '../../globals/Fire';
import { LoadingView } from "../../components/LoadingView";
import { OTButton } from "../../components/buttons/OTButtons";
import { OTUserData } from "../../data/OTData";
import * as StyleConstants from '../../globals/StyleConstants';
import { OTText } from "../../components/OTText";
import { Theme } from "../../globals/ThemeColors";
import { OTPopover } from "../../components/OTPopover";
import { TeamList, TeamTitle } from "../../components/team";
import { Feather } from '@expo/vector-icons';
import { isFrameless } from "../../utils/platform";
import { IUserTeamReq } from "models/src/team";
import { signOut } from "../../globals/app";
import { OTGlobals, OTUITree } from "@openteam/app-core";

interface ITeamNonAccessProps {
  teamId?: string
}
interface ITeamNonAccessState {
  showTeams: boolean
}

@observer
export class TeamNonAccess extends Component<ITeamNonAccessProps, ITeamNonAccessState> {
  constructor(props) {
    super(props);
    this.state = {
      showTeams: false
    }
  }

  componentDidMount = () => {

  }

  toggleShowTeams = () => {
    this.setState({
      showTeams: !this.state.showTeams
    })
  }

  setCurrentTeam = (teamId) => {

    if (OTUITree.userManager.currentTeamId != teamId) {
      OTUITree.userManager.setCurrentTeam(teamId);
    }

    this.setState({ showTeams: false })
  }


  removeUserTeam = async (teamId) => {
    await OTUITree.userManager.removeUserTeam(teamId);
    await OTUITree.userManager.removeTeamAccessReq(teamId);

    OTUITree.userManager.unsetCurrentTeam(teamId)
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        {
          this.renderHeader()
        }
        <ScrollView key="contentScroll"  >
          {
            this.renderContent()
          }

        </ScrollView>
      </View>
    )
  }

  renderHeader = () => {
    return (
      <View style={{
        marginLeft: isFrameless ? 70 : undefined,
        paddingHorizontal: 15,
        alignItems: 'center',
        height: 60,
        backgroundColor: Theme.HeaderBackground,
        flexDirection: 'row',
      }}>
        <OTPopover
          isOpen={this.state.showTeams}
          toggleIsOpen={this.toggleShowTeams}
          renderOpen={this.renderTeams}
          icon={
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {
                this.props.teamId ?
                  <TeamTitle teamId={this.props.teamId} teamPath={OTUITree.userManager.teamAccessIndex[this.props.teamId]} showName={true} />
                  :
                  <OTText style={{ paddingLeft: 10, fontSize: 22, fontWeight: 'bold', color: Theme.MainText }}>Pick a Team</OTText>
              }
              {
                this.state.showTeams ?
                  <Feather name={"chevron-up"} size={20} style={{ paddingLeft: 5, color: Theme.MainText }} />
                  :
                  <Feather name={"chevron-down"} size={20} style={{ paddingLeft: 5, color: Theme.MainText }} />
              }
            </View>}
          badgeNum={undefined}
          outerStyle={{ marginLeft: 0 }}
        />
        {/* <OTText style={{ fontWeight: "bold", fontSize: 22, color: StyleConstants.TeamHeaderIconColour }}>{OTUserData.userDoc?.teams?.[this.props.teamId]}</OTText> */}
        <View style={{ flex: 1 }} />
        {
          OTUITree.auth.userId && !OTUITree.auth.isAnonymous ?
            <OTButton
              small={true}
              outerStyle={{ flex: undefined, }}
              title="Sign out"
              onPress={signOut}
              // backgroundColor={Theme.ButtonNegativeColor}
              backgroundColor="white"
              borderColor={Theme.ButtonNegativeColor}
              textColor={Theme.ButtonNegativeColor}
            />
            :
            null
        }

        {/* <HelpButton teamId={this.props.teamId} icon={
          <View>
        <HeadIcon imageUrl={OTUserData.userDoc?.imageUrl} name={OTUserData.userDoc?.name} size={40} borderRadius={20}/>
        </View>
        }/> */}
      </View>
    )
  }

  renderTeams = () => {
    return (
      <TeamList showName={true} setCurrentTeam={this.setCurrentTeam} showAdd={true} onAddOpen={this.toggleShowTeams} />
    )
  }

  requestTeamAccess = () => {
    const teamId = this.props.teamId!;
    const teamPath = OTUITree.userManager.teamAccessIndex[teamId];
    OTUITree.userManager.doTeamAccessReq(teamPath);
  }

  renderContent = () => {
    if (!this.props.teamId || !(this.props.teamId in OTUITree.userManager.teamAccessIndex || {})) {
      return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <OTText>
            Select a team to display
          </OTText>
        </View>
      )
    } else

      return (


        <View style={{ backgroundColor: Theme.TeamBackground, borderTopLeftRadius: StyleConstants.curviness * 2, width: '100%', minHeight: '100vh', }}>
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>

            {
              this.props.teamId in OTUITree.userManager.reqTeams ?
                <LoadingView size="large" title="Requested access to team, this page will update automatically." />
                :
                <OTText style={{ fontSize: 18, fontWeight: "bold" }}>
                  You don't have access to this team
          </OTText>
            }

            <View style={{ flexDirection: 'row', paddingTop: 30 }}>
              <OTButton title="Remove Team" onPress={() => this.removeUserTeam(this.props.teamId)} backgroundColor={StyleConstants.SecondaryColour} />
              {
                this.props.teamId in OTUITree.userManager.reqTeams ?
                  null
                  :
                  <OTButton title="Request Access" outerStyle={{ marginLeft: 20 }} onPress={this.requestTeamAccess} />
              }
            </View>
          </View>
        </View>
      )
  }


}

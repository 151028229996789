import React, { Component } from 'react';
import { View, Animated } from "react-native";
import { Theme } from '../../globals/ThemeColors';
import { Logger } from '@openteam/app-util';

import { ReactAudio } from '../ReactAudio';
import { FaceDetection, Results as FaceDetectResults }  from "@mediapipe/face_detection";


const logger = new Logger("UserVideo")

export interface IFaceDetect {
    centre: { x: number, y: number};
    zoom: number;
}


interface IUserVideoProps {
    componentId: string
    flipVideo: boolean
    muted: boolean
    videoStream?: MediaStream
    hasAudio: boolean
    hasVideo: boolean
    width: number
    height: number
    onSize?: (width, height) => void
    sinkId?: string
    objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down"
    enableFaceDetect?: boolean
    onFaceDetect?: (IFaceBox) => void
    faceDetect?: IFaceDetect
    volume?: number 
}
interface IUserVideoState {
    topLeft: Animated.ValueXY,
    size: Animated.ValueXY,
}

const FACE_DETECT_MAX_ZOOM = 2.5
const FACE_DETECT_MIN_THRESHOLD = 0.4


export class UserVideo extends Component<IUserVideoProps, IUserVideoState> {
    faceDetection?: FaceDetection;
    faceDetectInterval?: NodeJS.Timeout
    faceDetectionEnabled: boolean
    faceDetectionLastSuccess?: number

    videoRef = React.createRef<HTMLVideoElement>()
    disableTransitions: boolean = false;

    constructor(props) {
        super(props)
        this.faceDetectionEnabled = false
        this.state = {
            topLeft: new Animated.ValueXY(),
            size: new Animated.ValueXY()
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        var propsChanged = false
        Object.entries(this.props).forEach(([key, val]) =>
            propsChanged = propsChanged || nextProps[key] !== val
        );

        var stateChanged = false

        Object.entries(nextState).forEach(([key, val]) =>
            stateChanged = stateChanged || this.state[key] !== val
        );

        if (this.props.width != nextProps.width || this.props.height != nextProps.height) {
            this.disableTransitions = true
        }

        return propsChanged || stateChanged
    }

    componentDidMount = async () => {
        this.setVideo()
        this.setupVideoListener()


        if (this.props.enableFaceDetect) {
            this.setupFaceDetect()
        }
    }

    componentDidUpdate = (prevProps: IUserVideoProps) => {
        this.disableTransitions = false;

        const videoChanged = this.setVideo(prevProps)

        if (prevProps.enableFaceDetect != this.props.enableFaceDetect) {
            if (this.props.enableFaceDetect) {
                this.setupFaceDetect()
            } else {
                this.shutdownFaceDetect()
            }
        } else if (videoChanged && this.faceDetectionEnabled) {
            this._setupFacedetectionTrack()
        }
    }

    componentWillUnmount = () => {
        this.stopVideoListener()
        if (this.videoRef.current) {
            this.videoRef.current.srcObject = null
        }

        this.shutdownFaceDetect(true)
    }

    setupFaceDetect = async () => {
        try {
            // This needs to match the version of the code.
            const fdVersion = "0.4.1627346767"
            this.faceDetection = new FaceDetection({locateFile: (file, pfx) => {
                return `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection@${fdVersion}/${file}`;
            }});

            this.faceDetection.setOptions({
                model: 'short',
                minDetectionConfidence: FACE_DETECT_MIN_THRESHOLD
            });

            await this.faceDetection.initialize()

            this.faceDetectionEnabled = true;

            this._setupFacedetectionTrack();

            this.faceDetection.onResults(this.faceResults)
        } catch (err) {
            logger.error(`Error setting up face detect`, err)
        }
    }

    _setupFacedetectionTrack = () => {
        if (this.faceDetectInterval) {
            clearInterval(this.faceDetectInterval)
        }

        const track = this.props.videoStream?.getVideoTracks()[0];

        if (track) {
            this.faceDetection?.reset();

            const imageCapture = new ImageCapture(track);

            this.faceDetectInterval = setInterval(async () => {
                if (this.faceDetectionEnabled) {
                    try {
                        const image = await imageCapture.grabFrame() as any;
                        await this.faceDetection?.send({image});
                    } catch(err) {
                        logger.error(`Error processing facedetect frame: `, err);
                        this.shutdownFaceDetect();
                    };
                } else {
                    this.faceDetectInterval && clearInterval(this.faceDetectInterval)
                }
            }, 1000)
            
        } else {
            logger.info(`Unable to start facedetect, no video track found`);
        }
    }

    shutdownFaceDetect = (onUnmount: boolean = false) => {
        this.faceDetectionEnabled = false;

        if (this.faceDetectInterval) {
            clearInterval(this.faceDetectInterval)
            this.faceDetectInterval = undefined;
            this.props.onFaceDetect?.(undefined)
        }
        
        this.faceDetection?.close();
        this.faceDetection = undefined;
    }


    setVideo = (prevProps?: IUserVideoProps) => {
        if (this.videoRef.current && this.videoRef.current.srcObject !== this.props.videoStream) {
            this.videoRef.current.srcObject = this.props.videoStream || null
            if (this.props.videoStream) {
                this.onSize()
            }
            return true;
        }
        return false;
    }

    setupVideoListener = () => {
        if (this.videoRef.current) {
            this.videoRef.current.addEventListener('resize', this.onSize);

        }
    }

    faceResults = (results: FaceDetectResults): void => {
        const now = new Date().getTime();
        if (results.detections.length) {
            const box = results.detections[0].boundingBox;

            const { xCenter, yCenter, height} = box;
            const zoom = Math.max(1/FACE_DETECT_MAX_ZOOM, Math.min(1, height * 2.5));

            const faceDetect = {
                centre: {
                    x: xCenter,
                    y: yCenter
                },
                zoom,
            }
            if (this.isSignificantChange(faceDetect)) {
                this.props.onFaceDetect?.(faceDetect)
            }
            this.faceDetectionLastSuccess = now;
        } else {
            logger.debug(`No detections`)
            if (now - (this.faceDetectionLastSuccess ?? now) > 5000) {
                logger.info(`resetting face detection area`)
                this.props.onFaceDetect?.(undefined)
            }
        }
    }

    isSignificantChange = (fd: IFaceDetect) => {
        const cfb = this.props.faceDetect

        const zoomChg = Math.abs((cfb?.zoom ?? 1) - fd.zoom) / fd.zoom;
        const xChg = Math.abs((cfb?.centre.x ?? 0.5) - fd.centre.x) / fd.zoom;
        const yChg = Math.abs((cfb?.centre.y ?? 0.5) - fd.centre.y) / fd.zoom;

        if (zoomChg > 0.15 || xChg > 0.075 || yChg > 0.075) {
            logger.debug(`changed zoom:${Math.round(zoomChg*100)}%, X: ${Math.round(xChg*100)}%, Y:${Math.round(yChg*100)}%`, fd)            
            return true;
        }
        return false;
    }

    onSize = () => {
        var video = this.videoRef.current
        if (video) {
            //logger.debug("size changed ", this.props.componentId, video.videoWidth, video.videoHeight)
            this.props.onSize && this.props.onSize(video.videoWidth, video.videoHeight)
        }
    }

    stopVideoListener = () => {
        if (this.videoRef.current) {
            this.videoRef.current.removeEventListener('resize', this.onSize);
        }
    }

    render() {
        let width = this.props.width
        let height = this.props.height
        let top = 0
        let left = 0

        const faceDetect = this.props.faceDetect

        const reflect = this.props.flipVideo ? -1 : 1;
        let transform = `scale(${reflect}, 1)`;
        
        if (faceDetect && faceDetect.zoom) {
            const {zoom, centre} = faceDetect;

            const scaleX = reflect / zoom
            const scaleY = 1 / zoom;

            const maxTx = (width - (width * zoom)) / 2;
            const maxTy = (height - (height * zoom)) /2;

            let tX = (centre.x - 0.5) * width * -1;
            let tY = (centre.y - 0.5) * height * -1;

            tX = Math.max(-maxTx, Math.min(maxTx, tX));
            tY = Math.max(-maxTy, Math.min(maxTy, tY));
            
            transform = `scale(${scaleX}, ${scaleY}) translate(${tX}px, ${tY}px)`;
            //logger.debug(`transform: ${transform}`)
        }


        return (
            <div style={{display: "flex"}}>
            <div style={{
                overflow: 'hidden',
                width: this.props.width, height: this.props.height,
            }}>
                <video
                    style={{
                        position: 'absolute',
                        width: width,
                        height: height,
                        top: top,
                        left: left,
                        transition: this.disableTransitions ? '' : "all 1500ms ease-out",
                        transform: transform,
                        display: this.props.hasVideo ? "block" : "none",
                        overflow: "hidden",
                        objectFit: this.props.objectFit ?? "cover",
                    }}
                    autoPlay
                    playsInline
                    muted={true}
                    key={this.props.componentId}
                    id={this.props.componentId}
                    ref={this.videoRef}
                />
                <ReactAudio
                    stream={this.props.hasAudio && this.props.videoStream || undefined}
                    sinkId={this.props.sinkId}
                    muted={this.props.muted}
                    volume={this.props.volume}
                />
            </div>
            </div>
        )
    }
}

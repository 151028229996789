import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import firestore from "@react-native-firebase/firestore";

import {
  IChannelUser,
  IChannels,
  IChannel,
  IMessage,
  IMessageFile,
  IOTChatMessage,
  TChatType,
  ITopic,
} from "@openteam/models";
import { Logger } from "@openteam/app-util";
import { runInAction } from "mobx";
import { addWatch, removeWatch } from "./FireUtils";

const logger = new Logger("ChatDb");



function firestoreTimestampToDate(val: any): Date | null  {
  if (val && (val instanceof firebase.firestore.Timestamp || val instanceof firestore.Timestamp)) {
    return val.toDate();
  } else if (val instanceof Date) {
    return val;
  }
  return null;
}

function convertFBToIChannel(doc): IChannel {
  doc.crDate = firestoreTimestampToDate(doc.crDate);
  doc.lastUpdate = firestoreTimestampToDate(doc.lastUpdate);

  for (const topicId in doc.topics || {}) {
    if (doc.topics[topicId]?.crDate) {
      doc.topics[topicId].crDate = firestoreTimestampToDate(doc.topics[topicId].crDate);
    }

    if (doc.topics[topicId]?.lastUpdate) {
      doc.topics[topicId].lastUpdate = firestoreTimestampToDate(doc.topics[topicId].lastUpdate);
    }

    if (doc.topics[topicId]?.lastMessage) {
      doc.topics[topicId].lastMessage = convertFBToIMessage(doc.topics[topicId].lastMessage);
    }
  }
  return doc;
}

function convertFBToIChannelUser(doc): IChannelUser {
  doc.crDate = firestoreTimestampToDate(doc.crDate);

  for (const topicId in doc.topics || {}) {
    if (doc.topics[topicId]?.lastTyping) {
      doc.topics[topicId].lastTyping = firestoreTimestampToDate(doc.topics[topicId].lastTyping);
    }
  }

  return doc;
}

function convertFBToIMessage(doc): IMessage {
  doc.crDate = firestoreTimestampToDate(doc.crDate);
  doc.editDate = firestoreTimestampToDate(doc.editDate);
  if (doc.replyMessage) {
    doc.replyMessage = convertFBToIMessage(doc.replyMessage);
  }
  return doc;
}

function serverTimestamp(fsdb: any) {
  if (fsdb instanceof firebase.firestore.Firestore) {
    return firebase.firestore.FieldValue.serverTimestamp();
  } else {
    return firestore.FieldValue.serverTimestamp();
  }
}

export class ChatDb {
  static watchUserChannelList = (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    callback: (added: IChannelUser[], edited: IChannelUser[], deleted: string[]) => void
  ) => {
    fsDb
      .collection("config")
      .doc("config")
      .get()
      .then((moo) => logger.debug(" firedbconfig", moo.data()));

    const channelusers = fsDb
      .collectionGroup("channelusers")
      .where("teamId", "==", teamId)
      .where("userId", "==", userId);

    const unsubscribe = channelusers.onSnapshot((snapshot) => {
      const added: IChannelUser[] = [];
      const edited: IChannelUser[] = [];
      const deleted: string[] = [];

      // logger.debug("watchUserChannelList", snapshot, snapshot?.docChanges());

      snapshot?.docChanges().forEach((change) => {
        let doc = convertFBToIChannelUser(change.doc.data() as IChannelUser);

        if (change.type === "added") {
          added.push(doc);
        }
        if (change.type === "modified") {
          edited.push(doc);
        }
        if (change.type === "removed") {
          deleted.push(doc.channelId);
        }
      });
      console.log(
        `watchUserChannelList teamId: ${teamId} userId: ${userId} added`,
        added,
        `edited`,
        edited,
        `deleted`,
        deleted
      );
      callback(added, edited, deleted);
    });

    return unsubscribe;
  };

  static watchChannels = (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    callback: (added: IChannel[], edited: IChannel[], deleted: string[]) => void
  ) => {
    const unsubscribe = fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .where("userIds", "array-contains", userId)
      .onSnapshot(
        (snapshot) => {
          const added: IChannel[] = [];
          const edited: IChannel[] = [];
          const deleted: string[] = [];

          // logger.debug("watchChannels", snapshot, snapshot?.docChanges());

          snapshot?.docChanges().forEach((change) => {
            if (change.type === "added") {
              var doc = convertFBToIChannel(change.doc.data() as IChannel);

              added.push(doc);
            }
            if (change.type === "modified") {
              var doc = convertFBToIChannel(change.doc.data() as IChannel);

              added.push(doc);
            }
            if (change.type === "removed") {
              deleted.push(change.doc.id);
            }
          });
          logger.debug(
            `watchChannels teamId: ${teamId} userId: ${userId} added`,
            added,
            `edited`,
            edited,
            `deleted`,
            deleted
          );
          callback(added, edited, deleted);
        },
        (error) => logger.error("watchChannels", error)
      );

    return unsubscribe;
  };

  static getChannel = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    channelId: string
  ): Promise<IChannel> => {
    const snapshot = await fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .get();

    if (snapshot) {
      const doc = convertFBToIChannel(snapshot.data() as IChannel);
      return doc;
    } else {
      throw Error("Channel not found");
    }
  };

  static watchChannel = (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    channelId: string,
    callback: (doc: IChannel) => void
  ) => {
    const unsubscribe = fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .onSnapshot(
        (snapshot) => {
          // logger.debug("watchChannel", snapshot);

          if (snapshot) {
            const data = convertFBToIChannel(snapshot.data() as IChannel);
            data && callback(data);
          }
        },
        (error) => logger.error("watchChannel", error)
      );

    return unsubscribe;
  };

  static watchChannelUsers = (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    channelId: string,
    callback: (added: IChannelUser[], edited: IChannelUser[], deleted: string[]) => void
  ) => {
    const unsubscribe = fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .collection("channelusers")
      .onSnapshot(
        (snapshot) => {
          const added: IChannelUser[] = [];
          const edited: IChannelUser[] = [];
          const deleted: string[] = [];

          // logger.debug("watchChannelUsers", snapshot, snapshot?.docChanges());

          snapshot?.docChanges().forEach((change) => {
            if (change.type === "added") {
              const doc = convertFBToIChannelUser(change.doc.data() as IChannelUser);

              added.push(doc);
            }
            if (change.type === "modified") {
              const doc = convertFBToIChannelUser(change.doc.data() as IChannelUser);

              added.push(doc);
            }
            if (change.type === "removed") {
              deleted.push(change.doc.id);
            }
          });
          logger.debug(
            `watchChannelUsers teamId: ${teamId} channelId: ${channelId} added`,
            added,
            `edited`,
            edited,
            `deleted`,
            deleted
          );
          callback(added, edited, deleted);
        },
        (error) => logger.error("watchChannelUsers", error)
      );

    return unsubscribe;
  };

  static getChannels = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string
  ): Promise<IChannels> => {
    const snapshot = await fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .where("chatType", "==", "channel")
      .get();
    const channels = {};
    snapshot.forEach((doc) => {
      channels[doc.id] = convertFBToIChannel(doc.data() as IChannel);
    });
    return channels;
  };

  static joinChannel = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    channelId: string
  ) => {
    const channelRef = fsDb.collection("chats").doc(teamId).collection("channels").doc(channelId);
    const channelUserRef = channelRef.collection("channelusers").doc(userId);

    const user: IChannelUser = {
      teamId: teamId,
      channelId: channelId,
      userId: userId,
      crDate: serverTimestamp(fsDb) as any,
      bookmarked: true,
      id: userId,
      topics: {
        default: {
          messageNum: 0,
          messageId: 0,
        },
      },
    };
    logger.info("joinChannel", channelId);
    await fsDb.runTransaction((transaction) => {
      return transaction.get(channelRef).then((channelSnap) => {
        if (channelSnap && !channelSnap.exists) {
          throw "Document does not exist!";
        }

        const channelDoc = channelSnap.data()! as IChannel;

        user.topics.default.messageNum = channelDoc.topics.default.messageNum || 0;
        user.topics.default.messageId = channelDoc.topics.default.messageId || 0;

        transaction.set(channelUserRef, user);

        transaction.update(channelRef, {
          userIds: firebase.firestore.FieldValue.arrayUnion(userId),
        });
      });
    });
  };

  static leaveChannel = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    channelId: string
  ) => {
    const channelRef = fsDb.collection("chats").doc(teamId).collection("channels").doc(channelId);
    const channelUserRef = channelRef.collection("channelusers").doc(userId);

    logger.info("leaveChannel", channelId);
    await fsDb.runTransaction((transaction) => {
      return transaction.get(channelRef).then((channelDoc) => {
        if (channelDoc && !channelDoc.exists) {
          throw "Document does not exist!";
        }

        let userIds: string[] = (channelDoc.data()?.userIds || []).filter(
          (channelUserId) => channelUserId != userId
        );

        transaction.update(channelRef, {
          userIds: firebase.firestore.FieldValue.arrayRemove(userId),
        });
        transaction.delete(channelUserRef);
      });
    });
  };

  static bookmarkChat = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    bookmarked: boolean
  ) => {
    logger.info("bookmarkChat", channelId, bookmarked);

    await fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .collection("channelusers")
      .doc(userId)
      .update({
        bookmarked: bookmarked || null,
      });
  };

  static bookmarkChatTopic = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    bookmarked: boolean
  ) => {
    logger.info("bookmarkChat", channelId, bookmarked);

    await fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .collection("channelusers")
      .doc(userId)
      .update({
        ["topics." + topicId + ".bookmarked"]: bookmarked || null,
      });
  };

  static markChatRead = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    messageNum: number,
    messageId: number
  ) => {
    logger.info("marking as read", userId, channelId, messageNum, messageId);

    await fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .collection("channelusers")
      .doc(userId)
      .update({
        ["topics." + topicId + ".messageNum"]: messageNum,
        ["topics." + topicId + ".messageId"]: messageId,
      });
  };

  static addChannel = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    userIds: string[],
    name?: string,
    desc?: string,
    chatType: TChatType = "channel",
    teamDefault: boolean = false
  ) => {
    const docRef = fsDb.collection("chats").doc(teamId).collection("channels").doc();
    const channelId = docRef.id;

    userIds.push(userId);

    const msg: IChannel = {
      id: channelId,
      teamId: teamId,
      userIds: userIds,
      createdBy: userId,
      chatType: chatType,
      teamDefault: teamDefault,
      crDate: serverTimestamp(fsDb) as any,
      lastUpdate: serverTimestamp(fsDb) as any,
      topics: {
        default: {
          crDate: serverTimestamp(fsDb) as any,
          lastUpdate: serverTimestamp(fsDb) as any,
          createdBy: userId,
          messageNum: 0,
          messageId: 0,
          name: "default",
        },
      },
    };

    if (name) {
      msg.name = name;
    }

    if (desc) {
      msg.desc = desc;
    }

    const batch = fsDb.batch();
    batch.set(docRef, msg);

    const channelUsersRef = fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .collection("channelusers");

    for (let uid of userIds) {
      let user: IChannelUser = {
        id: uid,
        channelId: channelId,
        teamId: teamId,
        userId: uid,
        crDate: serverTimestamp(fsDb) as any,
        topics: {
          default: {
            messageNum: 0,
            messageId: 0,
          },
        },
      };

      if (userId == uid) {
        user.bookmarked = true;
      }
      batch.set(channelUsersRef.doc(uid), user);
    }

    await batch.commit();

    return channelId;
  };

  static updateChannel = async (
    fsDb: firebase.firestore.Firestore,
    channelId: string,
    teamId: string,
    addUserIds: string[],
    name: string,
    desc?: string
  ) => {
    const channelRef = fsDb.collection("chats").doc(teamId).collection("channels").doc(channelId);
    const channelUsersRef = channelRef.collection("channelusers");
    await fsDb.runTransaction((transaction) => {
      return transaction.get(channelRef).then((channelDoc) => {
        if (channelDoc && !channelDoc.exists) {
          throw "Document does not exist!";
        }

        for (let uid of addUserIds) {
          let user: IChannelUser = {
            id: uid,
            channelId: channelId,
            teamId: teamId,
            userId: uid,
            crDate: serverTimestamp(fsDb) as any,
            topics: {
              default: {
                messageNum: 0,
                messageId: 0,
              },
            },
          };

          transaction.set(channelUsersRef.doc(uid), user);

          transaction.update(channelRef, {
            userIds: firebase.firestore.FieldValue.arrayUnion(...addUserIds),
            name: name,
            desc: desc,
            lastUpdate: serverTimestamp(fsDb) as any,
          });
        }
      });
    });
  };

  static removeChannelUser = async (
    fsDb: firebase.firestore.Firestore,
    channelId: string,
    teamId: string,
    userId: string
  ) => {
    ChatDb.leaveChannel(fsDb, teamId, userId, channelId);
  };

  static addDirectChannel = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    userIds: string[]
  ) => {
    const channelId = await ChatDb.addChannel(
      fsDb,
      teamId,
      userId,
      userIds,
      undefined,
      undefined,
      "chat",
      false
    );

    return channelId;
  };

  static createTopic = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    name: string
  ) => {
    const channelRef = fsDb.collection("chats").doc(teamId).collection("channels").doc(channelId);
    const topicRef = channelRef.collection("topics").doc();
    const topicId = topicRef.id;

    const topicDoc: ITopic = {
      createdBy: userId,
      crDate: serverTimestamp(fsDb) as any,
      messageId: 0,
      messageNum: 0,
      lastUpdate: serverTimestamp(fsDb) as any,
      name: name,
    };

    await fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .update({
        ["topics." + topicId]: topicDoc,
      });
    return topicId;
  };

  static editTopic = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    name: string
  ) => {
    await fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .update({
        ["topics." + topicId + ".name"]: name,
        ["topics." + topicId + ".lastUpdate"]: serverTimestamp(fsDb) as any,
      });
    return;
  };

  static archiveTopic = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    archived: boolean
  ) => {
    await fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .update({
        ["topics." + topicId + ".archived"]: archived,
      });
    return;
  };

  static setIsTyping = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    isTyping: boolean
  ) => {
    const now = new Date();
    const lastTyping = isTyping ? now : null;

    await fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .collection("channelusers")
      .doc(userId)
      .update({
        ["topics." + topicId + ".lastTyping"]: lastTyping,
      });
  };

  static addChatMessage = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    message: string,
    files?: IMessageFile[],
    replyMessage?: IOTChatMessage,
  ) => {
    if (files) {
      await Promise.all(files.map((cu) => cu.complete()));
    }

    const channelRef = fsDb.collection("chats").doc(teamId).collection("channels").doc(channelId);
    const userChannelRef = channelRef.collection("channelusers").doc(userId);

    const messageRef = channelRef.collection("topics").doc(topicId).collection("messages").doc();

    const id = messageRef.id;

    const msg: IMessage = {
      channelId: channelId,
      topicId: topicId,
      id: id,
      teamId: teamId,
      crDate: serverTimestamp(fsDb) as any,
      isSystem: false,
      userId: userId, //sender
      message: message || "",
      messageNum: 0,
      messageId: 0,
      replyMessage: replyMessage || null,
      files: files
        ? files.map((cu) => ({
            name: cu.file.name,
            type: cu.file.type,
            size: cu.file.size,
            url: cu.downloadUrl!,
          }))
        : null,
    };

    const messageNum = await fsDb.runTransaction((transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(channelRef).then((channelSnap) => {
        if (!channelSnap.exists) {
          throw "Document does not exist!";
        }

        const channelDoc = channelSnap.data() as IChannel;

        let messageId = (channelDoc.topics?.[topicId]?.messageId ?? 0) + 1;
        let messageNum = (channelDoc.topics?.[topicId]?.messageNum ?? 0) + 1;
        msg.messageNum = messageNum;
        msg.messageId = messageId;

        transaction.update(userChannelRef, {
          ["topics." + topicId + ".messageNum"]: messageNum,
          ["topics." + topicId + ".messageId"]: messageId,
        });

        transaction.update(channelRef, {
          ["topics." + topicId + ".lastMessage"]: msg,
          ["topics." + topicId + ".messageNum"]: messageNum,
          ["topics." + topicId + ".messageId"]: messageId,
        });

        transaction.set(messageRef, msg);

        ChatDb.setIsTyping(fsDb, teamId, userId, channelId, topicId, false);

        return messageNum;
      });
    });

    return messageNum;
  };

  static editChatMessage = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    messageId: string,
    message: string
  ) => {
    const msg = {
      message: message || "",
      editDate: serverTimestamp(fsDb) as any,
    };

    const docRef = fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .collection("topics")
      .doc(topicId)
      .collection("messages")
      .doc(messageId);
    docRef.update(msg);
  };

  static deleteChatMessage = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    messageId: string
  ) => {
    const docRef = fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .collection("topics")
      .doc(topicId)
      .collection("messages")
      .doc(messageId);
    docRef.delete();
  };

  static muteChatNotify = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    userId: string,
    channelId: string,
    topicId: string,
    muted?: boolean
  ) => {
    logger.info("muteChatNotify", channelId, muted);

    await fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .collection("channelusers")
      .doc(userId)
      .update({
        ["topics." + topicId + ".muteNotify"]: muted || null,
      });
  };

  static watchChannelMessages = (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    messageId: number,
    callback: (added: IMessage[], edited: IMessage[], deleted: string[]) => void
  ) => {
    const docRef = fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .collection("topics")
      .doc(topicId)
      .collection("messages");

    const unsubscribe = docRef
      .where("messageId", ">=", messageId)
      .orderBy("messageId", "asc")
      .onSnapshot(
        (snapshot) => {
          const added: IMessage[] = [];
          const edited: IMessage[] = [];
          const deleted: string[] = [];

          // logger.debug("watchChannelMessages", snapshot, snapshot?.docChanges());

          snapshot?.docChanges().forEach((change) => {
            if (change.type === "added") {
              added.push(convertFBToIMessage(change.doc.data() as IMessage));
            }
            if (change.type === "modified") {
              added.push(convertFBToIMessage(change.doc.data() as IMessage));
            }
            if (change.type === "removed") {
              deleted.push(change.doc.id);
            }
          });
          logger.debug(
            `watchChannelMessages teamId: ${teamId} channelId: ${channelId} added`,
            added,
            `edited`,
            edited,
            `deleted`,
            deleted
          );
          callback(added, edited, deleted);
        },
        (error) => logger.error("watchChannelMessages", error)
      );
    return unsubscribe;
  };

  static getChatMessage = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    messageId: string
  ): Promise<IMessage> => {
    const docRef = fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .collection("topics")
      .doc(topicId)
      .collection("messages")
      .doc(messageId);
    const doc = await docRef.get();
    const data = convertFBToIMessage(doc.data() as IMessage);
    logger.info("getChatMessage ", channelId, data);

    return data;
  };

  static getChatMessagesSince = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    sinceMessageId: number
  ) => {
    const docRef = fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .collection("topics")
      .doc(topicId)
      .collection("messages");

    const snapshot = await docRef.orderBy("messageId", "asc").startAfter(sinceMessageId).get();

    const messages = {};
    snapshot.forEach((doc) => {
      messages[doc.id] = convertFBToIMessage(doc.data() as IMessage);
    });

    logger.info("getChatMessagesSince ", channelId, sinceMessageId, messages);

    return messages;
  };

  static getMoreChatMessages = async (
    fsDb: firebase.firestore.Firestore,
    teamId: string,
    channelId: string,
    topicId: string,
    lastMessageId: number,
    pageSize?: number
  ) => {
    const docRef = fsDb
      .collection("chats")
      .doc(teamId)
      .collection("channels")
      .doc(channelId)
      .collection("topics")
      .doc(topicId)
      .collection("messages");

    let queryRef = docRef.orderBy("messageId", "desc").startAt(lastMessageId);

    if (pageSize) {
      queryRef = queryRef.limit(pageSize);
    }
    const snapshot = await queryRef.get();

    const messages = {};
    snapshot.docs.reverse().forEach((doc) => {
      messages[doc.id] = convertFBToIMessage(doc.data() as IMessage);
    });

    logger.info("getMoreChatMessages ", channelId, lastMessageId, messages);

    return messages;
  };
}

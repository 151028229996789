
import React, { useCallback, useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Platform,
  ActivityIndicator,
  FlatList,
  ListRenderItemInfo,
  Image,
} from "react-native";
import { debounce } from 'throttle-debounce';
import { GiphyFetch } from '@giphy/js-fetch-api'
import { OTTouchableOpacity } from "./buttons";
import { Feather } from "@expo/vector-icons";
import { IGif } from '@giphy/js-types';
import ScalableImage from 'react-native-scalable-image';
import { Theme } from "../globals/ThemeColors";
const gf = new GiphyFetch('dGsWybul6wfMJflcssZYGvviVPFINYDR')



interface IGifCellProps {
  gif: IGif;
  onPress: () => void;
}


const GifCell: React.FC<IGifCellProps> = ({ gif, onPress }) => (
  <TouchableOpacity
    activeOpacity={ 0.5 }
    onPress={onPress}
    style={{marginHorizontal: 5, marginBottom: 5}}
  >
    <Image
      key={`${gif.id}`}
      source={{ uri: gif.images.fixed_height_small.url }}
      style={{
        borderRadius: Theme.curviness,
        marginVertical: 5,
        width: parseInt(gif.images.fixed_height_small.width as any),
        height: parseInt(gif.images.fixed_height_small.height as any)
      }}
    />
  </TouchableOpacity>
);

interface IGifPickerProps {
  onGifSelected: (title: string, url: string, size?: string) => void;
}

export const GifPicker: React.FC<IGifPickerProps> = ({ onGifSelected }) => {
  const [trending, setTrending] = useState<IGif[]>([])
  const [searchResults, setSearchResults] = useState<IGif[] | undefined>(undefined)
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    async function fetchTrending() {
      const { data: gifs } = await gf.trending({ limit: 24,  })
      setTrending(gifs)
      console.log("trending gifs: ", gifs)

    }
    fetchTrending();
  }, []);

  const doSearch = useCallback(debounce(500, (text) => {
    gf.search(text, {
      sort: 'relevant', lang: 'en', limit: 24, type: 'gifs'
    }).then(({ data: gifs }) => {

      setSearchResults(gifs)
    })

  }), [])

  const onSearchChanged = (text) => {

    setSearchQuery(text)

    if (text) {
      doSearch(text)

    } else {
      setSearchResults(undefined)
    }

  }

  const Searchbar = (
    <View
      style={{
        margin: 10,
        paddingHorizontal: 10,
        backgroundColor: "#eee",
        borderRadius: 10,
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <TextInput
        style={{
          paddingVertical: 10,
          flex: 1,
        }}
        placeholder={"Search GIFs"}
        clearButtonMode="always"
        returnKeyType="done"
        autoCorrect={false}
        value={searchQuery}
        onChangeText={onSearchChanged}
      />
      {searchQuery ? (
        <OTTouchableOpacity onPress={() => onSearchChanged("")}>
          <Feather name="x" size={18} style={{ color: "#555" }} />
        </OTTouchableOpacity>
      ) : null}

      <Image
        source={{ uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fgiphypower.png?alt=media&token=8d9ef4ea-4db2-4ef8-957e-d4eafc2abfcd" }}
        style={{ width: 75, height: 27, borderRadius: 5, marginLeft: 5 }} />

    </View>
  );

  const renderGifCell = (item: IGif) => (
    <GifCell
      key={item.id}
      gif={item}
      onPress={() => {
        onGifSelected(item.title, item.images.fixed_height.url, item.images.fixed_height.size);
      }}
    />
  );

  return (
    <View
      style={{
        height: 200
      }}
    >
      {Searchbar}
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ paddingHorizontal: 10, flexDirection: "row", flexWrap: "wrap" }}
        keyboardShouldPersistTaps={"always"}
      >
        {(searchResults || trending).map((item, index) => renderGifCell(item))}
      </ScrollView>
    </View>
  );
};
import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Theme } from "../../globals/ThemeColors";

interface PopoutWindowProps {
    frameName: string
    title?: string
    width: number
    height: number
    onSize?: (width: number, height: number) => void
    onClose?: () => void
}

interface PopoutWindowState {
    width: number
    height: number
}

export default class PopoutWindow extends Component<PopoutWindowProps, PopoutWindowState> {
    windowObject: Window | null;
    windowObserver

    constructor(props) {
        super(props)
        const { width, height } = props
        this.state = {
            width: width,
            height: height
        }
        let options = `width=${this.state.width}, height=${this.state.height}`;
        if (this.props.title) {
            options = options + `, title=${props.title}`
        }

        this.windowObject = window.open('', props.frameName, options);
        if (this.windowObject && this.props.onClose) {
            this.windowObject.onclose = this.props.onClose;
        }
    }

    componentDidMount() {
        this.windowObserver = setInterval(
            () => {
                if (this.windowObject) {
                    if (this.windowObject.closed) {
                        if (this.props.onClose) {
                            this.props.onClose()
                        }
                    }
                    if (this.windowObject.innerWidth != this.state.width ||
                        this.windowObject.innerHeight != this.state.height) {
                        this.setState({
                            width: this.windowObject.innerWidth,
                            height: this.windowObject.innerHeight,
                        })
                        if (this.props.onSize) {
                            this.props.onSize(
                                this.windowObject.innerWidth,
                                this.windowObject.innerHeight
                            )
                        }
                    }
                }
            }, 500
        )

    }
    componentWillUnmount() {
        clearInterval(this.windowObserver)
        this.closeWindow()
    }

    closeWindow = () => {
        if (this.windowObject && !this.windowObject.closed) {
            this.windowObject.close()
        }
    }

    render() {
        return this.windowObject ? ReactDOM.createPortal(
            this.props.children,
            this.windowObject.document.body
        ) : null;
    }
}

export function getStyleElement() {
    return <style dangerouslySetInnerHTML={
        {
            __html: `
              @import url("https://use.typekit.net/yes5dwt.css");
              html {
                font-family: 'Lato', sans-serif;
                font-size: 14px;
              }
              body {
                  margin: 0;
                  padding: 0;
                  overflow: hidden;
                  background: ${Theme.OfficeBackground};
              }
              ::-webkit-scrollbar {
                width: 0.62em;
                background: ${Theme.ScrollbarTrackColour};
              }
              ::-webkit-scrollbar-thumb {
                background: ${Theme.ScrollbarColour};
                border-radius: 10px
              }
              ::-webkit-scrollbar-track-piece
              {
                 display:none;
              }
              svg {
                outline: none;
              }
            `
        }} />
}

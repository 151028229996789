import { observable, reaction } from "mobx"
import { Logger } from '@openteam/app-util';;
import { Appearance } from 'react-native-appearance';
import { StatusBar } from "react-native";
import { OTGlobals } from "@openteam/app-core";

const logger = new Logger("Theme")
const white = '#FFF';
const bg1 = '#F6F8FA';
const bg2 = '#EDF2F7';
const bg3 = '#D5E4F6';
const bg4 = "#F0F4F9";

const primary = '#4665F6'; // Bright blue
const fg1 = '#3C3C3C';
const fg2 = '#C4C4C4';

const negativeColor = '#EC6E7A';
const recordingColor = '#FF6666';
const warningColor = '#F6CC50';

export const themeDefault = {
  curviness: 10,
  IconSource: {
    uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fopenteamlogorow.png?alt=media"
  },
  LogoSource: {
    uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fopenteamlogo.png?alt=media"
  },

  AppBackground: white,
  HeaderBackground: white,
  MainColour: primary,
  MainContrastColour: white,
  PrimaryColour: primary,
  OnPrimaryColor: white,
  ScrollbarTrackColour: 'transparent', // bg2,
  ScrollbarColour: 'rgba(0,0,0,0.6)',
  MenuTeamOutlineColour: primary,

  TeamBackground: bg1,

  OfficeBackground: bg2,
  DialogBackground: white,
  RoomBackground: white,
  VideoCallBackground: bg3,
  FullVideoCallBackground: "#202227",
  BorderColour: '#E9E9E9',
  FullVideoHeaderBackground: white,

  ChatBackground: bg2,
  ChatHeader: white,
  ChatBody: bg1,
  ChatCompose: white,

  PluginBackground: "#CECFD0",

  HeaderIcon: "#7B7B7B",
  HeaderIconSize: 19,

  IconBackground: "#EEF2F6",

  InputHighlightColor: primary,
  InputBackgroundColor: white,
  ButtonColor: primary,
  ButtonIconColor: white,
  ButtonHighlightColor: white,

  ButtonDisabledColor: "#757575",

  ButtonPositiveColor: "#6BCE7C",
  ButtonPositiveIconColor: white,
  ButtonNegativeColor: "#FF564F",
  ButtonNegativeIconColor: white,
  ButtonWarnColor: "#F4B000",
  ButtonWarnIconColor: white,

  ButtonCapturingColor: "#FF564F",
  RecordingColor: recordingColor,
  SharingColor: recordingColor,
  WarningColor: warningColor,
  ErrorColor: '#FF564F',
  VolumeBarColour: '#FED217',

  MenuBackground: bg1,
  ControlBackground: "#F0F4F9",
  ControlSelected: "white",
  RowBackground: "#EBF1F7",
  RowHover: "#F0F4F9",
  DarkText: fg1,
  OffDarkText: fg2,

  MainText: fg1,
  SecondaryText: fg2,
  TertiaryText: primary,

  SeparatorColor: "rgba(233, 239, 246, 0.7)",

  OnlineColor: "#6BCE7C",
  OfflineColor: "#FF564F",
  ConnectingColour: "#FF9B19",

  BadgeColor: "#FF4F57",
  BadgeSoftAlertColor: "#F1BF42",

  ProgressBarFilled: primary,
  ProgressBarEmpty: "#BBB9CC",

  LinkColor: primary,

  BugColour: '#F4AE96',
  FeatureColour: '#9DC9F5',

  TransitionProperty: undefined,
  TransitionDuration: undefined,

  FeedbackAnnouncement: '#F1BF42',
  FeedbackGeneral: '#5383EC',
  FeedbackBug: '#D85140',
  FeedbackFeature: '#58A65C',

  VideoButtonBG: "rgba(237, 242, 247, 1)",
  VideoButtonFG: primary,

  SignalGood: '#84CB84',
  SignalBG: "rgba(237, 242, 247, 1)",
  SignalPoor: "rgba(244, 174, 81, 1)",
  SignalBad: "rgba(255, 86, 79, 1)",
  Signal3Bar: "rgba(244, 174, 81, 1)",
  Signal2Bar: "rgba(255, 86, 79, 1)",
  Signal1Bar: "rgba(255, 86, 79, 1)",


  LightShadow: {
    shadowColor: 'black',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowOffset: {
      height: 0, width: 0
    },
  }
}

const MAIN_DARK1 = '#1A2037'; // selection
const MAIN_DARK2 = '#1F2743'; // side bar
const MAIN_DARK3 = '#2C3860'; // bg blue
const MAIN_DARK4 = '#314179'; // box color

const TEXT1 = '#d4d4d4';
const TEXT2 = '#b4b4b4';

const themeDark = {
  ...themeDefault,
  IconSource: {
    uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fdark%2Fopenteamlogorow.png?alt=media"
  },
  LogoSource: {
    uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fopenteamlogolight.png?alt=media"
  },
  HeaderBackground: MAIN_DARK1,

  TeamBackground: MAIN_DARK1,
  OfficeBackground: MAIN_DARK2,
  DialogBackground: MAIN_DARK3,
  VideoCallBackground: MAIN_DARK4,
  FullVideoCallBackground: MAIN_DARK1,
  RoomBackground: MAIN_DARK1,
  RowBackground: MAIN_DARK1,
  RowHover: MAIN_DARK1,

  ScrollbarTrackColour: 'transparent', //MAIN_DARK2,
  ScrollbarColour: MAIN_DARK4,

  FullVideoHeaderBackground: MAIN_DARK1,

  HeaderIcon: TEXT1,

  ChatBackground: MAIN_DARK2,
  ChatHeader: white,
  ChatBody: MAIN_DARK1,
  ChatCompose: MAIN_DARK3,

  InputBackgroundColor: MAIN_DARK3,
  ButtonIconColor: TEXT1,
  ButtonHighlightColor: MAIN_DARK1,
  IconBackground: 'transparent',

  ButtonCapturingColor: "#FF564F",

  MenuBackground: MAIN_DARK1,
  ControlBackground: MAIN_DARK2,
  ControlSelected: MAIN_DARK4,
  DarkText: TEXT1,
  OffDarkText: TEXT2,

  MainText: TEXT1,
  SecondaryText: TEXT2,
  TertiaryText: TEXT2,

  ProgressBarEmpty: "#BBB9CC",

  LightShadow: {
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowRadius: 20,
    shadowOffset: {
      height: 0, width: 0
    },
  }
}

export function setDarkMode (darkMode) {
  setTheme(darkMode ? themeDark : themeDefault);
  StatusBar.setBarStyle(darkMode ? 'light-content': 'dark-content')

  SystemTheme.isDarkMode = darkMode
}


function setTheme(theme) {
  Object.keys(theme).forEach(key => {
    Theme[key] = theme[key]
  })
}

export var Theme: any = observable({});
export var SystemTheme: any = observable({});

SystemTheme.colorScheme = Appearance.getColorScheme()

let subscription = Appearance.addChangeListener(({ colorScheme }) => {
  SystemTheme.colorScheme = colorScheme
  logger.debug("colorScheme changed", colorScheme)
});

const setThemeReaction = reaction(
  () => {
    const userSettings = OTGlobals.remoteUserSettings;

    logger.debug("setThemeReaction returning, ", userSettings?.darkMode ?? false, "system is", SystemTheme.colorScheme)
    return userSettings?.darkMode ?? SystemTheme.colorScheme == 'dark'
  },
  (darkMode) => {
    setDarkMode(darkMode)
    logger.info(`Theme darkMode ${darkMode} MainText: ${Theme.MainText}`)
  }
)

setDarkMode(OTGlobals.remoteUserSettings?.darkMode ?? SystemTheme.colorScheme == 'dark')

export const BackgroundSource = { uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/webassets%2Fopenteambackground.jpg?alt=media" }
export const LogoSource = { uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fopenteamlogo.png?alt=media" }
export const IconSource = {
  uri: "https://firebasestorage.googleapis.com/v0/b/openteam-12bd3.appspot.com/o/appassets%2Fopenteamlogorow.png?alt=media"
}

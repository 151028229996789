import { observer } from "mobx-react";
import React, { Component } from "react";
import {  View } from "react-native";

import { OTTouchableOpacity } from "../../components";
import { OTText } from "../OTText";
import { Theme } from "../../globals/ThemeColors";
import { OTGlobals, PluginManager } from "@openteam/app-core";

interface IPluginListItemProps {
  pluginType: string;
  pluginManager: PluginManager;
  onClose: () => void;
}
interface IPluginListItemState {}

@observer
export class PluginListItem extends Component<IPluginListItemProps, IPluginListItemState> {
  render() {
    const pluginConfigList = OTGlobals.pluginConfigList;
    var pluginConfig = pluginConfigList[this.props.pluginType];
    return (
      <View
        style={{
          marginBottom: 6,
          marginHorizontal: 3,
          width: 90,
          height: 80,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: Theme.curviness,
          backgroundColor: Theme.RowBackground,
        }}
      >
        <OTTouchableOpacity
          onPress={this.setupPlugin}
          style={{
            // flexDirection: 'row',
            alignItems: "center",
            paddingVertical: 5,
            paddingHorizontal: 15,
            // backgroundColor: StyleConstants.BackgroundGrey,
            // borderRadius: Theme.curviness,
          }}
        >
          <pluginConfig.icon size={26} style={{ color: pluginConfig.iconColour }} />
          <OTText style={{ marginTop: 5, fontWeight: "bold", flex: 1, textAlign: "center" }}>
            {pluginConfig.name}
          </OTText>
        </OTTouchableOpacity>
      </View>
    );
  }

  setupPlugin = () => {
    this.props.pluginManager.setupPlugin(this.props.pluginType);
    this.props.onClose();
  };
}

import { observer } from "mobx-react";
import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { OTText } from "./OTText";

interface ISettingsRowProps {
    title: string
    width?: number
}

interface ISettingsRowState {
}

@observer
export default class SettingsRow extends Component<ISettingsRowProps, ISettingsRowState> {
    render() {
        return (
            <View style={styles.settingsSection}>
                <OTText style={styles.title}>{this.props.title}</OTText>
                <View style={{ width: this.props.width || 300 }}>
                    {this.props.children}
                </View>
            </View>
        )
    }
}
const styles = StyleSheet.create({
    title: {
        fontSize: 14,
        fontWeight: "600",
        marginBottom: 10,
        flex: 1,
    },
    settingsSection: {
        marginBottom: 20,
    },
})
import { observer } from "mobx-react";
import React, { Component } from 'react';
import { ListRenderItemInfo, FlatList, StyleSheet, View } from 'react-native';
import { Logger } from '@openteam/app-util';
import { IUserBadges } from "@openteam/models";
import { OTTouchableOpacity } from '../../components';
import { getTimeAgo, hitSlop5 } from '../../utils/OTUtils';
import * as StyleConstants from '../../globals/StyleConstants';
import HeadIcon from '../../components/HeadIcon';
import { OTPopover } from "../../components/OTPopover";
import { Theme } from "../../globals/ThemeColors";
import { OTText } from "../../components/OTText";
import { SKMegaphone, SKKnock, SKMic, SKVideo } from "../../components/SVGIcons";
import { Feather } from '@expo/vector-icons';
import { OTUITree } from "@openteam/app-core";


const logger = new Logger("Alerts")
interface IAlertButtonProps {
  teamId: string
}

interface IAlertButtonState {
}

@observer
export class AlertButton extends Component<IAlertButtonProps, IAlertButtonState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    const teamManager = OTUITree.teamManagers[this.props.teamId];
    const teamData = teamManager.teamData;

    return (
      <OTPopover
        title="Alerts"
        isOpen={teamManager.alertManager.showAlerts}
        toggleIsOpen={teamManager.alertManager?.toggleShowAlerts}
        renderOpen={this.renderAlerts}
        icon={<Feather name="bell" size={20} style={{ color: Theme.HeaderIcon }} />}
        badgeNum={teamData.unreadAlerts}
      />
    )
  }
  renderAlerts = () => {
    return (
      <AlertBadgesView
        teamId={this.props.teamId} />
    )
  }
}




interface IAlertBadgesViewProps {
  teamId: string
}

interface IAlertBadgesViewState {
}

@observer
export class AlertBadgesView extends Component<IAlertBadgesViewProps, IAlertBadgesViewState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }


  render() {
    const teamManager = OTUITree.teamManagers[this.props.teamId];

    return (
      <View style={[styles.list]}>
        {this.renderClearAll()}
        <FlatList
          style={{ flex: 1 }}
          data={Object.values(teamManager.alertManager.badges)}
          renderItem={this.renderUserAlert}
          keyExtractor={(item: IUserBadges) => item.userId}
          // ItemSeparatorComponent={() => <View style={styles.item} />}
          ListEmptyComponent={this.renderAlertEmpty}
        // ListFooterComponent={this.renderAlertFooter}
        />
      </View>
    )
  }

  renderAlertEmpty = () => {
    return <View style={{ alignItems: 'center' }}><OTText>No missed activities</OTText></View>
  }

  renderClearAll = () => {
    const teamManager = OTUITree.teamManagers[this.props.teamId];

    if (teamManager.alertManager.alerts.length > 0) {
      return (
        <View style={{ alignItems: 'flex-end', justifyContent: 'center', }}>
          <OTTouchableOpacity onPress={teamManager.alertManager.clearAlerts}
            stopPropagation={true}
            analyticsEvent="clear_all_alerts">
            <OTText style={{ fontSize: 14, color: Theme.ButtonNegativeColor }}>Clear All</OTText>
          </OTTouchableOpacity>
        </View>
      )
    }
    return <View />

  }


  renderUserAlert = ({ item }: ListRenderItemInfo<IUserBadges>) => {
    var userId = item.userId
    const teamManager = OTUITree.teamManagers[this.props.teamId];
    const userDoc = teamManager.teamData.getTeamUser(userId);

    return (
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: 250,
        backgroundColor: Theme.RowBackground,
        padding: 8,
        borderRadius: Theme.curviness / 1.5,
        marginTop: 10
      }} >
        <HeadIcon imageUrl={userDoc?.imageUrl} name={userDoc.name} />

        <View style={styles.msgBox}>

          <View style={{ flexDirection: 'row' }}>
            <OTText style={{ fontWeight: '600', fontSize: 14, flex: 1 }} numberOfLines={1}>{userDoc.name}</OTText>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>

            <View style={{ flexDirection: 'row', flex: 1, alignItems: 'center' }}>
              {
                item?.KNOCK ?
                  <View style={[styles.iconHolder, { marginTop: -5 }]}>
                    <SKKnock size={15} style={iconStyle} />
                  </View>
                  :
                  null
              }
              {
                item?.CALL ?
                  <View style={styles.iconHolder}>
                    <SKVideo size={18} style={iconStyle} />
                  </View>
                  :
                  null
              }
              {
                item?.PTT_WALKIE ?
                  <View style={styles.iconHolder}>
                    <SKMic size={17} style={iconStyle} />
                  </View>
                  :
                  null
              }
              {
                item?.PTT_GLOBAL ?
                  <View style={styles.iconHolder}>
                    <SKMegaphone size={16} style={iconStyle} />
                  </View>
                  :
                  null
              }

            </View>
            <OTText style={[styles.msgTime, { color: Theme.SecondaryText }]}>
              {getTimeAgo(item.lastUpdate)}
            </OTText>
          </View>
        </View>
        <OTTouchableOpacity
          onPress={() => teamManager.alertManager.removeUserAlerts(userId)}
          stopPropagation={true}
          analyticsEvent="clear_user_alerts"
          style={{ position: 'absolute', top: 5, right: 5 }}
        >
          <Feather name="x" size={14} style={{ color: Theme.SecondaryText }} />
        </OTTouchableOpacity>

      </View>
    )
  }
}




const iconStyle = { color: Theme.ButtonNegativeColor }
const styles = StyleSheet.create({
  list: {
    marginTop: -15
  },
  item:
  {
    borderBottomWidth: 1,
    borderColor: StyleConstants.BorderColour,
    marginVertical: 5
  },
  iconHolder: {
    height: 17,
    width: 17,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 5
  },
  iconStyle: {
    color: Theme.ButtonNegativeColor,
    paddingRight: 10
  },
  msgBox: { marginLeft: 10, flex: 1 },
  msgTime: { fontSize: 10 }
})
import { observer } from "mobx-react";
import React, { Component } from "react";
import { TouchableOpacity, Text, TextInput, Linking } from "react-native";
import { TextProps, TextInputProps } from "react-native";

import { Theme } from "../globals/ThemeColors";
import { PlatformOS } from "../utils/platform";
import AutogrowInput from "./AutoGrowInput";


interface IOTTextProps extends TextProps {
  href?: string
  hrefAttrs?: Object
}

@observer
export class OTText extends Component<IOTTextProps> {

  render () {
    const {style, ...otherProps} = this.props;
    const incomingStyle = Array.isArray(style) ? style : [style];

    const isMobile = PlatformOS == 'mobile'
    return (
      <Text      
        {...otherProps}
        onPress={isMobile && this.props.href ? () => Linking.openURL(this.props.href!) : undefined}
        style={[
          {
            fontFamily: !isMobile ? "Lato" : undefined,
            color: Theme.MainText,
            ...(isMobile ? {} : { cursor: "default" }),
          },
          ...incomingStyle,
        ]}
      />
    );
  }
}

export interface TTextInput {
  focus: () => void
}

@observer
export class OTTextInput extends Component<TextInputProps> {
  inputRef: any = React.createRef<TTextInput>();

  focus = () => {
    this.inputRef.current?.focus()
  }

  render () {
    const {style, ...otherProps} = this.props;
    const incomingStyle = Array.isArray(style) ? style : [style];

    const inputStyle = {
      fontFamily: PlatformOS != 'mobile' ? 'Lato' : undefined,
      color: Theme.MainText,
      borderWidth: 1,
      backgroundColor: 'transparent',
      borderRadius: Theme.curviness / 2,
      borderColor: Theme.InputHighlightColor,
      padding: 5,
      width: 300
    }

    if (otherProps.multiline) {
      return <AutogrowInput
      ref={this.inputRef}
      placeholderTextColor={Theme.SecondaryText}
      {...otherProps}
      style={[
        inputStyle,
        ...incomingStyle]} />
    }
    return (
      <TextInput
      ref={this.inputRef}
      placeholderTextColor={Theme.SecondaryText}
      {...otherProps} style={[
        inputStyle,
        ...incomingStyle]} />
    )
  }
}

interface OTEditableProps extends TextInputProps {
  onEdited: (str) => void
  disabled: boolean
}

interface OTEditableState {
  focused: boolean,
  value: string
}

@observer
export class OTEditable extends Component<OTEditableProps, OTEditableState> {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      value: props.value
    }
  }

  render () {
    const styles: any[] = [{
      fontFamily: 'Lato',
      color: Theme.MainText,
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: 'transparent',
      borderRadius: Theme.curviness / 2,
      padding: 2
    }];

    const { onEdited, style, ...textProps } = this.props;

    const incomingStyle = Array.isArray(style) ? style : [style]


    if (this.state.focused) {
      styles.push({ borderColor: Theme.InputHighlightColor })

      return <TextInput
        {...textProps}
        value={this.state.value}
        style={[...styles, ...incomingStyle]}
        autoFocus={true}
        onBlur={(e) => {
          this.setState({ focused: false }), onEdited && onEdited(this.state.value)
        }}
        onChangeText={(value) => { this.setState({ value }), this.props.onChangeText && this.props.onChangeText(value) }}
      />
    } else if (this.props.disabled) {
      return (
        <OTText {...textProps} style={[...incomingStyle]} >
          {this.state.value}
        </OTText>
      )
    } else {
      return (
        <TouchableOpacity
          // @ts-expect-error cursor not part of types
          style={{
          ...PlatformOS != 'mobile' ? {cursor: 'text',} : {},
           }} onPress={() => this.setState({ focused: true })}>
          <Text {...textProps} style={[...styles, ...incomingStyle]} >
            {this.state.value}
          </Text>
        </TouchableOpacity>
      )
    }
  }
}
import React, { Component, CSSProperties } from "react";
import { ViewStyle } from "react-native";




interface IInlineWebProps {
  style: CSSProperties
  src: string
}

interface IInlineWebState {
}

export class InlineWeb extends Component<IInlineWebProps, IInlineWebState> {
  _iframe
  render() {
    return (
      <iframe
      {...this.props}
      ref={r => this._iframe = r}
      style={this.props.style}
      src={this.props.src} />
    )
  }
}



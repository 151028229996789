import { observable } from "mobx";
import { Dimensions } from "react-native";
import { debounce } from "throttle-debounce";

export class DimensionManager {
  @observable windowWidth: number = Dimensions.get("window").width;
  @observable windowHeight: number = Dimensions.get("window").height;

  constructor() {
    this.start();
  }

  start = () => {
    Dimensions.addEventListener("change", this.getDimensions);
  };

  stop = () => {
    Dimensions.removeEventListener("change", this.getDimensions);
  };

  getDimensions = debounce(50, () => {
    this.windowWidth = Dimensions.get("window").width;
    this.windowHeight = Dimensions.get("window").height;
  });
}

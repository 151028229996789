import { parseEmojis, OTChat } from "./chat/OTChat";
import { observer } from "mobx-react";
import React, { Component, PureComponent } from "react";
import { View, ListRenderItemInfo } from "react-native";
import { OTTouchableOpacity } from ".";
import * as StyleConstants from '../globals/StyleConstants';

import { Logger } from '@openteam/app-util';
import { getStringColour } from "../utils/OTUtils";
import Autolink from "react-native-autolink";
import { toJS } from "mobx";
import { OTText, OTTextInput } from "./OTText";
import { ViewStyle } from "react-native";
import { Theme } from "../globals/ThemeColors";
import { Feather } from '@expo/vector-icons';
import { IOTChatMessage } from "@openteam/models";
import { ChatFlatList } from "./chat";
import { OTGlobals, OTUITree } from "@openteam/app-core";

const logger = new Logger("RoomMsgs")


interface IRoomMsgsProps {
    chats: IOTChatMessage[]
    sendChatMessage?: (msg) => void
    showAdd: boolean
    showName: boolean
    width?: number
}

interface IRoomMsgsState {
    chatText: string
    forceShow: boolean
}

@observer
export class RoomMsgs extends Component<IRoomMsgsProps, IRoomMsgsState> {
    chatFlatList: ChatFlatList | null = null
    constructor(props) {
        super(props);

        this.state = {
            chatText: '',
            forceShow: false
        }
    }


    sendChatMsg = () => {
        if (this.state.chatText && this.props.sendChatMessage) {
            this.props.sendChatMessage(this.state.chatText)
            this.setState({ chatText: '' })
        }
    }

    toggleForceShow = () => {
        if (this.state.forceShow) {
            this.chatFlatList?.scrollToStart()
        }
        this.setState({ forceShow: !this.state.forceShow })
    }

    render() {
        var chats = this.props.chats
        return (
            <View pointerEvents={'box-none'} style={{ position: 'absolute', right: 0, bottom: 0, left: 0, top: 0 }}>

                <ChatFlatList
                    ref={c => this.chatFlatList = c}
                    pointerEvents={this.state.forceShow ? 'auto' : 'none'}
                    style={{ borderRadius: 5, backgroundColor: this.state.forceShow ? "rgba(0,0,0,0.2)" : undefined }}
                    contentContainerStyle={{ paddingHorizontal: 10, paddingVertical: 10, alignItems: 'flex-end' }}
                    data={chats}
                    showsVerticalScrollIndicator={false}
                    renderItem={({ item }: ListRenderItemInfo<IOTChatMessage>) => this.renderItem(item)}
                    ItemSeparatorComponent={() => <View style={{ marginVertical: 3 }} />}
                    keyExtractor={(item: IOTChatMessage) => item.id}
                />
                {
                    this.props.showAdd ?
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                flex: 1,
                                borderRadius: 5,
                                borderWidth: 1,
                                borderColor: "rgba(255,255,255,0.2)",
                                backgroundColor: "rgba(0,0,0,0.3)",
                                // ...StyleConstants.BoxGlow
                            }}>
                            <OTTextInput
                                style={{
                                    flex: 1,
                                    padding: 10,
                                    color: 'white',
                                }}
                                blurOnSubmit={false}
                                value={this.state.chatText}
                                onChangeText={(chatText) => this.setState({ chatText })}
                                onSubmitEditing={this.sendChatMsg} />
                            <OTTouchableOpacity onPress={this.sendChatMsg} style={{ paddingHorizontal: 10 }} disabled={!this.state.chatText}>
                                <Feather name="send" size={20} style={{ color: this.state.chatText.length > 0 ? StyleConstants.SecondaryColour : StyleConstants.IconGrey }} />
                            </OTTouchableOpacity>
                            <OTTouchableOpacity onPress={this.toggleForceShow} style={{ paddingHorizontal: 10 }}>
                                <Feather name="clock" size={20} style={{ color: this.state.forceShow ? StyleConstants.SecondaryColour : StyleConstants.IconGrey }} />
                            </OTTouchableOpacity>

                        </View>
                        :
                        null
                }
            </View>

        )
    }

    renderItem = (item: IOTChatMessage) => {
        return (
            <DisappearingMessage message={item} forceShow={this.state.forceShow} showName={this.props.showName} width={this.props.width} />
        )
    }
}



interface IDisappearingMessageProps {
    message: IOTChatMessage
    showName: boolean
    forceShow: boolean
    width?: number
}

interface IDisappearingMessageState {
    pastThreshold: boolean
}

export class DisappearingMessage extends PureComponent<IDisappearingMessageProps, IDisappearingMessageState> {
    thresholdTime = 10000
    animateTime = 5000

    fadeTimeout
    constructor(props) {
        super(props);

        var ageMs = Date.now() - this.props.message.crDate.getTime()


        this.state = {
            // old: ageMs >= this.threshold + this.animation,
            pastThreshold: ageMs >= this.thresholdTime
        }

        if (!this.state.pastThreshold) {
            logger.debug("timeout for ", this.thresholdTime - ageMs)
            this.fadeTimeout = setTimeout(() => { this.setState({ pastThreshold: true }) }, this.thresholdTime - ageMs)
        }
    }

    componentWillUnmount = () => {
        clearTimeout(this.fadeTimeout)
    }

    render() {
        var message = this.props.message
        var forceShow = this.props.forceShow
        var isUser = message.userId == OTUITree.auth.userId

        var emojiResult = parseEmojis(message.message)

        var opacityStyle = {}
        var showMsg = true

        if (!forceShow) {
            var ageMs = Date.now() - this.props.message.crDate.getTime()

            if (ageMs >= this.thresholdTime + this.animateTime) {
                showMsg = false
                opacityStyle = {
                    opacity: 0
                }
            } else if (ageMs >= this.thresholdTime) {
                opacityStyle = {
                    ...opacityStyle,
                    transitionProperty: "opacity",
                    transitionDuration: `${((this.thresholdTime + this.animateTime - ageMs) / 1000).toFixed(2)}s`,
                    willChange: "opacity",
                    opacity: 0
                }
            }

        }

        return (
          <View
            style={{
              flex: 1,
              borderRadius: 10,
              paddingHorizontal: 10,
              justifyContent: "center",
              minHeight: 26,
              paddingVertical: 2,
              backgroundColor: getStringColour(message.name),
              opacity: 1,
              ...opacityStyle,
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
              {this.props.showName ? (
                <OTText style={{ fontWeight: "bold", paddingRight: 6 }}>
                  {isUser ? "Me" : message.name}
                </OTText>
              ) : null}

              <Autolink
                style={{
                  fontSize: emojiResult.isOnlyEmoji ? 50 : undefined,
                  flex: 1,
                  maxWidth: (this.props.width || 280) - 40,
                }}
                text={emojiResult.value}
                renderLink={(text, match) => (
                  <OTText
                    // @ts-expect-error pointerevents not part of types
                    pointerEvents={showMsg ? "auto" : "none"}
                    href={match.getAnchorHref()}
                    accessibilityRole="link"
                    hrefAttrs={{ target: "_blank" }}
                    style={{
                      color: StyleConstants.LinkColour,
                      maxWidth: (this.props.width || 280) - 40,
                    }}
                  >
                    {match.getAnchorHref()}
                  </OTText>
                )}
              />
            </View>
          </View>
        );
    }
}




const emojiShortcuts = [
    "👏",
    "👍",
    "👎",
    "😂",
    "🎉",
    "😍",
    "🤯",
    "👋",
]

interface IRoomChatWidgetProps {
    teamId: string
    chatText: string
    onChangeText: (text) => void
}
interface IRoomChatWidgetState {
}

@observer
export class RoomChatWidget extends Component<IRoomChatWidgetProps, IRoomChatWidgetState> {
    constructor(props) {
        super(props);

        this.state = {
        }
        console.log("creating RoomChatWidget")
    }

    sendChatMsg = () => {
        const callStateManager = OTGlobals.callStateManager;

        if (this.props.chatText) {
            callStateManager?.sendChatMessage(this.props.chatText)
            this.props.onChangeText('')
        }
    }

    sendEmojiMsg = (emoji) => {
        const callStateManager = OTGlobals.callStateManager;
        callStateManager?.sendChatMessage(emoji)
    }


    render() {
        return (
            <View style={{ width: emojiShortcuts.length / 2 * 60 }}>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    {
                        emojiShortcuts.map((emoji, index) => (
                            <OTTouchableOpacity key={`emoji-${index}`} style={{ height: 60, width: 60, alignItems: 'center', justifyContent: 'center' }} onPress={() => this.sendEmojiMsg(emoji)}>
                                <OTText style={{ fontSize: 32 }}>
                                    {emoji}
                                </OTText>
                            </OTTouchableOpacity>
                        ))
                    }

                </View>
                <View style={{ borderBottomWidth: 1, borderColor: StyleConstants.BorderColour }} />
                <View style={{ flexDirection: 'row', alignItems: 'center', padding: 5 }}>
                    <OTTextInput
                        style={{
                            flex: 1,
                            padding: 10,
                        }}
                        placeholder={"Send a chat message..."}
                        autoFocus={true}
                        blurOnSubmit={false}
                        value={this.props.chatText}
                        onChangeText={this.props.onChangeText}
                        onSubmitEditing={this.sendChatMsg} />

                    <View style={{ paddingRight: 10, flexDirection: 'row', alignItems: 'center' }}>
                        <OTTouchableOpacity onPress={this.sendChatMsg} style={{ paddingLeft: 10 }} disabled={!(this.props.chatText)}>
                            <Feather name="send" size={20} style={{ color: (this.props.chatText ? this.props.chatText.length : 0) > 0 ? Theme.ButtonColor : Theme.ButtonDisabledColor }} />
                        </OTTouchableOpacity>
                    </View>
                </View>
            </View>
        )
    }
}


interface IRoomChatProps {
    teamId: string
    toggleShow: () => void
    showChat: boolean
    outerStyle?: ViewStyle
}

interface IRoomChatState {
    forceShow: boolean
}

@observer
export class RoomChat extends Component<IRoomChatProps, IRoomChatState> {


    constructor(props) {
        super(props);

        this.state = {
            forceShow: false
        }
    }


    sendChatMsg = () => {
        const callStateManager = OTGlobals.callStateManager;

        if (callStateManager?.draftMessage || callStateManager?.draftFiles) {
            callStateManager?.sendChatMessage(callStateManager?.draftMessage, callStateManager?.draftFiles)
        }
    }



    render() {
        const callStateManager = OTGlobals.callStateManager;

        if (!callStateManager) {
            return <View />
        }

        var chats = toJS(callStateManager?.roomMsgs || []).reverse()
        return (
            <OTChat
                title={"Room Messages"}
                fullHeight={true}
                showUserImage={true}
                messages={chats}
                sendMessage={this.sendChatMsg}
                onClose={this.props.toggleShow}
                draftText={callStateManager.draftMessage}
                setDraftText={(text) => callStateManager.draftMessage = text}
                draftFiles={callStateManager.draftFiles}
                setDraftFiles={callStateManager.setDraftFiles}
                pendingMessages={callStateManager.pendingMessages}
                onFiles={callStateManager.addDraftFiles}
                outerStyle={this.props.outerStyle}
                showHeader={true}
            />

        )
    }
}

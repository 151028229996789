import events from "events";
import { autorun, observable, runInAction, toJS } from "mobx";

import { Logger } from "@openteam/app-util";
import {
  IPeerMsg,
  IOTRoom,
  IOTUser,
  IPTTController,
  ITeamDoc,
  ITeamUser,
  TActionType,
  TShowMeetingModal,
  ITeamRoomConfig,
} from "@openteam/models";
import { AwaitLock } from "@openteam/app-util";
import { FirebaseMessagingTypes } from "@react-native-firebase/messaging";
import { FireDb, SessionDb, TeamManagerDb } from "./fire";
import { OTUserInterface } from "./OTUserInterface";
import { OTAppCoreData } from "./OTAppCoreData";
import { CallRequestManager, endActiveCall, TDismissModal, TShowCallFeedback, TShowCallRequestModal } from "./CallRequest";
import { AlertManager, doActionAlert, recvAction, sendAction, userOnlineAlert } from "./Alert";
import { OTTeamDataClass } from "./OTTeamDataClass";
import { THandleOpenNotification } from "./AppHome/AppHomeManager";
import { P2PStreamManager } from "./PeerConnection";
import { ChatManager } from "./Chat";
import { CallStateManager } from "./CallStateManager";
import { TeamConnection } from "./TeamConnection";
import { TeamAccessReqManager } from "./TeamAccessReqManager";
import { MeetingManager } from "./Meeting/MeetingManager";
import { RoomManager } from "./RoomManager";
import { OTGlobals} from "./OTGlobals";
import { PTTTeamController } from "./PTTTeamController";
import { PTTController } from "./PTTController";
import { applyObjectUpdate } from "./utils/applyObjectUpdate";
import { userIsOnline } from "./utils/userIsOnline";

const logger = new Logger("TeamManager");

const NotifyTime = 6 * 60 * 60 * 1000;

var dummyUser: ITeamUser = {
  name: "Unknown User",
  id: "unknown",
  email: "unknownemail",
  imageUrl: null,
};

interface ITeamDetails {
  teamName: string;
}

export class TeamManager extends events.EventEmitter {
  fbDb: firebase.database.Database;
  fsDb: firebase.firestore.Firestore;
  myUserId: string;
  sessionToken: string;

  teamId: string;

  @observable teamData: OTTeamDataClass;

  hasTeam = true;
  _useTeamServer?: boolean;

  showMeetingModal: TShowMeetingModal;
  showCallRequestModal: TShowCallRequestModal;
  showCallFeedback: TShowCallFeedback;
  dismissModal: TDismissModal;
  handleOpenNotification: THandleOpenNotification;

  @observable _streamManager?: P2PStreamManager;
  pttManager?: IPTTController;
  @observable teamConnection?: TeamConnection;
  callRequestManager!: CallRequestManager;
  @observable chatManager!: ChatManager;
  alertManager!: AlertManager;
  teamAccessReqManager!: TeamAccessReqManager;
  meetingManager!: MeetingManager;

  teamDetails!: ITeamDetails;
  _users: { [id: string]: ITeamUser } = {};
  _adminUsers: { [id: string]: { id: string } } = {};
  @observable callStateManager?: CallStateManager;
  reqStart: boolean = false;
  @observable running: boolean = false;
  @observable loaded: boolean = false;

  _teamDoc?: ITeamDoc;
  idleTime: any;
  _roomManager!: RoomManager;
  _stateLock = new AwaitLock();
  _teamConnectionLock = new AwaitLock();
  _autorun: Record<string, any> = {};

  constructor(
    fbDb: firebase.database.Database,
    fsDb: firebase.firestore.Firestore,
    userId: string,
    sessionToken: string,
    teamId: string,
    showMeetingModal: TShowMeetingModal,
    showCallRequestModal: TShowCallRequestModal,
    showCallFeedback: TShowCallFeedback,
    dismissModal: TDismissModal,
    handleOpenNotification: THandleOpenNotification
  ) {
    super();

    this.fbDb = fbDb;
    this.fsDb = fsDb;
    this.myUserId = userId;
    this.sessionToken = sessionToken;
    this.teamId = teamId;

    this.showMeetingModal = showMeetingModal;
    this.showCallRequestModal = showCallRequestModal;
    this.showCallFeedback = showCallFeedback;
    this.dismissModal = dismissModal;
    this.handleOpenNotification = handleOpenNotification;

    logger.info(`Creating TeamManager for teamId=${teamId}`);

    this.teamData = new OTTeamDataClass(this.fbDb, this.myUserId, this.teamId);
  }

  handleCurrentTeam = () => {
    const teamData = this.teamData;
    if (!teamData) {
      return;
    }
    //logger.debug("handleCurrentTeam", this.teamId)

    if (OTUserInterface.platformUtils.PlatformOS != "mobile") {
      const teamFocused = OTGlobals.auth.userManager.currentTeamId == this.teamId;

      if (teamFocused != teamData.isFocused) {
        TeamManagerDb.setTeamBackgroundStatus(this.fbDb, this.myUserId, this.teamId, !teamFocused);
        logger.info(`setting teamId=${this.teamId} teamFocused=${teamFocused}`);
      }
    }
  };

  handleIsIdle = () => {
    logger.debug(`setting idle teamId=${this.teamId} isIdle=${OTGlobals.isIdle}`);
    TeamManagerDb.setIdle(this.fbDb, this.myUserId, this.teamId, OTGlobals.isIdle);
  };

  start = () => {
    const platformOS = OTUserInterface.platformUtils.PlatformOS;

    if (this.running) {
      return;
    }

    if (!this.loaded) {
      this.reqStart = true;
      return;
    }

    if (platformOS == "mobile") {
      TeamManagerDb.registerDeviceTeamUser(
        this.fbDb,
        this.myUserId,
        this.sessionToken,
        this.teamId
      );
    } else {
      SessionDb.setupTeamPresence(this.fbDb, this.myUserId, this.sessionToken, this.teamId);
    }

    this._roomManager = new RoomManager(this.fbDb, this.myUserId, this.teamId);

    if (platformOS != "mobile") {
      this._autorun["setupCurrentTeam"] = autorun(this.setupTeamConnection);

      // this.noteManager = new NoteManager(this.teamId)
      this.meetingManager = new MeetingManager(
        this.fbDb,
        this.teamId,
        this.myUserId,
        this.sessionToken,
        this.showMeetingModal,
        this._teamDoc?.users?.[this.myUserId].address
      );
    }

    this.callRequestManager = new CallRequestManager(
      this.fbDb,
      this.myUserId,
      this.sessionToken,
      this.teamId,
      this.joinTeamRoom,
      this.showCallRequestModal,
      this.dismissModal
    );

    this.callRequestManager.start();

    this.chatManager = new ChatManager(this.fsDb, this.teamId, this.myUserId);
    this.chatManager.start();

    this.alertManager = new AlertManager(this.fbDb, this.myUserId, this.teamId);
    this.teamAccessReqManager = new TeamAccessReqManager(this.fbDb, this.teamId);

    this._autorun["handleCurrentTeam"] = autorun(this.handleCurrentTeam);

    this._autorun["handleIsIdle"] = autorun(this.handleIsIdle);

    let myRoom: IOTRoom | null = null;

    runInAction(() => {
      if (this._teamDoc) {
        this._writeUsers(this._teamDoc);
        myRoom = this._roomManager.handleDoc(this._teamDoc) || null;
      }
    });

    this._manageCallState(myRoom);

    this.running = true;

    logger.info("started", this.teamId);
  };

  stop = async () => {
    if (!this.running) return;

    logger.debug("stopping");

    Object.values(this._autorun).map(x => x());
    this._autorun = {}

    this.teamData.stop();

    this.callRequestManager?.stop();

    this.chatManager?.stop();
    this.alertManager?.stop();
    this.teamAccessReqManager?.stop();
    this.meetingManager?.stop();

    this._streamManager && this._streamManager.stop();

    this.teamConnection && this.teamConnection.stop();

    await this.leaveTeamRoom();
    await this._manageCallState(null);

    this.running = false;
  };

  _autorunSetupCurrentTeam;
  setupTeamConnection = () => {
    const teamUseTeamServer = this.teamData.capabilities.useTeamServer;
    const globalUseTeamServer = OTAppCoreData.useTeamServer;
    this._setupTeamConnection(teamUseTeamServer ?? globalUseTeamServer);
  };

  _setupTeamConnection = async (useTeamServer?: boolean) => {
    await this._teamConnectionLock.acquireAsync();
    try {
      useTeamServer = useTeamServer ? true : false;

      logger.info("_setupTeamConnection called, useTeamServer", useTeamServer, this.teamId);

      if (this._useTeamServer != undefined && this._useTeamServer == useTeamServer) {
        return;
      }

      this._useTeamServer = useTeamServer;

      if (useTeamServer) {
        logger.info("using team server", this.teamId);
        if (this._streamManager) {
          this._streamManager.stop();
          this._streamManager = undefined;
        }

        if (this.pttManager) {
          this.pttManager = undefined;
        }

        if (!this.teamConnection) {
          this.teamConnection = new TeamConnection(this.myUserId, this.teamId);
          this.teamConnection.on("action", this.handleAlert);
        }

        if (!this.pttManager) {
          this.pttManager = new PTTTeamController(
            this.fbDb,
            this.myUserId,
            this.teamId,
            this.teamConnection,
            this._roomManager
          );
          this._teamDoc && this.pttManager && this.pttManager.processDoc(this._teamDoc);
        }
      } else {
        logger.info("using team mesh network", this.teamId);

        if (this.teamConnection) {
          this.teamConnection.stop();
          this.teamConnection = undefined;
        }

        if (this.pttManager) {
          this.pttManager = undefined;
        }

        if (!this._streamManager) {
          this._streamManager = new P2PStreamManager(
            this.fbDb,
            this.myUserId,
            this.sessionToken,
            this.teamId,
            "ptt"
          );

          this._streamManager.on("action", this.handleAlert);
          this._streamManager.start();
          this._streamManager.updateUsers(this._teamDoc?.users || {});
        }

        if (!this.pttManager) {
          this.pttManager = new PTTController(
            this.myUserId,
            this.teamId,
            this._streamManager,
            this._roomManager
          );

          this._teamDoc && this.pttManager && this.pttManager.processDoc(this._teamDoc);
        }
      }
    } finally {
      this._teamConnectionLock.release();
    }
  };

  handleNotification = (notification: FirebaseMessagingTypes.RemoteMessage) => {
    if (notification.data?.type == "CHAT") {
    } else {
      OTUserInterface.toastHandlers.show(
        notification.notification?.title,
        "info",
        notification.notification?.body
      );
    }

    if (notification.data?.type == "KNOCK") {
      OTUserInterface.soundEffects.knock();
    }
  };

  handleAlert = (userId, actionType: TActionType) => {
    recvAction(userId, actionType);
    var teamUserDoc = this.getTeamUser(userId);
    if (teamUserDoc) {
      doActionAlert(this.teamId, teamUserDoc, actionType);

      if (actionType == "PTT_WALKIE" || actionType == "KNOCK") {
        this.pttManager?.setLastPtt(userId);
      }
    }
  };

  handleTeamDoc = async (doc: ITeamDoc) => {
    await this._stateLock.acquireAsync();

    try {
      let myRoom: IOTRoom | null = null;
      // logger.debug("got doc update", doc)

      this._teamDoc = doc;

      if (!doc || !doc.users || !(this.myUserId in doc.users)) {
        return;
      }

      runInAction(() => {
        const teamData = this.teamData;

        this._users = doc.users;
        this._adminUsers = doc.admin || {};

        const meDoc = doc.users[this.myUserId];

        teamData.isAdmin = this.myUserId in this._adminUsers;
        teamData.isFocused = meDoc.status?.inBackground == false;

        teamData.config = doc.config;

        teamData.teamPath = doc.teamPath;
        teamData.teamName = doc.teamName;
        teamData.backgroundImageUrl = doc.backgroundImageUrl;
        teamData.iconImageUrl = doc.iconImageUrl;

        if (!teamData.preferences || !meDoc.preferences) {
          teamData.preferences = meDoc.preferences;
        } else {
          applyObjectUpdate(teamData.preferences, meDoc.preferences);
        }

        var newSubteams = doc.subteams || {};

        if (!teamData.subTeams) {
          teamData.subTeams = newSubteams;
        } else {
          applyObjectUpdate(teamData.subTeams, newSubteams);
        }

        if (this._streamManager) {
          this._streamManager.updateUsers(doc.users);
        }

        if (this.meetingManager) {
          var address = doc.users[this.myUserId].address;
          this.meetingManager.setupAddress(address);
        }

        this.teamDetails = {
          teamName: doc.teamName,
        };

        if (this.running) {
          myRoom = this._roomManager.handleDoc(doc) || null;
          this._writeUsers(doc);
        }
      });

      if (this.running) {
        await this._manageCallState(myRoom);
      }

      if (!this.loaded) {
        this.loaded = true;
        this.emit("loaded");
        if (this.reqStart) {
          this.start();
        }
      }
    } finally {
      this._stateLock.release();
    }
  };

  _writeUsers(doc: ITeamDoc) {
    const now = new Date().getTime();

    this.pttManager && this.pttManager.processDoc(doc);

    const teamData = this.teamData;

    Object.values(doc.users).forEach((user) => {
      const { isOnline, inLeeway } = userIsOnline(user);
      const inBackground = user.status?.inBackground == true;

      var newUserState: IOTUser = {
        userId: user.id,
        name: user.name,
        email: user.email,
        online: isOnline,
        hasMobile: Object.keys(user.device || {}).length > 0,
        inLeeway: inLeeway,
        idle: user.status?.idle == true,
        inBackground,
        subTeam: user.subTeam,
        imageUrl: user.imageUrl || undefined,
        isAdmin: user.id in this._adminUsers,
        sessionToken: user.status?.sessionToken || null,
        canPtt: this.pttManager?.canPttUser(user.id) || false,
        last_changed: user.status?.last_changed,
      };

      if (!teamData.users[user.id]) {
        teamData.users[user.id] = newUserState;
      } else {
        const prevLastChanged = teamData.users[user.id].last_changed;
        const prevOnline = teamData.users[user.id].online;
        const newLastChanged = newUserState.last_changed;

        applyObjectUpdate(teamData.users[user.id], newUserState);

        if (
          teamData.capabilities.notifyUserOnline &&
          !teamData.preferences?.disableNotifyUserOnline &&
          user.id != this.myUserId &&
          !prevOnline &&
          newUserState.online &&
          prevLastChanged &&
          newLastChanged &&
          prevLastChanged != newLastChanged
        ) {
          logger.info(
            "user.id ",
            user.id,
            "prevLastChanged",
            prevLastChanged,
            "newLastChanged",
            newLastChanged
          );

          const isRecent = now - newLastChanged < 30 * 60 * 1000;

          if (isRecent && newLastChanged - prevLastChanged > NotifyTime) {
            userOnlineAlert(this.teamId, user.id);
          }
        }
      }
    });

    let userIds = Object.keys(doc.users);
    Object.keys(teamData.users).forEach((userId) => {
      if (!userIds.includes(userId)) {
        delete teamData.users[userId];
      }
    });
  }

  getRoomCallState = (roomId: string) => {
    if (roomId == this.callStateManager?.roomId) {
      return this.callStateManager;
    }
  };

  _manageCallState = async (room: IOTRoom | null) => {
    // Must be called with this._stateLock lock held
    const callStateManager = OTGlobals.callStateManager;
    logger.debug("managerCallState");

    logger.debug(
      `_manageCallState teamId=${this.teamId}, currentTeamId=${
        OTGlobals.auth.userManager.currentTeamId
      }, callStateTeamId=${callStateManager?.teamId}, room=${toJS(room)}`
    );

    if (callStateManager && callStateManager.teamId == this.teamId) {
      if (
        callStateManager.roomId != room?.roomId ||
        !room?.config?.call ||
        room?.users[this.myUserId].currentSessionToken != this.sessionToken
      ) {
        logger.info(`Ending call for roomId=${callStateManager.roomId}`);

        const callSecs = await callStateManager.shutdown();

        OTGlobals.analytics?.logEvent("teammanager_leave_call", {
          roomId: callStateManager.roomId,
          timeInCall: callSecs,
        });
        OTGlobals.setCallStateManager(undefined);

        endActiveCall();
      } else {
        callStateManager.updateUsers(room.users);
      }
    }

    if (
      !OTGlobals.callStateManager &&
      room &&
      room.config?.call &&
      room.users[this.myUserId].currentSessionToken == this.sessionToken &&
      OTGlobals.auth.userManager.currentTeamId === this.teamId
    ) {
      logger.debug(`Starting call for teamId=${this.teamId}, ${room.roomId} with ${room.users}`);
      const callStateManager = new CallStateManager(
        this.fbDb,
        this.myUserId,
        this.sessionToken,
        this.teamId,
        room.roomId,
        room.users,
        this.showCallFeedback
      );
      callStateManager.on("leavecall", (ev) => this.joinTeamRoom(null));

      OTGlobals.setCallStateManager(callStateManager);

      OTGlobals.analytics?.logEvent("teammanager_join_call", {
        roomId: room.roomId,
        numUser: room.users.length,
      });
    }
  };

  getMe = () => {
    return this._users[this.myUserId];
  };

  getTeamUser = (userId: string): ITeamUser => {
    return this._users[userId] || dummyUser;
  };

  setDisplaySubTeam = (subTeam: string, display: boolean) => {
    FireDb.updateTeamUserPreference(this.fbDb, this.teamId, this.myUserId, "displaySubteam", {
      [subTeam]: display ? true : false,
    });
  };

  startPtt = async (roomId?: string, subTeam?: string, userId?: string) => {
    return await this.pttManager?.startPtt(roomId, subTeam, userId);
  };

  stopPtt = async () => {
    return await this.pttManager?.stopPtt();
  };

  knockUser = (userId, failsSiliently: boolean = false) => {
    this.sendAction(
      userId,
      "KNOCK",
      () => {
        OTUserInterface.soundEffects.knock();
      },
      () => {
        if (!failsSiliently) {
          OTUserInterface.toastHandlers.show("Unable to deliver knock, user offline?", "error");
        }
      }
    );
    OTGlobals.analytics?.logEvent("sent_knock", { userId: userId });
  };

  knockSubTeam = (subTeam: string) => {
    const teamData = this.teamData;
    const room = teamData.rooms["online"];

    var targets = Object.keys(room.subteams![subTeam]);

    targets.forEach((userId) => userId != this.myUserId && this.sendAction(userId, "KNOCK"));

    OTUserInterface.soundEffects.knock();
    OTGlobals.analytics?.logEvent("sent_knock_subteam", { subteam: subTeam });
  };

  callSubTeam = (subTeam: string, subTeamName: string) => {
    const callRequestManager = this.callRequestManager;

    const teamData = this.teamData;
    const room = teamData.rooms["online"];

    var targets = Object.keys(room.subteams![subTeam]).filter((userId) => userId != this.myUserId);

    var cancel = subTeam in callRequestManager.callsByKey;

    if (cancel) {
      targets.forEach((userId) => callRequestManager.sendCancelCall(userId, true));
    } else {
      targets.forEach((userId) => callRequestManager?.sendCallUser(userId, subTeam, subTeamName));
    }

    OTGlobals.analytics?.logEvent("call_subteam", { subteam: subTeam });
  };

  getRoom = (roomId) => this.teamData.rooms[roomId];

  toggleRoomLock = (roomId: string) => {
    logger.info("setting room lock ", !this.getRoom(roomId).config?.isLocked);

    FireDb.updateRoomConfig(this.fbDb, this.teamId, roomId, {
      isLocked: !this.getRoom(roomId).config?.isLocked,
    });
  };

  leaveTeamRoom = async () => {
    await this.joinTeamRoom(null);
  };

  joinTeamRoom = async (roomId: string | null, ignoreLock = false) => {
    const currentUser = this.teamData.getTeamUser(this.myUserId);

    const room = this.getRoom(roomId);

    const inRoom = this.myUserId in (room?.users || {})
    const isAdmin = currentUser.isAdmin;
    const isOwner = room?.config?.ownerUserId === this.myUserId;

    if (!ignoreLock && room && room.config?.isLocked && !isAdmin && !isOwner) {
      return OTUserInterface.toastHandlers.show("room is locked", "info");
    }

    await FireDb.joinTeamRoom(
      this.fbDb,
      this.teamId,
      this.myUserId,
      this.sessionToken,
      roomId,
      FireDb.getRoomUser(currentUser)
    );

  };

  startRoomForChannel = async (roomName: string | undefined, channelId: string, topicId: string) => {
    var roomConfigDoc: ITeamRoomConfig = {
      name: roomName || "Meeting Room",
      channelId: channelId,
      topicId: topicId,
      desc: "",
      enabled: true,
      call: true,
      permanent: false,
    };

    var roomId = await FireDb.createRoom(
      this.fbDb,
      this.teamId,
      this.myUserId,
      this.sessionToken,
      roomConfigDoc
    );

  };

  sendMessage = (
    userId,
    message: IPeerMsg,
    onSuccess?: () => void,
    onError?: (errorCode) => void
  ) => {
    if (this.teamConnection) {
      this.teamConnection.sendMessage(userId, message, (responseData) => {
        if (responseData.status == "OK") {
          onSuccess && onSuccess();
        } else {
          onError && onError(responseData.error);
        }
      });
    }

    if (this._streamManager) {
      this._streamManager.sendMessage(userId, message);
    }
  };

  sendAction = (
    userId: string,
    actionType: TActionType,
    onSuccess?: () => void,
    onError?: (errorCode) => void
  ) => {
    sendAction(this.fbDb, this.myUserId, this.teamId, userId, actionType);
    if (this.teamConnection) {
      this.teamConnection.sendAction(userId, actionType, (responseData) => {
        if (responseData.status == "OK") {
          onSuccess && onSuccess();
        } else {
          onError && onError(responseData.error);
        }
      });
    }

    if (this._streamManager) {
      this._streamManager.sendMessage(userId, {
        msgType: "ACTION",
        actionType: actionType,
      });
      onSuccess && onSuccess();
    }
  };

  acceptCall = () => {
    this.callRequestManager.recvRespondToCall("accepted");
  };

  rejectCall = () => {
    this.callRequestManager.recvRespondToCall("rejected");
  };

  leaveTeam = async () => {
    this.stop();

    if (this.teamData.isAdmin) {
      TeamManagerDb.removeTeamAdminUser(this.fbDb, this.teamId, this.myUserId);
    }
    TeamManagerDb.leaveTeam(this.fbDb, this.teamId, this.myUserId);

    await OTGlobals.auth.userManager.removeUserTeam(this.teamId);
  };
}

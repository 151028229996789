import React, { Component } from 'react';
import { ScrollView } from 'react-native';
import { View } from 'react-native-web';
import * as ModalService from '../components/ModalService'
import * as StyleConstants from '../globals/StyleConstants'
import { OTTouchableOpacity } from '../components';
import { observer } from 'mobx-react';
import { getStringColour } from "../utils/OTUtils";
import { JoinTeam } from '../components/team';
import { PageHeader } from './fragments/PageHeader';
import { OTText } from '../components/OTText';
import { ProgressBar } from '../components/ProgressBar';
import { Theme } from '../globals/ThemeColors';
import { InviteTeam } from './fragments/TeamAccessReqs';
import * as Analytics from '../utils/Analytics'
import { OTUITree } from '@openteam/app-core';

interface ILoginTeamProps {
  onSuccess: (teamId) => void
}

interface ILoginTeamState {
  showInvite: boolean
  teamId?: string
}

@observer
export default class LoginTeam extends Component<ILoginTeamProps, ILoginTeamState> {
  constructor(props) {
    super(props);


    this.state = {
      showInvite: false,
    }

  }

  componentDidMount = () => {
    Analytics.logEvent("loginteam_page")
  }


  render() {
    return (
      <View style={{ flex: 1, backgroundColor: Theme.OfficeBackground }} >
        <ScrollView contentContainerStyle={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1, paddingHorizontal: 25, paddingTop: 50 }}>
          <ModalService.OTModalContainer padding={40} style={{ minWidth: 300, minHeight: 200, justifyContent: 'space-around' }}>
            {
              this.state.showInvite && this.state.teamId ?
                <InviteTeam teamId={this.state.teamId} onClose={() => this.props.onSuccess(this.state.teamId)} />

                :
                <JoinTeam
                  onJoin={this.props.onSuccess}
                  onCreate={(teamId) => this.setState({ teamId: teamId, showInvite: true })}
                />
            }

          </ModalService.OTModalContainer>


          <PageHeader />
        </ScrollView>
        <View style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
          <ProgressBar progress={this.state.showInvite && this.state.teamId ? 100 : 66} borderRadius={0} height={12} />
        </View>
      </View>
    )
  }

  renderTeam = (teamId: string) => {
    var teamDoc = OTUITree.userManager.userTeams[teamId]
    return (
      <OTTouchableOpacity
        key={"menuteam" + teamDoc.teamPath}
        style={{ flexDirection: "row", alignItems: "center", padding: 5 }}
        onPress={() => OTUITree.userManager.setCurrentTeam(teamId)}
      >
        <View
          style={{
            height: 40,
            width: 40,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: Theme.curviness,
            backgroundColor: getStringColour(teamDoc.teamName),
            ...StyleConstants.BoxShadow,
          }}
        >
          <OTText style={{ fontSize: 24, fontWeight: "bold", color: "white" }}>
            {teamDoc.teamName.charAt(0).toUpperCase()}
          </OTText>
        </View>
        <OTText style={{ paddingLeft: 10, fontSize: 14 }}>{teamDoc.teamName.toUpperCase()}</OTText>
      </OTTouchableOpacity>
    );
  }

}


export function changeSDPCodec(data) {

  if (data.sdp ) {

    var lines = data.sdp.split("\n")

    for (var i = 0; i < lines.length; i++) {
      let fields = lines[i].split(/\s+/);
      if (fields[0] === 'm=video') {
        let [type, port, proto, ...formats] = fields;

        let h264_order = formats.indexOf("102");
        if (h264_order > 0) {
          formats.splice(h264_order, 1);  // remove from existing position
          formats.unshift("102");         // and prepend
        }
        lines[i] = [type, port, proto, ...formats].join(' ');
      }
    }

    data.sdp = lines.join('\n')
  }
  return data

}
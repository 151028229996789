import React, { Component } from "react";
import { observer } from "mobx-react";
import { OTTouchableOpacity } from "./buttons";
import { BadgeView } from "./BadgeUtil";
import * as StyleConstants from '../globals/StyleConstants';
import { View, Text, ViewStyle } from "react-native";
import { Theme } from "../globals/ThemeColors";
import { OTText } from "./OTText";
import { Menu, MenuOption, MenuOptions, MenuTrigger, renderers } from "react-native-popup-menu";
import * as Analytics from '../utils/Analytics'

interface IOTPopoverProps {
  dataSet?: {[id: string]: string}
  title?: string
  buttonLeft?: JSX.Element
  buttonRight?: JSX.Element
  isOpen: boolean
  toggleIsOpen: () => void
  badgeNum?: number
  icon: JSX.Element
  iconText?: string
  renderOpen: () => JSX.Element,
  outerStyle?: ViewStyle
  placement?: string
}

interface IOTPopoverState {
}

export class OTPopover extends Component<IOTPopoverProps, IOTPopoverState> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  toggleIsOpen = () => {
    this.props.toggleIsOpen();

    var eventTitle = this.props.title?.toLowerCase().replace(/ /g, "_");

    Analytics.logEvent(`${eventTitle}_menu_${this.props.isOpen ? "close" : "open"}`, {
      interaction: "button",
    });
  };

  render() {
    return (
      <Menu
        opened={this.props.isOpen}
        onBackdropPress={this.props.toggleIsOpen}
        renderer={renderers.Popover}
        rendererProps={{ placement: this.props.placement || "bottom", anchorStyle: { backgroundColor: "transparent" } }}
      >
        <MenuTrigger
          onPress={this.props.toggleIsOpen}
          customStyles={{ triggerOuterWrapper: { marginLeft: 30, ...this.props.outerStyle } }}
        >
          <View
            data-tip={this.props.title}
            data-tip-disable={this.props.isOpen}
            // @ts-expect-error dataSet not part of types
            dataSet={{
              tip: this.props.title,
              "tip-disable": this.props.isOpen,
              ...this.props.dataSet
            }}
            style={{ flexDirection: 'row', alignItems: 'center' }}>

            <View>
              {this.props.icon}
              <BadgeView badgeNum={this.props.badgeNum} />
            </View>
            {this.props.iconText ? (
              <OTText style={{ marginLeft: 10, color: StyleConstants.TeamHeaderIconColour }}>
                {this.props.iconText}
              </OTText>
            ) : null}
          </View>
        </MenuTrigger>
        <MenuOptions
          customStyles={{
            optionsContainer: {
              width: undefined,
              marginTop: 10,
              borderRadius: Theme.curviness,
              backgroundColor: Theme.DialogBackground,
              ...StyleConstants.LightShadow,
            },
            optionsWrapper: {},
            optionWrapper: { paddingVertical: 10 },
          }}
        >
          {this.renderOpen()}
        </MenuOptions>
      </Menu>
    );
  }

  renderOpen = () => {
    return (
      <View
        style={{
          padding: 10,
          paddingVertical: 15,
          // borderRadius: Theme.curviness,
          minWidth: 250,
          maxWidth: 300,
          maxHeight: 700,
        }}
      >
        {this.props.title ? (
          <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
            {this.props.buttonLeft}

            <OTText
              style={{
                flex: 1,
                fontWeight: "600",
                color: Theme.MainText,
                fontSize: 18,
                textAlign: "center",
              }}
            >
              {this.props.title}
            </OTText>

            {this.props.buttonRight}
          </View>
        ) : null}
        {this.props.renderOpen()}
      </View>
    );
  };
}
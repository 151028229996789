import React, { Component } from "react";
import { observer } from "mobx-react";
import { View, StyleSheet, Switch } from "react-native";
import { OTText } from "../../../components/OTText";
import { FireDb, OTGlobals, OTUITree } from "@openteam/app-core";
import { getRTDB } from "../../../globals/Fire/util";


interface NotifySettingsProps {
  teamId: string
}
interface NotifySettingsState {
}

const megaByte = 1024 * 1024

@observer
export class NotifySettings extends Component<NotifySettingsProps, NotifySettingsState> {
  constructor(props) {
    super(props);

    this.state = {
    }

  }

  componentWillUnmount = () => {
  }

  setUserOnlineNotify = (value: boolean) => {
    console.log("setUserOnlineNotify now set to", value)
    FireDb.setTeamUserPreference(getRTDB(), this.props.teamId, OTUITree.auth.userId, 'disableNotifyUserOnline', !value)
  }


  render() {
    const teamData = OTGlobals.getTeamData(this.props.teamId)

    return (
      <View style={{}}>

        <View style={[styles.settingsSection, { width: 300, flexDirection: 'row', alignItems: 'center' }]}>
          <OTText style={styles.title}>Let me know when a teammate comes online</OTText>
          <Switch value={!teamData.preferences?.disableNotifyUserOnline} onValueChange={this.setUserOnlineNotify} />
        </View>

      </View>
    )
  }

}




const styles = StyleSheet.create({
  title: {
    fontSize: 14,
    fontWeight: "600",
    marginBottom: 10,
  },
  settingsSection: {
    marginBottom: 20
  },
})
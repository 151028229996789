import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

import * as Device from "expo-device";
import { Logger } from "@openteam/app-util";
import { OTGlobals } from "../OTGlobals";
import { OTAppCoreData } from "../OTAppCoreData";
import { OTUserInterface } from "../OTUserInterface";
import { addWatch, removeWatch } from "./FireUtils";

const logger = new Logger("FireSession");

export class SessionDb {
  static hasSetupAppPresence: boolean = false;
  static isWatchingAppSession: boolean = false;

  static setupAppPresence = (
    fbDb: firebase.database.Database,
    userId: string,
    sessionToken: string,
    endActiveSessionCallback: (reload: boolean) => Promise<void>
  ) => {
    var statusRef = fbDb.ref(`/users/${userId}/status`);
    var sessionRef = statusRef.child("activeSessions").child(sessionToken!);

    const device = {
      manufacturer: Device.manufacturer,
      modelName: Device.modelName,
      deviceName: Device.deviceName,
      osName: Device.osName,
      osVersion: Device.osVersion,
    };

    logger.debug("device details", device);

    var isOfflineForDatabase = {
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    };

    var isOnlineForDatabase = {
      state: null, // clear previous status info
      last_changed: firebase.database.ServerValue.TIMESTAMP,
      sessionToken: sessionToken,
      appVersion: OTAppCoreData.version,
      platform: OTUserInterface.platformUtils.PlatformOS,
      userAgent: navigator.userAgent || null,
      device: device,
    };

    fbDb.ref(".info/connected").on("value", function (snapshot) {
      OTGlobals.fbConnected = snapshot.val();
      logger.info("OTGlobals.fbConnected", OTGlobals.fbConnected);

      if (OTGlobals.fbConnected) {
        OTGlobals.appHomeManager?.heartbeatManager?.doHeartbeat();
      }

      if (snapshot.val() == false) {
        return;
      }

      sessionRef
        .onDisconnect()
        .set(null)
        .then(function () {
          sessionRef.set(true);
        });

      statusRef
        .onDisconnect()
        .update(isOfflineForDatabase)
        .then(function () {
          statusRef
            .update(isOnlineForDatabase)
            .then(() =>
              SessionDb.watchAppSession(fbDb, userId, sessionToken, endActiveSessionCallback)
            );
        });
    });

    SessionDb.hasSetupAppPresence = true;
  };

  static watchAppSession = (
    fbDb: firebase.database.Database,
    userId: string,
    sessionToken: string,
    endActiveSessionCallback: (reload: boolean) => Promise<void>
  ) => {
    if (SessionDb.isWatchingAppSession) {
      return;
    }
    SessionDb.isWatchingAppSession = true;

    const ref = `/users/${userId}/status`;
    addWatch(ref);
    fbDb.ref(ref).on("value", (doc) => {
      if (sessionToken != doc.val().sessionToken) {
        endActiveSessionCallback(true);
      }
    });
  };

  static unwatchAppSession = (fbDb: firebase.database.Database, userId: string) => {
    if (!SessionDb.isWatchingAppSession) {
      return;
    }
    const ref = `/users/${userId}/status`;
    removeWatch(ref);
    fbDb.ref(ref).off("value");
  };

  static removeAppSession = async (
    fbDb: firebase.database.Database,
    userId: string,
    sessionToken: string,
    teamIds: string[]
    //teamIdList: Record<string, boolean>
  ) => {
    if (!SessionDb.hasSetupAppPresence) {
      return;
    }

    fbDb.ref(".info/connected").off("value");

    await Promise.all(
      teamIds.map((teamId) => {
        const statusRef = fbDb.ref("/teams/" + teamId + "/users/" + userId + "/status");
        const sessionRef = statusRef.child("activeSessions").child(sessionToken!);

        statusRef.onDisconnect().cancel();
        sessionRef.onDisconnect().cancel();

        return sessionRef.set(null);
      })
    );

    SessionDb.unwatchAppSession(fbDb, userId);

    var appStatusRef = fbDb.ref(`/users/${userId}/status`);
    var appSessionRef = appStatusRef.child("activeSessions").child(sessionToken!);

    await appSessionRef.set(null);
    appStatusRef.onDisconnect().cancel();
    appSessionRef.onDisconnect().cancel();

    SessionDb.hasSetupAppPresence = false;
  };

  static setupTeamPresence = (
    fbDb: firebase.database.Database,
    userId: string,
    sessionToken: string,
    teamId: string
  ) => {
    var statusRef = fbDb.ref("/teams/" + teamId + "/users/" + userId + "/status");
    var sessionRef = statusRef.child("activeSessions").child(sessionToken!);

    var isOfflineForDatabase = {
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    };

    fbDb.ref(".info/connected").on("value", function (snapshot) {
      if (snapshot.val() == false) {
        return;
      }

      sessionRef
        .onDisconnect()
        .set(null)
        .then(function () {
          sessionRef.set(true);
        });

      statusRef
        .onDisconnect()
        .update(isOfflineForDatabase)
        .then(function () {
          statusRef.update({
            state: null,
            idle: OTGlobals.isIdle || null,
            last_changed: firebase.database.ServerValue.TIMESTAMP,
            sessionToken: sessionToken,
            appVersion: OTAppCoreData.version,
            // currentRoom: null
          });
        });
    });
  };
}

import React, { Component } from "react";
import { observer } from "mobx-react";
import { View } from "react-native";
import { Logger } from '@openteam/app-util';
import { UserVideo } from "./userTile";
import PopoutWindow, { getStyleElement } from "./popoutWindow/PopoutWindow";
import { OTGlobals } from "@openteam/app-core";

const logger = new Logger("StreamPopout")


interface StreamPopoutProps {
    teamId: string
    streamId: string
    title?: string
    onClose?: () => void
}
interface StreamPopoutState {
    width: number
    height: number
}



@observer
export class StreamPopout extends Component<StreamPopoutProps, StreamPopoutState> {
    constructor(props) {
        super(props)
        this.state = { width: 1024, height: 768 }

    }

    onSize = (width, height) => {
        logger.info(`Popout window resized to ${width}x${height}`)
        this.setState({ width, height })
    }

    get windowTitle() {
        const { teamId, streamId } = this.props;
        const teamData = OTGlobals.getTeamData(teamId);
        const { userId, streamType } = teamData.streams[streamId]

        const user = teamData.getCallUser(userId)

        if (this.props.title) {
            return this.props.title;
        } else if (streamType == 'screen') {
            return `Screen Share (${user.name})`
        } else {
            return user.name
        }
    }

    render() {
        const { teamId, streamId } = this.props;
        const teamData = OTGlobals.getTeamData(teamId);
        const { streamType } = teamData.streams[streamId]

        return (
            <PopoutWindow
                frameName={`${streamType}-${streamId}`}
                width={this.state.width}
                height={this.state.height}
                title={this.windowTitle}
                onSize={this.onSize}
                onClose={this.props.onClose}
            >
                { this.renderScreenShare()}
                { getStyleElement()}
            </PopoutWindow>
        )
    }

    renderScreenShare() {
        const { teamId, streamId } = this.props;
        const teamData = OTGlobals.getTeamData(teamId);

        const streamInfo = teamData.streams[streamId];
        const { streamType, hasVideo, stream } = streamInfo || { streamType: 'camera' };

        return (
            <View>
                <UserVideo
                    componentId={streamId}
                    flipVideo={false}
                    muted={true}
                    videoStream={stream}
                    hasVideo={hasVideo}
                    hasAudio={false}
                    width={this.state.width}
                    height={this.state.height}
                    objectFit="contain"
                />
            </View>
        )
    }
}
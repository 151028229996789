import { action, observable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { Platform } from "react-native";
import { OTAppCoreData } from "@openteam/app-core";

import { Logger } from "@openteam/app-util";

const logger = new Logger("OTData");

class OTAppDataClass {
  //globals
  @observable admins: { [id: string]: { id: string } } = {};
  @observable splashEnabled?: boolean = undefined;
  @observable webUpgradeAvailable = false;
  @observable webUpgradeForce = false;
  @observable desktopAppUpgradeAvailable = false;
  @observable isFullscreen = false;
}

export class OTUserDataClass {
  //user
  //@observable started: boolean = false;
  @observable userInteracted = false;

/*   @observable userToken: string = "";
  @observable isAnonymous: boolean = false;
  @observable sessionToken: string = Platform.OS == "web" ? uuidv4() : OTAppCoreData.deviceId!;
 */
  //access parameters
  @observable referrerId?: string = undefined;
  @observable externalMeeting?: { teamPath: string; address: string; roomId?: string } = undefined;
  @observable appLogin?: string = undefined;
  @observable loginRedirect?: string = undefined;

  @observable reqTeamPath?: string = undefined;
  @observable teamInviteId?: string = undefined;

  //teams

  @action reset() {
    logger.debug("OTUserData.reset");

    //user
    //this.started = false;
    this.userInteracted = false;

/*     this.userToken = "";
    this.isAnonymous = false;
    this.sessionToken = OTAppCoreData.deviceId!;
 */
    // device

    this.referrerId = undefined;
    this.externalMeeting = undefined;
    this.appLogin = undefined;
    this.loginRedirect = undefined;

    this.reqTeamPath = undefined;
    this.teamInviteId = undefined;
  }

/*   isMe(userId: string) {
    return userId == this.userToken;
  }
 */
}

// Maintained by TeamManager, PTT streams go in here as well

export interface TrackStatSet {
  score: number;
  jitter: number;
  bitrate: number;
  roundTripTime: number;
}

export interface VideoTrackStat extends TrackStatSet {
  layer: number;
}

export interface TrackStat {
  kind: string;
  codec: string;
}

export var OTAppData: OTAppDataClass = new OTAppDataClass();
export var OTUserData: OTUserDataClass = new OTUserDataClass();

export function createOTAppData() {
  OTAppData = new OTAppDataClass();
}

export function createOTUserData() {
  OTUserData = new OTUserDataClass();
}

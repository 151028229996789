import React from "react";
import { StyleSheet } from "react-native";
import { View } from "react-native-web";
import * as ModalService from "../../components/ModalService";
import { DialogView } from "../../components/DialogView";
import { Theme } from "../../globals/ThemeColors";
import { OTTouchableOpacity } from "../../components";
import { OTText } from "../../components/OTText";
import { Feather } from "@expo/vector-icons";
import { OTGlobals } from "@openteam/app-core";

interface IReleaseNote {
  type: "feature" | "bug";
  title: string;
  desc?: string;
}
// never delete anything from this list as it's index is used to work out what
// a user has seen
var releaseNotes: IReleaseNote[] = [
  {
    type: "bug",
    title: "Improve performance of walkie talkie",
    desc: "Walkie talkie is now much faster at obtaining connection.",
  },
  {
    type: "feature",
    title: "Improved team security",
    desc: "Users that try to access your team now need approval. You can approve users in the invite menu in the top bar.",
  },
  {
    type: "feature",
    title: "Calls now have a messaging feature.",
    desc: "Messages are only sent to the current participants and are lost when you leave the room.",
  },
  {
    type: "bug",
    title: "Improve cpu usage of video chat",
  },
  {
    type: "feature",
    title: "Video Calls in 'focus' view automatically show talking person",
    desc: "If you select 'focus' mode the visible stream will now switch to the team member making the most noise. If you just want to see a particular stream you can pin it by clicking on it.",
  },
  {
    type: "feature",
    title: "Chat with users",
    desc: "You can now chat with people! You will find the chat button next to the call and knock buttons",
  },
  {
    type: "bug",
    title: "Fix issue with connection in calls not always initialising",
  },
  {
    type: "feature",
    title: "Video Chat View Modes",
    desc: "We've moved view modes to the top right of the screen to make it easier to switch between the Grid/Focused and Theatre modes",
  },
  {
    type: "feature",
    title: "Rooms have more settings",
    desc: "We've added the ability to choose whether your room has walkie-talkie enabled, whether it is a call, and whether to default webcam/mic off when someone joins",
  },
  {
    type: "feature",
    title: "YouTube App",
    desc: "We've added our first app to video chat! From the 'Apps' menu you can now select YouTube and watch a video in sync with whoever else is in the room",
  },
  {
    type: "feature",
    title: "General Improvements and fixes",
    desc: `New notification when someone joins break room so you can take breaks together.

Clicking on notifications now takes you to OpenTeam.

You can now see when someone is calling you into a room.

When entering a room, you are now automatically shown the room.

You can only lock rooms when inside them and the room automatically unlocks when you are the last one to leave.

New coloured connection dot, green for connected, grey for connecting and red for disconnected.
    `,
  },
  {
    type: "feature",
    title: "Improved performance in video calls with large numbers of participants",
    desc: `You should notice much lower cpu and bandwidth usage when participating in video calls with more than 2 people.`,
  },
  {
    type: "bug",
    title: "Two-way Walkie talkie conversations now work reliably.",
    desc: `A number of issues with Walkie talkie have now been fixed giving much more reliable behaviour when having two way and longer conversations.`,
  },
  {
    type: "feature",
    title: "Peer-to-Peer file transfer",
    desc: `Drag a file onto a user's tile to send that file to them directly over the P2P data connection.`,
  },
  {
    type: "feature",
    title: "Room chat made less intrusive",
    desc: `Chat history will no longer display on hover, instead click the message history icon to see historic chat messages`,
  },
  {
    type: "bug",
    title: "Fixed occasional issue with user video not appearing in a call",
  },
  {
    type: "bug",
    title: "Stop user photo being overwritten from another team",
  },
  {
    type: "feature",
    title: "Team Images",
    desc: `You can now set a custom background/icon image for your team`,
  },
  {
    type: "feature",
    title: "Settings menu changes",
    desc: `User settings have now been combined with team settings and moved to the cog in the top right`,
  },
  {
    type: "feature",
    title: "Feedback has now moved under a new help menu",
  },
  {
    type: "bug",
    title: "Connection reliability fixes, especially for users on slow/bad connections",
  },
  {
    type: "feature",
    title: "Multiple Teams",
    desc: `You are now logged into all of the teams you are part of at the same time. When someone is using a different team, their status indicator on their tile goes blue. Whilst they are in this state, you are unable to push to talk to them.`,
  },
  {
    type: "feature",
    title: "Sub Team improvements",
    desc: `Your sub teams can now be created, renamed and assigned from the settings panel by your team admin.`,
  },
  {
    type: "bug",
    title: "Connection Issues",
    desc: `We have fixed various issues around connections not initialising when joining rooms.`,
  },
  {
    type: "feature",
    title: "Camera and Screenshare Quality settings",
    desc: `You can now increase/decrease the quality of your camera and screenshares using the new quality settings under devices in the settings panel. Lower quality settings will use less CPU.`,
  },
  {
    type: "feature",
    title: "Simulcast Camera Streams",
    desc: `This allows the system to generate an additional low quality stream to be used in low bandwidth situations. It can be enabled or disabled in the device settings and is enabled by default.

Stream statistics can now be seen by clicking on the signal icon and can be enabled in the settings.`,
  },
  {
    type: "bug",
    title: "MacOS: Fix issue where ringtone will play after ending a facetime call.",
  },
  {
    type: "bug",
    title: "Fix issue where rooms with lots of participants would go off the screen.",
  },
  {
    type: "bug",
    title: "Fix issue where trying to change media device settings fails.",
  },
  {
    type: "feature",
    title: "Clicking on a video when in the team view will now maximise that video.",
  },
  {
    type: "feature",
    title: "Optimised Video Quality",
    desc: `To save your bandwidth and CPU usage, OpenTeam now automatically optimises the quality of the video you receive depending on your bandwidth availablity and how big the videos are displayed on your screen.`,
  },
  {
    type: "bug",
    title:
      "Fix issue where in certain circumstances audio would stop during a call and not recover.",
  },
  {
    type: "feature",
    title: "Add support for Google one tap login.",
  },
  {
    type: "feature",
    title: "Performance improvements",
    desc: "We've been working hard behind the scenes to reduce the CPU usage of video meetings. Hopefully this means your computer stays quieter and cooler, despite the hot weather",
  },
  {
    type: "feature",
    title: "UI improvements",
    desc: "We've revamped the video chat UI to make sure it is cleaner and works better than ever.",
  },
  {
    type: "feature",
    title: "Integration with Witeboard",
    desc: "Collaborate in meetings using Witeboard. You can even leave a Witeboard open in a room so it is there the next time you need it.",
  },
  {
    type: "feature",
    title: "Desktop client now available",
    desc: "You'll now notice that when you're using OpenTeam in the browser you get prompted to install our desktop client. The desktop client includes additional features such as a 'collaboration' widget that shows when you minimise the app when in a call, and also presence detection, so your team memebers know if you're at your computer or not. Mac OS users also get a frameless design.",
  },
  {
    type: "feature",
    title: "Integration with Google Docs",
    desc: "From a meeting room, you can now create a Google Doc with one click, which automatically shares it with everyone else in the call. Great for when you want to collaborate with speed, or take minutes of the meeting.",
  },
  {
    type: "feature",
    title: "Room Notifications",
    desc: "You can now set whether meeting rooms you create notify online users or not. You can pick between notifying everyone online (like the break room) or just a certain sub team. e.g. You could create an 'Engineering Help' room which allowed team mates to ask for help from engineering only.",
  },
  {
    type: "feature",
    title: "Unsplash team backgrounds",
    desc: "We have integrated with Unsplash, so you can now pick a cool team background from Unsplashed (Team Admins will see this in settings).",
  },
  {
    type: "bug",
    title: "Video performance",
    desc: "We have fixed a bug that was preventing the video stream from recovering from interruptions in some situations, sometimes causing it to flash between the video and photo.",
  },
  {
    type: "feature",
    title: "Collaboration apps",
    desc: "We've added integrations with Google Drive, MiroBoard, Trello and VS Code to meeting rooms. We've also added the ability to pop them out into separate windows and also access them when using the desktop client widget.",
  },
  {
    type: "bug",
    title: "Fix display of links in video call",
    desc: "When sending a link in a video call, if the link was too long it would only be partially displayed. This is now fixed, and also those links are now clickable.",
  },
];

export function showReleaseNotes(lastSeenIndex?: number) {
  if (lastSeenIndex == undefined) {
    OTGlobals.userSettingsManager.updateRemoteSettings({ releaseNoteIndex: releaseNotes.length });
    return;
  }

  if (releaseNotes.length > lastSeenIndex) {
    var latestUpdates = releaseNotes.slice(lastSeenIndex).reverse();

    ModalService.showModal({
      contents: (
        <DialogView title="Latest Updates">
          <View>
            {latestUpdates.map((releaseNote, index) => (
              <View key={index} style={{ padding: 10 }}>
                <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
                  <View
                    style={[
                      styles.item,
                      {
                        backgroundColor:
                          releaseNote.type == "bug" ? Theme.BugColour : Theme.FeatureColour,
                      },
                    ]}
                  >
                    <OTText style={{ fontWeight: "bold" }}>{releaseNote.type}</OTText>
                  </View>
                  <View style={{ flex: 1, maxWidth: 500 }}>
                    <OTText style={{ fontWeight: "bold" }}>{releaseNote.title}</OTText>
                    <OTText>{releaseNote.desc}</OTText>
                  </View>
                </View>
              </View>
            ))}
          </View>
          <OTTouchableOpacity
            onPress={dismissReleaseNotes}
            style={{
              position: "absolute",
              top: -10,
              right: -10,
              padding: 3,
              borderRadius: 18,
              backgroundColor: "white",
              maxWidth: "70vw",
            }}
          >
            <Feather name="x-circle" size={30} style={{ color: "black" }} />
          </OTTouchableOpacity>
        </DialogView>
      ),
      showContainer: true,
    });
  }
}

function dismissReleaseNotes() {
  ModalService.dismissModal();
  OTGlobals.userSettingsManager.updateRemoteSettings({ releaseNoteIndex: releaseNotes.length });
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  item: {
    width: 70,
    paddingVertical: 3,
    borderRadius: Theme.curviness / 3,
    alignItems: "center",
    marginRight: 10,
  },
});
